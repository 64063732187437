import clsx from 'clsx';
import { FacilityLocation } from 'patientOnboarding/@types';
import avatar from 'patientOnboarding/assets/img/avatar.jpg';
import { ReactComponent as ClockIcon } from 'patientOnboarding/assets/svg/clock.svg';
import { ReactComponent as CloseIcon } from 'patientOnboarding/assets/svg/close.svg';
import { ReactComponent as ErrorIcon } from 'patientOnboarding/assets/svg/error.svg';
import { ReactComponent as InOfficeIcon } from 'patientOnboarding/assets/svg/in-office.svg';
import { ReactComponent as VideoChatIcon } from 'patientOnboarding/assets/svg/video-icon-new.svg';
import { intakeRoutes } from 'patientOnboarding/routes/absolutes';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, SpanButton } from 'lifestance-ui';

import { updatePreferences } from 'patientOnboarding/store/preferences/actions';
import { updateReservation } from 'patientOnboarding/store/reservationGlobal/actions';

import {
  obiePreferencesData,
  selectedClinicianSelector,
} from 'patientOnboarding/selectors/selectors';

import { formatDateWithoutTime, getLocalTime, getTimeZone } from 'patientOnboarding/utils';

import styles from './Appointment.module.scss';

interface AppointmentProps {
  testId?: string;
  onClose: () => void;
  reservation: any;
  reservationError?: string;
  setNext?: (viewNumber: number) => void;
  // goToIntake?: boolean;
}

export const Appointment: React.FC<React.PropsWithChildren<AppointmentProps>> = ({
  onClose,
  reservation,
  reservationError,
  testId = '',
  setNext,
}) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isVideoAppointment = reservation?.modality === 'VIDEO';
  const appointmentTypeIcon = isVideoAppointment ? (
    <VideoChatIcon data-testId={`appointment${testId}VideoIcon`} />
  ) : (
    <InOfficeIcon data-testId={`appointment${testId}OfficeIcon`} />
  );
  const appointmentTypeTitle = isVideoAppointment ? 'Video appointment' : 'In-office appointment';
  const imgsrc = reservation?.clinician.photo ? reservation?.clinician.photo : avatar;
  const preferences = useSelector(obiePreferencesData);
  const profile = useSelector(selectedClinicianSelector);
  const facility = reservation.selectedFacility;
  const { location_back } = preferences;
  const isColdLanding = (location_back?.length === 0 && location?.pathname.includes('provider'))
    || location_back?.includes('/find-care/booking/search');
  const getFacility = (facilities: FacilityLocation[], facilityId: number) => facilities.filter((fac) => fac.facility_id === facilityId);
  const address = `${facility?.facility_name} ${facility?.address_line1} ${facility?.city} ${facility?.state}`;
  const mapsURL = `https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}`;

  const handleClose = () => {
    setOpen(!open);
    setTimeout(() => {
      onClose && onClose();
    }, 500);
  };

  const handleContinue = () => {
    setOpen(!open);
    navigate(intakeRoutes.intake);
    dispatch(
      updatePreferences({
        clinician_profile_back: [
          ...preferences.clinician_profile_back,
          `${location.pathname}${location.search}`,
        ],
        rematch_clinician_id: 0,
      }),
    );
    const selectedFacility = getFacility(profile.facility_location, reservation.facility_id);
    dispatch(
      updateReservation({
        selectedFacility: { ...selectedFacility[0], license_key: profile.license_key },
      }),
    );
  };

  const handleFilterWall = () => {
    if (setNext) {
      setNext(4);
    }
  };

  const labelButton = reservation.availability ? 'Looks good' : 'Choose a new time';

  return (
    <div className={styles.appointment}>
      <div
        data-testId={`mobileBackground${testId}Close`}
        className={styles.modal}
        onClick={handleClose}
      />
      <div className={clsx(styles.container, { [styles.open]: open })}>
        <div className={styles.header}>
          <h2 className={styles.title}>Your appointment</h2>
        </div>
        <CloseIcon
          data-testId={`button${testId}Close`}
          onClick={handleClose}
          className={styles.closeIcon}
        />
        <div className={styles.row}>
          <div className={styles.icon}>
            <img
              className={styles.resultThumbnail}
              src={imgsrc}
              alt={reservation.clinician.first_name}
            />
          </div>
          <div className={styles.info}>
            <h2 data-testId={`appointment${testId}NameCredentials`}>
              {reservation.clinician.first_name}
              {' '}
              {reservation.clinician.last_name}
              {reservation?.clinician?.credentials ? `, ${reservation.clinician.credentials}` : ''}
            </h2>
            {/* {reservation.availability
              ? <p className={styles.link}>Share profile</p>
              ? <p className={styles.link}>Find another provider</p> : ""} */}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.icon}>
            {reservationError && reservationError !== '' ? (
              <ErrorIcon data-testId={`appointment${testId}ErrorIcon`} />
            ) : (
              <ClockIcon data-testId={`appointment${testId}ClockIcon`} />
            )}
          </div>
          <div className={styles.info}>
            <h2 data-testId={`appointment${testId}TypeOfCare`}>{reservation?.type_of_care}</h2>
            {reservation.availability ? (
              <>
                <p data-testId={`appointment${testId}Reservation`}>
                  <span>{formatDateWithoutTime(reservation?.available_date)}</span>
                  ,
                  {' '}
                  <span data-testId={`appointment${testId}StartHour`}>
                    {getLocalTime(reservation?.available_date, reservation?.appointment_start_time)}
                  </span>
                  -
                  <span data-testId={`appointment${testId}EndHour`}>
                    {getLocalTime(reservation?.available_date, reservation?.appointment_end_time)}
                    {' '}
                  </span>
                  <span data-testId={`appointment${testId}TimeZone`}>{getTimeZone()}</span>
                </p>
                {reservationError && reservationError !== '' && (
                  <p className={styles.notAvailable}>This time is no longer available</p>
                )}
              </>
            ) : (
              <p className={styles.notAvailable}>This time is no longer available</p>
            )}
          </div>
        </div>
        <div className={clsx(styles.row, styles.last)}>
          <div className={styles.icon}>{appointmentTypeIcon}</div>
          <div className={styles.info}>
            <h2 data-testId={`appointment${testId}Type`}>{appointmentTypeTitle}</h2>
            {!isVideoAppointment && (
              <>
                <div className={styles.line} data-testId="facilityAddress">
                  <div>{facility?.address_line1}</div>
                </div>
                <div className={styles.line} data-testId="facilityAddress">
                  <div>{facility?.address_line2}</div>
                </div>
                <div className={styles.line} data-testId="facilityCity">
                  <div>
                    {facility?.city}
                    ,
                    {facility?.state}
                    {' '}
                    {facility?.postal_code}
                  </div>
                </div>
                <div className={styles.line} style={{ marginTop: '4px' }}>
                  <a
                    className={styles.link}
                    href={mapsURL}
                    target="_blank"
                    rel="noreferrer"
                    data-testId="Link"
                  >
                    <SpanButton>Open in Google maps</SpanButton>
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          {isColdLanding ? (
            <Button testId={`button${testId}LooksGood`} fullWidth onClick={handleFilterWall}>
              {labelButton}
            </Button>
          ) : (
            <Button testId={`button${testId}LooksGood`} fullWidth onClick={handleClose}>
              {labelButton}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
