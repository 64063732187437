import { clsx } from 'clsx';
import { ReactComponent as Warning } from 'patientOnboarding/assets/svg/alert-gold.svg';
import { ReactComponent as Alert } from 'patientOnboarding/assets/svg/alert-line.svg';
import { ReactComponent as GreenCheck } from 'patientOnboarding/assets/svg/check-fill.svg';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, LoadingSpan } from 'lifestance-ui';

import { obiePreferencesData, selectedClinician } from 'patientOnboarding/selectors/selectors';

import { formatAgesAccepted, orderAges, useDevice } from 'patientOnboarding/utils';

import styles from './MatchedConditions.module.scss';

interface MatchedConditionsProps {
  zipCode: string;
  dateOfBirth: string;
  onClick: () => void;
  onViewMoreMatches: () => void;
  handleRematchTOC: (toc: string) => void;
  selectedTypeOfCare?: string;
}

export const MatchedConditions: FC<MatchedConditionsProps> = ({
  zipCode,
  dateOfBirth,
  onClick,
  onViewMoreMatches,
  handleRematchTOC,
  selectedTypeOfCare,
}) => {
  const profile = useSelector(selectedClinician);
  const { state, age, typeOfCare } = useSelector(obiePreferencesData);
  const [locationWaiting, setLocationWaiting] = useState(true);
  const [locationValid, setLocationValid] = useState(false);
  const [ageValid, setAgeValid] = useState(false);
  const [dateOfBirthWaiting, setDateOfBirthWaiting] = useState(true);
  const [validAgeForTOC, setValidAgeForTOC] = useState(true);
  const [newTypeOfCare, setNewTypeOfCare] = useState('');
  const sameState = profile.facility_location[0].state === state;
  const orderedAges = orderAges(profile.ages_accepted);
  const agesString = formatAgesAccepted(orderedAges);
  const distanceInMiles = profile.facility_location[0].distance_in_miles;
  const currentTOC = selectedTypeOfCare || typeOfCare;
  const isChildCare = currentTOC.toLowerCase().includes('child');
  const { isMobile } = useDevice();

  const idle = (functionToIdle: Dispatch<SetStateAction<boolean>>) => {
    setTimeout(() => {
      functionToIdle(false);
    }, 575);
  };

  const checkForAges = (ageGiven: string, agesToCompare: string) => {
    const currentAge = parseInt(ageGiven, 10);
    const maxAge = 150; // We are using 150 as the patient's max age (Check PaymentOptions.tsx:216)
    const values = agesToCompare.replaceAll(' ', '').split(',');
    const upperLimits = values.map((value) => parseInt(value.substring(value.indexOf('-') + 1), 10));

    const lowerLimits = values.map((value) => parseInt(value.substring(0, value.indexOf('-')), 10));

    if (agesToCompare.includes('65+')) {
      upperLimits.push(maxAge);
      lowerLimits.push(65);
    }

    const filterAges = upperLimits.filter(
      (value, ind) => lowerLimits[ind] <= currentAge && currentAge <= value,
    );

    return filterAges.length > 0;
  };
  const validateAgeForTOC = (ageGiven: string, toc: string) => {
    const currentAge = parseInt(ageGiven, 10);
    const regexBoth = /testing/;
    const regexChild = /child/;
    const isBoth = regexBoth.test(toc.toLowerCase());
    const isChild = regexChild.test(toc.toLowerCase());
    if (isBoth) return true;
    if (isChild) return isChild && currentAge < 18;
    return !isChild && currentAge >= 18;
  };

  const getNewToc = (toc: string) => {
    const includesChild = toc.toLowerCase().includes('child');
    const patient = includesChild ? 'adult' : 'child';
    const includesTherapy = toc.toLowerCase().includes('therapy');
    const type = includesTherapy ? 'therapy' : 'psychiatry';
    return profile.type_of_cares.filter((currentToc: string) => [patient, type].every((word) => currentToc.toLowerCase().includes(word)));
  };
  const matchingProvidersText = isMobile
    ? 'See matching providers'
    : 'See providers that meet your criteria';

  useEffect(() => {
    if (sameState) {
      setLocationValid(true);
    }

    setAgeValid(checkForAges(age, agesString));
    const isValidForTOC = validateAgeForTOC(age, currentTOC);
    if (isValidForTOC) {
      setValidAgeForTOC(true);
    } else {
      setValidAgeForTOC(false);
      setNewTypeOfCare(getNewToc(currentTOC)[0] || '');
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLocationWaiting(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (!locationWaiting) {
      idle(setDateOfBirthWaiting);
    }
  }, [locationWaiting, dateOfBirthWaiting]);

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Verifying compatibility</h1>
      <span className={styles.subHeader}>
        Cross-referencing your needs with this provider’s background and credentials...
      </span>
      <div className={styles.content}>
        <div className={styles.card}>
          <LoadingSpan loading={locationWaiting}>
            <span className={styles.field}>Location:</span>
            {' '}
            {zipCode}
            {!locationValid ? <Alert className={styles.noMatch} /> : null}
            {locationValid && distanceInMiles > 60 ? <Alert className={styles.alert} /> : null}
            {locationValid && distanceInMiles < 60 ? <GreenCheck /> : null}
          </LoadingSpan>
          {!locationValid && !locationWaiting && (
            <p>This provider is not licensed in this state.</p>
          )}
          {locationValid && !locationWaiting && distanceInMiles > 60 && (
            <p>Keep in mind this provider is more than 60 miles away.</p>
          )}
        </div>
        <div className={styles.card}>
          <LoadingSpan loading={dateOfBirthWaiting}>
            <span className={styles.field}>Date of birth:</span>
            {' '}
            {dateOfBirth}
            {ageValid && validAgeForTOC && <GreenCheck />}
            {(!validAgeForTOC || !ageValid) && (
              <Warning
                className={clsx({
                  [styles.alert]: !validAgeForTOC,
                  [styles.noMatch]: newTypeOfCare.length === 0 || !ageValid,
                })}
              />
            )}
          </LoadingSpan>
          {!ageValid && !dateOfBirthWaiting && (
            <p className={styles.ageValid}>
              This provider only sees patients who are
              {` ${agesString}`}
            </p>
          )}
          {ageValid && !validAgeForTOC && !dateOfBirthWaiting && (
            <p className={styles.validAgeForToc}>
              This age does not match the type of appointment selected as the reason for your visit.
            </p>
          )}
        </div>
        {!dateOfBirthWaiting && (
          <div className={styles.footer}>
            {locationValid && ageValid && validAgeForTOC && (
              <Button fullWidth onClick={onClick}>
                Continue to booking
              </Button>
            )}
            {locationValid && ageValid && !validAgeForTOC && newTypeOfCare?.length > 0 && (
              <Button fullWidth onClick={() => handleRematchTOC(newTypeOfCare)}>
                Book as
                {' '}
                {isChildCare ? 'an adult' : 'a child'}
              </Button>
            )}
            {(!locationValid || !ageValid || (!validAgeForTOC && newTypeOfCare.length === 0)) && (
              <Button fullWidth onClick={onViewMoreMatches}>
                {matchingProvidersText}
              </Button>
            )}
            {locationValid && ageValid && distanceInMiles > 60 && (
              <Button
                secondary
                fullWidth
                onClick={onViewMoreMatches}
                className={styles.seeMoreProvidersButton}
              >
                See providers closer to you
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
