import { useEffect, useMemo, useState } from 'react';

import { buildThresholdList } from 'patientOnboarding/utils';

export const useIntersection = (targetRef, options, callback, steps = 5) => {
  const [isVisible, setIsVisible] = useState(false);
  const defaultOptions = options || {
    root: null,
    rootMargin: '0px',
    threshold: buildThresholdList(steps),
  };
  const defaultCallback = callback || (([firstEntry]) => setIsVisible(firstEntry.intersectionRatio < 1));
  const optionsMemo = useMemo(() => defaultOptions, [defaultOptions]);

  useEffect(() => {
    const currentTarget = targetRef?.current;
    const observer = new IntersectionObserver(defaultCallback, optionsMemo);

    if (currentTarget) observer.observe(currentTarget);

    // unmount
    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [targetRef, optionsMemo]);
  return isVisible;
};
