import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from 'lifestance-ui/assets/svg/close.svg';

import styles from './ContextModal.module.scss';

interface IContextModalProps {
  isOpen: boolean;
  onClose: (val: boolean) => void;
  fullScreen?: boolean;
  bottomScreen?: boolean;
  hideHeader?: boolean;
  hideCloseIcon?: boolean;
  mainClassName?: string;
  scrollableModalContent?: boolean;
  bottomModal?: boolean;
}

export const ContextModal: FC<React.PropsWithChildren<IContextModalProps>> = ({
  isOpen,
  onClose,
  children,
  fullScreen,
  bottomScreen,
  hideHeader,
  hideCloseIcon,
  mainClassName = '',
  bottomModal = false,
}) => {
  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      onClose(false);
    }, 500);
  };

  // TBD with dropdownd inside since right now dropdowns are overwritten
  // this behavior working properly but only if no dropdowns are in place
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.style.overflow = 'hidden';
  //   }
  //   return function cleanup() {
  //     document.body.style.overflow = 'auto';
  //   };
  // }, [isOpen]);

  return (
    <div
      className={clsx(styles.modal, {
        [styles.fullScreen]: fullScreen,
        [styles.bottomScreen]: bottomScreen,
        [styles.showModal]: open,
      })}
      data-testId="Modal"
    >
      <div
        className={clsx(styles.modalContent, {
          [styles.modalContentBottom]: bottomModal,
        })}
        data-testId="ModalContent"
      >
        {!hideHeader && (
          <div
            className={clsx(styles.modalHeader, {
              [styles.defHeight]: hideCloseIcon,
            })}
            data-testId="ModalHeader"
          >
            <span
              tabIndex={0}
              role="button"
              className={styles.closeButton}
              onClick={handleClose}
              onKeyDown={handleClose}
              data-testId="CloseButton"
            >
              {!hideCloseIcon && <CloseIcon />}
            </span>
          </div>
        )}
        <div
          className={clsx(styles.main, mainClassName, {
            [styles.withoutHeader]: hideHeader,
          })}
          data-testId="Header"
        >
          {children}
        </div>
      </div>
    </div>
  );
};
