import { bookingRoutes } from 'patientOnboarding/routes/absolutes';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { SpanButton } from 'lifestance-ui';

import styles from './NoAvailability.module.scss';

interface NoAvailabilityBoxProps {
  firstName: string;
  typeOfCare: string;
  emptyAppointment?: boolean;
}

export const NoAvailabilityBox: FC<NoAvailabilityBoxProps> = ({
  firstName,
  typeOfCare,
  emptyAppointment = false,
}) => {
  const navigate = useNavigate();
  const appendArticle = (toc: string) => {
    const regexp = /^[aeiou]\w*/i;
    const isVowel = regexp.test(toc);
    return isVowel ? `an ${toc}` : `a ${toc}`;
  };

  const message = emptyAppointment
    ? `${firstName} has no upcoming availability.`
    : `${firstName} has no upcoming availability for ${appendArticle(typeOfCare)} appointment.`;

  const navigateToMatch = () => {
    navigate(bookingRoutes.matchCriteria);
  };

  return (
    <div className={styles.container} data-testId="noAppointment">
      {message}
      <span style={{ cursor: 'pointer' }}>
        <SpanButton onClick={navigateToMatch}>Browse other providers</SpanButton>
      </span>
    </div>
  );
};
