import React, { ReactNode, MouseEvent } from 'react';

import clsx from 'clsx';
import styles from './IconButton.module.scss';

interface IconButtonProps {
  icon: ReactNode;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  selected: boolean;
  label: string;
  testId?: string;
}

export const IconButton = ({
  icon,
  onClick,
  selected,
  label,
  testId = '',
}: IconButtonProps) => (
  <div
    data-testId={`iconButton${testId}`}
    className={clsx(styles.button, {
      [styles.selected]: selected,
    })}
    onClick={onClick}
  >
    <div className={styles.icon}>
      {icon}
    </div>
    <label>{label}</label>
  </div>
);
