import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InsuranceData {
  zipCode: string;
  city: string;
  state: string;
  error: string | null;
  cards: any;
  insuranceSkipFlag: boolean;
  showCCOF: boolean;
  hideSkipCCOF: boolean;
  filters: any | null;
  heartlandApiKey: string;
}
const initialState: InsuranceData = {
  zipCode: '',
  city: '',
  state: '',
  error: null,
  cards: {},
  insuranceSkipFlag: true,
  showCCOF: false,
  hideSkipCCOF: false,
  filters: null,
  heartlandApiKey: '',
};

const insuranceSlice = createSlice({
  name: 'PO_insurance',
  initialState,
  reducers: {
    setAddressData: (state, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload,
    }),
    setInsuranceFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setInsuranceCards: (state, action: PayloadAction<any>) => {
      state.cards = {
        ...state.cards,
        ...action.payload,
      };
    },
    setInsuranceSkipFlag: (state, action: PayloadAction<boolean>) => {
      state.insuranceSkipFlag = action.payload;
    },
    setShowCCOF: (state, action: PayloadAction<boolean>) => {
      state.showCCOF = action.payload;
    },
    setHideSkipButtonCCOF: (state, action: PayloadAction<boolean>) => {
      state.hideSkipCCOF = action.payload;
    },
    setHeartlandApiKey: (state, action: PayloadAction<string>) => {
      state.heartlandApiKey = action.payload;
    },
    resetInsurance: () => initialState,
  },
});

export const {
  setError,
  setAddressData,
  setInsuranceFilters,
  setInsuranceCards,
  setInsuranceSkipFlag,
  setShowCCOF,
  setHideSkipButtonCCOF,
  setHeartlandApiKey,
  resetInsurance,
} = insuranceSlice.actions;

export default insuranceSlice.reducer;
