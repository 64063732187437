import clsx from 'clsx';
import { ReactComponent as CloseCircleIcon } from 'intakeOptimization/assets/svg/close-circle.svg';
import React, { useEffect, useRef, useState } from 'react';

import styles from './Input.module.scss';

interface InputProps {
  onChange: (value: string) => void;
  value?: string;
  testId?: string;
  disabled?: boolean;
  error?: string;
  onClear?: () => void;
  onClick?: (e: React.MouseEvent) => void;
  isFocus: boolean;
  placeholder?: string;
  numberOfOptions?: number;
  onClose: () => void;
  lastEventType?: string;
  onBlur?: (e: React.FocusEvent) => void;
  onFocus?: (e: React.FocusEvent) => void;
}

export const Input: React.FC<React.PropsWithChildren<InputProps>> = ({
  onChange,
  value,
  testId = '',
  disabled = false,
  error,
  onClear,
  onClick,
  isFocus,
  placeholder,
  numberOfOptions = 0,
  onClose,
  lastEventType,
  onBlur,
  onFocus,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  const [accessedViaKeyboard, setAccessedViaKeyboard] = useState(
    lastEventType === 'tab' || lastEventType === 'ArrowUp',
  );

  useEffect(() => {
    if (lastEventType === 'tab' || lastEventType === 'ArrowUp') {
      setAccessedViaKeyboard(true);
    } else {
      setAccessedViaKeyboard(false);
    }
  }, [lastEventType]);

  useEffect(() => {
    // activating the input type when isFocus is passed
    if (isFocus && inputRef) {
      inputRef.current?.focus();
    } else if (!isFocus && inputRef) {
      setAccessedViaKeyboard(false);
    }
  }, [isFocus, inputRef]);

  const handleInputFocus = (e: React.FocusEvent) => {
    setAccessedViaKeyboard(true);
    onFocus?.(e);
  };

  const handleNavigate = (event: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>) => {
    event.stopPropagation();
    switch (event.key) {
      case 'Escape':
        event.preventDefault();
        onClose();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className={styles.container}>
        <input
          ref={inputRef}
          data-cy={testId}
          data-testId={`input${testId}`}
          value={value}
          type="text"
          className={clsx(styles.input, {
            [styles.error]: error,
            [styles.tabbed]: accessedViaKeyboard,
          })}
          onChange={handleChange}
          disabled={disabled}
          onClick={onClick}
          autoFocus
          placeholder={placeholder}
          onKeyDown={handleNavigate}
          onBlur={onBlur}
          onFocus={handleInputFocus}
        />
        {value && value.length > 0 ? (
          <button
            type="button"
            aria-labelledby="clear-button"
            className={styles.clearButton}
            onClick={onClear}
          >
            <CloseCircleIcon />
          </button>
        ) : null}
      </div>
      {error && error.length && (
        <span data-testId={`input${testId}Error`} className={clsx({ [styles.error]: error })}>
          {error}
        </span>
      )}
    </>
  );
};
