import { LocationFilter } from 'intakeOptimization/@types';

import { LocationOptionFormatter } from './LocationOptionFormatter';

export const getLocationOptions = (locations: LocationFilter[]) => {
  const uniqueLocations = locations.reduce((accumulator: LocationFilter[], current) => {
    if (!accumulator.find((item) => item.facility_name === current.facility_name)) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);

  return uniqueLocations.map((item) => ({
    ...item,
    label: (
      <LocationOptionFormatter
        addressLine1={item.address_line1}
        city={item.city}
        distanceInMiles={item.distance_in_miles}
      />
    ),
  }));
};
