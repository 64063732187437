import { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './LoadingSpan.module.scss';

interface LoadingSpanProps {
  loading: boolean;
}

export const LoadingSpan: FC<PropsWithChildren<LoadingSpanProps>> = ({
  children,
  loading,
}) => (
  <div className={styles.container}>
    {loading && <div className={styles.loaderLine} />}
    <div className={!loading ? styles.content : styles.hide}>{children}</div>
  </div>
);
