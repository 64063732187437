import { genderFilters } from 'patientOnboarding/utils/genderFilters';

interface Option {
  label: string;
  value: string;
}

export const getGenderFilterValues = (values: string[]) => values?.reduce(
  (gender: any, curr) => {
    let updatedGender = [...gender];
    if (
      genderFilters[curr]
        && genderFilters[curr]?.length
        && !updatedGender?.join(',').includes(genderFilters[curr].join(','))
    ) {
      updatedGender = [...updatedGender, ...genderFilters[curr]];
    }
    return updatedGender;
  },
  [...values],
);

// eslint-disable-next-line max-len

export const getFocusFilterValues = (
  concerns: { options: Option[] }[],
  selectedFilters: string[],
) => {
  // eslint-disable-next-line no-shadow,max-len
  const getSelectedValuesByConcern = (concernType: number, selectedFilters: string[]) => concerns[concernType].options
    .filter((item: Option) => selectedFilters.includes(item.value))
    .map((item: Option) => (concernType === 1 ? encodeURIComponent(item.value) : item.value));
  const concernsValues = getSelectedValuesByConcern(0, selectedFilters);
  const populations = getSelectedValuesByConcern(1, selectedFilters);
  const interventions = getSelectedValuesByConcern(2, selectedFilters);
  return { concernsValues, populations, interventions };
};
