import clsx from 'clsx';
import { ReactComponent as ErrorWarning } from 'patientOnboarding/assets/svg/error-warning-line.svg';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBookingErrorModalShow } from 'patientOnboarding/store/reservationGlobal/actions';

import { SpanButton, ModalToast } from 'lifestance-ui';

import {
  contactInfo as contactInfoSelector,
  obiePreferencesData,
  selectedFacilityData,
} from 'patientOnboarding/selectors/selectors';

import { useAppointmentStatus, useInvoca } from 'patientOnboarding/hooks';

import {
  defaultPhoneNumber,
  extractTimes,
  formatContactNumber,
  getContactInfoByState,
} from 'patientOnboarding/utils';

import styles from './SomethingWrongModal.module.scss';


export const SomethingWrongModal: FC = () => {
  const contactInfo = useSelector(contactInfoSelector);
  const preferences = useSelector(obiePreferencesData);
  const facility = useSelector(selectedFacilityData);
  const dispatch = useDispatch();
  const state = facility?.state ? facility.state : preferences.state;
  const licenseKey = facility?.license_key ? facility?.license_key : preferences?.licenseKey;
  const contactInfoByState = getContactInfoByState(contactInfo, state, licenseKey as number);
  const contactNumber = preferences?.marketingReferralPhone.length > 0
    ? preferences?.marketingReferralPhone
    : contactInfoByState?.intake_call_in_number;
  const hasAppointmentData = useAppointmentStatus();
  const [serviceHours, setServiceHours] = useState<string | undefined>('')
  const times = extractTimes(serviceHours)
  useInvoca();

  useEffect(() => {
    if (window.Invoca) {
      window.Invoca.PNAPI.run();
    }
  }, [window]);

  useEffect(() => {
      setServiceHours(contactInfoByState?.support_hours)
  },[hasAppointmentData])

  return (
    <ModalToast
      onClose={() => {
        dispatch(setBookingErrorModalShow(false));
      }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.iconContainer}>
          <ErrorWarning />
        </div>
        <h1 className={styles.title}>Something went wrong</h1>
        <p className={styles.text}>
          We’re sorry but there was an unexpected error
          processing your booking. Please call
          <span className={styles.numberMobile}>
            <a
              className={clsx(styles.phone, 'invocaPhoneNumber')}
              href={`tel:${formatContactNumber(contactNumber)}`}
            >
              &nbsp;
              <br />
              <SpanButton className="invocaPhoneNumber">{contactNumber || defaultPhoneNumber}</SpanButton>
            </a>
          </span>
          <span className={clsx(styles.number, 'invocaPhoneNumber')}>
            {contactNumber || defaultPhoneNumber}
          </span>
          &nbsp; between  <br/>
          {times[0]} and {times[1]} to finish booking.
        </p>
      </div>
    </ModalToast>
  );
};
