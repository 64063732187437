const types = {
  GET_MATCHES: '@@PO_MATCHES/GET_MATCHES',
  LOAD_MATCHES: '@@PO_MATCHES/LOAD_MATCHES',
  GET_MORE_MATCHES: '@@PO_MATCHES/GET_MORE_MATCHES',
  LOAD_MORE_MATCHES: '@@PO_MATCHES/LOAD_MORE_MATCHES',
  SET_LOADING: '@@PO_MATCHES/SET_LOADING',
  SET_ERROR: '@@PO_MATCHES/SET_ERROR',
  SET_SEARCH_TERM: '@@PO_MATCHES/SET_SEARCH_TERM',
  GET_CLINICIAN_PROFILE: '@@PO_MATCHES/GET_CLINICIAN_PROFILE',
  SET_CLINICIAN: '@@PO_MATCHES/SET_CLINICIAN',
  LOAD_CLINICIAN_PROFILE: '@@PO_MATCHES/LOAD_CLINICIAN_PROFILE',
  RESET_MATCHES: '@@PO_MATCHES/RESET_MATCHES',
  GET_CLINICIAN_DETAILS: '@@PO_MATCHES/GET_CLINICIAN_DETAILS',
  SET_CLINICIAN_DETAILS: '@@PO_MATCHES/SET_CLINICIAN_DETAILS',
  GET_CLINICIAN_AVAILABILITIES: '@@PO_MATCHES/GET_CLINICIAN_AVAILABILITIES',
  SET_CLINICIAN_AVAILABILITIES: '@@PO_MATCHES/SET_CLINICIAN_AVAILABILITIES',
  SET_CLINICIAN_AVAILABLE_DATES: '@@PO_MATCHES/SET_CLINICIAN_AVAILABLE_DATES',
  SET_STICKY_MENU: '@@PO_MATCHES/SET_STICKY_MENU',
  GET_OTHER_PROVIDERS: '@@PO_MATCHES/GET_OTHER_PROVIDERS',
};

export default types;
