import { GlobalIdNameSelected, RSupportInfo } from 'intakeOptimization/@types';

export const createDatesFromTo = (dateStart: Date, dateEnd: Date) => {
  const datesResult = [];
  const secondsPerDay = 86400;
  const baseLimit = dateStart.getTime();
  const upperLimit = dateEnd.getTime();
  for (let ind = baseLimit; ind < upperLimit; ind += secondsPerDay) {
    const newDay = ind + secondsPerDay;
    datesResult.push(newDay);
  }
  return datesResult.map((elem: number) => new Date(elem));
};

export const removeDuplicates = (arr: GlobalIdNameSelected[]) => arr.filter(
  (value, index, a) => a.findIndex((innerValue) => innerValue.id === value.id) === index,
);

export const defaultPhoneNumber = '(Unavailable)';

export const disableBodyScroll = () => {
  document.body.style.overflow = 'hidden';
  document.documentElement.style.overflow = 'hidden';
};

export const enableBodyScroll = () => {
  document.body.style.overflow = 'initial';
  document.documentElement.style.overflow = 'initial';
};

export const disableMobileScroll = () => {
  document.body.style.position = 'fixed';
};

export const enableMobileScroll = () => {
  document.body.style.position = 'relative';
};

// Expects a host from the window.location object.
export const getEnvironment = (host: string) => {
  const hostComponents = host.split('.')[0];
  const subDomain = hostComponents.split('-');
  return subDomain[0];
};

export const getPhoneNumberbyState = (
  contactInfo: RSupportInfo[],
  state: string,
  licenseKey: number,
) => contactInfo.find((element) => element.state === state && element.license_key === licenseKey)
  ?.intake_call_in_number;
export const getContactInfoByState = (
  contactInfo: RSupportInfo[],
  state: string,
  licenseKey: number,
) => {
  if (contactInfo.length > 1) {
    return contactInfo.find(
      (element) => element.state === state && element.license_key === licenseKey,
    );
  }
  return contactInfo?.[0];
};

export const getPreviousPathname = (preferences: any): string => {
  if (preferences.path_history.length > 1) {
    return preferences.path_history[preferences.path_history.length - 2];
  }
  return '';
};

// isEqual method from lodash does not require update. Global application
export const isEqual = (obj1: any, obj2: any): boolean => {
  /**
   * More accurately check the type of a JavaScript object
   * @param  {Object} obj The object
   * @return {String}     The object type
   */
  const getType = (obj: any) => Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

  const areArraysEqual = () => {
    // Check length
    if (obj1.length !== obj2.length) return false;

    // Check each item in the array
    for (let i = 0; i < obj1.length; i += 1) {
      if (!isEqual(obj1[i], obj2[i])) return false;
    }

    // If no errors, return true
    return true;
  };

  const areObjectsEqual = () => {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

    // Check each item in the object
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj1) {
      if (Object.prototype.hasOwnProperty.call(obj1, key)) {
        if (!isEqual(obj1[key], obj2[key])) return false;
      }
    }

    // If no errors, return true
    return true;
  };

  const areFunctionsEqual = () => obj1.toString() === obj2.toString();

  const arePrimativesEqual = () => obj1 === obj2;

  // Get the object type
  const type = getType(obj1);

  // If the two items are not the same type, return false
  if (type !== getType(obj2)) return false;

  // Compare based on type
  if (type === 'array') return areArraysEqual();
  if (type === 'object') return areObjectsEqual();
  if (type === 'function') return areFunctionsEqual();
  return arePrimativesEqual();
};

export const convertObjectValuesToString = (obj: any) => {
  const stringBaseObject: any = {};
  Object.keys(obj).forEach((key) => {
    // Removes array and undesired characters for Gender values eg: Male|M
    // Allowed characters - /
    stringBaseObject[key] = obj[key].toString().replaceAll('|', ',');
  });
  return stringBaseObject;
};

export const hasVideoAvailability = (profile: any) => {
  let videoAvailabilities = 0;
  profile?.facility_location?.forEach((f: any) => {
    f.clinician_availabilities?.forEach((a: any) => {
      if (a.virtual_or_video_visit) {
        videoAvailabilities += 1;
      }
    });
  });
  if (videoAvailabilities > 0) {
    return true;
  }
  return false;
};

export const hasOfficeAvailability = (profile: any) => {
  let officeAvailability = 0;
  profile?.facility_location?.forEach((f: any) => {
    f.clinician_availabilities?.forEach((a: any) => {
      if (a.in_person_visit) {
        officeAvailability += 1;
      }
    });
  });
  if (officeAvailability > 0) {
    return true;
  }
  return false;
};
export const getValueByKey = (obj: any, key: string) => obj[key];

/**
 * @param {string} date ~ Date in YYYY-MM-DD format
 * @param {boolean} fullname ~ Returns the full name of the day if true (Monday / Mon)
 */
export const getWeekDay = (date: string, fullname = false) => {
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dateObj = new Date(date.replace(/-/g, '/'));
  return fullname ? weekday[dateObj.getDay()] : weekday[dateObj.getDay()].substring(0, 3);
};
