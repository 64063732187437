import { action } from 'typesafe-actions';

import t from './types';

export const setModalities = (modality) => action(t.UPDATE_MODALITIES, modality);
export const getModalities = (id) => action(t.VIEW_CLINICIAN_MODALITIES, id);
export const getLocations = (id) => action(t.VIEW_CLINICIAN_LOCATIONS, id);
export const setLocations = (location) => action(t.UPDATE_CLINICIAN_LOCATIONS, location);
export const updatePatient = (patient) => action(t.UPDATE_PATIENT, patient);

export const getPatientInsuranceInfo = (id) => action(t.GET_PATIENT_INSURANCE_INFO, id);
export const setPatientInsuranceInfo = (insuranceInfo) => action(t.SET_PATIENT_INSURANCE_INFO, insuranceInfo);
export const updateInsuranceInfo = (insuranceInfo) => action(t.UPDATE_PATIENT_INSURANCE_INFO, insuranceInfo);

export const createAppointment = (appointmentInfo) => action(t.CREATE_APPOINTMENT, appointmentInfo);
