import React from 'react';

import { ReactComponent as HospitalIcon } from 'lifestance-ui/assets/svg/hospital-line.svg';
import { ReactComponent as PhoneIcon } from 'lifestance-ui/assets/svg/phone-line.svg';
import { ReactComponent as SmartphoneIcon } from 'lifestance-ui/assets/svg/smartphone-line.svg';

import styles from './EmergencyResources.module.scss';

export const EmergencyResources = () => (
  <div>
    <div className={styles.infoContainer}>
      <div className={styles.bullet}>
        <HospitalIcon />
        <p className={styles.header}>Emergency department</p>
        <p className={styles.text}>Call 911 or head to the nearest hospital emergency department</p>
      </div>
      <div className={styles.bullet}>
        <PhoneIcon />

        <p className={styles.header}>National Suicide Prevention Lifeline</p>
        <p className={styles.text}>
          Reach out at
          {' '}
          <a href="tel:18002738255">
            <span className={styles.bold}>1-800-273-8255</span>
          </a>
        </p>
      </div>
      <div className={styles.bullet}>
        <SmartphoneIcon />
        <p className={styles.header}>Crisis Text Line </p>
        <p className={styles.text}>
          Text “HOME” to
          {' '}
          <span className={styles.bold}>
            {' '}
            <a href="sms:741741&body=HOME">741741</a>
          </span>
        </p>
      </div>
    </div>
  </div>
);
