import { useEffect, useRef, useState } from 'react';

export const useInvoca = (display = true) => {
  const mounted = useRef(false);
  const [invocaAPIExists, setInvocaAPIExists] = useState(Boolean(window.Invoca));

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  // Improves the control over invoca dependency so to make sure all conditions are met before running the API
  useEffect(() => {
    const interval = setInterval(() => {
      if ('Invoca' in window) {
        setInvocaAPIExists(true);
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (mounted.current && display && invocaAPIExists) {
      window.Invoca.PNAPI.run();
    }
  }, [mounted.current, display, invocaAPIExists]);
};
