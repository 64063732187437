import { bookingRoutes } from 'patientOnboarding/routes/constants';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './ListCardSkeleton.module.scss';

export const ListCardSkeleton = () => (
  <div className={styles.card}>
    <div className={styles.resultDetails}>
      <div className={styles.resultThumbnail}>
        <div className={styles.animation} />
      </div>
      <div className={styles.resultInfo}>
        <div className={styles.tag}>
          <div className={styles.animation} />
        </div>
        <div className={styles.resultTitle}>
          <div className={styles.animation} />
        </div>
        <div className={styles.resultDescription}>
          <div className={styles.animation} />
        </div>
        <div className={styles.resultAvailability}>
          <div className={styles.animation} />
        </div>
        <div className={styles.buttons}>
          <div className={styles.buttonDate}>
            <div className={styles.animation} />
          </div>
          <div className={styles.buttonDate}>
            <div className={styles.animation} />
          </div>
          <div className={styles.buttonDate}>
            <div className={styles.animation} />
          </div>
        </div>
        <div className={styles.resultLinkMobile}>
          <div className={styles.animation} />
        </div>
      </div>
    </div>
    <div className={styles.resultExtra}>
      <div className={styles.resultAddress}>
        <div className={styles.adressLine}>
          <div className={styles.animation} />
        </div>
        <div className={styles.adressLine}>
          <div className={styles.animation} />
        </div>
        <div className={styles.adressLine}>
          <div className={styles.animation} />
        </div>
      </div>
      <Link to={bookingRoutes.clinicianProfile}>
        <div className={styles.resultLink}>
          <div className={styles.animation} />
        </div>
      </Link>
    </div>
    <div className={styles.availabilityMobile}>
      <div className={styles.buttonDate}>
        <div className={styles.animation} />
      </div>
      <div className={styles.buttonDate}>
        <div className={styles.animation} />
      </div>
      <div className={styles.buttonDate}>
        <div className={styles.animation} />
      </div>
    </div>
  </div>
);
