import { intakeRoutes as absoluteIntakeRoutes } from 'patientOnboarding/routes/absolutes';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Step } from 'lifestance-ui/ProgressBar/types';

import { ProgressBar } from 'lifestance-ui';

import { obiePreferencesData, obieShowCCOF } from 'patientOnboarding/selectors/selectors';

import {
  BILLING_SUBSTEPS,
  INSURANCE_BILLING_CCOF_SUBSTEPS,
  INSURANCE_BILLING_SUBSTEPS,
  INTAKE_STEPS,
  INTAKE_STEPS_NO_CC_NO_INSURANCE,
  NO_CCOF_NO_INSURANCE_SUBSTEPS,
} from './steps';

export function IntakeProgressBar(): ReactElement {
  const location = useLocation();

  const { paymentMethod } = useSelector(obiePreferencesData);
  const isCreditCardOnFileEnabled = useSelector(obieShowCCOF);

  const insuranceAndBillingRoutes = isCreditCardOnFileEnabled
    ? INSURANCE_BILLING_CCOF_SUBSTEPS
    : INSURANCE_BILLING_SUBSTEPS;
  const billingRoutes = isCreditCardOnFileEnabled ? BILLING_SUBSTEPS : [];
  const billingSubsteps = paymentMethod === 'self_pay' ? billingRoutes : insuranceAndBillingRoutes;
  const isSelfPayAndNoCC = paymentMethod === 'self_pay' && !isCreditCardOnFileEnabled;
  const isSelfPay = paymentMethod === 'self_pay';

  const intakeSteps = INTAKE_STEPS.map((e) => e.number === 2 && isSelfPay ? { ...e, title: 'Billing' } : e);

  const stepNumber = () => {
    switch (location.pathname) {
      case absoluteIntakeRoutes.register:
      case absoluteIntakeRoutes.account:
      case absoluteIntakeRoutes.demographics:
      case absoluteIntakeRoutes.reason:
      case absoluteIntakeRoutes.address:
      case absoluteIntakeRoutes.emergencyContact:
      case absoluteIntakeRoutes.userExists:
        return 1;
      case absoluteIntakeRoutes.insuranceDetails:
      case absoluteIntakeRoutes.insuranceCard:
      case absoluteIntakeRoutes.paymentMethod:
        return 2;
      case absoluteIntakeRoutes.review:
      case absoluteIntakeRoutes.confirmation:
        return 3;
      default:
        return 1;
    }
  };

  let currentStep;
  if (isSelfPayAndNoCC) {
    const realStepNumber = stepNumber() === 3 ? 2 : stepNumber();
    currentStep = INTAKE_STEPS_NO_CC_NO_INSURANCE.find(
      (step) => step.number === realStepNumber,
    ) as Step;
  } else {
    currentStep = intakeSteps.find((step) => step.number === stepNumber()) as Step;
  }

  if (currentStep.number === 2 && isSelfPayAndNoCC) {
    currentStep.subSteps = NO_CCOF_NO_INSURANCE_SUBSTEPS;
  } else if (currentStep?.number === 2) {
    currentStep.subSteps = billingSubsteps;
  }

  currentStep.currentSubStep = currentStep
    ? currentStep?.subSteps?.find(
      (subStep) => `/find-care/intake${subStep.link}` === location.pathname,
    )
    : null;

  return (
    <ProgressBar
      steps={isSelfPayAndNoCC ? INTAKE_STEPS_NO_CC_NO_INSURANCE : intakeSteps}
      step={currentStep as Step}
      paymentMethod={paymentMethod}
    />
  );
}
