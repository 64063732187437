export const onClickOut = ({ triggerRef, action, ignoreParent = false }) => {
  const handleClickOutside = (event) => {
    const triggerOnParentClick = ignoreParent && triggerRef.current?.parentElement?.contains(event.target);
    if (
      triggerRef.current
      && (triggerOnParentClick || !triggerRef.current?.parentElement?.contains(event.target))
      && !triggerRef.current?.contains(event.target)
    ) {
      action();
    }
  };
  const handleEscape = (event) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      action();
    }
  };

  document.addEventListener('click', handleClickOutside, { capture: true });
  document.addEventListener('keydown', handleEscape);

  return () => {
    document.removeEventListener('click', handleClickOutside);
    document.removeEventListener('keydown', handleEscape);
  };
};
