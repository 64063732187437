import React from 'react';

import { ABIEFormOptions } from 'intakeOptimization/@types';
import { IOption, IOptionsList } from './types';

export const highlightText = (text: string, searchString: string, className: string) => {
  const lowerText = text.toLowerCase();
  const lowerSearchString = searchString.toLowerCase();
  const index = lowerText.indexOf(lowerSearchString);

  if (index === -1) {
    return <span>{text}</span>;
  }

  const before = text.substring(0, index);
  const highlight = text.substring(index, index + searchString.length);
  const after = text.substring(index + searchString.length);

  return (
    <span>
      {before}
      <strong className={className}>{highlight}</strong>
      {after}
    </span>
  );
};

export const transformOptionsList = (
  query: string,
  optionsLists: IOptionsList[],
  className: string,
) => query !== ''
  ? [...optionsLists]
    .map((optionsList) => ({
      ...optionsList,
      options: optionsList.options.filter((option) => (option.label as string).toLowerCase().includes(query.toLowerCase())),
    }))
    .filter((optionsList) => optionsList.options.length > 0)
    .map((optionsList) => ({
      ...optionsList,
      options: optionsList.options.map((option) => ({
        ...option,
        label: highlightText(option.label as string, query, className),
      })),
    }))
  : optionsLists;

export const transformSingleOptionsList = (
  query: string,
  optionsLists: IOption[],
  className: string,
) => {
  if (query !== '') {
    const newOptions: IOption[] = [...(optionsLists as ABIEFormOptions[])]
      .filter((option) => option.label.toLowerCase().includes(query.toLowerCase()))
      .map((option) => ({
        ...option,
        label: highlightText(option.label as string, query, className),
      }));
    return newOptions;
  }
  return optionsLists;
};
