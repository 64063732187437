import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as CheckIcon } from 'lifestance-ui/assets/svg/check-line.svg';

import styles from '../ProgressBar.module.scss';

export const CompletedStep = ({ step, testId = 'progressBar', paymentMethod, numberOfSteps }) => {
  const [isClickable, setIsClickable] = useState(false);

  useEffect(() => {
    if (paymentMethod === 'self_pay' && step.title === 'Insurance & Billing') {
      setIsClickable(true);
    }
  }, [paymentMethod]);

  return (
    <div
      data-testid={testId}
      className={clsx(styles.step, styles.step_completed, {
        [styles.oneThird]: numberOfSteps === 3,
        [styles.oneHalf]: numberOfSteps === 2,
      })}
      style={{ pointerEvents: isClickable ? 'none' : '' }}
    >
      <div className={styles.step_progress} />
      <div data-testid={`${testId}Number`} className={styles.step_number}>
        <div className={styles.filled_circle}>
          <CheckIcon />
        </div>
      </div>
      <div className={styles.step_title}>{step.title}</div>
    </div>
  );
};
