const types = {
  VIEW_CLINICIAN_MODALITIES: '@@PO_clinician/VIEW_CLINICIAN_MODALITIES',
  UPDATE_MODALITIES: '@@PO_clinician/UPDATE_MODALITIES',
  VIEW_CLINICIAN_LOCATIONS: '@@PO_clinician/VIEW_CLINICIAN_LOCATIONS',
  UPDATE_CLINICIAN_LOCATIONS: '@@PO_clinician/UPDATE_CLINICIAN_LOCATIONS',
  UPDATE_PATIENT: '@@PO_patient/UPDATE_PATIENT',
  GET_PATIENT_INSURANCE_INFO: '@@PO_patient/GET_PATIENT_INSURANCE_INFO',
  SET_PATIENT_INSURANCE_INFO: '@@PO_patient/SET_PATIENT_INSURANCE_INFO',
  UPDATE_PATIENT_INSURANCE_INFO: '@@PO_patient/UPDATE_PATIENT_INSURANCE_INFO',
  CREATE_APPOINTMENT: '@@PO_patient/CREATE_APPOINTMENT',
};

export default types;
