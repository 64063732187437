/* eslint-disable no-param-reassign */
import * as API from 'intakeOptimization/api/legacy';
import * as ABIE_API_V2 from 'intakeOptimization/api/v2/abie';
import * as Effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, put, takeLatest } from 'redux-saga/effects';

import { updateReservation } from 'intakeOptimization/store/reservationGlobal/actions';

import { getLegacyQueryString } from 'intakeOptimization/utils';

import { getTimezoneOffset } from 'lifestance-ui/utils';
import {
  loadClinicianProfile,
  loadMatches,
  loadMoreMatches,
  setClinician,
  setClinicianAvailabilities,
  setClinicianAvailableDates,
  setError,
  setLoading,
  setLoadingAvailabilities,
  setLoadingMoreClinicians,
  setLoadingProfile,
  setOtherProviders,
} from './actions';
import t from './types';

const call: any = Effects.call;

function* handleGetMatches(action: any): any {
  try {
    yield put(setLoading(true));
    // TODO error to another place
    delete action.payload.preferences.errors;
    const preferences: any = {};

    for (const [key, value] of Object.entries(action.payload.preferences)) {
      if (Array.isArray(value) && value.length === 1 && key !== 'license_keys') {
        preferences[key] = value.toString();
      } else if (key === 'special_cases' && value === 'None of the above') {
        // Do nothing
      } else {
        preferences[key] = value;
      }
    }

    const queryString = getLegacyQueryString(preferences);
    const appName = action.payload.app_name;
    const response = yield call(API.getMatches, {
      preferences: queryString,
      page: action.payload.page,
      app_name: appName,
    });
    if (response && action.payload.page) {
      yield put(loadMoreMatches(response.data));
    } else {
      yield put(loadMatches({ ...response.data, page: 1 }));
    }
    yield put(setLoading(false));
  } catch (e: any) {
    if (Array.isArray(e.response.data)) {
      yield put(loadMatches(e.response.data));
    }
    yield put(setLoading(false));
    yield put(setError(e.response?.status));
  }
}

function* handleGetMoreMatches(action: any): any {
  try {
    // yield put(setLoading(true));
    yield put(setLoadingMoreClinicians(true));
    delete action.payload.preferences.errors;
    const preferences: any = {};
    for (const [key, value] of Object.entries(action.payload.preferences)) {
      if (Array.isArray(value) && value.length === 1) {
        preferences[key] = value.toString();
      } else if (key === 'special_cases' && value === 'None of the above') {
        // Do nothing
      } else {
        preferences[key] = value;
      }
    }
    const queryString = getLegacyQueryString(preferences);

    const { page, app_name } = action.payload;
    const response = yield call(API.getMatches, {
      preferences: queryString,
      page,
      app_name,
    });
    if (response) {
      yield put(loadMoreMatches(response.data));
    }
    yield put(setLoadingMoreClinicians(false));
  } catch (e: any) {
    yield put(setLoading(false));
    yield put(setLoadingMoreClinicians(false));
    yield put(setError(e.response.status));
  }
}

function* handleClinicianProfile(action: any): any {
  yield put(setLoadingProfile(true));
  yield put(setClinicianAvailableDates([]));
  yield put(setClinicianAvailabilities([]));

  try {
    const { id, typeOfCare, zipCode, appName } = action.payload;
    yield put(setLoading(true));
    const response = yield call(ABIE_API_V2.getClinicianProfile, {
      id,
      typeOfCare,
      zipCode,
      appName,
    });
    if (response) {
      yield put(setLoadingProfile(false));
      yield put(setLoading(false));
      yield put(loadClinicianProfile(response.data));
      yield put(setClinician(true));
      yield put(
        updateReservation({
          clinician: response.data,
        }),
      );
    }
  } catch (e: any) {
    yield put(setLoadingProfile(false));
    yield put(setClinician(false));
    yield put(setLoading(false));
    yield put(setError(e.response?.status));
  }
}

function* handleGetOtherProvidersForClinician(action: PayloadAction<{ id: string }>): any {
  yield put(setClinicianAvailableDates([]));
  yield put(setClinicianAvailabilities([]));
  try {
    const { id } = action.payload;
    const response = yield call(ABIE_API_V2.getClinicianProfileOtherProviders, { id });
    yield put(setOtherProviders(response.data.clinicians));
  } catch (e: any) {
    yield put(setError(e.response?.status));
  }
}

function* handleClinicianAvailabilities(action: any): any {
  try {
    yield put(setLoadingAvailabilities(true));
    yield put(setClinicianAvailableDates([]));
    const videoOption = action.payload.videoOption || false;
    const requestBody = videoOption
      ? {
        facility_id: action.payload.facilityIds[0],
        clinician_id: action.payload.clinicianId,
        available_date: action.payload.availableDate,
        type_of_cares: action.payload.typeOfCare,
        video: true,
        offset: getTimezoneOffset(),
      }
      : {
        facility_id: action.payload.facilityIds[0],
        facility_ids: action.payload.facilityIds,
        clinician_id: action.payload.clinicianId,
        available_date: action.payload.availableDate,
        type_of_cares: action.payload.typeOfCare,
        offset: getTimezoneOffset(),
      };
    const response = yield call(ABIE_API_V2.getClinicianAvailabilities, requestBody);
    if (response) {
      const data = response.data.clinician_availabilities;
      const availableDates = response.data.meta?.clinician_availability_dates?.map(
        (date: string) => {
          const now = new Date(date);
          const localDate = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
          return localDate;
        },
      );
      const sortedAvailableDates = availableDates.sort(
        (a: Date, b: Date) => a.getTime() - b.getTime(),
      );
      yield put(setClinicianAvailableDates(sortedAvailableDates));
      yield put(setLoadingAvailabilities(false));
      yield put(setClinicianAvailabilities(data));
      yield put(setClinician(true));
    }
  } catch (e: any) {
    yield put(setClinician(false));
    yield put(setLoading(false));
    yield put(setLoadingAvailabilities(true));
    yield put(setError(e.response?.status));
  }
}

function* matchesSaga(): any {
  yield all([
    yield takeLatest(t.GET_MATCHES, handleGetMatches),
    yield takeLatest(t.GET_MORE_MATCHES, handleGetMoreMatches),
    yield takeLatest(t.GET_CLINICIAN_PROFILE, handleClinicianProfile),
    yield takeLatest(t.GET_CLINICIAN_AVAILABILITIES, handleClinicianAvailabilities),
    yield takeLatest(t.GET_OTHER_PROVIDERS, handleGetOtherProvidersForClinician),
  ]);
}

export default matchesSaga;
