import React, { ReactElement, ReactNode, SyntheticEvent } from 'react';

interface Props {
  children: ReactNode;
  className: string;
}

export function DragSafeContainer({ children, className }: Props): ReactElement {
  function doNothing(event: SyntheticEvent) {
    event.preventDefault();
  }

  return (
    <div className={className} onDrop={doNothing} onDragOver={doNothing}>
      {children}
    </div>
  );
}
