import clsx from 'clsx';
import { bookingRoutes } from 'patientOnboarding/routes/constants';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

import {
  Button,
  Dropdown,
  DropdownSingleSelect,
  Input,
  InputMask,
  Label,
  SpanButton,
  Toast,
} from 'lifestance-ui';

import { getClinicians } from 'patientOnboarding/store/cliniciansGlobal/slice';
import {
  getCliniciansByZipCode,
  setErrors,
  setLoadingCountByZip,
  setValidZipCode,
  updatePreferences,
  validateZipCode,
} from 'patientOnboarding/store/preferences/actions';

import {
  contactInfo as contactInfoSelector,
  lifestanceState,
  obieEnabled,
  obiePreferencesData,
} from 'patientOnboarding/selectors/selectors';

import { useInsuranceValidation } from 'patientOnboarding/hooks';

import {
  SELF_PAY_VALUE,
  cleanNullProperties,
  genderFilters,
  getAge,
  getContactInfoByState,
  getDateInfo,
  getFormatForMatches,
  licenseKeys,
  stateAbbrToName,
  useDevice,
  validateDateStringFormat,
} from 'patientOnboarding/utils';
import { getGenderFilterValues } from 'patientOnboarding/views/ProviderMatches/utils';

import { NoProviders } from '../NoProvidersBox/NoProvidersBox';
import styles from './ProviderWall.module.scss';

interface IProviderWallProps {
  handleExisting?(message: string): boolean;
  handleOpen(): any;
  onContinue?: () => void;
}

export const ProviderWall: FC<IProviderWallProps> = ({
  handleExisting,
  handleOpen,
  onContinue,
}) => {
  const preferences = useSelector(obiePreferencesData);
  const contactInfo = useSelector(contactInfoSelector);
  const { loading, zipCode, isZipCodeValid } = preferences;
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const [ageError, setAgeError] = useState(false);
  const [ageErrorMessage, setAgeErrorMessage] = useState('');
  const [patientDOB, setPatientDOB] = useState(preferences.preliminaryDOB);
  const [showForm, setShowForm] = useState(true);
  const [mockLoading, setMockLoading] = useState(false);
  const [continueTouched, setContinueTouched] = useState(false);

  const [formZipCode, setFormZipCode] = useState<string>(zipCode);

  const isChild = preferences.typeOfCare.toLowerCase().includes('child');

  const [formFitContent, setFormFitContent] = useState(false);

  const state = preferences.state;
  const licenseKey = preferences.licenseKey;
  const contactInfoByState = getContactInfoByState(contactInfo, state, licenseKey);
  const contactNumber = contactInfoByState?.intake_call_in_number;
  const enabledObie = useSelector(obieEnabled);
  const [information, setInformation] = useState(true);
  const { errors } = preferences;
  const enabledState = useSelector(lifestanceState);
  const [lifestanceInfo, setLifestanceInfo] = useState(true);
  const [showMessage, setShowMessage] = useState('');

  const { city, cliniciansAvailableOnZipCode, loadingCountByZip } = useSelector(obiePreferencesData);

  const { insuranceOpts, isInsuranceValidOnState } = useInsuranceValidation({
    updatedPreferences: preferences,
    clinicianState: state,
  });

  useEffect(() => {
    if (formZipCode.length === 5) {
      dispatch(setValidZipCode(true));
    } else {
      setShowForm(false);
      dispatch(setValidZipCode(false));
    }
  }, [formZipCode]);

  useEffect(() => {
    if (zipCode.length === 5 && errors.zipCode.length === 0 && !loading && !loadingCountByZip) {
      if (enabledState && enabledObie && cliniciansAvailableOnZipCode === 0) {
        setShowMessage('No providers');
      } else if (enabledState && !enabledObie) {
        setShowMessage('No OBIE');
      } else if (!enabledState) {
        setShowMessage('No LS');
      } else {
        setShowMessage('');
      }
    } else if (errors.zipCode.length > 0) {
      setShowMessage('');
    }
  }, [loading, loadingCountByZip]);

  useEffect(() => {
    dispatch(validateZipCode(formZipCode));
    dispatch(getCliniciansByZipCode(formZipCode));
  }, []);

  useEffect(() => {
    if ((!showForm && !isZipCodeValid) || zipCode.length !== 5) {
      dispatch(
        updatePreferences({
          ...preferences,
          typeOfCare: '',
          insuranceCompany: '',
          paymentMethod: '',
          preliminaryAge: '',
          preliminaryDOB: '',
        }),
      );
      setAgeError(false);
      setPatientDOB('');
    }
  }, [preferences.zipCode, showForm]);

  const readZipcode = (value: string) => {
    setFormZipCode(value);
    setContinueTouched(false);
    setErrorMessage('');
    if (value.length === 5) {
      dispatch(validateZipCode(value));
      dispatch(getCliniciansByZipCode(value));
      setInformation(true);
      dispatch(setErrors({ zipCode: '' }));
    }
    if (value.length < 5) {
      setInformation(false);
      setLifestanceInfo(false);
      setShowMessage('');
    }
  };

  const handleBlur = () => {
    if (formZipCode.length < 5) {
      setErrorMessage('Enter a valid 5 digit zip code');
    }
  };

  useEffect(() => {
    if (errors.zipCode) {
      setErrorMessage(errors.zipCode);
      setInformation(false);
      setLifestanceInfo(false);
    }
  }, [errors.zipCode]);

  const handleContinue = () => {
    if (formZipCode.length === 5) {
      dispatch(validateZipCode(formZipCode));
      dispatch(updatePreferences({ zipCode: formZipCode }));
      setMockLoading(true);
      setTimeout(() => {
        setMockLoading(false);
        setShowForm(true);
      }, 2000);
    }
  };

  const handleInsuranceCompany = (insuranceCompany: string) => {
    const preferenceToUpdate = insuranceCompany === SELF_PAY_VALUE
      ? { paymentMethod: insuranceCompany, insuranceCompany }
      : { paymentMethod: 'insurance', insuranceCompany };
    dispatch(
      updatePreferences({
        ...preferenceToUpdate,
      }),
    );
  };

  const handleTypeOfCare = (typeOfCare: string) => {
    dispatch(updatePreferences({ typeOfCare }));
  };

  const handleViewMatches = () => {
    onContinue?.();
    setContinueTouched(true);
    if (isZipCodeValid && preferences.zipCode.length === 5 && showForm) {
      handleExisting?.(errorMessage);
      dispatch(
        updatePreferences({
          ...preferences,
          hasValidZipCode: true,
          errors: { zipCode: '' },
        }),
      );
      const preferencesForMatches = getFormatForMatches(preferences);
      const genderValues = [
        { gender: 'Male', values: genderFilters.Male },
        { gender: 'Female', values: genderFilters.Female },
        { gender: 'Non-binary', values: genderFilters.Them },
      ];
      const selectedGender = preferencesForMatches.gender === 'Non-binary'
        ? [preferencesForMatches.gender]
        : genderValues
          ?.filter(({ values }) => (preferencesForMatches.gender as string[])?.join(',').includes(values?.join(',')))
          .map(({ values }) => values)
          .flat();
      const gender = getGenderFilterValues(selectedGender).join('|');
      const cleanedFilters = cleanNullProperties({ ...preferencesForMatches, gender });

      const queryString = Object.keys(cleanedFilters)
        .map((key) => `${key}=${encodeURIComponent(cleanedFilters[key])}`)
        .join('&');
      navigate({
        pathname: bookingRoutes.providerMatches,
        search: queryString,
      });

      dispatch(
        getClinicians({
          activeFilters: cleanedFilters,
        }),
      );
      handleOpen();
    } else {
      handleContinue();
    }
  };

  const handleAgeBlur = () => {
    const DOB = patientDOB;
    const { month, day, year } = getDateInfo(DOB);
    const currentAge = getAge(year, month, day);

    if (currentAge < 3) {
      setAgeError(true);
      setAgeErrorMessage(
        'Our clinicians are trained to care for children 3 years old and up. Please call us if you have any questions.',
      );
    } else if (!validateDateStringFormat(DOB)) {
      setAgeError(true);
      setAgeErrorMessage('Please enter a valid date of birth');
    } else if (isChild && currentAge >= 18) {
      setAgeError(true);
      setAgeErrorMessage('For anyone over 18 years old, please select an adult appointment type.');
    } else if (!isChild && currentAge < 18) {
      setAgeError(true);
      setAgeErrorMessage('For anyone under 18 years old, please select a child appointment type.');
    } else {
      setAgeErrorMessage('');
      setAgeError(false);

      dispatch(
        updatePreferences({
          age: currentAge.toString(),
          preliminaryAge: currentAge.toString(),
          preliminaryDOB: DOB,
        }),
      );
    }
  };

  useEffect(() => {
    // We observe the Type Of Care to check if the DOB is correct
    if (patientDOB.length === 10) {
      handleAgeBlur();
    }
  }, [preferences.typeOfCare]);

  const handleAgeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPatientDOB(e.target.value);
  };

  const transitionLongForm = useSpring<never>({
    from: { opacity: 0, height: 0, overflow: 'hidden' },
    to: { opacity: 1, height: isMobile ? 500 : 300, overflow: 'visible' },
    reverse: !showForm,
    config: { tension: 180, friction: 14 },
  });

  useEffect(() => {
    if (showForm) {
      // We need to add the questionsContainer class to the form
      // so the form can grow when the error messages appear. (When the animation ends)
      setTimeout(() => {
        setFormFitContent(true);
      }, 1000);
    } else {
      setFormFitContent(false);
      setAgeErrorMessage('');
      setAgeError(false);
    }
  }, [showForm]);

  const validateOptions = () => {
    if ((showForm && ageError) || (!showForm && !isZipCodeValid)) {
      return true;
    }
    if (
      showForm
      && preferences.typeOfCare.length === 0
      && preferences.insuranceCompany.length === 0
    ) {
      return true;
    }
    if ((showForm && patientDOB.length === 0) || patientDOB === '__/__/____') {
      return true;
    }
    return showForm && preferences.paymentMethod === '';
  };

  const renderForm = () => (
    <>
      <div>
        <div className={styles.title}>What is your zip code?</div>
        <Input
          type="zipCode"
          placeholder="e.g. 12345"
          onChange={readZipcode}
          error={errorMessage}
          value={formZipCode}
          defaultValue={preferences.zipCode}
          onBlur={handleBlur}
          onFocus={() => {
            setErrorMessage('');
          }}
        />
      </div>
      {isZipCodeValid && preferences?.filters?.typesOfCare.length !== 0 ? (
        <animated.div
          style={transitionLongForm}
          className={clsx({
            [styles.questionsContainer]: formFitContent,
          })}
        >
          <div>
            <div className={styles.questions}>
              <Label>What type of care are you looking for?</Label>
              <a
                href="http://www.lifestance.com/services"
                target="_blank"
                rel="noreferrer"
                className={styles.unsure}
                style={{ textDecoration: 'none' }}
              >
                <SpanButton>Not sure</SpanButton>
              </a>
            </div>
            {isMobile ? (
              <Dropdown
                options={preferences.filters?.typesOfCare}
                placeholder="Select an option"
                onChange={handleTypeOfCare}
                title="Type of care"
                icon="ArrowBlackIcon"
                value={preferences.typeOfCare}
                sideWays
                fullHeight
              />
            ) : (
              <DropdownSingleSelect
                options={preferences.filters?.typesOfCare}
                placeholder="Select an option"
                onChange={handleTypeOfCare}
                value={preferences.typeOfCare}
              />
            )}
            <div className={styles.questions}>
              <Label>What is the patient&apos;s date of birth?</Label>
            </div>
            <div>
              <InputMask
                tabIndex={0}
                data-testId="inputDateOfBirth"
                mask="99/99/9999"
                placeholder="MM/DD/YYYY"
                error={ageErrorMessage}
                onChange={handleAgeChange}
                onBlur={handleAgeBlur}
                value={patientDOB}
                inputMode="numeric"
              />
            </div>
            <div className={styles.questions}>
              <Label>How would you like to pay?</Label>
            </div>
            {isMobile ? (
              <Dropdown
                options={insuranceOpts}
                placeholder="Select an option or self-pay"
                onChange={handleInsuranceCompany}
                title="Insurance"
                value={preferences.insuranceCompany || preferences.paymentMethod}
                sideWays
                fullHeight
              />
            ) : (
              <DropdownSingleSelect
                options={insuranceOpts}
                placeholder="Select an option or self-pay"
                onChange={handleInsuranceCompany}
                value={preferences.insuranceCompany || preferences.paymentMethod}
              />
            )}
            <Toast
              variant="information"
              label={`Do you have ${preferences.insuranceCompany} Medicaid?`}
              description={`We are not currently accepting Medicaid benefits in ${stateAbbrToName(
                state,
              )}.`}
              containerClassName={clsx(styles.toast, {
                [styles.showToast]: !isInsuranceValidOnState,
              })}
            />
          </div>
        </animated.div>
      ) : null}
      {showMessage === 'No providers' && !loading && (
        <NoProviders
          state={state}
          showForm={!showForm}
          phoneNumber={contactNumber}
          enabledState={enabledState}
          enabledObie={enabledObie}
        />
      )}
      {showMessage === 'No OBIE' && !loading && (
        <NoProviders
          state={state}
          showForm={!showForm}
          phoneNumber={contactNumber}
          enabledState={enabledState}
          enabledObie={enabledObie}
        />
      )}
      {showMessage === 'No LS' && !loading && (
        <NoProviders
          state={state}
          showForm={!showForm}
          phoneNumber={contactNumber}
          enabledObie={enabledObie}
          enabledState={enabledState}
        />
      )}
    </>
  );

  return (
    <div className={clsx(styles.container, styles.scrollableContainer)}>
      <div className={styles.header} data-testId="title">
        New here? Let’s match you
        <br />
        with the right provider.
      </div>
      <div className={styles.subHeader}>
        If you’ve seen a LifeStance supported provider before,
        <a
          className={styles.link}
          href={`https://patientportal.advancedmd.com/${
            licenseKeys[city] || '140098'
          }/account/logon`}
          target="_blank"
          rel="noreferrer"
        >
          {' '}
          login to your portal.
        </a>
      </div>
      {renderForm()}
      {showMessage !== '' ? null : (
        <div className={styles.button}>
          <Button
            fullWidth
            onClick={handleViewMatches}
            disabled={validateOptions()}
            isLoading={loading || mockLoading}
          >
            Continue
          </Button>
        </div>
      )}
    </div>
  );
};
