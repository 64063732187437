import React, { FC, useEffect, useState } from 'react';

import { useResizingMediaQuery } from 'intakeOptimization/hooks';

import { cleanAvailability } from 'intakeOptimization/utils';

import { FilterABIE as Filter } from '../FilterAdmin/Filter';
import { MobileFiltersAdmin as MobileFilters } from '../MobileFiltersAdmin/MobileFilters';
import styles from './SearchFilters.module.scss';

interface ISearchFiltersProps {
  filters: any;
  onChange: (name: string, ...value: any[]) => void;
  defaultValues: any;
  isAdmin?: boolean;
  onOpen(): void;
  onClose(): void;
  showMobileFilters: boolean;
  clinicianCount: number;
  isLoading: boolean;
}

export const SearchFiltersABIE: FC<ISearchFiltersProps> = ({
  filters,
  onChange,
  defaultValues,
  isAdmin = false,
  onClose,
  onOpen,
  showMobileFilters = true,
  clinicianCount,
  isLoading,
}) => {
  const buttonText = clinicianCount > 0 ? `See ${clinicianCount} results` : 'No exact matches';
  const forceNewData = (values: any) => {
    if (Array.isArray(values)) {
      return [...values];
    }
    return values?.toString();
  };
  const isTabletLg = useResizingMediaQuery('(min-width: 1024px)');
  const isTabletMd = useResizingMediaQuery('(min-width: 768px)');
  const isTabletSm = useResizingMediaQuery('(min-width: 481px)');
  const [sliceBegin, setSliceBegin] = useState(0);
  const [mobileFilters, setMobileFilters] = useState(filters); // useMediaQuery('(min-width: 768px)');

  const handleChange = (name: string, values: string[]) => {
    const newValue = [];
    newValue.push(
      name === 'availability_filter' ? cleanAvailability(values) : forceNewData(values),
    );
    onChange(name, ...newValue);
  };

  const getSliceBegin = (
    isTabletOrDesktop: boolean,
    isTabletMedium: boolean,
    isTabletSmall: boolean,
  ) => {
    if (isTabletOrDesktop) {
      return 6;
    }
    if (isTabletMedium) {
      return 4;
    }
    if (isTabletSmall) {
      return 2;
    }
    return 2;
  };

  useEffect(() => {
    setSliceBegin(getSliceBegin(isTabletLg, isTabletMd, isTabletSm));
  }, [isTabletLg, isTabletMd, isTabletSm]);

  useEffect(() => {
    setMobileFilters(filters.slice(sliceBegin, filters.length));
  }, [sliceBegin]);

  return (
    <div key="SearchFilterContainer" className={styles.filters}>
      {filters?.map((filter: any) => {
        const defaultFilterValues = filter?.name !== 'focus'
          ? defaultValues[filter?.name]
          : defaultValues?.expertises?.concat(
            defaultValues?.interventions,
            defaultValues?.populations,
          );
        return (
          <Filter
            buttonText={buttonText}
            key={filter.name}
            className={styles.filter}
            name={filter.name}
            defaultValues={defaultFilterValues}
            label={filter.label}
            options={filter.options}
            onChange={handleChange}
            testId={filter.testId}
            heightConstraint={filter.maxHeight}
            isLoading={isLoading}
            onClose={onClose}
            separatorStep={filter.name === 'credentials' ? 2 : 0}
          />
        );
      })}
      {showMobileFilters && (
        <MobileFilters
          onChange={onChange}
          defaultValues={defaultValues}
          filters={mobileFilters}
          isAdmin={isAdmin}
          sliceBegin={sliceBegin}
          onClose={onClose}
          onOpen={onOpen}
          clinicianCount={clinicianCount}
        />
      )}
    </div>
  );
};
