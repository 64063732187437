import clsx from 'clsx';
import { Clinician } from 'patientOnboarding/@types';
import avatar from 'patientOnboarding/assets/img/rectangle-avatar.jpg';
import { ReactComponent as CaretRight } from 'patientOnboarding/assets/svg/arrow-right.svg';
import { ReactComponent as CheckmarkIcon } from 'patientOnboarding/assets/svg/checkmark.svg';
import { ReactComponent as Map } from 'patientOnboarding/assets/svg/map-fill.svg';
import { bookingRoutes as absoluteBookingRoutes } from 'patientOnboarding/routes/absolutes';
import { bookingRoutes } from 'patientOnboarding/routes/constants';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ListCard, Pillbox, SpanButton } from 'lifestance-ui';

import {
  activeFiltersSelectorObie,
  loadingDisplayedCliniciansSelector,
} from 'patientOnboarding/store/cliniciansGlobal/selectors';
import { getLicenseKeyRules } from 'patientOnboarding/store/insurance/actions';
import { getClinicianProfile, loadClinicianProfile } from 'patientOnboarding/store/matches/actions';
import {
  setIsBookingModalOpenObie,
  updatePreferences,
} from 'patientOnboarding/store/preferences/actions';
import { updateReservation } from 'patientOnboarding/store/reservationGlobal/actions';
import { getContactInfo } from 'patientOnboarding/store/userGlobal/actions';

import {
  isExistingAccountHolder,
  obiePreferencesData,
  selectedClinician as selectedClinicianSelector,
} from 'patientOnboarding/selectors/selectors';

import { distanceInMilesLabel, sortAvailabilities, useDevice } from 'patientOnboarding/utils';

import styles from './SearchResults.module.scss';
import { AvailabilityButtons } from './components/AvailabilityButtons/AvailabilityButtons';

interface SearchResultsProps {
  matches: Clinician[];
  brief?: boolean;
  baseRoute: string;
  onNavigate?: ((route: string, provider: Clinician) => void) | null;
}

export const SearchResults: React.FC<React.PropsWithChildren<SearchResultsProps>> = ({
  matches,
  brief,
  baseRoute,
  onNavigate,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<Clinician>(null as unknown as Clinician);
  const preferences = useSelector(obiePreferencesData);

  const activeFiltersObie = useSelector(activeFiltersSelectorObie);

  const currentPreferences = activeFiltersObie;
  const topSearch = location.pathname === bookingRoutes.search;
  const { isMobile, isTablet, isDesktop } = useDevice();

  const [duplicatedClinicians, setDuplicatedClinicians] = useState<number[]>([]);
  const inOffice = (currentPreferences?.modality as string)?.includes('in_office');
  const inVirtual = (currentPreferences?.modality as string)?.includes('video_visit');
  const bothVisitTypes = (inOffice && inVirtual) || (currentPreferences?.modality as string)?.length === 0;

  const selectedClinician = useSelector(selectedClinicianSelector);
  const isLoadingDisplayedClinicians = useSelector(loadingDisplayedCliniciansSelector);

  const expertisesPillsLimit = 10;
  const expertisesPillsMobileLimit = 4;

  const matchingConcerns = (
    clinicianConcerns: string[],
    clinicianInterventions: string[],
    clinicianPopulations: string[],
  ) => {
    const concerns = (activeFiltersObie?.concerns as string[])?.concat(
      activeFiltersObie?.interventions as string[],
      activeFiltersObie?.populations as string[],
    );
    const clinicianAreasOfFocus = clinicianConcerns?.concat(
      clinicianInterventions,
      clinicianPopulations,
    );

    const totalMatches = clinicianAreasOfFocus.filter((value: any) => concerns?.includes(value));
    const sortedMatches = totalMatches.sort((a, b) => {
      const nameA = a.toUpperCase();
      const nameB = b.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
    return sortedMatches;
  };

  const handleSelectSlot = (availability: any, clinician: any, event: any, facility: any) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    dispatch(
      updateReservation({
        clinician: {
          first_name: clinician.first_name,
          last_name: clinician.last_name,
          credentials: clinician.license_type,
          photo: clinician.photo,
        },
        selectedFacility: facility,
      }),
    );
    dispatch(getLicenseKeyRules(availability.license_key));
    dispatch(getContactInfo(availability.license_key));
    dispatch(
      getClinicianProfile(
        clinician.id,
        currentPreferences.typeOfCare as string,
        currentPreferences.zipCode as string,
      ),
    );
    const modality = availability.display === 'Office' ? 'IN-OFFICE' : 'VIDEO';
    const modalityIfNoGiven = {
      virtual_or_video_visit: availability.virtual_or_video_visit || 0,
      in_person_visit: availability.in_person_visit || 0,
    };
    // FIXME: Remove ABIE action to save facilityId and use just one for both OBIE and ABIE
    // dispatch(updatePreferences({ facilityId: availability.facility_id }));
    dispatch(
      updateReservation({
        ...availability,
        availability: true,
        modality,
        ...modalityIfNoGiven,
        selectedFacility: clinician.addresses[0],
      }),
    );
    dispatch(setIsBookingModalOpenObie(true));
  };

  const noAvailability = 'No upcoming availability';
  const isExistingUser = useSelector(isExistingAccountHolder);

  const navigateToProviderUrl = (route: string, provider: Clinician) => {
    const id = `${provider.first_name.toLowerCase()}-${provider.last_name.toLowerCase()}-${
      provider.id
    }`;
    dispatch(
      updateReservation({
        clinician: provider,
        selectedFacility: provider.addresses[0],
      }),
    );
    dispatch(
      updatePreferences({
        location: location.search,
        location_back: `${location.pathname}${location.search}`,
      }),
    );
    return navigate(`${absoluteBookingRoutes.findCare}/${route.replace(':id', id)}`, {
      state: { back: `${location.pathname}${location.search}` },
    });
  };

  const handleNavigate = (route: string, provider: Clinician) => {
    dispatch(loadClinicianProfile(null));
    dispatch(
      updateReservation({
        facilityIds: [provider.addresses[0].facility_id],
      }),
    );
    if (onNavigate) {
      onNavigate(route, provider);
    } else {
      navigateToProviderUrl(route, provider);
    }
  };

  useEffect(() => {
    if (selectedMatch && selectedMatch.id) {
      setOpenCalendar(true);
    }
  }, [selectedClinician]);

  const toggleOpenCalendar = (status: boolean) => {
    if (!status) {
      const empty: any = null;
      setSelectedMatch(empty);
      setOpenCalendar(false);
    }
  };

  const handleAvailabilityView = (route: string, provider: Clinician, event: any) => {
    event.stopPropagation();
    handleNavigate(route, provider);
  };

  const getNavigateLabel = () => {
    const isSearch = location.pathname === bookingRoutes.search;
    const adminText = 'View all availability';
    const searchText = 'View profile';
    const defaultText = 'View profile and availability';

    if (isSearch) return searchText;
    return defaultText;
  };

  const toFindDuplicates = (arry: number[]) => arry?.filter((item, index) => arry.indexOf(item) !== index);

  useEffect(() => {
    const clinicianIDs = matches?.map((c) => +c.id);
    setDuplicatedClinicians(toFindDuplicates(clinicianIDs));
  }, [matches]);

  return matches && matches.length > 0
    ? (matches.map((match, ind) => {
      const sorted_clinician_availabilities = sortAvailabilities(match.clinician_availabilities);
      return (
        <ListCard
          testId={`Results${ind}`}
          key={`${match.id}in${ind.toString()}`}
          className={clsx({ [styles.brief]: brief })}
          onClick={() => handleNavigate(baseRoute, match)}
          data-testId="listCard"
          gender={match?.gender}
          languages={match.languages.map((l) => l.name)}
          topSearch={topSearch}
        >
          <div className={clsx({ [styles.topSearch]: topSearch }, styles.resultDetails)}>
            <img
              onClick={() => handleNavigate(baseRoute, match)}
              className={clsx(styles.resultThumbnail, styles.clickableInfo, {})}
              src={match.photo && match.photo.length ? match.photo : avatar}
              alt={match.first_name}
              data-testId="clinicianAvatar"
            />
            <div className={styles.resultInfo}>
              <div
                className={clsx(styles.clickableInfo, styles.obieResultBasic)}
                onClick={() => handleNavigate(baseRoute, match)}
              >
                <p data-testId="role" className={styles.tag}>
                  {match.clinician_type}
                </p>
                <h2 className={styles.resultTitle} data-testId="resultTitle">
                  {`${match.first_name} ${match.last_name}${
                    match.license_type ? `, ${match.license_type}` : ''
                  }`}
                  <span
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => handleNavigate(baseRoute, match)}
                  >
                    <CaretRight className={styles.arrowRight} data-testId="CaretRight" />
                  </span>
                </h2>
                <p className={styles.mobileDistanceLabel}>
                  {distanceInMilesLabel(match?.distance_in_miles)}
                </p>
                <div
                  className={clsx(
                    {
                      [styles.noMarginWrapper]:
                          matchingConcerns(match.concerns, match.interventions, match.populations)
                            .length === 0,
                    },
                    styles.expertisesWrapper,
                  )}
                >
                  {matchingConcerns(match.concerns, match.interventions, match.populations).map(
                    (expertise: any) => (
                      <div className={styles.pillboxContainer}>
                        <Pillbox mode="main" iconElement={<CheckmarkIcon />}>
                            {expertise}
                          </Pillbox>
                      </div>
                    ),
                  )}
                  {matchingConcerns(match.concerns, match.interventions, match.populations)
                    .length > expertisesPillsLimit ? (
                      <div className={styles.pillboxContainer}>
                        <Pillbox mode="secondary">
                            {`+${
                              matchingConcerns(match.concerns, match.interventions, match.populations)
                                .length - expertisesPillsLimit
                            } expertises`}
                          </Pillbox>
                      </div>
                    ) : (
                      <></>
                    )}
                </div>
                {!brief && (
                <>
                  {match.clinician_availabilities
                      && match.clinician_availabilities.length > 0 ? (
                        <div className={styles.resultAvaibilityButtons}>
                          <p
                            className={clsx(
                              {
                                [styles.resultAvaibilityExpertise]:
                                  matchingConcerns(
                                    match.concerns,
                                    match.interventions,
                                    match.populations,
                                  ).length > 0,
                              },
                              styles.resultAvailability,
                            )}
                            data-testId="nextAvailability"
                          >
                            Next availability:
                          </p>
                          <div className={styles.buttons}>
                            <AvailabilityButtons
                              availabilities={sorted_clinician_availabilities}
                              bothVisits={bothVisitTypes}
                              video={inVirtual}
                              office={inOffice}
                              clinician={match}
                              onSelect={handleSelectSlot}
                            />
                          </div>
                        </div>
                    ) : (
                      <div
                            style={{ display: `${!isExistingUser ? '' : 'none'}` }}
                            className={clsx(styles.noAvailability, styles.desktop)}
                          >
                            <p>{noAvailability}</p>
                          </div>
                    )}

                  <span
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => handleAvailabilityView(baseRoute, match, e)}
                    onClick={(e) => handleAvailabilityView(baseRoute, match, e)}
                  >
                    <p className={styles.resultLinkMobile} data-testId="profileLink">
                      <SpanButton>{getNavigateLabel()}</SpanButton>
                    </p>
                  </span>
                </>
                )}
              </div>
              <div className={styles.resultExtra}>
                <div className={styles.resultAddress} data-testId="resultAddress">
                  {match.distance_in_miles
                      && Number(match.distance_in_miles) === match.distance_in_miles && (
                        <>
                          {distanceInMilesLabel(match.distance_in_miles)}
                          <br />
                        </>
                  )}
                  {match.addresses[0].address_line1}
                  <br />
                  {match.addresses[0].city}
                  ,&nbsp;
                  {match.addresses[0].state}
                  {/* {!brief && <LineBottom />} */}
                  {/* This to apply only for OBIE */}
                  {duplicatedClinicians?.includes(+match.id) && (
                  <div
                    className={styles.multipleOfficesPill}
                    onClick={() => handleNavigate(baseRoute, match)}
                  >
                    <Pillbox mode="beige" iconElement={<Map />}>
                      Multiple offices
                        </Pillbox>
                  </div>
                  )}
                </div>
                <div
                  tabIndex={0}
                  role="button"
                  onKeyDown={(e) => handleAvailabilityView(baseRoute, match, e)}
                  onClick={(e) => handleAvailabilityView(baseRoute, match, e)}
                >
                  <p className={styles.resultLink} data-testId="profileLink">
                    {!isMobile && <SpanButton arrow>{getNavigateLabel()}</SpanButton>}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {!topSearch && (
          <>
            <div className={styles.expertisesWrapperMobile}>
              {matchingConcerns(match.concerns, match.interventions, match.populations)
                .slice(0, expertisesPillsMobileLimit)
                .map((expertise) => (
                  <div className={styles.pillboxContainerMobile}>
                    <Pillbox mode="main" iconElement={<CheckmarkIcon />}>
                      {expertise}
                    </Pillbox>
                  </div>
                ))}
              {matchingConcerns(match.concerns, match.interventions, match.populations).length
                  > expertisesPillsMobileLimit ? (
                    <div className={styles.pillboxContainerMobile}>
                      <Pillbox mode="secondary">
                        {`+${
                          matchingConcerns(match.concerns, match.interventions, match.populations)
                            .length - expertisesPillsMobileLimit
                        } expertises`}
                      </Pillbox>
                    </div>
                ) : (
                  <></>
                )}
            </div>
            <div className={styles.availabilityMobile}>
              {match.clinician_availabilities
                  && match.clinician_availabilities.length
                  && !topSearch ? (
                    <AvailabilityButtons
                      availabilities={sorted_clinician_availabilities}
                      bothVisits={bothVisitTypes}
                      video={inVirtual}
                      office={inOffice}
                      clinician={match}
                      onSelect={handleSelectSlot}
                    />
                ) : (
                  <div
                    style={{ display: `${!isExistingUser ? '' : 'none'}` }}
                    className={clsx(styles.noAvailability)}
                  >
                    <p>{noAvailability}</p>
                  </div>
                )}
            </div>
          </>
          )}
        </ListCard>
      );
    }) as unknown as ReactElement)
    : null;
};

SearchResults.defaultProps = {
  onNavigate: null,
};
