import { bookingRoutes } from 'patientOnboarding/routes/absolutes';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { SpanButton } from 'lifestance-ui';

import styles from './NoAvailabilityBox.module.scss';

export const NoAvailabilityBox: FC = () => {
  const navigate = useNavigate();

  const navigateToMatch = () => {
    navigate(bookingRoutes.matchCriteria);
  };

  return (
    <div className={styles.container}>
      <span className={styles.text}>No upcoming availability.</span>
      <SpanButton onClick={navigateToMatch}>Browse other providers</SpanButton>
    </div>
  );
};
