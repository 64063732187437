import React, { FC, useEffect, useState } from 'react';

import styles from './ProgressBar.module.scss';
import { CompletedStep } from './components/CompletedStep';
import { CurrentNumber } from './components/CurrentNumber';
import { PendingStep } from './components/PendingStep';
import { Step } from './types';

interface IProgressBarProps {
  steps: Step[];
  step: Step;
  testId?: string;
  paymentMethod: string;
}

export const ProgressBar: FC<IProgressBarProps> = ({
  steps,
  step,
  testId = 'progressBar',
  paymentMethod,
}) => {
  const [stepId, setStepId] = useState(step.number);

  const totalSubSteps = steps.reduce(
    (total, step) => total + (step.subSteps ? step.subSteps.length : 1),
    0,
  );

  const percentagePerSubStep = 100 / totalSubSteps;

  const getSubStepPercentage = (steps: Step[], currentStep: Step) => {
    let cumulativeSubSteps = 0;
    for (const step of steps) {
      if (step.number === currentStep.number) {
        if (currentStep.subSteps) {
          cumulativeSubSteps += currentStep.currentSubStep?.number ?? 0;
        } else {
          // eslint-disable-next-line no-plusplus
          cumulativeSubSteps++;
        }
        break;
      }
      if (step.subSteps) {
        cumulativeSubSteps += step.subSteps.length;
      } else {
        // eslint-disable-next-line no-plusplus
        cumulativeSubSteps++;
      }
    }
    return cumulativeSubSteps * percentagePerSubStep;
  };

  const subStepPercentage = getSubStepPercentage(steps, step);

  useEffect(() => {
    setStepId(step.number);
  }, [step]);

  return (
    <>
      <div className={styles.placeholder} />
      <div className={styles.container}>
        <div className={styles.indicator}>
          <div
            className={styles[`progress_${steps.length === 3 ? 'nocc_noins_' : ''}${stepId}`]}
            style={{ width: `${subStepPercentage}%` }}
          />
        </div>
        <header data-testid={`${testId}StepTitle`} className={styles.header}>
          {steps[stepId - 1].title}
        </header>
        <div className={styles.steps_container}>
          {steps.map((stepItem) => {
            if (
              stepItem.number < stepId
              || (steps.length === stepId && step.currentSubStep?.number === step.subSteps?.length)
            ) {
              return (
                <CompletedStep
                  key={stepItem.number}
                  testId={`${testId}Completed${stepItem.title}`}
                  step={stepItem}
                  paymentMethod={paymentMethod}
                  numberOfSteps={steps.length}
                />
              );
            }
            if (stepItem.number === stepId) {
              return (
                <CurrentNumber
                  key={stepItem.number}
                  testId={`${testId}Current${stepItem.title}`}
                  step={step}
                  numberOfSteps={steps.length}
                />
              );
            }
            if (stepItem.number > stepId) {
              return (
                <PendingStep
                  key={stepItem.number}
                  testId={`${testId}Pending${stepItem.title}`}
                  step={stepItem}
                  numberOfSteps={steps.length}
                />
              );
            }
          })}
        </div>
      </div>
    </>
  );
};
