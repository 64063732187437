import clsx from 'clsx';
import { ReactComponent as SortIcon } from 'patientOnboarding/assets/svg/arrow-up-down-line.svg';
import React, {
  FC,
  MutableRefObject,
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { MobileDropdown } from 'lifestance-ui';

import { onClickOut, simpleKeyboardControl, useDevice } from 'patientOnboarding/utils';

import styles from './SortDropdown.module.scss';

type Option = {
  label: string;
  value: string;
};
interface ISortDropDownProps {
  onChange: any;
  options: Option[];
  value: string;
  testId?: string;
  classNameContainer?: string;
  variant?: 'ABIE' | 'OBIE';
}

export const SortDropdown: FC<React.PropsWithChildren<ISortDropDownProps>> = ({
  onChange,
  options,
  value,
  testId,
  classNameContainer = '',
  variant = 'OBIE',
}) => {
  const { isMobile, isTablet, isSmallTablet } = useDevice();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedLabel, setSelectedLabel] = useState<Option | undefined>();
  const optionRefs = useRef<MutableRefObject<HTMLLIElement>[]>([]);
  const triggerRef = useRef(null);
  const isAdmin = variant === 'ABIE';

  if (optionRefs?.current !== null && optionRefs?.current?.length !== options?.length) {
    optionRefs.current = Array(options?.length)
      .fill(options)
      .map((_, i) => optionRefs?.current[i] || createRef());
  }

  useEffect(() => {
    if (value !== '') {
      setSelectedValue(value);
    }
  }, [value]);

  useEffect(() => {
    const currentLabel = options?.find((option) => selectedValue === option.value);
    setSelectedLabel(currentLabel);
  }, [selectedValue]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleSelect = (value: any) => {
    document.body.style.overflow = 'initial';
    setSelectedValue(value);
    onChange(value);
    setOpen(!open);
  };

  useEffect(() => {
    if (open) {
      return onClickOut({
        triggerRef,
        action: () => setOpen(false),
        ignoreParent: true,
      });
    }
  }, [open, triggerRef]);

  const mobileClose = () => {
    document.body.style.overflow = 'initial';
    setOpen(!open);
  };

  return (
    <>
      <div
        className={clsx(
          styles.container,
          {
            [styles.containerAdmin]: isAdmin,
          },
          classNameContainer,
        )}
        ref={dropdownRef}
      >
        <div
          className={clsx(styles.dropdown, {
            [styles.dropdownAdmin]: isAdmin,
            [styles.dropdownOpen]: open,
          })}
          data-testId={`sortDropdown${testId}`}
          onClick={handleClick}
          onKeyDown={(e) => simpleKeyboardControl(e, () => handleClick(), true)}
          role="button"
          tabIndex={isAdmin ? 0 : -1}
        >
          <SortIcon className={styles.icon} />
          <span className={styles.value}>{selectedLabel?.label}</span>
          <span className={styles.sortText}>Sort</span>
        </div>
        {!isMobile && open && (
          <div
            data-testId={`dropdownOptionsContainer${testId}`}
            className={styles.optionsContainer}
            ref={triggerRef}
          >
            {options
              && options.length > 0
              && options.map(({ label, value }, i) => (
                <li
                  ref={optionRefs.current[i]}
                  key={value}
                  onClick={() => handleSelect(value)}
                  tabIndex={-1}
                  role="option"
                  aria-selected={selectedValue === value}
                  onKeyDown={() => 0}
                  data-value={value}
                  className={clsx({
                    [styles.focused]: selectedValue === value,
                  })}
                  data-testId={`dropdownOption${testId}SetOption${i}`}
                >
                  {label}
                </li>
              ))}
          </div>
        )}
      </div>
      {(isMobile || isTablet || isSmallTablet) && open && (
        <MobileDropdown
          testId={testId}
          options={options}
          title="Sort By"
          handleSelect={handleSelect}
          open={open}
          mobileClose={mobileClose}
          value={selectedValue}
          icon="CloseIcon"
          sideWays={false}
          variant="radio"
        />
      )}
    </>
  );
};
