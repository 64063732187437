import clsx from 'clsx';
import { ReactComponent as ArrowDownIcon } from 'patientOnboarding/assets/svg/arrow-down.svg';
import React, { FC, useEffect, useRef, useState } from 'react';

import { Button, InputOption, MobileDropdown, Radio } from 'lifestance-ui';

import {
  enableBodyScroll,
  onClickOut,
  simpleKeyboardControl,
  stateAbbrToName,
  useDevice,
  useLastEventClickOrTab,
} from 'patientOnboarding/utils';

import styles from './DistanceFilter.module.scss';

interface DistanceFilterProps {
  onChange: (value: string, triggeredByUser: boolean) => void;
  entireState: string;
  state: string;
  zipCode: string | number;
  large?: boolean;
  defaultValue?: number | string;
  isAdmin?: boolean;
  isMobileProp?: boolean;
  isSecondInstance?: boolean;
}

const distanceOptions = [
  {
    label: 'Within 5 miles',
    value: '5',
  },
  {
    label: 'Within 10 miles',
    value: '10',
  },
  {
    label: 'Within 25 miles',
    value: '25',
  },
  {
    label: 'Within 60 miles',
    value: '60',
  },
  {
    label: 'Entire state',
    value: 'entire_state',
  },
];

export const DistanceFilter: FC<DistanceFilterProps> = ({
  onChange,
  entireState,
  state,
  zipCode,
  large = false,
  defaultValue,
  isAdmin = false,
  isMobileProp = false,
  isSecondInstance = false,
}) => {
  const [open, setOpen] = useState<boolean | null>(false);
  const [selected, setSelected] = useState<string | number | null>(
    defaultValue || (distanceOptions[3]?.value as string),
  );
  const [selectedLabel, setSelectedLabel] = useState<number | string | undefined>(defaultValue);
  const [closedByOut, setClosedByOut] = useState<boolean>(false);
  const [xDistanceContainer, setXDistanceContainer] = useState<number>(0);
  const { isMobile, isTablet, isSmallTablet } = useDevice();
  const { lastEventType } = useLastEventClickOrTab();
  const [scrollY, setScrollY] = useState(0);

  const triggerRef = useRef(null);
  const containerRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (entireState === 'true') {
      setSelected('entire_state');
    } else {
      setSelected(
        distanceOptions.find((o) => o.value?.toString() === defaultValue?.toString())
          ?.value as string,
      );
    }
  }, [entireState, defaultValue]);

  const handleSaveAndClose = () => {
    if (selected !== 'entire_state') {
      setSelectedLabel(selected as string);
    }
    if (isAdmin && lastEventType === 'tab') {
      containerRef.current?.focus();
    }
    setClosedByOut(false);
    onChange(selected as string, true);
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setClosedByOut(false);
      setTimeout(() => {
        enableBodyScroll();
      }, 500);
    }
    if (open && !isMobile) {
      setClosedByOut(true);
      return onClickOut({
        triggerRef,
        action: () => {
          setOpen(false);
        },
        ignoreParent: true,
      });
    }
  }, [open, onChange, triggerRef]);

  useEffect(() => {
    if (!open && closedByOut) {
      handleSaveAndClose();
    }
  }, [open]);

  const handleChange = (value: string) => {
    setSelected(value);
  };

  useEffect(() => {
    if (scrollY < 107 && isSecondInstance) {
      setOpen(false);
    }
  }, [scrollY]);

  const handleChangeMobile = (value: string) => {
    setSelectedLabel(value as string);
    onChange(selected as string, true);
    setSelected(value);
    onChange(value, true);
    setOpen(false);
    setTimeout(() => {
      enableBodyScroll();
    }, 500);
  };

  const handleOpen = () => {
    if (!open && !closedByOut) {
      setOpen(true);
    }
  };

  useEffect(() => {
    visualViewport?.addEventListener('resize', () => {
      setXDistanceContainer(
        document.querySelector('#distanceFilterText')?.getBoundingClientRect()?.x as number,
      );
    });
    setXDistanceContainer(
      document.querySelector('#distanceFilterText')?.getBoundingClientRect()?.x as number,
    );
    window.addEventListener('scroll', (e) => {
      setScrollY(window.scrollY);
    });
  }, []);

  const initialValue = entireState === 'true'
    ? 'entire_state'
    : distanceOptions.find((o) => o.value?.toString() === selected?.toString())?.value;

  useEffect(() => {
    // On desktop we don't change the selectedLabel until the user hits Save
    if (isMobile) {
      if (selected === 'entire_state') {
        setSelectedLabel(state);
      } else {
        setSelectedLabel(selected?.toString() as string);
      }
    }
  }, [selected]);

  useEffect(() => {
    if (defaultValue === 'entire_state') {
      setSelectedLabel(state);
    } else {
      setSelectedLabel(defaultValue?.toString() as string);
    }
  }, [defaultValue]);

  return (
    <span className={clsx(styles.container, { [styles.large]: large })}>
      <span
        style={{
          marginTop: isMobile ? '4px' : '0px',
        }}
        id="distanceFilterText"
      >
        <span
          ref={containerRef}
          className={clsx(styles.distanceFilter, {
            [styles.arrowButton]: !open,
            [styles.arrowButtonUp]: open,
          })}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => simpleKeyboardControl(e, handleOpen, true)}
          onClick={handleOpen}
        >
          {entireState === 'true' ? (
            <span
              className={clsx({
                [styles.mobileText]: isMobileProp,
              })}
            >
              {stateAbbrToName(state)}
              {' '}
              <ArrowDownIcon />
            </span>
          ) : (
            <span
              className={clsx({
                [styles.mobileText]: isMobileProp,
              })}
            >
              {selectedLabel}
              {' '}
              miles from
              {' '}
              <span className={styles.textWithArrow}>
                {zipCode}
                {' '}
                <ArrowDownIcon />
              </span>
            </span>
          )}
        </span>
        {!isMobile && open && (
          <div
            ref={triggerRef}
            className={styles.optionsContainer}
            style={{ left: `${xDistanceContainer}px` }}
          >
            <div className={styles.content}>
              <Radio
                name="appointment"
                defaultValue={initialValue as string}
                options={distanceOptions as InputOption[]}
                onChange={(value) => handleChange(value)}
                className={styles.clean}
                autoFocus
                ariaLabel="Select a distance"
              />
            </div>
            <div className={styles.footer}>
              <Button size="small" onClick={handleSaveAndClose} testId="SaveButton">
                Save
              </Button>
            </div>
          </div>
        )}
      </span>
      {(isMobile || isSmallTablet || isTablet) && open && (
        <div className={styles.mobilefilter}>
          <MobileDropdown
            options={distanceOptions}
            open={open}
            handleSelect={handleChangeMobile}
            mobileClose={() => setOpen(false)}
            value={initialValue}
            icon="CloseIcon"
            title="Distance"
            sideWays={false}
            testId="DistanceDropdown"
            variant="radio"
            avoidOpeniOsStyle
          />
        </div>
      )}
    </span>
  );
};
