import { GlobalOption } from 'patientOnboarding/@types';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { obiePreferencesData } from 'patientOnboarding/selectors/selectors';

import { SELF_PAY_VALUE,
  formatNotValidInsuranceOptions,
  getSortedNotValidInsuranceOptions,
  validateInsurance,
} from 'patientOnboarding/utils';

interface UseInsuranceValidationParams {
  updatedPreferences?: any;
  filterSelfPayOption?: boolean;
  clinicianState?: string;
  insuranceCompanies?: any;
}

export const useInsuranceValidation = ({
  updatedPreferences,
  filterSelfPayOption,
  clinicianState,
  insuranceCompanies,
}: UseInsuranceValidationParams = {}) => {
  const preferences = useSelector(obiePreferencesData);
  const state = useMemo(
    () => clinicianState || preferences?.state,
    [preferences?.state, clinicianState],
  ) as string;
  const [insuranceOpts, setInsuranceOpts] = useState<GlobalOption[]>([]);
  const [isInsuranceValidOnState, setIsInsuranceValidOnState] = useState(true);

  const currentPreferences = useMemo(
    () => updatedPreferences || preferences,
    [updatedPreferences, preferences],
  );
  const currentInsuranceCompanies = useMemo(
    () => insuranceCompanies || preferences?.filters.insuranceCompanies,
    [insuranceCompanies, preferences?.filters.insuranceCompanies],
  );

  useEffect(() => {
    if (currentPreferences.paymentMethod === SELF_PAY_VALUE) {
      setIsInsuranceValidOnState(true);
    } else {
      const { isNotValidOnState } = validateInsurance(
        currentPreferences.insuranceCompany,
        state as string,
      );

      setIsInsuranceValidOnState(Boolean(!isNotValidOnState));
    }
  }, [
    currentPreferences.paymentMethod,
    currentPreferences.insuranceCompany,
    currentPreferences.state,
  ]);

  useEffect(() => {
    if (currentInsuranceCompanies) {
      const insurances = getSortedNotValidInsuranceOptions(currentInsuranceCompanies, state);
      const insuranceOptions = formatNotValidInsuranceOptions(insurances, state as string);

      if (filterSelfPayOption) {
        setInsuranceOpts(
          insuranceOptions.filter((option: GlobalOption) => option.value !== 'self_pay'),
        );
      } else {
        setInsuranceOpts(insuranceOptions);
      }
    }
  }, [currentInsuranceCompanies, state, filterSelfPayOption]);

  return {
    insuranceOpts,
    isInsuranceValidOnState,
  };
};
