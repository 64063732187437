import clsx from 'clsx';
import {
  FC, FunctionComponent, MouseEvent, SVGProps, useState,
} from 'react';
import styles from './CircularButton.module.scss';

interface ICircularButton {
  isActive: boolean;
  Icon: FunctionComponent<React.PropsWithChildren<SVGProps<SVGSVGElement>>>;
  onClick: () => void;
  testId?: string;
}

export const CircularButton: FC<React.PropsWithChildren<ICircularButton>> = ({
  isActive,
  Icon,
  onClick,
  testId = 'Default',
}) => {
  const [active, setActive] = useState(isActive);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setActive(!active);
    onClick();
  };
  return (
    <button
      type="button"
      className={clsx(
        styles.circularButton,
        {
          [styles.active]: active,
        },
      )}
      onClick={handleClick}
      data-testId={`circularButton${testId}`}
    >
      <Icon />
    </button>
  );
};
