import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';

import { userLogout } from 'modules/application/store/actions';

import { SSOSessionData, isAbie } from 'patientOnboarding/selectors/selectors';

export function IdleMonitor() {
  const dispatch = useDispatch();
  const location = useLocation();

  const idleTimeout = 1000 * 60;
  let idleEvent;
  const navigate = useNavigate();
  const isAdmin = useSelector(isAbie);
  const sessionData = useSelector(SSOSessionData);
  const licenseKey = sessionData.patient_info?.license_key;
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');
  const events = ['mousemove', 'click', 'keypress', 'load', 'mousedown', 'scroll'];

  const sessionTimeout = () => {
    if (typeof idleEvent !== 'undefined') {
      clearTimeout(idleEvent);
    }
    idleEvent = setTimeout(() => logOut(), idleTimeout);
  };

  const logOut = () => {
    if (
      !isAdmin
      && typeof licenseKey !== 'undefined'
      && location.pathname !== '/timeout'
      && location.pathname !== '/error'
      && !location.pathname.includes('admin')
      && !location.pathname.includes('email')
      && !location.pathname.includes('/find-care/intake/confirmation')
    ) {
      dispatch(userLogout());
      navigate('/timeout', { replace: true });
    }
  };

  useEffect(() => {
    sessionTimeout();
  });

  useEffect(() => {
    if (typeof licenseKey !== 'undefined') {
      events.forEach((event) => {
        window.addEventListener(event, sessionTimeout);
      });
      return () => {
        events.forEach((event) => {
          clearTimeout(idleEvent);
          window.removeEventListener(event, sessionTimeout);
        });
      };
    }
  });

  return <></>;
}
