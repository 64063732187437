import { FC } from 'react';

import { distanceInMilesLabel } from '.';

interface LocationOptionFormatterProps {
  addressLine1: string;
  city: string;
  distanceInMiles: number;
}

export const LocationOptionFormatter: FC<LocationOptionFormatterProps> = ({
  addressLine1,
  city,
  distanceInMiles,
}) => (
  <span>
    {city}
    {' '}
    |
    {addressLine1}
    {' '}
    {distanceInMiles && Number(distanceInMiles) === distanceInMiles && (
      <span style={{ color: 'grey' }}>
        (
        {distanceInMilesLabel(distanceInMiles, 'miles')}
        )
      </span>
    )}
  </span>
);
