import { ActiveFilter } from 'patientOnboarding/store/cliniciansGlobal/slice';

export const filterByModality = (
  clinicians: Record<string, unknown>[],
  activeFilters: ActiveFilter[],
) => {
  const modalityFilter = activeFilters?.find((filter) => filter?.name === 'modality');
  if (!modalityFilter) return clinicians;
  // @ts-expect-error doesn't like that it's unknown 🤷‍️
  return clinicians.filter((clinician) => clinician.clinician_availabilities.some((availability) => {
    const hasVideoVisit = (modalityFilter.value as string[]).includes('video_visit')
        && availability.virtual_or_video_visit === 1;
    const hasInPersonVisit = (modalityFilter.value as string[]).includes('in_office')
        && availability.in_person_visit === 1;
    return hasVideoVisit || hasInPersonVisit;
  }));
};
