import clsx from 'clsx';
import { ReactComponent as Illustration } from 'patientOnboarding/assets/svg/illustration.svg';
import React, { Suspense, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { CircularLoading, Container } from 'lifestance-ui';

import {
  contactInfo as contactInfoSelector,
  obiePreferencesData,
  selectedFacilityData,
} from 'patientOnboarding/selectors/selectors';

import { useInvoca } from 'patientOnboarding/hooks';

import {
  defaultPhoneNumber,
  formatContactNumber,
  getContactInfoByState,
} from 'patientOnboarding/utils';

import styles from './ErrorHandlers.module.scss';

const ErrorHandlerView = () => {
  const contactInfo = useSelector(contactInfoSelector);
  const preferences = useSelector(obiePreferencesData);
  const facility = useSelector(selectedFacilityData);
  const state = facility?.state ? facility.state : preferences.state;
  const licenseKey = facility?.license_key ? facility.license_key : preferences.licenseKey;
  const contactInfoByState = getContactInfoByState(contactInfo, state, licenseKey);
  const contactNumber = preferences.marketingReferralPhone.length > 0
    ? preferences.marketingReferralPhone
    : contactInfoByState?.intake_call_in_number;

  useInvoca();

  return (
    <Suspense fallback={<CircularLoading size="xlarge" />}>
      <Container className={styles.container}>
        <div className={styles.rounded}>
          <Illustration style={{ margin: '0px auto' }} />
        </div>
        <span className={styles.errorLabel}>Error code: 500</span>
        <h2>Sorry about that</h2>
        <div className={styles.textContainer}>
          <p className={styles.padded}>
            An error has occurred and we’re working to fix the problem. We’ll be up and running
            again shortly.
          </p>
          <p>
            To finish booking, please call us at
            {' '}
            <a
              className={clsx(styles.phone, 'invocaPhoneNumber')}
              href={`tel:${formatContactNumber(contactNumber) || ''}`}
            >
              {contactNumber || defaultPhoneNumber}
            </a>
            .
          </p>
        </div>
      </Container>
    </Suspense>
  );
};

export default ErrorHandlerView;
