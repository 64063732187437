import clsx from 'clsx';
import { ReactComponent as LogoMobile } from 'intakeOptimization/assets/svg/logo-mobile.svg';
import { ReactComponent as Logo } from 'intakeOptimization/assets/svg/logo.svg';
import { ReactComponent as NewMatchHomeIcon } from 'intakeOptimization/assets/svg/new-match-home.svg';
import { ReactComponent as SearchIcon } from 'intakeOptimization/assets/svg/search.svg';
import { ReactComponent as TalkingPointsOffIcon } from 'intakeOptimization/assets/svg/talking-points-off.svg';
import { ReactComponent as TalkingPointsIcon } from 'intakeOptimization/assets/svg/talking-points.svg';
import { adminRoutes as absoluteAdminRoutes } from 'intakeOptimization/routes/absolutes';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { BackButton, CircularButton, Container } from 'lifestance-ui';

import { resetAge, setIsTalkingPoint } from 'intakeOptimization/store/admin/reducers';
import { resetActiveFilters } from 'intakeOptimization/store/clinicians/slice';
import { resetAllFilters } from 'intakeOptimization/store/filters/actions';
import { resetPage } from 'intakeOptimization/store/matches/reducers';
import { removeClinicianProfileBack } from 'patientOnboarding/store/preferences/actions';
import { setPreviousPath } from 'patientOnboarding/store/searches/actions';

import {
  abieTalkingPoints,
  isAbie,
  stickyMenuSelector as stickyMenuSelectorAbie,
} from 'intakeOptimization/selectors/selectors';
import { obiePreferencesData, stickyMenuSelector } from 'patientOnboarding/selectors/selectors';

import { usePreviousRoute } from 'patientOnboarding/utils/hooks/usePreviousRoute';

import { Tooltip } from '../Tooltip/Tooltip';
import styles from './Header.module.scss';

export const Header = ({ displaySearch = true }) => {
  const previousRoute = usePreviousRoute();
  const location = useLocation();
  const [isSearchEnabled, setIsSearchEnabled] = useState(displaySearch);
  const [withBoxShadow, setWithBoxShadow] = useState(true);
  const [showSearchTooltip, setShowSearchTooltip] = useState(false);
  const [showNewMatchTooltip, setShowNewMatchTooltip] = useState(false);
  const dispatch = useDispatch();
  const isAdmin = useSelector(isAbie);
  const isSticky = useSelector(stickyMenuSelector);
  const isStickyAbie = useSelector(stickyMenuSelectorAbie);
  const isTalkingPoint = useSelector(abieTalkingPoints);
  const searchByNameRoute = absoluteAdminRoutes.search;
  const showSearch = [absoluteAdminRoutes.searchResults];

  const isHome = location.pathname === absoluteAdminRoutes.patientType;
  const navigate = useNavigate();
  const preferences = useSelector(obiePreferencesData);

  const noSearchViews = [
    absoluteAdminRoutes.accountHolderParent,
    absoluteAdminRoutes.accountHolderChild,
    absoluteAdminRoutes.physicalAddress,
    absoluteAdminRoutes.accountInsurance,
    absoluteAdminRoutes.emailVerification,
    absoluteAdminRoutes.bookingForSelf,
    absoluteAdminRoutes.bookAppointment,
    absoluteAdminRoutes.appointmentError,
  ];

  const noTopDetails = [
    absoluteAdminRoutes.patientType,
    absoluteAdminRoutes.filters,
    absoluteAdminRoutes.insuranceCard,
    absoluteAdminRoutes.consents,
    absoluteAdminRoutes.confirmation,
    absoluteAdminRoutes.review,
  ];

  useEffect(() => {
    if (noTopDetails.includes(location.pathname)) {
      setWithBoxShadow(true);
      setIsSearchEnabled(false);
    } else if (showSearch.includes(location.pathname)) {
      setWithBoxShadow(true);
      setIsSearchEnabled(true);
    } else if (noSearchViews.includes(location.pathname)) {
      setWithBoxShadow(true);
      setIsSearchEnabled(false);
    } else if (
      isAdmin
      && location.pathname.includes(
        absoluteAdminRoutes.clinicianProfile.substring(
          0,
          absoluteAdminRoutes.clinicianProfile.indexOf(':'),
        ),
      )
    ) {
      setIsSearchEnabled(false);
      setWithBoxShadow(false);
    } else {
      setWithBoxShadow(true);
      setIsSearchEnabled(displaySearch);
    }
  }, [location.pathname]);

  const noBackRoutes = [absoluteAdminRoutes.patientType, absoluteAdminRoutes.searchResults];

  const shouldRouteHaveBackButton = !noBackRoutes.includes(location.pathname);

  const handleShowSearch = () => {
    dispatch(setPreviousPath(`${location.pathname}${location.search}`));
    navigate(searchByNameRoute);
  };

  const handleNewMatch = () => {
    dispatch(resetAllFilters());
    dispatch(resetAge());
    dispatch(resetPage());
    dispatch(resetActiveFilters());
    navigate(absoluteAdminRoutes.patientType);
  };

  const handleProfileBack = () => {
    if (preferences.clinician_profile_back.length > 0) {
      dispatch(removeClinicianProfileBack());
    }
  };

  const specificBackActionOnPath = (path: string) => {
    const regex = /^\/find-care\/intake$/;
    if (regex.test(path)) {
      handleProfileBack();
    }
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.shiftKey && event.key === 'S') {
        handleShowSearch();
      } else if (event.shiftKey && event.key === 'N') {
        handleNewMatch();
      }
    },
    [handleShowSearch, handleNewMatch],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div
      className={`${styles.header} ${withBoxShadow ? styles.boxShadow : ''} ${
        isSticky || isStickyAbie ? styles.stickyHeader : ''
      }`}
    >
      <Container className={clsx(styles.headerContainer, styles.adminHeaderContainer)}>
        <>
          {!isHome && shouldRouteHaveBackButton && (
            <BackButton
              className={styles.back}
              previousRoute={previousRoute}
              onClick={() => specificBackActionOnPath(location.pathname)}
            />
          )}
          <div className={styles.content}>
            <a
              href="http://www.lifestance.com/"
              className={styles.logo}
              target="_blank"
              rel="noreferrer"
            >
              <Logo />
            </a>
            {isHome ? (
              <div className={styles.adminHeaderLogo}>
                <a
                  href="http://www.lifestance.com/"
                  className={styles.mobileLogo}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LogoMobile />
                </a>
                <CircularButton
                  Icon={isTalkingPoint ? TalkingPointsIcon : TalkingPointsOffIcon}
                  isActive={isTalkingPoint}
                  onClick={() => dispatch(setIsTalkingPoint(!isTalkingPoint))}
                />
              </div>
            ) : (
              <div className={styles.headerLogo}>
                <a
                  href="http://www.lifestance.com/"
                  className={styles.mobileLogo}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LogoMobile />
                </a>
              </div>
            )}
            <div className={styles.headerButtons}>
              {!isHome && isSearchEnabled && (
                <button
                  className={styles.trigger}
                  type="button"
                  onClick={handleShowSearch}
                  data-testId="SearchByName"
                  onMouseEnter={() => setShowSearchTooltip(true)}
                  onMouseLeave={() => setShowSearchTooltip(false)}
                >
                  <SearchIcon />
                  <span>Search by name</span>
                  <Tooltip text="Shift+S" show={showSearchTooltip} />
                </button>
              )}
              {!isHome && (
                <div className={styles.newMatchContainer}>
                  <button
                    className={styles.newMatch}
                    onClick={handleNewMatch}
                    onMouseEnter={() => setShowNewMatchTooltip(true)}
                    onMouseLeave={() => setShowNewMatchTooltip(false)}
                  >
                    <NewMatchHomeIcon />
                    <span>Start new match</span>
                  </button>
                  <Tooltip text="Shift+N" show={showNewMatchTooltip} />
                </div>
              )}
            </div>
          </div>
        </>
      </Container>
    </div>
  );
};
