import { ActiveFilter } from 'patientOnboarding/store/cliniciansGlobal/slice';

export const filterByRadius = (
  clinicians: Record<string, unknown>[],
  activeFilters: ActiveFilter[],
) => {
  const distanceFilter = activeFilters?.find((filter) => filter?.name === 'distance');
  if (!distanceFilter || distanceFilter.value === '') return clinicians;

  return clinicians.filter(
    (clinician) => (clinician.distance_in_miles as number) <= (distanceFilter.value as number),
  );
};
