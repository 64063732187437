import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Patient {
  name: string;
  id: string;
}

export interface SessionData {
  patient_info: {
    authorized_patients_list: Patient[];
    selected_patient_id: string;
  };
  error?: string;
}

const initialState: { data: SessionData } = {
  data: {
    patient_info: {
      authorized_patients_list: [],
      selected_patient_id: '',
    },
    error: '',
  },
};

export const sessionSlice = createSlice({
  name: 'PO_session',
  initialState,
  reducers: {
    setSessionData: (state, action: PayloadAction<SessionData>) => {
      state.data = { ...state.data, ...action.payload };
    },
    setSessionDataError: (state, action: PayloadAction<string>) => {
      state.data.error = action.payload;
    },
  },
});

export const sessionActions = sessionSlice.actions;
export default sessionSlice.reducer;
