import clsx from 'clsx';
import { ReactComponent as Question } from 'patientOnboarding/assets/svg/question-line.svg';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

import { Button, Input, InputMask, Label, Tooltip, TooltipModalMobile } from 'lifestance-ui';

import { VerifyingModal } from 'patientOnboarding/components';

import { getClinicianProfile } from 'patientOnboarding/store/matches/reducers';
import {
  getCliniciansByZipCode,
  setErrors,
  setValidZipCode,
  updatePreferences,
  validateZipCode,
} from 'patientOnboarding/store/preferences/actions';

import {
  lifestanceState,
  obieEnabled,
  obieMatches,
  obiePreferencesData,
  selectedClinicianSelector,
} from 'patientOnboarding/selectors/selectors';

import { useDebounce, useMediaQuery } from 'patientOnboarding/hooks';

import {
  disableBodyScroll,
  enableBodyScroll,
  getAge,
  getDateInfo,
  useDevice,
  validateDateStringFormat,
} from 'patientOnboarding/utils';

import modals from '../../zipCodeErrorModals';
import styles from './ViewFilterWall.module.scss';

interface IViewFilterWallProps {
  setNextView: (value: number) => void;
  setModalToShow: (val: string) => void;
}

export const ViewFilterWall: FC<React.PropsWithChildren<IViewFilterWallProps>> = ({
  setNextView,
  setModalToShow,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [ageErrorMessage, setAgeErrorMessage] = useState('');
  const preferences = useSelector(obiePreferencesData);
  const [dateOfBirth, setDateOfBirth] = useState(preferences.preliminaryDOB || '');
  const [zipCode, setZipCode] = useState(preferences.zipCode || '');
  const profile = useSelector(selectedClinicianSelector);
  const enabledObie = useSelector(obieEnabled);
  const enabledState = useSelector(lifestanceState);
  const matches = useSelector(obieMatches);
  const dispatch = useDispatch();
  const { errors, isZipCodeValid, age, cliniciansAvailableOnZipCode } = preferences;
  const { id } = useParams();
  const { isDesktop, isMobile } = useDevice();
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const isTabletMobile = useMediaQuery('(max-width: 1024px)');
  const tooltipText = 'Our providers are specialized in supporting patients of specific ages.';
  const debouncedZipcode = useDebounce(zipCode, 500);
  const appendArticle = (toc: string) => {
    const regexp = /^[aeiou]\w*/i;
    const isVowel = regexp.test(toc);
    return isVowel ? `an ${toc}` : `a ${toc}`;
  };
  const variants = {
    mobile: {
      header: 'Continue booking',
      subHeader: `Let’s make sure that ${profile?.first_name} meets all of your needs before booking.`,
    },
    desktop: {
      header: `Continue Booking ${appendArticle(preferences.preliminaryTOC)} Initial Consultation`,
      subHeader: `First, let’s make sure that ${profile?.first_name} meets all of your needs.`,
    },
  };
  const messages = isTabletMobile ? variants.mobile : variants.desktop;

  useEffect(() => {
    if (errors.zipCode) {
      setErrorMessage(errors.zipCode);
    }
  }, [errors.zipCode, zipCode]);

  const readZipcode = (value: string) => {
    setZipCode(value);
    if (value.length < 5) {
      dispatch(setValidZipCode(false));
      setErrorMessage('Enter a valid 5 digit zip code');
    }
    dispatch(setErrors({ zipCode: '' }));
    setErrorMessage('');
  };

  useEffect(() => {
    if (debouncedZipcode && debouncedZipcode !== '') {
      dispatch(updatePreferences({ zipCode: debouncedZipcode }));
      if (debouncedZipcode.length < 5) {
        setErrorMessage('Enter a valid 5 digit zip code');
        return;
      }
      dispatch(validateZipCode(debouncedZipcode));
      dispatch(getCliniciansByZipCode(debouncedZipcode));
    }
  }, [debouncedZipcode]);

  const handleAge = (event: ChangeEvent<HTMLInputElement>) => {
    const currentString = event.target.value;
    setAgeErrorMessage('');
    setDateOfBirth(currentString);
    setAgeError(!validateDateStringFormat(currentString));
    const { month, day, year } = getDateInfo(currentString);
    const currentAge = getAge(year, month, day);
    dispatch(
      updatePreferences({
        age: currentAge.toString(),
        preliminaryAge: currentAge.toString(),
        preliminaryDOB: currentString,
      }),
    );
  };

  const handleAgeBlur = () => {
    if (ageError) {
      setAgeErrorMessage('Please enter a valid date of birth');
    }
  };

  const checkCompatibility = () => {
    const miliseconds = 2000;
    setIsLoading(true);
    const clinicianId = id?.substring(id.lastIndexOf('-') + 1);
    dispatch(getClinicianProfile(clinicianId, preferences.preliminaryTOC, zipCode));
    setTimeout(() => {
      if (enabledState && enabledObie && cliniciansAvailableOnZipCode === 0) {
        setModalToShow(modals.noClinicians);
      } else if (enabledState && !enabledObie) {
        setModalToShow(modals.noObie);
      } else if (!enabledState) {
        setModalToShow(modals.noServiceState);
      } else {
        setIsVerifying(true);
      }
      setIsLoading(false);
    }, miliseconds);
  };

  const onBlurZipCode = () => {
    if (zipCode.length < 5) {
      setErrorMessage('Enter a valid 5 digit zip code');
    }
  };

  const handleMobileModal = () => {
    setIsMobileModalOpen(!isMobileModalOpen);
  };

  const handleVerifying = () => {
    setIsVerifying(false);
    setNextView(5);
  };

  useEffect(() => {
    if (zipCode.length < 5 || matches.loading || !age || ageError) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [zipCode.length, matches.loading, age]);

  useEffect(() => {
    if (!isDesktop) {
      setTimeout(() => disableBodyScroll(), 500);
    }
    return () => {
      setTimeout(() => enableBodyScroll(), 500);
    };
  }, [isDesktop]);

  const transitionsPuzzle = useTransition([isVerifying], {
    from: { y: -50, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: -50, opacity: 0 },
    reverse: isVerifying,
    delay: 200,
    expires: true,
  });

  return (
    <div className={clsx(styles.container, styles.scrollable)}>
      {!isVerifying && (
        <>
          <div className={styles.header}>{messages.header}</div>
          <span className={styles.subHeader}>{messages.subHeader}</span>
          <div className={styles.firstQuestion}>
            <Label>What is your zip code?</Label>
          </div>
          <Input
            type="zipCode"
            placeholder="e.g. 12345"
            onChange={readZipcode}
            error={errorMessage}
            defaultValue={zipCode}
            onBlur={() => onBlurZipCode()}
            maxlength={5}
          />
          <div className={styles.questions}>
            <Label className={styles.birthQuestion}>What is the patient’s date of birth?</Label>
            {isMobile ? (
              <>
                <Question onClick={handleMobileModal} />
                {isMobileModalOpen && (
                  <TooltipModalMobile
                    message={tooltipText}
                    title="Why are we asking this?"
                    onClose={handleMobileModal}
                  />
                )}
              </>
            ) : (
              <Tooltip content={tooltipText} legendOrientation="up" className={styles.tooltip}>
                <Question />
              </Tooltip>
            )}
          </div>
          <InputMask
            defaultValue={dateOfBirth}
            placeholder="MM/DD/YYYY"
            onChange={handleAge}
            onBlur={handleAgeBlur}
            error={ageErrorMessage}
            mask="99/99/9999"
            inputMode="numeric"
          />
          <div className={styles.button}>
            <Button
              key="buttonForCompatibility"
              fullWidth
              onClick={checkCompatibility}
              disabled={isDisabled}
              isLoading={isLoading}
            >
              Check for compatibility
            </Button>
          </div>
        </>
      )}
      {isVerifying
        && transitionsPuzzle(
          (stylesObject, item) => item && (
          <animated.div style={stylesObject}>
            <VerifyingModal handleOpen={handleVerifying} />
          </animated.div>
          ),
        )}
    </div>
  );
};
