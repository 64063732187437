import clsx from 'clsx';
import { ReactComponent as Vector } from 'patientOnboarding/assets/svg/Vector.svg';
import { ReactComponent as ArrowDown } from 'patientOnboarding/assets/svg/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'patientOnboarding/assets/svg/arrow-up.svg';
import React, { useEffect, useState } from 'react';

import styles from './ExpertiseAreas.module.scss';

type Area = {
  id?: string;
  name: string;
};

interface SpecialityAreasProps {
  expertises: Area[];
  populations: Area[];
  interventions: Area[];
}

export const ExpertiseAreas: React.FC<React.PropsWithChildren<SpecialityAreasProps>> = ({
  expertises,
  populations,
  interventions,
}) => {
  const [showAllOptions, setShowAllOptions] = useState(false);
  const [focusTab, setFocusTab] = useState('expertises');

  const handleExpand = () => {
    setShowAllOptions(true);
  };

  const handleCollapse = () => {
    setShowAllOptions(false);
  };

  const handleTabChange = (tab: string) => {
    setFocusTab(tab);
    setShowAllOptions(false);
  };

  useEffect(() => {
    if (expertises.length === 0) {
      setFocusTab('populations');
    } else if (populations.length === 0 && expertises.length === 0) {
      setFocusTab('interventions');
    }
  }, []);

  return (
    <div className={styles.expertiseAreas}>
      {(expertises.length > 0 || populations.length > 0 || interventions.length > 0) && (
        <div className={styles.heading}>Focus</div>
      )}
      <div
        className={clsx(styles.tabs, {
          [styles.nodata]: expertises.length === 0 && populations.length === 0,
        })}
      >
        {expertises.length > 0 && (
          <button
            type="button"
            className={clsx({
              [styles.active]: focusTab === 'expertises',
            })}
            data-testId="expertiseButton"
            onClick={() => handleTabChange('expertises')}
          >
            Expertise
          </button>
        )}
        {populations.length > 0 && (
          <button
            type="button"
            className={clsx({
              [styles.active]: focusTab === 'populations',
            })}
            data-testId="populationButton"
            onClick={() => handleTabChange('populations')}
          >
            Populations
          </button>
        )}
        {interventions.length > 0 && (
          <button
            type="button"
            className={clsx({
              [styles.active]: focusTab === 'interventions',
            })}
            data-testId="interventionButton"
            onClick={() => handleTabChange('interventions')}
          >
            Interventions
          </button>
        )}
      </div>
      {/* Expertises tab */}
      {expertises.length > 0 || populations.length > 0 || interventions.length > 0 ? (
        <>
          <div
            className={clsx({
              [styles.show]: focusTab === 'expertises',
              [styles.hide]: focusTab !== 'expertises',
            })}
          >
            <div
              className={clsx(styles.areas, {
                [styles.fewItems]: expertises.length <= 4,
              })}
            >
              {expertises.length > 0
                && expertises.map((area) => (
                  <div
                    className={clsx(styles.area, {
                      [styles.expanded]: showAllOptions,
                      [styles.oneRow]: expertises.length <= 4,
                    })}
                    key={area.id}
                  >
                    <Vector className={styles.vector} />
                    <div>{area.name}</div>
                  </div>
                ))}
            </div>
            {!showAllOptions && expertises.length > 8 && (
              <span onClick={handleExpand} data-testId="expandExpertise" className={styles.seeMore}>
                See all
                {' '}
                {expertises.length}
                {' '}
                expertises
                <span className={styles.arrowIcon}>
                  <ArrowDown />
                </span>
              </span>
            )}
            {showAllOptions && (
              <span onClick={handleCollapse} className={styles.seeMore}>
                See less
                <span className={styles.arrowIcon}>
                  <ArrowUp />
                  {' '}
                </span>
                {' '}
              </span>
            )}
          </div>
          {/* End of expertises tab */}

          {/* Populations tab */}
          <div
            className={clsx({
              [styles.show]: focusTab === 'populations',
              [styles.hide]: focusTab !== 'populations',
            })}
          >
            <div
              className={clsx(styles.areas, {
                [styles.fewItems]: populations.length <= 4,
              })}
            >
              {populations.length > 0
                && populations.map((area) => (
                  <div
                    className={clsx(styles.area, {
                      [styles.expanded]: showAllOptions,
                      [styles.oneRow]: populations.length <= 4,
                    })}
                    key={area.id}
                  >
                    <Vector className={styles.vector} />
                    <div>{area.name}</div>
                  </div>
                ))}
            </div>
            {!showAllOptions && populations.length > 8 && (
              <span
                onClick={handleExpand}
                data-testId="expandPopulation"
                className={styles.seeMore}
              >
                See all
                {' '}
                {populations.length}
                {' '}
                populations
                <span className={styles.arrowIcon}>
                  <ArrowDown />
                </span>
              </span>
            )}
            {showAllOptions && (
              <span onClick={handleCollapse} className={styles.seeMore}>
                See less
                <span className={styles.arrowIcon}>
                  <ArrowUp />
                  {' '}
                </span>
                {' '}
              </span>
            )}
          </div>
          {/* End of Populations tab */}

          {/* Interventions tab */}
          <div
            className={clsx({
              [styles.show]: focusTab === 'interventions',
              [styles.hide]: focusTab !== 'interventions',
            })}
          >
            <div
              className={clsx(styles.areas, {
                [styles.fewItems]: interventions.length <= 4,
              })}
            >
              {interventions.length > 0
                && interventions.map((area) => (
                  <div
                    className={clsx(styles.area, {
                      [styles.expanded]: showAllOptions,
                      [styles.oneRow]: interventions.length <= 4,
                    })}
                    key={area.id}
                  >
                    <Vector className={styles.vector} />
                    <div>{area.name}</div>
                  </div>
                ))}
            </div>
            {!showAllOptions && interventions.length > 8 && (
              <span
                onClick={handleExpand}
                data-testId="expandIntervention"
                className={styles.seeMore}
              >
                See all
                {' '}
                {interventions.length}
                {' '}
                interventions
                <span className={styles.arrowIcon}>
                  <ArrowDown />
                </span>
              </span>
            )}
            {showAllOptions && (
              <span onClick={handleCollapse} className={styles.seeMore}>
                See less
                <span className={styles.arrowIcon}>
                  <ArrowUp />
                  {' '}
                </span>
                {' '}
              </span>
            )}
          </div>
          {/* End of Interventions tab */}
        </>
      ) : null}
    </div>
  );
};
