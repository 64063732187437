import clsx from 'clsx';
import React, {
  FocusEventHandler,
  InputHTMLAttributes,
  KeyboardEventHandler,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';

import styles from './Input.module.scss';

declare function OnChangeFunction(value: string, event: React.ChangeEvent<HTMLInputElement>): void;

interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  id?: string;
  type: 'zipCode' | 'age' | 'phone' | 'email' | 'text' | 'search';
  disabled?: boolean;
  placeholder?: string;
  onChange: typeof OnChangeFunction;
  error?: string;
  defaultValue?: string | number | readonly string[] | undefined;
  value?: string | number | readonly string[] | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  testId?: string;
  caution?: string;
  maxlength?: number;
  tabIndex?: number;
  adminInput?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  lastEventType?: string;
  enableTabNavStyling?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id = '',
      disabled,
      type,
      placeholder = '',
      onChange,
      error = '',
      defaultValue = '',
      value,
      onBlur,
      testId = '',
      caution = '',
      maxlength,
      tabIndex,
      adminInput,
      onFocus,
      onKeyDown,
      lastEventType,
      enableTabNavStyling = false,
    },
    ref,
  ) => {
    const [inputType, setInputType] = useState(
      'text' as React.HTMLAttributes<HTMLInputElement>['inputMode'],
    );

    const isValidNumber = (innerValue: string) => /^[0-9]*$/.test(innerValue);
    const [tabbed, setTabbed] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if ((type === 'zipCode' || type === 'age') && !isValidNumber(e.target.value)) {
        return;
      }

      if (type === 'zipCode' && e.target.value.length > 5) {
        return;
      }
      onChange(e.target.value, e);
    };

    useEffect(() => {
      switch (type) {
        case 'zipCode':
        case 'age':
          setInputType('numeric');
          break;
        case 'phone':
          setInputType('tel');
          break;
        case 'email':
          setInputType('email');
          break;
        case 'search':
          setInputType('search');
          break;
        default: {
          setInputType('text');
        }
      }
    }, []);

    const onInputFocus: FocusEventHandler<HTMLInputElement> = (event) => {
      setTabbed(lastEventType === 'tab');
      if (onFocus) onFocus(event);
    };

    const onInputBlur: FocusEventHandler<HTMLInputElement> = (event) => {
      setTabbed(false);
      if (onBlur) onBlur(event);
    };

    return (
      <>
        <input
          id={id}
          ref={ref}
          data-cy={testId}
          tabIndex={tabIndex}
          data-testId={`input${testId}`}
          value={value}
          type={inputType === 'search' ? 'search' : 'text'}
          className={clsx(styles.input, {
            [styles.error]: error,
            [styles.caution]: caution,
            [styles.adminInput]: adminInput,
            [styles.tabbed]: tabbed && enableTabNavStyling,
          })}
          placeholder={placeholder}
          onChange={handleChange}
          defaultValue={defaultValue}
          onBlur={onInputBlur}
          inputMode={inputType}
          maxLength={maxlength}
          disabled={disabled}
          onFocus={onInputFocus}
          onKeyDown={onKeyDown}
          onClick={() => setTabbed(false)}
        />
        {error && error.length && (
          <span data-testId={`input${testId}Error`} className={clsx({ [styles.error]: error })}>
            {error}
          </span>
        )}
        <span data-testId={`input${testId}Caution`} className={clsx({ [styles.caution]: caution })}>
          {caution}
        </span>
      </>
    );
  },
);
