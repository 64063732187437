import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AppointmentCalendar,
  AppointmentSelector,
  Button,
  CircularLoading,
  Dropdown,
  Modal,
} from 'lifestance-ui';

import { LocationDropdown } from 'patientOnboarding/components';
import { NoAvailabilityBox } from 'patientOnboarding/components/ClinicianProfile/components/NoAvailability/NoAvailability';
import { TypesOfCareMeta } from 'patientOnboarding/components/ClinicianProfile/typesOfCareMeta';

import { getClinicianAvailabilities } from 'patientOnboarding/store/matches/actions';
import { updatePreferences } from 'patientOnboarding/store/preferences/actions';
import { updateReservation } from 'patientOnboarding/store/reservationGlobal/actions';

import {
  availabilities,
  loadingAvailabilities,
  obieClinicianAvailableDates,
  obiePreferencesData,
  reservationData,
  selectedClinicianSelector,
} from 'patientOnboarding/selectors/selectors';

import { formatDuration, formatNameOfDay, sortAvailabilities } from 'patientOnboarding/utils';

import styles from './Schedule.module.scss';

interface IScheduleProps {
  onCancel: any;
  onBack: string;
  onNext: string;
  handleOpen: (value: boolean) => void;
}

type SelectedFacilities = {
  isVideoOnly: boolean;
  facilityIds: number[];
};

export const Schedule: FC<React.PropsWithChildren<IScheduleProps>> = ({
  onCancel,
  onBack = '',
  onNext = '',
  handleOpen,
}) => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const nonSortedClinicianAvailabilities = useSelector(availabilities);
  const clinicianAvailabilities = nonSortedClinicianAvailabilities
    ? sortAvailabilities(nonSortedClinicianAvailabilities)
    : [];
  const clinicianAvailableDates = useSelector(obieClinicianAvailableDates);
  const isLoadingAvailabilities = useSelector(loadingAvailabilities);
  const preferences = useSelector(obiePreferencesData);
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [timesAvailable, setTimesAvailable] = useState([] as any[]);
  const [hourSelected, setHourSelected] = useState('');
  const [view, setView] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [calendarLoaded, setCalendarLoaded] = useState(false);
  const [duration, setDuration] = useState<number>();
  const [noAvailability, setNoAvailability] = useState<boolean>(false);
  const reservation = useSelector(reservationData);
  const profile = useSelector(selectedClinicianSelector);
  const videoOption = reservation.isVideoOnly;

  // LOCATION DROPDOWN
  const [isDisabledLocation, setIsDisabledLocation] = useState(true);
  const [selectedFacilities, setSelectedFacilities] = useState<SelectedFacilities>({} as any);

  const typeOfCareOptions = profile?.type_of_cares?.map((typeOfCare: string) => ({
    value: typeOfCare,
    label: TypesOfCareMeta[typeOfCare]?.label,
    description: TypesOfCareMeta[typeOfCare]?.description,
  }));

  const isColdLanding = preferences?.location_back?.length === 0
    || preferences?.location_back.includes('/find-care/booking/search');
  const [selectedTypeOfCare, setSelectedTypeOfCare] = useState(
    isColdLanding && !preferences.zipCode ? typeOfCareOptions[0].value : preferences.typeOfCare,
  );
  const getFormattedDate = (pickedDate: Date) => new Date(pickedDate)?.toISOString()?.substring(0, 10);

  const getClinicianAvailabilitiesByDate = (dateSelected: string, typeOfCareValue?: string) => {
    const typeOfCare = typeOfCareValue || preferences.typeOfCare;
    dispatch(
      getClinicianAvailabilities({
        facilityIds: videoOption
          ? profile.facility_location.map((o: any) => o.facility_id)
          : reservation.facilityIds,
        clinicianId: profile.id,
        availableDate: dateSelected,
        typeOfCare,
        videoOption,
      }),
    );
  };

  const handleTypeOfCare = (typeOfCare: string) => {
    setSelectedTypeOfCare(typeOfCare);
    dispatch(updatePreferences({ typeOfCare }));
    getClinicianAvailabilitiesByDate(getFormattedDate(new Date()), typeOfCare);
    setCalendarLoaded(false);
  };

  const handleHour = (slot: any) => {
    const { duration, date, availabilityStatus } = slot;
    const humanDuration = formatDuration(duration);
    setDuration(humanDuration);
    setHourSelected(date);
    setIsAvailable(availabilityStatus);
    const facility = profile.facility_location.find((f: any) => f.facility_id === slot.facility_id);
    dispatch(
      updateReservation({
        ...slot,
        availability: slot.availabilityStatus,
        selectedFacility: facility,
      }),
    );
  };

  const handleSelectDate = (dateSelected: Date) => {
    const now = new Date(dateSelected);
    const utc = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    getClinicianAvailabilitiesByDate(getFormattedDate(utc));
    setStartDate(dateSelected);
  };

  const handleBack = (currentView: number) => {
    if (currentView === 0) {
      onCancel();
    } else {
      setView(0);
    }
  };

  useEffect(() => {
    if (clinicianAvailabilities && clinicianAvailabilities.length > 0) {
      const times = [] as any[];
      clinicianAvailabilities.forEach((item: any) => {
        if (item.virtual_or_video_visit === 1) {
          times.push({
            ...item,
            modality: 'VIDEO',
            slotStartTime: item.appointment_start_time,
            slotEndTime: item.appointment_end_time,
            date: item.available_date,
            availabilityStatus: true,
          });
        }
        if (item.in_person_visit === 1 && !reservation?.isVideoOnly) {
          times.push({
            ...item,
            modality: 'IN-OFFICE',
            slotStartTime: item.appointment_start_time,
            slotEndTime: item.appointment_end_time,
            date: item.available_date,
            availabilityStatus: true,
          });
        }
      });
      setDuration(formatDuration(times[0]?.duration));
      setTimesAvailable(times);
      times.forEach((t: any) => {
        t?.clinician_availability_key === reservation?.clinician_availability_key
          ? setHourSelected(reservation?.available_date as string)
          : null;
      });
    } else if (clinicianAvailabilities && clinicianAvailabilities.length === 0) {
      setTimesAvailable([]);
    }
  }, [nonSortedClinicianAvailabilities]);

  useEffect(() => {
    if (profile && reservation.facilityIds?.length >= 1) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 400);

      // Getting first date
      const availabilites: any = [];
      profile?.facility_location
        ?.filter((f: any) => reservation.facilityIds?.includes(f?.facility_id))
        ?.forEach((o: any) => {
          o.clinician_availabilities.forEach((u: any) => {
            availabilites.push(u);
          });
        });

      const dates = sortAvailabilities(availabilites);
      const firstDate = dates[0]?.available_date;

      const date = new Date(`${firstDate?.replaceAll('-', '/')}`);
      handleSelectDate(new Date(date.setDate(date.getDate())));
      setCalendarLoaded(true);
      setNoAvailability(false);
    } else {
      setNoAvailability(true);
    }
  }, [reservation.facilityIds]);

  useEffect(() => {
    if (hourSelected.length === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [hourSelected]);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return isOpen ? (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      variant={view === 0 ? 'close' : 'back'}
      onBack={() => handleBack(view)}
      title="Availability"
      testId="modal"
    >
      <div className={styles.container}>
        {!isColdLanding ? (
          <>
            <div className={styles.headingDesktop}>
              <h1 className={styles.title}>Select a date and time</h1>
            </div>
            <div className={styles.headingMobile}>
              <h1 className={styles.title}>{view === 0 ? 'Choose a date' : 'Choose a time'}</h1>
            </div>
            <p className={styles.description}>
              This will be a
              {' '}
              {duration}
              -minute consultation appointment
            </p>
          </>
        ) : (
          <div className={styles.coldLanding}>
            <div className={styles.coldTitle}> Choose a Date and Time</div>
            <div className={styles.coldDescription}>
              {preferences.typeOfCare}
              {' '}
              Initial Consultation
            </div>
          </div>
        )}
        <div className={styles.multipleLocationsHeader}>
          <div className={styles.headerM}>
            <div className={styles.titleM}> Choose a Date and Time</div>
            <div className={styles.descriptionM}>
              {preferences.typeOfCare}
              {' '}
              Initial Consultation
            </div>
          </div>
          <div className={styles.multipleLocationContainer}>
            <LocationDropdown
              placeholder="Select a location"
              value=""
              testId="scheduleLocations"
              setIsDisabledLocation={setIsDisabledLocation}
              setFacilities={setSelectedFacilities}
              scheduleModal
            />
          </div>
        </div>
        <div className={styles.content}>
          <div
            className={clsx(styles.calendar, {
              [styles.windowVisible]: view === 0,
            })}
          >
            <div className={styles.appointmentType}>
              {isColdLanding ? (
                <div className={styles.dropdownContainer}>
                  <h3 className={styles.reason}>What is the reason for your visit?</h3>
                  <Dropdown
                    options={typeOfCareOptions}
                    placeholder="Select an option"
                    onChange={handleTypeOfCare}
                    title="Reason for your visit"
                    icon="CloseIcon"
                    value={selectedTypeOfCare}
                    sideWays
                    testId="typeOfCare"
                    disabled={!isColdLanding}
                  />
                </div>
              ) : (
                <div className={styles.appointmentNote}>
                  <h3 className={styles.appointmentTypeTitle}>Appointment Type</h3>
                  <p className={styles.appointmentTypeDesc}>{preferences.typeOfCare}</p>
                </div>
              )}
              {/* TODO Add functionality * /
                <div className={styles.appointmentCategories}>
                  <div className={styles.appointmentCategory}>
                    <div>
                      <BuildingIcon className={styles.modalityIcon} />
                      <span>In Office</span>
                    </div>
                    <div className={styles.option}>
                      <input
                        type="checkbox"
                        id="valueA"
                        value="IN-OFFICE"
                        onChange={(e) => handleModality(e.target.value)}
                      />
                      <label htmlFor="valueA">
                        <div className={styles.checkbox} />
                      </label>
                    </div>
                  </div>
                  <div className={styles.appointmentCategory}>
                    <div>
                      <VideoChatIcon className={styles.modalityIcon} />
                      <span>Video</span>
                    </div>
                    <div className={styles.option}>
                      <input
                        type="checkbox"
                        id="valueB"
                        value="VIDEO"
                        onChange={(e) => handleModality(e.target.value)}
                      />
                      <label htmlFor="valueB">
                        <div className={styles.checkbox} />
                      </label>
                    </div>
                  </div>
                </div>
                /* */}
            </div>
            {isLoadingAvailabilities && <CircularLoading />}
            {((!isLoadingAvailabilities && clinicianAvailableDates.length === 0)
              || !noAvailability) && (
              <AppointmentCalendar
                formatWeekDay={formatNameOfDay}
                startDate={startDate}
                minDate={clinicianAvailableDates[0]}
                includeDates={clinicianAvailableDates}
                handleSelectDate={(date) => handleSelectDate(date)}
              />
            )}
            {((!isLoadingAvailabilities && clinicianAvailableDates.length === 0)
              || noAvailability) && (
              <NoAvailabilityBox firstName={profile.first_name} typeOfCare={selectedTypeOfCare} />
            )}
          </div>
          <div
            className={clsx(styles.appointmentsContainer, {
              [styles.windowVisible]: view === 1,
            })}
          >
            {((!isLoadingAvailabilities && clinicianAvailableDates.length === 0)
              || !noAvailability) && (
              <>
                <div className={styles.loading}>{loading ? <CircularLoading /> : null}</div>
                <div
                  className={clsx({
                    [styles.lowOpacity]: loading,
                  })}
                >
                  <AppointmentSelector
                    startDate={startDate}
                    timesAvailable={timesAvailable}
                    reservation={reservation}
                    handleHour={handleHour}
                    hourSelected={hourSelected}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.buttonsDesktop}>
          <Button secondary size="medium" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            size="medium"
            disabled={isDisabled}
            onClick={() => {
              handleOpen(true);
              onCancel();
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  ) : null;
};
