import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { reservationData } from 'patientOnboarding/selectors/selectors';

export const useAppointmentStatus = () => {
  const [hasAppointmentData, seHasAppointmentData] = useState<boolean>(true);
  const reservation = useSelector(reservationData);

  useEffect(() => {
    const hasData = Boolean(
      reservation && reservation.appointment_start_time && reservation.appointment_end_time,
    );
    seHasAppointmentData(hasData);
  }, [reservation]);

  return hasAppointmentData;
};
