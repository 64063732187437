import { useMsal } from '@azure/msal-react';
import { adminRoutes as absoluteAdminRoutes } from 'intakeOptimization/routes/absolutes';
import AccountHolderInfo from 'intakeOptimization/views/AccountHolderInfo/AccountHolderInfo';
import { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { BackButton, CircularLoading } from 'lifestance-ui';

import { ProtectedRoute } from 'intakeOptimization/components';

import { setIsAdmin } from 'intakeOptimization/store/admin/actions';
import { updateFilters } from 'intakeOptimization/store/filters/actions';

import { abieAdminConfig } from 'intakeOptimization/selectors/selectors';

import { usePreviousRoute } from 'intakeOptimization/hooks';

import { getTimezoneOffset } from 'intakeOptimization/utils';
import { useDevice } from 'intakeOptimization/utils/useDevice';

import styles from './Admin.module.scss';
import ADMIN_ROUTES from './Admin.routes';
import { getMicrosoftProfile } from 'intakeOptimization/api/v2/abie/msal';

const Admin = () => {
  const previousRoute = usePreviousRoute();
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const location = useLocation();
  const { paymentMethod } = useSelector(abieAdminConfig);
  const [state, setState] = useState({
    hasBackButton: false,
  });

  useEffect(() => {
    if (window && window.heap) {
      const getProfile = async () => {
        const profile = await getMicrosoftProfile();
        if (profile.data) {
          window.heap.identify(profile.data.mail);
        }
      };
      getProfile();
    }
  }, []);

  useEffect(() => {
    if (
      [
        absoluteAdminRoutes.insuranceCard,
        absoluteAdminRoutes.confirmation,
        absoluteAdminRoutes.patientType,
        absoluteAdminRoutes.searchResults,
        absoluteAdminRoutes.consents,
        absoluteAdminRoutes.review,
      ].includes(location.pathname)
    ) {
      setState({
        hasBackButton: false,
      });
    }
    if ([absoluteAdminRoutes.consents].includes(location.pathname)) {
      setState({
        hasBackButton: paymentMethod !== 'self_pay',
      });
    }
    if ([absoluteAdminRoutes.filters].includes(location.pathname)) {
      setState({
        hasBackButton: true,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.includes('admin')) {
      dispatch(setIsAdmin(true));
      dispatch(updateFilters({ utc_offset: getTimezoneOffset() }));
    }
  }, []);

  const { instance } = useMsal();

  const acquireAccessToken = async () => {
    const activeAccount = instance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = instance.getAllAccounts();
    if (!activeAccount && accounts.length === 0) {
      console.error('No accounts found. Please login.');
      return;
    }
    const request = {
      scopes: ['User.Read'],
      account: activeAccount || accounts[0],
    };
    const authResult = await instance.acquireTokenSilent(request);
    return authResult.accessToken;
  };

  useEffect(() => {
    const getToken = async () => {
      const token = await acquireAccessToken();
      localStorage.setItem('ms-token', token);
    };
    getToken();
  }, []);

  return (
    <>
      {!isMobile && state.hasBackButton && (
        <div className={styles.backContainer}>
          <BackButton withText className={styles.back} previousRoute={previousRoute} />
        </div>
      )}
      <Suspense fallback={<CircularLoading size="xlarge" />}>
        <Routes>
          <Route path="/" element={<Navigate to="patient" />} />
          {ADMIN_ROUTES.map(({ featureFlag, path, Component }) => (
            <Route
              key={`p-${path}`}
              path={path}
              element={(
                <ProtectedRoute featureFlag={featureFlag}>
                  <Suspense fallback={<CircularLoading size="xlarge" />}>
                    <Component />
                  </Suspense>
                </ProtectedRoute>
              )}
            />
          ))}
          <Route path="intake/*" element={<AccountHolderInfo />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default Admin;
