import { ReactComponent as Illustration } from 'patientOnboarding/assets/svg/illustration.svg';
import React, { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, CircularLoading, Container } from 'lifestance-ui';

import t from 'modules/application/store/errorHandlers/types';

import styles from './ErrorHandlers.module.scss';

const ErrorHandlerView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAdmin } = useSelector((state) => state.admin?.config);
  const handleReturnToHome = () => {
    if (isAdmin) {
      navigate('/admin/patient');
    } else {
      navigate('/');
    }
    const payload = { status: null, error: null };
    dispatch({ type: t.THROW_API_ERROR, payload });
  };
  return (
    <Suspense fallback={<CircularLoading size="xlarge" />}>
      <Container className={styles.container}>
        <div className={styles.rounded}>
          <Illustration style={{ margin: '0px auto' }} />
        </div>
        <h2>Just a friendly error</h2>
        <p>Looks like that page is not working.</p>
        <p>Let’s try that again.</p>
        <Button className={styles.button} onClick={() => handleReturnToHome()}>
          Return Home
        </Button>
      </Container>
    </Suspense>
  );
};

export default ErrorHandlerView;
