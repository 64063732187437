import clsx from 'clsx';
import React, { ReactElement } from 'react';
import styles from './Container.module.scss';

interface ContainerProps {
  children: ReactElement | ReactElement[] | null
  className?: string
  sm?: boolean
  fullWidth?: boolean
}

export const Container: React.FC<React.PropsWithChildren<ContainerProps>> = ({
  children,
  className,
  sm,
  fullWidth = false,
}) => (
  <div
    className={clsx(
      styles.container,
      className,
      { [styles.small]: sm,
      },
      fullWidth && styles.fullWidth,
    )}
  >
    {children}
  </div>
);
