import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import t from './types';

type IInitialState = {
  status: string;
  error: string;
  redirectUrl: string;
};

const initialState = {
  status: '',
  error: '',
  redirectUrl: '',
};

// const errorSlice = createSlice({
//   name: 'error',
//   initialState,
//   reducers: {
//     throwAPIError: (state, action: PayloadAction<IInitialState>) => {
//       state.status = action.payload.status;
//       state.error = action.payload.error;
//       state.redirectUrl = action.payload.redirectUrl;
//     },
//   },
// });

const errorHandlerReducer = (state = initialState, action: PayloadAction<IInitialState>) => {
  switch (action.type) {
    case t.THROW_API_ERROR:
      console.log('--== errorHandlerReducer ', action);
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default errorHandlerReducer;
