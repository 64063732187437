import clsx from 'clsx';
import { ReactComponent as LightbulbIcon } from 'patientOnboarding/assets/svg/lightbulb-line.svg';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SpanButton } from 'lifestance-ui';

import { Modal } from 'patientOnboarding/components';

import {
  contactInfo as contactInfoSelector,
  obiePreferencesData,
  reservationData,
} from 'patientOnboarding/selectors/selectors';

import { useInvoca } from 'patientOnboarding/hooks';

import {
  defaultPhoneNumber,
  formatContactNumber,
  getContactInfoByState,
} from 'patientOnboarding/utils';

import styles from './PatientIsPolicyholder.module.scss';

interface Props {
  name: string;
  lastname: string;
  error: {
    status?: number;
    message?: string;
  };
  patientIsPolicyholder: boolean;
}

export const PatientIsPolicyholder: FC<React.PropsWithChildren<Props>> = ({
  name = '',
  lastname = '',
  error,
  patientIsPolicyholder,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const contactInfo = useSelector(contactInfoSelector);
  const reservation = useSelector(reservationData);
  const preferences = useSelector(obiePreferencesData);
  const contactInfoByState = getContactInfoByState(
    contactInfo,
    reservation.selectedFacility?.state as string,
    reservation.license_key as number,
  );
  const contactNumber = preferences.marketingReferralPhone.length > 0
    ? preferences.marketingReferralPhone
    : contactInfoByState?.intake_call_in_number;

  useEffect(() => {
    setIsModalOpen(patientIsPolicyholder);
    return () => {
      setIsModalOpen(false);
    };
  }, [error, patientIsPolicyholder]);

  useInvoca();

  return (
    <Modal
      modalOpen={isModalOpen}
      title=""
      closeModal={() => {
        setIsModalOpen(false);
      }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.iconContainer}>
          <LightbulbIcon />
        </div>
        <h1 className={styles.title}>Account holder already exists</h1>
        <p className={styles.text}>
          Looks like
          {' '}
          {name}
          {' '}
          {lastname}
          {' '}
          is an existing responsible party in our system. Call us
          at&nbsp;
          <span className={styles.numberMobile}>
            <a
              className={clsx(styles.phone, 'invocaPhoneNumber')}
              href={`tel:${formatContactNumber(contactNumber) || ''}`}
            >
              <SpanButton>{contactNumber || defaultPhoneNumber}</SpanButton>
            </a>
          </span>
          <span className={styles.number}>{contactNumber || defaultPhoneNumber}</span>
          {' '}
          to finish
          booking this appointment.
        </p>
      </div>
    </Modal>
  );
};
