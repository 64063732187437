const types = {
  VALIDATE_ZIP_INSURANCE: '@@IO_INSURANCE/VALIDATE_ZIP_INSURANCE',
  SET_ADDRESS_DATA: '@@IO_INSURANCE/SET_ADDRESS_DATA',
  SET_ERROR: '@@IO_INSURANCE/SET_ERROR',
  GET_INSURANCE_FILTERS: '@@IO_INSURANCE/GET_INSURANCE_FILTERS',
  SET_INSURANCE_FILTERS: '@@IO_INSURANCE/SET_INSURANCE_FILTERS',
  SAVE_INSURANCE_CARD: '@@IO_INSURANCE/SAVE_INSURANCE_CARD',
  SET_INSURANCE_CARDS: '@@IO_INSURANCE/SET_INSURANCE_CARDS',
  GET_LICENSE_KEY_RULES: '@@IO_INSURANCE/GET_LICENSE_KEY_RULES',
  SET_INSURANCE_SKIP_FLAG: '@@IO_INSURANCE/SET_INSURANCE_SKIP_FLAG',
  SET_SHOW_CCOF_FLAG: '@@IO_INSURANCE/SET_SHOW_CCOF_FLAG',
  SET_HIDE_SKIP_BUTTON_CCOF: '@@IO_INSURANCE/SET_HIDE_SKIP_BUTTON_CCOF',
};

export interface FilterDataPayload {
  zipCode: string;
  typeOfCare: string;
  patientType: string;
}

export interface InsuranceCardPayload {
  id: number;
  frontCard: File;
  backCard: File;
  system: string;
}

export interface FiltersDTO {
  concerns: Array<{ name: string }>;
  populations: Array<{ name: string }>;
  interventions: Array<{ name: string }>;
  focusOptions: Array<{ name: string; options: Array<{ label: string; value: string }> }>;
  marketing_referrals: Array<{
    display_marketing_referral: string;
    amd_marketing_referral: string;
  }>;
  gender_identity: string[];
}

export interface LicenseKeyRulesDTO {
  insurance_skip_option_flag: boolean;
  enable_credit_card_on_file: boolean;
  disable_skip_credit_card: boolean;
}

export default types;
