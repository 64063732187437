import clsx from 'clsx';
import { ReactComponent as LightbulbIcon } from 'intakeOptimization/assets/svg/lightbulb-line.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SpanButton } from 'lifestance-ui';

import { IntakeHeader } from 'intakeOptimization/components';

import { trackPatientStatus } from 'intakeOptimization/store/admin/actions';

import {
  abieChildPatientId,
  abiePatientData,
  abiePatientId,
  abiePatientType,
} from 'intakeOptimization/selectors/selectors';

import styles from './EmailVerification.module.scss';

const EmailVerification = () => {
  const dispatch = useDispatch();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const patientData = useSelector(abiePatientData);
  const patientType = useSelector(abiePatientType);
  const isChild = patientType === 'child';
  const patientId = useSelector(isChild ? abieChildPatientId : abiePatientId) as number;
  const contentHeader = {
    title: 'Appointment is booked',
    description:
      'The patient must complete intake steps or their appointment may be cancelled before the visit.',
  };

  const handleOpenModal = () => {
    setIsModalOpened(true);
  };
  const handleCloseModal = () => {
    setIsModalOpened(false);
    setIsResend(true);
  };

  useEffect(() => {
    dispatch(
      trackPatientStatus({
        patientId,
        intakeStatus: 'email_verification_sent',
      }),
    );
  }, []);

  return (
    <div className={styles.container}>
      <IntakeHeader
        testId="EmailVerification"
        title={contentHeader.title}
        description={contentHeader.description}
      />
      {isResend && (
        <div className={clsx(styles.troubleContainer)}>
          <LightbulbIcon />
          <div>
            <p className={styles.troubleTitle} data-testId="trouble">
              Still having trouble?
            </p>
            <p className={styles.troubleCTA}>
              Call us at
              {' '}
              <a className={styles.troublePhone} data-testId="phoneNumber" href="tel:8444685050">
                (844) 468–5050
              </a>
            </p>
          </div>
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.content_top}>
          <p className={styles.bold} data-testId="emailSent">
            Email has been sent
            {' '}
          </p>
          <p className={styles.text} data-testId="emailReceived">
            Confirm that the patient has received the email with next steps at
            {' '}
            <span className={styles.bold}>{patientData.email}</span>
          </p>
        </div>
        <div className={styles.content_middle}>
          <p className={styles.bold} data-testId="nextSteps">
            Next Steps
          </p>
          <ul className={styles.list} data-testId="nextStepsList">
            <li>Patient must complete intake in email</li>
            <li>Review cancellation policy</li>
            <li>Review how to join telehealth</li>
          </ul>
        </div>

        {!isResend && (
          <button
            onClick={handleOpenModal}
            className={styles.resend_button}
            data-testId="patientEmail"
          >
            <SpanButton>Patient didn’t receive an email.</SpanButton>
          </button>
        )}
      </div>
      <div className={styles.whiteSpace} />
      {/* {isModalOpened && <ReSendEmail closeModal={handleCloseModal} />} */}
    </div>
  );
};

export default EmailVerification;
