import { useEffect, useState } from 'react';

export const useLastKeyDown = () => {
  const [lastKeyDown, setLastKeyDown] = useState<string>('');

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      // Check if the tab key was the last pressed
      setLastKeyDown(e.key);
    });
  }, []);

  return { lastKeyDown };
};
