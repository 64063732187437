import { all, fork } from 'redux-saga/effects';

import abieadminConfigSaga from 'intakeOptimization/store/admin/sagas';
import abiecliniciansSaga from 'intakeOptimization/store/clinicians/sagas';
import abieobieCliniciansSaga from 'intakeOptimization/store/cliniciansGlobal/sagas';
import abiefiltersSaga from 'intakeOptimization/store/filters/sagas';
import abieinsuranceSaga from 'intakeOptimization/store/insurance/sagas';
import abiematchesSaga from 'intakeOptimization/store/matches/sagas';
import abiepreferencesSaga from 'intakeOptimization/store/preferences/sagas';
import abieadminReservationSaga from 'intakeOptimization/store/reservation/sagas';
import abiereservationSaga from 'intakeOptimization/store/reservationGlobal/sagas';
import abiesearchesSaga from 'intakeOptimization/store/searches/sagas';
import abiesessionSaga from 'intakeOptimization/store/session/sagas';
import abieadminUserSaga from 'intakeOptimization/store/user/sagas';
import abieuserSaga from 'intakeOptimization/store/userGlobal/sagas';
import obieclinicianSaga from 'modules/establishedPatients/store/clinician/sagas';
import obieadminConfigSaga from 'patientOnboarding/store/admin/sagas';
import obiecliniciansSaga from 'patientOnboarding/store/clinicians/sagas';
import obieobieCliniciansSaga from 'patientOnboarding/store/cliniciansGlobal/sagas';
import obiefiltersSaga from 'patientOnboarding/store/filters/sagas';
import obieinsuranceSaga from 'patientOnboarding/store/insurance/sagas';
import obiematchesSaga from 'patientOnboarding/store/matches/sagas';
import obiepatientSaga from 'patientOnboarding/store/patient/sagas';
import obiepreferencesSaga from 'patientOnboarding/store/preferences/sagas';
import obieadminReservationSaga from 'patientOnboarding/store/reservation/sagas';
import obiereservationSaga from 'patientOnboarding/store/reservationGlobal/sagas';
import obiesearchesSaga from 'patientOnboarding/store/searches/sagas';
import obiesessionSaga from 'patientOnboarding/store/session/sagas';
import obieadminUserSaga from 'patientOnboarding/store/user/sagas';
import obieuserSaga from 'patientOnboarding/store/userGlobal/sagas';

export function* rootSaga() {
  yield all([
    // OBIE
    fork(obieadminConfigSaga),
    fork(obiecliniciansSaga),
    fork(obiefiltersSaga),
    fork(obieadminReservationSaga),
    fork(obieadminUserSaga),
    fork(obieobieCliniciansSaga),
    fork(obiematchesSaga),
    fork(obiepreferencesSaga),
    fork(obiesearchesSaga),
    fork(obiesessionSaga),
    fork(obieclinicianSaga),
    fork(obiepatientSaga),
    fork(obieinsuranceSaga),
    fork(obiereservationSaga),
    fork(obieuserSaga),
    // ABIE
    fork(abieadminConfigSaga),
    fork(abiecliniciansSaga),
    fork(abiefiltersSaga),
    fork(abieadminReservationSaga),
    fork(abieadminUserSaga),
    fork(abieobieCliniciansSaga),
    fork(abiematchesSaga),
    fork(abiepreferencesSaga),
    fork(abiesearchesSaga),
    fork(abiesessionSaga),
    fork(abieinsuranceSaga),
    fork(abiereservationSaga),
    fork(abieuserSaga),
  ]);
}
