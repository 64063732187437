const types = {
  GET_ALL_CLINICIANS: '@@PO_clinicians/GET_ALL_CLINICIANS',
  GET_CLINICIANS_COUNT: '@@PO_clinicians/GET_CLINICIANS_COUNT',
  LOAD_CLINICIANS: '@@PO_clinicians/LOAD_CLINICIANS',
  SET_LOADING_CLINICIANS: '@@PO_CLINICIAN_MATCHES/SET_LOADING_CLINICIANS',
  SET_ERROR: '@@PO_CLINICIAN_MATCHES/SET_ERROR',
  SET_SEARCH_TERM: '@@PO_CLINICIAN_MATCHES/SET_SEARCH_TERM',
  RESET_CLINICIANS: '@@PO_CLINICIAN_MATCHES/RESET_CLINICIANS',
  GET_OTHER_CLINICIANS: '@@PO_CLINICIAN_MATCHES/GET_OTHER_CLINICIANS',
  GET_FILTERED_CLINICIANS_OBIE: '@@PO_CLINICIAN_MATCHES/GET_FILTERED_CLINICIANS_OBIE',
  GET_MORE_FILTERED_CLINICIANS_OBIE: '@@PO_CLINICIAN_MATCHES/GET_MORE_FILTERED_CLINICIANS_OBIE',
  GET_DISPLAYED_CLINICIANS_OBIE: '@@PO_CLINICIAN_MATCHES/GET_DISPLAYED_CLINICIANS_OBIE',
};

export default types;
