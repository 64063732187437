import clsx from 'clsx';
import { ReactComponent as SortIcon } from 'intakeOptimization/assets/svg/arrow-up-down-line.svg';
import React, {
  FC,
  KeyboardEvent,
  MutableRefObject,
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { MobileDropdown } from 'lifestance-ui';

import { onClickOut, simpleKeyboardControl, useDevice } from 'intakeOptimization/utils';

import styles from './SortDropdown.module.scss';

type Option = {
  label: string;
  value: string;
};
interface ISortDropDownProps {
  onChange: any;
  options: Option[];
  value: string;
  testId?: string;
  classNameContainer?: string;
}

export const SortDropdown: FC<React.PropsWithChildren<ISortDropDownProps>> = ({
  onChange,
  options,
  value,
  testId,
  classNameContainer = '',
}) => {
  const { isMobile, isTablet, isSmallTablet } = useDevice();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedLabel, setSelectedLabel] = useState<Option | undefined>();
  const optionRefs = useRef<MutableRefObject<HTMLLIElement>[]>([]);
  const triggerRef = useRef(null);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleSelect = (value: any) => {
    document.body.style.overflow = 'initial';
    setSelectedValue(value);
    onChange(value);
    setOpen(!open);
  };

  const mobileClose = () => {
    document.body.style.overflow = 'initial';
    setOpen(!open);
  };

  const navigateToNextElement = (refObj: MutableRefObject<HTMLLIElement>) => {
    const currentIndex = optionRefs.current.findIndex((el) => el.current === refObj.current);
    if (currentIndex < optionRefs.current.length - 1) {
      const nextIndex = optionRefs.current.findIndex((el) => el.current === refObj.current) + 1;
      optionRefs.current[nextIndex].current.focus();
    }
  };

  const navigateToPrevElement = (refObj: MutableRefObject<HTMLLIElement>) => {
    const currentIndex = optionRefs.current.findIndex((el) => el.current === refObj.current);
    if (currentIndex !== 0) {
      const nextIndex = optionRefs.current.findIndex((el) => el.current === refObj.current) - 1;
      optionRefs.current[nextIndex].current.focus();
    }
  };

  const handleNavigation = (evt: KeyboardEvent, refObj: MutableRefObject<HTMLLIElement>) => {
    const { key } = evt;
    switch (key) {
      case ' ':
        evt.preventDefault();
        handleSelect(refObj.current.dataset.value as string);
        break;
      case 'Escape':
      case 'Enter':
        evt.preventDefault();
        handleSelect(refObj.current.dataset.value as string);
        break;
      case 'ArrowDown':
        evt.preventDefault();
        navigateToNextElement(refObj);
        break;
      case 'ArrowUp':
        evt.preventDefault();
        navigateToPrevElement(refObj);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (value !== '') {
      setSelectedValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (open) {
      return onClickOut({
        triggerRef,
        action: () => setOpen(false),
        ignoreParent: true,
      });
    }
  }, [open, triggerRef]);

  useEffect(() => {
    const currentLabel = options?.find((option) => selectedValue === option.value);
    setSelectedLabel(currentLabel);
  }, [selectedValue]);

  useEffect(() => {
    if (optionRefs?.current?.length !== options?.length) {
      optionRefs.current = Array(options?.length)
        .fill(options)
        .map((_, i) => optionRefs?.current[i] || createRef());
    }
  }, []);

  return (
    <>
      <div className={clsx(styles.container, classNameContainer)} ref={dropdownRef}>
        <div
          className={clsx(styles.dropdown, {
            [styles.dropdownOpen]: open,
          })}
          data-testId={`sortDropdown${testId}`}
          onClick={handleClick}
          onKeyDown={(e) => simpleKeyboardControl(e, () => handleClick(), true)}
          role="button"
          tabIndex={0}
        >
          <SortIcon className={styles.icon} />
          <span className={styles.value}>{selectedLabel?.label}</span>
          <span className={styles.sortText}>Sort</span>
        </div>
        {!isMobile && open && (
          <div
            data-testId={`dropdownOptionsContainer${testId}`}
            className={styles.optionsContainer}
            ref={triggerRef}
            role="listbox"
          >
            {options.map(({ label, value: optValue }, i) => (
              <li
                ref={optionRefs.current[i]}
                key={optValue}
                onClick={() => handleSelect(optValue)}
                tabIndex={0}
                role="option"
                aria-selected={selectedValue === optValue}
                onKeyDown={(event) => handleNavigation(event, optionRefs.current[i])}
                data-value={optValue}
                className={clsx({
                  [styles.focused]: selectedValue === optValue,
                })}
                data-testId={`dropdownOption${testId}SetOption${i}`}
              >
                {label}
              </li>
            ))}
          </div>
        )}
      </div>
      {(isMobile || isTablet || isSmallTablet) && open && (
        <MobileDropdown
          testId={testId}
          options={options}
          title="Sort By"
          handleSelect={handleSelect}
          open={open}
          mobileClose={mobileClose}
          value={selectedValue}
          icon="CloseIcon"
          sideWays={false}
          variant="radio"
        />
      )}
    </>
  );
};
