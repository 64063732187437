import * as API from 'patientOnboarding/api/legacy';
import * as ABIE_API_V2 from 'patientOnboarding/api/v2/abie';
import * as Effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { RFilterData, RValidateZipCode } from 'patientOnboarding/@types';
import { all, put, takeLatest } from 'redux-saga/effects';

import {
  getFocusPayload,
  setCredentials,
  setErrors,
  setFocus,
  setLicenseKeys,
  setLoading,
  setLocations,
  setSpecialCases,
  setValidZipCode,
  updateFilters,
  validateZipCodePayload,
} from 'patientOnboarding/store/filters/actions';

import { isDisabledTOC } from 'patientOnboarding/utils';

import t from './types';

const call: any = Effects.call;

function* handleValidateZipCode(action: PayloadAction<validateZipCodePayload>): any {
  try {
    yield put(setLoading(true));
    yield put(setErrors({ zipCode: '' }));
    const { zipCode, appName, age } = action.payload;
    const response = yield call(ABIE_API_V2.validateZipCode, zipCode, appName);
    const { city, state, insurances, type_of_cares } = response.data as RValidateZipCode;
    const insuranceCompanies = insurances.map((insurance) => ({
      label: insurance,
      value: insurance,
    }));
    const typesOfCare = type_of_cares.map((typeOfCare) => {
      const disabled = isDisabledTOC(typeOfCare, age);
      return {
        label: typeOfCare,
        value: typeOfCare,
        disabled,
      };
    });
    if (response) {
      yield put(setLoading(false));
      if (zipCode.length === 5 && type_of_cares?.length > 0) {
        yield put(setValidZipCode(true));
      } else {
        yield put(setValidZipCode(false));
      }
      yield put(updateFilters({ city }));
      yield put(updateFilters({ state }));
      yield put(
        updateFilters({
          filters: {
            insuranceCompanies,
            typesOfCare,
          },
        }),
      );
    }
  } catch (e) {
    yield put(setLoading(false));
    yield put(setValidZipCode(false));
    yield put(setErrors({ zipCode: 'Enter a valid zip code for United States' }));
  }
}

function* handleGetFocus(action: PayloadAction<getFocusPayload>): any {
  try {
    put(setLoading(true));
    const { zipCode, patientType, typeOfCare } = action.payload;
    const response = yield call(ABIE_API_V2.getFilterData, { zipCode, typeOfCare, patientType });
    const { license_types, locations, special_cases, license_keys } = response.data as RFilterData;
    const expertises = (response.data as RFilterData).expertises.map((el) => ({
      label: el.name,
      value: el.name,
      id: el.id,
    }));
    const interventions = (response.data as RFilterData).interventions.map((el) => ({
      label: el.name,
      value: el.name,
      id: el.id,
    }));
    const populations = (response.data as RFilterData).populations.map((el) => ({
      label: el.name,
      value: el.name,
      id: el.id,
    }));
    const focusOptions = [
      {
        label: 'Expertises',
        options: expertises,
      },
      {
        label: 'Populations',
        options: populations,
      },
      {
        label: 'Interventions',
        options: interventions,
      },
    ];
    const credentials = license_types.map((credential: any) => ({
      label: credential,
      value: credential,
    }));
    const locationNames = locations.map((location: any) => ({
      ...location,
      label: `${location.address_line1} - ${location.city}`,
      value: location.facility_name,
    }));
    const specialCases = special_cases.map((el: any) => ({
      label: el.name,
      value: el.name,
      id: el.id,
    }));
    yield put(setFocus(focusOptions));
    yield put(setSpecialCases(specialCases));
    yield put(setCredentials(credentials));
    yield put(setLocations(locationNames));
    yield put(setLicenseKeys(license_keys));
    put(setLoading(false));
  } catch (err) {
    // TBD
    put(setLoading(false));
  }
}

function* filtersSaga(): any {
  yield all([
    yield takeLatest(t.VALIDATE_ZIPCODE, handleValidateZipCode),
    yield takeLatest(t.GET_FOCUS, handleGetFocus),
  ]);
}

export default filtersSaga;
