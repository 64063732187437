/*
 * For computed selectors or calculations inside a selector avoid declaring selectors as the
 * following and use instead createSelector from redux utilities.
 * https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization
 */
import { useLocation } from 'react-router-dom';

// TODO: redefine this once apps split
import { RootState } from 'modules/application/store';

import { getContactInfoByState } from 'intakeOptimization/utils';

export type { InitialState as ABIEState } from 'intakeOptimization/store/filters/reducers';
export type { InitialState as OBIEState } from 'intakeOptimization/store/preferences/reducers';

// Global
export const reservationData = (state: RootState) => state.abie.reservationGlobal;
export const reservationLicenseKey = (state: RootState) => state.abie.reservationGlobal.license_key;
export const selectedFacilityData = (state: RootState) => state.abie.reservationGlobal.selectedFacility;
export const isLoadingReservationSelector = (state: RootState) => state.abie.reservationGlobal.loading;
export const isAbie = (state: RootState) => state.abie.config.isAdmin;
export const availableDates = (state: RootState) => state.abie.matches?.clinicianAvailableDates;
export const availabilities = (state: RootState) => state.abie.matches?.clinicianAvailabilities;
export const showAppt = (state: RootState) => state.abie.preferences.showAppointment;
export const showApptLoading = (state: RootState) => state.abie.preferences.showAppointmentLoading;
export const availabilityStatus = (state: RootState) => state.abie.reservationGlobal.availability;
export const contactInfo = (state: RootState) => state.abie.userGlobal.contactInfo;
export const contactNumber = (state: RootState) => {
  if (state.abie.preferences?.marketingReferralPhone.length > 0) {
    return state.abie.preferences?.marketingReferralPhone;
  }
  const location = useLocation();
  const location_back = state.abie.config.isAdmin
    ? state.abie.filters.location_back
    : state.abie.preferences.location_back;
  const stateIn = state.abie.preferences.state;
  const licenseKey = state.abie.reservationGlobal.selectedFacility?.license_key
    ? state.abie.reservationGlobal.selectedFacility?.license_key
    : state.abie.preferences.licenseKey;
  const clinician = state.abie.matches.selectedClinician;
  const isColdLanding = (location_back?.length === 0 && location.pathname.includes('provider'))
    || location_back?.includes('/find-care/booking/search');
  if (isColdLanding) {
    const clinicianFacility = clinician?.facility_location[0];
    const contactInfoByState = getContactInfoByState(
      state.abie.userGlobal.contactInfo,
      clinicianFacility?.state,
      clinician?.license_key,
    );
    return contactInfoByState?.intake_call_in_number;
  }
  const contactInfoByState = getContactInfoByState(
    state.abie.userGlobal.contactInfo,
    stateIn,
    licenseKey,
  );
  return contactInfoByState?.intake_call_in_number;
};
export const selectedClinician = (state: RootState) => state.abie.matches?.selectedClinician;
export const stickyMenuSelector = (state: RootState) => state.abie.matches.stickyMenu;
export const preliminaryInsuranceSelector = (state: RootState) => state.abie.userGlobal.insurancePremliminaryData;
export const selectedClinicianSelector = (state: RootState) => state.abie.matches?.selectedClinician;
export const hasClinicianSelector = (state: RootState) => state.abie.matches?.hasClinician;
export const otherProviders = (state: RootState) => state.abie.matches?.otherProviders;
export const isClinicianProfileLoading = (state: RootState) => state.abie.matches.loadingProfile;
export const loadingAvailabilities = (state: RootState) => state.abie.matches.loadingAvailabilities;
export const preliminaryAccountHolderSelector = (state: RootState) => state.abie.userGlobal.preliminaryAccountHolder;
export const loadingFiltersSelector = (state: RootState) => state.abie.matches.loadingFiltering;
export const obieAccountHolderExists = (state: RootState) => state.abie.userGlobal.exists_in_amd;
export const obiePortalLink = (state: RootState) => state.abie.userGlobal.patient_portal_link;
export const obieAccountHolderInfo = (state: RootState) => state.abie.userGlobal.accountHolderInfo;
export const obieAppointmentData = (state: RootState) => state.abie.reservationGlobal.appointment;
export const obiePatientAppointmentData = (state: RootState) => state.abie.reservationGlobal?.appointment?.patient_appointment;

// ABIE
export const abiePreferencesData = (state: RootState) => state.abie.filters;
export const abiePatientId = (state: RootState) => state.abie.config?.bookingForSelf?.account_holder_patient_id;
export const abieChildPatientId = (state: RootState) => state.abie.config.bookingForSelf.child?.id
  ? state.abie.config.bookingForSelf.child.id
  : state.abie.config.bookingForSelf.account_holder_patient_id;
export const abiePatientAppointmentId = (state: RootState) => state.abie.config.appointmentId;
export const abiePatientData = (state: RootState) => state.abie.config.bookingForSelf;
export const abieAdminConfig = (state: RootState) => state.abie.config;
export const abieInsuranceDetails = (state: RootState) => state.abie.user.insuranceDetails;
export const abieUserData = (state: RootState) => state.abie.user;
export const abieIsLoading = (state: RootState) => state.abie.user.isLoading;
export const hasAddress = (state: RootState) => state.abie.user.hasAddress;
export const abieReservationData = (state: RootState) => state.abie.reservation;
export const abieReservation = (state: RootState) => state.abie.reservation.patientAppointment;
export const abieReservationLicenseKey = (state: RootState) => state.abie.reservation.patientAppointment.clinician_address.license_key;
export const abieReservationError = (state: RootState) => state.abie.reservation.error;
export const abieFilters = (state: RootState) => state.abie.filters;
export const abieInsuranceCards = (state: RootState) => state.abie.user.insuranceCards;
export const abiePatientType = (state: RootState) => state.abie.filters.patientType;
export const abieInsuranceSkipFlag = (state: RootState) => state.abie.user.insuranceDetails.insuranceSkipFlag;
export const abieCreateAccountError = (state: RootState) => state.abie.config.error;
export const abieAccountHolderExists = (state: RootState) => state.abie.config.existsInAMD;
export const abiePatientPortal = (state: RootState) => state.abie.user.patientPortal;
export const abiePatientHasInsuranceInfo = (state: RootState) => state.abie.user.hasInsuranceInfo;
export const abieResponsibleParties = (state: RootState) => state.abie.config.responsibleParty;
export const abieTalkingPoints = (state: RootState) => state.abie.config.isTalkingPoint;
export const abieFocusOptions = (state: RootState) => state.abie.filters.focusOptions;
export const abieSpecialCases = (state: RootState) => state.abie.filters.allSpecialCases;
export const abieBookingModalOpen = (state: RootState) => state.abie.config.isBookingModalOpen;
export const abieClinicians = (state: RootState) => state.abie.clinicians;
export const isLoadingAllClinicians = (state: RootState) => state.abie.clinicians.loadingClinicians;
export const isLoadingCount = (state: RootState) => state.abie.clinicians.loadingCount;
export const isLoadingCliniciansSelector = (state: RootState) => state.abie.clinicians.loadingClinicians;
export const abieEnabled = (state: RootState) => state.abie.config.isEnabled;
export const lifestanceState = (state: RootState) => state.abie.config.isStateEnabled;

// OBIE
export const obiePreferencesData = (state: RootState) => state.abie.preferences;
export const obiePreferencesIsLoading = (state: RootState) => state.abie.preferences.loading;
export const obieUserData = (state: RootState) => state.abie.user;
export const obieCreateAccountError = (state: RootState) => state.abie.user.error;
export const obieSearchData = (state: RootState) => state.abie.searches;
export const obieClinicianAvailableDates = (state: RootState) => state.abie.matches?.clinicianAvailableDates;
export const isExistingAccountHolder = (state: RootState) => state.abie.preferences?.isExistingUser;
export const obieZipCode = (state: RootState) => state.abie.preferences?.zipCode;
export const obieZipCodes = (state: RootState) => state.abie.preferences?.zip_codes;
export const obieLicenseKey = (state: RootState) => state.abie.preferences?.licenseKey;
export const SSOSessionData = (state: RootState) => state.abie.session.data;
export const obieMatches = (state: RootState) => state.abie.matches;
export const obieInsuranceFilters = (state: RootState) => state.abie.insurance.filters;
export const obieInsuranceCards = (state: RootState) => state.abie.insurance.cards;
export const obieGenderIdentities = (state: RootState) => state.abie.insurance.filters?.gender_identity;
export const obieInsuranceSkipFlag = (state: RootState) => state.abie.insurance.insuranceSkipFlag;
export const obieShowCCOF = (state: RootState) => state.abie.insurance.showCCOF;
export const obieHideSkipButtonCCOF = (state: RootState) => state.abie.insurance.hideSkipCCOF;
export const obieHeartlandApiKey = (state: RootState) => state.abie.insurance.heartlandApiKey;
export const obieLocations = (state: RootState) => state.abie.insurance?.filters?.locations;
export const obieFocusOptions = (state: RootState) => state.abie.insurance?.filters?.focusOptions;
export const obieBookingModal = (state: RootState) => state.abie.preferences.isBookingModalObie;
export const obieTypesOfCareselector = (state: RootState) => state.abie.preferences.typeOfCare;
export const obiePreviousPreferences = (state: RootState) => state.abie.preferences.previousPreferences;
export const obieAccountHolder = (state: RootState) => state.abie.preferences?.accountHolder;
export const obieUserIsLoading = (state: RootState) => state.abie.user?.isLoading;
export const obieSkippedCreditCardInfo = (state: RootState) => state.abie.preferences.skippedCreditCardInfo;
export const obieClinicians = (state: RootState) => state.abie.clinicians;

export const obieOrAbiePreferencesSelector = (state: RootState) => {
  if (state.abie.config.isAdmin) {
    return state.abie.filters;
  }
  return state.abie.preferences;
};
