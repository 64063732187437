import API from 'intakeOptimization/api/legacy/config';

export const adminBookingForSelfInfo = (payload) => API.post('/account_holders', { ...payload });

export const adminUpdateBookingForSelfInfo = (payload) => API.patch(`/patients/${payload.account_holder_patient_id}`, {
  ...payload,
});
export const adminBookingForChildInfo = (payload) => API.post('/patients', { ...payload });

export const adminUpdateBookingForChildInfo = (payload) => API.patch(`/patients/${payload.account_holder_patient_id}`, {
  ...payload,
});
