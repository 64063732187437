import clsx from 'clsx';
import React, { useState } from 'react';

import styles from './Radio.module.scss';

export interface InputOption {
  value: string;
  label: string;
}

interface RadioProps {
  name?: string;
  className: string;
  options: InputOption[];
  onChange: (selected: string) => void;
  defaultValue: string;
  autoFocus?: boolean;
  ariaLabel?: string;
  optionClassName?: string;
}

export const Radio = ({
  className,
  options,
  name,
  onChange,
  defaultValue,
  autoFocus = false,
  ariaLabel = '',
  optionClassName = '',
}: RadioProps) => {
  const [selected, setSelected] = useState(defaultValue);

  const handleSelect = (value: string) => {
    setSelected(value);
    onChange(value);
  };

  return (
    <div className={className} role="radiogroup" aria-label={ariaLabel}>
      {options
        && options.map((option) => (
          <div
            data-testId={`radio-${option.value}`}
            className={clsx(
              styles.option,
              {
                [styles.selected]: selected === option.value,
              },
              optionClassName,
            )}
            key={option.value}
            aria-checked={selected === option.value}
            aria-label={option.label}
            data-value={option.value}
            tabIndex={-1}
            onClick={() => handleSelect(option.value)}
          >
            <label htmlFor={option.value}>
              <input
                type="radio"
                name={name}
                id={option.value}
                value={option.value}
                checked={selected === option.value}
                className={clsx({
                  [styles.radioSelected]: selected === option.value,
                })}
                onChange={() => handleSelect(option.value)}
                autoFocus={selected === option.value && autoFocus}
              />
              {option.label}
            </label>
          </div>
        ))}
    </div>
  );
};
