import { Clinician } from 'patientOnboarding/@types';

import { ActiveFilter } from 'patientOnboarding/store/cliniciansGlobal/slice';

import { filterByModality } from './utils/filterByModality';
import { filterByRadius } from './utils/filterByRadius';
import { getActiveFilters } from './utils/getActiveFilters';
import { getFilteredClinicians } from './utils/getFilteredClinicians';
import { getMirroredClinicians } from './utils/getMirroredClinicians';

export const filterClinicians = (clinicians: Clinician[], filters: ActiveFilter[]) => {
  const mirroredClinicians = getMirroredClinicians(clinicians);
  const activeFilters = getActiveFilters(filters);
  const cliniciansWithModality = filterByModality(mirroredClinicians, activeFilters);
  const cliniciansWithinRadius = filterByRadius(cliniciansWithModality, activeFilters);

  const filteredClinicians = getFilteredClinicians(cliniciansWithinRadius, activeFilters);
  const count = filteredClinicians.length;

  return { clinicians: filteredClinicians as Clinician[], count };
};
