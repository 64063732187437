import clsx from 'clsx';
import { FacilityLocation } from 'patientOnboarding/@types';
import avatar from 'patientOnboarding/assets/img/avatar.jpg';
import { ReactComponent as CloseIcon } from 'patientOnboarding/assets/svg/close.svg';
import { ReactComponent as InOfficeIcon } from 'patientOnboarding/assets/svg/in-office.svg';
import { ReactComponent as ClockIcon } from 'patientOnboarding/assets/svg/time-line.svg';
import { ReactComponent as VideoChatIcon } from 'patientOnboarding/assets/svg/video-icon-new.svg';
import React from 'react';
import { useSelector } from 'react-redux';

import { Button, SpanButton } from 'lifestance-ui';

import { obiePreferencesData, reservationData } from 'patientOnboarding/selectors/selectors';

import {
  formatDateWithoutTime,
  getLocalTime,
  getTimeZone,
  useDevice,
} from 'patientOnboarding/utils';

import formatAndLocalDate from 'lifestance-ui/utils/formatAndLocalDate';
import styles from './BookingModal.module.scss';

interface BookingModalProps {
  title: string;
  onContinue: () => void;
  buttonLabel: string;
  loading?: boolean;
  onClose: () => void;
  isColdLanding?: boolean;
  setNext?: (viewNumber: number) => void;
}

export const BookingModal = ({
  title,
  onContinue,
  buttonLabel,
  loading = false,
  onClose,
  isColdLanding = false,
  setNext,
}: BookingModalProps) => {
  const reservation = useSelector(reservationData);
  const preferences = useSelector(obiePreferencesData);
  const { isMobile } = useDevice();
  const variants = {
    coldLanding: {
      video: 'Video visit',
      office: 'In-office visit',
    },
    searchResults: {
      video: 'Video visit',
      office: 'In-office visit',
    },
  };
  const getFacility = (facilityId: number, facilities: FacilityLocation[]) => facilities?.find((elem) => elem?.facility_id === facilityId);
  const messages = isColdLanding ? variants.coldLanding : variants.searchResults;
  const isVideoAppointment = reservation?.modality === 'VIDEO';
  const appointmentTypeIcon = isVideoAppointment ? <VideoChatIcon /> : <InOfficeIcon />;
  const appointmentTypeTitle = isVideoAppointment ? messages.video : messages.office;
  const facility = reservation.selectedFacility
    ? reservation.selectedFacility
    : getFacility(reservation.facility_id as number, reservation.clinician.facility_location);
  const address = `${facility?.facility_name} ${facility?.address_line1} ${facility?.city} ${facility?.state}`;
  const mapsURL = `https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}`;

  const handleFilterWall = () => {
    if (setNext) {
      setNext(4);
    } else {
      onContinue(); // setNext is undefined when desktop
    }
  };

  return (
    <div className={styles.overlay}>
      <div
        className={clsx(styles.modalContainer, {
          [styles.coldLanding]: isColdLanding,
        })}
      >
        {isMobile ? (
          <div className={styles.topContainer}>
            <CloseIcon
              onClick={onClose}
              className={styles.cursorPointer}
              data-testId="closeModalIcon"
            />
            <div className={styles.header}>{title}</div>
          </div>
        ) : null}
        <div className={styles.modalContent}>
          {isMobile ? null : (
            <>
              <div className={styles.close}>
                <CloseIcon
                  onClick={onClose}
                  className={styles.cursorPointer}
                  data-testId="closeModalIcon"
                />
              </div>
              <div data-testId="confirmAppointmentDetailsLabel" className={styles.header}>
                {title}
              </div>
            </>
          )}
          <div className={styles.dataContainer}>
            <div className={styles.leftContainer}>
              <img
                data-testId="clinicianPhoto"
                className={styles.image}
                src={
                  reservation?.clinician.photo && reservation?.clinician.photo.length
                    ? reservation?.clinician.photo
                    : avatar
                }
                alt={reservation?.clinician.first_name}
              />
            </div>
            <div className={styles.rightContainer}>
              <p data-testId="clinicianCredentials" className={styles.boldText}>
                {`${reservation?.clinician?.first_name} ${reservation?.clinician?.last_name}${
                  reservation?.clinician?.credentials
                    ? `, ${reservation.clinician.credentials}`
                    : ''
                }`}
              </p>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.dataContainer}>
            <div className={styles.leftContainer}>
              <ClockIcon />
            </div>
            <div className={styles.rightContainer}>
              <p
                data-testId="typeOfCare"
                className={clsx(styles.boldText, { [styles.appointmentType]: isColdLanding })}
              >
                {reservation?.type_of_care}
              </p>
              <p
                data-testId="availableDate"
                className={clsx(styles.date, { [styles.grayText]: isColdLanding })}
              >
                {/* The problem was the day conversion to local not time */}
                {
                  formatAndLocalDate(reservation.appointment_utc_start_datetime as string)
                    .dateModalFormat
                }
                {' '}
                <br />
                <span>
                  {getLocalTime(reservation?.available_date, reservation?.appointment_start_time)
                    .replace(' PM', 'pm')
                    .replace(' AM', 'am')}
                </span>
                -
                <span>
                  {getLocalTime(reservation?.available_date, reservation?.appointment_end_time)
                    .replace(' PM', 'pm')
                    .replace(' AM', 'am')}
                  {' '}
                  {getTimeZone()}
                </span>
              </p>
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.dataContainer}>
            <div className={styles.leftContainer}>{appointmentTypeIcon}</div>
            <div className={styles.rightContainer}>
              <p
                data-testId="videoAppointment"
                className={clsx(styles.boldText, { [styles.appointmentType]: isColdLanding })}
              >
                {appointmentTypeTitle}
              </p>
              {!isVideoAppointment && (
                <>
                  <div className={styles.line} data-testId="facilityAddress">
                    <div>{facility?.address_line1}</div>
                  </div>
                  <div className={styles.line} data-testId="facilityAddress">
                    <div>{facility?.address_line2}</div>
                  </div>
                  <div className={styles.line} data-testId="facilityCity">
                    <div>
                      {facility?.city}
                      ,
                      {facility?.state}
                      {' '}
                      {facility?.postal_code}
                    </div>
                  </div>
                  <div className={styles.line} data-testId="facilityCity">
                    <a
                      className={styles.link}
                      href={mapsURL}
                      target="_blank"
                      rel="noreferrer"
                      data-testId="Link"
                    >
                      {isMobile ? (
                        'Open in Google maps'
                      ) : (
                        <SpanButton>Open in Google maps</SpanButton>
                      )}
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className={clsx(styles.buttonsContainer, {
              [styles.buttonColdLanding]: isColdLanding,
            })}
          >
            {isColdLanding && preferences.zipCode.length === 0 ? (
              <Button testId="buttonLooksGood" fullWidth onClick={handleFilterWall}>
                {buttonLabel}
              </Button>
            ) : (
              <Button
                testId="bookAppointmentSlotBtn"
                fullWidth
                onClick={onContinue}
                isLoading={loading}
                disabled={loading}
              >
                {buttonLabel}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

BookingModal.defaultProps = {
  loading: false,
};
