import clsx from 'clsx';
import React from 'react';

import { ReactComponent as InformationIcon } from 'lifestance-ui/assets/svg/information.svg';
import { ReactComponent as TalkingInformationalIcon } from 'lifestance-ui/assets/svg/talking-informational.svg';

import styles from './SimpleTooltip.module.scss';

interface ISimpleTooltipProps {
  children: React.ReactNode;
  className?: string;
  isAdmin?: boolean;
  isDependentLegend?: boolean;
  testId?: string;
}

export const SimpleTooltip: React.FC<React.PropsWithChildren<ISimpleTooltipProps>> = ({
  children,
  className,
  isAdmin,
  isDependentLegend,
  testId = 'tooltip',
}) => (
  <div
    className={clsx(
      {
        [styles.adminTooltip]: isAdmin,
        [styles.tooltip]: !isAdmin,
      },
      className,
    )}
    data-testId={`${testId}-container`}
  >
    {isAdmin ? <TalkingInformationalIcon /> : <InformationIcon />}
    <div
      className={clsx({
        [styles.dependentLegend]: isDependentLegend,
        [styles.legend]: !isDependentLegend,
      })}
      data-testId={`${testId}-legend`}
    >
      {children}
    </div>
  </div>
);
