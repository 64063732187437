import clsx from 'clsx';
import React, { KeyboardEventHandler, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ReactComponent as BuildingIcon } from 'lifestance-ui/assets/svg/building-4-line.svg';
import { ReactComponent as VideoChatIcon } from 'lifestance-ui/assets/svg/video-chat-line.svg';
import { getLocalTime } from 'lifestance-ui/utils';

import {
  selectedClinicianSelector as abieSelectedClinicianSelector,
  isAbie,
} from 'intakeOptimization/selectors/selectors';
import {
  obiePreferencesData,
  selectedClinicianSelector,
} from 'patientOnboarding/selectors/selectors';

import formatAndLocalDate from 'lifestance-ui/utils/formatAndLocalDate';
import styles from './AppointmentSelector.module.scss';

interface AppointmentSelectorProps {
  startDate?: Date;
  timesAvailable: any;
  reservation: any;
  handleHour: (a: any) => void;
  hourSelected: null | string;
  profile?: any;
  slotsTabIndex?: number;
}

export const AppointmentSelector = ({
  startDate,
  timesAvailable,
  reservation,
  handleHour,
  hourSelected,
  profile,
  slotsTabIndex = 0,
}: AppointmentSelectorProps) => {
  const location = useLocation();
  const preferences = useSelector(obiePreferencesData);
  const { location_back } = preferences;
  const isAdmin = useSelector(isAbie);
  const profileData = useSelector(
    isAdmin ? abieSelectedClinicianSelector : selectedClinicianSelector,
  );
  const isColdLanding = (location_back?.length === 0 && location.pathname.includes('provider'))
    || location_back?.includes('/find-care/booking/search');

  const getCityByFacilityId = (facilityId: number, profileData: any): string => profileData?.facility_location?.find((f: any) => f.facility_id === facilityId).city;

  const navigateAppointmentSelector = (
    event: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>,
  ) => {
    // Tab
    if (event?.key === 'Tab' && !event.shiftKey) {
      event.preventDefault();
      (document.querySelector('#closeModal') as HTMLSpanElement)?.focus();
    }

    // Shift + Tab
    if (event?.key === 'Tab' && event.shiftKey) {
      event.preventDefault();
      // Get last day on calendar and focus it
      const elements = document.querySelectorAll('.react-datepicker__day[aria-disabled="false"]');
      const lastElement = elements[elements.length - 1];
      (lastElement as HTMLDivElement).focus();
    }
  };

  return (
    <div className={styles.appointmentSelector}>
      {startDate && (
        <h2 data-testId="calendarChoosenDate" className={styles.chosenDate}>
          {startDate.toLocaleString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
          })}
        </h2>
      )}
      <div
        className={clsx(styles.appointments, {
          [styles.coldLanding]: isColdLanding,
        })}
        role="button"
        tabIndex={0}
        onKeyDown={navigateAppointmentSelector}
        id="appointmentSlots"
      >
        {timesAvailable
          && timesAvailable.map((a: any, index: number) => (
            <div
              role="button"
              tabIndex={slotsTabIndex}
              data-testId={`appointmentSlotAvailabilitySetAvailability${index}`}
              className={clsx(styles.appointment, {
                [styles.selected]:
                  reservation.clinician_availability_key === a.clinician_availability_key
                  && reservation.modality === a.modality
                  && hourSelected,
                [styles.selectedAdmin]: isAdmin,
              })}
              onKeyDown={() => handleHour(a)}
              onClick={() => handleHour(a)}
              key={`${a.clinician_availability_key}_${a.modality}`}
            >
              <span className={styles.wordWrap}>
                {a.modality === 'VIDEO' ? (
                  <VideoChatIcon className={styles.modalityIcon} />
                ) : (
                  <BuildingIcon className={styles.modalityIcon} />
                )}
                <span>
                  {(
                    formatAndLocalDate(a.appointment_utc_start_datetime as string)
                      .dateSlotFormat as string
                  )
                    .split(',')[1]
                    ?.trim()}
                  {a.modality !== 'VIDEO'
                    ? ` | ${getCityByFacilityId(a.facility_id, profile || profileData)}`
                    : ' | Video '}
                </span>
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};
