import clsx from 'clsx';
import React, { FC } from 'react';

import { ReactComponent as PuzzleLeft } from 'lifestance-ui/assets/svg/puzzle-left.svg';
import { ReactComponent as PuzzleRight } from 'lifestance-ui/assets/svg/puzzle-right.svg';

import styles from './PuzzleLoading.module.scss';

interface IPuzzleLoadingProps {
  loading?: boolean;
}

export const PuzzleLoading: FC<React.PropsWithChildren<IPuzzleLoadingProps>> = ({ loading }) => (
  <div
    className={clsx({
      [styles.loading]: loading,
      [styles.notLoading]: !loading,
    })}
  >
    <div className={styles.puzzleLeft}>
      <PuzzleLeft />
    </div>
    <div className={styles.puzzleRight}>
      <PuzzleRight />
    </div>
  </div>
);
