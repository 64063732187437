import * as API from 'patientOnboarding/api/legacy';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setLocations, setModalities, setPatientInsuranceInfo } from './actions';
import t from './types';

function* postClinicianModality(action) {
  try {
    const viewClinicianModality = yield call(API.establishedClinicianModality, action.payload);
    if (viewClinicianModality?.data.modalities.length) {
      yield put(setModalities(viewClinicianModality.data));
    } else {
      const mockData = {
        modalities: ['video_visits', 'in_office_only'],
      };
      yield put(setModalities(mockData));
    }
  } catch (e) {
    console.log('error', e);
  }
}

function* postClinicianLocation(action) {
  try {
    const viewClinicianLocation = yield call(API.getClinicianLocations, action.payload);
    yield put(setLocations(viewClinicianLocation?.data));
  } catch (e) {
    console.log('error');
  }
}

function* postCreateAppointment(action) {
  try {
    const createAppointment = yield call(API.createAppointment, action.payload);
  } catch (e) {
    console.log('error');
  }
}

function* getPatientInsurance(action) {
  try {
    const insuranceInfo = yield call(API.getPatientInsuranceInfo, action.payload);
    yield put(setPatientInsuranceInfo(insuranceInfo?.data?.patient));
  } catch (e) {
    console.log('error', e.message);
  }
}

function* postPatientInsuranceUpdate(action) {
  try {
    const insuranceInfo = yield call(API.updatePatienceInsuranceInfo, action.payload);
  } catch (e) {
    console.log('error', e.message);
  }
}

function* patientSaga() {
  yield all([
    yield takeLatest(t.VIEW_CLINICIAN_MODALITIES, postClinicianModality),
    yield takeLatest(t.VIEW_CLINICIAN_LOCATIONS, postClinicianLocation),
    yield takeLatest(t.GET_PATIENT_INSURANCE_INFO, getPatientInsurance),
    yield takeLatest(t.UPDATE_PATIENT_INSURANCE_INFO, postPatientInsuranceUpdate),
    yield takeLatest(t.CREATE_APPOINTMENT, postCreateAppointment),
  ]);
}

export default patientSaga;
