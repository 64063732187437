import React from 'react';
import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/styles';
import styles from './CircularLoading.module.scss';
import clsx from 'clsx';

interface CircularLoadingProps {
  max?: number;
  min?: number;
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | number;
  progress?: number
  component?: 'schedule'
}

export const CircularLoading: React.FC<React.PropsWithChildren<CircularLoadingProps>> = ({
  max = 100,
  min = 0,
  size = 'medium',
  progress = undefined,
  component = '',
}) => (
  <CircularProgress
    className={clsx(styles.circularProgress, {
      [styles.component] : component === 'schedule'
    })}
    max={max}
    min={min}
    size={size}
    progress={progress}
  />
);
