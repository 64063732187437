import { ReactComponent as NoResults } from 'intakeOptimization/assets/svg/no-results.svg';
import { ReactComponent as OverFiltered } from 'intakeOptimization/assets/svg/overfiltered.svg';
import { FC, ReactNode } from 'react';

import { SpanButton } from 'lifestance-ui';

import { stateAbbrToName } from 'intakeOptimization/utils/stateConversion';

import styles from './ViewMoreProviders.module.scss';

const getContent = (
  entireState: string,
  radius: number,
  activeFilters: Record<string, any>,
  results: number,
  state: string,
):
  | {
      image: ReactNode;
      title: string;
      subtitle: string;
      buttonText?: string;
    }
  | undefined => {
  const relevantFilters = Object.keys(activeFilters)
    .filter((key) => !['distance', 'entireState', 'modality'].includes(key))
    .map((key) => activeFilters[key]);
  const hasActiveFilters = Object.values(relevantFilters).some(
    (value) => value && value.length > 0,
  );
  if ((!entireState || entireState === 'false') && results === 0 && !hasActiveFilters) {
    return {
      image: <NoResults />,
      title: 'No clinician availability',
      subtitle: 'To see more options, ask about video visits.',
      buttonText: 'Expand search',
    };
  }
  if ((!entireState || entireState === 'false') && results > 0 && results < 10) {
    return {
      image: <NoResults />,
      title: 'Limited clinician availability',
      subtitle: 'To see more options, ask about video visits.',
      buttonText: 'Expand Search',
    };
  }
  if (entireState === 'true' && results > 0 && results < 10) {
    return {
      image: <NoResults />,
      title: 'Limited clinician availability',
      subtitle: 'Find out which filters are flexible.',
    };
  }
  if ((!entireState || entireState === 'false') && hasActiveFilters && results === 0) {
    return {
      image: <OverFiltered />,
      title: 'No clinicians match selected filters',
      subtitle:
        'Find out which filters are flexible or if they’re willing to see providers across the state via video.',
      buttonText: `See all providers in ${stateAbbrToName(state)}`,
    };
  }
  if (entireState === 'true' && hasActiveFilters && results === 0) {
    return {
      image: <OverFiltered />,
      title: 'No clinicians match selected filters',
      subtitle: 'Find out which filters are flexible.',
    };
  }
};

type ViewMoreProvidersProps = {
  handleViewAll: () => void;
  entireState: string;
  radius: number;
  activeFilters: Record<string, any>;
  results: number;
  state: string;
};

export const ViewMoreProviders: FC<ViewMoreProvidersProps> = ({
  handleViewAll,
  entireState,
  radius,
  activeFilters,
  results,
  state,
}) => {
  const content = getContent(entireState, radius, activeFilters, results, state);

  return content ? (
    <div className={styles.container}>
      <div className={styles.icon}>{content?.image}</div>
      <div>
        <h1 className={styles.title}>{content?.title}</h1>
      </div>
      <div className={styles.description}>{content?.subtitle}</div>
      {content?.buttonText ? (
        <div className={styles.button}>
          <SpanButton secondary onClick={handleViewAll} className={styles.button}>
            <span>{content?.buttonText}</span>
          </SpanButton>
        </div>
      ) : null}
    </div>
  ) : null;
};
