export const TypesOfCareMeta: any = {
  'Adult Therapy': {
    label: 'Adult Therapy (18+)',
    url: 'https://lifestance.com/services/psychotherapy/',
    description:
      'LifeStance therapists provide a wide variety of services, including Cognitive Behavioral Therapy (CBT), psychoanalysis and integrative therapy. Therapists cannot manage medications. <b>For ages 18 and over.</b>',
  },
  'Adult Psychiatry': {
    label: 'Adult Psychiatry (18+)',
    url: 'https://lifestance.com/services/adult-psychiatry/',
    description:
      'A psychiatrist or mental health nurse practitioner can work with you to decide if medication is the right option for you and get the right prescription to your pharmacy. <b>For ages 18 and over.</b>',
  },
  'Child and Adolescent Therapy': {
    // THIS VALUE DOES NOT EXIST IN THE DATABASE IT MIGHT HAVE TO BE DELETED
    url: 'https://lifestance.com/services/child-adolescent-therapy/',
    description:
      'Children and teens can benefit from talking with a licensed therapist. Providers will adjust techniques to meet your child’s maturity and needs.',
  },
  'Child or Adolescent Therapy': {
    label: 'Child & Adolescent Therapy (<18)',
    url: 'https://lifestance.com/services/child-adolescent-therapy/',
    description:
      'Children and teens can benefit from talking with a licensed therapist. Providers will adjust techniques to meet your child’s maturity and needs. <b>For ages 17 and under.</b>',
  },
  'Child and Adolescent Psychiatry': {
    label: 'Child & Adolescent Psychiatry (<18)',
    url: 'https://lifestance.com/services/child-and-adolescent-psychiatry/',
    description:
      'If you think medication might be the right answer for your child or teen, talk to one of these providers. There’s no pressure. Just options. <b>For ages 17 and under.</b>',
  },
  'Couples Therapy': {
    label: 'Couple’s Therapy (18+)',
    url: 'https://lifestance.com/services/couples-therapy/',
    description:
      'Relationships take hard work. A licensed therapist can help partners work through issues and become resilient together. <b>For ages 18 and over.</b>',
  },
  'Family Therapy': {
    label: 'Family Therapy',
    url: 'https://lifestance.com/services/family-therapy/',
    description:
      'Also known as family counseling, aims to address the psychological, behavioral, and emotional issues that cause family problems.',
  },
  'Group Therapy': {
    label: 'Group Therapy',
    url: 'https://lifestance.com/services/group-therapy/',
    description:
      'A therapeutic group that typically focuses on one issue and whose participants experience the same or similar mental health disorders.',
  },
};
