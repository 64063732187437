import { FC, ReactNode } from 'react';

import styles from './Tooltip.module.scss';

interface TooltipProps {
  text: string;
  show: boolean;
}

export const Tooltip: FC<TooltipProps> = ({ text, show }) => (
  <div className={`${styles.tooltip} ${show ? styles.show : ''}`}>
    <span>{text}</span>
  </div>
);
