import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ROUTES from './routes';

const Routing = () => (
  <Routes>
    <Route path="/" element={<Navigate to="booking" />} />
    {ROUTES.map(({ path, Component }) => (
      <Route
        key={`p-${path}`}
        path={path}
        element={(
          <Suspense>
            <Component />
          </Suspense>
        )}
      />
    ))}
  </Routes>
);

export default Routing;
