import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';

import { ReactComponent as ArrowLeft } from 'lifestance-ui/assets/svg/arrow-left-black.svg';
import { ReactComponent as CloseIcon } from 'lifestance-ui/assets/svg/close.svg';
import { disableBodyScroll, enableBodyScroll } from 'lifestance-ui/utils';

import styles from './Modal.module.scss';

interface IModalProps {
  children?: React.ReactNode;
  variantModal?: 'message' | 'action';
  isOpen: boolean;
  onClose: () => void;
  onBack?: () => void;
  footer?: React.ReactNode;
  fullScreen?: boolean;
  className?: string;
  style?: React.CSSProperties;
  testId: string;
  variant?: 'close' | 'back' | 'message';
  modalStyle?: 'coldLanding' | '';
  title?: string;
  disableBodyScrollingOnMount?: boolean;
}

export const Modal: FC<React.PropsWithChildren<IModalProps>> = ({
  children,
  isOpen,
  onClose,
  onBack,
  footer,
  fullScreen = false,
  className = '',
  style = {},
  testId,
  variant = 'back',
  title = '',
  variantModal = 'action',
  modalStyle,
  disableBodyScrollingOnMount = true,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(isOpen);
    }, 100);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      if (onClose) onClose();
    }, 100);
  };

  useEffect(() => {
    if (disableBodyScrollingOnMount) {
      setTimeout(() => disableBodyScroll(), 500);
    }

    return () => {
      setTimeout(() => enableBodyScroll(), 500);
    };
  }, []);

  const navigateAppointmentSelector = (
    event: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>,
  ) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      onClose();
    }
    if (title === 'Availability') {
      // Shift + Tab
      if (event?.key === 'Tab' && event.shiftKey) {
        event.preventDefault();
        // Focus on appointment slots
        (document.querySelector('#appointmentSlots') as HTMLDivElement).focus();
      }
    }
  };

  return (
    <div
      data-testId={testId}
      className={clsx(styles.container, { [styles.open]: open, [styles.fullScreen]: fullScreen })}
    >
      <div
        style={style}
        className={clsx(styles.modal, className, { [styles.open]: open })}
        data-testId="modal"
      >
        {variant === 'close' ? (
          <div className={clsx(styles.modalHeader)} data-testId="ModalHeader">
            <span
              tabIndex={0}
              role="button"
              className={styles.closeButton}
              onClick={handleClose}
              data-testId="CloseButton"
              id="closeModal"
              onKeyDown={navigateAppointmentSelector}
            >
              <CloseIcon />
            </span>
          </div>
        ) : null}
        <div
          className={clsx(styles.headerMobile, {
            [styles.headerMobileMessage]: variant === 'message',
          })}
        >
          {(variant === 'close' || variant === 'message') && (
            <CloseIcon
              data-testId={`${testId}CloseIcon`}
              onClick={handleClose}
              className={styles.closeIcon}
            />
          )}
          {variant === 'back' && onBack && <ArrowLeft onClick={onBack} className={styles.goBack} />}
          {title ? (
            <span className={styles.title}>{title}</span>
          ) : (
            <div className={styles.emptyTitle} />
          )}
          {variantModal === 'action' && (
            <span data-testId={`${testId}Cancel`} onClick={handleClose} className={styles.cancel}>
              Cancel
            </span>
          )}
        </div>
        {children}
        {footer && (
          <div
            className={clsx(styles.footer, {
              [styles.newColdLanding]: modalStyle === 'coldLanding',
            })}
          >
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};
