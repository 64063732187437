import { createAction } from '@reduxjs/toolkit';
import { ClinicianAvailabilityPayload } from 'patientOnboarding/@types';
import { action } from 'typesafe-actions';

import t from './types';

export const getSSOClinicianAvailabilities = createAction<ClinicianAvailabilityPayload>(
  t.GET_CLINICIAN_AVAILABILITIES,
);

export const getModalities = createAction<string>(t.VIEW_CLINICIAN_MODALITIES);

export const getLocations = createAction<string>(t.VIEW_CLINICIAN_LOCATIONS);

export { setModalities, setLocations, updateClinician } from './reducers';
