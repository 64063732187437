import * as API from 'patientOnboarding/api/legacy';
import * as ABIE_API_V2 from 'patientOnboarding/api/v2/abie';
import * as OBIE_API_V2 from 'patientOnboarding/api/v2/obie';
import * as Effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { datadogLogs } from '@datadog/browser-logs';

import { loadClinicianProfile } from 'patientOnboarding/store/matches/actions';
import {
  setMarketingReferralPhone,
  updatePreferences,
} from 'patientOnboarding/store/preferences/actions';
import { updateReservation } from 'patientOnboarding/store/reservationGlobal/actions';

import { accountExistError, accountGenericError } from 'patientOnboarding/utils';

import {
  handleError,
  setAccountHolder,
  setContactInfo,
  setEmailStatus,
  setEmergencyContactInfo,
  setExistsInAmd,
  setLoading,
  setPatient,
  setPatientPortalLink,
  setResponsibleParty,
  setUserInsuranceDetails,
  updateUser,
} from './actions';
import {
  AccountHolderBasicData,
  AccountHolderInfo,
  AddressPayloadType,
  Patient,
  UserInsuranceDetails,
  setAccountHolderResentEmail,
  setEmailResent,
  setIsLoadingResend,
  setIsLoadingSelectedSlot,
} from './reducers';
import t from './types';

// eslint-disable-next-line
const call: any = Effects.call;
function* handleSaveAddressData(action: PayloadAction<AddressPayloadType>): any {
  try {
    yield call(API.saveAddressInfo, action.payload);
  } catch (err: any) {
    // TBD
  }
}
function* handleUpdatePatient(action: PayloadAction<{ patientId?: number; data: Patient }>): any {
  try {
    yield put(setLoading(true));
    datadogLogs.logger.log('Updating patient', {
      action: 'handleUpdatePatient',
      payload: action.payload,
    });
    const genderIdentity = action.payload.data.genderIdentity
      ? action.payload.data.genderIdentity
      : yield select((state) => state.obie?.userGlobal.genderIdentity);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // const response = yield call(API.updatePatient, action.payload);// , gender_identity: genderIdentity}});
    const response = yield call(API.updatePatient, {
      ...action.payload,
      data: { ...action.payload.data, gender_identity: genderIdentity },
    });
    datadogLogs.logger.log('Patient updated', {
      action: 'handleUpdatePatient',
      response: response.data,
    });
    yield put(setPatient(response.data.patient));
    yield put(setLoading(false));
  } catch (err: any) {
    yield put(setLoading(false));
    datadogLogs.logger.error('Error updating patient', {
      action: 'handleUpdatePatient',
      payload: action.payload,
      level: 'error',
    });
    console.log(err);
    console.error('error from handlePatientIntakeStatus is ', err);
  }
}

function* handleCreateAccountHolder(action: PayloadAction<AccountHolderInfo>): any {
  yield put(setLoading(true));
  yield put(setExistsInAmd(false));
  try {
    datadogLogs.logger.log('Creating account holder', {
      action: 'handleCreateAccountHolder',
      payload: action.payload,
    });
    const response = yield call(API.createAccountHolder, action.payload);
    datadogLogs.logger.log('Account holder created', {
      action: 'handleCreateAccountHolder',
      response: response.data,
    });
    if (response?.data?.account_holder) {
      const accountHolder = action.payload;
      const accountHolderId = response.data.account_holder.id;
      const accountHolderPatientId = response.data.account_holder.account_holder_patient_id;
      yield put(setAccountHolder({ ...accountHolder, accountHolderId, accountHolderPatientId }));
      const { accountHolderRelationship } = action.payload;
      if (accountHolderRelationship === 'self') {
        yield put(setPatient({ id: accountHolderPatientId }));
      }
      yield put(setLoading(false));
    }
  } catch (err: any) {
    yield put(setLoading(false));
    datadogLogs.logger.error('Error creating account holder', {
      action: 'handleCreateAccountHolder',
      payload: action.payload,
      level: 'error',
    });
    if (err.response.status === 422 && err.response.data?.message === accountExistError) {
      const responsibleParty = err.response.data.existing_accounts.responsible_party_patients;
      yield put(setExistsInAmd(true));
      yield put(setResponsibleParty(responsibleParty));
      yield put(setPatientPortalLink(err.response.data.patient_portal_url));
      yield put(
        handleError({
          status: err.response.status,
          message: accountExistError,
        }),
      );
    } else {
      datadogLogs.logger.error('Other error creating account holder', {
        action: 'handleCreateAccountHolder',
        payload: action.payload,
        level: 'error',
      });
      yield put(
        handleError({
          status: err.response.status,
          message: accountGenericError,
        }),
      );
    }
    console.error(err);
  }
}

function* handleCreatePatient(action: PayloadAction<Patient | AccountHolderInfo>): any {
  yield put(setLoading(true));
  try {
    datadogLogs.logger.log('Creating patient', {
      action: 'handleCreatePatient',
      payload: action.payload,
    });
    const response = yield call(API.createPatient, action.payload);
    datadogLogs.logger.log('Patient created', {
      action: 'handleCreatePatient',
      response: response.data,
    });
    yield put(setPatient(response.data.patient));
    yield put(setLoading(false));
  } catch (err: any) {
    datadogLogs.logger.error('Error creating patient', {
      action: 'handleCreatePatient',
      payload: action.payload,
      level: 'error',
    });
    if (err?.response?.data?.exists_in_amd) {
      yield put(setExistsInAmd(true));
    }
    console.error(err?.response?.data?.error);
    console.log(err?.response?.data?.error);
    yield put(setLoading(false));
  }
}

function* handleGetEmailStatus(action: PayloadAction<string>): any {
  try {
    const response = yield call(API.getEmailStatus, action.payload);
    if (response) {
      yield put(setEmailStatus(response.data));
    }
  } catch (err: any) {
    if (err.response.data.message === 'Account already verified') {
      yield put(setEmailStatus(err.response.data));
    }
  }
}

function* handleResendEmail(action: PayloadAction<{ email_address: string; id: number }>): any {
  try {
    yield put(setIsLoadingResend(true));
    yield put(setEmailResent(false));
    yield call(OBIE_API_V2.resendConfirmationEmail, action.payload);
    yield put(setAccountHolderResentEmail(action.payload.email_address));
    yield put(setEmailResent(true));
    yield put(setIsLoadingResend(false));
  } catch (error) {
    yield put(setEmailResent(false));
    yield put(setIsLoadingResend(false));
    console.error(error);
  }
}

function* handleUpdateInsuranceDetails(action: PayloadAction<UserInsuranceDetails>): any {
  try {
    yield put(setUserInsuranceDetails(action.payload));
    yield call(API.saveInsuranceDetails, action.payload);
  } catch (err: any) {
    console.error('Error saving insurance Data: ', err);
  }
}

function* handleAccountHolderSelectedSlot(action: PayloadAction<any>): any {
  yield put(setIsLoadingSelectedSlot(true));
  try {
    datadogLogs.logger.log('Saving account holder selected slot', {
      action: 'handleAccountHolderSelectedSlot',
      payload: action.payload,
    });
    const response = yield call(API.saveAccountHolderSelectedSlotInfo, action.payload);
    datadogLogs.logger.log('Account holder selected slot saved', {
      action: 'handleAccountHolderSelectedSlot',
      response: response.data,
    });
    yield put(setIsLoadingSelectedSlot(false));
  } catch (err: any) {
    datadogLogs.logger.error('Error saving selected slot from account holder', {
      action: 'handleAccountHolderSelectedSlot',
      payload: action.payload,
      level: 'error',
    });
    console.error('Error saving selected slot from account holder');
    yield put(setIsLoadingSelectedSlot(false));
  }
}

function* handleSelectedSlotInfo(action: PayloadAction<string>): any {
  try {
    const response = yield call(API.getSelectedSlotInfo, action.payload);
    datadogLogs.logger.log('Selected slot info', {
      action: 'handleSelectedSlotInfo',
      response: response.data,
    });
    if (response) {
      datadogLogs.logger.log('Selected slot info', {
        action: 'handleSelectedSlotInfo',
        response: response.data,
      });
      yield put(loadClinicianProfile(response.data.selected_slot_info.reservation.clinician));
      const preferences = {
        city: response.data.selected_slot_info.reservation.selectedFacility.city,
        state: response.data.selected_slot_info.reservation.selectedFacility.state,
        zipCode: response.data.selected_slot_info.user.accountHolderInfo.zipCode,
        modality: response.data.selected_slot_info.reservation.modality,
        paymentMethod: response.data.selected_slot_info.user.accountHolderInfo.paymentMethod,
        insuranceCompany: response.data.selected_slot_info.user.accountHolderInfo.insuranceCompany,
        license_key: response.data.selected_slot_info.reservation.license_key,
        typeOfCare: response.data.selected_slot_info.user.accountHolderInfo.typeOfCare,
        intake: response.data.selected_slot_info.user.accountHolderInfo.intake,
        filters: response.data.selected_slot_info.filters,
        accountHolder:
          response.data.selected_slot_info.user.accountHolderInfo.accountHolderRelationship,
        marketingReferralPhone: response.data.selected_slot_info.preferences.marketingReferralPhone,
      };
      yield put(updatePreferences(preferences));
      yield put(updateReservation(response.data.selected_slot_info.reservation));
      yield put(
        updateUser({
          ...response.data.selected_slot_info.user,
          isLoading: false,
          patient: {
            id: response.data.selected_slot_info.user.accountHolderInfo.accountHolderPatientId,
          },
        }),
      );
    }
  } catch (err: any) {
    datadogLogs.logger.error('Error getting selected slot from account holder', {
      action: 'handleSelectedSlotInfo',
      payload: action.payload,
      level: 'error',
    });
    console.error('Error getting selected slot from account holder');
  }
}

function* handleGetContactInfo(action: PayloadAction<number>): any {
  try {
    const marketing_referral = yield select((state) => state.obie.preferences?.preliminaryReferral);
    let response;
    if (document.location.pathname.includes('admin')) {
      response = yield call(ABIE_API_V2.getContactInfo, {
        license: action.payload,
        marketingReferral: marketing_referral,
      });
    } else {
      response = yield call(OBIE_API_V2.getContactInfo, {
        license: action.payload,
        marketingReferral: marketing_referral,
      });
    }
    const marketingReferralPhone = response.data.find(
      (f: any) => f?.marketing_referral,
    )?.marketing_referral;
    if (marketingReferralPhone !== undefined && marketingReferralPhone !== null) {
      yield put(setMarketingReferralPhone(marketingReferralPhone));
    }
    yield put(setContactInfo(response.data));
  } catch (err: any) {
    // TBD
  }
}

function* handleGetEmergencyContactInfo(action: PayloadAction<number>): any {
  try {
    const response = yield call(API.getEmergencyContactInfo, action.payload);
    const emergencyContactInfo = {
      firstName: response.data.first_name,
      lastName: response.data.last_name,
      phone: response.data.phone,
      relationship: response.data.relationship_to_patient,
    };
    yield put(setEmergencyContactInfo(emergencyContactInfo));
  } catch (err: any) {
    // TBD
  }
}

function* handleSaveEmergencyContactInfo(action: any): any {
  try {
    const { firstName, lastName, phone, relationship } = yield select(
      (state) => state.obie.userGlobal.emergencyContactInfo,
    );
    const patientId = yield select((state) => state.obie.userGlobal.patient.id);
    const response = yield call(API.saveEmergencyContact, {
      firstName,
      lastName,
      phone,
      relationship,
      patientId,
    });
  } catch (err: any) {
    // TBD
    console.log(err);
  }
}

function* handleUpdateAccountHolder(
  action: PayloadAction<{ id: number; data: Partial<AccountHolderBasicData> }>,
) {
  try {
    const { id, data } = action.payload;
    yield call(API.updateAccountHolder, { id, accData: data });
  } catch (e) {
    console.error(e);
  }
}

function* userSagas(): any {
  yield all([
    yield takeLatest(t.SAVE_ADDRESS, handleSaveAddressData),
    yield takeLatest(t.UPDATE_PATIENT, handleUpdatePatient),
    yield takeLatest(t.UPDATE_INSURANCE_DETAILS, handleUpdateInsuranceDetails),
    yield takeLatest(t.CREATE_ACCOUNT_HOLDER, handleCreateAccountHolder),
    yield takeLatest(t.CREATE_PATIENT, handleCreatePatient),
    yield takeLatest(t.GET_EMAIL_STATUS, handleGetEmailStatus),
    yield takeLatest(t.RESEND_EMAIL, handleResendEmail),
    yield takeLatest(t.SAVE_ACCOUNT_HOLDER_SLOT, handleAccountHolderSelectedSlot),
    yield takeLatest(t.GET_SELECTED_SLOT_INFO, handleSelectedSlotInfo),
    yield takeLatest(t.HANDLE_ERROR, handleError),
    yield takeLatest(t.GET_CONTACT_INFO, handleGetContactInfo),
    yield takeLatest(t.GET_EMERGENCY_CONTACT_INFO, handleGetEmergencyContactInfo),
    yield takeLatest(t.SAVE_EMERGENCY_CONTACT_INFO, handleSaveEmergencyContactInfo),
    yield takeLatest(t.UPDATE_ACCOUNT_HOLDER, handleUpdateAccountHolder),
  ]);
}

export default userSagas;
