import { useDispatch, useSelector } from 'react-redux';

import {
  getClinicians,
  getDisplayedClinicians,
  getFilteredCliniciansObie,
  setActiveFiltersObie as setActiveFilters,
} from 'patientOnboarding/store/cliniciansGlobal/slice';
import { setLoadingFiltering } from 'patientOnboarding/store/matches/reducers';
import { updatePreferences } from 'patientOnboarding/store/preferences/actions';

import { obiePreferencesData } from 'patientOnboarding/selectors/selectors';

import { cleanNullProperties, getFormatForMatches } from 'patientOnboarding/utils';

export const useAvailabilityFilter = () => {
  const dispatch = useDispatch();
  const preferences = useSelector(obiePreferencesData);

  const filterAvailability = (selectedFilters: string[]) => {
    dispatch(setLoadingFiltering(true));
    dispatch(
      updatePreferences({
        availability_filter: selectedFilters,
      }),
    );

    dispatch(
      setActiveFilters({
        availability_filter: selectedFilters,
      }),
    );

    const formattedFilters = getFormatForMatches({
      ...preferences,
      availability_filter: selectedFilters,
    });

    const cleanedNullFilters = cleanNullProperties(formattedFilters);

    if (selectedFilters.length === 0) {
      delete cleanedNullFilters.availability_filter;
    }

    dispatch(getClinicians({ activeFilters: cleanedNullFilters }));
    dispatch(getFilteredCliniciansObie());
    dispatch(getDisplayedClinicians({ page: 1 }));
  };

  return {
    filterAvailability,
  };
};
