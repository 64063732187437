import { RootState } from 'modules/application/store';

export const activeFiltersSelector = (state: RootState) => state.abie.clinicians.activeFilters.reduce((acc: Record<string, unknown>, filter) => {
  acc[filter.name] = filter.value;
  return acc;
}, {});

export const activeFiltersArraySelector = (state: RootState) => state.abie.clinicians.activeFilters;

export const cliniciansSelector = (state: RootState) => state.abie.clinicians.clinicians;
export const filteredCliniciansSelector = (state: RootState) => state.abie.clinicians.filteredClinicians;

export const displayedCliniciansSelector = (state: RootState) => state.abie.clinicians.displayedClinicians;

export const loadingDisplayedCliniciansSelector = (state: RootState) => state.abie.clinicians.loadingDisplayedClinicians;

export const pageSelector = (state: RootState) => state.abie.clinicians.page;
export const totalPagesSelector = (state: RootState) => state.abie.clinicians.totalPages;
export const countSelector = (state: RootState) => state.abie.clinicians.clinicianCount;
