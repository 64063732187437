import clsx from 'clsx';
import React, { FC } from 'react';

import styles from '../ProgressBar.module.scss';
import { Step } from '../types';

interface CurrentNumberProps {
  step: Step;
  testId: string;
  numberOfSteps: number;
}
export const CurrentNumber: FC<CurrentNumberProps> = ({
  step,
  testId = 'progressBarCurrent',
  numberOfSteps,
}) => {
  const subStepWidth = 100 / (Number(step.subSteps?.length) + 1);
  const currentWidth = Number(step.currentSubStep?.number) * subStepWidth;

  return (
    <div
      data-testid={testId}
      className={clsx(styles.step, styles.step_current, {
        [styles.oneThird]: numberOfSteps === 3,
        [styles.oneHalf]: numberOfSteps === 2,
      })}
    >
      <div className={styles.step_indicator}>
        <div className={styles.step_progress} style={{ width: `${currentWidth}%` }} />
      </div>
      <div data-testid={`${testId}Number`} className={styles.step_number}>
        <div className={styles.circle_half_green} />
        <div className={styles.circle_half_gray} />
        <div className={styles.number}>{step.number}</div>
      </div>
      <div className={styles.step_title}>{step.title}</div>
    </div>
  );
};
