import clsx from 'clsx';
import React, { FC, MouseEvent, ReactNode, useState } from 'react';

import { ReactComponent as ArrowDownIcon } from 'lifestance-ui/assets/svg/arrow-down.svg';
import { ReactComponent as ArrowRightIcon } from 'lifestance-ui/assets/svg/arrow-right-line.svg';

import styles from './SpanButton.module.scss';

interface ISpanButtonProps {
  children?: ReactNode | string;
  underline?: boolean;
  arrow?: boolean;
  arrowIcon?: 'right' | 'down';
  testId?: string;
  className?: string;
  onClick?: () => void;
  id?: string;
  withPointer?: boolean;
  secondary?: boolean;
}

export const SpanButton: FC<React.PropsWithChildren<ISpanButtonProps>> = ({
  children,
  underline,
  arrow,
  testId,
  arrowIcon,
  className,
  onClick,
  id,
  withPointer = false,
  secondary = false,
}) => {
  const [mouseOver, setMouseOver] = useState<boolean>(false);

  const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
    if (onClick) {
      event?.preventDefault();
      onClick();
    }
  };

  return (
    <span
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      className={clsx(styles.labelButton, className, {
        [styles.underline]: underline,
        [styles.mouseOver]: mouseOver,
        [styles.pointer]: withPointer,
        [styles.secondary]: secondary,
      })}
      onClick={handleClick}
      data-testId={`spanbutton-${testId}`}
      id={id}
    >
      {children}
      {arrow && <ArrowRightIcon />}
      {arrowIcon === 'down' && <ArrowDownIcon />}
    </span>
  );
};
