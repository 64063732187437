import { KeyboardEvent } from 'react';

export const simpleKeyboardControl = (
  event: KeyboardEvent<HTMLElement>,
  action: () => void,
  preventSpaceScrolling = false,
) => {
  const keyPressed = event.key;
  if (keyPressed === ' ' && preventSpaceScrolling) {
    event.preventDefault();
  }
  switch (keyPressed) {
    case 'Enter':
    case ' ':
      action();
      break;
    default:
  }
};
