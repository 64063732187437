import clsx from 'clsx';
import { FC, KeyboardEventHandler, useEffect, useState } from 'react';

import { Rectangle } from './Rectangle';
import styles from './Rectangle.module.scss';

interface IRectangleGroupProps {
  name: string;
  options: Array<any>;
  multiSelect?: boolean;
  onSelectHandler: (key: string, options: Array<any>, value?: any) => void;
  error?: any;
  testId?: string;
  disabled?: boolean;
  defaultFocus?: boolean;
  groupClassName?: string;
  buttonClassName?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}

export const RectangleGroup: FC<React.PropsWithChildren<IRectangleGroupProps>> = ({
  name,
  options,
  multiSelect,
  testId,
  disabled,
  defaultFocus,
  onSelectHandler,
  error,
  groupClassName,
  buttonClassName = '',
  onKeyDown,
}) => {
  const [optionsData, setOptionsData] = useState<any>([]);
  useEffect(() => {
    if (options && options.length > 0) {
      setOptionsData(options);
    } else {
      setOptionsData([{ label: 'Select an option', value: 'false', disabled: true }]);
    }
  }, [options]);

  const onClickHandler = (option: string) => {
    const cloneItems: any = [];
    optionsData.forEach((item: any) => {
      let isSelected = item.isSelected;
      if (!isSelected && item.value === option) {
        isSelected = !item.isSelected;
      } else if (!multiSelect && item.value !== option) {
        isSelected = false;
      }
      cloneItems.push({ ...item, isSelected });
    });
    onSelectHandler(name, cloneItems, option);
  };

  return (
    <div className={clsx(groupClassName, 'rectanglZeGroup')} onKeyDown={onKeyDown} id={name}>
      {optionsData.map((item: any, index: number) => (
        <Rectangle
          keyIntrinc={item.value}
          name={item.value}
          index={`${index + 1}`}
          label={item.label}
          isActive={item.isSelected}
          error={error}
          disabled={disabled || item.disabled}
          className={buttonClassName}
          btnIndex={index + 1}
          count={optionsData.length}
          defaultFocus={defaultFocus && index === 0}
          handleClick={onClickHandler}
          testId={testId}
        />
      ))}
      {error && error.length && (
        <span data-testId={`input${testId}Error`} className={clsx({ [styles.error]: error })}>
          {error}
        </span>
      )}
    </div>
  );
};
