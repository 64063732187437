import clsx from 'clsx';
import avatar from 'patientOnboarding/assets/img/avatar.jpg';
import calendarSuccess from 'patientOnboarding/assets/svg/calendar-success.svg';
import { ReactComponent as ClockIcon } from 'patientOnboarding/assets/svg/clock.svg';
import { ReactComponent as CloseIcon } from 'patientOnboarding/assets/svg/close.svg';
import { ReactComponent as ErrorIcon } from 'patientOnboarding/assets/svg/error.svg';
import { ReactComponent as InOfficeIcon } from 'patientOnboarding/assets/svg/in-office.svg';
import { ReactComponent as VideoChatIcon } from 'patientOnboarding/assets/svg/video-icon-new.svg';
import { establishedPatientsRoutes } from 'patientOnboarding/routes/constants';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Button } from 'lifestance-ui';

import { updatePreferences } from 'patientOnboarding/store/preferences/actions';

import {
  existingPatient,
  sessionDataPatientInfoSelector,
} from 'patientOnboarding/selectors/selectors';

import { formatDateWithoutTime, getLocalTime, getTimeZone } from 'patientOnboarding/utils';

import styles from './AppointmentEstablished.module.scss';

interface AppointmentEstablishedProps {
  testId?: string;
  onClose: () => void;
  reservation: any;
  reservationError: string;
}

export const AppointmentEstablished: React.FC<
  React.PropsWithChildren<AppointmentEstablishedProps>
> = ({ onClose, reservation, reservationError, testId = '' }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const isVideoAppointment = reservation?.modality === 'VIDEO';
  const appointmentTypeIcon = isVideoAppointment ? (
    <VideoChatIcon data-testId={`appointment${testId}VideoIcon`} />
  ) : (
    <InOfficeIcon data-testId={`appointment${testId}OfficeIcon`} />
  );
  const appointmentTypeTitle = isVideoAppointment ? 'Video appointment' : 'In-office appointment';
  const imgsrc = reservation?.clinician.photo ? reservation?.clinician.photo : avatar;
  const [selectedPatient, setSelectedPatient] = useState<any>({});
  const sessionData = useSelector(sessionDataPatientInfoSelector);
  const { isReschedule } = useSelector(existingPatient); // HARDCODED FROM REDUCER INITAL STATE
  const [patientFirstName, setPatientFirstName] = useState<string>('');
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(!open);
    setTimeout(() => {
      onClose && onClose();
    }, 500);
  };

  const labelButton = reservation.availability ? 'Looks good' : 'Choose a new time';

  useEffect(() => {
    const selectedPatientID = parseInt(sessionData.selected_patient_id, 10);
    const selectedPatient = sessionData.authorized_patients_list.find(
      (o: any) => o.id === selectedPatientID,
    );

    setSelectedPatient(selectedPatient);
    setPatientFirstName(
      `${selectedPatient?.name.split(' ')[0].charAt(0).toUpperCase()}${selectedPatient?.name
        .split(' ')[0]
        .slice(1)
        .toLowerCase()}`,
    );
  }, [sessionData]);

  useEffect(() => {
    if (location.pathname === establishedPatientsRoutes.modality) {
      dispatch(updatePreferences({ showAppointment: true }));
    }
  }, [location.pathname]);

  return (
    <div className={styles.appointment}>
      <div
        data-testId={`mobileBackground${testId}Close`}
        className={styles.modal}
        onClick={handleClose}
      />
      <div className={clsx(styles.container, { [styles.open]: open })}>
        {!isReschedule && (
          <>
            <div className={styles.header}>
              <h2 className={styles.title}>Your appointment</h2>
            </div>
            <div className={clsx(styles.row, styles.first)} style={{ marginBottom: '20px' }}>
              <div className={styles.info}>
                <h1>Appointment details</h1>
                <p>
                  For
                  {patientFirstName}
                </p>
              </div>
            </div>
          </>
        )}

        {isReschedule && (
          <>
            <div className={clsx(styles.row, styles.first)}>
              <div className={clsx(styles.info, styles.center)}>
                <img src={calendarSuccess} alt="rescheduling icon" className={styles.calendar} />
              </div>
            </div>
            <div
              className={clsx(styles.row, styles.first)}
              style={{ paddingTop: '0px', marginBottom: '20px' }}
            >
              <div className={clsx(styles.info, styles.center)} style={{ textAlign: 'center' }}>
                <h2>
                  Rescheduling
                  {patientFirstName}
                  's Session
                </h2>
                <p className={styles.gray_500}>With [provider’s first, on [x/x/xx], [time].</p>
              </div>
            </div>
          </>
        )}
        <CloseIcon
          data-testId={`button${testId}Close`}
          onClick={handleClose}
          className={styles.closeIcon}
        />
        {(location.pathname === establishedPatientsRoutes.availableAppointments
          || location.pathname === establishedPatientsRoutes.modality
          || location.pathname === establishedPatientsRoutes.location
          || location.pathname === establishedPatientsRoutes.confirmPaymentInfo
          || location.pathname === establishedPatientsRoutes.confirmation) && (
          <div className={styles.row}>
            <div className={styles.icon}>
              <img
                className={styles.resultThumbnail}
                src={imgsrc}
                alt={reservation.clinician.first_name}
              />
            </div>
            <div className={styles.info}>
              <h2 data-testId={`appointment${testId}NameCredentials`}>
                {reservation.clinician.first_name}
                {' '}
                {reservation.clinician.last_name}
                {reservation?.clinician?.credentials
                  ? `, ${reservation.clinician.credentials}`
                  : ''}
              </h2>
              <p>Adult Therapy, follow-up</p>

              {/* {reservation.availability
                ? <p className={styles.link}>Share profile</p>
                ? <p className={styles.link}>Find another provider</p> : ""} */}
            </div>
          </div>
        )}

        {location.pathname === establishedPatientsRoutes.provider && (
          <div className={styles.row}>
            <div className={styles.icon}>
              <div className={styles.grayIcon} />
            </div>
            <div className={styles.info}>
              <p className={styles.gray}>Select your provider</p>
            </div>
          </div>
        )}

        {(location.pathname === establishedPatientsRoutes.modality
          || location.pathname === establishedPatientsRoutes.location) && (
          <div className={styles.row}>
            <div className={styles.icon}>
              <div className={styles.grayIcon} />
            </div>
            <div className={styles.info}>
              <p className={styles.gray}>Select how you'd like to meet</p>
            </div>
          </div>
        )}

        {(location.pathname === establishedPatientsRoutes.availableAppointments
          || location.pathname === establishedPatientsRoutes.confirmPaymentInfo
          || location.pathname === establishedPatientsRoutes.confirmation) && (
          <div className={clsx(styles.row)}>
            <div className={styles.icon}>{appointmentTypeIcon}</div>
            <div className={clsx(styles.info)}>
              <p className={styles.centering} data-testId={`appointment${testId}Type`}>
                {appointmentTypeTitle}
              </p>
            </div>
          </div>
        )}
        {location.pathname === establishedPatientsRoutes.availableAppointments && (
          <div className={styles.row}>
            <div className={styles.icon}>
              <div className={styles.grayIcon} />
            </div>
            <div className={styles.info}>
              <p className={styles.gray}>Select date and time</p>
            </div>
          </div>
        )}

        {location.pathname !== establishedPatientsRoutes.provider
          && location.pathname !== establishedPatientsRoutes.modality
          && location.pathname !== establishedPatientsRoutes.availableAppointments
          && location.pathname !== establishedPatientsRoutes.location && (
            <div className={styles.row}>
              <div className={styles.icon}>
                {reservationError && reservationError !== '' ? (
                  <ErrorIcon data-testId={`appointment${testId}ErrorIcon`} />
                ) : (
                  <ClockIcon data-testId={`appointment${testId}ClockIcon`} />
                )}
              </div>
              <div className={styles.info}>
                <h2 data-testId={`appointment${testId}TypeOfCare`}>{reservation?.type_of_care}</h2>
                {reservation.availability ? (
                  <>
                    <p data-testId={`appointment${testId}Reservation`}>
                      <span>{formatDateWithoutTime(reservation?.available_date)}</span>
                      ,
                      {' '}
                      <span data-testId={`appointment${testId}StartHour`}>
                        {getLocalTime(
                          reservation?.available_date,
                          reservation?.appointment_start_time,
                        )}
                      </span>
                      -
                      <span data-testId={`appointment${testId}EndHour`}>
                        {getLocalTime(
                          reservation?.available_date,
                          reservation?.appointment_end_time,
                        )}
                        {' '}
                      </span>
                      <span data-testId={`appointment${testId}TimeZone`}>{getTimeZone()}</span>
                    </p>
                    {reservationError && reservationError !== '' && (
                      <p className={styles.notAvailable}>This time is no longer available</p>
                    )}
                  </>
                ) : (
                  <p className={styles.notAvailable}>This time is no longer available</p>
                )}
              </div>
            </div>
        )}
        <div className={styles.footer}>
          <Button testId={`button${testId}LooksGood`} fullWidth onClick={handleClose}>
            {labelButton}
          </Button>
        </div>
      </div>
    </div>
  );
};
