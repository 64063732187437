import clsx from 'clsx';
import React, { FC, MutableRefObject, createRef, useEffect, useRef, useState } from 'react';

import { Button } from 'lifestance-ui';

import { onClickOut } from 'intakeOptimization/utils';

import { IOption, IOptionsList, OptionsClassName } from '../types';
import styles from './OptionsList.module.scss';

interface OptionsListProps {
  onClose: (e?: any) => void;
  testId?: string;
  onSelect: (
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement | HTMLLIElement>,
    value: string,
    ref?: MutableRefObject<HTMLDivElement>,
  ) => void;
  optionsLists: IOptionsList[] | IOption[];
  onClear: () => void;
  selected: string[];
  query: string;
  filter?: boolean;
  onSave?: () => void;
  disableOnClickOut?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>) => void;
  optionsClassName?: OptionsClassName;
}

export const OptionsList: FC<OptionsListProps> = ({
  onClose,
  testId,
  onSelect,
  selected,
  optionsLists,
  onClear,
  query,
  filter,
  onSave,
  disableOnClickOut = false,
  onKeyDown,
  optionsClassName,
}) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    // In order for the clickout to work with formik the change detection needs to be in a useEffect to be considered
    // BUT we only want to use this when this component is not part of a filter
    if (!disableOnClickOut && open) {
      return onClickOut({
        triggerRef,
        action: () => {
          setOpen(false);
        },
        ignoreParent: true,
      });
    }
  }, [open, triggerRef, disableOnClickOut]);

  const optionRefs = useRef<MutableRefObject<HTMLDivElement>[]>([]);
  if (optionRefs.current.length !== optionsLists?.length) {
    optionRefs.current = Array(optionsLists?.length)
      .fill(optionsLists)
      .map((_, i) => optionRefs.current[i] || createRef());
  }

  const handleSelect = (
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement | HTMLLIElement>,
    option: string,
    ref?: MutableRefObject<HTMLDivElement>,
  ) => {
    /**
     * In order to scroll to top in a dynamic height we are giving a few extra time to let the Selected container show
     * as current ref has an specific height in the container but once the selected container appears this will change
     * we need to make sure the selected container is there before scrolling to top.
     * */
    if (query.length > 0) {
      setTimeout(() => {
        const { scrollX, scrollY } = window;
        containerRef.current?.scrollIntoView();
        window.scrollTo(scrollX, scrollY);
      }, 100);
    }
    onSelect(event, option, ref);
  };

  const focusNextTabbableElement = () => {
    const tabbableElements = Array.from(
      document.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      ),
    );

    const currentIndex = tabbableElements.indexOf(document.activeElement as any);

    let nextIndex = currentIndex + 1;
    if (nextIndex >= tabbableElements.length) {
      nextIndex = 0;
    }
    (tabbableElements[nextIndex] as HTMLElement).focus();
  };

  const focusPrevTabbableElement = () => {
    const tabbableElements = Array.from(
      document.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      ),
    );

    const currentIndex = tabbableElements.indexOf(document.activeElement as any);
    let prevIndex = currentIndex - 1;
    if (prevIndex < 0) {
      prevIndex = tabbableElements.length - 1;
    }
    (tabbableElements[prevIndex] as HTMLElement).focus();
  };

  const handleNavigate = (event: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>) => {
    event.stopPropagation();
    onKeyDown?.(event);
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        focusNextTabbableElement();
        break;
      case 'ArrowUp':
        event.preventDefault();
        focusPrevTabbableElement();
        break;
      case 'Enter':
      case 'Escape':
        event.preventDefault();
        setOpen(false);
        break;
      case ' ':
        event.preventDefault();
        handleSelect(event, document.activeElement?.getAttribute('data-value') as string);
        break;
      default:
    }
  };

  useEffect(() => {
    if (!open) {
      onClose();
      onSave?.();
    }
  }, [open]);

  const applyToCondition = (apply: string | number[], index: number) => {
    if (apply === 'all') {
      return 1;
    }
    if (Array.isArray(apply)) {
      return apply.includes(index + 1) ? 1 : -1;
    }
    return 0;
  };

  const applyWhenCondition = (when: string, currQuery: string) => {
    if (when === 'everytime') {
      return 1;
    }
    if (when === 'empty-query') {
      return currQuery.length === 0 ? 1 : -1;
    }
    return 0;
  };

  const drawNestedOptions = (
    options: IOptionsList[],
    currQuery: string,
    className = '',
    applyTo: string | number[] = 'all',
    applyWhen = 'everytime',
  ) => options.map((optionsList, i) => (
    <div
      key={`divContainer${optionsList.label}`}
      className={clsx(styles.options, { [styles.filterOptions]: filter })}
    >
      <h3>{optionsList.label}</h3>
      {optionsList.options.map((option, i) => (
        <div
          className={clsx(styles.option, {
            [className]:
                applyWhenCondition(applyWhen, currQuery) + applyToCondition(applyTo, i) > 0,
          })}
          key={option.value}
          ref={optionRefs.current[i]}
          role="option"
          aria-selected={selected.includes(option.value)}
          data-value={option.value}
          onKeyDown={handleNavigate}
          onClick={(e) => handleSelect(e, option.value, optionRefs.current[i])}
          tabIndex={0}
          data-testid={`dropdownSelect${testId}OptionBoxSetOption`}
        >
          <div
            id={option.value}
            className={clsx(styles.checkbox, {
              [styles.checked]: selected.includes(option.value),
            })}
            data-testid={`dropdownSelect${testId}Checkbox`}
          />
          <div
            data-testid={`dropdownSelect${testId}OptionValueSetOption${i}`}
            className={styles.label}
          >
            {option.label}
          </div>
        </div>
      ))}
    </div>
  ));

  const drawOptions = (
    options: IOption[],
    currQuery: string,
    className = '',
    applyTo: string | number[] = 'all',
    applyWhen = 'everytime',
  ) => (
    <div
      key="divContainerOptions"
      className={clsx(styles.simpleOptions, { [styles.filterOptions]: filter })}
    >
      {options.map((option, i) => (
        <div
          className={clsx(styles.option, {
            [className]:
              applyWhenCondition(applyWhen, currQuery) + applyToCondition(applyTo, i) > 0,
          })}
          key={option.value}
          ref={optionRefs.current[i]}
          role="option"
          aria-selected={selected.includes(option.value)}
          data-value={option.value}
          onKeyDown={handleNavigate}
          onClick={(e) => handleSelect(e, option.value, optionRefs.current[i])}
          tabIndex={0}
          data-testid={`dropdownSelect${testId}OptionBoxSetOption`}
        >
          <div
            id={option.value}
            className={clsx(styles.checkbox, {
              [styles.checked]: selected.includes(option.value),
            })}
            data-testid={`dropdownSelect${testId}Checkbox`}
          />
          <div
            data-testid={`dropdownSelect${testId}OptionValueSetOption${i}`}
            className={styles.label}
          >
            {option.label}
          </div>
        </div>
      ))}
    </div>
  );

  const drawItems = (
    optionsToDraw: IOptionsList[] | IOption[],
    currentQuery: string,
    optionsCustomProps?: OptionsClassName,
  ) => 'options' in optionsToDraw[0]
    ? drawNestedOptions(
          optionsToDraw as IOptionsList[],
          currentQuery,
          optionsCustomProps?.className,
          optionsCustomProps?.applyTo,
          optionsCustomProps?.applyWhen,
    )
    : drawOptions(
          optionsToDraw as IOption[],
          currentQuery,
          optionsCustomProps?.className,
          optionsCustomProps?.applyTo,
          optionsCustomProps?.applyWhen,
    );

  return (
    <div
      ref={triggerRef}
      className={clsx(styles.optionsContainer, {
        [styles.filterOptionsContainer]: filter,
      })}
      role="listbox"
      aria-multiselectable
      data-testId={`dropdownSelectOptionsContainer${testId}`}
    >
      <div ref={containerRef} />
      {
        // If the user is typing a new query we should not show the Selected section
      }
      {selected?.length > 0 && query?.length === 0 ? (
        <div
          key="divContainerSelected"
          className={clsx(styles.options, styles.selectedList, {
            [styles.filterSelectedList]: filter,
          })}
        >
          <h3>Selected</h3>
          {selected.map((option) => (
            <div
              className={styles.option}
              key={option}
              role="option"
              aria-selected
              data-value={option}
              onKeyDown={handleNavigate}
              onClick={(e) => handleSelect(e, option)}
              tabIndex={0}
              data-testid={`dropdownSelect${testId}OptionBoxSetOption`}
            >
              <div
                id={option}
                className={clsx(styles.checkbox, styles.checked)}
                data-testid={`dropdownSelect${testId}Checkbox`}
              />
              <div
                data-testId={`dropdownSelect${testId}OptionValueSetOption`}
                className={styles.label}
              >
                {option}
              </div>
            </div>
          ))}
        </div>
      ) : null}
      {optionsLists.length > 0 ? (
        drawItems(optionsLists, query, optionsClassName)
      ) : (
        <span className={styles.noResults}>No results</span>
      )}
      {filter ? null : (
        <div className={styles.footer}>
          <Button
            testId={`buttonDropdownSelect${testId}Clear`}
            size="small"
            className={styles.clearButton}
            tertiary
            onClick={onClear}
          >
            Clear
          </Button>
          <Button
            size="small"
            disabled={selected.length === 0}
            onClick={() => setOpen(false)}
            testId={`buttonDropdownSelect${testId}SaveClose`}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};
