import clsx from 'clsx';
import { FacilityLocation } from 'intakeOptimization/@types';
import { ReactComponent as ArrowDown } from 'intakeOptimization/assets/svg/arrow-down.svg';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SpanButton } from 'lifestance-ui';

import {
  contactInfo as contactInfoSelector,
  obiePreferencesData,
  reservationData,
  selectedClinician,
} from 'intakeOptimization/selectors/selectors';

import { useInvoca } from 'intakeOptimization/hooks';

import {
  defaultPhoneNumber,
  formatContactNumber,
  getContactInfoByState,
  useDevice,
} from 'intakeOptimization/utils';
import { licenseKeys } from 'intakeOptimization/utils/licenseKeys';

import styles from './ExistingPatientsBox.module.scss';

interface IExistingPatientBox {
  disableTabNav?: boolean;
  disableInvoca?: boolean;
}

const ExistingPatientsBox: FC<IExistingPatientBox> = ({
  disableTabNav = false,
  disableInvoca = true,
}) => {
  const preferences = useSelector(obiePreferencesData);
  const profile = useSelector(selectedClinician);
  const contactInfo = useSelector(contactInfoSelector);
  const reservation = useSelector(reservationData);
  const { city, location_back } = preferences;
  const [contactNumber, setContactNumber] = useState('');
  const licenseKey = profile?.license_key;
  const isColdLanding = location_back?.length === 0 || location_back?.includes('/find-care/booking/search');
  const [expanded, setExpanded] = useState(false);
  const [contactInfoLoaded, setContactInfoLoaded] = useState(false);
  const { isMobile } = useDevice();

  const handleExpand = () => {
    setExpanded((prevState) => !prevState);
  };
  const visitText = isMobile ? 'Patient portal' : 'Existing patient portal';
  const missingVideoLink = (profile.license_key === 139414 || profile.license_key === 147611) && !profile?.telehealth_url;

  const getFacility = (facilities: FacilityLocation[]) => {
    const facility = facilities?.find(
      (el: any) => el.facility_id === reservation?.selectedFacility?.facility_id,
    );
    return facility;
  };

  useEffect(() => {
    if (window.Invoca) {
      window.Invoca.PNAPI.run();
    }
  }, [window]);

  const getPhoneNumber = () => {
    let phone = '';
    if (preferences.marketingReferralPhone.length > 0) {
      phone = preferences.marketingReferralPhone;
    } else if (contactNumber) {
      phone = contactNumber;
    } else {
      phone = defaultPhoneNumber;
    }

    return phone;
  };

  useEffect(() => {
    if (contactInfo.length > 0) {
      setContactInfoLoaded(true);
      const facility = isColdLanding
        ? profile.facility_location[0]
        : getFacility(profile.facility_location);
      const contactInfoByState = getContactInfoByState(contactInfo, facility?.state, licenseKey);
      const phoneNumber = contactInfoByState?.intake_call_in_number;
      setContactNumber(phoneNumber as string);
    }
  }, [contactInfo]);

  useInvoca(!disableInvoca && contactNumber?.length > 0);

  return (
    <div className={styles.existContainer}>
      <div
        className={clsx(styles.existing, {
          [styles.expanded]: !missingVideoLink,
        })}
      >
        <div className={styles.titleBox} onClick={handleExpand}>
          <div className={styles.title} data-testId="existingPatients">
            Already have an appointment?
          </div>
          <ArrowDown />
        </div>
        <div
          className={clsx(styles.optionsBox, {
            [styles.visible]: expanded,
            [styles.expandedVisible]: expanded && missingVideoLink,
          })}
        >
          {!missingVideoLink ? (
            <div className={styles.options}>
              <div className={styles.visit} data-testId="waitingRoom">
                Video Visit Waiting Room
              </div>
              <a
                className={styles.join}
                tabIndex={disableTabNav ? -1 : 0}
                href={
                  profile?.telehealth_url?.length > 0
                    ? profile?.telehealth_url
                    : `${window.location.origin}/telehealth-url-error/?phone=${getPhoneNumber()}`
                }
                target="_blank"
                rel="noreferrer"
              >
                Join Now
              </a>
            </div>
          ) : null}
          <div className={styles.options}>
            <div className={styles.visit} data-testId="patientPortal">
              {visitText}
            </div>
            <div className={styles.portal} data-testId="signIn">
              {licenseKeys[city] ? (
                <span>
                  <a
                    tabIndex={disableTabNav ? -1 : 0}
                    href={`https://patientportal.advancedmd.com/${licenseKeys[city]}/account/logon`}
                  >
                    {' '}
                    <SpanButton>Sign in</SpanButton>
                  </a>
                </span>
              ) : (
                <span>
                  <a
                    tabIndex={disableTabNav ? -1 : 0}
                    href={`https://patientportal.advancedmd.com/${licenseKey}/account/logon`}
                  >
                    {' '}
                    <SpanButton>Sign in</SpanButton>
                  </a>
                </span>
              )}
            </div>
          </div>
          {missingVideoLink ? (
            <div className={styles.missingLink}>
              <div className={styles.missingVideoLink} data-testId="waitingRoom">
                Looking for your video visit link?
              </div>
              <div className={styles.visitText}>
                You will receive a link to join your virtual visit in your appointment reminder
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.concerns}>
        <div className={styles.questions} data-testId="question">
          Have a question?
        </div>
        <a
          tabIndex={disableTabNav ? -1 : 0}
          href={`tel:${formatContactNumber(contactNumber) || ''}`}
          className={clsx(styles.phone, 'invocaPhoneNumber')}
          data-testId="phoneNumber"
        >
          <SpanButton className={clsx(styles.phone, 'invocaPhoneNumber')}>
            <>
              {' '}
              {getPhoneNumber()}
            </>
          </SpanButton>
        </a>
      </div>
    </div>
  );
};
export default ExistingPatientsBox;
