import { ActiveFilter } from 'intakeOptimization/store/clinicians/slice';

import {
  LICENSED_CLINICIAN as licenseCredential,
  SUPERVISED_CLINICIAN as supervisedCredential,
} from 'intakeOptimization/utils';

export const validFiltersKeys = {
  licenseKeys: 'licenseKeys',
  expertises: 'expertises',
  interventions: 'interventions',
  populations: 'populations',
  modality: 'modality',
  location: 'location_names',
  gender: 'gender',
  language: 'language',
  credentials: 'credentials',
  distance: 'distance',
  entireState: 'entire_state',
};

const optionsForComposedFiltering = [
  {
    key: validFiltersKeys.credentials,
  },
];

/**
 * When dealing with credentials we have an special case where we can have both options selected if so we need to treat
 * the data as if none were selected.
 * @param credentials is the array of current selected filters value for the credentials object
 */
const getCredentialsSpecialCase = (credentials: unknown[]) => {
  if (
    credentials.includes(supervisedCredential.name)
    && credentials.includes(licenseCredential.name)
  ) {
    return credentials.filter(
      (credential) => credential !== licenseCredential.name && credential !== supervisedCredential.name,
    );
  }
  return credentials;
};

export const getFilteredClinicians = (
  clinicians: Record<string, unknown>[],
  activeFilters: ActiveFilter[],
) => {
  const nonDistanceFilters = activeFilters?.filter((filter) => filter?.name !== 'distance');
  return clinicians.filter((clinician) => nonDistanceFilters.every((filter) => {
    const clinicianValue = Array.isArray(clinician[filter.name])
      ? (clinician[filter.name] as unknown[])
      : [clinician[filter.name]];
    if (optionsForComposedFiltering.find((option) => option.key === filter.name)) {
      const filterValues = getCredentialsSpecialCase(filter.value as unknown[]);
      return filterValues.every((filterVal) => clinicianValue?.includes(filterVal));
    }
    return (filter.value as unknown[]).some((filterVal) => clinicianValue?.includes(filterVal));
  }));
};
