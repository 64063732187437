import { ReactComponent as NoResults } from 'patientOnboarding/assets/svg/no-results.svg';
import { FC } from 'react';

import { Button } from 'lifestance-ui';

import { stateAbbrToName } from 'patientOnboarding/utils/stateConversion';

import styles from './StateToastModal.module.scss';

type StateToastProps = {
  state: string;
  onClose: () => void;
};

export const StateModal: FC<StateToastProps> = ({ state, onClose }) => (
  <div className={styles.stateToastModal}>
    <div className={styles.container}>
      <div className={styles.icon}>
        <NoResults />
      </div>
      <div>
        <h1 className={styles.title}>No local providers available</h1>
      </div>
      <div className={styles.description}>
        Because there were no providers with availability within 60 miles of you, we’ve expanded the
        search to include providers across&nbsp;
        {stateAbbrToName(state)}
        . Distance knows no bounds when you can meet anywhere via video
        visit!
      </div>
      <div className={styles.button}>
        <Button fullWidth onClick={() => onClose()}>
          Got it!
        </Button>
      </div>
    </div>
  </div>
);

export default StateModal;
