import { FC, MouseEvent, RefObject, useEffect, useRef, useState } from 'react';
import AddToCalendarHOC, { SHARE_SITES } from 'react-add-to-calendar-hoc';

import { onClickOut } from 'lifestance-ui/utils';

import styles from './Calendar.module.scss';
import './index.d';

type CalendarEvent = {
  description: string;
  duration: number | string;
  location: string;
  startDatetime: string;
  endDatetime: string;
  title: string;
  timezone?: string;
};

interface ICalendarProps {
  testId?: string;
  event: CalendarEvent;
}

interface IButtonProps {
  testId?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  refer: RefObject<HTMLButtonElement>;
}

interface IDropdownProps {
  onRequestClose: (event: MouseEvent<HTMLDivElement>) => void;
  refer: RefObject<HTMLDivElement>;
  isOpen: boolean;
}

const Button: FC<React.PropsWithChildren<IButtonProps>> = ({
  testId = '',
  children,
  onClick,
  refer,
}) => (
  <button
    ref={refer}
    data-cy={testId}
    data-testId={testId}
    type="button"
    className={styles.link}
    onClick={onClick}
  >
    {children}
  </button>
);

const Dropdown: FC<React.PropsWithChildren<IDropdownProps>> = ({
  children,
  onRequestClose,
  isOpen,
  refer,
}) => {
  const handleClick = () => {
    refer.current?.click();
  };

  useEffect(() => {
    if (isOpen) {
      return onClickOut({
        triggerRef: refer,
        action: handleClick,
      });
    }
  }, [isOpen]);
  return (
    <div className={styles.dropdownBox} onClick={onRequestClose} ref={refer}>
      <div className={styles.dropdownItems}>{children}</div>
    </div>
  );
};

export const Calendar: FC<React.PropsWithChildren<ICalendarProps>> = ({ testId, event }) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const CalendarDropdown = AddToCalendarHOC(Button, Dropdown);
  return (
    <CalendarDropdown
      event={event}
      filename="MyAppointment"
      buttonProps={{ testId, refer: buttonRef, open, setOpen }}
      dropdownProps={{ refer: dropdownRef, open, setOpen }}
      items={[SHARE_SITES.GOOGLE, SHARE_SITES.YAHOO, SHARE_SITES.OUTLOOK, SHARE_SITES.ICAL]}
    />
  );
};
