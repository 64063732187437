import clsx from 'clsx';
import { Supervisor } from 'intakeOptimization/@types';
import { ReactComponent as UserStar } from 'intakeOptimization/assets/svg/user-star-line.svg';
import React from 'react';

import styles from './Supervisor.module.scss';

interface ISupervisorProps {
  supervisorData: Supervisor;
  isAdmin?: boolean;
}

const SupervisorSection: React.FC<ISupervisorProps> = ({ supervisorData, isAdmin = false }) => supervisorData.supervised_clinician && supervisorData.supervisors.length > 0 ? (
  <div
    className={clsx(styles.supervisorContainer, {
      [styles.supervisorContainerAdmin]: isAdmin,
    })}
  >
    <UserStar />
    <div className={styles.heading} data-testId="education">
      Supervised by
    </div>
    <div className={styles.supervisorDataContainer}>
      {supervisorData.supervisors.map(
        (item) => item.full_name.length > 0 && (
          <div className={styles.line}>
            <div>
              {item.full_name}
              {item.license_number.length > 0 ? ` | ${item.license_number}` : ''}
            </div>
          </div>
        ),
      )}
    </div>
  </div>
) : null;

export default SupervisorSection;
