import { ReactComponent as ArrowRight } from 'intakeOptimization/assets/svg/arrow-right-line.svg';
import React, { FC } from 'react';

import styles from './CardButton.module.scss';

interface ICardButtonProps {
  signInMessage?: string;
  buttonText: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const CardButton: FC<React.PropsWithChildren<ICardButtonProps>> = ({
  signInMessage,
  buttonText,
  onClick,
}) => (
  <button type="button" className={styles.card} onClick={onClick}>
    <h3 className={styles.content}>{buttonText}</h3>
    {signInMessage ? <span className={styles.link}>{signInMessage}</span> : <ArrowRight />}
  </button>
);
