import t from './types';

const initialState = {
  status: null,
  error: null,
  redirectUrl: null,
};

const errorHandlerReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.THROW_API_ERROR: {
      console.log('--== errorHandlerReducer ', action);
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return initialState;
    }
  }
};

export default errorHandlerReducer;
