import clsx from 'clsx';
import React, {
  FC,
  KeyboardEventHandler,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FormLabel } from '../FormLabel/FormLabel';
import TypeAheadInput from './Input';
import TypeAheadOptionsList from './OptionsList';
import TypeAheadTrigger from './Trigger';
import styles from './TypeAheadDropdown.module.scss';
import { IOption, IOptionsList, OptionsClassName } from './types';
import { transformOptionsList, transformSingleOptionsList } from './utils';

interface TypeAheadDropdownProps {
  label?: string;
  onSelect: any;
  optionsLists: IOptionsList[] | IOption[];
  selected: string[];
  className?: string;
  testId?: string;
  disabled?: boolean;
  placeholder?: string;
  filter?: boolean;
  handleSelectExpertise?: (value: string) => void;
  error?: string;
  onBlur?: () => void;
  onOpenOptionsList?: () => void;
  lastEventType?: string;
  onSave?: () => void;
  disableOnClickOut?: boolean;
  optionsClassName?: OptionsClassName;
}

export const TypeAheadDropdown: FC<TypeAheadDropdownProps> = ({
  label = 'Options',
  onSelect,
  optionsLists,
  selected,
  className,
  testId,
  placeholder,
  disabled,
  filter = false,
  handleSelectExpertise,
  error = '',
  onBlur,
  onOpenOptionsList,
  lastEventType,
  onSave,
  disableOnClickOut = false,
  optionsClassName,
}) => {
  const [query, setQuery] = useState('');
  const [transformedOptionsLists, setTransformedOptionsLists] = useState(optionsLists);
  const [openOptionsList, setOpenOptionsList] = useState(filter);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [isFocus, setIsFocus] = useState(true);
  const [isSelectedValue, setIsSelectedValue] = useState(false);
  const [lastEvent, setLastEvent] = useState(lastEventType);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedValues(selected);
  }, []);

  useEffect(() => {
    if (selected.length === 0) {
      setSelectedValues([]);
    }
  }, [selected]);

  /**
   * This function deals with adding or removing values from the selected list, if value already exists in
   * selectedValues it is removed if not is being added then it will be sorted in alphabetical order (starting with A
   * and ending with Z) it will also clear current query to search in the options and set the focus as true to get
   * back the linter on the input to type another area of focus.
   * @param newValue a string that represents the value selected
   */
  const handleSelect = (
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement | HTMLLIElement>,
    newValue: string,
    ref?: MutableRefObject<HTMLDivElement>,
  ) => {
    // Find out if the event is a keyboard event or a mouse event
    const isKeyboardEvent = event.type === 'keydown';
    let newValues = [];
    // We set the input focus to false as we want to reset the state to keep typing on the input.

    if (selectedValues.includes(newValue)) {
      newValues = selectedValues.filter((value: string) => value !== newValue);
    } else {
      newValues = [...selectedValues, newValue];
    }
    setSelectedValues(newValues.sort());
    if (handleSelectExpertise) {
      handleSelectExpertise(newValue);
    }
    if (query.length > 0) {
      setQuery('');
      setIsFocus(false);
      setIsSelectedValue(true);
    }
    if (isKeyboardEvent) {
      ref?.current?.focus();
    }
  };

  const handleClearInput = () => {
    setQuery('');
  };

  const handleOpenOptionsList = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    setLastEvent('click');
    setIsFocus(false);
    if (!openOptionsList && !disabled) {
      setOpenOptionsList(true);
      setQuery('');
    }
  };

  const handleInputClose = () => {
    if (!filter) {
      setOpenOptionsList(false);
      setIsFocus(false);
    } else {
      setOpenOptionsList(false);
      setIsFocus(false);
      onSave?.();
    }
  };

  const handleClose = () => {
    if (!filter) {
      setOpenOptionsList(false);
      setIsFocus(false);
      // Since handleClose it's called when we close the optionList this is where we should call the onBlur function
      onBlur?.();
    }
    onSelect(selectedValues);
  };

  const handleChangeInput = (value: string) => {
    setQuery(value);
  };

  const handleClearSelectedValues = () => {
    setSelectedValues([]);
  };

  useEffect(() => {
    if (optionsLists.length > 0) {
      if ('options' in optionsLists[0]) {
        const updatedTransformedOptionsLists = transformOptionsList(
          query,
          optionsLists as IOptionsList[],
          styles.highlighted,
        );
        setTransformedOptionsLists(updatedTransformedOptionsLists);
      } else {
        const updatedTransformedOptionsLists = transformSingleOptionsList(
          query,
          optionsLists as IOption[],
          styles.highlighted,
        );
        setTransformedOptionsLists(updatedTransformedOptionsLists);
      }
    }
  }, [query, optionsLists]);

  useEffect(() => {
    if ((onOpenOptionsList && openOptionsList) || (onOpenOptionsList && isFocus)) {
      onOpenOptionsList();
    }
  }, [openOptionsList, isFocus]);

  useEffect(() => {
    if (!isFocus && isSelectedValue) {
      setIsFocus(true);
      setIsSelectedValue(false);
    }
  }, [isSelectedValue, isFocus]);

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === 'Tab') {
      setLastEvent('tab');
    }
  };

  const handleInputBlur = (e: React.FocusEvent) => {
    e.stopPropagation();
    setIsFocus(false);
  };

  const handleInputFocus = (e: React.FocusEvent) => {
    e.stopPropagation();
    setIsFocus(true);
  };

  return (
    <div
      data-testid={`typeAheadDropdown${testId}`}
      tabIndex={-1}
      role="listbox"
      className={clsx(className, styles.container)}
      ref={dropdownRef}
      onKeyDown={handleKeyDown}
    >
      {!filter ? <FormLabel label={label} /> : null}
      {(openOptionsList || filter) && (
        <TypeAheadInput
          onChange={handleChangeInput}
          onClear={handleClearInput}
          onClick={handleOpenOptionsList}
          value={query}
          isFocus={isFocus}
          filter={filter}
          placeholder={placeholder}
          numberOfOptions={transformedOptionsLists.length}
          onClose={handleInputClose}
          lastEventType={lastEvent}
          testId={testId}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
        />
      )}
      {!openOptionsList && !filter && (
        <TypeAheadTrigger
          onClick={handleOpenOptionsList}
          placeholder={placeholder}
          disabled={disabled}
          values={selectedValues}
          error={error}
          lastEventType={lastEventType}
          testId={testId}
        />
      )}
      {openOptionsList && (
        <TypeAheadOptionsList
          selected={selectedValues}
          onSelect={handleSelect}
          optionsLists={transformedOptionsLists}
          onClear={handleClearSelectedValues}
          onClose={handleClose}
          onSave={onSave}
          query={query}
          filter={filter}
          disableOnClickOut={disableOnClickOut}
          testId={testId}
          onKeyDown={handleKeyDown}
          optionsClassName={optionsClassName}
        />
      )}
      {error && error.length ? (
        <span
          data-testId={`typeAheadError${testId}`}
          className={clsx({ [styles.errorMessage]: error })}
        >
          {error}
        </span>
      ) : null}
    </div>
  );
};
