import { useState } from 'react';

import { useDetectBrowser } from './useDetectBrowser';

interface PermissionsState {
  camera: PermissionState;
}

export const usePermissions = (): {
  checkCameraPermissions: () => Promise<{ permissionsDenied: boolean } | undefined>;
  permissions: PermissionsState;
  permissionsDenied: boolean;
} => {
  const { isAndroid, isIOS } = useDetectBrowser();

  const [permissions, setPermissions] = useState<PermissionsState>({
    camera: 'prompt',
  });

  const [permissionsDenied, setPermissionsDenied] = useState(false);

  const checkCameraPermissions = async () => {
    if (isAndroid || isIOS) {
      try {
        let cameraPermissionState: PermissionState = 'prompt';
        try {
          await navigator.mediaDevices.getUserMedia({ video: true });
          cameraPermissionState = 'granted';
          setPermissionsDenied(false);
        } catch (err) {
          if (err instanceof DOMException && err.name === 'NotAllowedError') {
            cameraPermissionState = 'denied';
          }
        }
        if (cameraPermissionState === 'denied') {
          setPermissionsDenied(true);
          return { permissionsDenied: true };
        }

        setPermissions({
          camera: cameraPermissionState,
        });

        return { permissionsDenied: false };
      } catch (error) {
        console.error('Error checking camera permission:', error);
      }
    } else {
      return { permissionsDenied: false };
    }
  };

  return { checkCameraPermissions, permissions, permissionsDenied };
};
