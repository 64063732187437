import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'lifestance-ui';

import {
  getFilteredCliniciansObie,
  setActiveFiltersObie,
} from 'patientOnboarding/store/cliniciansGlobal/slice';
import { updatePreferences } from 'patientOnboarding/store/preferences/actions';

import { genderFilters, onClickOut, useAvailabilityFilter } from 'patientOnboarding/utils';

import styles from './Filter.module.scss';
import Modality from './lib/Modality';
import Trigger from './lib/Trigger';
import { isChecked } from './lib/utils';
import { FilterProps, NestedOption, Option } from './types';

export const Filter: FC<FilterProps> = ({
  label,
  className,
  onChange,
  onClose,
  options,
  name,
  defaultValues,
  testId,
  heightConstraint = false,
  buttonText,
  isLoading = false,
  onClear,
}) => {
  const [open, setOpen] = useState<boolean | null>(null);
  const [selected, setSelected] = useState<string[]>([]);
  const triggerRef = useRef(null);
  const dispatch = useDispatch();
  const { filterAvailability } = useAvailabilityFilter();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const handleSaveAndClose = () => {
    onClose?.();
    setOpen(false);
  };

  const handleClick = () => {
    const formattedValues = typeof defaultValues === 'string' && defaultValues !== '' ? [defaultValues] : defaultValues;
    if (formattedValues) {
      setSelected(formattedValues);
    }
    const isOpen = !open;
    setOpen(isOpen);
    if (!isOpen) {
      onClose?.();
    }
  };

  const handleClear = (e: any) => {
    setSelected([]);
    if (name === 'concerns') {
      dispatch(
        setActiveFiltersObie({
          concerns: [],
          populations: [],
          interventions: [],
        }),
      );
      dispatch(
        updatePreferences({
          concerns: [],
          populations: [],
          interventions: [],
        }),
      );
    } else if (name === 'gender') {
      dispatch(
        setActiveFiltersObie({
          gender: [],
        }),
      );
      dispatch(
        updatePreferences({
          gender: [],
        }),
      );
    } else if (name === 'availability_filter') {
      filterAvailability([]);
    } else {
      dispatch(
        setActiveFiltersObie({
          [name]: [],
        }),
      );
      dispatch(
        updatePreferences({
          [name]: [],
        }),
      );
    }

    e.stopPropagation();
    dispatch(getFilteredCliniciansObie());
  };

  const handleCloseAndClear = (e: any) => {
    handleClear(e);
    if (name === 'concerns') {
      const newOption: NestedOption[] = options as NestedOption[];
      const selectedOption = newOption.filter((option) => {
        const newOption: NestedOption | Option = option as NestedOption;
        const foundValue = newOption.options.find((option) => selected.includes(option.value));
        return foundValue;
      });
      const filterLabel = selectedOption[0].label.toLowerCase();
      onClear?.(filterLabel);
    } else {
      onClear?.(name);
    }
  };

  const handleSelect = (value: string, filterLabel?: string) => {
    let filterName = filterLabel?.toLowerCase() ?? name;
    if (label?.toLowerCase() === 'availability') {
      filterName = 'availability_filter';
    }
    if (selected?.includes(value)) {
      let updatedValues = [];
      if (filterName === 'gender') {
        updatedValues = selected.filter(
          (option) => option !== value && genderFilters[option] && !genderFilters[option].includes(value),
        );
      } else {
        updatedValues = selected.filter((option) => option !== value);
      }
      onChange(filterName, updatedValues);
      setSelected(updatedValues);
    } else {
      setSelected((prevSelected) => [...prevSelected, value]);
      onChange(filterName, [...selected, value]);
    }
  };

  useEffect(() => {
    if (defaultValues && name === 'gender') {
      const genderValues = [
        {
          gender: 'Male',
          values: genderFilters.Male,
        },
        {
          gender: 'Female',
          values: genderFilters.Female,
        },
        {
          gender: 'Non-binary',
          values: genderFilters['Non-binary'],
        },
      ];
      const selectedGender = defaultValues === 'Non-binary'
        ? [defaultValues]
        : genderValues
          ?.filter(({ gender }) => (defaultValues as string[])?.join()?.includes(gender))
          .map(({ gender }) => gender);
      setSelected(selectedGender);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (defaultValues && name === 'modality') {
      const selectedValues = defaultValues as string[];
      setSelected(selectedValues);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (defaultValues) {
      const values = typeof defaultValues === 'string' && defaultValues.includes(',')
        ? defaultValues.split(',')
        : defaultValues;
      setSelected(values as string[]);
    }
  }, []);

  const clickButton = () => {
    buttonRef.current?.click();
  };

  useEffect(() => {
    if (open) {
      return onClickOut({
        triggerRef,
        action: clickButton,
        ignoreParent: true,
      });
    }
  }, [open, triggerRef]);

  return (
    // In <Modality/> we are returning an array of react elements so we need a default empty container (fragment).
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {name === 'modality' ? (
        <Modality
          name={name}
          options={options as Option[]}
          selected={selected}
          onSelect={handleSelect}
          className={className}
          testId={testId}
        />
      ) : (
        <div
          className={clsx(className, styles.container, [name])}
          data-testId={`container${testId}`}
        >
          <Trigger
            name={name}
            testId={testId}
            label={label}
            onClose={handleCloseAndClear}
            onClick={handleClick}
            selected={selected}
            open={open}
          />
          {open && (
            <div
              ref={triggerRef}
              className={clsx(styles.optionsContainer, {
                [styles.maxHeight]: heightConstraint,
              })}
            >
              <div className={styles.content}>
                {options
                  && options.map((option) => {
                    let newOption: NestedOption | Option = option as NestedOption;
                    if (newOption.options) {
                      return (
                        <div key={`divContainer${option.label}`} className={styles.options}>
                          <h3>{option.label}</h3>
                          {newOption.options.map((nestedOption) => (
                            <div
                              key={`divFilter${nestedOption.value}`}
                              data-testId={nestedOption.testId}
                              className={styles.option}
                            >
                              <input
                                type="checkbox"
                                key={`checkboxFilter${nestedOption.value}`}
                                data-cy={`${name}-${nestedOption.value}`}
                                id={nestedOption.value}
                                value={nestedOption.value}
                                onChange={(e) => handleSelect(
                                  nestedOption.value,
                                  option.label === 'focus' ? option.label : undefined,
                                )}
                                checked={selected.includes(nestedOption.value)}
                              />
                              <label className={styles.label} htmlFor={nestedOption.value}>
                                <div className={styles.checkbox} />
                                <span>{nestedOption.label}</span>
                              </label>
                            </div>
                          ))}
                        </div>
                      );
                    }
                    newOption = option as Option;
                    return (
                      <div
                        className={styles.option}
                        key={`divContainer${newOption.value}`}
                        data-testId={newOption.testId || newOption.value}
                      >
                        <input
                          type="checkbox"
                          id={newOption.value}
                          value={newOption.value}
                          key={`checkboxFilter${newOption.value}`}
                          onChange={(e) => handleSelect((newOption as Option).value)}
                          // Temp fix
                          checked={isChecked(selected, newOption?.value)}
                          data-testId={`option${testId}${newOption.value}`}
                        />
                        <label className={styles.label} htmlFor={newOption.value}>
                          <div className={styles.checkbox} data-cy={`${name}-${newOption.value}`} />
                          <span>{newOption.label}</span>
                        </label>
                      </div>
                    );
                  })}
              </div>
              <div className={styles.footer}>
                <Button size="small" tertiary onClick={handleClear} testId="ClearButton">
                  Clear
                </Button>
                <Button
                  ref={buttonRef}
                  size="small"
                  onClick={handleSaveAndClose}
                  testId="SaveButton"
                  isLoading={isLoading}
                >
                  {buttonText}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
