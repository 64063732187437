import clsx from 'clsx';
import { ReactComponent as TalkingPointIcon } from 'patientOnboarding/assets/svg/lightbulb-line.svg';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';

import { obiePreferencesData } from 'patientOnboarding/selectors/selectors';

import { useInvoca } from 'patientOnboarding/hooks';

import {
  defaultPhoneNumber,
  formatContactNumber,
  stateAbbrToName,
  useDevice,
} from 'patientOnboarding/utils';

import styles from './NoProvidersBox.module.scss';

interface INoProviders {
  showForm: boolean;
  state: string;
  phoneNumber?: string;
  enabledObie?: boolean;
  enabledState?: boolean;
}

export const NoProviders: FC<INoProviders> = ({
  showForm,
  state,
  phoneNumber,
  enabledObie,
  enabledState,
}) => {
  const transitionNoZipCode = useSpring<any>({
    from: { opacity: 0, height: 0, overflow: 'hidden' },
    to: { opacity: 1, height: 196, overflow: 'visible' },
    reverse: !showForm,
    config: { tension: 180, friction: 14 },
  });

  const titleLifestance = enabledObie ? 'No providers currently available' : 'Please call to book';
  const title = 'We are not in your area yet';
  const { isMobile } = useDevice();
  const preferences = useSelector(obiePreferencesData);

  useInvoca(showForm);

  return (
    <animated.div style={transitionNoZipCode}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <TalkingPointIcon />
        </div>
        <div className={styles.contentTitle}>{enabledState ? titleLifestance : title}</div>
        {!enabledState && (
          <div className={styles.contentText}>
            There are currently no LifeStance providers in
            {' '}
            {stateAbbrToName(state)}
            . Insurance
            requires that you see a provider who is licensed in your state of residence.
          </div>
        )}
        {enabledObie && enabledState && (
          <div className={styles.contentText}>
            It looks like we don’t have any providers in&nbsp;
            {stateAbbrToName(state)}
            &nbsp; that are accepting new clients. Please call&nbsp;
            <a
              className={clsx(styles.phone, 'invocaPhoneNumber')}
              href={`tel:${formatContactNumber(phoneNumber) || ''}`}
            >
              {phoneNumber || defaultPhoneNumber}
            </a>
            &nbsp;to be added to our waitlist.
          </div>
        )}
        {!enabledObie && enabledState && (
          <div className={styles.contentText}>
            We can't currently accommodate online booking in&nbsp;
            {stateAbbrToName(state)}
            . To find a provider and finish booking, please call
            {!isMobile ? <br /> : ' '}
            <a
              className={clsx(styles.phone, 'invocaPhoneNumber')}
              href={`tel:${formatContactNumber(phoneNumber) || ''}`}
            >
              {phoneNumber || defaultPhoneNumber}
            </a>
            .
          </div>
        )}
      </div>
    </animated.div>
  );
};
