const types = {
  GET_PATIENT_DETAILS: '@@PO_ADMIN/GET_PATIENT_DETAILS',
  POST_BOOKING_FOR_Self_INFO: '@@PO_ADMIN/POST_BOOKING_FOR_Self_INFO',
  POST_BOOKING_FOR_Child_INFO: '@@PO_ADMIN/POST_BOOKING_FOR_Child_INFO',
  PUT_BOOKING_FOR_Child_INFO: '@@PO_ADMIN/PUT_BOOKING_FOR_Child_INFO',
  PUT_BOOKING_FOR_Self_INFO: '@@PO_ADMIN/PUT_BOOKING_FOR_Self_INFO',
  RESEND_BOOKING_EMAIL: '@@PO_ADMIN/RESEND_BOOKING_EMAIL',
  TRACK_PATIENT_STATUS: '@@PO_ADMIN/TRACK_PATIENT_STATUS',
  START_INTAKE: '@@PO_ADMIN/START_INTAKE',
  SET_ACCOUNT_EXISTS_NEXT_STEPS: '@@PO_ADMIN/SET_ACCOUNT_EXISTS_NEXT_STEPS',
};

export default types;
