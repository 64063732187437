import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ClinicianAvailability } from 'intakeOptimization/@types';

dayjs.extend(customParseFormat);

export const sortAvailabilities = (
  availabilities: ClinicianAvailability[],
): ClinicianAvailability[] => [...availabilities].sort((a, b) => {
  const dateA = dayjs(`${a.available_date} ${a.appointment_start_time}`, 'YYYY-MM-DD HH:mm A');
  const dateB = dayjs(`${b.available_date} ${b.appointment_start_time}`, 'YYYY-MM-DD HH:mm A');
  return dateA.isAfter(dateB) ? 1 : -1;
});
