export const ageRanges = [
  {
    ageRange: '0 TO 5',
    downLimit: 0,
    upLimit: 5,
  },
  {
    ageRange: '6 TO 10',
    downLimit: 6,
    upLimit: 10,
  },
  {
    ageRange: '11 TO 14',
    downLimit: 11,
    upLimit: 14,
  },
  {
    ageRange: '15 TO 17',
    downLimit: 15,
    upLimit: 17,
  },
  {
    ageRange: '18 TO 21',
    downLimit: 18,
    upLimit: 21,
  },
  {
    ageRange: '22 TO 26',
    downLimit: 22,
    upLimit: 26,
  },
  {
    ageRange: '27 TO 40',
    downLimit: 27,
    upLimit: 40,
  },
  {
    ageRange: '41 TO 64',
    downLimit: 41,
    upLimit: 64,
  },
  {
    ageRange: '65+',
    downLimit: 65,
    upLimit: 150,
  },
];
