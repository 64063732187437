import { ActiveFilter } from 'intakeOptimization/store/clinicians/slice';

export const validFilters = [
  'licenseKeys',
  'concerns',
  'expertises',
  'interventions',
  'populations',
  'modality',
  'location_names',
  'gender',
  'language',
  'credentials',
  'distance',
  'entire_state',
];

export const getActiveFilters = (filters: ActiveFilter[]) => filters?.filter(
  (filter) => typeof filter?.value !== 'undefined'
      && (typeof filter?.value === 'number'
        ? !!filter?.value
        : (filter?.value as Array<string | number>).length)
      && validFilters.includes(filter?.name),
);
