import { current } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { FacilityLocation } from 'patientOnboarding/@types';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppointmentCalendar, AppointmentSelector, CircularLoading } from 'lifestance-ui';

import { BookingModal } from 'patientOnboarding/components';
import { NoAvailabilityBox } from 'patientOnboarding/components/ClinicianProfile/components/NoAvailability/NoAvailability';
import { TypesOfCareMeta } from 'patientOnboarding/components/ClinicianProfile/typesOfCareMeta';

import { getClinicianAvailabilities } from 'patientOnboarding/store/matches/actions';
import { updatePreferences } from 'patientOnboarding/store/preferences/actions';
import { updateReservation } from 'patientOnboarding/store/reservationGlobal/reducers';

import {
  availabilities,
  loadingAvailabilities,
  obieClinicianAvailableDates,
  obiePreferencesData,
  reservationData,
  selectedClinicianSelector,
  showAppt,
} from 'patientOnboarding/selectors/selectors';

import {
  formatDuration,
  formatNameOfDay,
  onClickOut,
  sortAvailabilities,
} from 'patientOnboarding/utils';

import styles from './Availability.module.scss';

interface iAvailabilityProps {
  setNext: (viewNumber: number) => void;
  goToIntake: boolean;
  handleContinueConfirmation: () => void;
  isNewTOC: boolean;
}

export const Availability: FC<iAvailabilityProps> = ({
  setNext,
  isNewTOC,
  handleContinueConfirmation,
}) => {
  const dispatch = useDispatch();
  const nonSortedClinicianAvailabilities = useSelector(availabilities);
  const clinicianAvailabilities = nonSortedClinicianAvailabilities
    ? sortAvailabilities(nonSortedClinicianAvailabilities)
    : [];
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [timesAvailable, setTimesAvailable] = useState([] as any[]);
  const [hourSelected, setHourSelected] = useState('' as any);
  const [view, setView] = useState(0);
  const [isAvailable, setIsAvailable] = useState(false);
  const [calendarLoaded, setCalendarLoaded] = useState(false);
  const [duration, setDuration] = useState<number>();
  const [showAppointmentSelector, setShowAppointmentSelector] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const clinicianAvailableDates = useSelector(obieClinicianAvailableDates);
  const isLoadingAvailabilities = useSelector(loadingAvailabilities);
  const preferences = useSelector(obiePreferencesData);
  const reservation = useSelector(reservationData);
  const profile = useSelector(selectedClinicianSelector);
  const showAppointment = useSelector(showAppt);
  const appointmentRef = useRef(null);
  const renderFlag = useRef<boolean>(false);
  const isColdLanding = preferences?.location_back?.length === 0
    || preferences?.location_back.includes('/find-care/booking/search');
  const typeOfCareOptions = profile?.type_of_cares?.map((typeOfCare: string) => ({
    value: typeOfCare,
    label: TypesOfCareMeta[typeOfCare]?.label,
    description: TypesOfCareMeta[typeOfCare]?.description,
  }));
  const currentTOC = isNewTOC || !isColdLanding ? preferences.typeOfCare : preferences.preliminaryTOC;
  const selectedTypeOfCare = isColdLanding && !preferences.zipCode ? currentTOC : typeOfCareOptions[0].value;
  const [openModal, setOpenModal] = useState(false);
  const videoOption = reservation.isVideoOnly;

  const getFormattedDate = (pickedDate: Date) => new Date(pickedDate)?.toISOString().substring(0, 10);

  const getFacility = (facilities: FacilityLocation[]) => {
    const facility = facilities?.find(
      (el: any) => el.facility_id === reservation?.selectedFacility?.facility_id,
    );
    return facility;
  };

  const getClinicianAvailabilitiesByDate = (
    dateSelected: string,
    typeOfCareValue?: string,
    facilityIds?: string[],
  ) => {
    const typeOfCare = typeOfCareValue || currentTOC;
    dispatch(
      getClinicianAvailabilities({
        facilityIds: videoOption
          ? profile.facility_location.map((o: any) => o.facility_id)
          : reservation.facilityIds,
        clinicianId: profile.id,
        availableDate: dateSelected,
        typeOfCare,
        videoOption,
      }),
    );
  };

  const handleHour = (slot: any) => {
    const { duration, date, availabilityStatus } = slot;
    const humanDuration = formatDuration(duration);
    setDuration(humanDuration);
    setHourSelected(date);
    setIsAvailable(availabilityStatus);
    const facility = profile.facility_location.find((f: any) => f.facility_id === slot.facility_id);
    dispatch(
      updateReservation({
        ...slot,
        availability: slot.availabilityStatus,
        selectedFacility: facility,
      }),
    );
    setOpenModal(true);
  };

  const handleContinue = () => {
    setOpenModal(false);
    handleContinueConfirmation();
  };

  const handleSelectDate = (dateSelected: Date) => {
    const now = new Date(dateSelected);
    const utc = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    getClinicianAvailabilitiesByDate(getFormattedDate(utc));
    setStartDate(dateSelected);
    setShowLoading(true);
    setShowAppointmentSelector(false);
    setTimeout(() => {
      setShowLoading(false);
      setShowAppointmentSelector(true);
    }, 1500);
  };

  useEffect(() => {
    if (clinicianAvailabilities && clinicianAvailabilities.length > 0) {
      const times = [] as any[];
      clinicianAvailabilities.forEach((item: any) => {
        if (item.virtual_or_video_visit === 1) {
          times.push({
            ...item,
            modality: 'VIDEO',
            slotStartTime: item.appointment_start_time,
            slotEndTime: item.appointment_end_time,
            date: item.available_date,
            availabilityStatus: true,
          });
        }
        if (item.in_person_visit === 1) {
          times.push({
            ...item,
            modality: 'IN-OFFICE',
            slotStartTime: item.appointment_start_time,
            slotEndTime: item.appointment_end_time,
            date: item.available_date,
            availabilityStatus: true,
          });
        }
      });
      setDuration(formatDuration(times[0].duration));
      setTimesAvailable(times);
      times.forEach((t: any) => {
        t?.clinician_availability_key === reservation?.clinician_availability_key
          ? setHourSelected(reservation?.available_date)
          : null;
      });
      if (videoOption) {
        const modalityCompatible = times.filter((val: any) => val.modality === 'VIDEO');
        setTimesAvailable(modalityCompatible);
      } else {
        setTimesAvailable(times);
      }
    } else if (clinicianAvailabilities && clinicianAvailabilities.length === 0) {
      setTimesAvailable([]);
    }
  }, [nonSortedClinicianAvailabilities]);

  useEffect(() => {
    if (!renderFlag.current) {
      if (profile) {
        const facility = getFacility(profile?.facility_location);
        if (!calendarLoaded && facility?.clinician_availabilities?.length && !isColdLanding) {
          const date = new Date(
            `${facility?.clinician_availabilities[0]?.available_date?.replaceAll('-', '/')}`,
          );
          handleSelectDate(new Date(date?.setDate(date?.getDate())));
          setCalendarLoaded(true);
        } else if (!calendarLoaded) {
          const currentAvailabities = [] as any;
          videoOption
            ? profile?.facility_location?.forEach((o: any) => {
              o?.clinician_availabilities?.forEach((u: any) => {
                currentAvailabities?.push(u);
              });
            })
            : profile?.facility_location
              ?.filter((f: any) => reservation?.facilityIds?.includes(f?.facility_id))
              ?.forEach((o: any) => {
                o?.clinician_availabilities?.forEach((u: any) => {
                  currentAvailabities?.push(u);
                });
              });
          const dates = sortAvailabilities(currentAvailabities);
          const firstDate = dates[0]?.available_date;
          const date = new Date(`${firstDate?.replaceAll('-', '/')}`);
          handleSelectDate(new Date(date?.setDate(date?.getDate())));
          setCalendarLoaded(true);
        }
        renderFlag.current = true;
      }
    }
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
    if (showAppointment) {
      localStorage.setItem('appointment', 'true');
      dispatch(updatePreferences({ showAppointment: false }));
    }
  };

  useEffect(() => {
    if (openModal) {
      return onClickOut({
        triggerRef: appointmentRef,
        action: handleCloseModal,
      });
    }
  }, [openModal, handleCloseModal]);

  return (
    <div className={styles.coldLanding}>
      <div className={styles.coldTitle}> Choose a Date and Time</div>
      <div className={styles.coldDescription}>
        {currentTOC}
        {' '}
        Initial Consultation
      </div>
      <div className={styles.content}>
        <div
          className={clsx(styles.calendar, {
            [styles.windowVisible]: view === 0,
          })}
        />
      </div>
      <AppointmentCalendar
        containerClassName={styles.calendarContainer}
        formatWeekDay={formatNameOfDay}
        startDate={startDate}
        minDate={clinicianAvailableDates[0]}
        includeDates={clinicianAvailableDates}
        handleSelectDate={(date) => handleSelectDate(date)}
      />

      {!isLoadingAvailabilities && clinicianAvailableDates.length === 0 && (
        <NoAvailabilityBox firstName={profile.first_name} typeOfCare={selectedTypeOfCare} />
      )}
      <div
        className={clsx(styles.appointmentsContainer, {
          [styles.windowVisible]: view === 0,
        })}
      >
        {showLoading && <CircularLoading size="xlarge" component="schedule" />}
        {showAppointmentSelector && (
          <AppointmentSelector
            startDate={startDate}
            timesAvailable={timesAvailable}
            reservation={reservation}
            handleHour={handleHour}
            hourSelected={hourSelected}
            profile={profile}
          />
        )}
      </div>
      {openModal && (
        <BookingModal
          title="Appointment details"
          buttonLabel="Looks good"
          onClose={handleCloseModal}
          onContinue={handleContinue}
          setNext={setNext}
          isColdLanding
        />
      )}
    </div>
  );
};
