import clsx from 'clsx';
import { FC } from 'react';

import { useInvoca } from 'patientOnboarding/hooks';

import { defaultPhoneNumber, formatContactNumber } from 'patientOnboarding/utils';

import styles from './InvocaNumber.module.scss';

interface IInvocaNumber {
  phoneNumber?: string;
  isMobile?: boolean;
}

export const InvocaNumber: FC<IInvocaNumber> = ({ phoneNumber, isMobile = true }) => {
  useInvoca();

  return isMobile ? (
    <a
      className={clsx(styles.phone, 'invocaPhoneNumber')}
      href={`tel:${formatContactNumber(phoneNumber) || ''}`}
    >
      {phoneNumber || defaultPhoneNumber}
    </a>
  ) : (
    <span className={clsx(styles.contactNumber, 'invocaPhoneNumber')}>
      {phoneNumber || defaultPhoneNumber}
    </span>
  );
};
