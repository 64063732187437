import * as API from 'intakeOptimization/api/legacy';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { SessionData } from 'intakeOptimization/store/session/reducers';

import { setSessionData } from './actions';
import t from './types';

function* handleSessionToken(action: PayloadAction<string>): Generator<any> {
  try {
    const response = yield call(API.getPatientInfo, action.payload);
    yield put(setSessionData((response as { data: SessionData }).data));
  } catch (e) {
    yield put(
      setSessionData({
        error: 'You are not authorized',
      }),
    );
  }
}

function* sessionSaga(): any {
  yield all([yield takeLatest(t.GET_SESSION_TOKEN, handleSessionToken)]);
}

export default sessionSaga;
