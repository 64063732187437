const invocaScriptId = 'invoca-script';

const getInvocaScript = () => (function (i, n, v, o, c, a) {
  const s = n.createElement('script');
  const fs = n.getElementsByTagName('script')[0];
  i.InvocaTagId = o;
  s.type = 'text/javascript';
  s.async = true;
  s.src = (n.location.protocol === 'https:' ? 'https://' : 'http://') + v;
  s.id = invocaScriptId;
  fs.parentNode.insertBefore(s, fs);
})(
  window,
  document,
  'solutions.invocacdn.com/js/pnapi_integration-latest.min.js',
  '2450/1561747394',
);

export const initInvoca = () => {
  const hasScript = !!document.getElementById(invocaScriptId);

  if (hasScript) return;

  getInvocaScript();
};
