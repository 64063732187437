import * as API from 'patientOnboarding/api/legacy';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  ClinicianAvailability,
  ClinicianAvailabilityPayload,
  FacilityLocation,
} from 'patientOnboarding/@types';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  setClinician,
  setClinicianAvailabilities,
  setClinicianAvailableDates,
  setError,
  setLoading,
} from 'patientOnboarding/store/matches/reducers';

import { setLocations, setModalities } from './actions';
import t from './types';

function* postClinicianModality(action: PayloadAction<string[]>) {
  try {
    const viewClinicianModality: { data: { modalities: string[] } } = yield call(
      API.establishedClinicianModality,
      action.payload,
    );
    if (viewClinicianModality?.data.modalities.length) {
      yield put(setModalities(viewClinicianModality.data.modalities));
    } else {
      const mockData: { modalities: string[] } = {
        modalities: ['video_visits', 'in_office_only'],
      };
      yield put(setModalities(mockData.modalities));
    }
  } catch (e) {
    console.log('error', e);
  }
}

function* postClinicianLocation(action: PayloadAction<string[]>) {
  try {
    const viewClinicianLocation: { data: FacilityLocation[] } = yield call(
      API.getClinicianLocations,
      action.payload,
    );
    yield put(setLocations(viewClinicianLocation?.data));
  } catch (e) {
    console.log('error');
  }
}

function* getClinicianAvailabilities(action: PayloadAction<ClinicianAvailabilityPayload>) {
  yield put(setClinicianAvailableDates([]));
  yield put(setClinicianAvailabilities([]));
  try {
    const response: {
      data: {
        clinician_availabilities: ClinicianAvailability[];
        meta: { clinician_availability_dates: string[] };
      };
    } = yield call(API.getSSOClinicianAvailabilities, {
      patient_status: 'existing',
      facility_id: action.payload.facilityId,
      clinician_id: action.payload.clinicianId,
      available_date: action.payload.availableDate,
      type_of_cares: action.payload.typeOfCare,
    });
    if (response) {
      const data = response.data.clinician_availabilities;
      const availableDates = response.data.meta?.clinician_availability_dates?.map(
        (date: string) => {
          const now = new Date(date);
          const localDate = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
          return localDate;
        },
      );
      const sortedAvailableDates = availableDates.sort(
        (a: Date, b: Date) => a.getTime() - b.getTime(),
      );
      yield put(setClinicianAvailableDates(sortedAvailableDates));
      yield put(setClinicianAvailabilities(data));
      yield put(setClinician(true));
    }
  } catch (e: any) {
    yield put(setClinician(false));
    yield put(setLoading(false));
    yield put(setError(e.response?.status));
  }
}

function* clinicianSaga(): any {
  yield all([
    yield takeLatest(t.VIEW_CLINICIAN_MODALITIES, postClinicianModality),
    yield takeLatest(t.VIEW_CLINICIAN_LOCATIONS, postClinicianLocation),
    yield takeLatest(t.GET_CLINICIAN_AVAILABILITIES, getClinicianAvailabilities),
  ]);
}

export default clinicianSaga;
