import clsx from 'clsx';
import React, { FC, KeyboardEventHandler, useEffect, useRef, useState } from 'react';

import { FormLabel } from '../FormLabel/FormLabel';
import TypeAheadInput from './Input';
import TypeAheadOptionsList from './OptionsList';
import TypeAheadTrigger from './Trigger';
import styles from './DropdownTypeAhead.module.scss';
import { IOptionsSingleList } from './types';
import { transformOptionsList } from './utils';

interface TypeAheadDropdownProps {
  label: string;
  onSelect: (value: string) => void;
  optionsLists: IOptionsSingleList[];
  selected: string;
  className?: string;
  testId?: string;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  lastEventType?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  disableOnClickOut?: boolean;
}

export const DropdownTypeAhead: FC<TypeAheadDropdownProps> = ({
  label,
  onSelect,
  optionsLists,
  selected,
  className,
  testId,
  placeholder,
  disabled,
  error = '',
  lastEventType,
  disableOnClickOut = false,
  onBlur,
  onFocus,
}) => {
  const [query, setQuery] = useState('');
  const [transformedOptionsLists, setTransformedOptionsLists] = useState(optionsLists);
  const [openOptionsList, setOpenOptionsList] = useState(false);
  const [selectedValues, setSelectedValues] = useState(selected);
  const [isFocus, setIsFocus] = useState(true);
  const [isSelectedValue, setIsSelectedValue] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (newValue: string) => {
    setSelectedValues(newValue);
    setQuery('');
    setIsFocus(false);
    setOpenOptionsList(false);
    setIsSelectedValue(true);
    onSelect(newValue);
    if (onBlur) {
      onBlur();
    }
  };

  const handleBlur = () => {
    setQuery('');
    setIsFocus(false);
    setOpenOptionsList(false);
    if (onBlur) {
      onBlur();
    }
  };

  const handleClearInput = () => {
    setQuery('');
  };

  const handleOpenOptionsList = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    setIsFocus(false);
    if (!openOptionsList && !disabled) {
      setOpenOptionsList(true);
      setQuery('');
    }
  };

  const handleInputClose = () => {
    setOpenOptionsList(false);
    setIsFocus(false);
  };

  const handleChangeInput = (value: string) => {
    setQuery(value);
  };

  const handleInputBlur = (e: React.FocusEvent) => {
    e.stopPropagation();
    setIsFocus(false);
  };

  const handleInputFocus = (e: React.FocusEvent) => {
    e.stopPropagation();
    setIsFocus(true);
  };

  useEffect(() => {
    const updatedTransformedOptionsLists = transformOptionsList(
      query,
      optionsLists,
      styles.highlighted,
    );
    setTransformedOptionsLists(updatedTransformedOptionsLists);
  }, [query, optionsLists]);

  useEffect(() => {
    if (onFocus && (openOptionsList || isFocus)) {
      onFocus();
    }
  }, [openOptionsList, isFocus]);

  useEffect(() => {
    if (!isFocus && isSelectedValue) {
      setIsFocus(true);
      setIsSelectedValue(false);
    }
  }, [isSelectedValue, isFocus]);

  useEffect(() => {
    if (selected.length === 0) {
      setSelectedValues('');
    }
  }, [selected]);

  return (
    <div
      data-testid={`typeAheadDropdown${testId}`}
      tabIndex={-1}
      role="listbox"
      className={clsx(className, styles.container)}
      ref={dropdownRef}
    >
      <FormLabel label={label} />
      {openOptionsList && (
        <TypeAheadInput
          onChange={handleChangeInput}
          onClear={handleClearInput}
          onClick={handleOpenOptionsList}
          value={query}
          isFocus={isFocus}
          placeholder={placeholder}
          numberOfOptions={transformedOptionsLists.length}
          onClose={handleInputClose}
          lastEventType={lastEventType}
          testId={testId}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
        />
      )}
      {!openOptionsList && (
        <TypeAheadTrigger
          onClick={handleOpenOptionsList}
          placeholder={placeholder}
          disabled={disabled}
          value={selectedValues}
          error={error}
          lastEventType={lastEventType}
          testId={testId}
        />
      )}
      {openOptionsList && (
        <TypeAheadOptionsList
          selected={selectedValues}
          onSelect={handleSelect}
          optionsLists={transformedOptionsLists}
          query={query}
          disableOnClickOut={disableOnClickOut}
          onBlur={handleBlur}
          testId={testId}
          lastEventType={lastEventType}
        />
      )}
      {error && error.length ? (
        <span
          data-testId={`dropdownError${testId}`}
          className={clsx({ [styles.errorMessage]: error })}
        >
          {error}
        </span>
      ) : null}
    </div>
  );
};
