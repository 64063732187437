export const buildThresholdList = (numSteps = 2) => {
  const thresholds = [];

  for (let i = 1.0; i <= numSteps; i += 1) {
    const ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
};
