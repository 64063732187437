import clsx from 'clsx';
import { ReactComponent as CaretDownIcon } from 'patientOnboarding/assets/svg/caret-down.svg';
import { ReactComponent as CloseIcon } from 'patientOnboarding/assets/svg/close.svg';
import React, { FC } from 'react';

import styles from '../Filter.module.scss';

interface TriggerProps {
  name: string;
  label: string;
  selected: string[];
  open: boolean | null;
  testId: string;
  onClick: () => void;
  onClose: (e: any) => void;
}

const Trigger: FC<TriggerProps> = ({ name, label, selected, open, testId, onClick, onClose }) => {
  const getLabel = (label: string) => {
    let formattedLabel = '';
    if (name === 'concerns') {
      formattedLabel = label;
    }

    if (name === 'availability_filter') {
      switch (label) {
        case 'before_10_AM':
          formattedLabel = 'Before 10am';
          break;
        case 'before_12_PM':
          formattedLabel = 'Before 12pm';
          break;
        case 'after_12_PM':
          formattedLabel = 'After 12pm';
          break;
        case 'after_3_PM':
          formattedLabel = 'After 3pm';
          break;
        case 'next_three_Days':
          formattedLabel = 'Next 3 days';
          break;
        case 'next_week':
          formattedLabel = 'Next week';
          break;
        case 'next_two_Weeks':
          formattedLabel = 'Next 2 weeks';
          break;
        case 'next_month':
          formattedLabel = 'Next month';
          break;
        default:
          break;
      }
    }

    if (name === 'gender') {
      formattedLabel = label;
    }

    if (name === 'language') {
      formattedLabel = label.charAt(0).toUpperCase() + label.slice(1);
    }

    return formattedLabel;
  };

  const hasSelectedOption = (selectedValues: string[]) => {
    let hasSelected = false;
    if (Array.isArray(selectedValues)) {
      hasSelected = selectedValues.filter((f) => f !== undefined).length > 0;
    } else {
      hasSelected = false;
    }
    return hasSelected;
  };

  // The count would be affected by the additional values "M", "F", and "NB" for gender filter functionality, so we clean the array
  const selectedLength = selected.filter((g) => !['M', 'F', 'NB'].includes(g)).length;

  return (
    <>
      <div className={clsx(open && styles.extraLayer, !open && styles.noExtraLayer)} />
      <div
        data-cy={`filter-${name}`}
        data-testId={`caret${testId}`}
        className={clsx(styles.select, {
          [styles.selectOpen]: open,
          [styles.selectedOptions]: hasSelectedOption(selected),
        })}
        onClick={onClick}
      >
        <span className={styles.filterLabel}>
          {selectedLength === 1 ? `${getLabel(selected[0])}` : label}
        </span>
        {selectedLength === 0 ? <CaretDownIcon /> : null}
        {selectedLength === 1 ? (
          <div className={styles.closeIcon} onClick={onClose}>
            <CloseIcon />
          </div>
        ) : null}
        {selectedLength > 1 ? <div className={styles.circle}>{selectedLength}</div> : null}
      </div>
    </>
  );
};

export default Trigger;
