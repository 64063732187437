import clsx from 'clsx';
import { intakeRoutes } from 'patientOnboarding/routes/constants';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isAbie } from 'patientOnboarding/selectors/selectors';

import styles from './Footer.module.scss';

export const Footer = () => {
  const location = useLocation();
  const isAdmin = useSelector(isAbie);
  const currentYear = new Date().getFullYear();
  const isIntakeBooking = location.pathname === intakeRoutes.bookAppointment || location.pathname === '/error';

  return (
    !isIntakeBooking && (
      <footer className={isAdmin ? clsx(styles.footer, styles.admin) : styles.footer}>
        <div className={styles.linksContainer}>
          <span className={styles.legend}>These providers are supported by LifeStance Health</span>
        </div>
        <p className={styles.copyright}>
          <a
            tabIndex={isAdmin ? -1 : 0}
            href="https://lifestance.com/privacy-policy/"
            target="_blank"
            className={styles.link}
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <span className={styles.divider}>|</span>
          © LifeStance&nbsp;
          {currentYear}
        </p>
      </footer>
    )
  );
};
