import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { BookAppointment, RPatientAppointment } from 'patientOnboarding/@types';

import t from './types';

export type ReservationState = {
  patientAppointment: RPatientAppointment;
  error: string;
};
const initialState: ReservationState = {
  patientAppointment: {
    id: -1,
    clinician: {
      id: -1,
      first_name: '',
      last_name: '',
      license_type: '',
      profile_photo: '',
      telehealth_url: '',
    },
    clinician_address: {
      id: -1,
      address_line1: '',
      address_line2: '',
      city: '',
      state: '',
      postal_code: '',
      facility_name: '',
      license_key: -1,
      facility_id: -1,
      rank_most_available: -1,
      rank_soonest_available: -1,
    },
    appointment_start_time: '',
    appointment_end_time: '',
    appointment_utc_start_datetime: '',
    duration: -1,
    modality: '',
    type_of_care: '',
    booked_by: '',
  },
  error: '',
};

const reservationSlice = createSlice({
  name: 'PO_reservation',
  initialState,
  reducers: {
    setPatientAppointment: (state, action: PayloadAction<RPatientAppointment>) => {
      state.patientAppointment = action.payload;
    },
    setAppointmentError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const bookAppointment = createAction<BookAppointment>(t.BOOK_APPOINTMENT);
export const { setPatientAppointment, setAppointmentError } = reservationSlice.actions;
export default reservationSlice.reducer;
