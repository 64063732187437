import API from 'patientOnboarding/api/legacy/config';

export const establishedClinicianModality = (id) => API.get(`/sso_clinician/${id}/modalities`);

export const getClinicianLocations = (id) => API.get(`/sso_clinician/${id}/locations`);

export const getClinician = (id) => API.get(`/clinician/${id}`);

export const getPatientInsuranceInfo = (id) => API.post('/sso_patient_insurance?patient_id=67', {
  patient_id: 67,
  is_changed: false,
}); // Temporal (It has to be patient's ID)

export const updatePatienceInsuranceInfo = ({
  patientID,
  insuranceCarrier,
  memberID,
  mentalHealthPhone,
  primaryPolicyHolder,
  policyHolderObject,
}) => API.post('/sso_patient_insurance', {
  patient_id: patientID,
  is_changed: 'true',
  insurance_details: {
    insurance_carrier: insuranceCarrier,
    member_id: memberID,
    mental_health_phone_number: mentalHealthPhone,
    primary_policy_holder: primaryPolicyHolder,
    provider_id: 3,
    facility_id: 95,
    license_key: 996075,
    policy_holder: {
      // first_name: "kent",
      // last_name: "wenna",
      // date_of_birth: "01/02/1994",
      // gender: "Male",
      // email: "bendi@email.com"
      ...policyHolderObject,
      // TEMPORAL
      first_name: 'kent',
      last_name: 'wenna',
      date_of_birth: '01/02/1994',
      gender: 'Male',
      email: 'bendi@email.com',
      // TEMPORAL (This data has to correspond with the ID)
    },
    address: {
      address_line1: 'Street',
      city: 'Montgomery',
      state: 'AL',
      postal_code: '36111',
    },
  },
});

export const getSSOClinicianAvailabilities = (params) => API.get('/sso_clinician_availabilities', {
  params,
});

export const createAppointment = (params) => API.post('/sso_book_appointments', {
  clinician_availability_key: params.clinician_availability_key,
  patient_id: params.patient_id,
});
