export const cleanFIlters = (filters) => {
  const newFilters = {};
  Object.keys(filters).forEach((key) => {
    const currentFilter = filters[key]?.toString();
    if (currentFilter?.includes('|')) {
      newFilters[key] = currentFilter?.split('|');
    } else if (currentFilter?.includes(',') && key !== 'pronouns') {
      newFilters[key] = currentFilter?.split(',');
    } else {
      newFilters[key] = filters[key];
    }
  });
  return newFilters;
};
