import React from 'react';

import { establishedPatientsRoutes } from './constants';

const Booking = React.lazy(() => import('patientOnboarding/routes/Booking'));
const Intake = React.lazy(() => import('patientOnboarding/routes/Intake'));
const EstablishedPatients = React.lazy(() => import('patientOnboarding/routes/EstablishedPatients'));

const BOOKING_ROUTES = [
  {
    path: 'booking/*',
    Component: Booking,
  },
  {
    path: 'intake/*',
    Component: Intake,
  },
  {
    path: establishedPatientsRoutes.existing,
    Component: EstablishedPatients,
  },
];

export default BOOKING_ROUTES;
