import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useFeature } from 'intakeOptimization/hooks';

interface ProtectedRouteProps {
  featureFlag: string;
  children: FC;
}

export const ProtectedRoute: ({
  featureFlag,
  children,
}: ProtectedRouteProps) => React.FunctionComponent | JSX.Element = ({ featureFlag, children }) => {
  const isEnabled = useFeature(featureFlag);

  return isEnabled ? children : <Navigate to="/error" />;
};
