import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppointmentCalendar, AppointmentSelector, CircularLoading, Modal } from 'lifestance-ui';

import { updateFilters } from 'intakeOptimization/store/filters/actions';
import {
  getClinicianAvailabilities,
  setClinicianAvailableDates,
} from 'intakeOptimization/store/matches/actions';
import { updateReservation } from 'intakeOptimization/store/reservationGlobal/actions';

import {
  abiePreferencesData,
  availabilities,
  availableDates,
  reservationData,
  selectedClinician as selectedClinicianG,
  selectedClinicianSelector,
} from 'intakeOptimization/selectors/selectors';

import {
  formatDuration,
  formatNameOfDay,
  sortAvailabilities,
  useLastKeyDown,
} from 'intakeOptimization/utils';

import styles from './Schedule.module.scss';

interface AdminScheduleProps {
  onCancel: (event: React.MouseEvent | null) => void;
  bothVisits?: boolean;
  video?: boolean;
  office?: boolean;
}

interface Availability {
  duration: string;
  date: string;
  availabilityStatus: boolean;
  facility_id: number;
  appointment_start_time: string;
  appointment_end_time: string;
  virtual_or_video_visit: number;
  in_person_visit: number;
  modality: 'VIDEO' | 'IN-OFFICE';
  available_date: string;
  slotStartTime?: string;
  slotEndTime?: string;
}

interface Facility {
  facility_id: number;
  clinician_availabilities: Array<{
    available_date: string;
    appointment_start_time: string;
    appointment_end_time: string;
    virtual_or_video_visit: number;
    in_person_visit: number;
    duration: number;
  }>;
}

export const AdminSchedule = ({ onCancel, bothVisits, video, office }: AdminScheduleProps) => {
  const dispatch = useDispatch();

  const selectedClinician = useSelector(selectedClinicianG);
  const clinicianAvailabilities = useSelector(availabilities);
  const clinicianAvailableDates = useSelector(availableDates);
  const preferences = useSelector(abiePreferencesData);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [timesAvailable, setTimesAvailable] = useState<Availability[]>([]);
  const [hourSelected, setHourSelected] = useState('');
  const [view, setView] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [calendarLoaded, setCalendarLoaded] = useState(false);
  const [duration, setDuration] = useState('');
  const reservation = useSelector(reservationData);
  const [selectedAvailability, setSelectedAvailability] = useState<Availability>();
  const profile = useSelector(selectedClinicianSelector);
  const renderFlag = useRef(false);
  const [daySelected, setDaySelected] = useState(0);
  const [currentMonth, setCurrentMonth] = useState('');

  const { lastKeyDown } = useLastKeyDown();

  const getFacility = (facilities: Facility[]) => {
    const facility = facilities?.find(
      (el: Facility) => el.facility_id === reservation?.selectedFacility?.facility_id,
    );
    return facility;
  };

  const getClinicianAvailabilitiesByDate = (dateSelected: string) => {
    dispatch(
      getClinicianAvailabilities({
        facilityIds: reservation.facilityIds,
        clinicianId: selectedClinician.id,
        availableDate: dateSelected,
        typeOfCare: preferences?.typeOfCare,
      }),
    );
  };

  const getFormattedDate = (pickedDate: Date) => pickedDate.toISOString().substr(0, 10);

  const handleHour = (slot: Availability) => {
    const { duration, date, availabilityStatus } = slot;
    const humanDuration = formatDuration(duration);
    setSelectedAvailability(slot);
    setDuration(humanDuration.toString());
    setHourSelected(date);
    setIsAvailable(availabilityStatus);
    // FIXME: Remove ABIE action to save facilityId and use just one for both OBIE and ABIE
    dispatch(updateFilters({ facilityId: slot.facility_id }));
    dispatch(updateReservation({ ...slot, availability: slot.availabilityStatus }));
  };

  const handleSelectDate = (dateSelected: Date) => {
    const now = new Date(dateSelected);
    const utc = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    getClinicianAvailabilitiesByDate(getFormattedDate(utc));
    setStartDate(dateSelected);
    setDaySelected((prevNumber) => prevNumber + 1);
  };

  const handleCancelEvent = (event: React.MouseEvent | null) => {
    dispatch(setClinicianAvailableDates([]));
    setStartDate(null);
    onCancel(event);
  };

  useEffect(() => {
    if (clinicianAvailabilities && clinicianAvailabilities.length > 0) {
      const times: Availability[] = [];
      sortAvailabilities(clinicianAvailabilities)
        .filter((item) => {
          if (bothVisits) {
            return item.virtual_or_video_visit === 1 || item.in_person_visit === 1;
          }
          if (video) {
            return item.virtual_or_video_visit === 1;
          }
          if (office) {
            return item.in_person_visit === 1;
          }
          return true;
        })
        .forEach((item) => {
          if (item.virtual_or_video_visit === 1 && (bothVisits || video)) {
            times.push({
              ...item,
              modality: 'VIDEO',
              slotStartTime: item.appointment_start_time,
              slotEndTime: item.appointment_end_time,
              date: item.available_date,
              availabilityStatus: true,
            });
          }
          if (item.in_person_visit === 1 && (bothVisits || office)) {
            times.push({
              ...item,
              modality: 'IN-OFFICE',
              slotStartTime: item.appointment_start_time,
              slotEndTime: item.appointment_end_time,
              date: item.available_date,
              availabilityStatus: true,
            });
          }
        });
      setDuration(formatDuration(times?.[0]?.duration).toString());
      setTimesAvailable(times);
    } else if (clinicianAvailabilities && clinicianAvailabilities.length === 0) {
      setTimesAvailable([]);
    }
  }, [clinicianAvailabilities]);

  const hasClass = (element: HTMLElement | null, className: string) => {
    let exist = true;
    if (element !== null || element !== undefined) {
      exist = ` ${element?.className} `.indexOf(` ${className} `) > -1;
    } else {
      exist = false;
    }

    return exist;
  };

  const addTabIndexToDays = (miliseconds: number) => {
    setTimeout(() => {
      const dayElements = document?.querySelectorAll('.react-datepicker__day');
      dayElements.forEach((element) => {
        if (!hasClass(element as HTMLElement, 'react-datepicker__day--disabled')) {
          (element as HTMLElement).tabIndex = 0;
          (element as HTMLElement).addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === ' ' || e.key === 'Enter') {
              e.preventDefault();
              (element as HTMLElement).click();
            }
          });
        }
      });

      document?.querySelector('#appointmentSlots')?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, miliseconds);
  };

  const focusRightElement = (miliseconds: number) => {
    setTimeout(() => {
      const selectedDay = document?.querySelector('.react-datepicker__day--selected');
      // If both change month buttons are disabled, we focus on the selected day
      if (
        (document?.querySelector('#buttonIncreaseMonth') as HTMLButtonElement)?.disabled
        && (document?.querySelector('#buttonDecreaseMonth') as HTMLButtonElement)?.disabled
      ) {
        (selectedDay as HTMLElement).focus();
      } else if (
        (daySelected <= 1 || selectedDay === null || selectedDay === undefined)
        && !lastKeyDown.includes('Arrow')
      ) {
        if (
          (document?.querySelector('#buttonDecreaseMonth') as HTMLElement)?.hasAttribute('disabled')
        ) {
          (document?.querySelector('#buttonIncreaseMonth') as HTMLElement).focus();
          (document?.querySelector('#buttonIncreaseMonth') as HTMLElement)?.classList?.add(
            'focus-visible',
          );
        } else if (!hasClass(document?.querySelector('#buttonIncreaseMonth'), 'focus-visible')) {
          (document?.querySelector('#buttonDecreaseMonth') as HTMLElement)?.focus();
          (document?.querySelector('#buttonDecreaseMonth') as HTMLElement)?.classList?.add(
            'focus-visible',
          );
        }
      } else if (
        !hasClass(document?.querySelector('#buttonDecreaseMonth'), 'focus-visible')
        && !hasClass(document?.querySelector('#buttonIncreaseMonth'), 'focus-visible')
        && !lastKeyDown.includes('Arrow')
      ) {
        // If a month arrow is focused, we don't want the selected day to be focused.
        // If the last keydown is an arrow we don't want to focus the selected day
        (selectedDay as HTMLElement).focus();
      }
    }, miliseconds);
  };

  useEffect(() => {
    if (!renderFlag.current) {
      setIsOpen(true);
      if (selectedClinician) {
        const facility = getFacility(selectedClinician?.facility_location);
        if (!calendarLoaded && facility?.clinician_availabilities?.length) {
          const date = new Date(
            `${facility.clinician_availabilities[0]?.available_date?.replaceAll('-', '/')}`,
          );
          handleSelectDate(new Date(date.setDate(date.getDate())));
          setCalendarLoaded(true);
        }
        renderFlag.current = true;
      }
    }

    const monthInterval = setInterval(() => {
      const currMonth = (document?.querySelector('.react-datepicker__current-month') as HTMLElement)
        ?.innerText;
      setCurrentMonth(currMonth);
    }, 500);

    return () => clearInterval(monthInterval);
  }, []);

  useEffect(() => {
    const handleDecrease = (e: Event) => {
      if ((e as KeyboardEvent).key === ' ' || (e as KeyboardEvent).key === 'Enter') {
        addTabIndexToDays(500);
      }
    };
    setTimeout(() => {
      (document?.querySelector('#buttonDecreaseMonth') as HTMLElement)?.addEventListener(
        'keydown',
        handleDecrease,
      );
      (document?.querySelector('#buttonIncreaseMonth') as HTMLElement)?.addEventListener(
        'keydown',
        handleDecrease,
      );
    }, 500);
    return () => {
      document
        ?.querySelector('#buttonDecreaseMonth')
        ?.removeEventListener('keydown', handleDecrease);
      document
        ?.querySelector('#buttonIncreaseMonth')
        ?.removeEventListener('keydown', handleDecrease);
    };
  }, [clinicianAvailableDates.length]);

  useEffect(() => {
    // The use of the arrows for the calendar messes the tabindex of the calendar
    // making the Tab unusable, so we add them again.
    if (lastKeyDown.includes('Arrow')) {
      addTabIndexToDays(500);
    }
  }, [lastKeyDown]);

  useEffect(() => {
    addTabIndexToDays(500);
    focusRightElement(500);
  }, [clinicianAvailableDates.length, currentMonth]);

  return isOpen ? (
    <Modal
      testId="modalCalendarAdmin"
      isOpen={isOpen}
      onClose={() => handleCancelEvent(null)}
      onBack={() => setView(0)}
      variant="close"
      title="Availability"
      disableBodyScrollingOnMount={false}
    >
      <div
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Escape') {
            e.preventDefault();
            onCancel(e as unknown as React.MouseEvent);
          }
        }}
      >
        <div className={styles.container}>
          <div className={styles.headingDesktop}>
            <h1 className={styles.title}>
              All availability for
              {' '}
              {profile?.first_name}
              {' '}
              {profile?.last_name}
              {profile?.credentials ? `, ${profile.credentials}` : ''}
            </h1>
          </div>
          <div className={styles.headingMobile}>
            <h1 className={styles.title}>{view === 0 ? 'Choose a date' : 'Choose a time'}</h1>
          </div>
          <p className={styles.description}>
            This will be a
            {' '}
            {duration}
            -minute consultation appointment.
          </p>
          <div className={styles.content}>
            <div
              className={clsx(styles.calendar, {
                [styles.windowVisible]: view === 0,
              })}
            >
              <div className={styles.appointmentType}>
                <div className={styles.appointmentNote}>
                  <h3 className={styles.appointmentTypeTitle}>Appointment Type</h3>
                  <p className={styles.appointmentTypeDesc}>{preferences.typeOfCare}</p>
                </div>
              </div>
              {clinicianAvailableDates.length > 0 ? (
                <AppointmentCalendar
                  formatWeekDay={formatNameOfDay}
                  startDate={startDate as unknown as Date}
                  minDate={clinicianAvailableDates[0]}
                  includeDates={clinicianAvailableDates}
                  handleSelectDate={handleSelectDate}
                  onMonthChange={() => addTabIndexToDays(500)}
                />
              ) : (
                <CircularLoading />
              )}
            </div>
            <div
              className={clsx(styles.appointmentsContainer, {
                [styles.windowVisible]: view === 0,
              })}
            >
              <AppointmentSelector
                startDate={startDate as unknown as Date}
                timesAvailable={timesAvailable}
                reservation={reservation}
                handleHour={handleHour}
                hourSelected={hourSelected}
                slotsTabIndex={-1}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  );
};
