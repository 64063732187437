import { createAction } from '@reduxjs/toolkit';

import t, { FilterDataPayload, InsuranceCardPayload } from './types';

export const getAddressData = createAction<string>(t.VALIDATE_ZIP_INSURANCE);
export const getInsuranceFilters = createAction<FilterDataPayload>(t.GET_INSURANCE_FILTERS);
export const saveInsuranceCard = createAction<InsuranceCardPayload>(t.SAVE_INSURANCE_CARD);
export const getLicenseKeyRules = createAction<number>(t.GET_LICENSE_KEY_RULES);

export {
  setError,
  setAddressData,
  setInsuranceFilters,
  setInsuranceCards,
  setInsuranceSkipFlag,
  setShowCCOF,
  setHideSkipButtonCCOF,
  setHeartlandApiKey,
  resetInsurance,
} from './reducers';
