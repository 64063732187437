import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FacilityLocation } from 'patientOnboarding/@types';

export type InitialState = {
  modalities: string[];
  locations: FacilityLocation[];
  selectedModality: string;
};

const initialState: InitialState = {
  modalities: [] as string[],
  locations: [] as FacilityLocation[],
  selectedModality: '',
};

const cliniciansSlice = createSlice({
  name: 'SSO_Clinicians',
  initialState,
  reducers: {
    setModalities: (state, action: PayloadAction<string[]>) => ({
      ...state,
      modalities: action.payload,
    }),
    setLocations: (state, action: PayloadAction<FacilityLocation[]>) => ({
      ...state,
      locations: action.payload,
    }),
    updateClinician: (state, action: PayloadAction<string>) => ({
      ...state,
      selectedModality: action.payload,
    }),
  },
});

export const { setModalities, setLocations, updateClinician } = cliniciansSlice.actions;

export default cliniciansSlice.reducer;
