import React, { useEffect, useRef, useState } from 'react';

import { Button, Input } from 'lifestance-ui';

import { Modal } from 'patientOnboarding/components';

import { validateEmail } from 'patientOnboarding/utils';

import styles from './ReSendEmail.module.scss';

interface ReSendProps {
  onResend: (firstMail: string, secondMail: string) => void;
  description: string;
  title: string;
  open: boolean;
  onClose: () => void;
}

export const ReSendEmail: React.FC<React.PropsWithChildren<ReSendProps>> = ({
  onResend,
  title,
  description,
  open,
  onClose,
}) => {
  const [firstEmail, setFirstEmail] = useState('');
  const [secondEmail, setSecondEmail] = useState('');
  const [isEmailMatching, setIsEmailMatching] = useState(true);
  const [error, setError] = useState('');
  const [validationError, setValidationError] = useState('');

  const formRef = useRef() as React.RefObject<HTMLFormElement>;

  useEffect(() => {
    !isEmailMatching ? setError('Email addresses do not match.') : setError('');
  }, [isEmailMatching]);

  const handelEmailValidation = (firstEmail: string) => {
    !validateEmail(firstEmail)
      ? setValidationError('Please enter a valid email address')
      : setValidationError('');
  };

  const handleInput = (value: string, id: number) => {
    id === 1 ? setFirstEmail(value) : setSecondEmail(value);
  };

  const verifyMatch = () => {
    firstEmail === secondEmail ? setIsEmailMatching(true) : setIsEmailMatching(false);
  };
  const isConfirm = () => firstEmail !== ''
    && secondEmail !== ''
    && firstEmail === secondEmail
    && error === ''
    && validationError === '';

  const sendEmail = () => {
    if (firstEmail === secondEmail) {
      onResend(firstEmail, secondEmail);
    }
  };

  return (
    <Modal title={title} modalOpen={open} testId="ResendEmail" closeModal={onClose}>
      <div className={styles.container}>
        <p className={styles.description}>{description}</p>
        <form ref={formRef}>
          <div className={styles.input}>
            <label htmlFor="emailInput"> Email address </label>
            <Input
              id="emailInput"
              testId="EmailAddress"
              type="email"
              placeholder="julia@email.com"
              onBlur={(e) => handelEmailValidation(e.target.value)}
              error={validationError}
              onChange={(e) => {
                handleInput(e, 1);
              }}
            />
          </div>
          <div className={styles.input}>
            <label htmlFor="emailInput"> Confirm email address </label>
            <Input
              id="secondEmailInput"
              testId="ConfirmEmailAddress"
              type="email"
              placeholder="julia@email.com"
              onChange={(event) => {
                handleInput(event, 2);
              }}
              onBlur={verifyMatch}
              error={error}
            />
          </div>
          <Button testId="buttonSubmitEmail" onClick={sendEmail} fullWidth disabled={!isConfirm()}>
            <p>Submit</p>
          </Button>
        </form>
      </div>
    </Modal>
  );
};
