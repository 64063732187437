import { useEffect, useState } from 'react';

export const useDetectBrowser = (): { browser: string; isAndroid: boolean; isIOS: boolean } => {
  const [browserInfo, setBrowserInfo] = useState<{
    browser: string;
    isAndroid: boolean;
    isIOS: boolean;
  }>({
    browser: '',
    isAndroid: false,
    isIOS: false,
  });

  useEffect(() => {
    const userAgent = navigator.userAgent;
    let detectedBrowser = 'Unknown';
    let isAndroid = false;
    let isIOS = false;

    if (/Android/i.test(userAgent)) {
      isAndroid = true;
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      isIOS = true;
    }

    if (userAgent.indexOf('Firefox') > -1) {
      detectedBrowser = 'Mozilla Firefox';
    } else if (userAgent.indexOf('SamsungBrowser') > -1) {
      detectedBrowser = 'Samsung Internet';
    } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
      detectedBrowser = 'Opera';
    } else if (userAgent.indexOf('Trident') > -1) {
      detectedBrowser = 'Microsoft Internet Explorer';
    } else if (userAgent.indexOf('Edge') > -1) {
      detectedBrowser = 'Microsoft Edge';
    } else if (userAgent.indexOf('Chrome') > -1) {
      detectedBrowser = 'Chrome';
    } else if (userAgent.indexOf('Safari') > -1) {
      detectedBrowser = 'Safari';
    }

    setBrowserInfo({ browser: detectedBrowser, isAndroid, isIOS });
  }, []);

  return browserInfo;
};
