import { FC, PropsWithChildren, Suspense, lazy, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isAbie } from 'patientOnboarding/selectors/selectors';

// TODO: After split replace this theme as it will be useless
const ObieTheme = lazy(() => import('./ObieTheme'));
const AbieTheme = lazy(() => import('./AbieTheme'));

export const ThemeSelector: FC<PropsWithChildren> = ({ children }) => {
  const isAdmin = useSelector(isAbie);
  return (
    <>
      <Suspense fallback={<></>}>
        {!isAdmin && <ObieTheme />}
        {isAdmin && <AbieTheme />}
      </Suspense>
      {children}
    </>
  );
};
