import clsx from 'clsx';
import {
  bookingRoutes as absoluteBookingRoutes,
  intakeRoutes,
} from 'patientOnboarding/routes/absolutes';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

import { MatchedConditions } from 'patientOnboarding/components';

import { getClinicians } from 'patientOnboarding/store/cliniciansGlobal/slice';
import { updatePreferences } from 'patientOnboarding/store/preferences/actions';
import { updateReservation } from 'patientOnboarding/store/reservationGlobal/actions';

import {
  obiePreferencesData,
  reservationData,
  selectedClinicianSelector,
} from 'patientOnboarding/selectors/selectors';

import { cleanNullProperties, getFormatForMatches } from 'patientOnboarding/utils';

import styles from './ViewMatchedCondtitions.module.scss';

interface IMatchedConditionsProps {
  handleBadTypeOfCare: (toc: string) => void;
}

export const ViewMatchedConditions: FC<React.PropsWithChildren<IMatchedConditionsProps>> = ({
  handleBadTypeOfCare,
}) => {
  const preferences = useSelector(obiePreferencesData);
  const reservation = useSelector(reservationData);
  const profile = useSelector(selectedClinicianSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { age, zipCode, preliminaryDOB } = preferences;

  // TBD with other modal for best matches
  const handleViewMatches = () => {
    // We will not need preliminary anymore
    dispatch(updatePreferences({ rematch_clinician_id: profile.id, preliminaryTOC: '' }));
    const formattedFilters = getFormatForMatches({
      ...preferences,
      zipCode,
      age,
      /**
       * As we are no longer using the preliminary TOC to go back in the modal and we will be setting the criteria to get matches
       * we set the preliminary as the typeOfCare as the user will be looking into new results
       * */
      typeOfCare: preferences.preliminaryTOC,
    });
    if (
      formattedFilters.insurances === 'I don’t see my insurance'
      || formattedFilters.insurances === 'self_pay'
    ) {
      formattedFilters.insurances = '';
      formattedFilters.payment_type = 'self_pay';
    }
    const cleanedFilters = cleanNullProperties(formattedFilters);
    dispatch(getClinicians({ activeFilters: cleanedFilters }));
    const queryString = Object.keys(cleanedFilters)
      .map((key) => `${key}=${cleanedFilters[key]}`)
      .join('&');
    navigate(`${absoluteBookingRoutes.providerMatches}?${queryString}`, {
      state: { search: queryString, coldLanding: true },
    });
  };

  const handleContinue = () => {
    navigate(intakeRoutes.intake);
    dispatch(
      updatePreferences({
        clinician_profile_back: [
          ...preferences.clinician_profile_back,
          `${location.pathname}${location.search}`,
        ],
        /**
         * Since we will continue with this clinician we are setting the user preferences with the actual TOC and
         * removing the preliminary one as we will not needed it anymore going forward.
         */
        typeOfCare: preferences.preliminaryTOC,
        rematch_clinician_id: 0,
        preliminaryTOC: '',
      }),
    );
    dispatch(
      updateReservation({
        selectedFacility: { ...reservation.selectedFacility, license_key: profile.license_key },
      }),
    );
  };

  const transitionsConditions = useTransition(true, {
    from: { y: -50, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: -50, opacity: 0 },
    reverse: true,
    delay: 200,
  });

  return (
    <div className={clsx(styles.container, styles.scrollable)}>
      {transitionsConditions(
        (stylesObject, item) => item && (
        <animated.div style={stylesObject}>
          <MatchedConditions
            onClick={handleContinue}
            onViewMoreMatches={handleViewMatches}
            zipCode={zipCode}
            dateOfBirth={preliminaryDOB}
            handleRematchTOC={handleBadTypeOfCare}
            selectedTypeOfCare={preferences.preliminaryTOC}
          />
        </animated.div>
        ),
      )}
    </div>
  );
};
