import * as API from 'intakeOptimization/api/legacy';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setLoading } from '../user/actions';
import { setAppointmentError, setPatientAppointment } from './actions';
import t from './types';

function* handleBookAppointment(action) {
  try {
    yield put(setLoading(true));
    yield put(setAppointmentError(''));
    const response = yield call(API.bookAppt, action.payload);
    yield put(setPatientAppointment(response.data.patient_appointment));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    yield put(setAppointmentError('Appointment no longer available'));
  }
}

function* reservationSaga() {
  yield all([yield takeLatest(t.BOOK_APPOINTMENT, handleBookAppointment)]);
}

export default reservationSaga;
