import { useEffect, useState } from 'react';

export const useDevice = () => {
  const [isTablet, setIsTablet] = useState(false);
  const [isSmallTablet, setIsSmallTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    if (width < 480) {
      setIsMobile(true);
    } else if (width >= 481 && width < 768) {
      setIsSmallTablet(true);
    } else if (width >= 768 && width <= 1279) {
      setIsTablet(true);
    } else {
      setIsDesktop(true);
    }
  }, []);

  return { isTablet, isMobile, isSmallTablet, isDesktop };
};
