import clsx from 'clsx';
import { ClinicianAvailability, FacilityLocation } from 'intakeOptimization/@types';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Dropdown } from 'lifestance-ui';

import { TypesOfCareMeta } from 'intakeOptimization/components/ClinicianProfile/typesOfCareMeta';

import { getClinicianProfile } from 'intakeOptimization/store/matches/actions';
import { updatePreferences } from 'intakeOptimization/store/preferences/actions';
import { updateReservation } from 'intakeOptimization/store/reservationGlobal/actions';

import {
  OBIEState,
  isAbie,
  isClinicianProfileLoading,
  obieOrAbiePreferencesSelector,
  reservationData,
  selectedClinician,
} from 'intakeOptimization/selectors/selectors';

import { getLocalTime, getWeekDay, orderAges, sortAvailabilities } from 'intakeOptimization/utils';

import formatAndLocalDate from 'lifestance-ui/utils/formatAndLocalDate';
import { activeFiltersSelector } from 'intakeOptimization/store/clinicians/selectors';
import ModalityIcon from '../AvailabilityBox/components/ModalityIcon';
import { NoAvailabilityBox } from '../NoAvailability/NoAvailability';
import styles from './NewPatientBoxAdmin.module.scss';

interface NewPatientBoxProps {
  handleOpen: (value: boolean) => void;
  onOpenCalendar: () => void;
}

type AvailabilityToDisplay = {
  modality: 'VIDEO' | 'IN-OFFICE';
  slotStartTime: string;
  slotEndTime: string;
  date: string;
  availabilityStatus: boolean;
} & ClinicianAvailability;

type SelectedFacilities = {
  isVideoOnly: boolean;
  facilityIds: number[];
};

const NewPatientBoxAdmin: FC<NewPatientBoxProps> = ({ handleOpen, onOpenCalendar }) => {
  const activeFilters = useSelector(activeFiltersSelector);
  const currentPreferences = activeFilters;
  const profile = useSelector(selectedClinician);
  const [displayAvailabilities, setDisplayAvaibilities] = useState(false);
  const [clinicianAvailabilities, setClinicianAvailabilities] = useState(
    [] as AvailabilityToDisplay[],
  );

  const inOffice = (currentPreferences?.modality as string[])?.includes('in_office');
  const inVirtual = (currentPreferences?.modality as string[])?.includes('video_visit');
  const bothVisitTypes = (inOffice && inVirtual) || (currentPreferences?.modality as string[])?.length === 0;

  const orderedAges = orderAges(profile?.ages_accepted);
  const youngerAge = orderedAges.split('-')[0];

  const typeOfCareOptionsUnfiltered = profile?.type_of_cares?.map((typeOfCare: string) => ({
    value: typeOfCare,
    label: TypesOfCareMeta[typeOfCare]?.label,
    description: TypesOfCareMeta[typeOfCare]?.description,
  }));

  let typeOfCareOptions;

  if (youngerAge >= 18) {
    typeOfCareOptions = typeOfCareOptionsUnfiltered.filter(
      (e: any) => !e.value.toLowerCase().includes('child'),
    );
  } else {
    typeOfCareOptions = typeOfCareOptionsUnfiltered;
  }

  const dispatch = useDispatch();
  const isAdmin = useSelector(isAbie);
  const reservation = useSelector(reservationData);
  const preferences = useSelector(obieOrAbiePreferencesSelector);
  const isLoadingProfile = useSelector(isClinicianProfileLoading);
  const navigate = useNavigate();
  const location = useLocation();
  const renderRef = useRef<boolean>(false);

  const isColdLanding = !isAdmin
    ? preferences?.location_back?.length === 0
      || profile?.id === (preferences as OBIEState)?.rematch_clinician_id
    : null;
  const [selectedTypeOfCare, setSelectedTypeOfCare] = useState(
    isColdLanding ? typeOfCareOptions?.[0]?.value : preferences.typeOfCare,
  );

  const handleTypeOfCare = (typeOfCare: string) => {
    setSelectedTypeOfCare(typeOfCare);
    dispatch(updatePreferences({ typeOfCare }));
    dispatch(updateReservation({ facility_id: clinicianAvailabilities[0]?.facility_id }));
    const clinicianId = profile.id;
    setClinicianAvailabilities([]);
    dispatch(updateReservation({ availability: false }));
    dispatch(getClinicianProfile(clinicianId, typeOfCare));
  };

  const getCityByFacilityId = (facilityId: number, profileData: any): string => profileData?.facility_location?.find((f: any) => f.facility_id === facilityId).city;

  useEffect(() => {
    if (!renderRef.current) {
      if (isColdLanding) {
        dispatch(getClinicianProfile(profile.id, selectedTypeOfCare));
      }
      dispatch(updatePreferences({ typeOfCare: selectedTypeOfCare }));
      dispatch(updateReservation({ facility_id: clinicianAvailabilities[0]?.facility_id }));
      renderRef.current = true;
    }
  }, []);

  const getFacilityLocationIndex = () => {
    if (reservation?.selectedFacility) {
      return profile?.facility_location.findIndex(
        (facility: FacilityLocation) => facility.id === reservation?.selectedFacility?.id,
      );
    }
    let index = 0;
    for (let i = 0; i <= profile?.facility_location?.length; i += 1) {
      if (profile?.facility_location[i]?.clinician_availabilities.length > 0) {
        index = i;
        break;
      }
    }

    return index;
  };

  useEffect(() => {
    if (profile && profile?.facility_location) {
      const temp: any = [];
      const firstFacilityLocation = profile?.facility_location[getFacilityLocationIndex()];
      const facilityClinicianAvailabilities = firstFacilityLocation?.clinician_availabilities
        ? sortAvailabilities(firstFacilityLocation.clinician_availabilities)
        : [];
      const allAvailabilities: AvailabilityToDisplay[] = facilityClinicianAvailabilities.reduce(
        (acc: AvailabilityToDisplay[], current) => {
          if (current.reason.includes('TORO')) {
            return acc.concat(
              {
                ...current,
                modality: 'VIDEO',
                slotStartTime: current.appointment_start_time,
                slotEndTime: current.appointment_end_time,
                date: current.available_date,
                availabilityStatus: true,
              },
              {
                ...current,
                modality: 'IN-OFFICE',
                slotStartTime: current.appointment_start_time,
                slotEndTime: current.appointment_end_time,
                date: current.available_date,
                availabilityStatus: true,
              },
            );
          }
          if (current.reason.includes('TELE')) {
            return acc.concat({
              ...current,
              modality: 'VIDEO',
              slotStartTime: current.appointment_start_time,
              slotEndTime: current.appointment_end_time,
              date: current.available_date,
              availabilityStatus: true,
            });
          }
          return acc.concat({
            ...current,
            modality: 'IN-OFFICE',
            slotStartTime: current.appointment_start_time,
            slotEndTime: current.appointment_end_time,
            date: current.available_date,
            availabilityStatus: true,
          });
        },
        [],
      );
      const availabilitiesToDisplay = allAvailabilities
        .filter((item) => {
          if (bothVisitTypes) {
            return item.modality === 'VIDEO' || item.modality === 'IN-OFFICE';
          }
          if (inVirtual) {
            return item.modality === 'VIDEO';
          }
          return item.modality === 'IN-OFFICE';
        })
        .slice(0, 3);
      setClinicianAvailabilities(availabilitiesToDisplay);
    }
  }, [profile]);

  const handleAppointment = (slot: any) => {
    dispatch(updateReservation({ ...slot, availability: slot.availabilityStatus }));
  };

  const isTomorrow = (date: Date) => {
    const newDate = new Date();
    const tomorrow = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 1);
    return (
      tomorrow.getFullYear() === date.getFullYear()
      && tomorrow.getMonth() === date.getMonth()
      && tomorrow.getDate() === date.getDate()
    );
  };

  const getDate = (value: string) => {
    if (!value.length) return;
    const dateValue = new Date(value.replace(/-/g, '/'));
    if (isTomorrow(dateValue)) {
      return 'Tomorrow';
    }
    const options: any = {
      day: 'numeric',
      month: 'numeric',
    };
    return new Date(value.replace(/-/g, '/')).toLocaleString('en-US', options);
  };

  const renderAvailabilities = () => clinicianAvailabilities.length === 0 && !isLoadingProfile ? (
    <NoAvailabilityBox firstName={profile.first_name} typeOfCare={selectedTypeOfCare} />
  ) : (
    <div
      className={clsx(styles.formInputs, {
        [styles.formInputLoading]: isLoadingProfile,
      })}
    >
      {clinicianAvailabilities.length > 0 && !isLoadingProfile && (
      <div className={styles.initialTitle}>This will be an initial consultation.</div>
      )}
      {clinicianAvailabilities.map((slot) => (
        <div
          data-testId="clinicianAvailabilities"
          className={clsx(styles.appointment, {
            [styles.selected]:
                reservation.clinician_availability_key === slot.clinician_availability_key
                && reservation.modality === slot.modality,
            [styles.selectedAdmin]: isAdmin,
          })}
          onClick={() => handleAppointment(slot)}
        >
          <ModalityIcon modality={slot.modality} data-testId="mobilityIcon" />
          <span className={styles.slotLabel}>
            {formatAndLocalDate(slot.appointment_utc_start_datetime).dateSlotFormat}
            {' | '}
            {slot.modality === 'VIDEO' ? 'Video' : getCityByFacilityId(slot.facility_id, profile)}
          </span>
        </div>
      ))}
      {isColdLanding ? (
        <Button
          className={styles.button}
          onClick={() => handleOpen(true)}
          size="medium"
          fullWidth
          isLoading={isLoadingProfile}
          disabled={!reservation?.availability || isLoadingProfile}
        >
          Continue
        </Button>
      ) : (
        <Button
          fullWidth
          onClick={onOpenCalendar}
          className={styles.book}
          data-testId="bookAppointment"
          id="seeMoreAvailability"
        >
          See more availability
        </Button>
      )}
    </div>
  );

  useEffect(() => {
    if (!isColdLanding) {
      setDisplayAvaibilities(true);
      setSelectedTypeOfCare(preferences.typeOfCare);
    }
  }, []);

  useEffect(() => {
    if (preferences.typeOfCare !== '') {
      setSelectedTypeOfCare(preferences.typeOfCare);
    }
  }, [preferences.typeOfCare]);

  const showAvailabilities = () => {
    setDisplayAvaibilities(true);
  };

  return (
    <div className={styles.newPatientContainer}>
      <div className={styles.box}>
        <div className={styles.titleBox}>
          <div className={styles.title} data-testId="existingPatients">
            New patient?
          </div>
          {displayAvailabilities ? (
            ''
          ) : (
            <div className={styles.description}>This will be an initial consultation</div>
          )}
          {selectedTypeOfCare ? (
            <>
              <div className={styles.reasonLabel}>What is the reason for your visit?</div>
              <div>
                <Dropdown
                  options={typeOfCareOptions}
                  placeholder="Select an option"
                  onChange={handleTypeOfCare}
                  title="Type of care"
                  value={selectedTypeOfCare}
                  sideWays
                  testId="typeOfCare"
                  disabled={!isColdLanding}
                  icon="CloseIcon"
                />
                {displayAvailabilities ? (
                  renderAvailabilities()
                ) : (
                  <Button
                    className={styles.button}
                    onClick={showAvailabilities}
                    size="medium"
                    fullWidth
                    isLoading={isLoadingProfile}
                    disabled={isLoadingProfile}
                  >
                    See availability
                  </Button>
                )}
              </div>
            </>
          ) : (
            <NoAvailabilityBox
              firstName={profile.first_name}
              typeOfCare={selectedTypeOfCare}
              emptyAppointment
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default NewPatientBoxAdmin;
