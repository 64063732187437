import clsx from 'clsx';
import { FC } from 'react';

import styles from './LicenseKeyPillbox.module.scss';

interface IPillbox {
  licenseKey?: number;
  className?: string;
}

export const LicenseKeyPillbox: FC<IPillbox> = ({ licenseKey = '--', className = '' }) => (
  <span className={clsx(className, styles.licenseKey)}>{licenseKey}</span>
);
