/**
 * ABIE SSO Authentication
 */
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import clsx from 'clsx';
import { msalConfig } from 'intakeOptimization/auth/config';
import Auth from 'intakeOptimization/views/Auth';
import ErrorHandlerView from 'modules/application/views/ErrorHandlers';
import TelehealthURLError from 'modules/application/views/ErrorHandlers/TelehealthURLError';
import UnknownErrorView from 'modules/application/views/ErrorHandlers/Unknown';
import TimeOut from 'modules/application/views/TimeOut/TimeOut';
import { initHeap } from 'patientOnboarding/analytics/heap';
import { initInvoca } from 'patientOnboarding/analytics/invoca';
import { initDatadog } from 'patientOnboarding/analytics/datadog';
import Routing from 'patientOnboarding/routes/Routing';
import {
  adminRoutes as absoluteAdminRoutes,
  bookingRoutes as absoluteBookingRoutes,
  intakeRoutes as absoluteIntakeRoutes,
} from 'patientOnboarding/routes/absolutes';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { Header as IntakeOptimizationHeader } from 'intakeOptimization/components';
import {
  ContactBubble,
  Footer,
  Header,
  IdleMonitor,
  ThemeSelector,
} from 'patientOnboarding/components';

import { updatePreferences as updatePreferencesAbie } from 'intakeOptimization/store/preferences/actions';
import { resetInsurance } from 'patientOnboarding/store/insurance/actions';
import { resetMatches } from 'patientOnboarding/store/matches/reducers';
import {
  resetPreferences,
  setLicenseKey,
  updatePreferences,
} from 'patientOnboarding/store/preferences/actions';
import { resetReservation } from 'patientOnboarding/store/reservationGlobal/actions';
import { resetUser } from 'patientOnboarding/store/userGlobal/actions';

import {
  contactInfo as contactInfoSelector,
  obieLicenseKey,
  obiePreferencesData,
  selectedClinician,
  selectedFacilityData,
} from 'patientOnboarding/selectors/selectors';

import { useQuery } from 'patientOnboarding/hooks';
import PreviousRouteProvider from 'patientOnboarding/hooks/usePreviousPath';

import { getContactInfoByState, getTimezoneOffset, useDevice } from 'patientOnboarding/utils';

import './App.scss';

const Root = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  const dispatch = useDispatch();
  const [showContactBubble, setShowContactBubble] = useState(true);
  const [isStickyMenu, setIsStickyMenu] = useState(false);
  const [hasSpecificDesign, setHasSpecificDesign] = useState(false);
  const [isErrorView, setErrorView] = useState(false);
  const [isTimeOutView, setTimeOutView] = useState(false);
  const errorHandler = useSelector((state) => state.errorHandler);
  const facility = useSelector(selectedFacilityData);
  const preferences = useSelector(obiePreferencesData);
  const contactInfo = useSelector(contactInfoSelector);
  const storedLicenseKey = useSelector(obieLicenseKey);
  const abieSpecificDesign = [
    absoluteAdminRoutes.patientType,
    absoluteAdminRoutes.searchResults,
    absoluteAdminRoutes.accountHolderExists,
  ];
  const stickyRoutes = [absoluteAdminRoutes.searchResults, absoluteBookingRoutes.providerMatches];

  let state;
  let licenseKey;
  const { location_back } = preferences;
  const isColdLanding = (location_back?.length === 0 && location.pathname.includes('provider'))
    || location_back?.includes('/find-care/booking/search');
  const clinician = useSelector(selectedClinician);

  if (isColdLanding) {
    state = clinician?.facility_location[0]?.state;
    licenseKey = clinician?.license_key;
  } else {
    state = facility?.state ? facility.state : preferences.state;
    licenseKey = facility?.license_key ? facility.license_key : preferences.licenseKey;
  }

  const contactInfoByState = getContactInfoByState(contactInfo, state, licenseKey);

  useEffect(() => {
    initHeap();
    initInvoca();
    initDatadog();
    dispatch(updatePreferences({ path_history: [...preferences.path_history, location.pathname] }));
    dispatch(
      updatePreferencesAbie({ path_history: [...preferences.path_history, location.pathname] }),
    );
  }, [location.pathname]);

  useEffect(() => {
    if (window.Invoca) {
      window.Invoca.PNAPI.run();
    }
    window.triggerError = () => {
      setErrorView(true);
      navigate('/unknown-error');
    };
  }, [window]);

  useEffect(() => {
    if (preferences.marketingReferralPhone.length > 0) {
      setShowContactBubble(true);
    } else if (!state || !licenseKey || licenseKey === -1 || !contactInfoByState) {
      setShowContactBubble(false);
    } else {
      setShowContactBubble(true);
    }
  }, [state, licenseKey, contactInfoByState]);

  useEffect(() => {
    if (
      (location
        && (location.pathname.includes('/admin') || location.pathname.includes('/error')))
      || !state
      || !licenseKey
      || licenseKey === -1
      || !contactInfoByState
    ) {
      setShowContactBubble(false);
    } else {
      setShowContactBubble(true);
    }
    if (location && location.pathname.includes('/timeout')) {
      setTimeOutView(true);
    } else {
      setTimeOutView(false);
    }
    if (abieSpecificDesign.includes(location.pathname)) {
      setHasSpecificDesign(true);
    } else {
      setHasSpecificDesign(false);
    }
    if (stickyRoutes.includes(location.pathname)) {
      setIsStickyMenu(true);
    } else {
      setIsStickyMenu(false);
    }
  }, [location, isTimeOutView]);

  useEffect(() => {
    if (errorHandler && !!errorHandler.status && errorHandler.status !== 500) {
      setErrorView(true);
      navigate('/error');
    } else if (
      errorHandler
      && errorHandler.status === 500
      && !query.get('patient_appointment_id')
    ) {
      setErrorView(true);
      navigate('/unknown-error');
    } else {
      setErrorView(false);
    }
  }, [errorHandler]);

  const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  useEffect(() => {
    const resetPaths = [
      '/',
      '/find-care/',
      '/find-care',
      '/find-care/booking/',
      '/find-care/booking',
      '/find-care/booking/match-criteria',
      '/find-care/booking/match-criteria/',
      '/find-care/booking/provider-matches',
      '/find-care/booking/provider-matches/',
      '/find-care/intake/cancellation',
    ];
    const clinicianPagePattern = /^\/find-care\/booking\/provider\/[^/]+$/;

    const matchesPath = resetPaths.includes(location.pathname) || clinicianPagePattern.test(location.pathname);

    if (matchesPath) {
      dispatch(resetPreferences());
      dispatch(resetUser());
      dispatch(resetReservation());
      dispatch(resetInsurance());
      dispatch(resetMatches());
    }

    dispatch(updatePreferences({ utc_offset: getTimezoneOffset() }));
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  useEffect(() => {
    if (storedLicenseKey !== -1) return;
    const licenseKey = isColdLanding
      ? clinician?.license_key
      : query.get('License_Key') || localStorage.getItem('licenseKey');
    if (licenseKey?.length) {
      localStorage.setItem('licenseKey', licenseKey);
      dispatch(setLicenseKey(parseInt(licenseKey, 10)));
    }
  }, [query, storedLicenseKey, licenseKey]);

  const noBottomViews = [
    absoluteIntakeRoutes.intake,
    absoluteIntakeRoutes.account,
    absoluteIntakeRoutes.demographics,
    absoluteIntakeRoutes.reason,
    absoluteIntakeRoutes.address,
    absoluteIntakeRoutes.emergencyContact,
    absoluteIntakeRoutes.paymentMethod,
    absoluteIntakeRoutes.insuranceCard,
    absoluteIntakeRoutes.insuranceDetails,
    absoluteIntakeRoutes.cancel,
    absoluteIntakeRoutes.cancelSuccess,
    absoluteIntakeRoutes.cancelKeep,
    absoluteIntakeRoutes.cancelExclusion,
    absoluteIntakeRoutes.cancelError,
    absoluteIntakeRoutes.alreadyCancelled,
    absoluteIntakeRoutes.appointmentError,
    absoluteIntakeRoutes.bookAppointment,
    absoluteIntakeRoutes.suicidalPrevention,
    absoluteIntakeRoutes.cancellationReasons,
    absoluteIntakeRoutes.appointmentOccuredPastFlag,
    absoluteIntakeRoutes.review,
    absoluteIntakeRoutes.confirmation,
  ];

  return (
    <>
      <ScrollRestoration
        getKey={(location) => {
          const paths = ['/find-care/booking/provider-matches', '/admin/results'];
          return paths.includes(location.pathname) ? location.pathname : location.key;
        }}
      />
      <div className={clsx('header', isStickyMenu && 'stickyMenu')}>
        {location.pathname.includes('/admin') ? (
          <IntakeOptimizationHeader displaySearch={!isErrorView} />
        ) : (
          !isTimeOutView && <Header displaySearch={!isErrorView} isColdLanding={isColdLanding} />
        )}
      </div>
      <div className={clsx('content', hasSpecificDesign && 'adminContent')}>
        <Outlet />
        {process.env.NODE_ENV !== 'development' ? <IdleMonitor /> : <></>}
      </div>
      {showContactBubble && (
        <div
          className="contactBubble"
          style={{
            bottom:
              noBottomViews.includes(location.pathname)
              || (location.pathname.includes('/provider/') && viewportWidth > 1024)
                ? 0
                : '100px',
          }}
        >
          <ContactBubble />
        </div>
      )}
      <div className={clsx(hasSpecificDesign && 'adminFooter', 'footer')}>
        <Footer />
      </div>
    </>
  );
};

export const msalInstance = new PublicClientApplication(msalConfig);

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PreviousRouteProvider>
        <Root />
      </PreviousRouteProvider>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/find-care/booking" />,
      },
      {
        path: '/find-care/*',
        element: <Routing />,
      },
      {
        path: '/admin/*',
        element: (
          <MsalProvider instance={msalInstance}>
            <Auth />
          </MsalProvider>
        ),
      },
      {
        path: '/timeout/',
        element: <TimeOut />,
      },
      {
        path: '/error/',
        element: <ErrorHandlerView />,
      },
      {
        path: '/unknown-error/',
        element: <UnknownErrorView />,
      },
      {
        path: '/telehealth-url-error/',
        element: <TelehealthURLError />,
      },
    ],
  },
]);

const App = () => (
  <div className="appContainer" id="appContainer">
    <ThemeSelector>
      <RouterProvider router={router} />
    </ThemeSelector>
  </div>
);

export default App;
