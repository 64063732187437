import clsx from 'clsx';
import { Insurances } from 'patientOnboarding/@types';
import { ReactComponent as Vector } from 'patientOnboarding/assets/svg/Vector.svg';
import { ReactComponent as ArrowDown } from 'patientOnboarding/assets/svg/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'patientOnboarding/assets/svg/arrow-up.svg';
import { ReactComponent as Insurance } from 'patientOnboarding/assets/svg/insurance_accepted.svg';
import { ReactComponent as Lightbulb } from 'patientOnboarding/assets/svg/lightbulb-line.svg';
import React, { useState } from 'react';

import { Alert } from 'lifestance-ui';

import { useInvoca } from 'patientOnboarding/hooks';

import { defaultPhoneNumber, formatContactNumber, removeDuplicates } from 'patientOnboarding/utils';

import styles from './Finances.module.scss';

interface FinancesProps {
  insurances: Insurances[];
  phoneNumber?: string;
  isAdmin?: boolean;
}

export const Finances: React.FC<FinancesProps> = ({ insurances, phoneNumber, isAdmin = false }) => {
  const [showAllOptions, setShowAllOptions] = useState(isAdmin);
  const hasInsurances = insurances.length > 0;
  const insurancesCleaned = removeDuplicates(insurances);
  useInvoca();

  if (hasInsurances) {
    return (
      <div className={styles.insuranceContainer}>
        <div className={styles.header}>
          <Insurance />
          <span className={styles.title}>Accepted Insurances</span>
        </div>
        <div className={styles.insuranceList}>
          {insurancesCleaned.map((insurance) => (
            <div
              className={clsx(styles.area, {
                [styles.expanded]: showAllOptions,
              })}
              key={insurance.id}
            >
              <Vector className={styles.vector} />
              <div>{insurance.name}</div>
            </div>
          ))}
        </div>
        {!isAdmin && !showAllOptions && insurancesCleaned.length > 4 && (
          <span
            role="button"
            tabIndex={0}
            onKeyDown={() => setShowAllOptions(true)}
            onClick={() => setShowAllOptions(true)}
            data-testId="expandFinances"
            className={styles.seeMore}
          >
            See more
            <span className={styles.arrowIcon}>
              <ArrowDown />
            </span>
          </span>
        )}
        {!isAdmin && showAllOptions && insurancesCleaned.length > 4 && (
          <span
            role="button"
            tabIndex={0}
            onKeyDown={() => setShowAllOptions(false)}
            onClick={() => setShowAllOptions(false)}
            data-testId="expandFinances"
            className={styles.seeMore}
          >
            See less
            <span className={styles.arrowIcon}>
              <ArrowUp />
            </span>
          </span>
        )}
      </div>
    );
  }
  return (
    <Alert type="info" className={styles.customAlert} wrap>
      <div className={styles.header}>
        <Lightbulb />
        <span className={styles.title}>Accepted Insurances</span>
      </div>
      <div className={styles.content}>
        Please give us a call to check if this provider accepts your insurance
        {' '}
        <a
          className={clsx(styles.phoneNumber, 'invocaPhoneNumber')}
          href={`tel:${formatContactNumber(phoneNumber) || ''}`}
        >
          {phoneNumber || defaultPhoneNumber}
        </a>
        .
      </div>
    </Alert>
  );
};
