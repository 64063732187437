import { getPersistConfig } from 'redux-deep-persist';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { rootReducer } from './reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'obie.preferences',
    'obie.reservationGlobal',
    'obie.userGlobal',
    'obie.insurance',
    'obie.matches',
  ],
  rootReducer,
};

const config = getPersistConfig(persistConfig);

export default persistReducer(config, rootReducer);
