import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Admin from 'intakeOptimization/routes/Admin';
import Login from 'intakeOptimization/views/Auth/Login';
import React from 'react';

const Auth = () => (
  <>
    <AuthenticatedTemplate>
      <Admin />
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Login />
    </UnauthenticatedTemplate>
  </>
);
export default Auth;
