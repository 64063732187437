import clsx from 'clsx';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';

import { ReactComponent as ArrowBlackIcon } from 'lifestance-ui/assets/svg/arrow-left-black.svg';
import { ReactComponent as CheckmarkIcon } from 'lifestance-ui/assets/svg/checkmark.svg';
import { ReactComponent as CloseIcon } from 'lifestance-ui/assets/svg/close.svg';
import { ReactComponent as NewIcon } from 'lifestance-ui/assets/svg/new.svg';
import { useDevice } from 'lifestance-ui/utils';

import { Button, Radio } from 'lifestance-ui';

import styles from './MobileDropdown.module.scss';

export const MobileDropdown = ({
  options,
  title,
  handleSelect,
  mobileClose,
  open,
  value,
  icon,
  sideWays,
  testId = 'MobileDropdown',
  variant = 'dropdown',
  fullHeight = false,
  avoidOpeniOsStyle = false,
}) => {
  const [selected, setSelected] = useState(value);
  const { isMobile } = useDevice();

  const fullWindowHeight = 667;
  const [windowDimensions, setWindowDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const isiOS = () => ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
    navigator.platform,
  )
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

  // TBD
  const isSafariAndiPhone = () => navigator.userAgent.includes('iPhone') && navigator.userAgent.includes('Safari');

  const handleSave = (e) => {
    e.preventDefault();
    handleSelect(selected);
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    // Avoid scroll when MobileDropdown opened
    open && isMobile
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'initial');
  }, [open, isMobile]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const rendercloseIcon = () => {
    switch (icon) {
      case 'CloseIcon':
        return <CloseIcon />;
      default:
        return <ArrowBlackIcon />;
    }
  };

  const handleMobileClose = () => {
    setSelected(value);
    mobileClose();
  };

  const handleClick = (optValue, isDisabled) => {
    if (!isDisabled) {
      setSelected(optValue);
    }
  };

  return (
    <div className={clsx({ [styles.scrim]: open })}>
      <div
        className={clsx(
          styles.wrapper,
          { [styles.open]: open },
          { [styles.sideWays]: sideWays },
          { [styles.fullHeight]: fullHeight },
          { [styles.openiOs]: open && isSafariAndiPhone() && !avoidOpeniOsStyle },
        )}
      >
        <div className={clsx(styles.top)}>
          <span
            data-testId={`mobileDropdown${testId}CloseButton`}
            className={styles.handleClose}
            onClick={handleMobileClose}
          >
            {rendercloseIcon()}
          </span>
          <span data-testId={`mobileDropdown${testId}Title`} className={styles.title}>
            {title}
          </span>
        </div>
        {variant === 'dropdown' && (
          <ul
            className={clsx(styles.options, {
              [styles.optionsDescription]: options[0]?.description,
            })}
          >
            {options
              && options.length > 0
              && options.map(({ label, value: optValue, description, isDisabled = false }, ind) => (
                <li
                  key={`${optValue}Mobile${ind + 1}`}
                  onClick={() => handleClick(optValue, isDisabled)}
                  data-testId={`mobileDropdownOption${testId}SetOption${ind}`}
                  className={clsx(styles.item, {
                    [styles.selection]: selected === optValue,
                    [styles.itemDescription]: description,
                    [styles.selectionDescription]: selected === optValue && description,
                    [styles.disabledItem]: isDisabled,
                  })}
                >
                  <div>
                    <p className={description ? styles.title : ''}>{label}</p>
                    {description ? (
                      <span className={styles.description}>{parse(description)}</span>
                    ) : null}
                  </div>
                  {selected === optValue && !description && <CheckmarkIcon />}
                </li>
              ))}
            {title === 'Type of care' && (
              <li data-testId={`dropdownOption${testId}SetOptionNotSure`}>
                <a
                  className={styles.unsure}
                  href="https://www.lifestance.com/services"
                  target="_blank"
                  rel="noreferrer"
                >
                  Not sure
                  {' '}
                  <span className={styles.new}>
                    <NewIcon />
                  </span>
                </a>
              </li>
            )}
          </ul>
        )}
        {variant === 'radio' && (
          <Radio
            defaultValue={selected}
            options={options}
            onChange={(radioValue) => setSelected(radioValue)}
            className={clsx(styles.options, styles.clean, {
              [styles.openiOs]: open && isSafariAndiPhone() && !avoidOpeniOsStyle,
            })}
          />
        )}
        <div
          className={clsx(styles.stickyButton, {
            [styles.stickyButtoniOS]: windowDimensions?.height >= fullWindowHeight && isiOS(),
          })}
        >
          <Button
            testId={`button${testId}SaveClose`}
            fullWidth
            disabled={selected === null}
            onClick={handleSave}
          >
            Save & close
          </Button>
        </div>
      </div>
    </div>
  );
};
