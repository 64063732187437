import clsx from 'clsx';
import React, { PropsWithChildren, RefObject, forwardRef } from 'react';

import { LoadingDots } from 'lifestance-ui/LoadingDots/LoadingDots';

import styles from './Button.module.scss';

interface ButtonProps {
  onClick: (e?: any) => void;
  fullWidth?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  size?: 'small' | 'medium' | 'narrow' | 'lg' | 'xl';
  disabled?: boolean;
  className?: string;
  testId?: string;
  isLoading?: boolean;
  isFocused?: boolean;
  tabIndex?: number;
  keyIntrinc?: string;
  shortcut?: string;
  id?: string;
}

export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (
    {
      children,
      onClick,
      fullWidth = false,
      secondary = false,
      tertiary = false,
      size = 'medium',
      disabled = false,
      className = '',
      testId = '',
      isLoading = false,
      isFocused = false,
      tabIndex,
      keyIntrinc,
      id = '',
    },
    ref,
  ) => (
    <button
      ref={ref as RefObject<HTMLButtonElement>}
      key={keyIntrinc}
      data-cy={testId}
      data-testId={`button${testId}`}
      type="button"
      id={id}
      className={clsx(
        styles.button,
        {
          [styles.fullWidth]: fullWidth,
          [styles.secondary]: secondary,
          [styles.tertiary]: tertiary,
          [styles.sm]: size === 'small',
          [styles.md]: size === 'medium',
          [styles.narrow]: size === 'narrow',
          [styles.loading]: isLoading,
        },
        className,
      )}
      autoFocus={isFocused}
      onClick={onClick}
      disabled={disabled}
      tabIndex={tabIndex}
    >
      {isLoading ? <LoadingDots secondary={secondary} /> : children}
    </button>
  ),
);
