import { useEffect, useState } from 'react';

export const useMediaQuery = (query: string) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setMatches(e.matches);
    mediaMatch.addEventListener('change', handler);
    return () => mediaMatch.removeEventListener('change', handler);
  });
  return matches;
};

export const useResizingMediaQuery = (query: string) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch?.matches);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => {
      setMatches(e.matches);
    };
    mediaMatch.addEventListener('change', handler);
    return () => mediaMatch.removeEventListener('change', handler);
  }, [window.innerWidth]);
  return matches;
};

export const isMobileQuery = '(max-width: 480px)';
export const isTabletQuery = '(min-width: 481px)';
export const isDesktopQuery = '(min-width: 1280px)';
