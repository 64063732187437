const types = {
  GET_MATCHES: '@@IO_MATCHES/GET_MATCHES',
  LOAD_MATCHES: '@@IO_MATCHES/LOAD_MATCHES',
  GET_MORE_MATCHES: '@@IO_MATCHES/GET_MORE_MATCHES',
  LOAD_MORE_MATCHES: '@@IO_MATCHES/LOAD_MORE_MATCHES',
  SET_LOADING: '@@IO_MATCHES/SET_LOADING',
  SET_ERROR: '@@IO_MATCHES/SET_ERROR',
  SET_SEARCH_TERM: '@@IO_MATCHES/SET_SEARCH_TERM',
  GET_CLINICIAN_PROFILE: '@@IO_MATCHES/GET_CLINICIAN_PROFILE',
  SET_CLINICIAN: '@@IO_MATCHES/SET_CLINICIAN',
  LOAD_CLINICIAN_PROFILE: '@@IO_MATCHES/LOAD_CLINICIAN_PROFILE',
  RESET_MATCHES: '@@IO_MATCHES/RESET_MATCHES',
  GET_CLINICIAN_DETAILS: '@@IO_MATCHES/GET_CLINICIAN_DETAILS',
  SET_CLINICIAN_DETAILS: '@@IO_MATCHES/SET_CLINICIAN_DETAILS',
  GET_CLINICIAN_AVAILABILITIES: '@@IO_MATCHES/GET_CLINICIAN_AVAILABILITIES',
  SET_CLINICIAN_AVAILABILITIES: '@@IO_MATCHES/SET_CLINICIAN_AVAILABILITIES',
  SET_CLINICIAN_AVAILABLE_DATES: '@@IO_MATCHES/SET_CLINICIAN_AVAILABLE_DATES',
  SET_STICKY_MENU: '@@IO_MATCHES/SET_STICKY_MENU',
  GET_OTHER_PROVIDERS: '@@IO_MATCHES/GET_OTHER_PROVIDERS',
};

export default types;
