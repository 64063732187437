import clsx from 'clsx';
import { ClinicianAvailability, FacilityLocation } from 'patientOnboarding/@types';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Dropdown, SpanButton, Toast } from 'lifestance-ui';

import { LocationDropdown } from 'patientOnboarding/components';
import { TypesOfCareMeta } from 'patientOnboarding/components/ClinicianProfile/typesOfCareMeta';

import { getClinicianProfile } from 'patientOnboarding/store/matches/actions';
import { updatePreferences } from 'patientOnboarding/store/preferences/actions';
import { updateReservation } from 'patientOnboarding/store/reservationGlobal/actions';

import {
  contactNumber,
  isAbie,
  isClinicianProfileLoading,
  obiePreferencesData,
  reservationData,
  selectedClinician,
} from 'patientOnboarding/selectors/selectors';

import { useInsuranceValidation } from 'patientOnboarding/hooks';

import {
  getLocalTime,
  getWeekDay,
  hasVideoAvailability,
  orderAges,
  sortAvailabilities,
  stateAbbrToName,
  useDevice,
} from 'patientOnboarding/utils';

import formatAndLocalDate from 'lifestance-ui/utils/formatAndLocalDate';
import ModalityIcon from '../AvailabilityBox/components/ModalityIcon';
import { NoAvailabilityBox } from '../NoAvailability/NoAvailability';
import { NoInsurance } from '../NoInsurance/NoInsurance';
import styles from './NewPatientBox.module.scss';

interface NewPatientBoxProps {
  handleOpen: (value: boolean) => void;
  onOpenCalendar: () => void;
  isFilterWall: boolean;
}

type AvailabilityToDisplay = {
  modality: 'VIDEO' | 'IN-OFFICE';
  slotStartTime: string;
  slotEndTime: string;
  date: string;
  availabilityStatus: boolean;
} & ClinicianAvailability;

type SelectedFacilities = {
  isVideoOnly: boolean;
  facilityIds: number[];
};

const NewPatientBox: FC<NewPatientBoxProps> = ({ handleOpen, onOpenCalendar, isFilterWall }) => {
  const profile = useSelector(selectedClinician);
  const phoneNumber = useSelector(contactNumber);
  const telehealth = profile?.virtual_visit === true && hasVideoAvailability(profile);
  const [displayAvailabilities, setDisplayAvaibilities] = useState(false);
  const [coldLandingStep, setColdLandingStep] = useState(1);
  const [clinicianAvailabilities, setClinicianAvailabilities] = useState(
    [] as AvailabilityToDisplay[],
  );
  const [isDisabledLocation, setIsDisabledLocation] = useState(true);
  const reservation = useSelector(reservationData);
  const [selectedFacilities, setSelectedFacilities] = useState<SelectedFacilities>({
    facilityIds: reservation?.facilityIds,
    isVideoOnly: reservation?.isVideoOnly as boolean,
  });
  const [isLoadingAvailabilities, setIsLoadingAvailabilities] = useState<boolean>(false);
  const [locationMount, setLocationMount] = useState<number>(1);
  const [paymentMethodsUnique, setPaymentMethodsUnique] = useState<
    { label: string; value: string }[]
  >([]);

  const orderedAges = orderAges(profile?.ages_accepted);
  const youngerAge = orderedAges.split('-')[0];

  const typeOfCareOptionsUnfiltered = profile?.type_of_cares?.map((typeOfCare: string) => ({
    value: typeOfCare,
    label: TypesOfCareMeta[typeOfCare]?.label,
    description: TypesOfCareMeta[typeOfCare]?.description,
  }));

  let typeOfCareOptions;

  if (youngerAge >= 18) {
    typeOfCareOptions = typeOfCareOptionsUnfiltered.filter(
      (e: any) => !e.value.toLowerCase().includes('child'),
    );
  } else {
    typeOfCareOptions = typeOfCareOptionsUnfiltered;
  }

  const handleOpenCalendar = () => {
    setDisplayAvaibilities(false);
    onOpenCalendar();
  };

  useEffect(() => {
    const paymentMethods = [
      ...profile.insurances.map((o: any) => ({ label: o.name, value: o.name })),
      { label: 'I don’t see my insurance', value: 'I don’t see my insurance' },
    ];

    const uniqueMethods = [
      ...(new Map(paymentMethods.map((item) => [item.value, item])).values() as any),
    ];

    setPaymentMethodsUnique(uniqueMethods);
  }, [profile.insurances]);

  const facilities = profile?.facility_location;
  const getPrimaryLocation: FacilityLocation = facilities?.find(
    (el: FacilityLocation) => el.primary_location,
  );

  const dispatch = useDispatch();
  const isAdmin = useSelector(isAbie);
  const preferences = useSelector(obiePreferencesData);
  const isLoadingProfile = useSelector(isClinicianProfileLoading);
  const navigate = useNavigate();
  const location = useLocation();
  const renderRef = useRef<boolean>(false);
  const { isTablet, isDesktop } = useDevice();

  const isColdLanding = (preferences?.location_back?.length === 0 && location.pathname.includes('provider'))
    || preferences?.location_back.includes('/find-care/booking/search')
    || preferences?.rematch_clinician_id !== 0;
  const [selectedTypeOfCare, setSelectedTypeOfCare] = useState(
    isColdLanding ? typeOfCareOptions?.[0]?.value : preferences.typeOfCare,
  );

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    isColdLanding ? '' : preferences.insuranceCompany || preferences.paymentMethod,
  );

  const insuranceValidationParams = useMemo(
    () => ({
      updatedPreferences: {
        paymentMethod: selectedPaymentMethod,
        insuranceCompany: selectedPaymentMethod,
      },
      clinicianState: getPrimaryLocation?.state,
      insuranceCompanies: paymentMethodsUnique,
    }),
    [selectedPaymentMethod, getPrimaryLocation?.state, paymentMethodsUnique],
  );

  const { insuranceOpts, isInsuranceValidOnState } = useInsuranceValidation(insuranceValidationParams);

  useEffect(() => {
    if (selectedPaymentMethod === 'self_pay') {
      dispatch(
        updatePreferences({
          paymentMethod: selectedPaymentMethod,
        }),
      );
    } else if (selectedPaymentMethod !== '') {
      dispatch(
        updatePreferences({
          insuranceCompany: selectedPaymentMethod,
          paymentMethod: 'insurance',
        }),
      );
    }
    setDisplayAvaibilities(false);
    setLocationMount((prevState) => prevState + 1);
    if (isColdLanding) {
      setSelectedFacilities({ facilityIds: [], isVideoOnly: false });
      dispatch(updateReservation({ facilityIds: [], isVideoOnly: false }));
    }
  }, [selectedPaymentMethod]);

  const handleTypeOfCare = (typeOfCare: string) => {
    setSelectedTypeOfCare(typeOfCare);
    const clinicianId = profile.id;
    setClinicianAvailabilities([]);
    dispatch(getClinicianProfile(clinicianId, typeOfCare));
  };

  useEffect(() => {
    if (!renderRef.current) {
      if (isColdLanding) {
        dispatch(getClinicianProfile(profile.id, selectedTypeOfCare));
      } else {
        setSelectedFacilities({
          facilityIds: [reservation.selectedFacility?.facility_id as number],
          isVideoOnly:
            (preferences?.modality as unknown) === 'video_visit'
            || (preferences?.modality[0] === 'video_visit' && preferences?.modality.length === 1),
        });
      }
      if (isTablet || isDesktop) {
        dispatch(updatePreferences({ typeOfCare: selectedTypeOfCare }));
      }
      renderRef.current = true;
    }
  }, []);

  const bothVisitTypes = (preferences?.modality?.includes('in_office')
      && preferences?.modality?.includes('video_visit'))
    || preferences?.modality?.length === 0;

  useEffect(() => {
    if (
      selectedPaymentMethod !== 'I don’t see my insurance'
      && selectedPaymentMethod?.length > 0
      && selectedFacilities?.facilityIds?.length > 0
    ) {
      if (displayAvailabilities && !isLoadingAvailabilities) {
        // If they are already displaying (so we don't trigger on paymentMethod change)
        showAvailabilities();
      }
    }
  }, [selectedFacilities]);

  useEffect(() => {
    if (profile && profile?.facility_location) {
      const availabilites: any = [];
      profile?.facility_location
        ?.filter((f: any) => selectedFacilities?.facilityIds?.includes(f?.facility_id))
        ?.forEach((o: any) => {
          o.clinician_availabilities.forEach((u: any) => {
            availabilites.push(u);
          });
        });

      const temp: any = [];
      const facilityClinicianAvailabilities = availabilites
        ? sortAvailabilities(availabilites)
        : [];
      const allAvailabilities: AvailabilityToDisplay[] = facilityClinicianAvailabilities.reduce(
        (acc: AvailabilityToDisplay[], current) => {
          if (current.reason.includes('TORO')) {
            if (reservation.isVideoOnly) {
              return acc.concat({
                ...current,
                modality: 'VIDEO',
                slotStartTime: current.appointment_start_time,
                slotEndTime: current.appointment_end_time,
                date: current.available_date,
                availabilityStatus: true,
              });
            }
            return acc.concat(
              {
                ...current,
                modality: 'VIDEO',
                slotStartTime: current.appointment_start_time,
                slotEndTime: current.appointment_end_time,
                date: current.available_date,
                availabilityStatus: true,
              },
              {
                ...current,
                modality: 'IN-OFFICE',
                slotStartTime: current.appointment_start_time,
                slotEndTime: current.appointment_end_time,
                date: current.available_date,
                availabilityStatus: true,
              },
            );
          }
          if (current.reason.includes('TELE')) {
            return acc.concat({
              ...current,
              modality: 'VIDEO',
              slotStartTime: current.appointment_start_time,
              slotEndTime: current.appointment_end_time,
              date: current.available_date,
              availabilityStatus: true,
            });
          }
          return acc.concat({
            ...current,
            modality: 'IN-OFFICE',
            slotStartTime: current.appointment_start_time,
            slotEndTime: current.appointment_end_time,
            date: current.available_date,
            availabilityStatus: true,
          });
        },
        [],
      );

      const availabilitiesToDisplay = allAvailabilities
        .map((o: any) => {
          if (reservation.isVideoOnly && o.virtual_or_video_visit === 1) {
            return {
              ...o,
              modality: 'VIDEO',
            };
          }
          if (reservation.isVideoOnly && o.virtual_or_video_visit === 0) {
            return {
              ...o,
              remove: true,
            };
          }

          return o;
        })
        .filter((f: any) => !f.remove)
        .slice(0, 3);
      setClinicianAvailabilities(availabilitiesToDisplay);
    }
  }, [profile, isLoadingProfile, selectedFacilities]);

  const getFacilityById = (facilityId: number, profileData: any) => profileData?.facility_location?.find((f: any) => f.facility_id === facilityId);

  const handleAppointment = (slot: any) => {
    dispatch(
      updateReservation({
        ...slot,
        selectedFacility: getFacilityById(slot?.facility_id, profile),
        availability: slot.availabilityStatus,
      }),
    );
    handleOpen(true);
  };

  const isTomorrow = (date: Date) => {
    const newDate = new Date();
    const tomorrow = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 1);
    return (
      tomorrow.getFullYear() === date.getFullYear()
      && tomorrow.getMonth() === date.getMonth()
      && tomorrow.getDate() === date.getDate()
    );
  };

  const getDate = (value: string) => {
    if (!value.length) return;
    const dateValue = new Date(value.replace(/-/g, '/'));
    if (isTomorrow(dateValue)) {
      return 'Tomorrow';
    }
    const options: any = {
      day: 'numeric',
      month: 'numeric',
    };
    return new Date(value.replace(/-/g, '/')).toLocaleString('en-US', options);
  };

  const renderAvailabilities = () => clinicianAvailabilities.length === 0 && !isLoadingProfile && telehealth ? (
    <div style={{ height: '20px' }} />
  ) : (
    <div
      className={clsx(styles.formInputs, {
        [styles.formInputLoading]: isLoadingProfile,
      })}
    >
      {clinicianAvailabilities.length > 0 && !isLoadingProfile && (
      <div className={styles.reasonLabel} style={{ marginBottom: '12px' }}>
        Choose a date and time
      </div>
      )}
      {isLoadingProfile && isFilterWall && (
      <div className={styles.reasonLabel} style={{ marginBottom: '12px' }}>
        Choose a date and time
      </div>
      )}
      {clinicianAvailabilities.map((slot) => (
        <div
          data-testId="clinicianAvailabilities"
          className={clsx(styles.appointment, {
            [styles.selected]:
                reservation.clinician_availability_key === slot.clinician_availability_key
                && reservation.modality === slot.modality,
            [styles.selectedAdmin]: isAdmin,
          })}
          onClick={() => handleAppointment(slot)}
        >
          <ModalityIcon modality={slot.modality} data-testId="mobilityIcon" />
          <span className={styles.wordWrap}>
            {formatAndLocalDate(slot.appointment_utc_start_datetime).dateSlotFormat}
              &nbsp;|&nbsp;
            {slot.modality === 'VIDEO'
              ? 'Video'
              : getFacilityById(slot.facility_id, profile)?.city}
          </span>
        </div>
      ))}
      {clinicianAvailabilities.length > 0 && !isLoadingProfile && (
      <p className={styles.link}>
        <span onClick={handleOpenCalendar} data-testId="viewAllAvailability">
          <SpanButton>View all availability</SpanButton>
        </span>
      </p>
      )}
      {isLoadingProfile && isFilterWall && (
      <p className={styles.link}>
        <span onClick={handleOpenCalendar} data-testId="viewAllAvailability">
          <SpanButton>View all availability</SpanButton>
        </span>
      </p>
      )}
    </div>
  );

  const isSeeAllEnabled = () => {
    if (isLoadingProfile) {
      return false;
    }
    if (isColdLanding) {
      if (coldLandingStep === 1) {
        return true;
      }
      if (
        selectedPaymentMethod.length > 0
        && (selectedFacilities?.facilityIds?.length > 0 || selectedFacilities?.isVideoOnly)
      ) {
        return true;
      }
      return false;
    }
    if (!isColdLanding) {
      if (
        selectedPaymentMethod.length > 0
        && (selectedFacilities?.facilityIds?.length > 0 || selectedFacilities?.isVideoOnly)
      ) {
        return true;
      }
      return false;
    }
  };

  useEffect(() => {
    if (!isColdLanding) {
      setDisplayAvaibilities(false);
      setSelectedTypeOfCare(preferences.typeOfCare);
    }
  }, []);

  useEffect(() => {
    if (preferences.typeOfCare !== '') {
      setSelectedTypeOfCare(preferences.typeOfCare);
    }
  }, [preferences.typeOfCare]);

  useEffect(() => {
    if (selectedTypeOfCare?.length > 0) {
      dispatch(updatePreferences({ typeOfCare: selectedTypeOfCare }));
      setDisplayAvaibilities(false);
    }
  }, [selectedTypeOfCare]);

  const showAvailabilities = () => {
    setIsLoadingAvailabilities(true);
    setDisplayAvaibilities(false);
    setTimeout(() => {
      setIsLoadingAvailabilities(false);
      setDisplayAvaibilities(true);
    }, 1500);
  };

  const handleSeeAvailability = () => {
    if (isColdLanding) {
      if (coldLandingStep === 1) {
        setColdLandingStep(2);
      } else {
        showAvailabilities();
      }
    } else if (isSeeAllEnabled()) {
      showAvailabilities();
    }
  };

  const handlePaymentMethod = (value: string) => {
    setSelectedPaymentMethod(value);
    setSelectedFacilities({ facilityIds: [], isVideoOnly: false });
    dispatch(updateReservation({ facilityIds: [], isVideoOnly: false }));
  };

  const isValidProfile = profile.facility_location.length > 0
    && profile.facility_location.find((f: any) => f.clinician_availabilities.length > 0)
    && profile.type_of_cares.length > 0;

  return (
    <div className={styles.newPatientContainer}>
      <div className={styles.box}>
        <div className={styles.titleBox}>
          <div className={styles.title} data-testId="existingPatients">
            New patient?
          </div>
          {!isColdLanding && isValidProfile ? (
            <div className={styles.description}>
              This will be an initial consultation for
              <span className={styles.reasonLabel}>
                {` ${selectedTypeOfCare} ${
                  selectedTypeOfCare.toLowerCase().includes('child') ? '(<17)' : ''
                }
                ${selectedTypeOfCare.toLowerCase().includes('adult') ? '(18+)' : ''} `}
              </span>
            </div>
          ) : null}
          {selectedTypeOfCare && isValidProfile ? (
            <div>
              {isColdLanding ? (
                <>
                  <div className={styles.reasonLabel}>What is the reason for your visit?</div>
                  <Dropdown
                    options={typeOfCareOptions}
                    placeholder="Select an option"
                    onChange={handleTypeOfCare}
                    title="Type of care"
                    value={selectedTypeOfCare}
                    sideWays
                    testId="typeOfCare"
                    disabled={!isColdLanding}
                    icon="CloseIcon"
                  />
                </>
              ) : null}
              {coldLandingStep >= 2 ? (
                <>
                  <div className={styles.reasonLabel}>How would you like to pay?</div>
                  <Dropdown
                    options={insuranceOpts}
                    placeholder="Select payment method"
                    onChange={handlePaymentMethod}
                    title="Payment method"
                    value={selectedPaymentMethod}
                    sideWays
                    testId="paymentMethod"
                    icon="CloseIcon"
                  />
                  <Toast
                    variant="information"
                    label={`Do you have ${preferences.insuranceCompany} Medicaid?`}
                    description={`We are not currently accepting Medicaid benefits in ${stateAbbrToName(
                      getPrimaryLocation?.state,
                    )}.`}
                    containerClassName={clsx(styles.toast, {
                      [styles.showToast]: !isInsuranceValidOnState,
                    })}
                  />
                </>
              ) : null}
              {selectedPaymentMethod !== 'I don’t see my insurance'
                && selectedPaymentMethod.length > 0 && (
                  <>
                    <div className={styles.reasonLabel}>Location of provider</div>
                    <LocationDropdown
                      placeholder="Select a location"
                      value="Locations...TBD"
                      testId="locations"
                      setIsDisabledLocation={setIsDisabledLocation}
                      setFacilities={setSelectedFacilities}
                      key={locationMount}
                    />
                  </>
              )}
              {selectedPaymentMethod.length > 0
                && selectedPaymentMethod === 'I don’t see my insurance' && (
                  <NoInsurance clinicianName={profile.first_name} phoneNumber={phoneNumber} />
              )}
              {displayAvailabilities && !isLoadingAvailabilities && renderAvailabilities()}
              {!displayAvailabilities && selectedPaymentMethod !== 'I don’t see my insurance' && (
                <Button
                  className={styles.button}
                  onClick={handleSeeAvailability}
                  size="medium"
                  fullWidth
                  isLoading={isLoadingProfile || isLoadingAvailabilities}
                  disabled={!isSeeAllEnabled()}
                >
                  See availability
                </Button>
              )}
            </div>
          ) : (
            <NoAvailabilityBox
              firstName={profile.first_name}
              typeOfCare={selectedTypeOfCare}
              emptyAppointment
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default NewPatientBox;
