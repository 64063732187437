import React from 'react';

import { Filter, MobileFilters } from 'patientOnboarding/components';

import { cleanAvailability } from 'patientOnboarding/utils';

import styles from './SearchFilters.module.scss';

interface ISearchFiltersProps {
  filters: any;
  onChange: (name: string, ...value: any[]) => void;
  defaultValues: any;
  onOpen(): void;
  onClose(): void;
  showMobileFilters: boolean;
  clinicianCount: number;
  isLoading?: boolean;
  onClear?: (name: string) => void;
}

export const SearchFilters = ({
  filters,
  onChange,
  defaultValues,
  onClose,
  onOpen,
  showMobileFilters = true,
  clinicianCount,
  isLoading = false,
  onClear,
}: ISearchFiltersProps) => {
  const forceNewData = (values: any) => {
    if (Array.isArray(values)) {
      return [...values];
    }
    return values?.toString();
  };

  const handleChange = (name: string, values: string[]) => {
    const newValue = [];
    newValue.push(forceNewData(values));
    onChange(name, ...newValue);
  };

  const buttonText = clinicianCount > 0 ? `See ${clinicianCount} results` : 'No exact matches';

  const mobileFilters = filters.slice(1, filters.length);

  return (
    <div key="SearchFilterContainer" className={styles.filters}>
      {filters?.map((filter: any) => {
        const values = filter?.name !== 'concerns'
          ? defaultValues[filter.name]
          : defaultValues?.concerns?.concat(
            defaultValues?.interventions,
            defaultValues?.populations,
          );
        return (
          <Filter
            key={filter.name}
            className={styles.filter}
            name={filter.name}
            defaultValues={values}
            label={filter.label}
            options={filter.options}
            onClose={onClose}
            testId={filter.testId}
            heightConstraint={filter.maxHeight}
            buttonText={buttonText}
            isLoading={isLoading}
            onChange={handleChange}
            onClear={onClear}
          />
        );
      })}
      {showMobileFilters && (
        <MobileFilters
          onChange={onChange}
          defaultValues={defaultValues}
          filters={mobileFilters}
          onClose={onClose}
          onOpen={onOpen}
          buttonText={buttonText}
        />
      )}
    </div>
  );
};
