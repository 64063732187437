import dayjs from 'dayjs';

export const isObjectEmpty = (obj: Record<string, unknown>): boolean => Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

const specialMonthsCases = [4, 6, 9, 11];

const ages = {
  adultAge: 18,
  childAge: 3,
  maxAge: 150,
};

const validateDates = (day: number, month: number, year: number, isChild = false) => {
  const currentMonth = new Date().getMonth() + 1;
  const currentDay = new Date().getDate();
  const monthDifference = currentMonth - month;
  const yearsToExtract = monthDifference < 0 || (monthDifference <= 0 && currentDay - day < 0) ? 1 : 0;
  const currentYears = new Date().getFullYear() - year - yearsToExtract;
  const isValidDay = day >= 1 && day <= 31;
  const isValidMonth = month >= 1 && month <= 12;
  const isValidYear = isChild
    ? currentYears >= ages.childAge && currentYears < ages.adultAge
    : currentYears >= ages.adultAge && currentYears < ages.maxAge;
  return isValidDay && isValidMonth && isValidYear;
};

export const validateBirthDay = (value: string, isChild = false) => {
  const birth = value?.replace(/[/_]/g, '');
  if (birth?.length === 10) {
    const month = parseInt(birth.slice(0, 2), 10);
    const day = parseInt(birth.slice(3, 5), 10);
    const year = parseInt(birth.slice(6, 10), 10);
    return validateDates(day, month, year, isChild);
  }

  if (birth?.length === 8) {
    const month = parseInt(birth.slice(0, 2), 10);
    const day = parseInt(birth.slice(2, 4), 10);
    const year = parseInt(birth.slice(4, 8), 10);
    return validateDates(day, month, year, isChild);
  }
};

export const validateEmail = (value: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

export const validatePhone = (value: string): boolean => {
  const phone = value.replace(/[-_]/g, '');
  return phone.length === 10;
};

export const validateName = (value: string): boolean => value.length !== 0;

export const getAge = (year: number, month: number, day: number): number => {
  const dateString = `${year}-${month}-${day}`;
  const today = new Date();

  const birthDay = new Date(dateString.replace(/-/g, '/'));
  let age = today.getFullYear() - birthDay.getFullYear();
  const monthDifference = today.getMonth() - birthDay.getMonth();
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDay.getDate())) {
    age -= 1;
  }
  return age;
};

export const validateDateFormat = (day: number, month: number, year: number): boolean => {
  const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  let isValidDay = false;
  if (isLeapYear && month === 2) {
    isValidDay = day >= 1 && day <= 29;
  } else if (month === 2) {
    isValidDay = day >= 1 && day <= 28;
  } else if (specialMonthsCases.includes(month)) {
    isValidDay = day >= 1 && day <= 30;
  } else {
    isValidDay = day >= 1 && day <= 31;
  }
  const isValidMonth = month >= 1 && month <= 12;
  const isValidYear = year <= new Date().getFullYear();
  return isValidDay && isValidMonth && isValidYear;
};

// Format MM/DD/YYYY
export const getDateInfo = (
  dateString: string,
): {
  month: number;
  day: number;
  year: number;
} => {
  const dateSplit = dateString?.split('/');
  const month = parseInt(dateSplit[0], 10);
  const day = parseInt(dateSplit[1], 10);
  const year = parseInt(dateSplit[2], 10);

  return {
    month,
    day,
    year,
  };
};

// Format MM/DD/YYYY
export const validateDate = (value: string): boolean => {
  if (!value) {
    return false;
  }
  const { month, day, year } = getDateInfo(value);
  const isValidDateFormat = validateDateFormat(day, month, year);
  const age = getAge(year, month, day);
  if (Number.isNaN(age)) {
    return false;
  }
  return dayjs(value).isValid() && isValidDateFormat && age >= ages.childAge && age <= ages.maxAge;
};

export const isDisabledTOC = (typeOfCare: string, age: number) => {
  const adult = typeOfCare.toLowerCase().match(/adult|couples|family/g);
  if (typeOfCare.toLowerCase().includes('child') && age >= 18) {
    return true;
  }
  if (!!adult && adult?.length > 0 && age < 18) {
    return true;
  }
  return false;
};

export const validateDateStringFormat = (dateString: string): boolean => {
  const regexTwoDigits = /\d\d/;
  const regexFourDigits = /\d\d\d\d/;
  const dateParts = dateString.split('/');
  const validMonth = regexTwoDigits.test(dateParts[0]);
  const validDay = regexTwoDigits.test(dateParts[1]);
  const validYear = regexFourDigits.test(dateParts[2]);
  if (validMonth && validDay && validYear) {
    const { month, day, year } = getDateInfo(dateString);
    return validateDateFormat(day, month, year);
  }
  return false;
};

// Always receives a MM/DD/YYYY format which includes 02/02/19__ we always removed the special characters
export const isDOBWithNoSpecialCharacters = (dateOfBirth: string) => {
  const birth = dateOfBirth.replace(/[/_]/g, '');
  return birth.length === 8;
};

export const simpleAgeAndFormatVerification = (
  dateOfBirth: string,
  setAgeError: (val: boolean) => void,
  setDOBError: (val: string) => void,
) => {
  if (!isDOBWithNoSpecialCharacters(dateOfBirth)) {
    setAgeError(true);
    setDOBError('Please enter a valid birth');
  } else {
    const { month, day, year } = getDateInfo(dateOfBirth);
    const age = getAge(year, month, day);
    if (!validateDateFormat(day, month, year) || age >= ages.maxAge) {
      setAgeError(true);
      setDOBError('Please enter a valid date of birth');
    } else if (age < ages.childAge) {
      setAgeError(true);
      setDOBError(
        'Our clinicians are trained to care for children 3 years old and up. Please call us if you have any questions.',
      );
    } else {
      setAgeError(false);
      setDOBError('');
    }
  }
};

export const ageVerification = (
  dateOfBirth: string,
  setAgeError: (val: boolean) => void,
  setDOBError: (val: string) => void,
  handlePatientAge: (val: number, dob: string) => void,
  handlePatientChildAge: (val: number, dob: string) => void,
  isAdultOption = true,
) => {
  if (!isDOBWithNoSpecialCharacters(dateOfBirth)) {
    setAgeError(true);
    setDOBError('Please enter a valid birth');
  } else {
    const { month, day, year } = getDateInfo(dateOfBirth);
    const age = getAge(year, month, day);
    if (!validateDateFormat(day, month, year) || age >= ages.maxAge) {
      setAgeError(true);
      setDOBError('Please enter a valid date of birth');
    } else if (isAdultOption) {
      if (age < ages.adultAge && age >= ages.maxAge) {
        setDOBError('For anyone under 18 years old, please select a child appointment type.');
        setAgeError(true);
      } else {
        setAgeError(false);
        setDOBError('');
        handlePatientAge(age, dateOfBirth);
      }
    } else if (age < ages.childAge) {
      setAgeError(true);
      setDOBError(
        'Our clinicians are trained to care for children 3 years old and up. Please call us if you have any questions.',
      );
    } else if (age >= ages.adultAge) {
      setAgeError(true);
      setDOBError('For anyone over 18 years old, please select an adult appointment type.');
    } else {
      handlePatientChildAge(age, dateOfBirth);
      setAgeError(false);
      setDOBError('');
    }
  }
};
