import { ReactComponent as CameraIcon } from 'intakeOptimization/assets/svg/camera.svg';
import { ReactComponent as CloudIcon } from 'intakeOptimization/assets/svg/cloud.svg';
import React, { FunctionComponent, useRef } from 'react';

import { SpanButton } from 'lifestance-ui';

import styles from './InsuranceDragCard.module.scss';

interface InsuranceDragCardProps {
  title: string;
  cardFile: File;
  srcPreview: string;
  setCardFile: (value: File) => void;
  setErrorCard: ({
    error,
    message,
    title,
    card,
  }: {
    error: boolean;
    message: React.ReactElement | string;
    title: string;
    card: 'front' | 'back';
  }) => void;
  validFormats: string[];
  maxSize: number;
  testId?: string;
  onCancelMobile?: () => void;
  onUploadIntent?: (callback: () => void) => void;
}

// checkDeviceIOS
const isMobileIos = Boolean(
  ['iphone', 'ipod'].find((device) => navigator.userAgent.toLowerCase().includes(device)),
);

// for newer versions of iPhone iOS show only image files
const acceptFiles = isMobileIos ? 'image/png,image/jpg,image/webp' : 'image/*';

export const InsuranceDragCard: FunctionComponent<InsuranceDragCardProps> = ({
  cardFile,
  srcPreview,
  setCardFile,
  setErrorCard,
  validFormats,
  maxSize,
  title,
  testId,
  onCancelMobile,
  onUploadIntent,
}) => {
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const cardInputRef = useRef<HTMLInputElement>(null);
  const cardInputMobileRef = useRef<HTMLInputElement>(null);
  const cardType = title.toLowerCase().includes('front') ? 'front' : 'back';
  const titles = {
    notSupported: 'File unsupported',
    unableToUpload: 'Unable to upload image',
  };
  const errorMessages = {
    fileSize:
      'Sorry about that! The file size is too large. Please reduce its size to 8MB and try again.',
    badReading:
      'Sorry about that! Your image was not able to upload. Please try again or try a different image.',
  };

  const handleErrorsValidFormat = (
    format: string,
    size: number,
    name: string,
    sizeRequired = true,
  ) => {
    const validFormat = validFormats.includes(format);
    const validSize = sizeRequired ? size <= maxSize : true;
    if (!validFormat) {
      setErrorCard({
        error: true,
        message: (
          <>
            Sorry about that!
            <span className={styles.fileName}>{` ${name} `}</span>
            is a type of file we are not able to support. Try uploading a .png, .jpg, or .bpm file
            type.
          </>
        ),
        title: titles.notSupported,
        card: cardType,
      });
    } else if (!validSize) {
      setErrorCard({
        error: true,
        message: errorMessages.fileSize,
        title: titles.notSupported,
        card: cardType,
      });
    } else {
      setErrorCard({ error: false, message: '', title: '', card: cardType });
    }
    return { validFormat, validSize };
  };

  const setFile = (ev: React.DragEvent<HTMLDivElement>) => {
    let file: File;
    if (ev.dataTransfer.items && ev.dataTransfer.items[0].kind === 'file') {
      file = ev.dataTransfer.items[0].getAsFile() as File;
    } else {
      file = ev.dataTransfer.files.item(0) as File;
    }
    const { validFormat, validSize } = handleErrorsValidFormat(file.type, file.size, file.name);
    if (validFormat && validSize) {
      setCardFile(file);
    }
  };

  const removeDragData = (ev: React.DragEvent<HTMLDivElement>) => {
    if (ev.dataTransfer.items) {
      ev.dataTransfer.items.clear();
    } else {
      ev.dataTransfer.clearData();
    }
  };

  const onDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    setFile(ev);
    removeDragData(ev);
    if (dropZoneRef.current) {
      dropZoneRef.current.classList.remove(styles.dragOver);
    }
  };

  const dragOverHandler = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    if (dropZoneRef.current) {
      dropZoneRef.current.classList.add(styles.dragOver);
    }
  };

  const dragLeaveHandler = () => {
    if (dropZoneRef.current) {
      dropZoneRef.current.classList.remove(styles.dragOver);
    }
  };

  const dragEnterHandler = () => {
    if (dropZoneRef.current) {
      dropZoneRef.current.classList.add(styles.dragOver);
    }
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (file) {
      const { validFormat, validSize } = handleErrorsValidFormat(file.type, file.size, file.name);
      if (validFormat && validSize) {
        setCardFile(file);
      }
    }
    if (cardInputRef.current) {
      cardInputRef.current.value = '';
    }
  };

  const handleImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const image = event.target.files?.item(0) as File;
    if (image) {
      const { validFormat, validSize } = handleErrorsValidFormat(
        image.type,
        image.size,
        image.name,
        false,
      );
      if (validFormat && validSize) {
        setCardFile(image);
      } else if (onCancelMobile) {
        onCancelMobile();
      }
    }
    if (cardInputRef.current) {
      cardInputRef.current.value = '';
    }
  };

  const handleMobileClick = async () => {
    onUploadIntent?.(() => {
      cardInputRef.current?.click();
    });
  };

  return (
    <>
      <div
        ref={dropZoneRef}
        className={styles.card}
        onDrop={onDrop}
        onDragEnter={dragEnterHandler}
        onDragOver={dragOverHandler}
        onDragLeave={dragLeaveHandler}
        data-testId={`fieldOf${testId}`}
      >
        <div className={styles.cloud} data-testId={`insuranceCloudIcon${testId}`}>
          <CloudIcon />
        </div>
        <div className={styles.content}>
          <div className={styles.cardTitle} data-testId={testId}>
            {title}
          </div>
          <div className={styles.instructions} data-testId="insuranceDragOrDrop">
            Drag & Drop or
            {' '}
            <span
              className={styles.link}
              onClick={() => {
                cardInputRef.current?.click();
              }}
              data-testId={`insuranceClickToUpload${testId}`}
            >
              <SpanButton>click to upload</SpanButton>
            </span>
            <input
              style={{ display: 'none' }}
              ref={cardInputRef}
              type="file"
              onChange={onFileChange}
              data-testId={`insuranceClickToUpload${testId}`}
            />
          </div>
        </div>
      </div>
      <div className={styles.cardContainerMobile} onClick={handleMobileClick}>
        <div className={styles.cardMobile}>
          <div className={styles.camera} data-testId={`insuranceCameraIcon${testId}`}>
            {cardFile?.name ? (
              <img
                id="image-helper"
                src={srcPreview}
                className={styles.miniature}
                alt="Front card"
              />
            ) : (
              <CameraIcon />
            )}
          </div>
          <input
            style={{ display: 'none' }}
            ref={cardInputMobileRef}
            accept={acceptFiles}
            type="file"
            onChange={handleImage}
            data-testId={`insuranceClickToUpload${testId}`}
          />
        </div>
        <div className={styles.cardTitleMobile}>{title}</div>
      </div>
    </>
  );
};
