import clsx from 'clsx';
import { ExpertiseListOptions } from 'intakeOptimization/@types';
import { ReactComponent as CaretDownIcon } from 'intakeOptimization/assets/svg/caret-down.svg';
import React, {
  FC,
  KeyboardEvent,
  MutableRefObject,
  createRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'lifestance-ui';

import { abiePreferencesData } from 'intakeOptimization/selectors/selectors';

import { capitalizeFirstLetter, genderFilters, onClickOut } from 'intakeOptimization/utils';
import { simpleKeyboardControl } from 'intakeOptimization/utils/keyboardAccessibility';
import { useLastEventClickOrTab } from 'intakeOptimization/utils/lastEventClickOrTab';

import { filterNames } from 'intakeOptimization/views/ClinicianSearchResults/filters';
import { TypeAheadDropdown } from '../TypeAheadDropdown/TypeAheadDropdown';
import styles from './Filter.module.scss';
import { OptionsClassName } from '../TypeAheadDropdown/types';

interface Option {
  label: string;
  value: string;
  testId?: string;
}

type SelectOptions = {
  label: string;
  value: string;
  isSelected: boolean;
  icon: number;
};

interface FilterProps {
  label: string;
  className: string;
  onChange: (name: string, selected: string[]) => void;
  options: Option[];
  name: string;
  defaultValues: string[] | string;
  testId: string;
  heightConstraint?: boolean;
  buttonText: string;
  isLoading?: boolean;
  onClose?: () => void;
  separatorStep?: number;
}

export const FilterABIE: FC<FilterProps> = ({
  label,
  className,
  onChange,
  options,
  name,
  defaultValues,
  testId,
  heightConstraint = false,
  buttonText,
  isLoading = false,
  onClose,
  separatorStep = 0,
}) => {
  const [open, setOpen] = useState<boolean | null>(null);
  const [selected, setSelected] = useState<string[]>([]);
  const triggerRef = useRef(null);
  const [expertisesOptions, setExpertisesOptions] = useState<ExpertiseListOptions[]>([]);
  const { focusOptions } = useSelector(abiePreferencesData);
  const { lastEventType } = useLastEventClickOrTab();
  const [tabbed, setTabbed] = useState(false);
  const optionRefs = useRef<MutableRefObject<HTMLDivElement>[]>([]);
  const filterNamesForTypeaheadDropdown = [filterNames.focus, filterNames.credentials];
  const filterTypeaheadProps = {
    [filterNames.focus]: {
      placeholder: 'Search filters...',
    },
    [filterNames.credentials]: {
      placeholder: 'Search credentials...',
      optionsClassName: {
        applyTo: [2],
        applyWhen: 'empty-query',
        className: styles.credentialsSeparator,
      } as OptionsClassName,
      className: styles.credentialsFilterContainer,
    },
  };

  const handleSaveAndClose = () => {
    onClose?.();
    setOpen(!open);
  };

  const handleClick = () => {
    const formattedValues = typeof defaultValues === 'string' && defaultValues !== '' ? [defaultValues] : defaultValues;
    if (formattedValues) setSelected(formattedValues);

    const isOpen = !open;
    setOpen(isOpen);
    if (!isOpen) {
      onClose?.();
    }
  };

  const handleClear = () => {
    setSelected([]);
    onChange(name, []);
  };

  const handleSelect = (value: string) => {
    if (selected?.includes(value)) {
      let updatedValues = [];
      if (name === 'gender') {
        updatedValues = selected.filter(
          (option) => option !== value && genderFilters[option] && !genderFilters[option].includes(value),
        );
      } else {
        updatedValues = selected.filter((option) => option !== value);
      }
      setSelected(updatedValues);
      onChange(name, updatedValues);
    } else {
      setSelected((previousSelected) => [...previousSelected, value]);
      onChange(name, [...selected, value]);
    }
  };

  useEffect(() => {
    if (defaultValues && name === 'gender') {
      const genderValues = [
        {
          gender: 'Male',
          values: genderFilters.Male,
        },
        {
          gender: 'Female',
          values: genderFilters.Female,
        },
        {
          gender: 'Non-binary',
          values: genderFilters['Non-binary'],
        },
      ];
      const selectedGender = defaultValues === 'Non-binary'
        ? [defaultValues]
        : genderValues
          ?.filter(({ gender }) => (defaultValues as string[])?.join(',').includes(gender))
          .map(({ gender }) => gender);
      setSelected(selectedGender);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (defaultValues && name === 'modality') {
      const selectedValues = defaultValues as string[];
      setSelected(selectedValues);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (defaultValues) {
      const values = typeof defaultValues === 'string' && defaultValues.includes(',')
        ? defaultValues.split(',')
        : defaultValues;
      setSelected(values as string[]);
    }
  }, []);

  useEffect(() => {
    if (open) {
      return onClickOut({
        triggerRef,
        action: () => handleSaveAndClose(),
        ignoreParent: true,
      });
    }
  }, [open, triggerRef]);

  const hasSelectedOption = () => {
    let hasSelected = false;
    if (Array.isArray(selected)) {
      if (selected.filter((f) => f !== undefined).length > 0) {
        hasSelected = true;
      } else {
        hasSelected = false;
      }
    } else {
      hasSelected = false;
    }
    return hasSelected;
  };

  const isChecked = (value: string) => selected?.includes(value);

  useEffect(() => {
    if (focusOptions && focusOptions.length > 0) {
      const expertisesList: ExpertiseListOptions[] = [];
      focusOptions.forEach((item: any, index: number) => {
        expertisesList.push({
          label: item.label,
          options: item.options?.map((el: SelectOptions, ind: number) => ({
            ...el,
            isSelected: false,
            icon: ind + 1,
          })),
        });
      });
      setExpertisesOptions(expertisesList);
    }
  }, [focusOptions]);

  const handleExpertiseSelect = (vals: string[]) => {
    const newValues: any = [];
    expertisesOptions.forEach((expertiseList) => {
      const selectedListOptions = expertiseList.options.filter((option) => vals.includes(option.value));
      const selected = selectedListOptions.map((option) => ({ ...option, isSelected: true }));
      newValues.push({ label: expertiseList.label, options: selected });
    });
    onChange('expertisesList', newValues);
  };

  const handleFocus = () => {
    setTabbed(lastEventType === 'tab');
  };

  const handleBlur = () => {
    setTabbed(false);
  };

  const navigateToNextElement = (refObj: MutableRefObject<HTMLDivElement>) => {
    const currentIndex = optionRefs.current.findIndex((el) => el.current === refObj.current);
    if (currentIndex < optionRefs.current.length - 1) {
      const nextIndex = optionRefs.current.findIndex((el) => el.current === refObj.current) + 1;
      optionRefs.current[nextIndex].current.focus();
    }
  };

  const navigateToPrevElement = (refObj: MutableRefObject<HTMLDivElement>) => {
    const currentIndex = optionRefs.current.findIndex((el) => el.current === refObj.current);
    if (currentIndex !== 0) {
      const nextIndex = optionRefs.current.findIndex((el) => el.current === refObj.current) - 1;
      optionRefs.current[nextIndex].current.focus();
    }
  };

  const handleNavigation = (evt: KeyboardEvent, refObj: MutableRefObject<HTMLDivElement>) => {
    const { key } = evt;
    switch (key) {
      case ' ':
        evt.preventDefault();
        handleSelect(refObj.current.dataset.value as string);
        break;
      case 'Escape':
      case 'Enter':
        evt.preventDefault();
        handleSaveAndClose();
        break;
      case 'ArrowDown':
        evt.preventDefault();
        navigateToNextElement(refObj);
        break;
      case 'ArrowUp':
        evt.preventDefault();
        navigateToPrevElement(refObj);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (open) {
      if (optionRefs?.current !== null && optionRefs?.current?.length !== options?.length) {
        optionRefs.current = Array(options?.length)
          .fill(options)
          .map((_, i) => optionRefs?.current[i] || createRef());
      }
    }
  }, [open, optionRefs.current]);

  return (
    // In render modality we are returning an array of react elements so we need a default empty container (fragment).
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <div className={clsx(className, styles.container, [name])} data-testId={`container${testId}`}>
        <div className={clsx(open && styles.extraLayer, !open && styles.noExtraLayer)} />
        <div
          data-cy={`filter-${name}`}
          data-testId={`caret${testId}`}
          className={clsx(styles.select, {
            [styles.selectOpen]: open,
            [styles.selectedOptions]: hasSelectedOption(),
            [styles.tabbed]: tabbed,
          })}
          tabIndex={0}
          role="button"
          onKeyDown={(e) => simpleKeyboardControl(e, handleClick, true)}
          onClick={handleClick}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <span className={styles.filterLabel}>{label}</span>
          {selected.length === 0 ? (
            <CaretDownIcon />
          ) : (
            <div className={styles.circle}>{selected.length}</div>
          )}
        </div>
        {open && (
          <div
            className={clsx(styles.filterContainer, filterTypeaheadProps[name]?.className || '')}
            ref={triggerRef}
          >
            <div
              className={clsx({
                [styles.maxHeight]: heightConstraint,
                [styles.filterOptionsContainer]: filterNamesForTypeaheadDropdown.includes(name),
              })}
              role="listbox"
            >
              {filterNamesForTypeaheadDropdown.includes(name) ? (
                <TypeAheadDropdown
                  testId={`Client ${capitalizeFirstLetter(name)}`}
                  optionsLists={name === filterNames.focus ? expertisesOptions : options}
                  onSelect={handleExpertiseSelect}
                  selected={selected}
                  placeholder={filterTypeaheadProps[name].placeholder}
                  filter
                  handleSelectExpertise={handleSelect}
                  lastEventType={lastEventType}
                  onSave={handleSaveAndClose}
                  disableOnClickOut
                  optionsClassName={filterTypeaheadProps[name].optionsClassName}
                />
              ) : (
                options
                && options.map((option, i) => (
                  <>
                    <div
                      ref={optionRefs.current[i]}
                      tabIndex={0}
                      className={styles.option}
                      key={`divContainer${option.value}`}
                      data-testId={option.testId || option.value}
                      onClick={() => handleSelect(option.value)}
                      onKeyDown={(event) => handleNavigation(event, optionRefs.current[i])}
                      role="option"
                      aria-selected
                      data-checked={isChecked(option.value)}
                      data-value={option.value}
                      autoFocus={i === 0}
                    >
                      <label className={styles.label} htmlFor={option.value}>
                        <div className={styles.checkbox} data-cy={`${name}-${option.value}`} />
                        <span>{option.label}</span>
                      </label>
                    </div>
                    {separatorStep === i + 1 ? <div className={styles.separator} /> : null}
                  </>
                ))
              )}
            </div>
            <div className={styles.footer}>
              <Button
                className={styles.clearButton}
                size="small"
                tertiary
                onClick={handleClear}
                testId="ClearButton"
              >
                Clear
              </Button>
              <Button
                size="small"
                onClick={handleSaveAndClose}
                testId="SaveButton"
                isLoading={isLoading}
                disabled={isLoading}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
