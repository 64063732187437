import { useEffect, useState } from 'react';

export const useLastEventClickOrTab = () => {
  const [lastEventType, setLastEventType] = useState<string>('click');

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      // Check if the tab key was the last pressed
      if (e.key === 'Tab') {
        setLastEventType('tab');
      }
    });

    document.addEventListener('mousedown', (e) => {
      // Check if the mouseclick was the last event
      setLastEventType('click');
    });
  }, []);

  return { lastEventType };
};
