import { createAction } from '@reduxjs/toolkit';
import { InsuranceListReq } from 'patientOnboarding/@types';

import { preferencesActions } from './reducers';
import t from './types';

const zipCodeFunction = (zipCode: string | number, setState: boolean | null = true) => ({
  payload: {
    zipCode,
    setState,
  },
});

export type ZipCodePayload = ReturnType<typeof zipCodeFunction>['payload'];

export const {
  updatePreferences,
  updateInsuranceList,
  resetFilters,
  setValidZipCode,
  setHasZipCode,
  setIsBookingModalOpenObie,
  setErrors,
  setExistingUser,
  setColdUser,
  setAccountHolder,
  setLicenseKey,
  setLoading,
  removeClinicianProfileBack,
  setMarketingReferral,
  setPreviousPreferences,
  setMarketingReferralListOptions,
  setCreditCardInfo,
  setSkippedCreditCardInfo,
  resetPreferences,
  setMarketingReferralPhone,
  setLoadingCountByZip,
  setCliniciansAvailableOnZipCode,
} = preferencesActions;
export const fetchValidZipCode = createAction<number | string>(t.FETCH_ZIP_CODE);
export const getInsuranceList = createAction<InsuranceListReq>(t.GET_INSURANCE_LIST);
export const getCliniciansValidate = createAction<string>(t.GET_CLINICIANS_VALID);
export const validateZipCode = createAction(t.VALIDATE_ZIPCODE, zipCodeFunction);
export const getCliniciansByZipCode = createAction(t.GET_CLINICIANS_COUNT, zipCodeFunction);
