/* eslint-disable camelcase */

/* eslint-disable no-plusplus */

/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';

import { ageRanges } from './ageRanges';

export const getTimezoneOffset = () => {
  const date = new Date();
  const timeOffSet = date.getTimezoneOffset();
  return timeOffSet.toString();
};

export const cleanNullProperties = (obj) => {
  for (const propName in obj) {
    if (
      obj[propName] === null
      || obj[propName] === ''
      || obj[propName] === undefined
      || obj[propName] === 'undefined'
      || obj[propName] === 'null'
      || obj[propName]?.length === 0
      || obj[propName][0] === undefined
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

/*
  valueToRemove is the value to remove if the valueCompare it's in the array
*/
export const cleanTimeOfDay = (arrayValues, valueToRemove, valueToCompare) => {
  let arrayToReturn = [];
  if (typeof arrayValues === 'string' || arrayValues instanceof String) {
    arrayValues = arrayValues.includes(',') ? arrayValues.split(',') : [arrayValues];
  }
  if (arrayValues.length) {
    const isRemovable = arrayValues.includes(valueToRemove) && arrayValues.includes(valueToCompare);
    if (isRemovable) {
      arrayToReturn = arrayValues.filter((item) => item !== valueToRemove);
    } else {
      arrayToReturn = arrayValues;
    }
  }
  return arrayToReturn;
};

export const cleanDaysAvailable = (arrayValues, valueToCompare) => {
  let arrayToReturn = [];
  const arrayAvailabilityCount = arrayValues.filter((i) => !/\d/.test(i)).length;
  if (arrayValues.length) {
    if (valueToCompare === 'next_three_Days') {
      arrayToReturn = arrayValues;
    }
    if (valueToCompare === 'next_week') {
      arrayToReturn = arrayAvailabilityCount > 1
        ? arrayValues.filter((item) => item !== 'next_three_Days')
        : arrayValues;
    }
    if (valueToCompare === 'next_two_Weeks') {
      if (arrayAvailabilityCount > 1) {
        const arrayToReturnFirst = arrayValues.filter((item) => item !== 'next_three_Days');
        arrayToReturn = arrayToReturnFirst.filter((item) => item !== 'next_week');
      } else {
        arrayToReturn = arrayValues;
      }
    }
    if (valueToCompare === 'next_month') {
      if (arrayAvailabilityCount > 1) {
        const arrayToReturnFirst = arrayValues.filter((item) => item !== 'next_three_Days');
        arrayToReturn = arrayToReturnFirst.filter((item) => item !== 'next_week');
        arrayToReturn = arrayToReturnFirst.filter((item) => item !== 'next_two_Weeks');
      } else {
        arrayToReturn = arrayValues;
      }
    }
  }
  return arrayToReturn;
};

export const cleanAvailability = (arr) => {
  const am = cleanTimeOfDay(arr, 'before_10_AM', 'before_12_PM');
  const pm = cleanTimeOfDay(am, 'after_3_PM', 'after_12_PM');
  const pm2 = cleanTimeOfDay(pm, 'after_5_PM', 'after_3_PM');
  const pm3 = cleanTimeOfDay(pm2, 'after_5_PM', 'after_12_PM');
  const next3Days = cleanDaysAvailable(pm3, 'next_three_Days');
  const nextWeek = cleanDaysAvailable(next3Days, 'next_week');
  const next2Weeks = cleanDaysAvailable(nextWeek, 'next_two_Weeks');
  const month = cleanDaysAvailable(next2Weeks, 'next_month');
  return month;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return;
  const lowerCaseString = string.toLowerCase();
  const replaced = lowerCaseString.replace(
    /\b[\w']+\b/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
  return replaced;
};

export const getFormatForMatchesQuery = (query) => ({
  concerns: decodeURIComponent(query.get('concerns')),
  expertises: decodeURIComponent(query.get('expertises')),
  populations: decodeURIComponent(query.get('populations')),
  interventions: decodeURIComponent(query.get('interventions')),
  availability_filter: decodeURIComponent(query.get('availability_filter')),
  gender: decodeURIComponent(query.get('gender')),
  modality: decodeURIComponent(query.get('modality')),
  languages: decodeURIComponent(query.get('languages')),
  location_names: decodeURIComponent(query.get('location_names')),
  zip_codes: decodeURIComponent(query.get('zip_codes')),
  payment_type: decodeURIComponent(query.get('payment_type')),
  insurances: decodeURIComponent(query.get('insurances')),
  type_of_cares: decodeURIComponent(query.get('type_of_cares')),
  special_cases: decodeURIComponent(query.get('special_cases')),
  age: decodeURIComponent(query.get('age')),
  credentials: decodeURIComponent(query.get('credentials')),
  utc_offset: decodeURIComponent(query.get('utc_offset')),
  sort_order: decodeURIComponent(query.get('sort_order')),
  distance: decodeURIComponent(query.get('distance')),
  entire_state: decodeURIComponent(query.get('entire_state')),
  preliminaryDOB: decodeURIComponent(query.get('preliminarydob')),
});

export const getFormatForMatches = (preferences) => ({
  languages: preferences.language ? preferences.language : null,
  type_of_cares: preferences.typeOfCare ? preferences.typeOfCare : null,
  zip_codes: preferences.zipCode ? preferences.zipCode : null,
  payment_type: preferences.paymentMethod ? preferences.paymentMethod : null,
  insurances: preferences.insuranceCompany ? preferences.insuranceCompany : null,
  concerns: preferences.concern?.length > 0 ? preferences.concern : preferences.concerns,
  expertises: preferences.expertise ? preferences.expertise : preferences.expertises,
  age:
    preferences.typeOfCare.toLowerCase().includes('child') && preferences.childAge
      ? preferences.childAge
      : preferences.preliminaryAge,
  credentials: preferences.credentials ? preferences.credentials : null,
  utc_offset: preferences.utc_offset ? preferences.utc_offset : getTimezoneOffset(), // This is a BE required param (can't be null)
  location_names: preferences.location_names ? preferences.location_names : null,
  availability_filter: preferences.availability_filter
    ? cleanAvailability(preferences.availability_filter)
    : null,
  gender: preferences.gender ? preferences.gender : null,
  modality: preferences.modality ? preferences.modality : null,
  special_cases: preferences.specialCases ? preferences.specialCases : null,
  sort_order: preferences.sortType ? preferences.sortType : null,
  interventions: preferences.interventions ? preferences.interventions : null,
  populations: preferences.populations ? preferences.populations : null,
  entire_state: preferences.entireState === 'true' ? preferences.entireState : null,
  distance: preferences.distance ? preferences.distance : null,
  license_keys: preferences.licenseKeys ? preferences.licenseKeys : null,
  preliminarydob: preferences.preliminaryDOB ? preferences.preliminaryDOB : null,
});

export const getFormatForClinicians = (preferences) => ({
  age:
    preferences.typeOfCare.toLowerCase().includes('child') && preferences.childAge
      ? preferences.childAge
      : preferences.preliminaryAge,
  availability_filter: preferences.availability_filter ? preferences.availability_filter : null,
  insurances: preferences.insuranceCompany ? preferences.insuranceCompany : null,
  modality: preferences.modality ? preferences.modality : null,
  payment_type: preferences.paymentMethod ? preferences.paymentMethod : null,
  special_cases: preferences.specialCases ? preferences.specialCases : null,
  type_of_cares: preferences.typeOfCare ? preferences.typeOfCare : null,
  utc_offset: preferences.utc_offset ? preferences.utc_offset : null,
  zip_codes: preferences.zipCode ? preferences.zipCode : null,
});

export const formatQueryToObject = (queryObject) => ({
  language: queryObject.languages !== 'null' ? [queryObject.languages] : [],
  typeOfCare: queryObject.type_of_cares !== 'null' ? queryObject.type_of_cares : '',
  zipCode: queryObject.zip_codes !== 'null' ? queryObject.zip_codes : '',
  paymentMethod: queryObject.payment_type !== 'null' ? queryObject.payment_type : '',
  insuranceCompany: queryObject.insurances !== 'null' ? queryObject.insurances : '',
  concerns:
    queryObject.concerns !== 'null'
      ? Array.isArray(queryObject.concerns)
        ? queryObject.concerns
        : [queryObject.concerns]
      : [],
  expertises:
    queryObject.expertises !== 'null'
      ? Array.isArray(queryObject.expertises)
        ? queryObject.expertises
        : [queryObject.expertises]
      : [],
  populations:
    queryObject.populations !== 'null'
      ? Array.isArray(queryObject.populations)
        ? queryObject.populations
        : [queryObject.populations]
      : [],
  interventions:
    queryObject.interventions !== 'null'
      ? Array.isArray(queryObject.interventions)
        ? queryObject.interventions
        : [queryObject.interventions]
      : [],
  age: queryObject.age !== 'null' ? queryObject.age : '',
  credentials: queryObject.credentials !== 'null' ? queryObject.credentials : [],
  utc_offset: queryObject.utc_offset !== 'null' ? queryObject.utc_offset : getTimezoneOffset(), // This is a BE required param (can't be null)
  location_names: queryObject.location_names !== 'null' ? queryObject.location_names : [],
  availability_filter:
    queryObject.availability_filter !== 'null' ? [queryObject.availability_filter] : [],
  gender: queryObject.gender !== 'null' ? queryObject.gender : [],
  modality:
    queryObject.modality !== 'null'
      ? Array.isArray(queryObject.modality)
        ? queryObject.modality
        : [queryObject.modality]
      : [],
  distance: queryObject.distance !== 'null' ? queryObject.distance : '',
  entireState: queryObject.entire_state !== 'null' ? queryObject.entire_state : '',
  preliminaryDOB: queryObject.preliminaryDOB !== 'null' ? queryObject.preliminaryDOB : '',
});

export const getMatchedProperties = (object, matchProperties) => {
  const objectKeys = Object.keys(object).sort();
  const matchesKeys = Object.keys(matchProperties).sort();
  const matchedKeys = objectKeys.filter((key) => matchesKeys.includes(key));
  const matchedProperties = matchedKeys.reduce((o, key) => ({ ...o, [key]: object[key] }), {});
  return matchedProperties;
};

export const formatDate = (value) => {
  if (!value || Number.isNaN(value)) return;
  const options = {
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  };
  const date = Date.parse(value);
  let longDate = new Intl.DateTimeFormat('en', options).format(date);
  const index = longDate.lastIndexOf(',');
  longDate = longDate.substring(0, index) + longDate.substring(index + 1);
  return longDate;
};

export const getTimezoneAbbreviation = () => {
  let date = new Date();
  date = date.toString();
  let timezone = date.match(/\(.+\)/g)[0];
  timezone = timezone.replace('(', '').replace(')', '');
  let abbreviation = '';
  timezone.split(' ').forEach((word) => {
    abbreviation += word.split('')[0];
  });
  let browser;
  const sUsrAg = navigator.userAgent;
  if (sUsrAg.indexOf('Safari') > 1) {
    browser = 'Apple Safari';
  }
  if (browser === 'Apple Safari') {
    return timezone;
  }
  return abbreviation;
};

export const getOrdinal = (number) => {
  const ordinalSuffix = ['th', 'st', 'nd', 'rd'];
  const value = number % 100;
  return number + (ordinalSuffix[(value - 20) % 10] || ordinalSuffix[value] || ordinalSuffix[0]);
};

export const formatDateWithoutTime = (value) => {
  if (!value) return;
  const options = {
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  };
  const date = new Date(value.replace ? value.replace(/-/g, '/') : value);
  const longDate = new Intl.DateTimeFormat('en', options).format(date);
  const values = longDate.split(' ');
  const dateFormatted = `${values[0]} ${values[1]} ${getOrdinal(values[2])}`;
  return dateFormatted;
};

export const formatSpeciality = (concern) => {
  const concernMatch = [];
  concern.forEach((val) => concernMatch.push(val.name));
  return concernMatch.length > 1 ? concernMatch.join(', ') : concernMatch[0];
};

export const formatDateForCalendar = (value) => {
  // 2021-07-15T14:30:00.036Z
  // 20210804T195839+05:00
  if (!value) {
    return '';
  }
  const isoString = dayjs(value).format('YYYYMMDDTHHmmss');
  return isoString;
};

export const camelToUnderscore = (string) => string.replace(/([A-Z])/g, '_$1').toLowerCase();

export const formatPayload = (payload) => {
  const formattedPayload = {};
  Object.keys(payload).forEach((key) => {
    formattedPayload[camelToUnderscore(key)] = payload[key];
  });
  return formattedPayload;
};

const convertTime12To24 = (time) => {
  let hours = Number(time.match(/^(\d+)/)[1]);
  const minutes = Number(time.match(/:(\d+)/)[1]);
  let sMinutes = minutes.toString();

  const AMPM = time.match(/\s(.*)$/)[1];
  if (AMPM === 'PM' && hours < 12) hours += 12;
  if (AMPM === 'AM' && hours === 12) hours -= 12;

  let sHours = hours.toString();

  if (hours < 10) sHours = `0${sHours}`;
  if (minutes < 10) sMinutes = `0${sMinutes}`;
  return `${sHours}:${sMinutes}`;
};

export const addMinutes = (date, minutes) => new Date(date.getTime() + minutes * 60000);

export const getLocalTime = (date, time) => {
  const formattedTime = convertTime12To24(time);
  const combineDateTime = new Date(`${date}T${formattedTime}Z`);
  const isoString = combineDateTime.toISOString();

  return getLocalTimeFromIso(isoString);
};

export const getLocalTimeFromIso = (isoString) => {
  const newDate = new Date(isoString);
  return newDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
};

export const getTimeZone = () => new Date().toLocaleDateString('en-us', { day: '2-digit', timeZoneName: 'short' }).substring(4);

export const formatDuration = (time) => {
  if (!time) return 0;
  const temporal = time.split(':');
  const hours = parseInt(temporal[0], 10) * 60;
  const minutes = parseInt(temporal[1], 10);
  const total = hours + minutes;
  return total;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const sizeType = Math.floor(Math.log(bytes) / Math.log(k));
  return `${(bytes / k ** sizeType).toFixed(dm)} ${sizes[sizeType]}`;
};

export const getLegacyQueryString = (preferences) => Object.keys(preferences).map((key) => {
  const arrayDefaultValues = [
    'clinician_types',
    'concerns',
    'credentials',
    'expertises',
    'facility_ids',
    'insurances',
    'interventions',
    'languages',
    'location_names',
    'populations',
    'gender',
    'special_cases',
    'zip_codes',
  ];
  let value = preferences[key];
  if (!Array.isArray(value) && key === 'availability_filter') value = [value];
  if (Array.isArray(value)) {
    const arrayValues = value.map((item) => `search[${key}][]=${item}`);
    return arrayValues.join('&');
  }

  let returnString;
  if (arrayDefaultValues.includes(key)) {
    returnString = `search[${key}][]=${JSON.stringify(value).replace(/"/g, '')}`;
  } else {
    returnString = `search[${key}]=${JSON.stringify(value).replace(/"/g, '')}`;
  }

  return returnString;
});

export const getQueryString = (preferences) => Object.keys(preferences)
  .filter((key) => key !== 'modality')
  .map((key) => {
    const arrayDefaultValues = [
      'clinician_types',
      'concerns',
      'credentials',
      'expertises',
      'facility_ids',
      'insurances',
      'interventions',
      'languages',
      'location_names',
      'populations',
      'gender',
      'special_cases',
      'zip_codes',
    ];
    let value = preferences[key];
    if (!Array.isArray(value) && key === 'availability_filter') value = [value];
    if (Array.isArray(value)) {
      const arrayValues = value.map((item) => `${key}[]=${item}`);
      return arrayValues.join('&');
    }

    const returnString = `${key}${arrayDefaultValues.includes(key) ? '[]' : ''}=${JSON.stringify(
      value,
    ).replace(/"/g, '')}`;

    return returnString;
  });

export const formatContactNumber = (contactNumber) => contactNumber?.replaceAll(/[^0-9]/g, '');

export const delay = (ms) => new Promise((resolve) => {
  setTimeout(() => resolve(true), ms);
});

export const formattedContactInfo = (contactInfo) => ({
  location: contactInfo.location,
  intake_call_in_number: contactInfo.support_number,
  support_hours: contactInfo.support_hours,
  established_patients_call_in_number: contactInfo.established_patients_call_in_number,
  follow_up_url: contactInfo.follow_up_url,
});

export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

export const distanceInMilesLabel = (distance_in_miles, measurementType = 'miles away ') => {
  if (distance_in_miles) {
    return `${
      parseFloat(distance_in_miles?.toFixed(1)) < 0.1
        ? (distance_in_miles + 0.1).toFixed(1)
        : distance_in_miles?.toFixed(1)
    } ${measurementType}`;
  }
};

export const cleanProviderRoute = (route) => route.substring(0, route.indexOf(':'));

export const getAgeRange = (age) => ageRanges.find((elem) => age >= elem.downLimit && age <= elem.upLimit).ageRange;

export const formatNameOfDay = (nameOfDay) => nameOfDay.substring(0, 2);

export const formatAgesAccepted = (agesAccepted) => {
  let agesString = '';
  if (agesAccepted.length > 0) {
    const agesArray = agesAccepted?.replaceAll(',', '').replaceAll('–', '-').replaceAll('+', '').split(' ') || [];
    const ages = [];

    agesArray.forEach((o) => {
      ages.push(parseInt(o.split('-')[0], 10));
      ages.push(parseInt(o.split('-')[1], 10));
    });

    const agePairs = ages.reduce((result, value, index, array) => {
      if (index % 2 === 0) result.push(array.slice(index, index + 2));
      return result;
    }, []);

    let supportsOver65 = false;

    if (Number.isNaN(agePairs[agePairs.length - 1][1])) {
      supportsOver65 = true;
      agePairs.pop();
    }

    agePairs.forEach((o, i) => {
      let nextNumber = 0;
      let prevNumber = 0;
      if (i !== agePairs.length - 1) {
        nextNumber = agePairs[i + 1][0];
      }

      if (i !== 0) {
        prevNumber = agePairs[i - 1][1];
      }

      if (i === 0) {
        agesString += o[0];
      }

      if (o[0] - 1 !== prevNumber && i !== 0) {
        agesString += `${o[0]}`;
      }

      if (o[1] + 1 !== nextNumber) {
        if (i !== agePairs.length - 1) {
          agesString += `-${o[1]}, `;
        } else {
          agesString += `-${o[1]}`;
        }
      }

      if (i === agePairs.length - 1 && supportsOver65) {
        agesString += ', 65+';
      }
    });

    if (agesString.substring(agesString.length - 8) === '-64, 65+') {
      agesString = agesString?.replaceAll('-64, 65+', '-65+');
    }
  }
  return agesString;
};

export const orderAges = (agesAccepted) => {
  // '0–5, 6-10, 11–14, 15-17, 18-21, 22-26, 27-40, 41-64, 65+'
  // '65+, 41-64, 27-40, 22-26, 0–5, 6-10, 11–14, 15-17, 18-21'
  // '41-64, 27-40, 22-26, 0–5, 6-10, 11–14, 15-17, 18-21'
  // ''
  // '22-26, 0–5'
  const ages = agesAccepted.replaceAll(/–|\+/gi, '-').replaceAll(' ', '').split(',');
  const agesHierarchy = ages.map((el) => Number.parseInt(el.substring(0, el.indexOf('-')), 10));
  agesHierarchy.sort((a, b) => a - b);
  const orderedAges = agesHierarchy.map((orderedNumber) => ages.find((el) => Number.parseInt(el.substring(0, el.indexOf('-')), 10) === orderedNumber));
  if (orderedAges.at(orderedAges.length - 1)?.match(/\d\d-$/)?.length) {
    orderedAges.pop();
    orderedAges.push('65+');
  }
  const agesAcceptedString = orderedAges.join(', ');
  return agesAcceptedString;
};
