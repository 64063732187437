import React, { FC, FunctionComponent, JSXElementConstructor, ReactElement } from 'react';
import clsx from 'clsx';
import styles from './Pillbox.module.scss';

interface PillboxProps {
  iconElement?: JSX.Element;
  mode: 'main' | 'secondary' | 'beige';
  className?: string;
  iconContainerClass?: string;
  testId?: string;
}

export const Pillbox: FC<React.PropsWithChildren<PillboxProps>> = ({
  iconElement,
  mode,
  children,
  className,
  iconContainerClass = '',
  testId = 'pillbox',
}) => (
  <div
    data-cy="Pillbox"
    data-testId={`pillbox${testId}Container`}
    className={clsx([
      styles.pillbox,
      className,
      {
        [styles.main]: mode === 'main',
        [styles.secondary]: mode === 'secondary',
        [styles.beige]: mode === 'beige',
      },
    ])}
    >
    {iconElement ? (
      <div data-cy="IconContainer" className={clsx([styles.iconContainer, iconContainerClass])}>
        {iconElement}
      </div>
    ) : null}
    <span data-cy="PillboxText" className={styles.pillboxText} data-testId={`pillbox${testId}Text`}>{children}</span>
  </div>
);
