import { ReactComponent as Lightbulb } from 'patientOnboarding/assets/svg/lightbulb-line.svg';
import { FC } from 'react';

import { ModalToast } from 'lifestance-ui';

import { useResizingMediaQuery } from 'patientOnboarding/hooks';

import { stateAbbrToName } from 'patientOnboarding/utils';

import styles from './NoServiceStateModal.module.scss';

interface INoState {
  onClose: () => void;
  state: string;
}

const NoServiceStateModal: FC<INoState> = ({ onClose, state }) => {
  const isMobile = useResizingMediaQuery('(max-width: 768px)');

  return (
    <ModalToast onClose={onClose}>
      <div className={styles.icon}>
        <Lightbulb />
      </div>
      <h1 className={styles.title}>We are not in your area yet</h1>
      <span className={styles.description}>
        There are currently no LifeStance providers in&nbsp;
        {stateAbbrToName(state)}
        . Insurance requires that you see a provider who is licensed in your
        state of residence.
      </span>
    </ModalToast>
  );
};

export default NoServiceStateModal;
