import { intakeRoutes } from 'patientOnboarding/routes/constants';

export const INTAKE_STEPS = [
  {
    number: 1,
    title: 'Patient information',
    step: 'pending',
    link: intakeRoutes.intake,
    addsToProgressBar: true,
    subSteps: [
      {
        number: 1,
        link: intakeRoutes.intake,
      },
      {
        number: 2,
        link: intakeRoutes.register,
      },
      {
        number: 3,
        link: intakeRoutes.account,
      },
      {
        number: 4,
        link: intakeRoutes.demographics,
      },
      {
        number: 5,
        link: intakeRoutes.reason,
      },
      {
        number: 6,
        link: intakeRoutes.address,
      },
      {
        number: 7,
        link: intakeRoutes.emergencyContact,
      },
    ],
  },
  {
    number: 2,
    title: 'Insurance & Billing',
    step: 'pending',
    link: intakeRoutes.insuranceDetails,
    addsToProgressBar: true,
  },
  {
    number: 3,
    title: 'Booking',
    step: 'pending',
    link: intakeRoutes.review,
    subSteps: [
      {
        number: 1,
        link: intakeRoutes.review,
      },
      {
        number: 2,
        link: intakeRoutes.confirmation,
      },
    ],
  },
];

export const INTAKE_STEPS_NO_CC_NO_INSURANCE = [
  {
    number: 1,
    title: 'Patient information',
    step: 'pending',
    link: intakeRoutes.intake,
    addsToProgressBar: true,
    subSteps: [
      {
        number: 1,
        link: intakeRoutes.intake,
      },
      {
        number: 2,
        link: intakeRoutes.register,
      },
      {
        number: 3,
        link: intakeRoutes.account,
      },
      {
        number: 4,
        link: intakeRoutes.demographics,
      },
      {
        number: 5,
        link: intakeRoutes.reason,
      },
      {
        number: 6,
        link: intakeRoutes.address,
      },
      {
        number: 7,
        link: intakeRoutes.emergencyContact,
      },
    ],
  },
  {
    number: 2,
    title: 'Booking',
    step: 'pending',
    link: intakeRoutes.review,
    subSteps: [
      {
        number: 1,
        link: intakeRoutes.review,
      },
      {
        number: 2,
        link: intakeRoutes.confirmation,
      },
    ],
  },
];

export const NO_CCOF_NO_INSURANCE_SUBSTEPS = [
  {
    number: 1,
    link: intakeRoutes.review,
  },
  {
    number: 2,
    link: intakeRoutes.confirmation,
  },
];

export const INSURANCE_BILLING_SUBSTEPS = [
  {
    number: 1,
    link: intakeRoutes.insuranceDetails,
  },
  {
    number: 2,
    link: intakeRoutes.insuranceCard,
  },
];

export const BILLING_SUBSTEPS = [
  {
    number: 1,
    link: intakeRoutes.paymentMethod,
  },
];

export const INSURANCE_BILLING_CCOF_SUBSTEPS = [
  ...INSURANCE_BILLING_SUBSTEPS,
  {
    number: 3,
    link: intakeRoutes.paymentMethod,
  },
];
