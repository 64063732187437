import clsx from 'clsx';
import { ReactComponent as Illustration } from 'patientOnboarding/assets/svg/illustration.svg';
import { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CircularLoading, Container } from 'lifestance-ui';

import { getAppointmentDetails } from 'patientOnboarding/store/reservationGlobal/actions';

import {
  isLoadingReservationSelector,
  obiePatientAppointmentData,
} from 'patientOnboarding/selectors/selectors';

import { useInvoca, useQuery } from 'patientOnboarding/hooks';

import { defaultPhoneNumber, formatContactNumber, useDevice } from 'patientOnboarding/utils';

import styles from './ErrorHandlers.module.scss';

const TelehealthURLError = () => {
  const dispatch = useDispatch();
  const appointment = useSelector(obiePatientAppointmentData);
  const isLoading = useSelector(isLoadingReservationSelector);
  const query = useQuery();
  const { isMobile } = useDevice();
  const [contactNumber, setContactNumber] = useState('');
  const [loadInvoca, setLoadInvoca] = useState(false);

  /**
   * Due to phone number being inside a Suspense element loading and fetching for the invoca script at the same time
   * we are using a fake timer to avoid staling a previous render that will be placed on top after Invoca has been
   * already triggered.
   */
  useEffect(() => {
    const fakeLoad = setTimeout(() => {
      if (!loadInvoca) {
        setLoadInvoca(true);
        clearTimeout(fakeLoad);
      }
    }, 300);
    return () => clearTimeout(fakeLoad);
  });

  useEffect(() => {
    if (!query.get('phone')) {
      dispatch(getAppointmentDetails(query.get('patient_appointment_id') as string));
    }
  }, []);

  useEffect(() => {
    const queryPhoneNumber = query.get('phone');
    // We prioritize the marketing referral phone number
    setContactNumber(
      queryPhoneNumber
        || appointment?.marketing_referral_phone
        || appointment?.support_info?.support_number,
    );
  }, [appointment?.marketing_referral_phone, appointment?.support_info?.support_number]);

  useInvoca(loadInvoca);

  return (
    <Suspense fallback={<CircularLoading size="xlarge" />}>
      <Container className={styles.container}>
        <div className={styles.rounded}>
          <Illustration style={{ margin: '0px auto' }} />
        </div>
        <h2>Sorry about that</h2>
        <div className={styles.textContainer}>
          <p className={styles.padded}>
            An error has occurred and we’re working to fix the problem. We’ll be up and running
            again shortly.
          </p>
          <p
            className={clsx({
              [styles.showPhone]: !isLoading,
              [styles.hidePhone]: isLoading,
            })}
          >
            Please call us at
            {!isMobile ? ' ' : null}
            <a
              className={clsx(styles.phone, 'invocaPhoneNumber', {
                [styles.newLine]: isMobile,
              })}
              href={`tel:${formatContactNumber(contactNumber) || ''}`}
            >
              {contactNumber || defaultPhoneNumber}
            </a>
            .
          </p>
        </div>
      </Container>
    </Suspense>
  );
};

export default TelehealthURLError;
