import { getEnvironment } from 'patientOnboarding/utils';

import { HEAP_APP_ID_DEV, HEAP_APP_ID_PROD, HEAP_APP_ID_QA, HEAP_APP_ID_UAT } from './analytics';

const { host } = window.location;
const env = getEnvironment(host);
const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

// for environments [env]-book.lifestance.com
const heapAppId: { [key: string]: string } = {
  dev: HEAP_APP_ID_DEV,
  qa: HEAP_APP_ID_QA,
  uat: HEAP_APP_ID_UAT,
  book: HEAP_APP_ID_PROD, // production: no prefix
};

const HEAP_SCRIPT_ID = 'react-heap';

const getHeapScript = (id: string): string => `
  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("${id}");
`;

export const initHeap = () => {
  if (!document) {
    return;
  }

  const hasScript = !!document.getElementById(HEAP_SCRIPT_ID);
  if (hasScript) return;

  const script = document.createElement('script');

  script.innerHTML = getHeapScript(isDev ? heapAppId.dev : heapAppId[env]);
  script.id = HEAP_SCRIPT_ID;
  script.async = true;

  document.body.appendChild(script);
};
