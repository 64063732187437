import clsx from 'clsx';
import { FC } from 'react';

import { ReactComponent as WarningIcon } from 'lifestance-ui/assets/svg/alert-gold.svg';
import { ReactComponent as SuccesIcon } from 'lifestance-ui/assets/svg/checkbox-circle-line.svg';
import { ReactComponent as CriticalIcon } from 'lifestance-ui/assets/svg/close-circle-line.svg';
import { ReactComponent as OtherIcon } from 'lifestance-ui/assets/svg/error-warning-line.svg';
import { ReactComponent as InformationIcon } from 'lifestance-ui/assets/svg/lightbulb-line.svg';
import { useInvoca } from 'lifestance-ui/hooks';
import { formatContactNumber } from 'lifestance-ui/utils';

import styles from './Toast.module.scss';

interface IToastProps {
  description?: string;
  label?: string;
  url?: string;
  urlLabel?: string;
  phoneNumber?: string;
  showIcon?: boolean;
  variant: 'information' | 'warning' | 'critical' | 'success' | 'other';
  containerClassName?: string;
}
export const Toast: FC<IToastProps> = ({
  variant,
  phoneNumber = '',
  url = '',
  urlLabel = '',
  showIcon = true,
  label = '',
  description = '',
  containerClassName = '',
}) => {
  const getIcon = (variantType: 'information' | 'warning' | 'critical' | 'success' | 'other') => {
    switch (variantType) {
      case 'information':
        return <InformationIcon />;
      case 'warning':
        return <WarningIcon />;
      case 'critical':
        return <CriticalIcon />;
      case 'success':
        return <SuccesIcon />;
      default:
        return <OtherIcon />;
    }
  };

  useInvoca();

  return (
    <div
      className={clsx(styles.container, containerClassName, {
        [styles.information]: variant === 'information',
        [styles.warning]: variant === 'warning',
        [styles.critical]: variant === 'critical',
        [styles.success]: variant === 'success',
      })}
    >
      {showIcon && <div className={styles.icon}>{getIcon(variant)}</div>}
      <div className={styles.content}>
        <span className={styles.label}>{label}</span>
        <span className={styles.description}>
          {description}
          {' '}
          {variant === 'information' && phoneNumber.length > 0 && (
            <a
              className={clsx(styles.phone, 'invocaPhoneNumber')}
              href={`tel:${formatContactNumber(phoneNumber)}`}
            >
              {phoneNumber}
            </a>
          )}
        </span>
        {url.length > 0 && (
          <a className={styles.link} href={url}>
            {urlLabel}
          </a>
        )}
      </div>
    </div>
  );
};
