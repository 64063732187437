import clsx from 'clsx';
import { FC } from 'react';

import { Button } from 'lifestance-ui';

import styles from './Rectangle.module.scss';

interface IRectangleProps {
  name: string;
  index: string;
  label: string;
  error?: string;
  isActive: boolean;
  disabled?: boolean;
  className: string;
  btnIndex?: number;
  count?: number;
  defaultFocus?: boolean;
  handleClick: (name: string, value: boolean) => void;
  keyIntrinc?: string;
  testId?: string;
}

export const Rectangle: FC<React.PropsWithChildren<IRectangleProps>> = ({
  keyIntrinc,
  index,
  label,
  error,
  disabled,
  isActive,
  name,
  handleClick,
  defaultFocus,
  btnIndex,
  count,
  className,
  testId,
}) => {
  const onClickHandler = () => {
    handleClick(name, !isActive);
  };

  return (
    <Button
      testId={`${testId}${index}`}
      keyIntrinc={keyIntrinc}
      onClick={onClickHandler}
      disabled={disabled}
      className={clsx(
        className,
        styles.rectangleBtn,
        { [styles.rectangleBtnFirst]: btnIndex === 1 },
        { [styles.rectangleBtnLast]: btnIndex === count },
        { [styles.active]: isActive && !disabled },
        { [styles.error]: error },
      )}
      isFocused={defaultFocus}
    >
      <div className={styles.dRowFlex}>
        {index && (
          <div className={clsx({ [styles.icon]: index }, { [styles.iconDisabled]: disabled })}>
            {index}
          </div>
        )}
        <div className={styles.label}>{label}</div>
      </div>
    </Button>
  );
};
