import { FC, PropsWithChildren } from 'react';

import { ReactComponent as CloseIcon } from 'lifestance-ui/assets/svg/close-fill.svg';

import styles from './ModalWithMobileToast.module.scss';

interface IModalToast {
  onClose: () => void;
  title?: string;
}

export const ModalToast: FC<PropsWithChildren<IModalToast>> = ({
  title = '',
  onClose,
  children,
}) => (
  <div className={styles.overlay}>
    <div className={styles.modalContainer}>
      <div className={styles.topContainer}>
        <CloseIcon
          onClick={onClose}
          className={styles.cursorPointer}
          data-testid="closeToastModalIcon"
        />
        <div className={styles.header}>{title}</div>
      </div>
      <div className={styles.modalContent}>{children}</div>
    </div>
  </div>
);
