import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'intakeOptimization/auth/config';
import React, { useEffect } from 'react';

const Login = () => {
  const { instance } = useMsal();
  useEffect(() => {
    if (window && (window as any).heap) {
      (window as any).heap.track('Login attempt');
    }

    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  }, []);
  return <div />;
};
export default Login;
