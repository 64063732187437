import React, { FC, InputHTMLAttributes } from 'react';
import Input, { Props } from 'react-input-mask';
import clsx from 'clsx';
import styles from './InputMask.module.scss';

type InputMaskProps = {
  error?: string;
  testId?: string;
  caution?: string;
  adminInputMask?: boolean;
} & Props &
  InputHTMLAttributes<HTMLInputElement>;

export const InputMask: FC<React.PropsWithChildren<InputMaskProps>> = ({
  mask,
  tabIndex,
  id,
  onChange,
  onBlur,
  placeholder,
  value,
  defaultValue,
  disabled,
  error = '',
  caution = '',
  testId = '',
  adminInputMask,
  inputMode,
}) => (
  <>
    <Input
      data-testId={`inputMask${testId}`}
      mask={mask}
      tabIndex={tabIndex}
      disabled={disabled}
      id={id}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      className={clsx(styles.input, {
        [styles.error]: error,
        [styles.caution]: caution,
        [styles.adminInputMask]: adminInputMask
      })}
      value={value}
      defaultValue={defaultValue}
      inputMode={inputMode || 'text'}
    />
    {error && error.length && (
      <span
        data-testId={`inputMask${testId}Error`}
        className={clsx({ [styles.errorMessage]: error })}
      >
        {error}
      </span>
    )}
    {caution && caution.length > 0 && (
      <span
        data-testId={`inputMask${testId}Caution`}
        className={clsx({ [styles.cautionMessage]: caution })}
      >
        {caution}
      </span>
    )}
  </>
);
