import { adminRoutes, bookingRoutes, intakeRoutes } from 'intakeOptimization/routes/absolutes';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  abiePatientType,
  obiePreferencesData,
  obieShowCCOF,
} from 'intakeOptimization/selectors/selectors';

import { useFeature } from 'intakeOptimization/hooks/useFeature';

import { cleanProviderRoute } from 'intakeOptimization/utils';

export const usePreviousRoute = () => {
  const { pathname } = useLocation();
  const [previousRoute, setPreviousRoute] = useState('/');
  const isInsuranceCardEnabled = useFeature('insuranceCard');
  const showCreditCard = useSelector(obieShowCCOF);
  const preferences = useSelector(obiePreferencesData);
  const patientType = useSelector(abiePatientType);
  const isChild = patientType === 'child';
  const clinicianRoutes = [
    cleanProviderRoute(adminRoutes.clinicianProfile),
    cleanProviderRoute(bookingRoutes.clinicianProfile),
  ];

  useEffect(() => {
    const clinicianRelative = pathname.substring(0, pathname.lastIndexOf('/') + 1);
    const relativePath = clinicianRoutes.includes(clinicianRelative) ? clinicianRelative : pathname;
    switch (relativePath) {
      case clinicianRoutes[1]:
        setPreviousRoute(
          preferences.clinician_profile_back.length > 0
            ? preferences.clinician_profile_back[preferences.clinician_profile_back.length - 1]
            : preferences.location_back,
        );
        break;
      case bookingRoutes.providerMatches:
        if (preferences.rematch_clinician_id === 0) {
          setPreviousRoute(bookingRoutes.matchCriteria);
        } else {
          const prev = preferences.path_history;
          setPreviousRoute(prev[0]);
        }
        break;
      case bookingRoutes.matchCriteria:
        setPreviousRoute(bookingRoutes.findCare);
        break;
      case intakeRoutes.intake:
        setPreviousRoute(
          preferences.clinician_profile_back.length > 0
            ? preferences.clinician_profile_back[preferences.clinician_profile_back.length - 1]
            : preferences.location_back,
        );
        break;
      case intakeRoutes.demographics:
        setPreviousRoute(intakeRoutes.account);
        break;
      case intakeRoutes.register:
        setPreviousRoute(intakeRoutes.intake);
        break;
      case intakeRoutes.account:
        setPreviousRoute(intakeRoutes.register);
        break;
      case intakeRoutes.verifyEmail:
        setPreviousRoute(intakeRoutes.account);
        break;
      case intakeRoutes.userExists:
        setPreviousRoute(intakeRoutes.account);
        break;
      case intakeRoutes.address:
        setPreviousRoute(intakeRoutes.reason);
        break;
      case intakeRoutes.emergencyContact:
        setPreviousRoute(intakeRoutes.address);
        break;
      case intakeRoutes.reason:
        setPreviousRoute(intakeRoutes.demographics);
        break;
      case intakeRoutes.billing:
        setPreviousRoute(intakeRoutes.address);
        break;
      case intakeRoutes.insuranceDetails:
        setPreviousRoute(intakeRoutes.emergencyContact);
        break;
      case intakeRoutes.insuranceCard:
        setPreviousRoute(intakeRoutes.insuranceDetails);
        break;
      case intakeRoutes.paymentMethod:
        if (preferences.paymentMethod === 'self_pay') {
          setPreviousRoute(intakeRoutes.emergencyContact);
        } else {
          setPreviousRoute(intakeRoutes.insuranceCard);
        }
        break;
      case intakeRoutes.review:
        if (showCreditCard) {
          setPreviousRoute(intakeRoutes.paymentMethod);
        } else if (preferences.paymentMethod === 'self_pay') {
          setPreviousRoute(intakeRoutes.emergencyContact);
        } else {
          setPreviousRoute(intakeRoutes.insuranceCard);
        }
        break;
      case adminRoutes.filters:
        setPreviousRoute(adminRoutes.patientType);
        break;
      case adminRoutes.bookingForSelf:
        setPreviousRoute(adminRoutes.searchResults);
        break;
      case adminRoutes.accountHolderParent:
        setPreviousRoute(adminRoutes.searchResults);
        break;
      case clinicianRoutes[0]:
        setPreviousRoute(adminRoutes.searchResults);
        break;
      case adminRoutes.accountHolderChild:
        setPreviousRoute(adminRoutes.accountHolderParent);
        break;
      case adminRoutes.physicalAddress:
        if (isChild) {
          setPreviousRoute(adminRoutes.accountHolderChild);
        } else {
          setPreviousRoute(adminRoutes.bookingForSelf);
        }
        break;
      case adminRoutes.accountInsurance:
        setPreviousRoute(adminRoutes.physicalAddress);
        break;
      case adminRoutes.consents:
        if (isInsuranceCardEnabled) {
          setPreviousRoute(adminRoutes.insuranceCard);
        } else {
          setPreviousRoute(adminRoutes.accountInsurance);
        }
        break;
      default:
        setPreviousRoute('/');
        break;
    }
  }, [pathname]);

  return previousRoute;
};
