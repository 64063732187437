import React from 'react';
import '@rmwc/circular-progress/styles';
import clsx from 'clsx';
import styles from './LoadingDots.module.scss';

export const LoadingDots: React.FC<React.PropsWithChildren<{secondary: boolean}>> = ({ secondary }) => (
  <div
    className={clsx(styles.dotsLoader, {
      [styles.secondary]: secondary,
    })}
    data-cy="loading-dots"
  >
    <div />
    <div />
    <div />
  </div>
);
