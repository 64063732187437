import { ROtherClinicians } from 'intakeOptimization/@types';
import { ReactComponent as ArrowRightLine } from 'intakeOptimization/assets/svg/arrow-right-line.svg';
import { adminRoutes, bookingRoutes } from 'intakeOptimization/routes/absolutes';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { loadClinicianProfile } from 'intakeOptimization/store/matches/actions';
import { updatePreferences } from 'intakeOptimization/store/preferences/actions';

import { isAbie, obieOrAbiePreferencesSelector } from 'intakeOptimization/selectors/selectors';

import { sortDes } from 'intakeOptimization/utils/formatting/sortDescending';

import styles from './Providers.module.scss';

interface IProvidersProps {
  providers: ROtherClinicians[];
}

const Providers: React.FC<React.PropsWithChildren<IProvidersProps>> = ({ providers }) => {
  const isAdmin = useSelector(isAbie);
  const preferences = useSelector(obieOrAbiePreferencesSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const sortedProviders = [...providers].sort((a, b) => sortDes(a?.facility_location?.rank_most_available, b?.facility_location?.rank_most_available));
  const topProviders = sortedProviders.length > 3 ? sortedProviders.slice(0, 3) : sortedProviders;

  const handleOnNavigate = (provider: ROtherClinicians) => {
    const id = `${provider.first_name.toLowerCase()}-${provider.last_name.toLowerCase()}-${
      provider.id
    }`;
    const route = !isAdmin
      ? bookingRoutes.clinicianProfile.replace(':id', id)
      : adminRoutes.clinicianProfile.replace(':id', id);
    dispatch(
      updatePreferences({
        clinician_profile_back: [
          ...preferences.clinician_profile_back,
          `${location.pathname}${location.search}`,
        ],
      }),
    );
    dispatch(loadClinicianProfile(null));
    navigate(route);
  };

  return (
    <div className={styles.otherProviders}>
      <div className={styles.heading}>Other providers at this location</div>
      <div className={styles.providers}>
        {topProviders.map((provider) => (
          <div className={styles.provider}>
            <div className={styles.clinicianDP}>
              <img src={provider.photo} alt={provider.first_name} />
            </div>
            <div className={styles.clinicianInfo}>
              <div className={styles.clinicianType}>{provider.type}</div>
              <div className={styles.clinicianName}>
                {provider.first_name}
                {' '}
                {provider.last_name}
                {', '}
                {provider?.credentials?.replaceAll(',', ', ')}
              </div>
            </div>
            <div className={styles.rightLine} onClick={() => handleOnNavigate(provider)}>
              <ArrowRightLine />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Providers;
