import t from './types';

const initialState = {
  modalities: null,
  locations: null,
  insuranceInfo: {},
  isReschedule: false,
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.UPDATE_MODALITIES: {
      return {
        ...state,
        modalities: action.payload,
      };
    }
    case t.UPDATE_CLINICIAN_LOCATIONS: {
      return {
        ...state,
        locations: action.payload,
      };
    }
    case t.UPDATE_PATIENT: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case t.SET_PATIENT_INSURANCE_INFO: {
      return {
        ...state,
        insuranceInfo: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default patientReducer;
