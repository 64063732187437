import clsx from 'clsx';
import React, {
  KeyboardEvent,
  MutableRefObject,
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ReactComponent as CaretDownIcon } from 'lifestance-ui/assets/svg/caret-down.svg';
import { ReactComponent as CaretUpIcon } from 'lifestance-ui/assets/svg/caret-up.svg';
import { onClickOut } from 'lifestance-ui/utils';

import { Button, MobileDropdownSelect } from 'lifestance-ui';

import styles from './DropdownSelect.module.scss';

interface Option {
  label: string;
  value: string;
  testId?: string;
}

interface NestedOption {
  label: string;
  options: Option[];
}

interface DropdownSelectProps {
  placeholder: string;
  disabled?: boolean;
  className?: string;
  options: Option[] | NestedOption[];
  onChange: (selected: string[]) => void;
  defaultValues: string[];
  title: string;
  icon?: string;
  sideWays?: boolean;
  testId?: string;
}

export const DropdownSelect: React.FC<React.PropsWithChildren<DropdownSelectProps>> = ({
  placeholder,
  disabled = false,
  className,
  options,
  onChange,
  defaultValues,
  title,
  icon = '',
  sideWays = false,
  testId,
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(defaultValues);
  const [selectedLabels, setSelectedLabels] = useState([] as string[]);
  const [selectedRef, setSelectedRef] = useState<HTMLDivElement>(null as unknown as HTMLDivElement);

  const optionRefs = useRef<MutableRefObject<HTMLDivElement>[]>([]);
  if (optionRefs.current.length !== options?.length) {
    optionRefs.current = Array(options?.length)
      .fill(options)
      .map((_, i) => optionRefs.current[i] || createRef());
  }

  const dropdownRef = useRef<HTMLDivElement>(null as unknown as HTMLDivElement);

  const handleSave = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (defaultValues.length === 0) {
      setSelected([]);
    } else {
      setSelected(selected);
    }
  }, [defaultValues]);

  const handleClick = useCallback(() => {
    if (!disabled) {
      setOpen(!open);
    }
  }, [disabled, setOpen, open]);

  const handleSelect = (value: string): void => {
    const updatedValues = selected.includes(value)
      ? selected.filter((option) => option !== value)
      : [...selected, value];
    setSelected(updatedValues);
    onChange(updatedValues);
  };

  const handleMobileDropdownSelect = (values: Array<string>): void => {
    setOpen(false);
    setSelected(values);
    onChange(values);
  };

  const handleClear = () => {
    setSelected([]);
    onChange([]);
  };

  const focusPreviouslySelectedOption = () => {
    setTimeout(() => {
      selectedRef?.focus();
    }, 100);
  };

  const focusOption = (element: HTMLDivElement): void => {
    if (element) {
      element.focus();
      setSelectedRef(element);
    }
  };

  const selectOption = (element: HTMLElement): void => {
    const selectedValue = element?.getAttribute('data-value');
    handleSelect(selectedValue as string);
  };

  const selectFirstOption = () => {
    setTimeout(() => {
      const element = optionRefs.current[0].current;
      focusOption(element);
    }, 100);
  };

  const mobileClose = () => {
    setOpen(!open);
  };

  const openAndFocusOption = () => {
    setOpen(true);
    if (selected.length === 0) {
      selectFirstOption();
    } else {
      focusPreviouslySelectedOption();
    }
  };

  const handleNavigate = (event: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>) => {
    event.stopPropagation();
    // FIXME: keyCode is deprecated
    // https://developer.mozilla.org/en-US/docs/web/api/keyboardevent/keycode#browser_compatibility
    switch (event.keyCode) {
      case 38: // Arrow Up
        if (!open) {
          openAndFocusOption();
        }
        if (!selectedRef) {
          return;
        }
        if (selectedRef) {
          const previousOption = selectedRef?.previousSibling as HTMLDivElement;
          focusOption(previousOption);
        }
        break;
      case 40: // Arrow Down
        if (!open) {
          dropdownRef.current?.focus();
          openAndFocusOption();
        } else if (!selectedRef) {
          selectFirstOption();
        } else {
          const nextOption = selectedRef.nextSibling as HTMLDivElement;
          focusOption(nextOption);
        }
        break;
      case 13: // Enter
        if (!open) {
          openAndFocusOption();
        } else {
          setOpen(!open);
          dropdownRef.current?.focus();
        }
        break;
      case 27: // Escape
      case 9: // Tab
        setOpen(false);
        break;
      case 32: // Space
        event.preventDefault();
        selectOption(selectedRef);
        break;
      default:
    }
  };

  useEffect(() => {
    if (open) {
      return onClickOut({
        triggerRef: dropdownRef,
        action: handleClick,
      });
    }
  }, [open, handleClick]);

  const getOptionLabels = (opts: Option[]) => opts
    .filter((option: Option) => selected.includes(option.value))
    .map((option: Option) => option.label);

  const getNestedOptionLabels = (opts: NestedOption[]) => {
    const nestedLabels: string[] = [];
    opts.forEach((el) => {
      const currentOptions = el.options
        .filter((option) => selected.includes(option.value))
        .map((option) => option.label);
      if (currentOptions.length > 0) nestedLabels.push(...currentOptions);
    });
    return nestedLabels;
  };

  useEffect(() => {
    const optionInScope = options as NestedOption[];
    if (optionInScope[0]?.options && optionInScope[0]?.options.length > 0) {
      setSelectedLabels(getNestedOptionLabels(optionInScope));
    }
  }, [options, selected]);

  useEffect(() => {
    const optionInScope = options as Option[];
    if (optionInScope[0]?.value && optionInScope.length > 0) {
      setSelectedLabels(getOptionLabels(options as Option[]));
    }
  }, [options, selected]);

  return (
    <div
      id={`dropdownSelect${title}`}
      tabIndex={0}
      role="listbox"
      className={clsx(className, styles.container)}
      ref={dropdownRef}
      onKeyDown={handleNavigate}
    >
      <div
        className={clsx(styles.dropdown, {
          [styles.selectOpen]: open,
          [styles.selected]: !selected.includes(placeholder) && selected.length > 3,
          [styles.disabled]: disabled,
          [styles.selectedOptions]: selected.length > 0,
        })}
        data-testId={`dropdownSelect${testId}`}
        onClick={handleClick}
        onKeyDown={handleNavigate}
      >
        <span data-testId={`dropdownSelect${testId}PlaceHolder`} className={styles.value}>
          {' '}
          {selectedLabels.length > 0 ? selectedLabels.join(', ') : placeholder}
        </span>
        {open ? <CaretUpIcon /> : <CaretDownIcon />}
      </div>
      {open && (
        <div
          className={styles.optionsContainer}
          role="listbox"
          aria-multiselectable
          data-testId={`dropdownSelectOptionsContainer${testId}`}
        >
          {options
            && options.map((option, i) => {
              option = option as NestedOption;
              if (option.options) {
                return (
                  <div key={`divContainer${option.label}`} className={styles.options}>
                    <h3>{option.label}</h3>
                    {option.options.map((nestedOption) => (
                      <div
                        className={clsx(styles.option, {
                          [styles.focused]:
                            nestedOption.value === selectedRef?.getAttribute('data-value'),
                        })}
                        key={nestedOption.value}
                        ref={optionRefs.current[i]}
                        role="option"
                        aria-selected={selected.includes(nestedOption.value)}
                        data-value={nestedOption.value}
                        onKeyDown={handleNavigate}
                        onClick={() => handleSelect((nestedOption as Option).value)}
                        tabIndex={-1}
                        data-testId={`dropdownSelect${testId}OptionBoxSetOption${i}`}
                      >
                        <div
                          id={nestedOption.value}
                          className={clsx(styles.checkbox, {
                            [styles.checked]: selected.includes(nestedOption.value),
                          })}
                          data-testid={`dropdownSelect${testId}Checkbox`}
                        />
                        <div
                          data-testId={`dropdownSelect${testId}OptionValueSetOption${i}`}
                          className={styles.label}
                        >
                          {nestedOption.label}
                        </div>
                      </div>
                    ))}
                  </div>
                );
              }
              option = option as unknown as Option;
              return (
                <div
                  className={clsx(styles.option, {
                    [styles.focused]: option.value === selectedRef?.getAttribute('data-value'),
                  })}
                  key={option.value}
                  ref={optionRefs.current[i]}
                  role="option"
                  aria-selected={selected.includes(option.value)}
                  data-value={option.value}
                  onKeyDown={handleNavigate}
                  onClick={() => handleSelect((option as Option).value)}
                  tabIndex={-1}
                  data-testId={`dropdownSelect${testId}OptionBoxSetOption${i}`}
                >
                  <div
                    id={option.value}
                    className={clsx(styles.checkbox, {
                      [styles.checked]: selected.includes(option.value),
                    })}
                    data-testid={`dropdownSelect${testId}Checkbox`}
                  />
                  <div
                    data-testId={`dropdownSelect${testId}OptionValueSetOption${i}`}
                    className={styles.label}
                  >
                    {option.label}
                  </div>
                </div>
              );
            })}
          <div className={styles.footer}>
            <Button
              testId={`buttonDropdownSelect${testId}Clear`}
              size="small"
              tertiary
              onClick={handleClear}
            >
              Clear
            </Button>
            <Button
              size="small"
              disabled={selected.length === 0}
              onClick={handleSave}
              testId={`buttonDropdownSelect${testId}SaveClose`}
            >
              Save & close
            </Button>
          </div>
        </div>
      )}
      {!disabled && (
        <MobileDropdownSelect
          testId={testId}
          options={options}
          title={title}
          handleSelect={handleMobileDropdownSelect}
          open={open}
          mobileClose={mobileClose}
          value={selected}
          icon={icon}
          sideWays={sideWays}
        />
      )}
    </div>
  );
};
