import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ConcernListOptions,
  ExpertiseListOptions,
  InsuranceCompanies,
  MarketingReferralOption,
  TypeOfCare,
} from 'patientOnboarding/@types';

import { userLogout } from 'modules/application/store/actions';

import { SELF_PAY_VALUE } from 'patientOnboarding/utils';

type ErrorState = {
  zipCode: string;
  insuranceList?: null | string;
};

export type CreditCardInfo = {
  creditCardToken: string;
  lastFourDigits: string;
  expirationMonth: number;
  expirationYear: number;
  zipCode: number;
};

export type InitialState = {
  zipCode: string;
  zip_codes: string;
  isZipCodeValid: boolean;
  hasValidZipCode: boolean;
  paymentMethod: string;
  insuranceCompany: string;
  errors: ErrorState;
  typeOfCare: string;
  concern: string;
  expertise: string;
  isExistingUser: boolean;
  isColdUser: boolean;
  accountHolder: string;
  showAppointment: boolean;
  showAppointmentLoading: boolean;
  isBookingModalObie: boolean;
  filters: {
    insuranceCompanies: InsuranceCompanies[];
    typesOfCare: TypeOfCare[];
  };
  concerns: string[];
  concernsListOptions: ConcernListOptions[];
  expertises: string[];
  expertisesListOptions: ExpertiseListOptions[];
  specialCases: string;
  credentials: string[];
  availability_filter: string[];
  gender: string[];
  modality: string[];
  language: string[];
  location_names: string[];
  licenseKey: number;
  childAge: string;
  age: string;
  updated: boolean;
  sortType: string;
  interventions: string[];
  populations: string[];
  utc_offset: number | string;
  clinician_profile_back: string[];
  location: string;
  location_back: string;
  rematch_clinician_id: number;
  city: string;
  state: string;
  preliminaryDOB: string;
  preliminaryAge: string;
  loading: boolean;
  entireState: string;
  distance: string;
  preliminaryReferral: string;
  previousPreferences: string;
  marketingReferralListOptions: MarketingReferralOption[];
  creditCardInfo: CreditCardInfo;
  skippedCreditCardInfo: boolean;
  path_history: string[];
  marketingReferralPhone: string;
  preliminaryTOC: string;
  loadingCountByZip: boolean;
  cliniciansAvailableOnZipCode: number;
  distanceFilterIsOpen: boolean;
  displayedState: string;
};

const initialState: InitialState = {
  zipCode: '',
  isZipCodeValid: false,
  hasValidZipCode: false,
  paymentMethod: 'insurance',
  insuranceCompany: '',
  errors: {
    zipCode: '',
    insuranceList: null,
  },
  typeOfCare: '',
  concern: '',
  expertise: '',
  isExistingUser: false,
  isColdUser: false,
  accountHolder: '',
  showAppointment: true,
  showAppointmentLoading: false,
  isBookingModalObie: false,
  filters: {
    insuranceCompanies: [],
    typesOfCare: [],
  },
  concerns: [],
  concernsListOptions: [],
  expertises: [],
  expertisesListOptions: [],
  specialCases: '',
  credentials: [],
  availability_filter: [],
  gender: [],
  modality: ['in_office', 'video_visit'],
  language: [],
  location_names: [],
  licenseKey: -1,
  childAge: '',
  age: '',
  updated: false,
  sortType: 'most_available',
  interventions: [],
  populations: [],
  utc_offset: 0,
  clinician_profile_back: [],
  location: '',
  location_back: '',
  rematch_clinician_id: 0,
  zip_codes: '',
  city: '',
  state: '',
  preliminaryDOB: '',
  preliminaryAge: '',
  loading: false,
  entireState: 'false',
  distance: '60',
  preliminaryReferral: '',
  previousPreferences: '',
  marketingReferralListOptions: [],
  creditCardInfo: {} as CreditCardInfo,
  skippedCreditCardInfo: false,
  path_history: [],
  marketingReferralPhone: '',
  preliminaryTOC: '',
  loadingCountByZip: false,
  cliniciansAvailableOnZipCode: 0,
  distanceFilterIsOpen: false,
  displayedState: '',
};

export const preferencesSlice = createSlice({
  name: 'PO_preferences',
  initialState,
  reducers: {
    updatePreferences: (state, action: PayloadAction<Partial<InitialState>>) => action.payload?.paymentMethod === SELF_PAY_VALUE
      ? {
        ...state,
        ...action.payload,
        insuranceCompany: initialState.insuranceCompany,
      }
      : {
        ...state,
        ...action.payload,
      },
    updateInsuranceList: (
      state,
      action: PayloadAction<{
        insuranceCompanies: InsuranceCompanies[];
      }>,
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    resetFilters: (state) => {
      state.concerns = [];
      state.expertises = [];
      state.specialCases = '';
      state.credentials = [];
      state.availability_filter = [];
      state.gender = [];
      state.modality = [];
      state.language = [];
      state.location_names = [];
      state.concernsListOptions = [];
      state.expertisesListOptions = [];
    },
    setValidZipCode: (state, action: PayloadAction<boolean>) => {
      state.isZipCodeValid = action.payload;
    },
    setHasZipCode: (state) => {
      state.hasValidZipCode = true;
    },
    setIsBookingModalOpenObie: (state, action: PayloadAction<boolean>) => {
      state.isBookingModalObie = action.payload;
    },
    setErrors: (state, action: PayloadAction<Partial<ErrorState>>) => {
      state.errors = {
        ...state.errors,
        ...action.payload,
      };
    },
    setCreditCardInfo: (state, action: PayloadAction<CreditCardInfo>) => {
      state.creditCardInfo = action.payload;
    },
    setExistingUser: (state) => {
      state.isExistingUser = true;
    },
    setColdUser: (state, action: PayloadAction<boolean>) => {
      state.isColdUser = action.payload;
    },
    setAccountHolder: (state, action: PayloadAction<string>) => {
      state.accountHolder = action.payload;
    },
    setLicenseKey: (state, action: PayloadAction<number>) => {
      state.licenseKey = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    removeClinicianProfileBack: (state) => {
      const clinicianBack = state.clinician_profile_back;
      state.clinician_profile_back = clinicianBack.slice(0, clinicianBack.length - 1);
    },
    setMarketingReferral: (state, action: PayloadAction<string>) => {
      state.preliminaryReferral = action.payload;
    },
    setPreviousPreferences: (state, action: PayloadAction<string>) => {
      state.previousPreferences = action.payload;
    },
    setMarketingReferralListOptions: (state, action: PayloadAction<MarketingReferralOption[]>) => {
      state.marketingReferralListOptions = action.payload;
    },
    setSkippedCreditCardInfo: (state, action: PayloadAction<boolean>) => {
      state.skippedCreditCardInfo = action.payload;
    },
    setMarketingReferralPhone: (state, action: PayloadAction<string>) => {
      state.marketingReferralPhone = action.payload;
    },
    setLoadingCountByZip: (state, action: PayloadAction<boolean>) => {
      state.loadingCountByZip = action.payload;
    },
    setCliniciansAvailableOnZipCode: (state, action: PayloadAction<number>) => {
      state.cliniciansAvailableOnZipCode = action.payload;
    },
    resetPreferences: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => ({
      ...initialState,
    }));
  },
});

export const preferencesActions = preferencesSlice.actions;
export default preferencesSlice.reducer;
