import clsx from 'clsx';
import { ReactComponent as CloseIcon } from 'patientOnboarding/assets/svg/close-white.svg';
import { ReactComponent as PhoneIcon } from 'patientOnboarding/assets/svg/phone-line.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SpanButton } from 'lifestance-ui';

import {
  obiePatientAppointmentData,
  obiePreferencesData,
  selectedClinician,
  selectedFacilityData,
} from 'patientOnboarding/selectors/selectors';

import { useInvoca } from 'patientOnboarding/hooks';

import {
  defaultPhoneNumber,
  formatContactNumber,
  getContactInfoByState,
} from 'patientOnboarding/utils';

import styles from './ContactModal.module.scss';

export const ContactModal = ({ closeModal, isBubbleOpen, locationPath, contactInfo }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const location = useLocation();
  const [serviceHours, setServiceHours] = useState('');

  const [contactNumber, setContactNumber] = useState('');
  const preferences = useSelector(obiePreferencesData);
  const { location_back, marketingReferralPhone } = preferences;
  const facility = useSelector(selectedFacilityData);
  const clinician = useSelector(selectedClinician);
  const isColdLanding = (location_back?.length === 0 && location.pathname.includes('provider'))
    || location_back?.includes('/find-care/booking/search');
  const appointment = useSelector(obiePatientAppointmentData);

  useEffect(() => {
    const state = facility?.state ? facility.state : preferences.state;
    const licenseKey = facility?.license_key ? facility.license_key : preferences.licenseKey;
    if (isColdLanding) {
      const clinicianFacility = clinician?.facility_location[0];
      const contactInfoByState = getContactInfoByState(
        contactInfo,
        clinicianFacility.state,
        clinician.license_key,
      );
      const phoneNumber = contactInfoByState?.intake_call_in_number;
      setContactNumber(phoneNumber);
      setServiceHours(contactInfoByState?.support_hours);
    } else if (appointment) {
      setContactNumber(appointment?.support_info?.support_number);
      setServiceHours(appointment?.support_info?.support_hours);
    } else {
      const contactInfoByState = getContactInfoByState(contactInfo, state, licenseKey);
      const phoneNumber = contactInfoByState?.intake_call_in_number;
      setContactNumber(phoneNumber);
      setServiceHours(contactInfoByState?.support_hours);
    }

    if (marketingReferralPhone.length > 0) {
      setContactNumber(marketingReferralPhone);
    }
  }, [preferences?.state, facility?.state, facility?.license_key, preferences.licenseKey]);

  function handleCloseModal() {
    setIsModalOpen(false);
    setTimeout(() => {
      closeModal();
    }, 400);
  }

  useEffect(() => {
    if (isBubbleOpen && locationPath !== '') {
      if (locationPath !== location.pathname) handleCloseModal();
    }
  }, [location.pathname]);

  useInvoca();

  console.log(serviceHours)

  return (
    <div
      onClick={handleCloseModal}
      className={clsx(styles.overlay, !isModalOpen && styles.overlayOut)}
    >
      <div className={clsx(styles.container, !isModalOpen && styles.containerOut)}>
        <div className={styles.header}>
          <div onClick={handleCloseModal} className={styles.icon}>
            <CloseIcon />
          </div>
          <p className={styles.title}>Have a question?</p>
        </div>
        <div className={styles.content}>
          <p className={styles.availability}>
            We’re available by phone M-F from
            {' '}
            {serviceHours || '8:30am-5:30pm CST'}
            .
          </p>
          <div className={styles.phoneContainer}>
            <PhoneIcon />
            <p className={styles.photeCTA}>
              Call us at
              {' '}
              <a
                className={clsx(styles.phone, 'invocaPhoneNumber')}
                href={`tel:${formatContactNumber(contactNumber) || ''}`}
              >
                <SpanButton>{contactNumber || defaultPhoneNumber}</SpanButton>
              </a>
            </p>
          </div>
          <div className={styles.divider} />
          <span className={styles.bold}>Emergency resources</span>
          <p className={styles.suicidalIdeationText}>
            If you are thinking of harming yourself or others, immediate help is available.
            <a href="tel:911">
              {' '}
              <span className={styles.boldPhone}>Please call 911</span>
            </a>
            {' '}
            or go to the nearest hospital emergency department.
          </p>
        </div>
      </div>
    </div>
  );
};
