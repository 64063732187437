export default {
  GET_CONSENTS: '@@PO_ADMIN_USER/GET_CONSENTS',
  SET_CONSENTS: '@@PO_ADMIN_USER/SET_CONSENTS',
  SET_LOADING: '@@PO_ADMIN_USER/SET_LOADING',
  SET_SLOT: '@@PO_ADMIN_USER/SET_SLOT',
  SET_ERROR: '@@PO_ADMIN_USER/SET_ERROR',
  SIGN_CONSENTS: '@@PO_ADMIN_USER/SIGN_CONSENTS',
  SET_SIGNED_CONSENTS: '@@PO_ADMIN_USER/SET_SIGNED_CONSENTS',
  REVIEW_CONSENT: '@@PO_ADMIN_USER/REVIEW_CONSENTS',
  SAVE_ADDRESS: '@@PO_ADMIN_USER/SAVE_ADDRESSS',
  UPDATE_PATIENT: '@@PO_ADMIN_USER/UPDATE_PATIENT',
  SET_PATIENT: '@@PO_ADMIN_USER/SET_PATIENT',
  SET_INSURANCE_DETAILS: '@@PO_ADMIN_USER/SET_INSURANCE_DETAILS',
  SAVE_INSURANCE_DETAILS: '@@PO_ADMIN_USER/SAVE_INSURANCE_DETAILS',
  VALIDATE_ZIP_CODE: '@@PO_ADMIN_USER/VALIDATE_ZIP_CODE',
  GET_INSURANCE_SKIP_FLAG: '@@PO_ADMIN_USER/GET_INSURANCE_SKIP_FLAG',
};
