import clsx from 'clsx';
import React from 'react';

import { ReactComponent as InformationIcon } from 'lifestance-ui/assets/svg/information.svg';

import styles from './TooltipMobile.module.scss';

interface ITooltipMobileProps {
  className?: string;
  onClick: any;
  testId?: string;
}

export const TooltipMobile: React.FC<React.PropsWithChildren<ITooltipMobileProps>> = ({
  onClick,
  className,
  testId = 'TooltipModal',
}) => (
  <div className={clsx(styles.tooltip, className)} onClick={onClick} data-testId={`icon${testId}`}>
    <InformationIcon key="mobileTooltip" />
  </div>
);
