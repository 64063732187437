import { ActiveFilter } from 'patientOnboarding/store/cliniciansGlobal/slice';

const isEncoded = (str: string) => typeof str === 'string' && decodeURIComponent(str) !== str;

export const getFilteredClinicians = (
  clinicians: Record<string, unknown>[],
  activeFilters: ActiveFilter[],
) => {
  const nonDistanceFilters = activeFilters?.filter((filter) => filter?.name !== 'distance');
  return clinicians.filter((clinician) => nonDistanceFilters.every((filter) => {
    const clinicianValue = Array.isArray(clinician[filter.name])
      ? clinician[filter.name]
      : [clinician[filter.name]];
      // This is a special case to work with the LGBTQ+ populations as the + will be recognize on the URL
    if (filter.name === 'populations') {
      const encodedValues = (filter.value as string[]).map((val: string) => isEncoded(val) ? decodeURIComponent(val) : val);
      return encodedValues.some((filterVal) => (clinicianValue as string[])?.includes(filterVal));
    }
    return (filter.value as any[]).some((filterVal) => (clinicianValue as string[])?.includes(filterVal));
  }));
};
