import clsx from 'clsx';
import { ReactComponent as CaretDownIcon } from 'intakeOptimization/assets/svg/caret-down.svg';
import React, { FC, FocusEventHandler, MouseEventHandler, useState } from 'react';

import styles from './Trigger.module.scss';

interface TriggerProps {
  placeholder?: string;
  value: string;
  onClick: (event: React.MouseEvent | React.KeyboardEvent) => void;
  disabled?: boolean;
  testId?: string;
  error?: string;
  lastEventType?: string;
}

export const Trigger: FC<TriggerProps> = ({
  placeholder,
  value,
  onClick,
  disabled,
  testId,
  error = '',
  lastEventType,
}) => {
  const [tabbed, setTabbed] = useState<boolean>(false);
  const getDropdownText = (selectedValues: string) => {
    if (selectedValues.length > 1) {
      return selectedValues;
    }
    return placeholder;
  };

  const onInputFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    setTabbed(lastEventType === 'tab');
  };

  const onInputBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    setTabbed(false);
  };

  const onInputClick: MouseEventHandler<HTMLInputElement> = (event) => {
    setTabbed(true);
    if (onClick) onClick(event);
  };

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>) => {
    switch (event.key) {
      case 'Enter':
        onClick(event);
        break;
      default:
        break;
    }
  };

  return (
    <div
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      className={clsx(styles.dropdown, {
        [styles.placeholder]: value.length === 0,
        [styles.selected]: value.length > 0,
        [styles.disabled]: disabled,
        [styles.selectedOptions]: value.length > 0,
        [styles.error]: error && error.length > 0,
        [styles.tabbed]: tabbed,
      })}
      data-testId={`dropdown${testId}`}
      onClick={onInputClick}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      onKeyDown={onInputKeyDown}
    >
      <span data-testId={`dropdown${testId}PlaceHolder`} className={styles.value}>
        {getDropdownText(value)}
      </span>
      <CaretDownIcon />
    </div>
  );
};
