const formatAndLocalDate = (
  input: string,
): { dateModalFormat?: string; dateSlotFormat?: string } => {
  // Convert the input format to ISO 8601-compliant format
  const dateSplit = input.split(' ');

  const isoString = `${dateSplit[0]}T${dateSplit[1]}Z`;

  // Parse the ISO string into a Date object
  const date = new Date(isoString);

  // Check if the date is valid
  if (Number.isNaN(date.getTime())) {
    throw new Error('Invalid date format. Please provide a valid date string.');
  }

  // Days and months arrays for formatting
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const daysOfWeekFull = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const monthsOfYear = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const suffixes = ['st', 'nd', 'rd', 'th'];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const dayOfWeekFull = daysOfWeekFull[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const monthNum = date.getMonth() + 1;
  const dayOfMonth = date.getDate();

  // Get the appropriate suffix for the day
  const suffix = dayOfMonth % 10 > 3 && Math.floor(dayOfMonth / 10) !== 1
    ? suffixes[3]
    : suffixes[(dayOfMonth % 10) - 1] || suffixes[3];

  const hours = date.getHours(); // Local time hours
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHour = hours % 12 || 12; // Convert to 12-hour format

  // Modal Format: "Thursday, January 2nd"
  const dateModalFormat = `${dayOfWeekFull}, ${month} ${dayOfMonth}${suffix}`;

  // Slot Format: "Tue 12/24, 2:00 PM"
  const dateSlotFormat = `${daysOfWeek[date.getDay()].slice(0, 3)} ${monthNum}/${date.getDate()}, ${formattedHour}:${minutes} ${ampm}`;

  // Return an object with both formats
  return {
    dateModalFormat,
    dateSlotFormat,
  };
};

export default formatAndLocalDate;
