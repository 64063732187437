import { CreditCardInfo, RSupportInfo } from 'intakeOptimization/@types';

const types = {
  UPDATE_RESERVATION: '@@IO_RESERVATION/UPDATE_RESERVATION',
  CLEAR_RESERVATION: '@@IO_RESERVATION/CLEAR_RESERVATION',
  BOOK_APPOINTMENT: '@@IO_RESERVATION/BOOK_APPOINTMENT',
  SET_BOOKED: '@@IO_RESERVATION/SET_BOOKED',
  SET_IS_LOADING: '@@IO_RESERVATION/SET_IS_LOADING',
  SET_AVAILABILITY: '@@IO_RESERVATION/SET_AVAILABILITY',
  GET_APPT_DETAILS: '@@IO_RESERVATION/GET_APPT_DETAILS',
  CANCEL_APPT: '@@IO_RESERVATION/CANCEL_APPT',
  SET_ERROR: '@@IO_RESERVATION/SET_ERROR',
  SET_SUCCESS: '@@IO_RESERVATION/SET_SUCCESS',
  ALREADY_CANCELLED: '@@IO_RESERVATION/ALREADY_CANCELLED',
  APPOINTMENT_OCCURED_PAST_FLAG: '@@IO_RESERVATION/APPOINTMENT_OCCURED_PAST_FLAG',
  GET_CANCELLATION_REASONS: '@@IO_RESERVATION/GET_CANCELLATION_REASONS',
  SET_CANCELLATION_REASONS: '@@IO_RESERVATION/SET_CANCELLATION_REASONS',
  SET_CANCELLATION_REASON: '@@IO_RESERVATION/SET_CANCELLATION_REASON',
  SAVE_SELECTED_CANCELLATION_REASON: '@@IO_RESERVATION/SAVE_SELECTED_CANCELLATION_REASON',
  POST_BOOKING_API: '@@IO_RESERVATION/POST_BOOKING_API',
};

export interface ReservationPayload {
  clinicianKey: string;
  patientId: string;
  creditCardInfo: CreditCardInfo;
}

export interface CancellationReasonsPayload {
  cancellationReasonId: string;
  patientAppointmentId: string;
}

export interface AppointmentDetailsDTO {
  patient_appointment: {
    support_info: RSupportInfo[];
    marketing_referral_phone?: string;
  };
}

export default types;
