import clsx from 'clsx';
import { FilterOption } from 'intakeOptimization/@types';
import { ReactComponent as CloseIcon } from 'intakeOptimization/assets/svg/close.svg';
import { ReactComponent as FilterLogo } from 'intakeOptimization/assets/svg/filter-3-line.svg';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from 'lifestance-ui';

import { MobileFilter } from 'intakeOptimization/components';

import {
  countSelector,
  displayedCliniciansSelector,
} from 'intakeOptimization/store/clinicians/selectors';
import { setActiveFilters } from 'intakeOptimization/store/clinicians/slice';

import { obiePreferencesData } from 'intakeOptimization/selectors/selectors';

import { cleanAvailability, useDevice } from 'intakeOptimization/utils';
import { simpleKeyboardControl } from 'intakeOptimization/utils/keyboardAccessibility';

import styles from './MobileFilters.module.scss';

interface DefaultValues {
  concerns: string[];
  availability_filter: string[];
  gender: string[];
  modality: string[];
  language: string[];
  location_names: string[];
  [key: string]: string[];
}

interface MobileFiltersProps {
  onChange: (name: string, values: string[]) => void;
  defaultValues: DefaultValues;
  filters: FilterOption[];
  isAdmin: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  sliceBegin: number;
  clinicianCount: number;
}

export type MobileFilterHandle = {
  clearAll: () => void;
};

export const MobileFiltersAdmin: React.FC<React.PropsWithChildren<MobileFiltersProps>> = ({
  onChange,
  defaultValues,
  filters,
  onClose,
  onOpen,
  sliceBegin,
}) => {
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const count = useSelector(countSelector);
  const clinicians = useSelector(displayedCliniciansSelector);
  const preferences = useSelector(obiePreferencesData);
  const navigate = useNavigate();
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const [areFiltersActive, setAreFiltersActive] = useState(false);
  const [counterActiveFilters, setCounterActiveFilters] = useState(0);
  // Keys are in order changing the order will affect "Setting filters active" section below
  const filterKeys = {
    abieKeys: [
      'modality',
      'location_names',
      'availability_filter',
      'gender',
      'language',
      'credentials',
    ],
  };
  const keys = filterKeys.abieKeys;

  const filterRefs = useRef([
    createRef<MobileFilterHandle>(),
    createRef<MobileFilterHandle>(),
    createRef<MobileFilterHandle>(),
    createRef<MobileFilterHandle>(),
  ]);

  const handleOpen = () => {
    setOpen(true);

    setTimeout(() => {
      setVisible(true);
      onOpen?.();
    }, 100);
  };

  const handleClose = () => {
    setVisible(false);

    setTimeout(() => {
      setOpen(false);
      onClose?.();
    }, 500);
  };

  const handleSave = () => {
    handleClose();
  };

  const handleChange = (name: string, values: string[]) => {
    const newValue = name === 'availability_filter' ? cleanAvailability(values) : values;
    onChange(name, newValue);
  };

  const handleClear = () => {
    if (sliceBegin === 6) {
      dispatch(
        setActiveFilters({
          credentials: [],
          language: [],
        }),
      );
      filterRefs.current.forEach((ref) => ref.current?.clearAll());
    } else {
      dispatch(
        setActiveFilters({
          modality: [],
          location_names: [],
          availability_filter: [],
          gender: [],
          language: [],
          credentials: [],
        }),
      );
      filterRefs.current.forEach((ref) => ref.current?.clearAll());
    }
  };

  useEffect(() => {
    // Avoid scroll when MobileDropdown opened
    const appContainer = document.getElementById('appContainer') as HTMLElement;

    if (open && isMobile) {
      appContainer.style.overflow = 'hidden';
      appContainer.style.height = '100vh';
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    } else {
      appContainer.style.overflow = 'initial';
      appContainer.style.height = 'initial';
      document.body.style.overflow = 'initial';
      document.body.style.height = 'initial';
    }
  }, [open, isMobile]);

  // Setting filters active
  useEffect(() => {
    if (sliceBegin === 6) {
      // Tablet-large
      // As the slice is 6 means you are cutting 2 out of 8 filters so we are starting the array at 6 - 2
      // to get the actual array of keys
      const defaultValuesKeys = keys.slice(sliceBegin - 2);
      const ocurrences = defaultValuesKeys.reduce((acc, current) => {
        if (defaultValues[current]?.length > 0) {
          return acc + 1;
        }
        return acc + 0;
      }, 0);
      const active = ocurrences !== 0;
      const numberOfActiveFilters = active
        ? defaultValuesKeys
          .map((key) => (defaultValues[key] ? defaultValues[key]?.length : 0))
          .reduce((acc, curr) => acc + curr, 0)
        : 0;
      setAreFiltersActive(active);
      setCounterActiveFilters(numberOfActiveFilters);
    }
    if (sliceBegin === 4) {
      // Tablet-medium
      // As the slice is 4 means you are cutting 4 out of 8 filters so we are starting the array at 4 - 2
      // to get the actual array of keys
      const defaultValuesKeys = keys?.slice(sliceBegin - 2);
      const ocurrences = defaultValuesKeys?.reduce((acc, current) => {
        if (defaultValues[current]?.length > 0) {
          return acc + 1;
        }
        return acc + 0;
      }, 0);
      const active = ocurrences !== 0;
      const numberOfActiveFilters = active
        ? defaultValuesKeys
          .map((key) => (defaultValues[key] ? defaultValues[key]?.length : 0))
          .reduce((acc, curr) => acc + curr, 0)
        : 0;
      setAreFiltersActive(active);
      setCounterActiveFilters(numberOfActiveFilters);
    }
    if (sliceBegin === 2) {
      // Tablet-small
      // As the slice is 2 means you are cutting 6 out of 8 filters so we are starting the array at 6 - 2
      // to get the actual array of keys
      const defaultValuesKeys = keys?.slice(sliceBegin - 2);
      const ocurrences = defaultValuesKeys?.reduce((acc, current) => {
        if (defaultValues[current]?.length > 0) {
          return acc + 1;
        }
        return acc + 0;
      }, 0);
      const active = ocurrences !== 0;
      const numberOfActiveFilters = active
        ? defaultValuesKeys
          .map((key) => (defaultValues[key] ? defaultValues[key]?.length : 0))
          .reduce((acc, curr) => acc + curr, 0)
        : 0;
      setAreFiltersActive(active);
      setCounterActiveFilters(numberOfActiveFilters);
    }
  }, [defaultValues, sliceBegin]);

  return (
    <>
      <div
        className={clsx(styles.select, styles.adminSelect, {
          [styles.selectedOptions]: areFiltersActive,
        })}
        tabIndex={0}
        role="button"
        onKeyDown={(e) => simpleKeyboardControl(e, handleOpen, true)}
        onClick={handleOpen}
      >
        <span className={styles.adminLableTabletFilters}>Filters</span>
        {areFiltersActive ? (
          <div className={styles.circle}>{counterActiveFilters}</div>
        ) : (
          <FilterLogo />
        )}
      </div>
      {open && (
        <>
          <div
            className={clsx(styles.underLayer, { [styles.open]: visible })}
            onClick={handleClose}
          />
          <div className={clsx(styles.mobileFilters, { [styles.open]: visible })}>
            <div className={clsx(styles.topFilters, { [styles.open]: visible })}>
              <span className={styles.handleClose} onClick={handleClose}>
                <CloseIcon />
              </span>
              <span className={styles.filtersTitle}>Filters</span>
              <span className={styles.clearAll} onClick={handleClear}>
                Clear all
              </span>
            </div>
            <div className={styles.filters}>
              {filters.map((filter: any, i: number) => {
                const values = filter.name !== 'focus'
                  ? defaultValues[filter?.name]
                  : defaultValues.concerns.concat(
                    defaultValues.interventions,
                    defaultValues.populations,
                  );
                return (
                  <MobileFilter
                    className={styles.filter}
                    name={filter.name as string}
                    defaultValues={values}
                    label={filter.label as string}
                    options={filter.options}
                    onChange={handleChange}
                    ready={visible}
                    ref={filterRefs.current[i]}
                  />
                );
              })}
            </div>
            <div className={styles.footer}>
              <Button fullWidth onClick={handleSave}>
                {count === 0 ? 'No providers available' : `Show ${count} results`}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
