import * as API from 'intakeOptimization/api/legacy';
import * as Effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, put, takeLatest } from 'redux-saga/effects';

import { setMarketingReferralPhone } from 'intakeOptimization/store/preferences/actions';
import {
  setContactInfo,
  setExistsInAmd,
  setPatientIsPolicyholder,
} from 'intakeOptimization/store/userGlobal/actions';

import {
  setAlreadyCancelled,
  setAppointmentOccuredPastFlag,
  setAvailability,
  setBookedAppointment,
  setCancelSuccess,
  setCancellationReasons,
  setError,
  setLoading,
} from './actions';
import t, { AppointmentDetailsDTO, CancellationReasonsPayload, ReservationPayload } from './types';

const call: any = Effects.call;

function* handleBookingAppointment(action: PayloadAction<ReservationPayload>) {
  try {
    yield put(setLoading(true));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response: { data: any } = yield call(API.bookAppointment, action.payload);
    yield put(setBookedAppointment(response.data));
    yield put(setLoading(false));
  } catch (e: any) {
    yield put(setLoading(false));
    if (e.response.data.message === 'Appointment no longer available') {
      yield put(setLoading(false));
      yield put(setAvailability(true));
    }
  }
}

function* handleGetAppointmentDetails(action: PayloadAction<string>) {
  try {
    yield put(setLoading(true));
    const response: { data: AppointmentDetailsDTO } = yield call(
      API.getAppointmentDetails,
      action.payload,
    );
    yield put(setBookedAppointment(response.data));
    const contactInfo = response.data.patient_appointment.support_info;
    const marketingReferralPhone = response.data.patient_appointment?.marketing_referral_phone;
    if (marketingReferralPhone !== undefined && marketingReferralPhone !== null) {
      yield put(setMarketingReferralPhone(marketingReferralPhone));
    }
    // yield put(setContactInfo(formattedContactInfo(contactInfo)));
    yield put(setContactInfo(contactInfo));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
    // TBD
  }
}

function* handleCancelAppointment(action: PayloadAction<string>) {
  yield put(setLoading(true));
  try {
    yield call(API.cancelAppointment, action.payload);
    yield put(setCancelSuccess(true));
    yield put(setLoading(false));
  } catch (err: any) {
    if (err.response.data.already_cancelled_flag === true) {
      yield put(setAlreadyCancelled(true));
    } else if (err.response.data.appointment_occured_past_flag === true) {
      yield put(setAppointmentOccuredPastFlag(true));
    } else {
      yield put(setCancelSuccess(false));
      yield put(setError(err));
    }
    yield put(setLoading(false));
  }
}

function* handleGetCancellationReasons() {
  try {
    const response: { data: any } = yield call(API.getCancellationReasons);
    yield put(setCancellationReasons(response.data));
  } catch (err: any) {
    yield put(setError(err));
  }
}

function* handleSaveSelectedCancellationReason(action: PayloadAction<CancellationReasonsPayload>) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response: { data: any } = yield call(API.saveSelectedCancellationReason, action.payload);
    yield put(setCancellationReasons(response.data));
  } catch (err: any) {
    yield put(setError(err));
  }
}

function* handlePostBooking(action: PayloadAction<ReservationPayload>): any {
  try {
    yield put(setLoading(true));
    const responseBooking = yield call(API.bookAppointment, action.payload);
    yield put(setBookedAppointment(responseBooking.data));
    yield put(setLoading(false));
    yield put(setAvailability(true));
  } catch (e: any) {
    yield put(setLoading(false));
    if (e.response.data.message === 'Appointment no longer available') {
      yield put(setAvailability(false));
    }
    if (
      e.response?.data?.error?.includes('Duplicate name')
      || e.response?.data?.error?.includes('2147218401')
      || e.response?.data?.error?.includes('2147217400')
    ) {
      yield put(setExistsInAmd(true));
    }
    if (e.response?.data?.error?.includes('2147217302')) {
      yield put(setPatientIsPolicyholder(true));
    }
    yield put(setError(e.response?.status));
  }
}

function* reservationSaga(): Generator {
  yield all([
    yield takeLatest(t.BOOK_APPOINTMENT, handleBookingAppointment),
    yield takeLatest(t.GET_APPT_DETAILS, handleGetAppointmentDetails),
    yield takeLatest(t.CANCEL_APPT, handleCancelAppointment),
    yield takeLatest(t.GET_CANCELLATION_REASONS, handleGetCancellationReasons),
    yield takeLatest(t.SAVE_SELECTED_CANCELLATION_REASON, handleSaveSelectedCancellationReason),
    yield takeLatest(t.POST_BOOKING_API, handlePostBooking),
  ]);
}

export default reservationSaga;
