import {
  FacilityLocation,
  FilterCSR,
  LocationFilter,
  NestedOption,
  Option,
} from 'intakeOptimization/@types';

import { getLocationOptions } from './getLocationOptions';
import { updateAvailabilityFilterOptions } from './updateAvailabilityFilterOptions';

type LocationElement = {
  label: any | JSX.Element;
  address_line1: string;
  address_line2?: string;
  city: string;
  distance_in_miles: number;
  facility_id: number;
  facility_name: string;
  value: string;
};

export const getUpdatedFilterOptions = (
  filter: FilterCSR,
  locations: LocationFilter[],
  preferences: { focusOptions: NestedOption[] },
  credentials: Option[],
  licenseKeys: number[],
  isAdmin: boolean,
): LocationElement[] | NestedOption[] | Option[] | FacilityLocation[] => {
  switch (filter.name) {
    case 'location_names':
      return getLocationOptions(locations);
    case 'availability_filter':
      return updateAvailabilityFilterOptions(filter.options as NestedOption[], isAdmin);
    case 'focus':
      return preferences.focusOptions;
    case 'credentials':
      return credentials;
    case 'licenseKeys':
      return licenseKeys?.map((value) => ({
        label: value.toString(),
        value: value.toString(),
      }));
    default:
      return filter.options;
  }
};
