import clsx from 'clsx';
import { ReactComponent as NoResults } from 'patientOnboarding/assets/svg/no-results.svg';
import { ReactComponent as OverFiltered } from 'patientOnboarding/assets/svg/overfiltered.svg';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { SpanButton } from 'lifestance-ui';

import { InvocaNumber } from 'patientOnboarding/components';

import {
  contactInfo as contactInfoSelector,
  selectedFacilityData,
} from 'patientOnboarding/selectors/selectors';

import { getContactInfoByState, stateAbbrToName, useDevice } from 'patientOnboarding/utils';

import styles from './ViewMoreProviders.module.scss';

const getContent = (
  state: string,
  entireState: string,
  preferences: Record<string, any>,
  results: number,
  contactNumber?: string,
  isMobile?: boolean,
):
  | {
      image: ReactNode;
      title: string;
      subtitle: ReactNode;
      buttonText?: string;
    }
  | undefined => {
  const relevantFilters = Object.keys(preferences)
    .filter((key) => [
      'concerns',
      'populations',
      'interventions',
      'availability_filter',
      'gender',
      'language',
    ].includes(key))
    .reduce((obj, key) => {
      // @ts-expect-error param reassignment
      obj[key] = preferences[key];
      return obj;
    }, {});
  const hasActiveFilters = Object.values(relevantFilters).some(
    // @ts-expect-error length
    (value) => value && value.length > 0,
  );

  if ((!entireState || entireState === 'false') && results === 0 && !hasActiveFilters) {
    return {
      image: <NoResults />,
      title: 'To see more options, look across the state',
      subtitle: `See all providers in ${stateAbbrToName(state)} with a video visit.`,
      buttonText: `See all in ${stateAbbrToName(state)}`,
    };
  }
  if ((!entireState || entireState === 'false') && results > 0 && results < 10) {
    return {
      image: <NoResults />,
      title: 'To see more options, look across the state',
      subtitle: `See all providers in ${stateAbbrToName(state)} with a video visit.`,
      buttonText: `See all in ${stateAbbrToName(state)}`,
    };
  }
  if ((!entireState || entireState === 'false') && hasActiveFilters && results === 0) {
    return {
      image: <OverFiltered />,
      title: 'No results close to you',
      subtitle: (
        <span>
          Adjust your filters or expand your search to see all providers in&nbsp;
          {stateAbbrToName(state)}
          {' '}
          via video.
        </span>
      ),
      buttonText: `See all in ${stateAbbrToName(state)}`,
    };
  }
  if (entireState === 'true' && hasActiveFilters && results === 0) {
    return {
      image: <OverFiltered />,
      title: 'We can’t make an exact match',
      subtitle: 'Try adjusting or removing filters.',
    };
  }
  if (entireState === 'true' && !hasActiveFilters && results === 0) {
    return {
      image: <OverFiltered />,
      title: 'There are no providers available',
      subtitle: (
        <>
          It looks like we don't have any providers in
          {' '}
          {stateAbbrToName(state)}
          {' '}
          that are accepting
          new clients right now. Please call
          <br />
          <InvocaNumber phoneNumber={contactNumber} isMobile={isMobile} />
          {' '}
          to be added to our
          waitlist.
        </>
      ),
    };
  }
};

type ViewMoreProvidersProps = {
  state: string;
  handleViewAll: () => void;
  entireState: string;
  radius: number;
  preferences: Record<string, any>;
  results: number;
};

export const ViewMoreProviders: FC<ViewMoreProvidersProps> = ({
  state,
  handleViewAll,
  entireState,
  preferences,
  results,
}) => {
  const facility = useSelector(selectedFacilityData);
  const contactState = facility?.state ? facility.state : preferences.state;
  const licenseKey = facility?.license_key ? facility.license_key : preferences.licenseKey;
  const contactInfo = useSelector(contactInfoSelector);
  const contactInfoByState = getContactInfoByState(contactInfo, contactState, licenseKey);
  const contactNumber = contactInfoByState?.intake_call_in_number;
  const { isSmallTablet, isMobile } = useDevice();

  const content = getContent(
    state,
    entireState,
    preferences,
    results,
    contactNumber,
    isSmallTablet || isMobile,
  );
  return content ? (
    <div
      className={clsx(styles.container, {
        [styles.noResults]: results === 0,
      })}
    >
      <div className={styles.icon}>{content?.image}</div>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>{content?.title}</h1>
      </div>
      <div className={styles.description}>{content?.subtitle}</div>
      {content?.buttonText ? (
        <div className={styles.button}>
          <SpanButton secondary onClick={handleViewAll} className={styles.button}>
            <span>{content?.buttonText}</span>
          </SpanButton>
        </div>
      ) : null}
    </div>
  ) : null;
};
