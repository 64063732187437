import clsx from 'clsx';
import { bookingRoutes } from 'patientOnboarding/routes/absolutes';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SpanButton } from 'lifestance-ui';

import { updatePreferences } from 'patientOnboarding/store/preferences/actions';

import {
  obiePreferencesInsuranceCompany,
  selectedClinician,
} from 'patientOnboarding/selectors/selectors';

import { useInvoca } from 'patientOnboarding/hooks';

import styles from './NoInsurance.module.scss';

interface INoInsuranceProps {
  clinicianName: string;
  phoneNumber?: string;
}

export const NoInsurance: FC<INoInsuranceProps> = ({ clinicianName, phoneNumber }) => {
  const navigate = useNavigate();
  const profile = useSelector(selectedClinician);
  const insuranceCompany = useSelector(obiePreferencesInsuranceCompany);
  const dispatch = useDispatch();

  const handleNavigate = () => {
    const url = `${bookingRoutes.findCare}?License_Key=${profile.license_key}`;
    navigate(url);
  };

  useEffect(() => {
    // If this component is being mounted that means that
    // insuranceCompany state should return to initial state.
    dispatch(updatePreferences({ insuranceCompany: '' }));
  }, [insuranceCompany]);

  useInvoca();

  return (
    <div className={styles.container}>
      <div className={styles.message}>
        <span className={styles.paragrahp}>Find a provider who accepts your insurance</span>
        {' '}
        <SpanButton onClick={handleNavigate} withPointer>
          Match with a provider
        </SpanButton>
      </div>
      <p className={styles.text}>
        If you have your heart set on
        {' '}
        {clinicianName}
        {' '}
        continue with self-pay or call us at
        {' '}
        <span className={clsx(styles.phone, 'invocaPhoneNumber')}>{phoneNumber}</span>
        {' '}
      </p>
    </div>
  );
};
