import { ReactComponent as CalendarIcon } from 'patientOnboarding/assets/svg/calendar-line.svg';
import { ReactComponent as ErrorIcon } from 'patientOnboarding/assets/svg/error.svg';
import { ReactComponent as LoadingIcon } from 'patientOnboarding/assets/svg/loading.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

import { AppointmentEstablished } from 'patientOnboarding/components';

import { updatePreferences } from 'patientOnboarding/store/preferences/actions';

import {
  abieReservationError,
  availabilityStatus,
  existingPatient,
  reservationData,
  showAppt,
  showApptLoading,
} from 'patientOnboarding/selectors/selectors';

import { useDevice } from 'patientOnboarding/utils/useDevice';

import styles from './TopAppointmentEstablished.module.scss';

interface LocationState {
  from?: string;
}

export const TopAppointmentEstablished = () => {
  const [openModal, setOpenModal] = useState(false);
  const showLoading = useSelector(showApptLoading);
  const showAppointment = useSelector(showAppt);
  const reservation = useSelector(reservationData);
  const reservationError = useSelector(abieReservationError);
  const isReservationAvailable = useSelector(availabilityStatus);
  const isReschedule = useSelector(existingPatient); // HARDCODED FROM REDUCER INITAL STATE

  const { isMobile, isTablet } = useDevice();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const fromRoute = location?.state?.from;
  const appointmentRef = useRef(null);
  const hasBeenOpened = localStorage.getItem('appointment');

  const delayForClose = 5000;

  const appointmentTimer = useRef<null | NodeJS.Timeout>(null);

  useEffect(() => {
    dispatch(updatePreferences({ showAppointmentLoading: true }));
    setTimeout(() => {
      dispatch(updatePreferences({ showAppointmentLoading: false }));
    }, 1000);
  }, [location.pathname]);

  useEffect(() => {
    if (!isReschedule) {
      dispatch(updatePreferences({ showAppointment: false }));
    } else {
      dispatch(updatePreferences({ showAppointment: true }));
      clearInterval(appointmentTimer.current as any);
      appointmentTimer.current = setTimeout(() => {
        dispatch(updatePreferences({ showAppointment: false }));
      }, delayForClose);
    }
  }, []);

  useEffect(() => {
    if ((reservation && !isMobile && !isTablet) || (reservationError && reservationError !== '')) {
      setOpenModal(showAppointment);
    } else {
      setOpenModal(false);
    }
  }, [reservation, reservationError, showAppointment, isMobile, isTablet]);

  const handleModal = () => {
    // setOpenModal(true);
    dispatch(updatePreferences({ showAppointment: true }));
    clearInterval(appointmentTimer.current as any);
    appointmentTimer.current = setTimeout(() => {
      dispatch(updatePreferences({ showAppointment: false }));
    }, delayForClose);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    if (showAppointment) {
      localStorage.setItem('appointment', 'true');
      dispatch(updatePreferences({ showAppointment: false }));
    }
  };

  const transitions = useTransition(showAppointment, {
    from: { y: -100, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: -100, opacity: 0 },
    reverse: showAppointment,
    delay: 200,
  });

  return (
    <div id="topAppointmentContainer" ref={appointmentRef}>
      {transitions(
        (styles, item) => item && (
        <animated.div style={styles}>
          <AppointmentEstablished
            testId="AppointmentDetails"
            onClose={handleCloseModal}
            reservation={reservation}
            reservationError={reservationError}
          />
        </animated.div>
        ),
      )}
      <button
        data-testId="topAppointmentToggleDetails"
        type="button"
        className={styles.trigger}
        onClick={handleModal}
      >
        {showLoading ? (
          <LoadingIcon data-testId="topAppointmentLoadingIcon" className={styles.loadingIcon} />
        ) : (
          <CalendarIcon data-testId="topAppointmentCalendarIcon" className={styles.calendarIcon} />
        )}
        <span>Your appointment</span>
      </button>
    </div>
  );
};
