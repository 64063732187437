import React, { FC, MutableRefObject, createRef, useRef } from 'react';
import clsx from 'clsx';

import { IOptionsSingleList } from '../types';
import styles from './OptionsList.module.scss';

interface OptionsListProps {
  testId?: string;
  onSelect: (value: string) => void;
  optionsLists: IOptionsSingleList[];
  selected: string;
  query: string;
  disableOnClickOut?: boolean;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>) => void;
  lastEventType?: string;
}

export const OptionsList: FC<OptionsListProps> = ({
  testId,
  onSelect,
  selected,
  optionsLists,
  query,
  disableOnClickOut = false,
  onBlur,
  onKeyDown,
  lastEventType = 'tab',
}) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const optionRefs = useRef<MutableRefObject<HTMLDivElement>[]>([]);
  if (optionRefs.current.length !== optionsLists?.length) {
    optionRefs.current = Array(optionsLists?.length)
      .fill(optionsLists)
      .map((_, i) => optionRefs.current[i] || createRef());
  }

  const handleSelect = (option: string) => {
    /**
     * In order to scroll to top in a dynamic height we are giving a few extra time to let the Selected container show
     * as current ref has an specific height in the container but once the selected container appears this will change
     * we need to make sure the selected container is there before scrolling to top.
     * */
    if (query.length > 0) {
      setTimeout(() => {
        const { scrollX, scrollY } = window;
        containerRef.current?.scrollIntoView();
        window.scrollTo(scrollX, scrollY);
      }, 100);
    }
    onSelect(option);
  };

  const focusNextTabbableElement = () => {
    const tabbableElements = Array.from(
      document.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      ),
    );

    const currentIndex = tabbableElements.indexOf(document.activeElement as any);

    let nextIndex = currentIndex + 1;
    if (nextIndex >= tabbableElements.length) {
      nextIndex = 0;
    }
    (tabbableElements[nextIndex] as HTMLElement).focus();
  };

  const focusPrevTabbableElement = () => {
    const tabbableElements = Array.from(
      document.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      ),
    );

    const currentIndex = tabbableElements.indexOf(document.activeElement as any);
    let prevIndex = currentIndex - 1;
    if (prevIndex < 0) {
      prevIndex = tabbableElements.length - 1;
    }
    (tabbableElements[prevIndex] as HTMLElement).focus();
  };

  const handleNavigate = (event: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>) => {
    event.stopPropagation();
    onKeyDown?.(event);
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        focusNextTabbableElement();
        break;
      case 'ArrowUp':
        event.preventDefault();
        focusPrevTabbableElement();
        break;
      case 'Escape':
        event.preventDefault();
        break;
      case 'Enter':
      case ' ':
        event.preventDefault();
        handleSelect(document.activeElement?.getAttribute('data-value') as string);
        break;
      default:
    }
  };
  const handleBlur = () => {
    if (onBlur && !disableOnClickOut) {
      onBlur();
    }
  };

  return (
    <>
      {
        /**
         * This overlay is to close the options list and trigger the onBlur function so we can close the dropdown
         * options when clicking on the overlay so this has a relation with a z-index clickable area under the options
         * list and the input that is accesible. So we have an input that can be clicked and won't close the options
         * list if the user wants to click or select text from the input, so this overlay needs to be under the input
         * and the options list which right now have a |z-index: 3| on overlayRef and 4 on interactive elements
         *  */
        lastEventType === 'click' && (
          <div className={styles.overlayRef}>
            <div className={styles.overlay} onClick={handleBlur} />
          </div>
        )
      }
      <div
        ref={triggerRef}
        className={styles.optionsContainer}
        role="listbox"
        data-testId={`dropdownOptionsContainer${testId}`}
      >
        <div ref={containerRef} />
        {optionsLists.length > 0 ? (
          <div key={`divContainerSingle${testId}`} className={styles.options}>
            {optionsLists.map((option, index) => (
              <div
                className={clsx(styles.option, { [styles.selected]: selected === option.value })}
                key={option.value}
                ref={optionRefs.current[index]}
                role="option"
                aria-selected={selected === option.value}
                data-value={option.value}
                onKeyDown={handleNavigate}
                onClick={() => handleSelect(option.value)}
                tabIndex={0}
                data-testid={`dropdownOption${testId}SetOption${index}`}
              >
                <div
                  data-testid={`dropdownOption${testId}OptionLabel${index}`}
                  className={styles.label}
                >
                  {option.label}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <span className={styles.noResults}>No results</span>
        )}
      </div>
    </>
  );
};
