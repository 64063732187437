import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

const host = window?.location.host;
const env = host.includes('qa-book')
  || host.includes('localhost')
  || host.includes('perf-book')
  || host.includes('dev-book')
  || host.includes('uat-book')
  ? 'NONPROD'
  : 'PROD';

export const DATADOG_API_KEY = '57136c1193f9a561c75e277e97912361';

const PROD_CLIENT_ID = '2b174fc3-b47b-4ae6-927d-3db7f593fa3f';
const NON_PROD_CLIENT_ID = '';
const PROD_CLIENT_TOKEN = 'pube83eb4566abbd62093dc412fc64eae61';
const NON_PROD_CLIENT_TOKEN = '';
const clientId = env === 'NONPROD' ? NON_PROD_CLIENT_ID : PROD_CLIENT_ID;
const token = env === 'NONPROD' ? NON_PROD_CLIENT_TOKEN : PROD_CLIENT_TOKEN;

export const initDatadog = () => {
  datadogRum.init({
    applicationId: clientId,
    clientToken: token,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'obie',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '54.2',
    allowedTracingUrls: [(url) => url.startsWith('https://book.lifestance.com')],
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });

  datadogLogs.init({
    clientToken: token,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: 'prod',
    telemetrySampleRate: 100,
  });
};
