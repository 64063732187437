import clsx from 'clsx';
import { FacilityLocation } from 'patientOnboarding/@types';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal } from 'lifestance-ui';

import { getClinicianProfile } from 'patientOnboarding/store/matches/actions';
import { updatePreferences } from 'patientOnboarding/store/preferences/actions';
import { updateReservation } from 'patientOnboarding/store/reservationGlobal/actions';

import {
  isClinicianProfileLoading,
  obiePreferencesData,
  selectedClinician,
} from 'patientOnboarding/selectors/selectors';

import { disableMobileScroll, enableMobileScroll } from 'patientOnboarding/utils';

import styles from './ScheduleCriteria.module.scss';
import {
  NoAvailabilityBox,
  Payment,
  ViewFilterWall,
  ViewLocation,
  ViewMatchedConditions,
  ViewReason,
} from './Views';
import { Availability } from './Views/Availability';

interface IScheduleProps {
  onCancel: () => void;
  handleBadTypeOfCare: (toc: string) => void;
  initialView?: number;
  handleContinueConfirmation: () => void;
  view?: number;
  onChange: (view: number) => void;
  setModalToShow: (val: string) => void;
}

export const ScheduleCriteria: FC<React.PropsWithChildren<IScheduleProps>> = ({
  onCancel,
  handleBadTypeOfCare,
  initialView = 0,
  handleContinueConfirmation,
  view = 0,
  onChange,
  setModalToShow,
}) => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const preferences = useSelector(obiePreferencesData);
  const isLoadingProfile = useSelector(isClinicianProfileLoading);
  const profile = useSelector(selectedClinician);
  const [currentView, setCurrentView] = useState(initialView);
  const views = [
    { view: 'typeOfCare', value: 0, title: 'Reason for visit' },
    { view: 'payment', value: 1, title: 'Payment' },
    { view: 'location', value: 2, title: 'Provider location' },
    { view: 'calendar', value: 3, title: 'Availability' },
    { view: 'matching', value: 4 },
    { view: 'matched', value: 5 },
  ];
  const [currentTitle, setCurrentTitle] = useState(views[0].title);
  const [noAvailabilities, setNoAvailabilities] = useState(false);
  const [clinicianTriggered, setClinicianTriggered] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const isColdLanding = preferences?.location_back?.length === 0
    || preferences?.location_back.includes('/find-care/booking/search');
  const [isNewToc, setIsNewToc] = useState(false);

  useEffect(() => {
    if (view !== currentView) {
      setCurrentView(view);
    }
    if (preferences.preliminaryTOC === '' && preferences.zipCode === '' && isColdLanding) {
      setCurrentView(0);
    }
  }, [view]);

  const handleBack = () => {
    const viewing = currentView;
    if (viewing === 4) {
      dispatch(
        updatePreferences({ zipCode: '', isZipCodeValid: false, preliminaryDOB: '', age: '' }),
      );
      setIsNewToc(false);
    }
    if (viewing === 3 && !isNewToc) {
      dispatch(updateReservation({ facility_id: '' }));
    }
    if (viewing === 3 && isNewToc) {
      onChange(5);
      setCurrentView(5);
      return;
    }
    if (viewing > initialView) {
      setCurrentView(viewing - 1);
      onChange(viewing - 1);
    } else {
      onCancel();
    }
  };

  const closePleaseCallModal = () => {
    setModalToShow('');
    setIsLoading(false);
  };

  const handleTOCContinue = () => {
    const clinicianId = profile.id;
    setClinicianTriggered(false);
    setNoAvailabilities(false);
    dispatch(updateReservation({ availability: false }));
    dispatch(getClinicianProfile(clinicianId, preferences.preliminaryTOC));
    setClinicianTriggered(true);
  };

  const handlePaymentContinue = (viewNumber: number) => {
    setCurrentView(viewNumber + 1);
    setIsDisabled(true);
  };

  const handleLocation = (viewNumber: number) => {
    setCurrentView(viewNumber + 1);
    setIsDisabled(true);
  };

  const handleFilterMatching = (viewNumber: number) => {
    setCurrentView(viewNumber);
  };

  const handleVerifyCriteria = (viewNumber: number) => {
    setCurrentView(viewNumber);
  };

  const handleContinue = () => {
    const viewing = currentView;
    switch (viewing) {
      case 0:
        handleTOCContinue();
        break;
      case 1:
        handlePaymentContinue(viewing);
        break;
      case 2:
        handleLocation(viewing);
        break;
      case 3:
        handleFilterMatching(viewing);
        break;
      default:
    }
  };

  const getAllAvailabilities = (facilities: FacilityLocation[]) => facilities.flatMap((facility) => facility.clinician_availabilities);

  const handleNewTOC = (toc: string) => {
    setCurrentView(3);
    setIsNewToc(true);
    handleBadTypeOfCare(toc);
  };

  useEffect(() => {
    const allAvailabilities = getAllAvailabilities(profile?.facility_location);
    if (
      currentView === 0
      && clinicianTriggered
      && allAvailabilities?.length !== 0
      && !isLoadingProfile
    ) {
      setCurrentView(currentView + 1);
      setNoAvailabilities(false);
      setIsDisabled(true);
      setClinicianTriggered(false);
    } else if (clinicianTriggered && allAvailabilities?.length === 0 && !isLoadingProfile) {
      setNoAvailabilities(true);
    }
  }, [clinicianTriggered, isLoadingProfile]);

  useEffect(() => {
    setIsLoading(isLoadingProfile);
  }, [isLoadingProfile]);

  useEffect(() => {
    const viewing = views.find((elem) => elem.value === currentView);
    setCurrentTitle(viewing?.title || '');
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [currentView]);

  useEffect(() => {
    // Implemented this in order to remedy background mobile scroll issue on Safari
    disableMobileScroll();

    return () => {
      enableMobileScroll();
    };
  }, []);

  return (
    <Modal
      isOpen
      className={styles.modalBody}
      onClose={onCancel}
      variant={currentView === 0 ? 'close' : 'back'}
      onBack={() => handleBack()}
      title={currentTitle}
      testId="scheduleCriteria"
      footer={
        !noAvailabilities ? (
          <Button
            fullWidth
            isLoading={isLoading}
            disabled={isDisabled}
            onClick={handleContinue}
            className={clsx(styles.button, {
              [styles.noButton]: currentView === 3,
              [styles.noButton]: currentView === 4,
              [styles.noButton]: currentView === 5,
            })}
          >
            Continue
          </Button>
        ) : (
          <NoAvailabilityBox />
        )
      }
      modalStyle={currentView === 3 || currentView === 4 || currentView === 5 ? 'coldLanding' : ''}
    >
      <div ref={containerRef} className={styles.container}>
        {currentView === 0 && (
          <ViewReason
            setDisabled={setIsDisabled}
            setNoAvailabilities={setNoAvailabilities}
            clinicianTriggered={clinicianTriggered}
          />
        )}
        {currentView === 1 && <Payment setDisabled={setIsDisabled} />}
        {currentView === 2 && <ViewLocation setDisabled={setIsDisabled} />}
        {currentView === 3 && (
          <Availability
            isNewTOC={isNewToc}
            setNext={handleFilterMatching}
            goToIntake={initialView !== 0}
            handleContinueConfirmation={handleContinueConfirmation}
          />
        )}
        {currentView === 4 && (
          <ViewFilterWall setNextView={handleVerifyCriteria} setModalToShow={setModalToShow} />
        )}
        {currentView === 5 && <ViewMatchedConditions handleBadTypeOfCare={handleNewTOC} />}
      </div>
    </Modal>
  );
};
