import API from './api';

type PayloadAllClinicians = {
  preferences: string[];
};

export const getClinicianCountByZipCode = (zipCode: any) => API.get(`/clinician_count_by_zip?address_info[zip_code]=${zipCode}`);

export const getAllCliniciansObie = ({ preferences }: PayloadAllClinicians) => {
  const params = new URLSearchParams();
  preferences.forEach((pref) => {
    if (pref.includes('&')) {
      const prefValues = pref.split('&');
      prefValues.forEach((elem) => {
        const pivot = elem.indexOf('=');
        const propName = elem.substring(0, pivot);
        const propValue = elem.substring(pivot + 1);
        params.append(propName, propValue);
      });
    } else {
      const pivot = pref.indexOf('=');
      const propName = pref.substring(0, pivot);
      const propValue = pref.substring(pivot + 1);
      params.append(propName, propValue);
    }
  });
  return API.get('/clinicians', {
    params,
  });
};

export const validateZipCode = (zipCode: string) => API.get(`/validate_zip?address_info[zip_code]=${zipCode}`);

type filterDataParams = {
  zipCode?: string;
  typeOfCare: string;
  patientType: string;
};
export const getFilterData = ({ zipCode, typeOfCare, patientType }: filterDataParams) => API.get(
  `/filter_data?zip_code=${zipCode}&type_of_care=${typeOfCare}&patient_type=${patientType}`,
);

export const getClinicianAvailabilities = (params: any) => API.get('/clinician_availabilities', {
  params,
});

type getContactInfoParams = {
  license?: string | number;
  marketingReferral?: string;
};
export const getContactInfo = ({ license, marketingReferral }: getContactInfoParams) => API.get(
  `/support_info?office_code=${license}${
    marketingReferral ? `&marketing_referral=${marketingReferral}` : ''
  }`,
);

type clinicianProfileParams = {
  id?: string | number;
  typeOfCare?: string;
  zipCode?: string | number;
  appName?: string;
};

export const getClinicianProfile = ({
  id,
  typeOfCare,
  zipCode,
  appName,
}: clinicianProfileParams) => {
  const URL = `/clinician/${id}?${typeOfCare ? `&type_of_cares=${typeOfCare}` : ''}${
    zipCode ? `&zip_codes=${zipCode}` : ''
  }&app_name=obie`;
  return API.get(URL.replace('?&', '?'));
};

export const getClinicianProfileOtherProviders = ({ id }: { id: string | number }) => API.get(`/clinician/${id}?other_providers=true`);
