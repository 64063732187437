import * as API from 'intakeOptimization/api/legacy';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  continueToIntake,
  handleExceptions,
  pushBookingForChildInfo,
  pushBookingForSelfInfo,
  setExistsInAMD,
  setIsBookingModalOpen,
  setResponsibleParty,
} from 'intakeOptimization/store/admin/actions';
import { setPatientAppointment } from 'intakeOptimization/store/reservation/actions';
import { setLoading, setPatientPortal } from 'intakeOptimization/store/user/actions';
import { setContactInfo } from 'intakeOptimization/store/userGlobal/actions';

import { reservationData } from 'intakeOptimization/selectors/selectors';

import { delay, formattedContactInfo } from 'intakeOptimization/utils';
import { accountExistError, accountGenericError } from 'intakeOptimization/utils/errors';

import t from './types';

const formatDate = (dateWithDash) => {
  const values = dateWithDash.split('-');
  return `${values[1]}/${values[2]}/${values[0]}`;
};

function* postBookingForSelfInfo(action) {
  try {
    yield put(setLoading(true));
    yield put(handleExceptions({}));
    yield put(setPatientPortal(''));
    const bookingForSelfInfo = yield call(API.adminBookingForSelfInfo, action.payload);
    const { data } = bookingForSelfInfo;
    if (data && data.account_holder.id) {
      const reservation = yield select(reservationData);
      yield call(API.saveAccountHolderSelectedSlotInfo, {
        accountHolderId: data.account_holder.id,
        reservation: { modality: reservation.modality },
      });
      yield put(
        pushBookingForSelfInfo({
          ...bookingForSelfInfo.data.account_holder,
          account_holder_id: bookingForSelfInfo.data.account_holder.id,
        }),
      );
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response.status === 422 && e.response.data?.message === accountExistError) {
      const account_holder_id = e.response.data.existing_accounts.responsible_party_patients[0].lfs_account_holder_id;
      yield put(
        handleExceptions({
          status: e.response.status,
          message: accountExistError,
        }),
      );
      yield put(setExistsInAMD(true));
      yield put(
        pushBookingForSelfInfo({
          ...action.payload,
          account_holder_id,
          account_holder_patient_id: account_holder_id,
        }),
      );
      yield put(setResponsibleParty(e.response.data.existing_accounts.responsible_party_patients));
      yield put(setPatientPortal(e.response.data.patient_portal_url));
    } else {
      yield put(handleExceptions({ status: 422, message: accountGenericError }));
    }
  }
}

function* postBookingForChildInfoSaga(action) {
  try {
    yield put(setLoading(true));
    yield put(handleExceptions({}));
    const bookingForSelfInfo = yield call(API.adminBookingForChildInfo, action.payload);
    const { data } = bookingForSelfInfo;
    if (data && data.patient.id) {
      const formattedDate = formatDate(data.patient.date_of_birth);
      yield put(
        pushBookingForChildInfo({
          ...data.patient,
          date_of_birth: formattedDate,
        }),
      );
    }
    yield put(setLoading(false));
  } catch (e) {
    if (e && e.toString().indexOf(422) > -1) {
      yield put(
        handleExceptions({
          status: 422,
          message: 'Account holder already exists',
        }),
      );
    } else {
      yield put(handleExceptions({ status: 'Unable to post BookingForSelf' }));
    }
    yield put(setLoading(false));
  }
}

function* putBookingForSelfInfoSaga(action) {
  try {
    yield put(setLoading(true));
    yield put(handleExceptions({}));
    const bookingForSelfInfo = yield call(API.adminUpdateBookingForSelfInfo, action.payload);
    const { data } = bookingForSelfInfo;
    if (data && data.patient.account_holder_id) {
      const formattedDate = formatDate(data.patient.date_of_birth);
      const reservation = yield select(reservationData);
      yield call(API.saveAccountHolderSelectedSlotInfo, {
        accountHolderId: data.patient.account_holder_id,
        reservation: { modality: reservation.modality },
      });
      yield put(
        pushBookingForSelfInfo({
          ...bookingForSelfInfo.data.patient,
          date_of_birth: formattedDate,
          account_holder_id: bookingForSelfInfo.data.patient.account_holder_id,
        }),
      );
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e && e.toString().indexOf(422) > -1) {
      yield put(
        handleExceptions({
          status: 422,
          message: 'Account holder already exists',
        }),
      );
    } else {
      yield put(handleExceptions({ status: 'Unable to post BookingForSelf' }));
    }
  }
}

function* putBookingForChildInfoSaga(action) {
  try {
    yield put(setLoading(true));
    yield put(handleExceptions({}));
    const response = yield call(API.adminUpdateBookingForChildInfo, action.payload);
    const { data } = response;
    const formattedDate = formatDate(data.patient.date_of_birth);
    yield put(
      pushBookingForChildInfo({
        ...data.patient,
        date_of_birth: formattedDate,
      }),
    );
    yield put(setLoading(false));
  } catch (e) {
    if (e && e.toString().indexOf(422) > -1) {
      yield put(
        handleExceptions({
          status: 422,
          message: 'Account holder already exists',
        }),
      );
    } else {
      yield put(handleExceptions({ status: 'Unable to put BookingForChild' }));
    }
    yield put(setLoading(false));
  }
}

function* handleGetPatientDetails(action) {
  try {
    const response = yield call(API.getAppointmentDetails, action.payload.appointmentId);
    const appointmentDetails = response.data.patient_appointment;
    const contactInfo = response.data.patient_appointment.support_info;
    yield put(setPatientAppointment(appointmentDetails));
    yield put(setContactInfo(formattedContactInfo(contactInfo)));
  } catch (e) {
    // TBD
  }
}

function* handleResendEmail(action) {
  try {
    const response = yield call(API.resendBookingEmail, action.payload);
    const patient = response.data.account_holder;
    yield put(pushBookingForSelfInfo(patient));
  } catch (e) {
    // TBD
  }
}

function* handleTrackPatientStatus(action) {
  try {
    yield call(API.trackPatientStatus, action.payload);
  } catch (e) {
    // TBD
  }
}

function* handleStartIntake(action) {
  try {
    yield put(setLoading(true));
    yield delay(1000);
    yield put(continueToIntake(true));
    yield delay(100);
    yield put(continueToIntake(false));
    yield put(setIsBookingModalOpen(false));
    yield put(setLoading(false));
  } catch (e) {
    // TBD
  }
}

function* handleSaveNextSteps(action) {
  try {
    const reservation = yield select(reservationData);
    yield call(API.saveAccountHolderSelectedSlotInfo, {
      accountHolderId: action.payload,
      reservation: { modality: reservation.modality },
    });
  } catch (err) {
    // TBD
  }
}

function* filtersSaga() {
  yield all([
    yield takeLatest(t.POST_BOOKING_FOR_Self_INFO, postBookingForSelfInfo),
    yield takeLatest(t.PUT_BOOKING_FOR_Self_INFO, putBookingForSelfInfoSaga),
    yield takeLatest(t.POST_BOOKING_FOR_Child_INFO, postBookingForChildInfoSaga),
    yield takeLatest(t.PUT_BOOKING_FOR_Child_INFO, putBookingForChildInfoSaga),
    yield takeLatest(t.GET_PATIENT_DETAILS, handleGetPatientDetails),
    yield takeLatest(t.RESEND_BOOKING_EMAIL, handleResendEmail),
    yield takeLatest(t.TRACK_PATIENT_STATUS, handleTrackPatientStatus),
    yield takeLatest(t.START_INTAKE, handleStartIntake),
    yield takeLatest(t.SET_ACCOUNT_EXISTS_NEXT_STEPS, handleSaveNextSteps),
  ]);
}

export default filtersSaga;
