import React from 'react';

import styles from './IntakeHeader.module.scss';

interface IntakeHeaderProps {
  title: string;
  description: string;
  testId?: string;
}
export const IntakeHeader: React.FC<IntakeHeaderProps> = ({ title, description, testId }) => (
  <div>
    <h1 className={styles.title} data-testId={`intakeHeader${testId}ConfirmationTitle`}>
      {title}
    </h1>
    <p className={styles.description} data-testId={`intakeHeader${testId}ConfirmationDescription`}>
      {description}
    </p>
  </div>
);
