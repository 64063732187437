import clsx from 'clsx';
import React, { ReactElement } from 'react';

import { useScrollToMiddleOnFocus } from 'lifestance-ui/hooks';

import styles from './ListCard.module.scss';

interface ListCardProps {
  className: string;
  onClick?: () => void;
  isAdmin?: boolean;
  testId?: string;
  topSearch?: boolean;
  gender?: string;
  languages?: string[];
}

const defaultFunc = () => {
  // Do nothing
};

export const ListCard: React.FC<React.PropsWithChildren<ListCardProps>> = ({
  children,
  className,
  onClick = defaultFunc,
  isAdmin,
  testId = '',
  topSearch,
  gender,
  languages,
}) => {
  const ref = useScrollToMiddleOnFocus<HTMLDivElement>();

  return (
    <div
      ref={ref}
      data-cy={testId}
      data-testId={`listCard${testId}`}
      data-gender={gender}
      data-languages={JSON.stringify(languages)}
      className={clsx(
        {
          [styles.card]: !isAdmin,
          [styles.cardAdmin]: isAdmin,
          [styles.topSearch]: topSearch,
        },
        className,
      )}
      onClick={isAdmin ? (e) => onClick() : (e) => defaultFunc()}
    >
      {children}
    </div>
  );
};
