/* eslint-disable quote-props */
import API from 'patientOnboarding/api/legacy/config';

import { formatPayload } from '../../utils';

export const getMatches = ({ preferences, page = 1, app_name = 'obie' }) => {
  const params = new URLSearchParams();
  preferences.forEach((pref) => {
    if (pref.includes('&')) {
      const prefValues = pref.split('&');
      prefValues.forEach((elem) => {
        const pivot = elem.indexOf('=');
        const propName = elem.substring(0, pivot);
        const propValue = elem.substring(pivot + 1);
        params.append(propName, propValue);
      });
    } else {
      const pivot = pref.indexOf('=');
      const propName = pref.substring(0, pivot);
      const propValue = pref.substring(pivot + 1);
      params.append(propName, propValue);
    }
  });
  params.append('page', page);
  params.append('app_name', app_name);
  return API.get('/clinicians', {
    params,
  });
};

export const getProviderByName = (query) => API.get(`/clinicians?${query}`);

export const saveAddressInfo = ({ addressLine1, addressLine2, city, state, postalCode, id }) => API.put(`/patients/${id}/patient_addresses`, {
  address_line1: addressLine1,
  address_line2: addressLine2,
  city,
  state,
  postal_code: postalCode,
});

export const updatePatient = ({ patientId, data }) => API.patch(`/patients/${patientId}`, formatPayload(data));

export const createAccountHolder = ({
  firstName,
  lastName,
  email,
  phone,
  dateOfBirth,
  legalGender,
  referral,
  receiveEmailUpdates,
  providerId,
  zipCode,
  addressId,
  typeOfCare,
  paymentMethod,
  insuranceCompany,
  genderIdentity,
}) => API.post('/account_holders', {
  first_name: firstName,
  last_name: lastName,
  email,
  date_of_birth: dateOfBirth,
  gender: legalGender,
  source: referral,
  phone_number: phone.replace(/-/g, ''),
  receive_email_updates: receiveEmailUpdates,
  provider_id: providerId,
  search_filter_values: {
    zip_codes: zipCode,
    clinician_address_id: addressId,
    type_of_care: typeOfCare,
    payment_type: paymentMethod,
    insurance_name: insuranceCompany,
  },
  gender_identity: genderIdentity,
});

export const createPatient = ({
  firstName,
  lastName,
  dateOfBirth,
  accountHolderRelationship,
  legalGender,
  accountHolderId,
  intakeStatus,
  zipCode,
  providerId,
  phone,
  pronouns,
  preferredName,
  referral_source,
  about,
  creditCardCollected,
  addressId,
  typeOfCare,
  paymentMethod,
  insuranceCompany,
  genderIdentity,
}) => API.post('/patients', {
  first_name: firstName,
  last_name: lastName,
  phone_number: phone.replace(/-/g, ''),
  date_of_birth: dateOfBirth,
  account_holder_relationship: accountHolderRelationship,
  gender: legalGender,
  account_holder_id: accountHolderId,
  intake_status: intakeStatus,
  search_filter_values: {
    zip_codes: zipCode,
    clinician_address_id: addressId,
    type_of_care: typeOfCare,
    payment_type: paymentMethod,
    insurance_name: insuranceCompany,
  },
  provider_id: providerId,
  pronouns,
  preferred_name: preferredName,
  referral_source,
  about,
  credit_card_on_file_collected: creditCardCollected,
  gender_identity: genderIdentity,
});

export const trackPatientStatus = ({ patientId, intakeStatus }) => API.patch(`/patient_intake_status/${patientId}`, { intake_status: intakeStatus });

export const getAllConsents = ({ patientId, skippedCreditCardInfo }) => API.get(`/patients/${patientId}/consent_forms${skippedCreditCardInfo ? '?skip_ccof=true' : ''}`);
export const getEmailStatus = ({ token }) => API.get(`/confirm_account?token=${token}`);
export const saveSignedConsents = ({ ids, signature, patientId, system = 'patient' }) => API.put(`/patients/${patientId}/consents`, {
  consent_form_ids: ids,
  signature,
  booked_by: system,
});
export const resendEmail = ({ email, id }) => API.patch(`/resend_account_verification_email/${id}`, {
  email,
});

export const bookAppointment = ({ clinicianKey, patientId, creditCardInfo }) => API.post('/book_appointments', {
  clinician_availability_key: clinicianKey,
  patient_id: patientId,
  credit_card_info: creditCardInfo,
});

export const bookAppt = ({
  clinicianKey,
  patientId,
  system, // patient (OBIE) admin (ABIE)
}) => API.post('/book_appointments', {
  booked_by: system,
  clinician_availability_key: clinicianKey,
  patient_id: patientId,
});

export const saveInsuranceDetails = ({
  patientId,
  policyHolder,
  data,
  address,
  system = 'patient',
}) => API.put(`/patients/${patientId}/insurance_coverages`, {
  insurance_details: {
    insurance_carrier: data.insuranceCarrier,
    member_id: data.insuranceMemberId,
    mental_health_phone_number: data.mentalHealthPhone,
    primary_policy_holder: data.policyholder,
    provider_id: data?.provider_id,
    facility_id: data?.facility_id,
    license_key: data?.license_key,
    policy_holder: policyHolder,
    address,
  },
  booked_by: system,
});

export const getAppointmentDetails = (id) => API.get(`/patient_appointments/${id}`);
export const cancelAppointment = (id) => API.put(`/appointment_cancellations/${id}`);
export const saveInsuranceCard = ({ id, frontCard, backCard, system = 'patient' }) => {
  const formData = new FormData();
  formData.append('front_card', frontCard);
  formData.append('back_card', backCard);
  formData.append('booked_by', system);
  return API.put(`/patients/${id}/insurance_card`, formData);
};

export const saveAccountHolderSelectedSlotInfo = ({
  accountHolderId,
  reservation,
  user,
  modality,
  filters,
  preferences,
}) => API.put(`/account_holders/${accountHolderId}/selected_slot_info`, {
  selected_slot_info: {
    accountHolderId,
    reservation,
    user,
    modality,
    filters,
    preferences,
  },
});

export const updateAccountHolder = ({ id, accData }) => API.patch(`/account_holders/${id}`, {
  ...accData,
});
export const getSelectedSlotInfo = ({ token }) => API.get(`selected_slot_info?token=${token}`);

export const resendBookingEmail = ({ email, id }) => API.patch(`/resend_booking_appointment_email/${id}`, { email });

export const getLicenseKeyRules = (licenseKey) => API.get(`/license_key_rules?license_key=${licenseKey}`);

export const getCancellationReasons = () => API.get('/cancellation_reasons');
export const saveSelectedCancellationReason = ({ cancellationReasonId, patientAppointmentId }) => API.post('/cancellations', {
  cancellation_reason_id: cancellationReasonId,
  patient_appointment_id: patientAppointmentId,
  cancelled_by: 'Patient',
});

export const saveEmergencyContact = ({ firstName, lastName, phone, relationship, patientId }) => API.post('patients/emergency_contact', {
  patient_id: patientId,
  first_name: firstName,
  last_name: lastName,
  phone,
  relationship_to_patient: relationship,
});
