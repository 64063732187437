export const filterOptions = [
  {
    label: 'Visit Type',
    name: 'modality',
    options: [
      { label: 'Video', value: 'video_visit' },
      { label: 'In-office', value: 'in_office' },
    ],
  },
  {
    label: 'Issues',
    name: 'concerns',
    maxHeight: true,
    options: [],
  },
  {
    label: 'Availability',
    name: 'availability_filter',
    maxHeight: true,
    options: [
      {
        label: 'Time of day',
        options: [
          { label: 'Before 10am', value: 'before_10_AM' },
          { label: 'Before 12pm', value: 'before_12_PM' },
          { label: 'After 12pm', value: 'after_12_PM' },
          { label: 'After 3pm', value: 'after_3_PM' },
        ],
      },
      {
        label: 'Availability',
        options: [
          { label: 'Next Three Days', value: 'next_three_Days' },
          { label: 'Next Week', value: 'next_week' },
          { label: 'Next Two Weeks', value: 'next_two_Weeks' },
          { label: 'Next Month', value: 'next_month' },
        ],
      },
    ],
  },
  {
    label: 'Gender',
    name: 'gender',
    options: [
      { label: 'Female', value: 'Female' },
      { label: 'Male', value: 'Male' },
      { label: 'Non-binary', value: 'Non-binary' },
    ],
  },
  {
    label: 'Language',
    name: 'language',
    maxHeight: true,
    options: [
      { label: 'English', value: 'English' },
      { label: 'Spanish', value: 'Spanish' },
      { label: 'Mandarin', value: 'Mandarin' },
      { label: 'Hindi', value: 'Hindi' },
      { label: 'German', value: 'German' },
      { label: 'French', value: 'French' },
      { label: 'Russian', value: 'Russian' },
    ],
  },
];
