import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';

import { ContextModal, PuzzleLoading } from 'lifestance-ui';

import styles from './OverlayLoadingModal.module.scss';

interface IOverlayModalProps {
  isLoading?: boolean;
}

export const OverlayLoadingModal: FC<IOverlayModalProps> = ({ isLoading = true }) => {
  const copy = [
    'Finding matches...',
    'Looking for the best fit...',
    'Loading results...',
    'Almost there...',
  ];
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const textStepsInterval = setInterval(() => {
      setStep((prevStep) => {
        let nextStep = prevStep;
        if (prevStep < 6) {
          // Last copy will stay for the remaining loading time
          nextStep = prevStep + 1;
        }
        return nextStep;
      });
    }, 2500);

    setLoading((prevLoading) => !prevLoading);
    const puzzleInterval = setInterval(() => {
      setLoading((prevLoading) => !prevLoading);
    }, 1500);

    return () => {
      clearInterval(textStepsInterval);
      clearInterval(puzzleInterval);
    };
  }, []);

  return (
    <ContextModal isOpen onClose={() => {}} hideCloseIcon hideHeader bottomModal>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          {copy.map((h, i) => (
            <div
              className={clsx(styles.header, {
                [styles.hide]: step !== i * 2,
                [styles.show]: step === i * 2,
              })}
            >
              {h}
            </div>
          ))}
        </div>
        <div className={styles.puzzle}>
          <PuzzleLoading loading={loading} />
        </div>
      </div>
    </ContextModal>
  );
};
