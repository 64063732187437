import { FacilityLocation } from 'intakeOptimization/@types';
import { ReactComponent as ArrowIcon } from 'intakeOptimization/assets/svg/arrow-left-full.svg';
import { ReactComponent as BuildingLine } from 'intakeOptimization/assets/svg/building-line.svg';
import React from 'react';

import { SpanButton } from 'lifestance-ui';

import { LicenseKeyPillbox } from 'intakeOptimization/components';

import { distanceInMilesLabel } from 'intakeOptimization/utils';

import styles from './Address.module.scss';

interface IFacilitiesProps {
  isAdmin?: boolean;
  facilities?: FacilityLocation[] | undefined;
}

const Address: React.FC<React.PropsWithChildren<IFacilitiesProps>> = ({
  facilities,
  isAdmin = false,
}) => (
  <div className={styles.addressContainer}>
    {facilities
      && facilities.map((facility) => {
        const address = `${facility.facility_name} ${facility.address_line1} ${facility.city} ${facility.state}`;
        const mapsURL = `https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}`;
        return (
          <div className={styles.address} key={facility.id}>
            <BuildingLine />
            <div className={styles.heading} data-testId="address">
              Address
            </div>
            {isAdmin && (
              <LicenseKeyPillbox licenseKey={facility.office_key} className={styles.licenseKey} />
            )}
            <div className={styles.addressContainer}>
              {facility.distance_in_miles ? (
                <div className={styles.line} data-testId="distanceInMiles">
                  {distanceInMilesLabel(facility.distance_in_miles)}
                </div>
              ) : (
                ''
              )}
              <div className={styles.line} data-testId="clinic">
                <div>
                  {facility.facility_name}
                  {' '}
                  Clinic
                </div>
              </div>
              <div className={styles.line} data-testId="facilityAddress">
                <div>{facility.address_line1}</div>
              </div>
              <div className={styles.line} data-testId="facilityCity">
                <div>
                  {facility.city}
                  ,&nbsp;
                  {facility.state}
                  ,&nbsp;
                  {facility.postal_code}
                </div>
              </div>
              <div className={styles.line} data-testId="facilityCity">
                <div className={styles.arrow}>
                  <a
                    tabIndex={isAdmin ? -1 : 0}
                    className={styles.link}
                    href={mapsURL}
                    target="_blank"
                    rel="noreferrer"
                    data-testId="Link"
                  >
                    <SpanButton>Get directions</SpanButton>
                    <ArrowIcon />
                  </a>
                </div>
              </div>
            </div>
            {/* TODO: ADD BACK AT LATER DATE */}
            {/* <Link className={clsx(styles.link, styles.line)}>
                Get directions
                <Directions className={styles.directions} />
              </Link> */}
          </div>
        );
      })}
  </div>
);

export default Address;
