import React, { FC } from 'react';
import styles from './TextArea.module.scss';

interface ITextAreaProps {
  placeholder: string;
  children?: React.ReactNode;
  onChange: (e: string) => void;
  onBlur?: () => void
  value: string;
  max: number;
  props?: any;
  fullWidth?: boolean;
}

export const TextArea: FC<React.PropsWithChildren<ITextAreaProps>> = ({
  placeholder,
  children,
  onChange,
  value,
  onBlur,
  max,
  ...props
}) => (
  <textarea
    value={value}
    className={styles.area}
    maxLength={max}
    minLength={0}
    placeholder={placeholder}
    onChange={(e) => onChange(e.target.value)}
    {...props}
  >
    {children}
  </textarea>
);
