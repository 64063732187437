import { combineReducers } from 'redux';

// ABIE
import abie_adminConfigReducer from 'intakeOptimization/store/index';
import obie_adminConfigReducer from 'patientOnboarding/store/index';

export const rootReducer = combineReducers({
  abie: abie_adminConfigReducer,
  obie: obie_adminConfigReducer,
});
