import clsx from 'clsx';
import { ReactComponent as Vector } from 'intakeOptimization/assets/svg/Vector.svg';
import React from 'react';

import styles from './ExpertiseAreas.module.scss';

type Area = {
  id?: string;
  name: string;
};

interface SpecialityAreasProps {
  expertises: Area[];
  populations: Area[];
  interventions: Area[];
}

export const AdminExpertiseAreas: React.FC<React.PropsWithChildren<SpecialityAreasProps>> = ({
  expertises,
  populations,
  interventions,
}) => {
  const formattedData = [
    {
      title: 'Expertises',
      data: expertises,
    },
    {
      title: 'Populations',
      data: populations,
    },
    {
      title: 'Interventions',
      data: interventions,
    },
  ];

  return (
    <div className={styles.expertiseAreas}>
      {(expertises.length > 0 || populations.length > 0 || interventions.length > 0) && (
        <div className={styles.heading}>Focus</div>
      )}
      {expertises.length > 0 || populations.length > 0 || interventions.length > 0 ? (
        <>
          {formattedData.map((section) => section.data.length > 0 ? (
            <div className={styles.card}>
              <div className={styles.cardHeader} data-testId={`${section.title}Header`}>
                {section.title}
              </div>
              <div
                className={clsx(styles.areas, {
                  [styles.fewItems]: section.data.length <= 4,
                })}
              >
                {section.data.map((area) => (
                  <div
                    className={clsx(styles.area, {
                      [styles.oneRow]: section.data.length <= 4,
                    })}
                    key={area.id}
                  >
                    <Vector className={styles.vector} />
                    <div>{area.name}</div>
                  </div>
                ))}
              </div>
            </div>
          ) : null)}
        </>
      ) : null}
    </div>
  );
};
