import { ReactComponent as CloseIcon } from 'patientOnboarding/assets/svg/close-circle.svg';
import React, { FC } from 'react';

import { formatBytes } from 'patientOnboarding/utils/format';

import styles from './InsuranceCard.module.scss';

interface InsuranceCardProps {
  src: string;
  altName: string;
  file: File;
  onClose: () => void;
  className?: string;
  style?: React.CSSProperties;
}

export const InsuranceCardDetail: FC<InsuranceCardProps> = ({
  src,
  altName,
  file,
  onClose,
  className = '',
  style = {},
}) => (
  <div style={style} className={`${styles.previews} ${className}`}>
    <img className={styles.miniature} src={src} alt={altName} />
    <div className={styles.cardContainer}>
      <span className={`${styles.cardDetails} ${styles.cardHeader}`}>{file.name}</span>
      <span className={styles.cardDetails}>{formatBytes(file.size)}</span>
    </div>
    <div className={styles.closeButton} onClick={onClose}>
      <CloseIcon />
    </div>
  </div>
);
