import { createAction } from '@reduxjs/toolkit';

import t, { CancellationReasonsPayload, ReservationPayload } from './types';

export const bookAppointment = createAction<ReservationPayload>(t.BOOK_APPOINTMENT);
export const getAppointmentDetails = createAction<string>(t.GET_APPT_DETAILS);
export const cancelAppointment = createAction<string>(t.CANCEL_APPT);
export const setError = createAction<string>(t.SET_ERROR);
export const getCancellationReasons = createAction(t.GET_CANCELLATION_REASONS);
export const saveSelectedCancellationReason = createAction<CancellationReasonsPayload>(
  t.SAVE_SELECTED_CANCELLATION_REASON,
);
export const postBooking = createAction<ReservationPayload>(t.POST_BOOKING_API);

export {
  updateReservation,
  clearReservation,
  setLoading,
  setAvailability,
  setBookedAppointment,
  setCancelSuccess,
  setAlreadyCancelled,
  setAppointmentOccuredPastFlag,
  setCancellationReasons,
  setCancellationReason,
  setBookingError,
  setBookingErrorModalShow,
  resetReservation,
} from './reducers';
