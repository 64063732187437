const types = {
  GET_PATIENT_DETAILS: '@@IO_ADMIN/GET_PATIENT_DETAILS',
  POST_BOOKING_FOR_Self_INFO: '@@IO_ADMIN/POST_BOOKING_FOR_Self_INFO',
  POST_BOOKING_FOR_Child_INFO: '@@IO_ADMIN/POST_BOOKING_FOR_Child_INFO',
  PUT_BOOKING_FOR_Child_INFO: '@@IO_ADMIN/PUT_BOOKING_FOR_Child_INFO',
  PUT_BOOKING_FOR_Self_INFO: '@@IO_ADMIN/PUT_BOOKING_FOR_Self_INFO',
  RESEND_BOOKING_EMAIL: '@@IO_ADMIN/RESEND_BOOKING_EMAIL',
  TRACK_PATIENT_STATUS: '@@IO_ADMIN/TRACK_PATIENT_STATUS',
  START_INTAKE: '@@IO_ADMIN/START_INTAKE',
  SET_ACCOUNT_EXISTS_NEXT_STEPS: '@@IO_ADMIN/SET_ACCOUNT_EXISTS_NEXT_STEPS',
};

export default types;
