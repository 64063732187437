import clsx from 'clsx';
import { ReactComponent as ChatIcon } from 'patientOnboarding/assets/svg/chat-smile-2-line.svg';
import { ReactComponent as CloseIcon } from 'patientOnboarding/assets/svg/close-white.svg';
import { bookingRoutes } from 'patientOnboarding/routes/constants';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { ContactModal } from 'patientOnboarding/components';

import { getContactInfo } from 'patientOnboarding/store/userGlobal/actions';

import {
  contactInfo as contactInfoSelector,
  obieLicenseKey,
} from 'patientOnboarding/selectors/selectors';

import styles from './ContactBubble.module.scss';

export const ContactBubble: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isValidPath, setIsValidPath] = useState(true);
  const [isWithFooter, setIsWithFooter] = useState(false);
  const [isWithMobileFooter, setIsWitMobileFooter] = useState(false);
  const [locationPath, setLocationPath] = useState('');

  const dispatch = useDispatch();

  const licenseKey = useSelector(obieLicenseKey);
  const contactInfo = useSelector(contactInfoSelector);

  const invalidPath = ['/', bookingRoutes.findCare];
  const pathsWithFooter: string[] = [];
  const location = useLocation();

  useEffect(() => {
    invalidPath.includes(location.pathname) ? setIsValidPath(false) : setIsValidPath(true);
  }, [location.pathname]);

  useEffect(() => {
    if (licenseKey !== -1) {
      dispatch(getContactInfo(licenseKey));
    }
  }, [licenseKey]);

  useEffect(() => {
    pathsWithFooter.includes(location.pathname) || window.contactBubble?.offsetBottom
      ? setIsWithFooter(true)
      : setIsWithFooter(false);
  }, [location.pathname]);

  useEffect(() => {
    if (locationPath === '' && isModalOpen) setLocationPath(location.pathname);
  }, [isModalOpen]);

  const triggerModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = (callback: () => void) => {
    setIsModalOpen(false);
    setLocationPath('');
    callback?.();
  };
  if (isValidPath) {
    return (
      <div
        className={clsx(styles.mainContainer, {
          [styles.withFooter]: isWithFooter,
        })}
      >
        <div onClick={triggerModalOpen} className={styles.bubble}>
          {isModalOpen ? <CloseIcon className={styles.closeChatIcon} /> : <ChatIcon />}
        </div>
        {isModalOpen && (
          <ContactModal
            closeModal={closeModal}
            isBubbleOpen={isModalOpen}
            locationPath={locationPath}
            contactInfo={contactInfo}
          />
        )}
      </div>
    );
  }
  return null;
};
