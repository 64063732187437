import clsx from 'clsx';
import { bookingRoutes } from 'patientOnboarding/routes/constants';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

import { Button, Dropdown, DropdownSingleSelect, Input, Label, Toast } from 'lifestance-ui';

import {
  getClinicians,
  getDisplayedClinicians,
  getFilteredCliniciansObie,
} from 'patientOnboarding/store/cliniciansGlobal/slice';
import {
  getCliniciansByZipCode,
  getInsuranceList,
  setErrors,
  setValidZipCode,
  updatePreferences,
  validateZipCode,
} from 'patientOnboarding/store/preferences/actions';

import {
  contactInfo as contactInfoSelector,
  lifestanceState,
  obieEnabled,
  obiePreferencesData,
} from 'patientOnboarding/selectors/selectors';

import { useInsuranceValidation } from 'patientOnboarding/hooks';

import {
  SELF_PAY_VALUE,
  cleanNullProperties,
  getAge,
  getContactInfoByState,
  getDateInfo,
  getFormatForMatches,
  stateAbbrToName,
  useDevice,
  validateDateFormat,
} from 'patientOnboarding/utils';

import { NoProviders } from '../NoProvidersBox/NoProvidersBox';
import styles from './UpdateCriteria.module.scss';

interface IUpdateCriteriaProps {
  handleOpen: (value: boolean) => void;
  onContinue?: () => void;
}

interface PreferencesData {
  typeOfCare: string;
  zipCode: string;
  insuranceCompany: string;
  childAge?: string;
  gender?: string[];
  preliminaryDOB: string;
  preliminaryAge?: string;
  paymentMethod: string;
  age?: string;
  entireState?: string;
  distance?: string;
  modality?: string[];
}

export const UpdateCriteria: FC<React.PropsWithChildren<IUpdateCriteriaProps>> = ({
  handleOpen,
  onContinue,
}) => {
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isChildrenOption, setIsChildrenOption] = useState(false);
  const [isAdultOption, setIsAdultOption] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [patientAge, setPatientAge] = useState<null | number>(null);
  const [DOBError, setDOBError] = useState('Please enter a valid date of birth');
  const [inputDelay, setInputDelay] = useState(0);
  const preferences = useSelector(obiePreferencesData);
  const {
    errors,
    isZipCodeValid,
    zipCode,
    loading,
    loadingCountByZip,
    cliniciansAvailableOnZipCode,
  } = preferences;
  const state = preferences.state;
  const [updatedPreferences, setUpdatedPreferences] = useState<PreferencesData>(preferences);
  const patientDOB = updatedPreferences?.preliminaryDOB;
  const selfPay = updatedPreferences.paymentMethod === SELF_PAY_VALUE;
  const noOption = 'I don’t see my insurance';
  const [showExtendedForm, setShowExtendedForm] = useState(true);
  const [showForm, setShowForm] = useState(true);
  const licenseKey = preferences.licenseKey;
  const contactInfo = useSelector(contactInfoSelector);
  const contactInfoByState = getContactInfoByState(contactInfo, state, licenseKey);
  const contactNumber = contactInfoByState?.intake_call_in_number;
  const [formFitContent, setFormFitContent] = useState(false);
  const [dynamicHeight, setDynamicHeight] = useState<number | string>();
  const enabledObie = useSelector(obieEnabled);
  const enabledState = useSelector(lifestanceState);
  const [information, setInformation] = useState(true);
  const [lifestanceInfo, setLifestanceInfo] = useState(false);
  const [showMessage, setShowMessage] = useState('');
  const [touchedInput, setTouchedInput] = useState(false);

  const { insuranceOpts, isInsuranceValidOnState } = useInsuranceValidation({
    updatedPreferences,
    clinicianState: state,
    insuranceCompanies: preferences.filters.insuranceCompanies,
  });

  useEffect(() => {
    setShowExtendedForm(true);
    setShowForm(true);
    setLifestanceInfo(false);
    setInformation(false);
    setShowMessage('');
    setTouchedInput(false);
  }, []);

  useEffect(() => {
    if (zipCode.length === 5) {
      const isChildrenInclude = preferences.typeOfCare.toLowerCase().includes('child');
      setIsChildrenOption(isChildrenInclude);
      setErrorMessage(errors.zipCode);
      setShowForm(false);
    }
  }, [preferences.zipCode]);

  useEffect(() => {
    if (!isZipCodeValid || zipCode.length !== 5) {
      setShowExtendedForm(false);
      setUpdatedPreferences({
        ...updatedPreferences,
        typeOfCare: '',
        insuranceCompany: '',
        paymentMethod: '',
        preliminaryDOB: '',
      });
      setDOBError('');
      setAgeError(false);
    }
    if (updatedPreferences.zipCode.length < 5) {
      setErrorMessage('');
      setInformation(false);
      setLifestanceInfo(false);
      setShowMessage('');
      setUpdatedPreferences({
        ...updatedPreferences,
        entireState: 'false',
        distance: '60',
        modality: ['video_visit', 'in_office'],
      });
    }
  }, [updatedPreferences.zipCode]);

  useEffect(() => {
    if (errors.zipCode) {
      setErrorMessage(errors.zipCode);
      setInformation(false);
    }
  }, [errors.zipCode]);

  const readZipcode = (value: string) => {
    setUpdatedPreferences({ ...updatedPreferences, zipCode: value });
    setTouchedInput(true);
    if (value.length === 5) {
      dispatch(validateZipCode(value, false));
      dispatch(getCliniciansByZipCode(value, false));
      setErrorMessage('');
      dispatch(setErrors({ zipCode: '' }));
    }
    if (value.length < 5) {
      dispatch(setValidZipCode(false));
      setInformation(false);
      setShowExtendedForm(false);
      setShowForm(false);
      setLifestanceInfo(false);
      setShowMessage('');
    }
  };

  useEffect(() => {
    if (!enabledState) {
      setLifestanceInfo(true);
    } else if (!enabledObie) {
      setInformation(true);
    }
  }, [enabledObie, enabledState, lifestanceInfo, information]);

  useEffect(() => {
    if (touchedInput) {
      if (
        updatedPreferences?.zipCode?.length === 5
        && errors?.zipCode?.length === 0
        && !loading
        && !loadingCountByZip
      ) {
        if (enabledState && enabledObie && cliniciansAvailableOnZipCode === 0) {
          setShowMessage('No providers');
        } else if (enabledState && !enabledObie && information) {
          setShowMessage('No OBIE');
        } else if (!enabledState && lifestanceInfo) {
          setShowMessage('Non-LS');
        } else {
          setShowMessage('');
        }
      } else if (errors.zipCode.length > 0) {
        setShowMessage('');
      }
    }
    return () => {
      setShowMessage('');
    };
  }, [
    updatedPreferences.zipCode,
    showMessage,
    loading,
    loadingCountByZip,
    lifestanceInfo,
    information,
  ]);

  const handleZipCodeBlur = (value: string) => {
    setUpdatedPreferences({ ...updatedPreferences, zipCode: value });
    if (value.length < 5) {
      dispatch(setValidZipCode(false));
      setErrorMessage('Enter a valid 5 digit zip code');
      setShowExtendedForm(false);
      setShowForm(false);
      setShowMessage('');
    }
  };

  const handleInsurance = (
    insuranceCompany: string,
    preferenceToUpdate: Record<string, unknown>,
  ) => {
    switch (insuranceCompany) {
      case SELF_PAY_VALUE:
        preferenceToUpdate = {
          paymentMethod: SELF_PAY_VALUE,
          insuranceCompany: 'Self-pay',
        };
        break;
      case noOption:
        preferenceToUpdate = {
          paymentMethod: SELF_PAY_VALUE,
          insuranceCompany: noOption,
        };
        break;
      default: {
        preferenceToUpdate = {
          paymentMethod: 'insurance',
          insuranceCompany,
        };
      }
    }
    setUpdatedPreferences({
      ...updatedPreferences,
      ...preferenceToUpdate,
    });
  };

  const handleTypeOfCare = (typeOfCare: string) => {
    const isChildrenInclude = typeOfCare.toLowerCase().includes('child');
    setIsChildrenOption(isChildrenInclude);
    setIsAdultOption(!isChildrenInclude);
    setUpdatedPreferences({ ...updatedPreferences, typeOfCare });
    dispatch(getInsuranceList({ typeOfCare, zipCode: updatedPreferences.zipCode }));
    if (updatedPreferences.insuranceCompany === noOption) {
      dispatch(updatePreferences({ ...updatedPreferences, insuranceCompany: SELF_PAY_VALUE }));
    }
  };

  const handleDOBChange = (e: ChangeEvent<HTMLInputElement>) => {
    const patientDateOfBirth = e.target.value;
    setUpdatedPreferences({ ...updatedPreferences, preliminaryDOB: patientDateOfBirth });
  };

  const handleAgeofChild = (ageOfChild: number) => {
    if (inputDelay) {
      clearTimeout(inputDelay);
    }
    setInputDelay(300);
    setTimeout(() => {
      dispatch(updatePreferences({ childAge: ageOfChild.toString(), age: '' }));
    }, 300);
  };

  useEffect(() => {
    ageVerification();
  }, [isAdultOption, DOBError, ageError]);

  const ageVerification = () => {
    if (patientDOB.length > 0 && patientDOB !== '__/__/____') {
      const value = patientDOB;
      const birth = value.replace(/[/_]/g, '');
      const maxAge = 150;
      if (birth.length === 8) {
        const { month, day, year } = getDateInfo(value);
        if (validateDateFormat(day, month, year)) {
          const age = getAge(year, month, day);
          if (isAdultOption) {
            if (age >= 18 && age < maxAge) {
              setAgeError(false);
              setPatientAge(age);
              setUpdatedPreferences({
                ...updatedPreferences,
                preliminaryAge: `${age}`,
                age: `${age}`,
              });
            } else {
              setDOBError('For anyone under 18 years old, please select a child appointment type.');
              setAgeError(true);
              setPatientAge(age);
              setUpdatedPreferences({
                ...updatedPreferences,
                preliminaryAge: `${age}`,
                age: `${age}`,
              });
            }
          } else if (age < 3) {
            setAgeError(true);
            setDOBError(
              'Our clinicians are trained to care for children 3 years old and up. Please call us if you have any questions.',
            );
          } else if (age && age >= 18) {
            setAgeError(true);
            setDOBError('For anyone over 18 years old, please select an adult appointment type.');
          } else {
            handleAgeofChild(age);
            setPatientAge(age);
            setUpdatedPreferences({
              ...updatedPreferences,
              preliminaryAge: `${age}`,
              age: `${age}`,
            });
            setAgeError(false);
          }
          if (age >= maxAge) {
            setAgeError(true);
            setDOBError('Please enter a valid birth');
          }
        } else {
          setAgeError(true);
          setDOBError('Please enter a valid date of birth');
        }
      } else {
        setAgeError(true);
        setDOBError('Please enter a valid date of birth');
      }
    }
  };

  useEffect(() => {
    if (preferences.typeOfCare !== '') {
      const isChildrenInclude = preferences.typeOfCare.toLowerCase().includes('child');
      setIsChildrenOption(isChildrenInclude);
      setIsAdultOption(!isChildrenInclude);
      if (preferences?.path_history[preferences?.path_history?.length - 2]?.includes('provider')) {
        ageVerification();
      }
    }
  }, [preferences.typeOfCare]);

  const handleViewMatches = () => {
    onContinue?.();
    if (isZipCodeValid && updatedPreferences?.zipCode?.length === 5 && showExtendedForm) {
      dispatch(validateZipCode(updatedPreferences.zipCode));
      dispatch(
        updatePreferences({
          ...updatedPreferences,
          state: preferences.state,
          city: preferences.city,
          filters: preferences.filters,
          entireState: 'false',
          distance: '60',
          modality: ['video_visit', 'in_office'],
        }),
      );
      const preferencesForMatches = getFormatForMatches(updatedPreferences);
      dispatch(getClinicians({ activeFilters: cleanNullProperties(preferencesForMatches) }));
      const cleanedFilters = cleanNullProperties(preferencesForMatches);
      const queryString = Object.keys(cleanedFilters)
        .map((key) => `${key}=${encodeURIComponent(cleanedFilters[key])}`)
        .join('&');
      handleOpen(false);
      dispatch(getFilteredCliniciansObie());
      dispatch(getDisplayedClinicians({ page: 1 }));
      navigate(`${bookingRoutes.providerMatches}?${queryString}`, {
        state: { search: queryString },
      });
    } else if (preferences.filters.typesOfCare.length === 0) {
      dispatch(
        updatePreferences({
          isZipCodeValid: false,
        }),
      );
      dispatch(setValidZipCode(false));
      setShowForm(true);
    } else {
      setShowExtendedForm(true);
    }
  };

  const validateOptions = () => {
    if ((showExtendedForm && ageError) || !isZipCodeValid) {
      return true;
    }
    if (
      showExtendedForm
      && updatedPreferences.typeOfCare.length === 0
      && updatedPreferences.insuranceCompany.length === 0
    ) {
      return true;
    }
    if ((showExtendedForm && patientDOB.length === 0) || patientDOB === '__/__/____') {
      return true;
    }
    if (showExtendedForm && updatedPreferences.paymentMethod === '') {
      return true;
    }
    return false;
  };

  const transition = useSpring<any>({
    from: { opacity: 0, height: 0, overflow: 'hidden' },
    to: { opacity: 1, height: dynamicHeight, overflow: 'visible' },
    reverse: !showExtendedForm,
    config: { tension: 180, friction: 14 },
  });

  useEffect(() => {
    if (showForm) {
      // We need to add the questionsContainer class to the form
      // so the form can grow when the error messages appear. (When the animation ends)
      setTimeout(() => {
        setFormFitContent(true);
      }, 1000);
    } else {
      setFormFitContent(false);
      setDOBError('');
      setAgeError(false);
    }
  }, [showForm]);

  useEffect(() => {
    const currHeight = !isInsuranceValidOnState ? 492 : 325;
    const currHeightWithError = ageError ? 'auto' : currHeight;
    setDynamicHeight(currHeightWithError);
  }, [ageError, isInsuranceValidOnState]);

  const renderForm = () => (
    <animated.div style={transition}>
      <>
        <div className={styles.questions}>
          <Label>What type of care are you looking for?</Label>
          <div className={styles.unsure}>
            <a
              href="http://www.lifestance.com/services"
              rel="noreferrer"
              target="_blank"
              className={styles.unsure}
              style={{ textDecoration: 'none' }}
            >
              Not sure
            </a>
          </div>
        </div>
        {isMobile ? (
          <Dropdown
            options={preferences.filters?.typesOfCare}
            placeholder="Select an option"
            onChange={handleTypeOfCare}
            title="Type of care"
            icon="ArrowBlackIcon"
            value={updatedPreferences.typeOfCare}
            sideWays
            testId="typeOfCare"
          />
        ) : (
          <DropdownSingleSelect
            options={preferences.filters?.typesOfCare}
            placeholder="Select an option"
            onChange={handleTypeOfCare}
            value={updatedPreferences.typeOfCare}
            testId="typeOfCare"
          />
        )}
        <div className={styles.questionsContainer}>
          <div className={styles.questions}>
            <Label htmlFor="dobField">What is the patient&apos;s date of birth?</Label>
          </div>
          <div>
            <InputMask
              tabIndex={0}
              data-cy="inputDateOfBirth"
              mask="99/99/9999"
              placeholder="MM/DD/YYYY"
              className={clsx(styles.input, {
                [styles.error]: ageError,
                [styles.disabled]: !isChildrenOption && !isAdultOption,
              })}
              onChange={handleDOBChange}
              onBlur={() => ageVerification()}
              value={patientDOB}
              inputMode="numeric"
              id="dobField"
            />
            {ageError && (
              <p data-testId="errorMessageParentDateOfBirth" className={styles.errorText}>
                {DOBError}
              </p>
            )}
          </div>
        </div>
        <div className={styles.questions}>
          <Label htmlFor="paymentMethod">How would you like to pay?</Label>
        </div>
        {isMobile ? (
          <Dropdown
            options={insuranceOpts}
            placeholder="Select an option or self-pay"
            onChange={handleInsurance}
            title="Insurance"
            value={selfPay ? updatedPreferences.paymentMethod : updatedPreferences.insuranceCompany}
            sideWays
            testId="insuranceCompany"
          />
        ) : (
          <DropdownSingleSelect
            options={insuranceOpts}
            placeholder="Select an option or self-pay"
            onChange={handleInsurance}
            value={selfPay ? updatedPreferences.paymentMethod : updatedPreferences.insuranceCompany}
            testId="insuranceCompany"
          />
        )}
        <Toast
          variant="information"
          label={`Do you have ${updatedPreferences.insuranceCompany} Medicaid?`}
          description={`We are not currently accepting Medicaid benefits in ${stateAbbrToName(
            state,
          )}.`}
          containerClassName={clsx(styles.toast, {
            [styles.showToast]: !isInsuranceValidOnState,
          })}
        />
      </>
    </animated.div>
  );

  return (
    <div
      className={clsx(styles.container, {
        [styles.expandedContainer]: showExtendedForm || formFitContent,
      })}
    >
      <div className={styles.header}>Let&apos;s update your search.</div>
      <div>
        <Label className={styles.title} htmlFor="zipCode">
          What is your zip code?
        </Label>
        <Input
          type="zipCode"
          placeholder="e.g. 12345"
          onChange={readZipcode}
          onBlur={(e) => handleZipCodeBlur(e.target.value)}
          error={errorMessage}
          value={updatedPreferences.zipCode}
          defaultValue={zipCode}
          testId="zipCode"
          id="zipCode"
          onFocus={() => setErrorMessage('')}
        />
      </div>
      {showExtendedForm && renderForm()}
      {showMessage === 'No providers' && !loading && (
        <NoProviders
          state={state}
          showForm={!showForm}
          phoneNumber={contactNumber}
          enabledState={enabledState}
          enabledObie={enabledObie}
        />
      )}
      {showMessage === 'No OBIE' && !loading && (
        <NoProviders
          state={state}
          showForm={!showForm}
          phoneNumber={contactNumber}
          enabledState={enabledState}
          enabledObie={enabledObie}
        />
      )}
      {showMessage === 'Non-LS' && !loading && (
        <NoProviders
          state={state}
          showForm={!showForm}
          phoneNumber={contactNumber}
          enabledObie={enabledObie}
          enabledState={enabledState}
        />
      )}
      {showMessage !== '' && !loading ? null : (
        <div className={styles.button}>
          <Button
            fullWidth
            onClick={handleViewMatches}
            disabled={validateOptions()}
            isLoading={loading}
          >
            Continue
          </Button>
        </div>
      )}
    </div>
  );
};
