import clsx from 'clsx';
import React, { FC } from 'react';

import styles from '../Filter.module.scss';
import { Option } from '../types';
import { isChecked } from './utils';

interface ModalityProps {
  name: string;
  options: Option[];
  selected: string[];
  onSelect: (option: string) => void;
  className?: string;
  testId: string;
}

const Modality: FC<ModalityProps> = ({ name, options, selected, onSelect, className, testId }) => {
  const handleModality = (newOption: any) => {
    onSelect(newOption);
  };

  return (
    <>
      {options?.map((option) => {
        const newOption = option as Option;
        return (
          <div
            className={clsx(className, styles.selectModality, [name], {
              [styles.selectedModality]: isChecked(selected, newOption.value),
            })}
          >
            <input
              type="checkbox"
              id={newOption.value}
              value={newOption.value}
              key={`checkboxFilter${newOption.value}`}
              onChange={(e) => handleModality(e.currentTarget.value)}
              checked={isChecked(selected, newOption?.value)}
              data-testId={`option${testId}${newOption.value}`}
            />
            <label className={styles.label} htmlFor={newOption.value}>
              <div className={styles.modalityCheckbox} data-cy={`${name}-${newOption.value}`} />
              <span className={styles.modalitySpan}>
                {newOption.label}
                <span className={styles.visits}> visits</span>
              </span>
            </label>
          </div>
        );
      })}
    </>
  );
};

export default Modality;
