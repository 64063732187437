import { createAction } from '@reduxjs/toolkit';

import { searchesActions } from './reducers';
import t from './types';

export const {
  resetMatches,
  loadMatches,
  setSearchTerm,
  clearSearchTerm,
  setLoading,
  setError,
  setPreviousPath,
} = searchesActions;

export const getClinicianByName = createAction(
  t.GET_PROVIDER_BY_NAME,
  (
    providerName: string,
    zipCode: string,
    age: string,
    typeOfCare: string,
    paymentType: string,
    insurance: string,
  ) => ({
    payload: {
      providerName,
      zipCode,
      age,
      typeOfCare,
      paymentType,
      insurance,
    },
  }),
);
