import clsx from 'clsx';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { ReactComponent as ArrowDown } from 'lifestance-ui/assets/svg/arrow-down-s-line.svg';
import { ReactComponent as ArrowUp } from 'lifestance-ui/assets/svg/arrow-up-s-line.svg';

import styles from './SlideCard.module.scss';

interface SlideProps {
  selected?: boolean;
  onClick: (id: string, event?: React.MouseEvent<HTMLDivElement>) => void;
  title: string;
  id: string;
  disableTabNav?: boolean;
}

export const SlideCard: React.FC<PropsWithChildren<SlideProps>> = ({
  selected = false,
  onClick,
  children,
  title,
  id,
  disableTabNav = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [currentHeight, setCurrentHeight] = useState('initial' as number | string);
  const [loaded, setLoaded] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onClick(event.currentTarget.id, event);
  };

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight as number);
      setLoaded(true);
    }
  }, [ref]);

  useEffect(() => {
    if (loaded && height) {
      if (selected) {
        setCurrentHeight(height + 20);
      } else {
        setCurrentHeight(0);
      }
    }
  }, [loaded, ref, selected, height]);

  return (
    <div className={clsx(styles.slideCardContainer)}>
      <div
        id={id}
        tabIndex={disableTabNav ? -1 : 0}
        role="button"
        className={clsx(styles.header, {
          [styles.active]: selected,
        })}
        onClick={handleClick}
        onKeyDown={(event) => {
          event.preventDefault();
          if (event.key === 'Enter') {
            onClick(event.currentTarget.id);
          }
        }}
      >
        <span className={styles.title}>{title}</span>
        {selected ? <ArrowUp /> : <ArrowDown />}
      </div>
      <div
        ref={ref}
        style={{
          height: currentHeight,
        }}
        className={styles.content}
      >
        {children}
      </div>
    </div>
  );
};
