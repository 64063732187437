const types = {
  VIEW_CLINICIAN_MODALITIES: '@@clinician/VIEW_CLINICIAN_MODALITIES',
  UPDATE_MODALITIES: '@@clinician/UPDATE_MODALITIES',
  VIEW_CLINICIAN_LOCATIONS: '@@clinician/VIEW_CLINICIAN_LOCATIONS',
  UPDATE_CLINICIAN_LOCATIONS: '@@clinician/UPDATE_CLINICIAN_LOCATIONS',
  UPDATE_CLINICIAN: '@@clinician/UPDATE_CLINICIAN',
  GET_CLINICIAN_AVAILABILITIES: '@@clinician/GET_CLINICIAN_AVAILABILITIES',
};

export default types;
