import featureFlags from 'intakeOptimization/analytics/featureFlags';

import { useDevice } from '../utils';

export const useFeature = (featureName) => {
  const { isMobile, isSmallTablet, isTablet } = useDevice();
  const desktopDisabledFeatures = featureFlags
    .filter((featureFlag) => featureFlag.desktop)
    .map((featureFlag) => featureFlag.name);
  const mobileDisabledFeatures = featureFlags
    .filter((featureFlag) => featureFlag.mobile)
    .map((featureFlag) => featureFlag.name);

  if (isMobile || isSmallTablet || isTablet) {
    return !mobileDisabledFeatures.includes(featureName);
  }
  return !desktopDisabledFeatures.includes(featureName);
};
