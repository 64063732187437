export const bookingRoutes = {
  findCare: 'booking',
  matchCriteria: 'match-criteria',
  clinicianProfile: 'provider/:id',
  providerMatches: '/find-care/booking/provider-matches',
  search: 'booking/search',
  followUp: 'booking/followup',
  scheduling: 'booking/scheduling',
};

export const intakeRoutes = {
  intake: '',
  register: '/register',
  userExists: '/existing',
  account: '/account',
  demographics: '/demographics',
  reason: '/reason',
  address: '/address',
  emergencyContact: '/emergency-contact',
  review: '/review',
  email: 'email',
  emailVerified: 'email-verified',
  emailVerificationError: 'email-error',
  emailTimedOut: 'email-timed-out',
  insuranceDetails: '/insurance-details',
  insuranceCard: '/insurance-card',
  rematch: 'reason-rematch',
  rematchResults: 'reason/rematch/results',
  rematchClinician: 'reason/rematch/:id',
  confirmation: '/confirmation',
  cancel: 'cancellation/confirm',
  cancelSuccess: 'cancel-success',
  cancelKeep: 'cancel-keep',
  cancelError: 'cancel-error',
  cancelExclusion: 'cancel-excluded',
  alreadyCancelled: 'already-cancelled',
  appointmentOccuredPastFlag: 'appointment-occured-past',
  appointmentError: 'appointment-error',
  bookAppointment: 'book-appointment',
  emailLanding: 'landing-email',
  suicidalPrevention: 'prevention',
  cancellationReasons: 'cancellation',
  paymentMethod: '/payment-method',
};

export const adminRoutes = {
  patientType: 'patient',
  filters: 'filters',
  search: 'booking-search',
  searchResults: 'results',
  consents: 'consents', // INTAKE
  review: 'review', // INTAKE
  clinicianProfile: 'provider/:id', // INTAKE
  confirmation: 'confirmation', // INTAKE
  appointmentError: 'appointment-error',
  bookAppointment: 'book-appointment', // INTAKE
  accountHolder: 'account/*', // INTAKE
  bookingForSelf: 'account-bookingForSelf', // INTAKE
  accountHolderParent: 'account-parent', // INTAKE
  accountHolderChild: 'account-child', // INTAKE
  physicalAddress: 'account-physicalAddress', // INTAKE
  accountInsurance: 'account-accountInsurance', // INTAKE
  emailVerification: 'account-emailVerification', // INTAKE
  insuranceCard: 'insurance-card', // INTAKE
  appointmentCancellation: 'cancellation', // INTAKE
  accountHolderExists: 'existingPatient', // INTAKE
};

export const establishedPatientsRoutes = {
  followUp: 'booking/followup',
  existing: 'existing',
  provider: 'existing/provider-selection',
  modality: 'existing/modality',
  location: 'existing/location',
  availableAppointments: 'existing/available-appointments',
  confirmPaymentInfo: 'existing/confirm-payment-info',
  confirmation: 'existing/confirmed',
};
