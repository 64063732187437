import clsx from 'clsx';
import { FacilityLocation } from 'patientOnboarding/@types';
import avatar from 'patientOnboarding/assets/img/avatar.jpg';
import { ReactComponent as AlertLine } from 'patientOnboarding/assets/svg/alert-line.svg';
import { ReactComponent as ArrowLeft } from 'patientOnboarding/assets/svg/arrow-left-black.svg';
import { ReactComponent as LeftArrowIcon } from 'patientOnboarding/assets/svg/arrow-left-line.svg';
import { ReactComponent as UsersIcon } from 'patientOnboarding/assets/svg/user-group.svg';
import { ReactComponent as UserVoice } from 'patientOnboarding/assets/svg/user-voice-fill.svg';
import { bookingRoutes, intakeRoutes } from 'patientOnboarding/routes/absolutes';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Alert, Button, CircularLoading, Container, ContextModal, SpanButton } from 'lifestance-ui';

import { BookingModal, FilterWall, Schedule, ScheduleCriteria } from 'patientOnboarding/components';
import modals from 'patientOnboarding/components/zipCodeErrorModals';
import NoClinicians from 'patientOnboarding/views/MatchCriteria/components/NoCliniciansModal/NoCliniciansModal';
import NoServiceStateModal from 'patientOnboarding/views/MatchCriteria/components/NoServiceStateModal/NoServiceStateModal';
import PleaseCallModal from 'patientOnboarding/views/MatchCriteria/components/PleaseCallModal/PleaseCallModal';

import { getLicenseKeyRules } from 'patientOnboarding/store/insurance/actions';
import { loadClinicianProfile } from 'patientOnboarding/store/matches/reducers';
import {
  removeClinicianProfileBack,
  updatePreferences,
} from 'patientOnboarding/store/preferences/actions';
import { updateReservation } from 'patientOnboarding/store/reservationGlobal/actions';
import { getContactInfo } from 'patientOnboarding/store/userGlobal/actions';

import {
  abiePreferencesData,
  contactNumber,
  hasClinicianSelector,
  isAbie,
  lifestanceState,
  obieEnabled,
  obieOrAbiePreferencesSelector,
  obiePreferencesData,
  obieTypesOfCareselector,
  otherProviders,
  reservationData,
  reservationLicenseKey,
  selectedClinicianSelector,
  selectedFacilityData,
} from 'patientOnboarding/selectors/selectors';

import { useMediaQuery } from 'patientOnboarding/hooks';

import {
  getPreviousPathname,
  simpleKeyboardControl,
  sortAvailabilities,
  stateAbbrToName,
  useDevice,
} from 'patientOnboarding/utils';

import styles from './ClinicianProfile.module.scss';
import { About } from './components/About/About';
import Address from './components/Address/Address';
import Education from './components/Education/Education';
import ExistingPatientsBox from './components/ExistingPatientsBox/ExistingPatientsBox';
import { ExpertiseAreas } from './components/ExpertiseAreas/ExpertiseAreas';
import { FAQ } from './components/FAQ/FrequentlyAskedQuestions';
import { Finances } from './components/Finances/Finances';
import NewPatientBox from './components/NewPatientBox/NewPatientBox';
import Providers from './components/Providers/Providers';
import Supervisor from './components/Supervisor/Supervisor';
import { TypesOfCareMeta } from './typesOfCareMeta';

interface IClinicianProfileProps {
  back: string;
  isExistingUser: boolean;
  onOpenFilterWall: (value: boolean) => void;
  onNext: string;
  customHeader?: ReactNode;
  isFilterWall: boolean;
}

export const ClinicianProfile: FC<IClinicianProfileProps> = ({
  back,
  isExistingUser,
  onOpenFilterWall,
  onNext,
  customHeader,
  isFilterWall,
}) => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const { isTablet, isDesktop } = useDevice();
  const isTabletMobile = useMediaQuery('(max-width: 1024px)');
  const [openMobileCriteria, setOpenMobileCriteria] = useState(false);
  const [showPleaseCallModal, setShowPleaseCallModal] = useState(false);
  const [hasAppointment, setHasAppointment] = useState(isExistingUser);
  const [isFilterWallOpen, setIsFilterWallOpen] = useState(true);
  const historyState = useLocation().state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useSelector(selectedClinicianSelector);
  const reservation = useSelector(reservationData);
  const otherClinicians = useSelector(otherProviders);
  const facilities = profile?.facility_location;
  const hasClinician = useSelector(hasClinicianSelector);
  const typeOfCare = useSelector(obieTypesOfCareselector);
  const typesOfCares = profile?.type_of_cares;
  const isAdmin = useSelector(isAbie);
  const { zipCode } = useSelector(obieOrAbiePreferencesSelector);
  const phoneNumber = useSelector(contactNumber);
  const preferences = useSelector(obiePreferencesData);
  const enabledObie = useSelector(obieEnabled);
  const enabledState = useSelector(lifestanceState);
  const [modalToShow, setModalToShow] = useState('');
  const abiePreferences = useSelector(abiePreferencesData);
  const prevProfileId = useRef<number>(0);
  const licenseKey = useSelector(reservationLicenseKey);
  const selectedFacility = useSelector(selectedFacilityData);
  const getPrimaryLocation: FacilityLocation = facilities?.find(
    (el: FacilityLocation) => el.primary_location,
  );
  const [openBookingModal, setOpenBookingModal] = useState(false);
  const isColdLanding = preferences?.location_back.length === 0;
  const initialView = isColdLanding ? 0 : 2;
  const [view, setView] = useState(initialView);
  const [supervisedPrefix, setSupervisedPrefix] = useState('');
  const financesRef = useRef<HTMLDivElement>(null);
  const { state, loading } = preferences;

  const getFacilityLocationIndex = () => {
    let index = 0;
    for (let i = 0; i <= profile?.facility_location?.length; i += 1) {
      if (profile?.facility_location[i]?.clinician_availabilities.length > 0) {
        index = i;
        break;
      }
    }
    return index;
  };

  const getEmployedMessage = () => {
    // non-NJ, non-CA, non-NY located clinician license_keys
    let employedByMessage = '';
    if (![148376, 148377, 143255, 144896, 149036, 142175].includes(profile?.license_key)) {
      employedByMessage = 'This provider is employed by a LifeStance Health supported practice.';
    }
    //  NJ located clinician
    if ([148376].includes(profile?.license_key)) {
      employedByMessage = 'This provider is employed by New Jersey Behavioral Health, a LifeStance Health supported practice.';
    }

    return employedByMessage;
  };

  // View in ScheduleCriteria for Provider Location.
  const availabilites = profile?.facility_location[getFacilityLocationIndex()];
  const facilityClinicianAvailabilities = availabilites?.clinician_availabilities
    ? sortAvailabilities(availabilites.clinician_availabilities)
    : [];

  const acceptingNewPatients = () => {
    if (facilities?.length === 0 || facilityClinicianAvailabilities?.length === 0 || !typeOfCare) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (getPreviousPathname(preferences).includes('intake') && isColdLanding) {
      navigate(0); // If it comes from intake and it was a cold landing we refresh
    }
  }, [preferences.path_history]);

  useEffect(() => {
    // Todo add existing existing user condition.
    if (historyState?.fromConfirmation) {
      setHasAppointment(false);
      window.contactBubble = {
        offsetBottom: 0,
      };
    }
  });

  useEffect(() => {
    if (!isExistingUser && !hasClinician) {
      onOpenFilterWall(true);
    } else {
      onOpenFilterWall(false);
    }
  }, [hasClinician]);

  useEffect(() => {
    if (isFilterWall) {
      setIsFilterWallOpen(false);
      // dispatch(setColdUser(true));
    }
  }, [isFilterWall]);

  useEffect(() => {
    if (zipCode !== '' && !isFilterWall && typeOfCare) {
      setHasAppointment(true);
      window.contactBubble = {
        offsetBottom: 100,
      };
    }
  }, [zipCode, isFilterWall, typeOfCare]);

  const handleOpenCalendar = () => {
    setOpenCalendar(true);
  };

  const handleMobileReservation = () => {
    if (!isAdmin) {
      setOpenMobileCriteria(true);
    } else {
      setOpenCalendar(true);
    }
  };

  const handleCloseCalendar = () => {
    setOpenCalendar(false);
    if (isAdmin) {
      // Focus button on calendar close
      setTimeout(() => {
        (document.querySelector('#seeMoreAvailability') as HTMLDivElement).focus();
      }, 500);
    }
  };

  const handleCancelModal = () => {
    // cancel profile modal
  };

  const clearSelectedClinician = () => {
    dispatch(loadClinicianProfile(null));
    dispatch(removeClinicianProfileBack());
  };

  const handleClose = (val: boolean) => {
    onOpenFilterWall(val);
    dispatch(
      updatePreferences({
        zipCode: '',
        age: '',
        preliminaryAge: '',
      }),
    );
  };

  useEffect(() => {
    if (isColdLanding) {
      dispatch(updatePreferences({ path_history: [location.pathname] }));
    }
  }, []);

  const handleBadTypeOfCare = (toc: string) => {
    if (toc.toLowerCase().includes('child')) {
      dispatch(
        updatePreferences({
          childAge: preferences?.age,
        }),
      );
    } else {
      dispatch(
        updatePreferences({
          childAge: '',
        }),
      );
    }
    dispatch(updatePreferences({ typeOfCare: toc }));
    dispatch(updateReservation({ selectedFacility: profile.facility_location[0] }));
    if (!isTabletMobile) {
      onOpenFilterWall(false);
      setOpenCalendar(true);
      setOpenMobileCriteria(false);
    }
  };

  const externalServices = () => (
    <div className={styles.externalServices}>
      <span>
        <UsersIcon />
      </span>
      <div className={styles.pillboxContainer}>
        {profile?.type_of_cares?.map((typeOfCares: string) => (
          <div className={styles.pillbox}>
            <a
              tabIndex={isAdmin ? -1 : 0}
              href={TypesOfCareMeta[typeOfCares]?.url}
              target="_blank"
              rel="noreferrer"
            >
              {typeOfCares}
            </a>
          </div>
        ))}
      </div>
    </div>
  );

  const renderFilterWall = () => (
    <div className={styles.modal}>
      <ContextModal isOpen={isFilterWall} onClose={handleClose}>
        <FilterWall
          handleOpen={onOpenFilterWall}
          handleBadTypeOfCare={handleBadTypeOfCare}
          setModalToShow={setModalToShow}
          closeFilterWall={handleClose}
        />
      </ContextModal>
    </div>
  );

  const handleOnCancelScheduleCriteria = () => {
    if (isColdLanding) {
      dispatch(
        updatePreferences({
          typeOfCare: '',
          preliminaryTOC: '',
          zipCode: '',
          age: '',
          preliminaryAge: '',
          preliminaryDOB: '',
          paymentMethod: 'insurance',
          insuranceCompany: '',
        }),
      );
      dispatch(updateReservation({ facilityIds: [], isVideoOnly: false }));
    }
    setOpenMobileCriteria(false);
  };

  const getFacility = (facilityId: number, profFacilities: FacilityLocation[]) => profFacilities.find((elem) => elem.facility_id === facilityId);

  const navigateToMatch = () => {
    navigate(bookingRoutes.matchCriteria);
  };

  const handleContinueConfirmation = () => {
    setOpenBookingModal(false);
    dispatch(
      updatePreferences({
        clinician_profile_back: [
          ...preferences.clinician_profile_back,
          `${location.pathname}${location.search}`,
        ],
        rematch_clinician_id: 0,
      }),
    );
    const facility = getFacility(reservation.facility_id as number, profile.facility_location);
    dispatch(
      updateReservation({ selectedFacility: { ...facility, license_key: profile.license_key } }),
    );
    if ((isTablet || isDesktop) && zipCode.length === 0) {
      onOpenFilterWall(true);
    } else if (isColdLanding && zipCode.length === 0) {
      setView(4);
    } else {
      navigate(intakeRoutes.intake);
    }
  };

  const handleCloseBookingModal = () => {
    if (isColdLanding) {
      dispatch(updatePreferences({ zipCode: '' }));
    }
    setOpenBookingModal(false);
  };

  useEffect(() => {
    if (licenseKey) {
      dispatch(getLicenseKeyRules(licenseKey));
    }
  }, [licenseKey]);

  useEffect(() => {
    if (prevProfileId.current !== profile?.id) {
      profile?.license_key ? dispatch(getContactInfo(profile?.license_key)) : null;
      prevProfileId.current = profile?.id;
    }
  }, [profile]);

  useEffect(() => {
    if (openMobileCriteria) {
      document.body.style.overflow = 'hidden';
    }
    return function cleanup() {
      document.body.style.overflow = 'auto';
    };
  }, [openMobileCriteria]);

  const handleViewChange = (currentView: number) => {
    setView(currentView);
  };

  useEffect(() => {
    if (isAdmin && abiePreferences.paymentMethod === 'insurance') {
      if (
        selectedFacility?.facility_id
        && abiePreferences.insuranceCompany
        && profile?.facility_location
      ) {
        const currentFacility = getFacility(
          selectedFacility?.facility_id as number,
          profile.facility_location,
        );
        const foundSupervised = currentFacility?.supervised_insurances.find(
          (ins) => ins.name === abiePreferences.insuranceCompany,
        );
        setSupervisedPrefix(foundSupervised ? 'Supervised ' : '');
      }
    }
  }, [selectedFacility?.facility_id, isAdmin, profile?.facility_location]);

  return (
    <>
      {!loading && modalToShow === modals.noServiceState && (
        <NoServiceStateModal onClose={() => setModalToShow('')} state={state} />
      )}
      {!loading && modalToShow === modals.noObie && (
        <PleaseCallModal
          onClose={() => setModalToShow('')}
          contactNumber={phoneNumber}
          state={state}
        />
      )}
      {!loading && modalToShow === modals.noClinicians && (
        <NoClinicians
          onClose={() => setModalToShow('')}
          contactNumber={phoneNumber}
          state={state}
        />
      )}
      {openCalendar && (
        <Schedule
          onBack={back}
          onCancel={handleCloseCalendar}
          onNext={onNext}
          handleOpen={setOpenBookingModal}
        />
      )}
      {openMobileCriteria && (
        <ScheduleCriteria
          initialView={initialView}
          onCancel={handleOnCancelScheduleCriteria}
          handleBadTypeOfCare={handleBadTypeOfCare}
          handleContinueConfirmation={handleContinueConfirmation}
          view={view}
          onChange={handleViewChange}
          setModalToShow={setModalToShow}
        />
      )}
      {openBookingModal && (
        <BookingModal
          title="Confirm appointment details"
          buttonLabel="Confirm and continue"
          onContinue={handleContinueConfirmation}
          onClose={handleCloseBookingModal}
          isColdLanding={isColdLanding}
        />
      )}
      {!isExistingUser && isFilterWall && modalToShow === '' && renderFilterWall()}
      {profile ? (
        <div className={styles.container}>
          <div className={styles.header}>
            <Container sm>
              <>
                {customHeader ? (
                  <div className={clsx({ [styles.customHeader]: customHeader })}>
                    <Link to={back}>
                      <ArrowLeft />
                    </Link>
                    <span onClick={handleCancelModal} data-testId="cancel">
                      Cancel
                    </span>
                  </div>
                ) : null}
                {isAdmin && (
                  <Link
                    to={back}
                    className={styles.adminLink}
                    onClick={clearSelectedClinician}
                    onKeyDown={(event) => simpleKeyboardControl(event, () => {
                      event.currentTarget.click();
                    })}
                  >
                    <LeftArrowIcon />
                    <span data-testId="results">
                      Back
                      {preferences.clinician_profile_back.length === 0 && ' to results'}
                    </span>
                  </Link>
                )}
                {hasAppointment && isFilterWallOpen && !isColdLanding && !isAdmin && (
                  <Link to={back} className={styles.link} onClick={clearSelectedClinician}>
                    <LeftArrowIcon />
                    <span data-testId="results">
                      Back
                      {preferences.clinician_profile_back.length === 0 && ' to results'}
                    </span>
                  </Link>
                )}
                {isColdLanding
                  && preferences.clinician_profile_back.length > 0
                  && !isAdmin
                  && back !== location.pathname && (
                    <Link to={back} className={styles.link} onClick={clearSelectedClinician}>
                      <LeftArrowIcon />
                      <span data-testId="results">Back</span>
                    </Link>
                )}
              </>
              <div
                className={clsx(styles.profileImage, {
                  [styles.isAdminImage]: isAdmin,
                })}
                data-testId="profileImage"
              >
                <img
                  src={profile?.photo && profile?.photo.length ? profile?.photo : avatar}
                  width={162}
                  height={162}
                  alt={profile?.firstName}
                />
              </div>
            </Container>
          </div>
          <Container sm>
            <div className={styles.maincontainer}>
              <div className={styles.clinicianinfo} data-testId="clinicianInfo">
                <div className={styles.clinicianType} data-testId="clinicianType">
                  {getPrimaryLocation ? (
                    <span>
                      {`${supervisedPrefix} ${profile?.type} in ${
                        getPrimaryLocation?.city
                      }, ${stateAbbrToName(getPrimaryLocation?.state)}`}
                    </span>
                  ) : (
                    <span>
                      {supervisedPrefix}
                      {profile?.type}
                    </span>
                  )}
                </div>
                <div className={styles.clinicianName} data-testId="clinicianName">
                  <p>
                    {profile?.first_name}
                    {' '}
                    {profile?.last_name}
                    {profile?.credentials ? `, ${profile.credentials}` : ''}
                  </p>
                  <div className={styles.mobile}>
                    <ExistingPatientsBox disableInvoca={isAdmin} />
                  </div>
                </div>
                <About
                  languages_spoken={profile?.languages_spoken}
                  pronouns={profile?.pronouns}
                  virtual_visit={profile?.virtual_visit}
                  in_office={profile?.in_office}
                  manages_medication={profile?.manages_medication}
                  ages_accepted={profile?.ages_accepted}
                  accepting_new_patients={acceptingNewPatients()}
                  gender={profile?.gender}
                  type={profile?.type}
                  insurances={profile?.insurances}
                  isAdmin={isAdmin}
                  sectionRef={financesRef}
                />
                {profile?.type_of_cares?.length ? externalServices() : ''}
                <div className={styles.about}>
                  <div className={styles.heading} data-testId="aboutSection">
                    <p>
                      {profile?.first_name}
                      {' '}
                      {profile?.last_name}
                      {profile?.credentials ? `, ${profile.credentials}` : ''}
                    </p>
                  </div>
                  <div className={styles.description} data-testId="aboutDescription">
                    {profile?.about}
                  </div>
                </div>
                {getEmployedMessage()?.length > 0 ? (
                  <div className={styles.employedBy} data-testId="employedByMessage">
                    {getEmployedMessage()}
                  </div>
                ) : null}
                <ExpertiseAreas
                  expertises={profile?.expertises !== undefined ? profile?.expertises : []}
                  populations={profile?.populations !== undefined ? profile?.populations : []}
                  interventions={profile?.interventions !== undefined ? profile?.interventions : []}
                />
                <div className={styles.background}>
                  <div ref={financesRef} className={styles.heading} data-testId="finances">
                    Finances
                  </div>
                  <Finances
                    insurances={profile?.insurances}
                    phoneNumber={phoneNumber}
                    isAdmin={isAdmin}
                  />
                </div>
                <div className={styles.background}>
                  <div className={styles.heading} data-testId="background">
                    Qualifications
                  </div>
                  {supervisedPrefix.length > 0 && (
                    <Alert type="info" className={styles.customAlert}>
                      <div className={styles.supervisedDisclaimer}>
                        <div>
                          <UserVoice />
                        </div>
                        <span className={styles.title}>
                          As a dependently licensed clinician,&nbsp;
                          {profile?.first_name}
                          &nbsp;can provide care with the extra benefit of supervision to further
                          support your treatment.
                        </span>
                      </div>
                    </Alert>
                  )}
                  {supervisedPrefix.length > 0 && (
                    <Supervisor supervisorData={profile.supervisor_data} isAdmin />
                  )}
                  <Education data={profile?.educations} />
                  {!isAdmin && <Supervisor supervisorData={profile.supervisor_data} />}
                </div>
                <div className={styles.localInfo}>
                  <div className={styles.heading} data-testId="locationInformation">
                    Location information
                  </div>
                  {facilities.length > 0 ? (
                    <Address isAdmin={isAdmin} facilities={profile?.facility_location} />
                  ) : null}
                  <div className={styles.cancellationPolicy}>
                    <AlertLine />
                    <div className={styles.heading} data-testId="cancellationPolicy">
                      Cancellation Policy
                    </div>
                    <div className={styles.body}>
                      If you need to cancel an appointment, just let us know at least two business
                      days ahead of time. If you don’t contact us, or you don’t show up within 15
                      minutes of the scheduled time, we will need to charge your credit card on file
                      the full amount for the reserved time. Health insurance doesn’t cover missed
                      appointments, and LifeStance depends on timely, full payment to provide the
                      highest quality of care to all of our patients. However, if an unforeseen
                      situation arises—such as illness, bereavement, an accident, etc.—we will waive
                      the fee if you can provide documentation that proves it.
                    </div>
                  </div>
                </div>
                {facilities.length > 0 ? (
                  <div className={styles.frequentlyAsked}>
                    <div className={styles.heading} data-testId="frequentlyAsked">
                      FAQs
                    </div>
                    <FAQ profile={profile} phoneNumber={phoneNumber} isAdmin={isAdmin} />
                  </div>
                ) : null}
                {otherClinicians?.length ? <Providers providers={otherClinicians} /> : null}
              </div>
              <div className={styles.viewAllAvailabilityButton}>
                {facilityClinicianAvailabilities.length === 0
                || typesOfCares.length === 0
                || facilities.length === 0 ? (
                  <div className={styles.noAvailability}>
                    No upcoming availability
                    <SpanButton onClick={navigateToMatch}>Browse other providers</SpanButton>
                  </div>
                  ) : (
                    <Button
                      fullWidth
                      onClick={handleMobileReservation}
                      data-testId="viewAllAvailability"
                    >
                      View all availability
                    </Button>
                  )}
              </div>
              <div className={styles.availabilityContainer}>
                {(isTablet || isDesktop) && (
                  <div className={styles.existingContainer}>
                    <NewPatientBox
                      onOpenCalendar={handleOpenCalendar}
                      handleOpen={() => {
                        setOpenBookingModal(true);
                      }}
                      isFilterWall={isFilterWall}
                    />
                    <ExistingPatientsBox disableTabNav={isAdmin} disableInvoca={isAdmin} />
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      ) : (
        <CircularLoading />
      )}
    </>
  );
};
