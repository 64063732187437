import * as API from 'intakeOptimization/api/legacy';
import * as API_V2 from 'intakeOptimization/api/v2/obie';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setMarketingReferralListOptions } from 'intakeOptimization/store/preferences/actions';

import {
  setAddressData,
  setError,
  setHideSkipButtonCCOF,
  setInsuranceFilters,
  setInsuranceSkipFlag,
  setShowCCOF,
} from './actions';
import t, {
  FilterDataPayload,
  FiltersDTO,
  InsuranceCardPayload,
  LicenseKeyRulesDTO,
} from './types';

function* handleValidateZipCode(action: PayloadAction<string>) {
  const zipCode = action.payload;
  try {
    yield put(setError(null));
    const isZipCodeValid: { data: { city: string; state: string } } = yield call(
      API_V2.validateZipCode,
      zipCode,
    );
    const { city, state } = isZipCodeValid.data;

    if (isZipCodeValid) {
      yield put(setAddressData({ zipCode, city, state }));
    }
  } catch (e) {
    yield put(setError('Enter a valid zip code for United States'));
  }
}

function* handleGetInsuranceFilters(action: PayloadAction<FilterDataPayload>) {
  try {
    yield put(setError(null));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const filters: { data: FiltersDTO } = yield call(API_V2.getFilterData, action.payload);

    const concerns = filters.data.concerns.map((el) => ({
      label: el.name,
      value: el.name,
    }));
    const interventions = filters.data.interventions?.map((el) => ({
      label: el.name,
      value: el.name,
    }));
    const populations = filters.data.populations?.map((el) => ({
      label: el.name,
      value: el.name,
    }));
    const marketing_referrals = filters.data.marketing_referrals?.map((el) => ({
      label: el.display_marketing_referral,
      value: el.amd_marketing_referral,
    }));
    const focusOptions = [
      {
        label: 'Concerns',
        options: concerns,
      },
      {
        label: 'Populations',
        options: populations,
      },
      {
        label: 'Interventions',
        options: interventions,
      },
    ];

    yield put(setInsuranceFilters({ ...filters.data, focusOptions }));
    yield put(setMarketingReferralListOptions(marketing_referrals));
  } catch (e) {
    yield put(setError('Error setting filters'));
  }
}

function* handleSaveInsuranceCard(action: PayloadAction<InsuranceCardPayload>) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield call(API.saveInsuranceCard, action.payload);
  } catch (e) {
    // TBD
  }
}

function* handleGetLicenseKeyRules(action: PayloadAction<string>) {
  try {
    const response: { data: LicenseKeyRulesDTO } = yield call(
      API.getLicenseKeyRules,
      action.payload,
    );
    const licenseSkipFlag = response.data.insurance_skip_option_flag;
    const enableCCOF = response.data.enable_credit_card_on_file;
    const skipButtonCCOF = response.data.disable_skip_credit_card;
    yield put(setInsuranceSkipFlag(licenseSkipFlag));
    yield put(setShowCCOF(enableCCOF));
    yield put(setHideSkipButtonCCOF(skipButtonCCOF));
  } catch (e) {
    // TBD
  }
}

function* insuranceSagas(): Generator {
  yield all([
    yield takeLatest(t.VALIDATE_ZIP_INSURANCE, handleValidateZipCode),
    yield takeLatest(t.GET_INSURANCE_FILTERS, handleGetInsuranceFilters),
    yield takeLatest(t.SAVE_INSURANCE_CARD, handleSaveInsuranceCard),
    yield takeLatest(t.GET_LICENSE_KEY_RULES, handleGetLicenseKeyRules),
  ]);
}

export default insuranceSagas;
