import clsx from 'clsx';
import { FC } from 'react';

import styles from './Alert.module.scss';

interface IAlertProps {
  type: 'critical' | 'warning' | 'success' | 'info';
  className?: string;
  children: any;
  wrap?: boolean;
}

export const Alert: FC<React.PropsWithChildren<IAlertProps>> = ({
  type,
  children,
  className = '',
  wrap = false,
}) => (
  <div
    className={clsx(styles.Alert, className, {
      [styles.critical]: type === 'critical',
      [styles.warning]: type === 'warning',
      [styles.success]: type === 'success',
      [styles.info]: type === 'info',
      [styles.wrap]: wrap,
    })}
  >
    {children}
  </div>
);
