import React, { FC } from 'react';
import { Pillbox } from 'lifestance-ui/Pillbox/Pillbox';
import clsx from 'clsx';
import styles from './SelectCardPillbox.module.scss';

interface IPillbox {
  label: string;
  icon?: JSX.Element;
}

interface SelectCardPillboxProps {
  pillboxOptions: IPillbox[];
  header: string;
  content?: string;
  extraContent?: string;
  className?: string;
  testId?: string;
  onClick: (data: unknown) => void;
  selected: boolean;
  data: unknown;
  pillboxClassName?: string;
}

export const SelectCardPillbox: FC<SelectCardPillboxProps> = ({
  className = '',
  header,
  content = '',
  extraContent = '',
  onClick,
  selected = false,
  pillboxOptions,
  testId = 'SelectCardPillbox',
  data,
  pillboxClassName,
}) => (
  <div
    data-cy="SelectCardPillbox"
    data-testid={`pillbox${testId}Container`}
    className={clsx([
      styles.selectCard,
      className,
      {
        [styles.selected]: selected,
      },
    ])}
    onClick={() => { onClick(data) }}
  >
    <div className={styles.headerContainer}>
      <h1 className={styles.header}>
        {header}&nbsp;
        {content.length > 0 && <span className={styles.desktopHeader}>| {content}</span>}
      </h1>
      <input
        type="checkbox"
        className={styles.checkbox}
        checked={selected}
      />
    </div>
    <div className={styles.contentContainer}>
      {content.length > 0 && <p className={styles.content}>{content}</p>}
      {extraContent?.length > 0 && <p className={styles.extraContent}>{extraContent}</p>}
      <div className={styles.pillboxContainer}>
        {pillboxOptions.map((elem) => <Pillbox mode="main" iconElement={elem.icon} className={pillboxClassName || styles.greenPillbox} iconContainerClass={styles.iconContainer}>{elem.label}</Pillbox>)}
      </div>
    </div>
  </div>
);
