import * as API_V1 from 'intakeOptimization/api/v2/obie';
import * as Effects from 'redux-saga/effects';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import {
  activeFiltersArraySelector,
  cliniciansSelector,
  filteredCliniciansSelector,
} from 'intakeOptimization/store/clinicians/selectors';
import { setLoadingFiltering } from 'intakeOptimization/store/matches/reducers';

import { getQueryString, LICENSED_CLINICIAN, SUPERVISED_CLINICIAN } from 'intakeOptimization/utils';
import { filterClinicians } from 'intakeOptimization/utils/filterClinicians';
import { sortClinicians } from 'intakeOptimization/utils/sortClinicians';

import {
  loadClinicians,
  setClinicianCount,
  setDisplayedClinicians,
  setError,
  setFilteredClinicians,
  setLoadingClinicians,
  setLoadingDisplayedClinicians,
  setPage,
  setTotalPages,
} from './slice';
import t from './types';

const { call, delay } = Effects;

function* handleGetFilteredClinicians(): any {
  const clinicians = yield select(cliniciansSelector);
  const activeFilters = yield select(activeFiltersArraySelector);
  const { clinicians: filteredClinicians, count } = filterClinicians(clinicians, activeFilters);
  const sortedClinicians = sortClinicians(filteredClinicians, activeFilters);
  const totalPages = Math.ceil(count / 10);
  yield put(setFilteredClinicians(sortedClinicians));
  yield put(setTotalPages(totalPages));
}

function* handleGetClinicians(action: any): any {
  try {
    yield put(setLoadingClinicians(true));
    yield put(loadClinicians([]));
    const preferences: any = {};
    for (const [key, value] of Object.entries(action.payload.activeFilters)) {
      if (Array.isArray(value) && value.length === 1 && key !== 'license_keys') {
        preferences[key] = value.toString();
      } else if (key === 'special_cases' && value === 'None of the above') {
        // Do nothing
      } else {
        preferences[key] = value;
      }
    }

    const queryString = getQueryString(preferences);
    const response = yield call(API_V1.getAllCliniciansObie, { preferences: queryString });
    yield put(loadClinicians(response.data.clinicians));
    yield call(handleGetFilteredClinicians);
    yield put(setLoadingClinicians(false));
    yield put(setLoadingFiltering(false));
  } catch (e: any) {
    yield put(setError(e.response?.status));
    yield put(setLoadingClinicians(false));
  }
}

function* handleGetCliniciansCount(action: any): any {
  try {
    yield put(setLoadingFiltering(true));

    const preferences: any = {};
    for (const [key, value] of Object.entries(action.payload.activeFilters)) {
      if (Array.isArray(value) && value.length === 1 && key !== 'license_keys') {
        preferences[key] = value.toString();
      } else if (key === 'special_cases' && value === 'None of the above') {
        // Do nothing
      } else {
        preferences[key] = value;
      }
    }

    const queryString = getQueryString(preferences);
    const response = yield call(API_V1.getAllCliniciansObie, { preferences: queryString });

    yield put(
      loadClinicians(
        response.data.clinicians.map((o: any) => {
          const isSupervised = o?.supervisor_data?.supervised_clinician;
          if (isSupervised) {
            return {
              ...o,
              license_types: [...o.license_types, { id: 1000, name: SUPERVISED_CLINICIAN.name }],
            };
          }
          return {
            ...o,
            license_types: [...o.license_types, { id: 1000, name: LICENSED_CLINICIAN.name }],
          };
        }),
      ),
    );
    yield put(setLoadingFiltering(false));
  } catch (e: any) {
    yield put(setError(e.response?.status));
    yield put(setLoadingFiltering(false));
  }
}

function* handleGetDisplayedClinicians(action: any): any {
  yield put(setLoadingDisplayedClinicians(true));
  const { page } = action.payload;
  const filteredClinicians = yield select(filteredCliniciansSelector);
  const paginatedResults = filteredClinicians.slice(0, page * 10);
  yield put(setPage(page));
  yield put(setDisplayedClinicians(paginatedResults));
  yield put(setClinicianCount(filteredClinicians.length));
  yield delay(100);
  yield put(setLoadingDisplayedClinicians(false));
}

function* cliniciansSaga(): any {
  yield all([
    yield takeLatest(t.GET_FILTERED_CLINICIANS_OBIE, handleGetFilteredClinicians),
    yield takeLatest(t.GET_ALL_CLINICIANS, handleGetClinicians),
    yield takeLatest(t.GET_DISPLAYED_CLINICIANS_OBIE, handleGetDisplayedClinicians),
    yield takeLatest(t.GET_CLINICIANS_COUNT, handleGetCliniciansCount),
  ]);
}

export default cliniciansSaga;
