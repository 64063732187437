import clsx from 'clsx';
import { ReactComponent as Lightbulb } from 'patientOnboarding/assets/svg/lightbulb-line.svg';
import { FC } from 'react';

import { ModalToast } from 'lifestance-ui';

import { useInvoca, useResizingMediaQuery } from 'patientOnboarding/hooks';

import { defaultPhoneNumber, formatContactNumber, stateAbbrToName } from 'patientOnboarding/utils';

import styles from './NoCliniciansModal.module.scss';

interface INoClinicians {
  onClose: () => void;
  state: string;
  contactNumber?: string;
}

const NoCliniciansModal: FC<INoClinicians> = ({ onClose, state, contactNumber = '' }) => {
  const isMobile = useResizingMediaQuery('(max-width: 768px)');
  useInvoca();

  return (
    <ModalToast onClose={onClose}>
      <div className={styles.icon}>
        <Lightbulb />
      </div>
      <h1 className={styles.title}>
        No providers currently
        {!isMobile ? <br /> : ' '}
        available
        {isMobile ? <br /> : ' '}
        in&nbsp;
        {stateAbbrToName(state)}
      </h1>
      <span className={styles.description}>
        It looks like we don’t have any providers here that are accepting new clients right now.
        Please call&nbsp;
        {isMobile ? (
          <a
            className={clsx(styles.phoneNumber, 'invocaPhoneNumber')}
            href={`tel:${formatContactNumber(contactNumber)}`}
          >
            {contactNumber || defaultPhoneNumber}
          </a>
        ) : (
          <>
            <br />
            <span className={clsx(styles.phone, 'invocaPhoneNumber')}>
              {contactNumber || defaultPhoneNumber}
            </span>
          </>
        )}
        &nbsp;to be added to our waitlist.
      </span>
    </ModalToast>
  );
};

export default NoCliniciansModal;
