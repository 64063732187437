import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import { msalInstance } from 'modules/application/App';

import { loginRequest } from '../../../auth/config';

export const JWT_TOKEN = localStorage.getItem('token');

const triggerNewSession = (instance: PublicClientApplication) => {
  localStorage.removeItem('ms-token');
  instance.loginRedirect(loginRequest).catch((e) => {
    console.log(e);
  });
};

const acquireAccessToken = async (msalInstance: PublicClientApplication) => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount) {
    console.log('No active account found.');
  }
  if (accounts.length === 0) {
    console.log('No accounts found.');
  }

  if (!activeAccount && accounts.length === 0) {
    triggerNewSession(msalInstance);
  }
  const request = {
    scopes: ['User.Read'],
    account: activeAccount || accounts[0],
  };

  try {
    const authResult = await msalInstance.acquireTokenSilent(request);
    if (!authResult) {
      triggerNewSession(msalInstance);
    }
    return authResult.accessToken;
  } catch (error) {
    triggerNewSession(msalInstance);
  }
};

const BASE_URL = '/abie/api/v2';

const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${JWT_TOKEN}`,
  },
});

API.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('ms-token');
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    return config;
  },
  async (error) => Promise.reject(error),
);

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { status } = error.response;
    // List of API statuses to trigger an error page
    if (status === 401) {
      const token = await acquireAccessToken(msalInstance);
      localStorage.setItem('ms-token', token as string);
      error.config.headers.Authorization = `Bearer ${token}`;
      return API.request(error.config);
    }
    if ([422].includes(status)) {
      window.location.href = '/admin';
    }
    if ([500].includes(status)) {
      const payload = { status, error, redirectionUrl: '/error' };
      // store.dispatch({ type: t.THROW_API_ERROR, payload })
    }

    return Promise.reject(error);
  },
);

export default API;
