import { Clinician, RAddress } from 'intakeOptimization/@types';

import { ActiveFilter } from 'intakeOptimization/store/clinicians/slice';

const sortKeys: Record<string, string> = {
  soonest_available: 'rank_soonest_available',
  most_available: 'rank_most_available',
  nearest_location: 'distance_in_miles',
};
export const sortClinicians = (clinicians: Clinician[], activeFilters: ActiveFilter[]) => {
  const filteredClinicians = clinicians;
  const sortBy = activeFilters?.find((filter) => filter?.name === 'sortType');

  if (sortBy) {
    const sortKey = sortKeys[sortBy.value as string] as keyof RAddress | keyof Clinician;
    const sortedClinicians = filteredClinicians.sort((a: Clinician, b: Clinician) => {
      if (['rank_most_available', 'rank_soonest_available'].includes(sortKey)) {
        const first = parseInt(a.addresses[0][sortKey as keyof RAddress] as string, 10);
        const second = parseInt(b.addresses[0][sortKey as keyof RAddress] as string, 10);
        return first - second;
      }
      return a[sortKey as keyof Clinician] < b[sortKey as keyof Clinician] ? -1 : 1;
    });
    return sortedClinicians;
  }
  return filteredClinicians;
};
