const types = {
  GET_CLINICIANS: '@@PO_abieClinicians/GET_CLINICIANS',
  GET_CLINICIANS_COUNT: '@@PO_abieClinicians/GET_CLINICIANS_COUNT',
  LOAD_CLINICIANS: '@@PO_abieClinicians/LOAD_CLINICIANS',
  SET_LOADING_CLINICIANS: '@@PO_ADMIN_MATCHES/SET_LOADING_CLINICIANS',
  SET_ERROR: '@@PO_ADMIN_MATCHES/SET_ERROR',
  SET_SEARCH_TERM: '@@PO_ADMIN_MATCHES/SET_SEARCH_TERM',
  RESET_CLINICIANS: '@@PO_ADMIN_MATCHES/RESET_CLINICIANS',
  GET_OTHER_CLINICIANS: '@@PO_ADMIN_MATCHES/GET_OTHER_CLINICIANS',
  GET_FILTERED_CLINICIANS: '@@PO_ADMIN_MATCHES/GET_FILTERED_CLINICIANS',
  GET_MORE_FILTERED_CLINICIANS: '@@PO_ADMIN_MATCHES/GET_MORE_FILTERED_CLINICIANS',
  GET_DISPLAYED_CLINICIANS: '@@PO_ADMIN_MATCHES/GET_DISPLAYED_CLINICIANS',
};

export default types;
