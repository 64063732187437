import { ReactComponent as BuildingIcon } from 'patientOnboarding/assets/svg/building_select.svg';
import { ReactComponent as Line } from 'patientOnboarding/assets/svg/list-card-divider.svg';
import { ReactComponent as VideoChatIcon } from 'patientOnboarding/assets/svg/video_select.svg';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SelectCardPillbox } from 'lifestance-ui';

import { updatePreferences } from 'patientOnboarding/store/preferences/actions';
import { updateReservation } from 'patientOnboarding/store/reservationGlobal/actions';

import {
  obiePreferencesData,
  reservationData,
  selectedClinicianSelector,
} from 'patientOnboarding/selectors/selectors';

import styles from './ViewLocation.module.scss';

interface IViewReason {
  setDisabled: (value: boolean) => void;
}

export const ViewLocation: FC<IViewReason> = ({ setDisabled }) => {
  const dispatch = useDispatch();
  const profile = useSelector(selectedClinicianSelector);
  const preferences = useSelector(obiePreferencesData);
  const reservation = useSelector(reservationData);
  const isColdLanding = preferences?.location_back?.length === 0 || profile?.id === preferences?.rematch_clinician_id;
  const [locations, setLocations] = useState([]);
  const videoVisitOnly = reservation.isVideoOnly;
  const telehealth = profile?.virtual_visit === true;
  const officeVisit = profile?.in_office === true;
  const [videoOption, setVideoOption] = useState(videoVisitOnly);
  const [selectedFacility, setSelectedFacilities] = useState<number[]>(
    reservation.facilityIds || [],
  );
  const videoPillbox = [{ label: 'No location preference' }];
  const { paymentMethod, insuranceCompany } = preferences;
  const facilityLocation = profile.facility_location;

  const containsValue = (facility: any) => facility.insurances.some(
    (insurance: { id: number; name: string }) => insurance.name === insuranceCompany,
  );

  const renderAppointmentText = () => {
    let text = '';
    if (telehealth && !officeVisit) {
      text = 'This provider is not currently offering in-office appointments';
    } else if (!telehealth && officeVisit) {
      text = 'This provider is not currently offering video appointments';
    }
    return text;
  };

  const handleSelectFacility = (facility: any) => {
    const facilityId = facility.facility_id;
    const arr = [...selectedFacility];
    if (!arr.includes(facilityId)) {
      arr.push(facilityId);
    } else {
      arr.splice(arr.indexOf(facilityId), 1);
    }
    setSelectedFacilities(arr);
    if (arr.length === 0) {
      handleSelectVideo();
      setVideoOption(true);
    } else {
      dispatch(updatePreferences({ modality: ['in_office'] }));
      dispatch(updateReservation({ facilityIds: arr, modality: 'IN-OFFICE', isVideoOnly: false }));
      setVideoOption(false);
    }
    setDisabled(false);
  };

  const handleSelectVideo = () => {
    setVideoOption(true);
    setSelectedFacilities([]);
    dispatch(updatePreferences({ modality: ['video_visit'] }));
    dispatch(updateReservation({ modality: 'VIDEO', isVideoOnly: true, facilityIds: [] }));
    setDisabled(false);
  };

  useEffect(() => {
    if (paymentMethod === 'self_pay') {
      setLocations(facilityLocation);
    } else {
      const filteredLocations = facilityLocation.filter(containsValue);
      const physicalLocations = filteredLocations.filter((facility: any) => facility.in_office);
      if (physicalLocations.length === 0) {
        handleSelectVideo();
      }
      setLocations(physicalLocations);
    }
    if (!isColdLanding) {
      const isArray = Array.isArray(preferences.modality);
      let isVideo = false;
      let isOffice = false;
      if (isArray) {
        isVideo = preferences.modality.includes('video_visit');
        isOffice = preferences.modality.includes('in_office');
      } else {
        isVideo = (preferences.modality as unknown as string) === 'video_visit';
        isOffice = (preferences.modality as unknown as string) === 'in_office';
      }
      if (isVideo && !isOffice) {
        handleSelectVideo();
      }
    }
  }, []);

  useEffect(() => {
    const { facilityIds } = reservation;
    if ((facilityIds.length > 0 && !videoOption) || videoOption) {
      setDisabled(false);
    }
  }, [reservation.facilityIds]);

  return (
    <>
      <div className={styles.header}>
        <span className={styles.location}>Choose a location</span>
        <h1 className={styles.head}>
          {preferences.preliminaryTOC}
          {' '}
          Initial Consultation
        </h1>
      </div>
      <div className={styles.optionsContainer}>
        {telehealth ? (
          <SelectCardPillbox
            header="Video visits only"
            pillboxOptions={videoPillbox}
            pillboxClassName={styles.videoPillbox}
            onClick={handleSelectVideo}
            selected={videoOption}
            data={locations}
          />
        ) : null}
        {telehealth && officeVisit ? (
          <div className={styles.divider}>
            <Line />
            <span>OR</span>
            <Line />
          </div>
        ) : null}
        {locations.length > 0 && officeVisit && (
          <>
            {locations.map((facility: any) => {
              if (facility?.clinician_availabilities.length) {
                const facilityPillbox = [];
                if (telehealth) facilityPillbox.push({ label: 'Video', icon: <VideoChatIcon /> });
                if (officeVisit) facilityPillbox.push({ label: 'In-office', icon: <BuildingIcon /> });
                const addressFormat = `${facility.address_line1}, ${facility.city}, ${facility.state} ${facility.postal_code}`;
                return (
                  <SelectCardPillbox
                    header={facility.city}
                    content={addressFormat}
                    pillboxOptions={facilityPillbox}
                    className={styles.selectCard}
                    onClick={handleSelectFacility}
                    selected={selectedFacility?.includes(facility.facility_id)}
                    data={facility}
                  />
                );
              }
            })}
          </>
        )}
        {!telehealth || !officeVisit ? (
          <>
            <div className={styles.divider}>
              <Line />
              <span>OR</span>
              <Line />
            </div>
            <div className={styles.noPhysicalLocations}>{renderAppointmentText()}</div>
          </>
        ) : null}
      </div>
    </>
  );
};
