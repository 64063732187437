import { ReactComponent as BuildingIcon } from 'patientOnboarding/assets/svg/building-4-line.svg';
import { ReactComponent as VideoChatIcon } from 'patientOnboarding/assets/svg/video-chat-line.svg';

import styles from '../AvailabilityBox.module.scss';

const ModalityIcon = ({ modality }) => modality === 'VIDEO' ? (
  <VideoChatIcon className={styles.modalityIcon} data-testId="videochatIcon" />
) : (
  <BuildingIcon className={styles.modalityIcon} data-testId="buildingIcon" />
);

export default ModalityIcon;
