import clsx from 'clsx';
import { FC, KeyboardEvent } from 'react';

/**
 * For richer keyboard navigation and better functionality this component needs to be upgraded to latest version as
 * some functionality does not work properly on current version 4.8.0 eg keyboard navigation allows you to navigate on
 * disabled elements but does not trigger or allow the onChange latest version https://reactdatepicker.com/#example-include-dates
 * with an inline prop will not allow you to navigate through disabled items but this needs an upgrade of versions
 */
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { ReactComponent as ArrowLeft } from 'lifestance-ui/assets/svg/arrow-left-blue.svg';
import { ReactComponent as ArrowRight } from 'lifestance-ui/assets/svg/arrow-right-blue.svg';

import styles from './AppointmentCalendar.module.scss';

interface ICalendarCell {
  day: number;
  date?: Date;
}
interface AppointmentCalendarProps {
  formatWeekDay: (day: string) => string;
  startDate?: Date;
  CustomElement?: FC<ICalendarCell>;
  minDate: Date;
  includeDates: Date[];
  handleSelectDate: (date: Date) => void;
  containerClassName?: string;
  onMonthChange?: () => void;
}

export const AppointmentCalendar = ({
  formatWeekDay,
  startDate,
  CustomElement,
  minDate,
  includeDates,
  handleSelectDate,
  containerClassName = '',
  onMonthChange,
}: AppointmentCalendarProps) => {
  const maxDate = [...includeDates].pop();

  /**
   * Workaround for custom keyboard navigation. We first disable keyboard navigation and then use this custom one
   * Do not include Shift, Space or Enter as these keys are managed upstream
   * Space and Enter does not trigger onKeyDown triggers onChange
   * Shift will focus to the selected elements as any key pressed will trigger the focusOnSelected element
   * As Shift + Tab includes Tab in the sequence stack the code for allowing keyboard navigation will work
   *  */
  const validKeys = ['Tab', 'Escape'];

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    // Does not prevent nothing
    if (validKeys.includes(event.key)) {
      // Allows propagation
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <div className={clsx([styles.appointmentCalendar, containerClassName])}>
      <DatePicker
        formatWeekDay={formatWeekDay}
        selected={startDate}
        renderDayContents={
          CustomElement ? (day, date) => <CustomElement day={day} date={date} /> : undefined
        }
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="react-datepicker--custom-header">
            <span data-testId="calendarCurrentMonth" className="react-datepicker__current-month">
              {date.toLocaleString('en-US', {
                month: 'long',
              })}
            </span>
            <div className="react-datepicker--custom-buttons">
              <button
                type="button"
                data-testId="buttonDecreaseMonth"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                tabIndex={0}
                id="buttonDecreaseMonth"
              >
                <ArrowLeft />
              </button>

              <button
                type="button"
                data-testId="buttonIncreaseMonth"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                tabIndex={0}
                id="buttonIncreaseMonth"
              >
                <ArrowRight />
              </button>
            </div>
          </div>
        )}
        minDate={minDate}
        maxDate={maxDate}
        includeDates={includeDates}
        onChange={(date: Date) => handleSelectDate(date)}
        inline
        onWeekSelect={onMonthChange}
        onKeyDown={handleKeyDown}
        disabledKeyboardNavigation
      />
    </div>
  );
};
