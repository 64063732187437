import { TouchEvent, useState } from 'react';

interface ISwipeOptions {
  minSwipeDistance?: number;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
}

export const useSwipeDetection = ({
  minSwipeDistance = 50,
  onSwipeLeft,
  onSwipeRight,
}: ISwipeOptions) => {
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);

  const onTouchStart = (e: TouchEvent<Element>) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    if (e.targetTouches && e.targetTouches.length) {
      setTouchStart(e.targetTouches[0].clientX);
    }
  };

  const onTouchMove = (e: TouchEvent<Element>) => {
    if (e.targetTouches && e.targetTouches.length) {
      setTouchEnd(e.targetTouches[0].clientX);
    }
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - (touchEnd as number);
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      onSwipeLeft?.();
    }
    if (isRightSwipe) {
      onSwipeRight?.();
    }
  };

  return { onTouchStart, onTouchMove, onTouchEnd };
};
