import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { Clinician, ROtherClinicians } from 'intakeOptimization/@types';
import { action } from 'typesafe-actions';

import t from './types';

type ActiveFilters = {
  name: string;
  values: string[] | number[];
};

export type InitialState = {
  loading?: boolean;
  loadingMore: boolean;
  loadingProfile: boolean;
  loadingAvailabilities: boolean;
  loadingFiltering: boolean;
  error: any;
  hasClinician: boolean;
  page: number;
  clinicianAvailableDates: any[];
  stickyMenu: boolean;
  clinician_count?: number;
  clinicians?: Clinician[];
  displayedClinicians: Clinician[];
  filteredClinicians: Clinician[];
  activeFilters: ActiveFilters[];
  meta?: any;
  searchTerm?:
    | {
        status: string;
      }
    | string;
  selectedClinician?: any;
  clinicianAvailabilities?: any[];
  additional_clinicians?: Clinician[];
  otherProviders?: ROtherClinicians[];
};

const initialState: InitialState = {
  loading: true,
  loadingMore: false,
  loadingProfile: false,
  loadingAvailabilities: false,
  loadingFiltering: false,
  error: null,
  hasClinician: false,
  page: 1,
  clinicianAvailableDates: [],
  displayedClinicians: [],
  filteredClinicians: [],
  activeFilters: [],
  stickyMenu: false,
};

const matchesSlice = createSlice({
  name: 'IO_matches',
  initialState,
  reducers: {
    loadMatches: (state, action: PayloadAction<InitialState>) => ({
      ...state,
      ...action.payload,
    }),
    loadMoreMatches: (state, action: PayloadAction<any>) => {
      state.clinician_count = action.payload.meta.clinician_count;
      state.clinicians = [...(state.clinicians as any), ...action.payload.clinicians];
      state.meta = action.payload.meta;
      state.page = state.meta.total_pages > state.page ? (state.page += 1) : state.page;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetPage: (state) => {
      state.page = 1;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    loadClinicianProfile: (state, action: PayloadAction<any>) => {
      state.selectedClinician = action.payload;
    },
    setClinician: (state, action: PayloadAction<boolean>) => {
      state.hasClinician = action.payload;
    },
    setClinicianAvailabilities: (state, action: PayloadAction<any>) => {
      state.clinicianAvailabilities = action.payload;
    },
    setClinicianAvailableDates: (state, action: PayloadAction<any>) => {
      state.clinicianAvailableDates = action.payload;
    },
    setStickyMenu: (state, action: PayloadAction<boolean>) => {
      state.stickyMenu = action.payload;
    },
    setOtherProviders: (state, action: PayloadAction<ROtherClinicians[]>) => {
      state.otherProviders = action.payload;
    },
    setLoadingProfile: (state, action: PayloadAction<boolean>) => {
      state.loadingProfile = action.payload;
    },
    setLoadingAvailabilities: (state, action: PayloadAction<boolean>) => {
      state.loadingAvailabilities = action.payload;
    },
    setLoadingMoreClinicians: (state, action: PayloadAction<boolean>) => {
      state.loadingMore = action.payload;
    },
    setLoadingFiltering: (state, action: PayloadAction<boolean>) => {
      state.loadingFiltering = action.payload;
    },
    resetMatches: () => initialState,
  },
});

export const getMatches = createAction<any>(t.GET_MATCHES);

type ClinicianProfile = {
  id: string | number;
  typeOfCare?: string | number;
  zipCode?: string | number;
};

export const getClinicianProfile = (
  id?: string | number,
  typeOfCare?: string | number,
  zipCode?: string | number,
) => action(t.GET_CLINICIAN_PROFILE, { id, typeOfCare, zipCode });

export const getOtherProvidersForClinician = (id: string) => action(t.GET_OTHER_PROVIDERS, { id });

export const getSelectedClinicianDetails = createAction<any>(t.GET_CLINICIAN_DETAILS);

export const setSelectedClinicianDetails = createAction<any>(t.SET_CLINICIAN_DETAILS);

type ClinicianAvailabilities = {
  facilityId?: number | string | null | number[];
  facilityIds?: number[];
  clinicianId: number | string;
  availableDate: any;
  typeOfCare: string | null;
  videoOption?: boolean;
};

export const getClinicianAvailabilities = createAction<ClinicianAvailabilities>(
  t.GET_CLINICIAN_AVAILABILITIES,
);

export const getMoreMatches = createAction<any>(t.GET_MORE_MATCHES);

export const {
  setLoading,
  setError,
  setSearchTerm,
  setClinician,
  loadMatches,
  loadMoreMatches,
  loadClinicianProfile,
  resetMatches,
  setClinicianAvailabilities,
  setClinicianAvailableDates,
  setStickyMenu,
  resetPage,
  setOtherProviders,
  setLoadingProfile,
  setLoadingAvailabilities,
  setLoadingMoreClinicians,
  setPage,
  setLoadingFiltering,
} = matchesSlice.actions;

export default matchesSlice.reducer;
