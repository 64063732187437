import clsx from 'clsx';
import { ReactComponent as LogoMobile } from 'patientOnboarding/assets/svg/logo-mobile.svg';
import { ReactComponent as Logo } from 'patientOnboarding/assets/svg/logo.svg';
import { ReactComponent as NewMatchHomeIcon } from 'patientOnboarding/assets/svg/new-match-home.svg';
import { ReactComponent as SearchIcon } from 'patientOnboarding/assets/svg/search.svg';
import { ReactComponent as TalkingPointsOffIcon } from 'patientOnboarding/assets/svg/talking-points-off.svg';
import { ReactComponent as TalkingPointsIcon } from 'patientOnboarding/assets/svg/talking-points.svg';
import {
  adminRoutes as absoluteAdminRoutes,
  bookingRoutes as absoluteBookingRoutes,
  intakeRoutes as absoluteIntakeRoutes,
} from 'patientOnboarding/routes/absolutes';
import {
  bookingRoutes,
  establishedPatientsRoutes,
  intakeRoutes,
} from 'patientOnboarding/routes/constants';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { BackButton, CircularButton, Container } from 'lifestance-ui';

import { TopAppointmentEstablished } from 'patientOnboarding/components';

import { resetAge, setIsTalkingPoint } from 'intakeOptimization/store/admin/reducers';
import { resetActiveFilters } from 'intakeOptimization/store/clinicians/slice';
import { resetAllFilters } from 'intakeOptimization/store/filters/actions';
import { resetPage } from 'intakeOptimization/store/matches/reducers';
import { removeClinicianProfileBack } from 'patientOnboarding/store/preferences/actions';
import { setPreviousPath } from 'patientOnboarding/store/searches/actions';

import {
  abieTalkingPoints,
  isAbie,
  stickyMenuSelector as stickyMenuSelectorAbie,
} from 'intakeOptimization/selectors/selectors';
import { obiePreferencesData, stickyMenuSelector } from 'patientOnboarding/selectors/selectors';

import { usePreviousRoute } from 'patientOnboarding/utils/hooks/usePreviousRoute';

import { TopAppointment } from '../TopAppointment/TopAppointment';
import styles from './Header.module.scss';

export const Header = ({ displaySearch = true, isColdLanding = false }) => {
  const previousRoute = usePreviousRoute();
  const location = useLocation();
  const [isSearchEnabled, setIsSearchEnabled] = useState(displaySearch);
  const [isAppointmentEnabled, setIsAppointmentEnabled] = useState(false);
  const [withBoxShadow, setWithBoxShadow] = useState(true);
  const dispatch = useDispatch();
  const isAdmin = useSelector(isAbie);
  const isSticky = useSelector(stickyMenuSelector);
  const isStickyAbie = useSelector(stickyMenuSelectorAbie);
  const isTalkingPoint = useSelector(abieTalkingPoints);
  const searchByNameRoute = isAdmin ? absoluteAdminRoutes.search : absoluteBookingRoutes.search;
  const showSearch = [absoluteAdminRoutes.searchResults];

  const isHome = location.pathname === absoluteBookingRoutes.findCare
    || location.pathname === absoluteAdminRoutes.patientType;
  const navigate = useNavigate();
  const preferences = useSelector(obiePreferencesData);

  const endflowScreens = [
    absoluteIntakeRoutes.confirmation,
    absoluteIntakeRoutes.cancel,
    absoluteIntakeRoutes.cancelSuccess,
    absoluteIntakeRoutes.cancelKeep,
    absoluteIntakeRoutes.cancelError,
    absoluteIntakeRoutes.cancelExclusion,
    absoluteIntakeRoutes.alreadyCancelled,
    absoluteIntakeRoutes.appointmentOccuredPastFlag,
    absoluteIntakeRoutes.bookAppointment,
    absoluteIntakeRoutes.emailVerificationError,
    absoluteIntakeRoutes.cancellationReasons,
  ];

  const noSearchViews = [
    absoluteAdminRoutes.accountHolderParent,
    absoluteAdminRoutes.accountHolderChild,
    absoluteAdminRoutes.physicalAddress,
    absoluteAdminRoutes.accountInsurance,
    absoluteAdminRoutes.emailVerification,
    absoluteAdminRoutes.bookingForSelf,
    absoluteAdminRoutes.bookAppointment,
    absoluteAdminRoutes.appointmentError,
    absoluteBookingRoutes.providerMatches,
  ];

  const noTopDetails = [
    absoluteBookingRoutes.findCare,
    absoluteBookingRoutes.matchCriteria,
    absoluteAdminRoutes.patientType,
    absoluteAdminRoutes.filters,
    absoluteAdminRoutes.insuranceCard,
    absoluteAdminRoutes.consents,
    absoluteAdminRoutes.confirmation,
    absoluteAdminRoutes.review,
  ];

  const establishedPatientScreens = [
    establishedPatientsRoutes.followUp,
    establishedPatientsRoutes.existing,
    establishedPatientsRoutes.provider,
    establishedPatientsRoutes.modality,
    establishedPatientsRoutes.location,
    establishedPatientsRoutes.availableAppointments,
    establishedPatientsRoutes.confirmPaymentInfo,
    establishedPatientsRoutes.confirmation,
  ];

  const isEstablishedPatientScreens = establishedPatientScreens.includes(location.pathname);

  useEffect(() => {
    if (location.pathname.includes(absoluteIntakeRoutes.intake)) {
      setIsSearchEnabled(false);
      setWithBoxShadow(false);
      if (endflowScreens.includes(location.pathname)) {
        setIsAppointmentEnabled(false);
        setWithBoxShadow(true);
      } else {
        setIsAppointmentEnabled(true);
        setWithBoxShadow(false);
      }
    } else if (noTopDetails.includes(location.pathname)) {
      setWithBoxShadow(true);
      setIsSearchEnabled(false);
      setIsAppointmentEnabled(false);
    } else if (showSearch.includes(location.pathname)) {
      setWithBoxShadow(true);
      setIsSearchEnabled(true);
      setIsAppointmentEnabled(false);
    } else if (
      noSearchViews.includes(location.pathname)
      || location.pathname.includes(
        absoluteBookingRoutes.clinicianProfile.substring(
          0,
          absoluteBookingRoutes.clinicianProfile.indexOf(':'),
        ),
      )
    ) {
      setWithBoxShadow(true);
      setIsSearchEnabled(false);
      setIsAppointmentEnabled(false);
    } else if (establishedPatientScreens.includes(location.pathname)) {
      setWithBoxShadow(false);
      setIsSearchEnabled(false);
      setIsAppointmentEnabled(false);
    } else if (
      isAdmin
      && location.pathname.includes(
        absoluteAdminRoutes.clinicianProfile.substring(
          0,
          absoluteAdminRoutes.clinicianProfile.indexOf(':'),
        ),
      )
    ) {
      setIsSearchEnabled(false);
      setIsAppointmentEnabled(false);
      setWithBoxShadow(false);
    } else {
      setWithBoxShadow(true);
      setIsSearchEnabled(displaySearch);
      setIsAppointmentEnabled(false);
    }
    if (location.pathname === absoluteIntakeRoutes.appointmentError) {
      setWithBoxShadow(true);
    }
    if (location.pathname === absoluteIntakeRoutes.appointmentOccuredPastFlag) {
      setIsAppointmentEnabled(false);
    }
  }, [location.pathname]);

  const noBackRoutes = [
    absoluteIntakeRoutes.emailVerified,
    absoluteIntakeRoutes.confirmation,
    absoluteIntakeRoutes.cancel,
    absoluteIntakeRoutes.cancelSuccess,
    absoluteIntakeRoutes.cancelError,
    absoluteIntakeRoutes.cancelExclusion,
    absoluteIntakeRoutes.cancelKeep,
    absoluteIntakeRoutes.alreadyCancelled,
    absoluteIntakeRoutes.appointmentOccuredPastFlag,
    absoluteAdminRoutes.patientType,
    absoluteAdminRoutes.searchResults,
    establishedPatientsRoutes.confirmation,
  ];

  const shouldRouteHaveBackButton = !noBackRoutes.includes(location.pathname);

  const handleShowSearch = () => {
    dispatch(setPreviousPath(`${location.pathname}${location.search}`));
    navigate(searchByNameRoute);
  };

  const handleNewMatch = () => {
    dispatch(resetAllFilters());
    dispatch(resetAge());
    dispatch(resetPage());
    dispatch(resetActiveFilters());
    navigate(absoluteAdminRoutes.patientType);
  };

  const handleProfileBack = () => {
    if (preferences.clinician_profile_back.length > 0) {
      dispatch(removeClinicianProfileBack());
    }
  };

  const specificBackActionOnPath = (path: string) => {
    const regex = /^\/find-care\/intake$/;
    if (path.includes(absoluteBookingRoutes.clinicianProfile.slice(0, -3))) {
      handleProfileBack();
    }
    if (regex.test(path)) {
      handleProfileBack();
    }
  };

  return (
    <div
      className={`${styles.header} ${withBoxShadow ? styles.boxShadow : ''} ${
        isSticky || isStickyAbie ? styles.stickyHeader : ''
      }`}
    >
      <Container
        className={clsx(styles.headerContainer, { [styles.adminHeaderContainer]: isAdmin })}
      >
        <>
          {!isHome && shouldRouteHaveBackButton && !isColdLanding && (
            <BackButton
              className={styles.back}
              previousRoute={previousRoute}
              onClick={() => specificBackActionOnPath(location.pathname)}
            />
          )}
          <div className={styles.content}>
            <a
              href="http://www.lifestance.com/"
              className={styles.logo}
              target="_blank"
              rel="noreferrer"
            >
              <Logo />
            </a>
            {isAdmin && isHome ? (
              <div className={styles.adminHeaderLogo}>
                <a
                  href="http://www.lifestance.com/"
                  className={styles.mobileLogo}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LogoMobile />
                </a>
                <CircularButton
                  Icon={isTalkingPoint ? TalkingPointsIcon : TalkingPointsOffIcon}
                  isActive={isTalkingPoint}
                  onClick={() => dispatch(setIsTalkingPoint(!isTalkingPoint))}
                />
              </div>
            ) : (
              <div className={styles.headerLogo}>
                <a
                  href="http://www.lifestance.com/"
                  className={styles.mobileLogo}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LogoMobile />
                </a>
              </div>
            )}
            <div className={styles.headerButtons}>
              {!isHome && !isEstablishedPatientScreens && isSearchEnabled && (
                <button
                  className={styles.trigger}
                  type="button"
                  onClick={handleShowSearch}
                  data-testId="SearchByName"
                >
                  <SearchIcon />
                  <span>Search by name</span>
                </button>
              )}
              {isAdmin && !isHome && (
                <button className={styles.newMatch} onClick={handleNewMatch}>
                  <NewMatchHomeIcon />
                  <span>Start new match</span>
                </button>
              )}
            </div>
            {isAppointmentEnabled ? <TopAppointment /> : null}
            {isEstablishedPatientScreens
            && location.pathname !== establishedPatientsRoutes.followUp ? (
              <TopAppointmentEstablished />
              ) : null}
          </div>
        </>
      </Container>
    </div>
  );
};
