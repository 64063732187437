import { combineReducers } from '@reduxjs/toolkit';

// Legacy ABIE
import adminConfigReducer from './admin/reducers';
import cliniciansReducer from './clinicians/slice';
import cliniciansGlobalReducer from './cliniciansGlobal/slice';
// Legacy OBIE
import errorHandlerReducer from './errorHandlers/reducers';
import filtersReducer from './filters/reducers';
import insuranceReducer from './insurance/reducers';
import matchesReducer from './matches/reducers';
import preferencesReducer from './preferences/reducers';
import reservationReducer from './reservation/reducer';
import reservationGlobalReducer from './reservationGlobal/reducers';
import searchesReducer from './searches/reducers';
import sessionReducer from './session/reducers';
import userReducer from './user/reducer';
import userGlobalReducer from './userGlobal/reducers';

const adminReducer = combineReducers({
  // Legacy ABIE
  filters: filtersReducer,
  config: adminConfigReducer,
  user: userReducer,
  reservation: reservationReducer,
  clinicians: cliniciansReducer,
  // Legacy OBIE
  preferences: preferencesReducer,
  matches: matchesReducer,
  userGlobal: userGlobalReducer,
  reservationGlobal: reservationGlobalReducer,
  insurance: insuranceReducer,
  errorHandler: errorHandlerReducer,
  searches: searchesReducer,
  session: sessionReducer,
  cliniciansGlobal: cliniciansGlobalReducer,
});

export type AdminRootState = ReturnType<typeof adminReducer>;

export default adminReducer;
