import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from 'lifestance-ui/assets/svg/close-white.svg';

import styles from './TooltipModalMobile.module.scss';

interface ITooltipModalMobileProps {
  title?: string;
  message?: string;
  onClose: () => void;
  testId?: string;
}

export const TooltipModalMobile: React.FC<React.PropsWithChildren<ITooltipModalMobileProps>> = ({
  title,
  message,
  onClose,
  testId,
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(!open);
    setTimeout(() => {
      onClose && onClose();
    }, 100);
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
  }, []);

  return (
    <>
      <div className={styles.backdrop} onClick={handleClose} />
      <div className={clsx(styles.legend, { [styles.open]: open })}>
        <div className={styles.header}>
          <CloseIcon
            onClick={handleClose}
            className={styles.closeIcon}
            data-testId={`icon${testId}`}
          />
          {title}
        </div>
        <div className={styles.message}>{message}</div>
      </div>
    </>
  );
};
