import clsx from 'clsx';
import parse from 'html-react-parser';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TypesOfCareMeta } from 'patientOnboarding/components/ClinicianProfile/typesOfCareMeta';

import { updatePreferences } from 'patientOnboarding/store/preferences/actions';

import {
  obiePreferencesData,
  selectedClinicianSelector,
} from 'patientOnboarding/selectors/selectors';

import { orderAges } from 'patientOnboarding/utils';

import styles from './ViewReason.module.scss';

type TOCDescription = {
  value: string;
  label: string;
  description: string;
  selected: boolean;
  height: number | string;
};

interface IViewReason {
  setDisabled: (value: boolean) => void;
  setNoAvailabilities: (val: boolean) => void;
  clinicianTriggered: boolean;
}

export const ViewReason: FC<IViewReason> = ({
  setDisabled,
  setNoAvailabilities,
  clinicianTriggered,
}) => {
  const dispatch = useDispatch();
  const profile = useSelector(selectedClinicianSelector);
  const preferences = useSelector(obiePreferencesData);

  const orderedAges = orderAges(profile?.ages_accepted);
  const youngerAge = orderedAges.split('-')[0];
  const [typeOfCareOptions, setTypeOfCareOptions] = useState<TOCDescription[]>([]);
  const refs = useRef<HTMLSpanElement[]>([]);
  const [heights, setHeights] = useState([] as number[]);
  const [loaded, setLoaded] = useState(false);

  const handleSelect = (toc: TOCDescription, index: number) => {
    setNoAvailabilities(false);
    const newTOCs = typeOfCareOptions.map((elem) => {
      const isSelected = elem.value === toc.value;
      return { ...elem, selected: isSelected, height: isSelected ? heights[index] : 0 };
    });
    setTypeOfCareOptions(newTOCs);
    dispatch(updatePreferences({ preliminaryTOC: toc.value }));
    setDisabled(false);
  };

  useEffect(() => {
    if (loaded && refs.current) {
      const originalHeights = refs.current.map((elem) => elem.clientHeight);
      setHeights(originalHeights);
    }
    const newTOCs = typeOfCareOptions.map((elem, index) => {
      const isSelected = elem.value === preferences.preliminaryTOC;
      return { ...elem, selected: isSelected, height: isSelected ? heights[index] : 0 };
    });
    setTypeOfCareOptions(newTOCs);
    const isSelectedTOC = newTOCs.find((elem) => elem.selected);
    if (isSelectedTOC) setDisabled(false);
  }, [loaded, refs]);

  useEffect(() => {
    if (!clinicianTriggered) {
      const typeOfCareOptionsUnfiltered: TOCDescription[] = profile?.type_of_cares?.map(
        (typeOfCare: string): TOCDescription => {
          const isSelected = preferences.preliminaryTOC === typeOfCare;
          return {
            value: typeOfCare,
            label: TypesOfCareMeta[typeOfCare]?.label,
            description: TypesOfCareMeta[typeOfCare]?.description,
            selected: isSelected,
            height: 'initial',
          };
        },
      );

      const formattedTOCs = youngerAge >= 18
        ? typeOfCareOptionsUnfiltered.filter((e) => !e.value.toLowerCase().includes('child'))
        : typeOfCareOptionsUnfiltered;
      setTypeOfCareOptions(formattedTOCs);
      setLoaded(true);
    }
  }, [profile.type_of_cares]);

  return (
    <>
      <div className={styles.header}>
        <h1 className={styles.head}>First, what is the reason for your visit?</h1>
        <span className={styles.subhead}>This will be an initial consultation.</span>
      </div>
      {typeOfCareOptions?.map((elem, index) => (
        <>
          {elem?.description ? (
            <div
              className={clsx(styles.option, {
                [styles.selected]: elem.selected,
              })}
              onClick={() => handleSelect(elem, index)}
            >
              <span className={styles.title}>{elem?.label}</span>
              <span
                ref={(expandable: HTMLSpanElement) => {
                  refs.current[index] = expandable;
                }}
                className={clsx(styles.description, { [styles.show]: elem.selected })}
                style={{
                  height: elem.height,
                }}
              >
                {parse(elem?.description)}
              </span>
            </div>
          ) : null}
        </>
      ))}
    </>
  );
};
