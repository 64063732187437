import * as API from 'intakeOptimization/api/legacy';
import * as API_V1 from 'intakeOptimization/api/v2/obie';
import * as Effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';

import {
  setCliniciansAvailableOnZipCode,
  setErrors,
  setLoading,
  setLoadingCountByZip,
  setValidZipCode,
  updateInsuranceList,
  updatePreferences,
} from 'intakeOptimization/store/preferences/actions';

import t from './types';

const call: any = Effects.call;

function* handleFetchZipCode(action: PayloadAction<string>): any {
  try {
    yield put(setValidZipCode(false));
    yield put(setErrors({ zipCode: '' }));
    const response = yield call(API_V1.validateZipCode, action.payload);
    const insuranceCompanies = response.data.insurances.map((insurance: Array<string>) => ({
      label: insurance,
      value: insurance,
    }));
    const typesOfCare = response.data.type_of_cares.map((typeOfCare: Array<string>) => ({
      label: typeOfCare,
      value: typeOfCare,
    }));

    if (response) {
      yield put(setValidZipCode(true));
      yield put(
        updatePreferences({
          filters: {
            insuranceCompanies,
            typesOfCare,
          },
        }),
      );
    }
  } catch (e: any) {
    if (e instanceof AxiosError) {
      yield put(setValidZipCode(false));
      e as AxiosError;
      yield put(setErrors({ zipCode: e.response?.data.message }));
      yield put(
        updatePreferences({
          filters: {
            insuranceCompanies: [],
            typesOfCare: [],
          },
        }),
      );
    }
  }
}

function* handleValidateZipCode(action: any): any {
  yield put(setLoading(true));
  yield put(setValidZipCode(false));
  try {
    const isZipCodeValid = yield call(API_V1.validateZipCode, action.payload);
    const { city } = isZipCodeValid.data;
    const { state } = isZipCodeValid.data;
    const insuranceCompanies = isZipCodeValid.data.insurances.map((insurance: Array<string>) => ({
      label: insurance,
      value: insurance,
    }));
    const typesOfCare = isZipCodeValid.data.type_of_cares.map((typeOfCare: Array<string>) => ({
      label: typeOfCare,
      value: typeOfCare,
    }));
    yield put(setLoading(false));

    if (isZipCodeValid) {
      yield put(setValidZipCode(true));
      yield put(updatePreferences({ city }));
      yield put(updatePreferences({ state }));
      yield put(
        updatePreferences({
          filters: {
            insuranceCompanies,
            typesOfCare,
          },
        }),
      );
    }
  } catch (e) {
    yield put(setLoading(false));
    yield put(setValidZipCode(false));
    yield put(setErrors({ zipCode: 'Enter a valid zip code for United States' }));
  }
}

function* getInsuranceList(action: any): any {
  try {
    const response = yield call(API.getInsuranceList, action.payload);
    const insuranceCompanies = response?.data?.insurances.map((insurance: Array<string>) => ({
      label: insurance,
      value: insurance,
    }));
    yield put(
      updateInsuranceList({
        insuranceCompanies,
      }),
    );
  } catch (e) {
    yield put(setErrors({ insuranceList: 'Could not fetch data' }));
  }
}

function* getClinicianCountByZipCode(action: PayloadAction<string>): any {
  try {
    yield put(setLoadingCountByZip(true));
    const response = yield call(API_V1.getClinicianCountByZipCode, action.payload);
    const licensedCliniciansOnZip = response.data.clinician_count;
    const state = response.data.state;
    yield put(setCliniciansAvailableOnZipCode(licensedCliniciansOnZip));
    yield put(updatePreferences({ state }));
    yield put(setValidZipCode(true));
    yield put(setLoadingCountByZip(false));
  } catch (e) {
    yield put(setLoadingCountByZip(false));
    if (e instanceof AxiosError) {
      yield put(setValidZipCode(false));
      yield put(setCliniciansAvailableOnZipCode(0));
      yield put(setErrors({ zipCode: 'Enter a valid zip code for United States' }));
    }
  }
}

function* getCliniciansValidate(action: PayloadAction<string>): any {
  try {
    yield put(setValidZipCode(false));
    yield call(API_V1.getClinicianCountByZipCode, action.payload);
    yield put(setValidZipCode(true));
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(setValidZipCode(false));
      yield put(setErrors({ zipCode: 'Enter a valid zip code for United States' }));
    }
  }
}

function* preferencesSaga(): any {
  yield all([
    yield takeLatest(t.VALIDATE_ZIPCODE, handleValidateZipCode),
    yield takeLatest(t.FETCH_ZIP_CODE, handleFetchZipCode),
    yield takeLatest(t.GET_INSURANCE_LIST, getInsuranceList),
    yield takeLatest(t.GET_CLINICIANS_COUNT, getClinicianCountByZipCode),
    yield takeLatest(t.GET_CLINICIANS_VALID, getCliniciansValidate),
  ]);
}

export default preferencesSaga;
