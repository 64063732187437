import { ReactComponent as Eye } from 'intakeOptimization/assets/svg/eye-fill.svg';
import { FC, KeyboardEvent, MouseEvent, useEffect, useState } from 'react';

import { isDesktopQuery, useMediaQuery } from 'intakeOptimization/hooks';

import { simpleKeyboardControl } from 'intakeOptimization/utils/keyboardAccessibility';

import styles from './SeeAllButton.module.scss';

type handler = (match: any, e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => void;

interface ISeeAllProps {
  tabIndex: number;
  match: any;
  onClick: handler;
}
export const SeeAllButton: FC<React.PropsWithChildren<ISeeAllProps>> = ({
  match,
  tabIndex,
  onClick,
}) => {
  const isDesktop = useMediaQuery(isDesktopQuery);
  const isTablet = useMediaQuery('(min-width: 768px)');
  const [text, setText] = useState('');

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    simpleKeyboardControl(e, () => onClick(match, e), true);
  };

  useEffect(() => {
    if (isDesktop) {
      setText('See more availability');
    } else if (isTablet) {
      setText('See more');
    } else {
      setText('More');
    }
  }, []);

  return (
    <div
      tabIndex={tabIndex}
      role="button"
      onKeyDown={handleKeyDown}
      onClick={(e) => onClick(match, e)}
      className={styles.buttonAvailability}
      data-open-schedule-id={match.id}
    >
      <Eye className={styles.eyeIcon} />
      {text}
    </div>
  );
};
