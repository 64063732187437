import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { Clinician } from 'intakeOptimization/@types';

import t from './types';

export type ActiveFilter = {
  name: string;
  value: string[] | number[] | string | number;
};

export type InitialState = {
  loadingCount?: boolean;
  loadingClinicians?: boolean;
  error: any;
  page: number;
  clinicianCount?: number;
  clinicians: Clinician[];
  filteredClinicians: Clinician[];
  displayedClinicians: Clinician[];
  additionalClinicians?: Clinician[];
  activeFilters: ActiveFilter[];
  searchTerm?: string;
  totalPages: number;
  loadingDisplayedClinicians: boolean;
};

const initialState: InitialState = {
  loadingCount: false,
  loadingClinicians: false,
  error: null,
  page: 1,
  clinicianCount: 0,
  clinicians: [],
  filteredClinicians: [],
  displayedClinicians: [],
  additionalClinicians: [],
  activeFilters: [],
  totalPages: 1,
  loadingDisplayedClinicians: false,
};

const cliniciansSlice = createSlice({
  name: 'IO_abieClinicians',
  initialState,
  reducers: {
    loadClinicians: (state, action: PayloadAction<Clinician[]>) => {
      state.clinicians = action.payload;
    },
    loadMoreClinicians: (state, action: PayloadAction<any>) => {
      state.page = action.payload;
      const pageLimitResults = action.payload * 10;
      state.displayedClinicians = state.filteredClinicians.slice(0, pageLimitResults);
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setActiveFilters: (state, action: PayloadAction<Record<string, unknown>>) => {
      const filtersMap = new Map(state.activeFilters.map((filter) => [filter.name, filter.value]));

      Object.entries(action.payload).forEach(([key, value]) => {
        filtersMap.set(key, value as string | string[] | number[]);
      });

      state.activeFilters = Array.from(filtersMap, ([name, value]) => ({ name, value }));
    },
    resetBasicFilters: (state) => {
      const basicFilters = [
        'populations',
        'interventions',
        'expertises',
        'modality',
        'entireState',
      ];
      state.activeFilters = state.activeFilters.filter((filter) => basicFilters.includes(filter.name));
    },
    resetClinicians: (state) => {
      state.loadingClinicians = false;
      state.error = null;
      state.clinicians = [];
    },
    resetPage: (state) => {
      state.page = 1;
    },
    setLoadingCount: (state, action: PayloadAction<boolean>) => {
      state.loadingCount = action.payload;
    },
    setLoadingClinicians: (state, action: PayloadAction<boolean>) => {
      state.loadingClinicians = action.payload;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setDisplayedClinicians: (state, action: PayloadAction<Clinician[]>) => {
      state.displayedClinicians = action.payload;
    },
    setFilteredClinicians: (state, action: PayloadAction<Clinician[]>) => {
      state.filteredClinicians = action.payload;
    },
    setClinicianCount: (state, action: PayloadAction<number>) => {
      state.clinicianCount = action.payload;
    },
    setLoadingDisplayedClinicians: (state, action: PayloadAction<boolean>) => {
      state.loadingDisplayedClinicians = action.payload;
    },
    resetActiveFilters: (state) => {
      state.activeFilters = [];
    },
  },
});

export const {
  loadClinicians,
  loadMoreClinicians,
  setPage,
  resetClinicians,
  resetPage,
  setLoadingCount,
  setLoadingClinicians,
  setError,
  setSearchTerm,
  setDisplayedClinicians,
  setActiveFilters,
  resetBasicFilters,
  setTotalPages,
  setFilteredClinicians,
  setClinicianCount,
  setLoadingDisplayedClinicians,
  resetActiveFilters,
} = cliniciansSlice.actions;

export const getClinicians = createAction<{ activeFilters: Record<string, unknown> }>(
  t.GET_CLINICIANS,
);
export const getCliniciansCount = createAction<{ activeFilters: Record<string, unknown> }>(
  t.GET_CLINICIANS_COUNT,
);
export const getFilteredClinicians = createAction(t.GET_FILTERED_CLINICIANS);
export const getDisplayedClinicians = createAction<{ page: number }>(t.GET_DISPLAYED_CLINICIANS);

export const getMoreFilteredClinicians = createAction<any>(t.GET_MORE_FILTERED_CLINICIANS);

export default cliniciansSlice.reducer;
