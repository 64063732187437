import { ActiveFilter } from 'patientOnboarding/store/cliniciansGlobal/slice';

const validFilters = [
  'concerns',
  'interventions',
  'populations',
  'modality',
  'gender',
  'language',
  'distance',
];

export const getActiveFilters = (filters: ActiveFilter[]) => filters?.filter(
  (filter) => typeof filter?.value !== 'undefined'
      && (typeof filter?.value === 'number'
        ? !!filter?.value
        : (filter?.value as Array<string | number>).length)
      && validFilters.includes(filter?.name),
);
