import clsx from 'clsx';
import { ReactComponent as Question } from 'patientOnboarding/assets/svg/question-line.svg';
import { intakeRoutes } from 'patientOnboarding/routes/absolutes';
import { bookingRoutes } from 'patientOnboarding/routes/constants';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

import { Button, Input, InputMask, Label, Tooltip, TooltipModalMobile } from 'lifestance-ui';

import { getClinicians } from 'patientOnboarding/store/cliniciansGlobal/slice';
import { getClinicianProfile } from 'patientOnboarding/store/matches/reducers';
import {
  getCliniciansByZipCode,
  setErrors,
  setValidZipCode,
  updatePreferences,
  validateZipCode,
} from 'patientOnboarding/store/preferences/actions';
import { updateReservation } from 'patientOnboarding/store/reservationGlobal/actions';

import {
  contactNumber,
  lifestanceState,
  obieEnabled,
  obieMatches,
  obiePreferencesData,
  reservationData,
  selectedClinicianSelector,
} from 'patientOnboarding/selectors/selectors';

import { useMediaQuery } from 'patientOnboarding/hooks';
import { useDebounce } from 'patientOnboarding/hooks/useDebounce';

import {
  SELF_PAY_VALUE,
  cleanNullProperties,
  disableBodyScroll,
  enableBodyScroll,
  getAge,
  getDateInfo,
  getFormatForMatches,
  isDOBWithNoSpecialCharacters,
  simpleAgeAndFormatVerification,
  useDevice,
} from 'patientOnboarding/utils';

import { MatchedConditions } from '../MatchedConditions/MatchedConditions';
import { VerifyingModal } from '../VerifyingModal/VerifyingModal';
import modals from '../zipCodeErrorModals';
import styles from './FilterWall.module.scss';

interface IFilterWallProps {
  handleOpen?: (value: boolean) => void;
  handleBadTypeOfCare: (toc: string) => void;
  setModalToShow: (val: string) => void;
  closeFilterWall: (val: boolean) => void;
}

export const FilterWall: FC<React.PropsWithChildren<IFilterWallProps>> = ({
  handleOpen,
  handleBadTypeOfCare,
  setModalToShow,
  closeFilterWall,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [checkingConditions, setCheckingConditions] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [ageErrorMessage, setAgeErrorMessage] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [zipCode, setZipCode] = useState('');
  const preferences = useSelector(obiePreferencesData);
  const enabledObie = useSelector(obieEnabled);
  const enabledState = useSelector(lifestanceState);
  const reservation = useSelector(reservationData);
  const profile = useSelector(selectedClinicianSelector);
  const [currentAge, setCurrentAge] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const matches = useSelector(obieMatches);
  const dispatch = useDispatch();
  const { errors, age, cliniciansAvailableOnZipCode, isZipCodeValid } = preferences;
  const { id } = useParams();
  const { isDesktop, isMobile } = useDevice();
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const isTabletMobile = useMediaQuery('(max-width: 1024px)');
  const tooltipText = 'Our providers are specialized in supporting patients of specific ages.';
  const debouncedZipcode = useDebounce(zipCode, 500);
  const appendArticle = (toc: string) => {
    const regexp = /^[aeiou]\w*/i;
    const isVowel = regexp.test(toc);
    return isVowel ? `an ${toc}` : `a ${toc}`;
  };
  const variants = {
    mobile: {
      header: 'Continue booking',
      subHeader: `Let’s make sure that ${profile?.first_name} meets all of your needs before booking.`,
    },
    desktop: {
      header: `Continue Booking ${appendArticle(preferences?.typeOfCare)} Initial Consultation`,
      subHeader: `First, let’s make sure that ${profile?.first_name} meets all of your needs.`,
    },
  };
  const messages = isTabletMobile ? variants.mobile : variants.desktop;

  useEffect(() => {
    if (errors.zipCode) {
      setErrorMessage(errors.zipCode);
    }
  }, [errors.zipCode, zipCode]);

  const readZipcode = (value: string) => {
    setZipCode(value);
    if (value.length < 5) {
      dispatch(setValidZipCode(false));
      setErrorMessage('Enter a valid 5 digit zip code');
    }
    dispatch(setErrors({ zipCode: '' }));
    setErrorMessage('');
  };

  useEffect(() => {
    if (zipCode.length === 5) {
      dispatch(validateZipCode(zipCode));
      dispatch(getCliniciansByZipCode(zipCode));
    }
  }, [zipCode]);

  const handleAge = (event: ChangeEvent<HTMLInputElement>) => {
    const currentString = event.target.value;
    setAgeErrorMessage('');
    setDateOfBirth(currentString);
    const { month, day, year } = getDateInfo(currentString);
    const patientAge = getAge(year, month, day);
    setCurrentAge(currentAge);
    dispatch(
      updatePreferences({
        age: patientAge.toString(),
        preliminaryAge: patientAge.toString(),
        preliminaryDOB: currentString,
      }),
    );
  };

  const handleAgeBlur = () => {
    simpleAgeAndFormatVerification(dateOfBirth, setAgeError, setAgeErrorMessage);
  };

  const onBlurZipCode = () => {
    if (zipCode.length < 5) {
      setErrorMessage('Enter a valid 5 digit zip code');
    }
  };

  // TBD with other modal for best matches
  const handleViewMatches = () => {
    dispatch(updatePreferences({ rematch_clinician_id: profile.id }));
    const formattedFilters = getFormatForMatches({
      ...preferences,
      zipCode,
      age,
    });
    if (
      formattedFilters.insurances === 'I don’t see my insurance'
      || formattedFilters.insurances === SELF_PAY_VALUE
    ) {
      formattedFilters.insurances = '';
      formattedFilters.payment_type = SELF_PAY_VALUE;
    }
    const cleanedFilters = cleanNullProperties(formattedFilters);
    dispatch(getClinicians({ activeFilters: cleanedFilters }));
    const queryString = Object.keys(cleanedFilters)
      .map((key) => `${key}=${cleanedFilters[key]}`)
      .join('&');
    navigate(`${bookingRoutes.providerMatches}?${queryString}`, {
      state: { search: queryString, coldLanding: true },
    });
  };

  const checkCompatibility = () => {
    const miliseconds = 2000;
    setIsLoading(true);
    const clinicianId = id?.substring(id.lastIndexOf('-') + 1);
    dispatch(getClinicianProfile(clinicianId, preferences.typeOfCare, zipCode));
    setTimeout(() => {
      if (enabledState && enabledObie && cliniciansAvailableOnZipCode === 0) {
        setModalToShow(modals.noClinicians);
      } else if (enabledState && !enabledObie) {
        setModalToShow(modals.noObie);
      } else if (!enabledState) {
        setModalToShow(modals.noServiceState);
      } else {
        // Happy path :)
        setIsVerifying(true);
      }
      setIsLoading(false);
    }, miliseconds);
  };

  const handleMobileModal = () => {
    setIsMobileModalOpen(!isMobileModalOpen);
  };

  const renderForm = () => (
    <>
      <div className={styles.header}>{messages.header}</div>
      <span className={styles.subHeader}>{messages.subHeader}</span>
      <div className={styles.firstQuestion}>
        <Label>What is your zip code?</Label>
      </div>
      <Input
        id="zipCode"
        type="zipCode"
        placeholder="e.g. 12345"
        onChange={readZipcode}
        error={errorMessage}
        value={zipCode}
        maxlength={5}
        onBlur={() => onBlurZipCode()}
      />
      <div className={styles.questions}>
        <Label className={styles.birthQuestion}>What is the patient’s date of birth?</Label>
        {isMobile ? (
          <>
            <Question onClick={handleMobileModal} />
            {isMobileModalOpen && (
              <TooltipModalMobile
                message={tooltipText}
                title="Why are we asking this?"
                onClose={handleMobileModal}
              />
            )}
          </>
        ) : (
          <Tooltip content={tooltipText} legendOrientation="up" className={styles.tooltip}>
            <Question />
          </Tooltip>
        )}
      </div>
      <InputMask
        defaultValue={dateOfBirth}
        placeholder="MM/DD/YYYY"
        onChange={handleAge}
        onBlur={handleAgeBlur}
        error={ageErrorMessage}
        mask="99/99/9999"
        inputMode="numeric"
      />
      <div className={styles.button}>
        <Button
          key="buttonForCompatibility"
          fullWidth
          onClick={checkCompatibility}
          disabled={isDisabled || !isZipCodeValid}
          isLoading={isLoading}
        >
          Check for compatibility
        </Button>
      </div>
    </>
  );

  const handleContinue = () => {
    navigate(intakeRoutes.intake);
    dispatch(
      updatePreferences({
        clinician_profile_back: [
          ...preferences.clinician_profile_back,
          `${location.pathname}${location.search}`,
        ],
        rematch_clinician_id: 0,
      }),
    );
    dispatch(
      updateReservation({
        selectedFacility: { ...reservation.selectedFacility, license_key: profile.license_key },
      }),
    );
  };

  const handleVerifying = () => {
    setIsVerifying(false);
    setCheckingConditions(true);
  };

  useEffect(() => {
    if (
      errors.zipCode.length > 0
      || matches.loading
      || !age
      || ageError
      || zipCode.length < 5
      || preferences.loading
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [errors.zipCode, matches.loading, age, ageError, zipCode]);

  useEffect(() => {
    if (!isDesktop) {
      setTimeout(() => disableBodyScroll(), 500);
    }
    return () => {
      setTimeout(() => enableBodyScroll(), 500);
    };
  }, [isDesktop]);

  const transitionsPuzzle = useTransition(isVerifying, {
    from: { y: -50, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: -50, opacity: 0 },
    reverse: isVerifying,
    delay: 200,
  });

  const transitionsConditions = useTransition(checkingConditions, {
    from: { y: -50, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: -50, opacity: 0 },
    reverse: checkingConditions,
    delay: 200,
  });

  useEffect(() => {
    if (isDOBWithNoSpecialCharacters(dateOfBirth)) {
      simpleAgeAndFormatVerification(dateOfBirth, setAgeError, setAgeErrorMessage);
    } else {
      setAgeError(true);
    }
  }, [dateOfBirth]);

  return (
    <div
      className={clsx(styles.container, {
        [styles.scrollable]: !dropdownOpen,
      })}
    >
      {!isVerifying && !checkingConditions && renderForm()}
      {isVerifying
        && transitionsPuzzle(
          (styles, item) => item && (
          <animated.div style={styles}>
            <VerifyingModal handleOpen={handleVerifying} />
          </animated.div>
          ),
        )}
      {checkingConditions
        && transitionsConditions(
          (styles, item) => item && (
          <animated.div style={styles}>
            <MatchedConditions
              onClick={handleContinue}
              onViewMoreMatches={handleViewMatches}
              zipCode={zipCode}
              dateOfBirth={dateOfBirth}
              handleRematchTOC={handleBadTypeOfCare}
            />
          </animated.div>
          ),
        )}
    </div>
  );
};
