import { createAction } from '@reduxjs/toolkit';

import { adminFiltersActions } from './reducers';
import t from './types';

export type validateZipCodePayload = {
  zipCode: string;
  appName: string;
  age: number;
};

export type getFocusPayload = {
  zipCode: string;
  typeOfCare: string;
  patientType: string;
};

export type getInsuranceListPayload = {
  zipCode: any;
  typeOfCare: any;
};

export const {
  resetAllFilters,
  resetNoVitalFilters,
  setCredentials,
  setErrors,
  setFocus,
  setFocusOptions,
  setInsuranceCompaniesOptions,
  setLicenseKeys,
  setLocations,
  setSpecialCases,
  setSpecialCasesOptions,
  setTypeOfCareOptions,
  setValidZipCode,
  updateFilters,
  updateInsuranceList,
  setLoading,
  setPatientFormStep,
} = adminFiltersActions;

export const validateZipCode = createAction<validateZipCodePayload>(t.VALIDATE_ZIPCODE);
export const getFocus = createAction<getFocusPayload>(t.GET_FOCUS);
