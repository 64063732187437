import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { ReactComponent as ArrowBlackIcon } from 'lifestance-ui/assets/svg/arrow-left-black.svg';
import { ReactComponent as CloseIcon } from 'lifestance-ui/assets/svg/close.svg';
import { ReactComponent as NewIcon } from 'lifestance-ui/assets/svg/new.svg';
import { useDevice } from 'lifestance-ui/utils';

import { Button } from 'lifestance-ui';

import styles from './MobileDropdownSelect.module.scss';

export const MobileDropdownSelect = ({
  options,
  title,
  handleSelect,
  mobileClose,
  open,
  value,
  icon,
  sideWays,
  testId,
}) => {
  const [selected, setSelected] = useState(value);
  const { isMobile } = useDevice();
  const fullWindowHeight = 667;
  const [windowDimensions, setWindowDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const isiOS = () => ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
    navigator.platform,
  )
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

  const handleSave = () => {
    handleSelect(selected);
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    // Avoid scroll when MobileDropdown opened
    open && isMobile
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'initial');
  }, [open, isMobile]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleOnSelect = (value) => {
    let temp = [...selected];
    if (!selected.includes(value)) {
      temp.push(value);
    } else {
      temp = selected.filter((item) => item !== value);
    }
    setSelected(temp);
  };

  const rendercloseIcon = () => {
    switch (icon) {
      case 'CloseIcon':
        return <CloseIcon />;
      default:
        return <ArrowBlackIcon />;
    }
  };

  const drawDefinitionList = (opts) => (
    <ul data-testId={`mobileDropdownSelect${testId}OptionsContainer`} className={styles.options}>
      {opts.map((option) => (
        <>
          <li key={`labelFor${option.label}`} className={styles.label}>
            {option.label}
          </li>
          {option.options.map((nestedOption, ind) => (
            <li
              key={`list-${nestedOption.value}`}
              data-testId={`mobileDropdownSelect${testId}OptionSetOption${ind}`}
              onClick={() => handleOnSelect(nestedOption.value)}
              className={clsx(styles.item, {
                [styles.lastItem]: ind + 1 === option.options.length,
              })}
            >
              <div
                id={nestedOption.value}
                className={clsx(styles.checkbox, {
                  [styles.checked]: selected.includes(nestedOption.value),
                })}
              />
              <div data-testId={`mobileDropdownSelect${testId}LabelSetOption${ind}`}>
                {nestedOption.label}
              </div>
            </li>
          ))}
        </>
      ))}
    </ul>
  );

  const drawList = (opts, title) => (
    <ul data-testId={`mobileDropdownSelect${testId}OptionsContainer`} className={styles.options}>
      {opts.map((nestedOption, ind) => (
        <li
          key={`list-${nestedOption.value}`}
          data-testId={`mobileDropdownSelect${testId}OptionSetOption${ind}`}
          onClick={() => handleOnSelect(nestedOption.value)}
          className={clsx(styles.item)}
        >
          <div
            id={nestedOption.value}
            className={clsx(styles.checkbox, {
              [styles.checked]: selected.includes(nestedOption.value),
            })}
            data-testId={`mobileDropdownSelect${testId}Checkbox`}
          />
          <div data-testId={`mobileDropdownSelect${testId}LabelSetOption${ind}`}>
            {nestedOption.label}
          </div>
        </li>
      ))}
      {title === 'Type of care' && (
        <li
          data-testId={`mobileDropdownSelect${testId}SetOptionTypeOfCare`}
          className={styles.unsure}
        >
          Not sure
          {' '}
          <span className={styles.new}>
            <NewIcon />
          </span>
        </li>
      )}
    </ul>
  );

  return (
    <div className={clsx({ [styles.scrim]: open })}>
      <div
        className={clsx(styles.wrapper, { [styles.open]: open }, { [styles.sideWays]: sideWays })}
      >
        <div className={clsx(styles.top)}>
          <span
            data-testId={`mobileDropdownSelect${testId}Close`}
            className={styles.handleClose}
            onClick={mobileClose}
          >
            {rendercloseIcon()}
          </span>
          <span data-testId={`mobileDropdownSelect${testId}Title`} className={styles.title}>
            {title}
          </span>
        </div>
        <ul>
          {options && options[0]?.options ? drawDefinitionList(options) : drawList(options, title)}
        </ul>
        <div
          className={clsx(styles.stickyButton, {
            [styles.stickyButtoniOS]: windowDimensions?.height >= fullWindowHeight && isiOS(),
          })}
          data-testId={`buttonMobileDropdownSelect${testId}SaveClose`}
        >
          <Button
            testId="buttonMobileDropdownSelectSaveClose"
            fullWidth
            disabled={selected === null}
            onClick={handleSave}
          >
            Save & close
          </Button>
        </div>
      </div>
    </div>
  );
};
