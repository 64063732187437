import clsx from 'clsx';
import { Clinician } from 'intakeOptimization/@types';
import avatar from 'intakeOptimization/assets/img/rectangle-avatar.jpg';
import { ReactComponent as ArrowRightIcon } from 'intakeOptimization/assets/svg/arrow-right-line.svg';
import { ReactComponent as CaretRight } from 'intakeOptimization/assets/svg/arrow-right.svg';
import { ReactComponent as CheckmarkIcon } from 'intakeOptimization/assets/svg/checkmark.svg';
import { ReactComponent as Map } from 'intakeOptimization/assets/svg/map-fill.svg';
import { bookingRoutes as absoluteBookingRoutes } from 'intakeOptimization/routes/absolutes';
import { bookingRoutes } from 'intakeOptimization/routes/constants';
import React, { KeyboardEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ListCard, Pillbox } from 'lifestance-ui';

import { AdminSchedule, LicenseKeyPillbox, SeeAllButton } from 'intakeOptimization/components';

import { activeFiltersSelector } from 'intakeOptimization/store/clinicians/selectors';
import {
  getClinicianProfile,
  loadClinicianProfile,
} from 'intakeOptimization/store/matches/actions';
import { updatePreferences } from 'intakeOptimization/store/preferences/actions';
import { updateReservation } from 'intakeOptimization/store/reservationGlobal/actions';

import {
  abiePreferencesData,
  isExistingAccountHolder,
  selectedClinicianSelector,
} from 'intakeOptimization/selectors/selectors';

import {
  distanceInMilesLabel,
  sortAvailabilities,
  useDevice,
  useLastEventClickOrTab,
} from 'intakeOptimization/utils';
import { simpleKeyboardControl } from 'intakeOptimization/utils/keyboardAccessibility';

import styles from './SearchResultsAdmin.module.scss';
import { AvailabilityButtons } from './components/AvailabilityButtons/AvailabilityButtons';

interface SearchResultsProps {
  matches: Clinician[];
  brief?: boolean;
  baseRoute: string;
  onNavigate?: ((route: string, provider: Clinician) => void) | null;
}

export const SearchResults: React.FC<React.PropsWithChildren<SearchResultsProps>> = ({
  matches,
  brief,
  baseRoute,
  onNavigate,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<Clinician>(null as unknown as Clinician);

  const activeFilters = useSelector(activeFiltersSelector);
  const { lastEventType } = useLastEventClickOrTab();

  const currentPreferences = activeFilters;
  const preferences = useSelector(abiePreferencesData);
  const topSearch = location.pathname === bookingRoutes.search;
  const { isTablet, isDesktop } = useDevice();

  const [openedScheduleId, setOpenedScheduleId] = useState<number>(0);

  const inOffice = (currentPreferences?.modality as string[])?.includes('in_office');
  const inVirtual = (currentPreferences?.modality as string[])?.includes('video_visit');
  const bothVisitTypes = (inOffice && inVirtual) || (currentPreferences?.modality as string[])?.length === 0;

  const selectedClinician = useSelector(selectedClinicianSelector);

  const matchingExpertises = (
    clinicianExpertises: string[] = [],
    clinicianInterventions: string[] = [],
    clinicianPopulations: string[] = [],
  ) => {
    const concerns = (activeFilters.expertises as string[]).concat(
      activeFilters.interventions as string[],
      activeFilters.populations as string[],
    );
    const clinicianConcerns = clinicianExpertises.concat(
      clinicianInterventions,
      clinicianPopulations,
    );
    const totalMatches = clinicianConcerns.filter((value: any) => concerns?.includes(value));

    const sortedMatches = totalMatches.sort((a, b) => {
      const nameA = a.toUpperCase();
      const nameB = b.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
    return sortedMatches;
  };

  const noAvailability = 'No upcoming availability';
  const isExistingUser = useSelector(isExistingAccountHolder);

  const navigateToProviderUrl = (route: string, provider: Clinician) => {
    const id = `${provider.first_name.toLowerCase()}-${provider.last_name.toLowerCase()}-${
      provider.id
    }`;
    dispatch(
      updateReservation({
        clinician: provider,
        selectedFacility: provider.addresses[0],
      }),
    );
    dispatch(
      updatePreferences({
        location: location.search,
        location_back: `${location.pathname}${location.search}`,
      }),
    );
    return navigate(`${absoluteBookingRoutes.findCare}/${route.replace(':id', id)}`, {
      state: { back: `${location.pathname}${location.search}` },
    });
  };

  const handleNavigate = (route: string, provider: Clinician) => {
    dispatch(loadClinicianProfile(null));
    dispatch(
      updateReservation({
        facilityIds: [provider.addresses[0].facility_id],
      }),
    );
    if (onNavigate) {
      onNavigate(route, provider);
    } else {
      navigateToProviderUrl(route, provider);
    }
  };

  useEffect(() => {
    if (selectedMatch && selectedMatch.id) {
      setOpenCalendar(true);
    }
  }, [selectedClinician]);

  const toggleOpenCalendar = (status: boolean) => {
    if (!status) {
      const empty: any = null;
      setSelectedMatch(empty);
      setOpenCalendar(false);
    }
  };

  const handleAvailabilityView = (
    provider: Clinician,
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
  ) => {
    event.stopPropagation();
    setOpenedScheduleId(parseInt(provider.id, 10) as number);
    dispatch(
      updateReservation({
        selectedFacility: provider.addresses[0],
      }),
    );
    dispatch(getClinicianProfile(provider.id, preferences.typeOfCare, preferences.zipCode));
    dispatch(
      updateReservation({
        facilityIds: [provider.addresses[0].facility_id],
      }),
    );
    setSelectedMatch(provider);
  };

  return matches && matches.length > 0
    ? (matches.map((match, ind) => {
      const sorted_clinician_availabilities = sortAvailabilities(match.clinician_availabilities);
      return (
        <>
          <ListCard
            testId={`Results${ind}`}
            key={`${match.id}in${ind.toString()}`}
            className={clsx({ [styles.brief]: brief })}
            onClick={() => handleNavigate(baseRoute, match)}
            isAdmin
            data-testId="listCard"
            gender={match?.gender}
            languages={match.languages.map((l) => l.name)}
            topSearch={topSearch}
          >
            <div className={clsx({ [styles.topSearch]: topSearch }, styles.resultDetails)}>
              <img
                onClick={() => handleNavigate(baseRoute, match)}
                className={clsx(
                  styles.resultThumbnail,
                  styles.clickableInfo,
                  styles.thumbnailAdmin,
                )}
                src={match.photo && match.photo.length ? match.photo : avatar}
                alt={match.first_name}
                data-testId="clinicianAvatar"
              />
              <div className={styles.resultInfo}>
                <div
                  className={clsx(styles.clickableInfo, styles.abieResultBasic)}
                  onClick={() => handleNavigate(baseRoute, match)}
                >
                  <p data-testId="role" className={clsx(styles.tagAdmin, styles.tag)}>
                    {match?.credentials?.find((f) => f === 'Supervised') ? 'SUPERVISED ' : null}
                    {match.clinician_type}
                  </p>
                  <h2
                    className={clsx(styles.resultTitleAdmin, styles.resultTitle)}
                    data-testId="resultTitle"
                  >
                    {`${match.first_name} ${match.last_name}${
                      match.license_type ? `, ${match.license_type}` : ''
                    }`}
                    <span
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e) => simpleKeyboardControl(e, () => handleNavigate(baseRoute, match))}
                      onClick={() => handleNavigate(baseRoute, match)}
                    >
                      <ArrowRightIcon
                        className={styles.blueArrowRight}
                        data-testId="ArrowRightBlueIcon"
                      />
                    </span>
                    <span>
                      <CaretRight className={styles.arrowRight} data-testId="CaretRight" />
                    </span>
                  </h2>
                  <div
                    className={clsx(
                      {
                        [styles.noMarginWrapperAdmin]:
                            matchingExpertises(
                              match.expertises as unknown as string[],
                              match.interventions as unknown as string[],
                              match.populations as unknown as string[],
                            ).length === 0,
                      },
                      styles.expertisesWrapper,
                      styles.expertisesWrapperAdmin,
                    )}
                  >
                    {matchingExpertises(
                        match.expertises as unknown as string[],
                        match.interventions as unknown as string[],
                        match.populations as unknown as string[],
                    ).map((expertise) => (
                      <div className={styles.pillboxContainer}>
                          <Pillbox mode="main" iconElement={<CheckmarkIcon />}>
                            {`${expertise}`}
                          </Pillbox>
                        </div>
                    ))}
                  </div>
                  {!brief
                      && (match.clinician_availabilities
                      && match.clinician_availabilities.length > 0 ? (
                        <div className={clsx(styles.resultAvailabilityButtonsAdmin)}>
                          <div className={styles.buttonsAdmin}>
                            <AvailabilityButtons
                              availabilities={sorted_clinician_availabilities}
                              bothVisits={bothVisitTypes}
                              video={inVirtual}
                              office={inOffice}
                              clinician={match}
                              onSelect={() => {}}
                              isAdmin
                            />
                          </div>
                          {(isTablet || isDesktop) && (
                            <SeeAllButton
                              onClick={handleAvailabilityView}
                              tabIndex={0}
                              match={match}
                            />
                          )}
                        </div>
                        ) : (
                          <div
                            style={{ display: `${!isExistingUser ? '' : 'none'}` }}
                            className={clsx(styles.noAvailability, styles.desktop)}
                          >
                            <p>{noAvailability}</p>
                          </div>
                        ))}
                </div>
                <div className={clsx(styles.resultExtraAdmin, styles.resultExtra)}>
                  <div className={styles.officeDetailsAdmin}>
                    {match.hasMultipleAddresses && (
                    <div className={styles.multipleOfficesPillAdmin}>
                      <Pillbox
                            mode="beige"
                            iconElement={<Map className={styles.mapIcon} />}
                            className={styles.multipleOfficesPill}
                          >
                            Multiple offices
                          </Pillbox>
                    </div>
                    )}
                    <LicenseKeyPillbox licenseKey={match.addresses[0].license_key} />
                  </div>
                  <div
                    className={clsx(styles.resultAddress, styles.resultAddressAdmin)}
                    data-testId="resultAddress"
                  >
                    {match.distance_in_miles
                        && Number(match.distance_in_miles) === match.distance_in_miles && (
                          <>
                            {distanceInMilesLabel(match.distance_in_miles)}
                            <br />
                          </>
                    )}
                    {match.addresses[0].facility_name}
                    <br />
                    {match.addresses[0].address_line1}
                    <br />
                    {match.addresses[0].city}
                    ,&nbsp;
                    {match.addresses[0].state}
                    {/* {!brief && <LineBottom />} */}
                  </div>
                </div>
              </div>
            </div>
            {!topSearch && (
            <div className={clsx(styles.availabilityMobile, styles.availabilityMobileAdmin)}>
              {match.clinician_availabilities
                  && match.clinician_availabilities.length
                  && !topSearch ? (
                    <AvailabilityButtons
                      availabilities={sorted_clinician_availabilities}
                      bothVisits={bothVisitTypes}
                      video={inVirtual}
                      office={inOffice}
                      clinician={match}
                      onSelect={() => {}}
                      isAdmin
                    />
                ) : (
                  <div
                    style={{ display: `${!isExistingUser ? '' : 'none'}` }}
                    className={clsx(styles.noAvailability)}
                  >
                    <p>{noAvailability}</p>
                  </div>
                )}
            </div>
            )}
          </ListCard>
          {openCalendar && ind === 0 && (
          <AdminSchedule
            onCancel={() => {
              if (lastEventType === 'tab') {
                setTimeout(() => {
                  (
                        document?.querySelector(
                          `[data-open-schedule-id="${openedScheduleId}"`,
                        ) as HTMLDivElement
                  )?.focus();
                }, 500);
              }
              toggleOpenCalendar(false);
            }}
            bothVisits={bothVisitTypes}
            video={inVirtual}
            office={inOffice}
          />
          )}
        </>
      );
    }) as unknown as ReactElement)
    : null;
};
