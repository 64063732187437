import clsx from 'clsx';
import React, { FC } from 'react';

import styles from './FormLabel.module.scss';

interface IFormLabelProps {
  label: string;
  isOptional?: boolean;
  containerClassName?: string;
  labelClassName?: string;
}

export const FormLabel: FC<React.PropsWithChildren<IFormLabelProps>> = ({
  label,
  isOptional = false,
  containerClassName,
  labelClassName,
}) => (
  <div className={clsx(containerClassName, styles.container)}>
    <p className={styles.label}>{label}</p>
    {isOptional && <p className={styles.optional}>Optional</p>}
  </div>
);
