import { hotjar } from 'react-hotjar';

import { getEnvironment } from './miscellaneous';

// for environments [env]-book.lifestance.com
const environmentIDs = {
  uat: 2582856,
  qa: 2582866,
  dev: 2534028,
  book: 2598550, // production: no prefix
};

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const hotjarInitialize = () => {
  const { host } = window.location;
  const env = isDev ? 'dev' : getEnvironment(host);
  hotjar.initialize(environmentIDs[env], 6);
};

export default hotjarInitialize;
