import clsx from 'clsx';
import { ReactComponent as LightbulbIcon } from 'patientOnboarding/assets/svg/lightbulb-line.svg';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, SpanButton } from 'lifestance-ui';

import { Modal } from 'patientOnboarding/components';

import { setExistsInAmd } from 'patientOnboarding/store/userGlobal/actions';

import {
  contactInfo as contactInfoSelector,
  obiePreferencesData,
  selectedFacilityData,
} from 'patientOnboarding/selectors/selectors';

import { useInvoca } from 'patientOnboarding/hooks';

import {
  accountExistError,
  defaultPhoneNumber,
  formatContactNumber,
  getContactInfoByState,
  patientExistError,
} from 'patientOnboarding/utils';

import styles from './UserExistsModal.module.scss';

interface Props {
  name: string;
  lastname: string;
  error?: {
    status?: number;
    message?: string;
  };
  portal: string;
  existsInAMD: boolean;
}

interface IMessage {
  name: string;
  lastName: string;
  phoneNumber?: string;
}

const Message: FC<IMessage> = ({ name, lastName, phoneNumber }) => {
  useInvoca();
  return (
    <p className={styles.text}>
      Looks like &nbsp;
      {name}
      &nbsp;
      {lastName}
      &nbsp; is an existing patient of ours. Sign in to your LifeStance Patient Portal account or
      call us at&nbsp;
      <span className={styles.numberMobile}>
        <a
          className={clsx(styles.phone, 'invocaPhoneNumber')}
          href={`tel:${formatContactNumber(phoneNumber)}`}
        >
          <SpanButton className="invocaPhoneNumber">{phoneNumber || defaultPhoneNumber}</SpanButton>
        </a>
      </span>
      <span className={clsx(styles.number, 'invocaPhoneNumber')}>
        {phoneNumber || defaultPhoneNumber}
      </span>
      &nbsp; to book an appointment.
    </p>
  );
};

export const UserExistsModal: FC<React.PropsWithChildren<Props>> = ({
  name = '',
  lastname = '',
  error,
  portal,
  existsInAMD,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const contactInfo = useSelector(contactInfoSelector);
  const preferences = useSelector(obiePreferencesData);
  const facility = useSelector(selectedFacilityData);
  const dispatch = useDispatch();
  const state = facility?.state ? facility.state : preferences.state;
  const licenseKey = facility?.license_key ? facility.license_key : preferences.licenseKey;
  const contactInfoByState = getContactInfoByState(contactInfo, state, licenseKey as number);
  const contactNumber = preferences.marketingReferralPhone.length > 0
    ? preferences.marketingReferralPhone
    : contactInfoByState?.intake_call_in_number;

  useEffect(() => {
    setIsModalOpen(error?.message === accountExistError || error?.message === patientExistError);
    return () => {
      setIsModalOpen(false);
    };
  }, [error, existsInAMD]);

  const handleClick = () => {
    window.location.href = portal;
  };

  return (
    <Modal
      modalOpen={isModalOpen}
      title=""
      closeModal={() => {
        setIsModalOpen(false);
        dispatch(setExistsInAmd(false));
      }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.iconContainer}>
          <LightbulbIcon />
        </div>
        <h1 className={styles.title}>This patient already exists</h1>
        <Message name={name} lastName={lastname} phoneNumber={contactNumber} />
      </div>

      <div className={styles.portalButton}>
        <Button onClick={handleClick} fullWidth>
          Sign in to Patient Portal
        </Button>
      </div>
    </Modal>
  );
};
