import clsx from 'clsx';
import { ReactComponent as BuildingIcon } from 'patientOnboarding/assets/svg/building_select.svg';
import { ReactComponent as CaretDownIcon } from 'patientOnboarding/assets/svg/caret-down.svg';
import { ReactComponent as CaretUpIcon } from 'patientOnboarding/assets/svg/caret-up.svg';
import { ReactComponent as Line } from 'patientOnboarding/assets/svg/list-card-divider.svg';
import { ReactComponent as VideoChatIcon } from 'patientOnboarding/assets/svg/video_select.svg';
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Pillbox, SelectCardPillbox } from 'lifestance-ui';

import { updatePreferences } from 'patientOnboarding/store/preferences/actions';
import { updateReservation } from 'patientOnboarding/store/reservationGlobal/actions';

import {
  isAbie,
  obiePreferencesData,
  reservationData,
  selectedClinicianSelector,
} from 'patientOnboarding/selectors/selectors';

import { hasVideoAvailability, onClickOut } from 'patientOnboarding/utils';

import styles from './LocationDropdown.module.scss';

type SelectedFacilities = {
  isVideoOnly: boolean;
  facilityIds: number[];
};

interface LocationDropdownProps {
  className?: string;
  disabled?: boolean;
  onChange?: any;
  placeholder?: string;
  value: string;
  testId?: string;
  dataCy?: string;
  sm?: boolean;
  setIsDisabledLocation: (value: boolean) => void;
  setFacilities: (facilities: SelectedFacilities) => void;
  scheduleModal?: boolean;
}

export const LocationDropdown: React.FC<React.PropsWithChildren<LocationDropdownProps>> = ({
  className = '',
  disabled = false,
  onChange,
  dataCy = '',
  placeholder = '',
  testId = '',
  value,
  sm = false,
  setIsDisabledLocation,
  setFacilities,
  scheduleModal,
}) => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(isAbie);
  const profile = useSelector(selectedClinicianSelector);
  const telehealth = profile?.virtual_visit === true && hasVideoAvailability(profile);
  const preferences = useSelector(obiePreferencesData);
  const reservation = useSelector(reservationData);
  const [locations, setLocations] = useState([]);
  const facilityLocation = profile.facility_location;
  const [videoOption, setVideoOption] = useState(reservation?.isVideoOnly);
  const [selectedFacility, setSelectedFacilities] = useState<number[]>(reservation?.facilityIds);
  const [selectedFacilityInfo, setSelectedFacilitiesInfo] = useState<any[]>(
    facilityLocation.filter((f: any) => reservation?.facilityIds?.includes(f.facility_id)),
  );

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const [coldLandingFlag, setColdLandingFlag] = useState(false);
  const [selectedRef, setSelectedRef] = useState<HTMLDivElement>(null as unknown as HTMLDivElement);
  const [bodyScroll, setBodyScroll] = useState<boolean>(true);
  const [ready, setReady] = useState<boolean>(false);
  const optionsContainerRef = useRef<any>();
  const optionRefs = useRef<MutableRefObject<HTMLLIElement>[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isColdLanding = !isAdmin
    ? preferences?.location_back?.length === 0 || profile?.id === preferences?.rematch_clinician_id
    : null;

  const videoPillbox = [{ label: 'No location preference' }];
  const { paymentMethod, insuranceCompany } = preferences;

  const containsValue = (facility: any) => facility?.insurances?.some(
    (insurance: { id: number; name: string }) => insurance.name === insuranceCompany,
  );

  const handleSelectFacility = (facility: any, isClick?: boolean) => {
    const facilityId = facility?.facility_id;
    const arr = selectedFacility.filter(
      (value, index, selectedFacility) => selectedFacility.indexOf(value) === index,
    );

    if (isClick) {
      dispatch(updatePreferences({ modality: ['in_office'], entireState: 'false' }));
      dispatch(updateReservation({ isVideoOnly: false, modality: 'IN-OFFICE' }));
    }

    if (reservation?.isVideoOnly) {
      // If it comes from video only it would only be 1 location.
      setSelectedFacilities([facilityId]);
      dispatch(
        updateReservation({ facilityIds: [facilityId], isVideoOnly: false, modality: 'IN-OFFICE' }),
      );
      setSelectedFacilitiesInfo(facilityLocation.filter((f: any) => f.facility_id === facilityId));
      setFacilities({
        isVideoOnly: false,
        facilityIds: [facilityId],
      });
    } else {
      if (!arr.includes(facilityId)) {
        arr.push(facilityId);
      } else if (profile?.facility_location?.length > 1 || telehealth) {
        arr.splice(arr.indexOf(facilityId), 1);
      }

      if (!isColdLanding && !coldLandingFlag) {
        // We do this just ONCE
        if (!arr.includes(reservation?.facilityIds[0]) && !isClick) {
          arr.push(reservation?.facilityIds[0]);
          setColdLandingFlag(true);
        }
      }

      setSelectedFacilities(arr);
      dispatch(updateReservation({ facilityIds: arr, isVideoOnly: false, modality: 'IN-OFFICE' }));
      setSelectedFacilitiesInfo(facilityLocation.filter((f: any) => arr.includes(f.facility_id)));
      setFacilities({
        isVideoOnly: false,
        facilityIds: arr,
      });
    }

    dispatch(updatePreferences({ modality: ['in_office'] }));
    setVideoOption(false);
    setIsDisabledLocation(false);

    if (arr.length === 0 && !videoOption && telehealth) {
      handleSelectVideo(facilityLocation);
    }

    setColdLandingFlag(true);
  };

  const handleSelectVideo = (facilities: any) => {
    const facilityIds = facilities.map((facility: any) => facility.facility_id);
    setVideoOption(true);
    setSelectedFacilities([]);
    dispatch(updatePreferences({ modality: ['video_visit'] }));
    dispatch(updateReservation({ facilityIds, modality: 'VIDEO', isVideoOnly: true }));
    setIsDisabledLocation(false);
    setFacilities({
      isVideoOnly: true,
      facilityIds: [...facilityIds],
    });
  };

  const handleClick = useCallback(() => {
    if (!disabled) setOpen(!open);
  }, [disabled, setOpen, open]);

  useEffect(() => {
    if (!isColdLanding) {
      const isArray = Array.isArray(preferences.modality);
      let isVideo = false;
      let isOffice = false;
      if (isArray) {
        isVideo = preferences.modality.includes('video_visit');
        isOffice = preferences.modality.includes('in_office');
      } else {
        isVideo = (preferences.modality as unknown as string) === 'video_visit';
        isOffice = (preferences.modality as unknown as string) === 'in_office';
      }

      if (reservation?.facilityIds.length === 1) {
        if (isVideo && !isOffice) {
          handleSelectVideo(facilityLocation);
        } else if (!coldLandingFlag && !scheduleModal) {
          handleSelectFacility(reservation?.selectedFacility, false);
        }
      }
    }
  }, []);

  useEffect(() => {
    /**
     * This useEffect helps us reflect the value selected on the Schedule component back in the NewPatientBox
     */
    if (ready) {
      const isArray = Array.isArray(preferences.modality);
      let isVideo = false;
      let isOffice = false;
      if (isArray) {
        isVideo = preferences.modality.includes('video_visit');
        isOffice = preferences.modality.includes('in_office');
      } else {
        isVideo = (preferences.modality as unknown as string) === 'video_visit';
        isOffice = (preferences.modality as unknown as string) === 'in_office';
      }
      const arr = reservation?.facilityIds;
      if (isVideo && !isOffice) {
        setIsDisabledLocation(false);
      } else {
        setSelectedFacilitiesInfo(facilityLocation.filter((f: any) => arr.includes(f.facility_id)));

        if (arr.length === 0 && !videoOption && telehealth) {
          handleSelectVideo(facilityLocation);
        }
      }
      setVideoOption(reservation.isVideoOnly);
      setSelectedFacilities(reservation.facilityIds);
      setFacilities({
        isVideoOnly: reservation.isVideoOnly,
        facilityIds: reservation.facilityIds,
      });
    }
    setReady(true);
  }, [preferences.modality, reservation?.facilityIds, reservation.isVideoOnly]);

  useEffect(() => {
    if (paymentMethod === 'self_pay') {
      setLocations(facilityLocation.filter((f: any) => f.clinician_availabilities?.length > 0));
    } else {
      const filteredLocations = facilityLocation.filter(containsValue);
      const physicalLocations = filteredLocations.filter((facility: any) => facility.in_office);
      // if (physicalLocations.length === 0) {
      //   handleSelectVideo(filteredLocations);
      // }
      setLocations(facilityLocation.filter((f: any) => f.clinician_availabilities?.length > 0));
    }
  }, [paymentMethod, insuranceCompany]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        const optionBounds = optionsContainerRef.current?.getBoundingClientRect();
        if (optionBounds.y + optionBounds.height > document.documentElement.clientHeight) {
          document.documentElement.scrollTo({
            top: optionBounds.y - optionBounds.height + document.documentElement.scrollTop,
            behavior: 'smooth',
          });
        }
      }, 200);
      return onClickOut({
        triggerRef: dropdownRef,
        action: handleClick,
        ignoreParent: true,
      });
    }
  }, [open, handleClick]);

  useEffect(() => {
    !bodyScroll
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'initial');
  }, [bodyScroll]);

  const focusPreviouslySelectedOption = () => {
    setTimeout(() => {
      if (selectedRef !== null) {
        selectedRef?.focus();
      }
    }, 100);
  };

  const selectOption = (element: any) => {
    if (element) {
      const value = element?.getAttribute('data-value');
      element.focus();
      setSelectedValue(value);
      onChange(value);
      setSelectedRef(element);
    }
  };

  const selectFirstOption = () => {
    setTimeout(() => {
      const element = optionRefs.current[0].current;
      selectOption(element);
    }, 100);
  };

  const openAndFocusOption = () => {
    setOpen(true);
    if (selectedValue === placeholder) {
      selectFirstOption();
    } else {
      focusPreviouslySelectedOption();
    }
  };

  const handleNavigate = (event: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>) => {
    event.stopPropagation();
    switch (event.keyCode) {
      case 38: // Arrow Up
        if (!open) {
          openAndFocusOption();
        }
        if (!selectedRef) {
          return;
        }
        if (selectedRef) {
          const previousOption = selectedRef?.previousSibling as HTMLLIElement;
          selectOption(previousOption);
        }
        break;
      case 40: // Arrow Down
        if (!open) {
          openAndFocusOption();
        } else if (!selectedRef) {
          selectFirstOption();
        } else {
          const nextOption = selectedRef.nextSibling as HTMLLIElement;
          selectOption(nextOption);
        }
        break;
      case 13: // Enter
        if (!open) {
          openAndFocusOption();
        } else {
          setOpen(!open);
          if (dropdownRef.current !== null) dropdownRef.current.focus();
        }
        break;
      case 27: // Escape
      case 9: // Tab
        setOpen(false);
        break;
      default:
    }
  };

  return (
    <div
      tabIndex={0}
      role="listbox"
      className={clsx(className, styles.container)}
      ref={dropdownRef}
      onKeyDown={handleNavigate}
    >
      <div
        className={clsx(styles.dropdown, {
          [styles.selectOpen]: open,
          [styles.selected]: selectedValue !== placeholder,
          [styles.disabled]: disabled,
        })}
        data-cy={dataCy || testId}
        data-testId={`dropdown${testId}`}
        onKeyDown={handleNavigate}
        onClick={handleClick}
      >
        <span
          className={clsx(styles.value, {
            [styles.placeholder]: selectedFacility.length === 0 && !videoOption,
          })}
        >
          {' '}
          {selectedFacility.length > 1 && !videoOption && (
            <Pillbox mode="main" className={styles.multipleLocationsPill}>
              Multiple locations
            </Pillbox>
          )}
          {selectedFacility.length === 1
            && !videoOption
            && `${selectedFacilityInfo[0]?.city} | ${selectedFacilityInfo[0]?.address_line1}, ${selectedFacilityInfo[0]?.state} ${selectedFacilityInfo[0]?.postal_code}`}
          {selectedFacility.length === 0 && !videoOption ? placeholder : ''}
          {videoOption && telehealth ? 'Video visit (no location preference)' : ''}
        </span>
        {open ? <CaretUpIcon /> : <CaretDownIcon />}
      </div>
      {open && (
        <div
          data-testId={`dropdownOptionsContainer${testId}`}
          className={clsx(className, styles.optionsContainer, { [styles.small]: sm })}
          onKeyDown={handleNavigate}
          onMouseEnter={() => setBodyScroll(false)}
          onMouseLeave={() => setBodyScroll(true)}
          ref={optionsContainerRef}
        >
          {telehealth && (
            <SelectCardPillbox
              header="Video visits only"
              pillboxOptions={videoPillbox}
              pillboxClassName={styles.bluePillbox}
              onClick={handleSelectVideo}
              selected={videoOption as boolean}
              data={locations}
            />
          )}
          {locations.length > 0 ? (
            <>
              {telehealth && (
                <div className={styles.divider}>
                  <Line />
                </div>
              )}
              {locations.map((facility: any, i: number) => {
                const facilityPillbox = [];
                if (facility.video_visit && telehealth) facilityPillbox.push({ label: 'Video', icon: <VideoChatIcon /> });
                if (facility.in_office) facilityPillbox.push({ label: 'In-office', icon: <BuildingIcon /> });
                const addressFormat = `${facility.address_line1}, ${facility.city}, ${facility.state} ${facility.postal_code}`;
                return (
                  <SelectCardPillbox
                    header={facility.city}
                    content={addressFormat}
                    pillboxOptions={facilityPillbox}
                    className={styles.selectCard}
                    onClick={() => handleSelectFacility(facility, true)}
                    selected={selectedFacility.includes(facility.facility_id) && !videoOption}
                    data={facility}
                    extraContent={
                      facility?.distance_in_miles
                        ? `${facility.distance_in_miles} miles away`
                        : undefined
                    }
                  />
                );
              })}
            </>
          ) : (
            <>
              <div className={styles.divider}>
                <Line />
              </div>
              <div className={styles.noPhysicalLocations}>No in-office appointments available</div>
            </>
          )}
          {!telehealth && (
            <>
              <div className={styles.divider}>
                <Line />
              </div>
              <div className={styles.noPhysicalLocations}>No video appointments available</div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
