import * as API from 'intakeOptimization/api/legacy';
import * as API_V1 from 'intakeOptimization/api/v2/abie';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setIsEnabled, setIsStateEnabled } from 'intakeOptimization/store/admin/actions';

import {
  setConsents,
  setError,
  setHasAddress,
  setHasInsurance,
  setInsuranceDetails,
  setInsuranceSkipFlag,
  setLoading,
  setSignedConsents,
} from './actions';
import t from './types';

function* handleGetConsents(action) {
  try {
    const response = yield call(API.getAllConsents, action.payload);
    const consents = response.data;
    yield put(setConsents(consents));
  } catch (err) {
    // TBD
    console.log(err);
  }
}

function* handleSignConsents(action) {
  try {
    yield put(setLoading(true));
    const responseConsent = yield call(API.saveSignedConsents, action.payload);
    const signedConsents = responseConsent.data;
    if (signedConsents) {
      yield put(setSignedConsents(true));
    }
    yield put(setLoading(false));
  } catch (e) {
    yield put(setLoading(false));
    if (e.response.data.message === 'Appointment no longer available') {
      // yield put(setAvailability(false));
    }
    yield put(setError(e.response?.status));
  }
}

function* handleSaveAddressData(action) {
  try {
    yield put(setLoading(true));
    yield call(API.saveAddressInfo, action.payload);
    yield put(setHasAddress(true));
    yield put(setLoading(false));
  } catch (err) {
    // TBD
    yield put(setLoading(false));
  }
}

function* handleSaveInsuranceDetails(action) {
  try {
    yield put(setLoading(true));
    yield call(API.saveInsuranceDetails, action.payload);
    yield put(setHasInsurance(true));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
  }
}

function* handleValidateZipCode(action) {
  try {
    const zipCode = action.payload;
    const response = yield call(API_V1.validateZipCode, zipCode);
    const { city, state, enabled_abie, lifestance_state } = response.data;
    yield put(setIsEnabled(!!enabled_abie));
    yield put(setIsStateEnabled(!!lifestance_state));
    yield put(
      setInsuranceDetails({
        zipCode,
        city,
        state,
      }),
    );
  } catch (error) {
    // TBD
  }
}

function* handleGetInsuranceSkipFlag(action) {
  try {
    const response = yield call(API.getLicenseKeyRules, action.payload);
    const licenseSkipFlag = response.data.insurance_skip_option_flag;
    yield put(setInsuranceSkipFlag(licenseSkipFlag));
  } catch (error) {
    // TBD
  }
}

function* consentSaga() {
  yield all([
    yield takeLatest(t.GET_CONSENTS, handleGetConsents),
    yield takeLatest(t.SIGN_CONSENTS, handleSignConsents),
    yield takeLatest(t.SAVE_ADDRESS, handleSaveAddressData),
    yield takeLatest(t.VALIDATE_ZIP_CODE, handleValidateZipCode),
    yield takeLatest(t.SAVE_INSURANCE_DETAILS, handleSaveInsuranceDetails),
    yield takeLatest(t.GET_INSURANCE_SKIP_FLAG, handleGetInsuranceSkipFlag),
  ]);
}

export default consentSaga;
