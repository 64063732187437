import { ReactComponent as CloseIcon } from 'patientOnboarding/assets/svg/close.svg';
import React from 'react';

import styles from './Modal.module.scss';

interface ModalProps {
  modalOpen: boolean;
  title?: string;
  closeModal?: () => void;
  testId?: string;
}

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  modalOpen = true,
  title,
  closeModal,
  children,
  testId = '',
}) => {
  const handleCloseModal = () => {
    if (closeModal) closeModal();
  };

  if (modalOpen) {
    return (
      <div className={styles.overlay}>
        <div className={styles.content}>
          <div className={styles.header}>
            {closeModal && (
              <>
                <div className={styles.icon}>
                  <CloseIcon data-testId={`closeModal${testId}`} onClick={handleCloseModal} />
                </div>
                <div className={styles.divider} />
              </>
            )}
            <p className={styles.title}>{title}</p>
          </div>
          <div>{children}</div>
        </div>
      </div>
    );
  }
  return <></>;
};
