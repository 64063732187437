import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Clinician } from 'patientOnboarding/@types';
import { bookingRoutes } from 'patientOnboarding/routes/constants';

import { userLogout } from 'modules/application/store/actions';

export type InitialState = {
  loading: boolean;
  error: null | string;
  searchTerm: string;
  clinicians: Clinician[];
  previousPath: string;
};

const initialState: InitialState = {
  loading: true,
  error: null,
  searchTerm: '',
  clinicians: [],
  previousPath: bookingRoutes.matchCriteria,
};

export const searchesSlice = createSlice({
  name: 'PO_searches',
  initialState,
  reducers: {
    resetMatches: (state) => ({
      ...state,
      loading: false,
      error: null,
      clinicians: [],
    }),
    loadMatches: (state, action: PayloadAction<Clinician[]>) => ({
      ...state,
      loading: false,
      ...action.payload,
    }),
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    clearSearchTerm: (state) => {
      state.searchTerm = '';
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<null | string>) => {
      state.error = action.payload;
    },
    setPreviousPath: (state, action: PayloadAction<string>) => {
      state.previousPath = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, () => ({
      ...initialState,
    }));
  },
});

export const searchesActions = searchesSlice.actions;
export default searchesSlice.reducer;
