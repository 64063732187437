import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Patient {
  name: string;
  id: string;
}

export interface SessionData {
  patient_info?: {
    authorized_patients_list: Patient[];
  };
  error?: string;
}

const initialState: { data: SessionData } = {
  data: {
    patient_info: {
      authorized_patients_list: [],
    },
  },
};

export const sessionSlice = createSlice({
  name: 'IO_session',
  initialState,
  reducers: {
    setSessionData: (state, action: PayloadAction<SessionData>) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const sessionActions = sessionSlice.actions;
export default sessionSlice.reducer;
