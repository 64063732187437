import clsx from 'clsx';
import React, { FC, useState } from 'react';

import { ReactComponent as CheckIcon } from 'lifestance-ui/assets/svg/check-line.svg';

import styles from './Checkbox.module.scss';

interface CheckboxProps {
  caption: string;
  onChange: (isActive: boolean) => void;
  className?: string;
  value: boolean;
  testId?: string;
  customStyles?: any;
}

export const Checkbox: FC<React.PropsWithChildren<CheckboxProps>> = ({
  caption,
  onChange,
  className,
  value,
  testId,
  customStyles,
}) => {
  const [isActive, setIsActive] = useState(value);

  const handleCheckClick = () => {
    onChange && typeof onChange === 'function' && onChange(!isActive);
    setIsActive(!isActive);
  };

  return (
    <div
      onClick={handleCheckClick}
      className={clsx(styles.container, className)}
      data-testId={`checkbox${testId}`}
    >
      <div
        className={clsx(styles.check, {
          [styles.checked]: isActive,
        })}
        data-checked={isActive ? 'checked' : 'notChecked'}
      >
        <CheckIcon />
      </div>
      <p
        data-testId={`checkbox${testId}Caption`}
        className={clsx(styles.caption, {
          [styles.customStyles]: customStyles,
        })}
      >
        {caption}
      </p>
    </div>
  );
};
