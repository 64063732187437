// To disable a feature the value in desktop/mobile should be true
const featureFlags = [
  {
    name: 'insuranceCard',
    desktop: false,
    mobile: false,
  },
  {
    name: 'creditCardOnFile',
    desktop: false,
    mobile: false,
  },
];
export default featureFlags;
