import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import {
  ExpertiseListOptions,
  FocusOptionsAbie,
  GlobalLabelValue,
  InsuranceCompaniesAbie,
  LocationFilter,
  SpecialCases,
  SpecialCasesOptions,
  TypeOfCareOptions,
  TypesOfCareAbie,
} from 'patientOnboarding/@types';

import { userLogout } from 'modules/application/store/actions';

import t from './types';

type Errors = {
  zipCode: string;
  insuranceList: string;
};

type Filters = {
  insuranceCompanies: GlobalLabelValue[];
  typesOfCare: TypesOfCareAbie[];
};

export type InitialState = {
  age: string;
  allCredentials: GlobalLabelValue[];
  allLicenseKeys: number[];
  allLocations: LocationFilter[];
  allSpecialCases: SpecialCases[];
  availability_filter: string[];
  city: string;
  credentials: string[];
  distance: string;
  entireState: string;
  errors: Errors;
  expertises: string[];
  expertisesListOptions: ExpertiseListOptions[];
  facilityId: number | null;
  filters: Partial<Filters>;
  focusOptions: FocusOptionsAbie[];
  gender: string[];
  insuranceCompanies: InsuranceCompaniesAbie[];
  insuranceCompany: string;
  interventions: string[];
  isZipCodeValid: boolean;
  language: string[];
  licenseKeys: string[];
  location_back: string;
  location_names: string[];
  modality: string[];
  patientType: 'self' | 'child';
  paymentMethod: string;
  populations: string[];
  preliminaryAge: string;
  sortType: string;
  specialCases: string;
  specialCasesOptions: SpecialCasesOptions[];
  state: string;
  typeOfCare: string;
  typeOfCareOptions: TypeOfCareOptions[];
  utc_offset: string;
  zipCode: string;
  clinician_profile_back: string[];
  isLoading: boolean;
  patientFormStep: number;
};

const initialState: InitialState = {
  patientType: 'self',
  zipCode: '',
  city: '',
  state: '',
  errors: {
    zipCode: '',
    insuranceList: '',
  },
  isZipCodeValid: false,
  expertises: [],
  paymentMethod: 'insurance',
  insuranceCompany: '',
  specialCases: '',
  typeOfCare: '',
  age: '',
  filters: {
    insuranceCompanies: [],
    typesOfCare: [],
  },
  allSpecialCases: [],
  focusOptions: [],
  allCredentials: [],
  allLocations: [],
  credentials: [],
  location_names: [],
  language: [],
  availability_filter: [],
  gender: [],
  modality: [],
  utc_offset: '',
  facilityId: null,
  typeOfCareOptions: [],
  insuranceCompanies: [],
  expertisesListOptions: [],
  specialCasesOptions: [],
  sortType: '',
  interventions: [],
  populations: [],
  location_back: '',
  entireState: 'true',
  distance: '60',
  allLicenseKeys: [],
  licenseKeys: [],
  preliminaryAge: '',
  clinician_profile_back: [],
  isLoading: false,
  patientFormStep: 1,
};

export const adminFiltersSlice = createSlice({
  name: 'PO_filters',
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<Partial<InitialState>>) => ({
      ...state,
      ...action.payload,
    }),
    setErrors: (state, action: PayloadAction<Partial<Errors>>) => {
      state.errors = { ...state.errors, ...action.payload };
    },
    setValidZipCode: (state, action: PayloadAction<boolean>) => {
      state.isZipCodeValid = action.payload;
    },
    setFocus: (state, action: PayloadAction<FocusOptionsAbie[]>) => {
      state.focusOptions = action.payload;
    },
    setSpecialCases: (state, action: PayloadAction<SpecialCases[]>) => {
      state.allSpecialCases = action.payload;
    },
    resetNoVitalFilters: (state) => {
      state.expertises = [];
      state.specialCases = '';
      state.credentials = [];
      state.availability_filter = [];
      state.gender = [];
      state.modality = ['video_visit', 'in_office'];
      state.language = [];
      state.location_names = [];
      state.interventions = [];
      state.populations = [];
      state.entireState = 'true';
      state.distance = '60';
      state.licenseKeys = [];
      state.preliminaryAge = '';
    },
    setCredentials: (state, action: PayloadAction<GlobalLabelValue[]>) => {
      state.allCredentials = action.payload;
    },
    setLocations: (state, action: PayloadAction<LocationFilter[]>) => {
      state.allLocations = action.payload;
    },
    updateInsuranceList: (state, action: PayloadAction<Partial<Filters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setTypeOfCareOptions: (state, action: PayloadAction<TypeOfCareOptions[]>) => {
      state.typeOfCareOptions = action.payload;
    },
    setInsuranceCompaniesOptions: (state, action: PayloadAction<InsuranceCompaniesAbie[]>) => {
      state.insuranceCompanies = action.payload;
    },
    setFocusOptions: (state, action: PayloadAction<ExpertiseListOptions[]>) => {
      state.expertisesListOptions = action.payload;
    },
    setSpecialCasesOptions: (state, action: PayloadAction<SpecialCasesOptions[]>) => {
      state.specialCasesOptions = action.payload;
    },
    setLicenseKeys: (state, action: PayloadAction<number[]>) => {
      state.allLicenseKeys = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    resetAllFilters: () => ({ ...initialState }),
    setPatientFormStep: (state, action: PayloadAction<number>) => {
      state.patientFormStep = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(userLogout, () => ({ ...initialState }));
  },
});

export const adminFiltersActions = adminFiltersSlice.actions;

export default adminFiltersSlice.reducer;
