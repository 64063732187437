import clsx from 'clsx';
import { ReactComponent as ChatNewLine } from 'intakeOptimization/assets/svg/chat-new-line.svg';
import { ReactComponent as InOfficeIcon } from 'intakeOptimization/assets/svg/in-office.svg';
import { ReactComponent as InsuranceIcon } from 'intakeOptimization/assets/svg/insurance.svg';
import { ReactComponent as MentalHealthLine } from 'intakeOptimization/assets/svg/mental-health-line.svg';
import { ReactComponent as PagesLine } from 'intakeOptimization/assets/svg/pages-line.svg';
import { ReactComponent as User3Line } from 'intakeOptimization/assets/svg/user-3-line.svg';
import { ReactComponent as VideoChatLine } from 'intakeOptimization/assets/svg/video-chat-line.svg';
import { FC, RefObject } from 'react';

import { formatAgesAccepted, orderAges, simpleKeyboardControl } from 'intakeOptimization/utils';

import styles from './About.module.scss';

type Insurance = {
  id: number;
  name: string;
};

type Language = {
  id: number;
  name: string;
};

export interface IClinicianProps {
  languages_spoken?: Language[];
  pronouns?: string;
  virtual_visit?: boolean;
  in_office: boolean;
  manages_medication?: number;
  ages_accepted?: string;
  accepting_new_patients: any;
  gender?: string;
  type: string;
  insurances: Insurance[];
  isAdmin?: boolean;
  sectionRef: RefObject<HTMLDivElement>;
}

const getAboutSection = (
  languages_spoken: Language[],
  pronouns: string,
  virtual_visit: boolean,
  in_office: boolean,
  manages_medication: number,
  ages_accepted: string,
  accepting_new_patients: any,
  gender: string,
  type: string,
  insurances: Insurance[],
) => {
  const orderedAges = orderAges(ages_accepted);
  const agesString = formatAgesAccepted(orderedAges);

  const languages = languages_spoken.map((language: Language) => language?.name).join(',');

  let appTypeIcon;
  let appTypeText;

  if (in_office && virtual_visit) {
    appTypeIcon = <InOfficeIcon />;
    appTypeText = 'See patients via video visits & in-office visits';
  }
  if (in_office && !virtual_visit) {
    appTypeIcon = <InOfficeIcon />;
    appTypeText = 'See patients via in-office visits';
  }
  if (!in_office && virtual_visit) {
    appTypeIcon = <VideoChatLine />;
    appTypeText = 'See patients via video visits';
  }

  let genderFormat;
  let pronounsFormat;

  switch (gender) {
    case 'F':
    case 'Female':
    case 'female':
      genderFormat = 'Female';
      break;
    case 'M':
    case 'Male':
    case 'male':
      genderFormat = 'Male';
      break;
    case 'Non-binary':
      genderFormat = 'Non-binary';
      break;
    default:
      genderFormat = '';
  }

  const regexpHim = /(?!\B\w)(?:\(he|him|his|male)(?!\B\w)/gi;
  const regexpHer = /(?!\B\w)(?:\(she|her|hers|female)(?!\B\w)/gi;
  const regexpThem = /(?!\B\w)(?:\(they|them|theirs|non-binary)(?!\B\w)/gi;
  const isHim = regexpHim.test(pronouns);
  const isHer = regexpHer.test(pronouns);
  const isThem = regexpThem.test(pronouns);

  if (isHim) {
    pronounsFormat = 'He/him';
  } else if (isHer) {
    pronounsFormat = 'She/her';
  } else if (isThem) {
    pronounsFormat = 'They/them';
  } else if (pronouns === 'Prefer not to say' || pronouns === 'Does not wish to provide') {
    pronounsFormat = 'Prefer not to say';
  } else if (pronouns === 'Other') {
    pronounsFormat = 'Not represented here';
  } else {
    pronounsFormat = '';
  }

  return [
    /**
     * TODO: We will use this logic for the time being until BE can send proper data
     */
    // TODO: 08/02: Until data is reliable, commenting this section out
    // {
    //   data: accepting_new_patients ? 'Accepting new patients' : 'Not accepting new patients',
    //   available: 1,
    //   icon: accepting_new_patients ? <UserYes /> : <UserNot />,
    //   biggerIcon: true,
    //   id: 'accepting_new',
    // },
    {
      data: `${type}: ${
        manages_medication ? 'does manage medications' : 'does not manage medications'
      }`,
      available: 1,
      icon: <MentalHealthLine />,
      biggerIcon: false,
      id: 'medications',
    },
    // TODO: Commenting out until data is more reliable
    // {
    //   data: appTypeText,
    //   available: 1,
    //   icon: appTypeIcon,
    //   biggerIcon: false,
    //   id: 'app_type',
    // },
    {
      data: `${genderFormat?.length > 0 ? `${genderFormat}` : ''}${
        genderFormat?.length > 0 && pronounsFormat?.length > 0 ? ', ' : ''
      }${pronounsFormat?.length > 0 ? `${pronounsFormat}` : ''}`,
      available: 1,
      icon: <User3Line />,
      biggerIcon: true,
      id: 'gender',
    },
    {
      data: languages.length > 0 ? `Fluent in ${languages.replaceAll(',', ', ')}` : '',
      available: 1,
      icon: <ChatNewLine />,
      biggerIcon: false,
      id: 'languages',
    },
    {
      data: insurances.length > 0 ? 'Accepts insurance' : '',
      available: 1,
      icon: <InsuranceIcon />,
      biggerIcon: false,
      id: 'insurance',
    },
    {
      data: agesString.length > 0 ? `Accepts ages: ${agesString}` : '',
      available: 1,
      icon: <PagesLine />,
      biggerIcon: false,
      id: 'ages',
    },
  ];
};

export const About: FC<IClinicianProps> = ({
  languages_spoken,
  pronouns = '',
  virtual_visit,
  in_office,
  manages_medication = 0,
  ages_accepted = '',
  accepting_new_patients = false,
  gender = '',
  type,
  insurances,
  isAdmin = false,
  sectionRef,
}) => {
  const profile = getAboutSection(
    languages_spoken as Language[],
    pronouns,
    virtual_visit as boolean,
    in_office,
    manages_medication,
    ages_accepted,
    accepting_new_patients,
    gender,
    type,
    insurances,
  );

  return (
    <div className={styles.about}>
      <div className={styles.services} data-testId="aboutServices">
        {profile.map((service) => {
          if (!service || !service.data?.length) {
            return null;
          }
          return (
            <div
              key={service.data}
              className={clsx(styles.service, { [styles.biggerIcon]: service.biggerIcon })}
              data-available={service.available}
              data-id={service.id}
            >
              {service.icon}
              <div>
                {service.data}
                {service.data === 'Accepts insurance' ? (
                  <span
                    className={clsx({
                      [styles.aboutLink]: !isAdmin,
                      [styles.aboutLinkAdmin]: isAdmin,
                    })}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(event) => simpleKeyboardControl(
                      event,
                      () => {
                        sectionRef.current?.scrollIntoView();
                        event.currentTarget.blur();
                      },
                      true,
                    )}
                    onClick={() => {
                      sectionRef.current?.scrollIntoView();
                    }}
                  >
                    See more
                  </span>
                ) : (
                  ''
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
