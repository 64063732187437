import { CreditCardInfo, RSupportInfo } from 'patientOnboarding/@types';

const types = {
  UPDATE_RESERVATION: '@@PO_RESERVATION_GLOBAL/UPDATE_RESERVATION',
  CLEAR_RESERVATION: '@@PO_RESERVATION_GLOBAL/CLEAR_RESERVATION',
  BOOK_APPOINTMENT: '@@PO_RESERVATION_GLOBAL/BOOK_APPOINTMENT',
  SET_BOOKED: '@@PO_RESERVATION_GLOBAL/SET_BOOKED',
  SET_IS_LOADING: '@@PO_RESERVATION_GLOBAL/SET_IS_LOADING',
  SET_AVAILABILITY: '@@PO_RESERVATION_GLOBAL/SET_AVAILABILITY',
  GET_APPT_DETAILS: '@@PO_RESERVATION_GLOBAL/GET_APPT_DETAILS',
  CANCEL_APPT: '@@PO_RESERVATION_GLOBAL/CANCEL_APPT',
  SET_ERROR: '@@PO_RESERVATION_GLOBAL/SET_ERROR',
  SET_SUCCESS: '@@PO_RESERVATION_GLOBAL/SET_SUCCESS',
  ALREADY_CANCELLED: '@@PO_RESERVATION_GLOBAL/ALREADY_CANCELLED',
  APPOINTMENT_OCCURED_PAST_FLAG: '@@PO_RESERVATION_GLOBAL/APPOINTMENT_OCCURED_PAST_FLAG',
  GET_CANCELLATION_REASONS: '@@PO_RESERVATION_GLOBAL/GET_CANCELLATION_REASONS',
  SET_CANCELLATION_REASONS: '@@PO_RESERVATION_GLOBAL/SET_CANCELLATION_REASONS',
  SET_CANCELLATION_REASON: '@@PO_RESERVATION_GLOBAL/SET_CANCELLATION_REASON',
  SAVE_SELECTED_CANCELLATION_REASON: '@@PO_RESERVATION_GLOBAL/SAVE_SELECTED_CANCELLATION_REASON',
  POST_BOOKING_API: '@@PO_RESERVATION_GLOBAL/POST_BOOKING_API',
};

export interface ReservationPayload {
  clinicianKey: string;
  patientId: string;
  creditCardInfo: CreditCardInfo;
}

export interface CancellationReasonsPayload {
  cancellationReasonId: string;
  patientAppointmentId: string;
}

export interface AppointmentDetailsDTO {
  patient_appointment: {
    support_info: RSupportInfo[];
    marketing_referral_phone?: string;
  };
}

export default types;
