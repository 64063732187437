import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FacilityLocation, RCancellationReason, RClinician } from 'patientOnboarding/@types';
import { boolean } from 'yup';

interface ReservationData {
  facility_id: string | null | number;
  type_of_care: string | null;
  clinician_availability_key: number | string;
  clinician: RClinician;
  appointmentType: string | null;
  modality: 'IN-OFFICE' | 'VIDEO' | '';
  availability: boolean;
  loading: boolean;
  appointment: any;
  cancelSuccess: boolean | null;
  alreadyCancelled: boolean | null;
  cancellationReasons: Array<RCancellationReason>;
  cancellationReason: string | number;
  provider_id?: number;
  appointment_start_time?: string;
  appointment_end_time?: string;
  appointment_utc_start_datetime?: string;
  available_date?: string;
  availabilityStatus?: string;
  license_key?: number;
  selectedFacility?: FacilityLocation;
  facilityIds: number[];
  isVideoOnly: boolean;
  bookingError: boolean;
  bookingErrorModalShow: boolean;
}

const initialState: ReservationData = {
  facility_id: null,
  clinician: {} as RClinician,
  type_of_care: null,
  appointmentType: null,
  clinician_availability_key: '',
  modality: '', // eg: IN-OFFICE | VIDEO
  availability: false,
  loading: false,
  appointment: {},
  cancelSuccess: null,
  alreadyCancelled: null,
  cancellationReasons: [],
  cancellationReason: -1,
  facilityIds: [],
  isVideoOnly: false,
  bookingError: false,
  bookingErrorModalShow: false,
};

const reservationSlice = createSlice({
  name: 'PO_reservationGlobal',
  initialState,
  reducers: {
    updateReservation: (state, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload,
    }),
    clearReservation: () => initialState,
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
    setAvailability: (state, action: PayloadAction<boolean>) => ({
      ...state,
      availability: action.payload,
    }),
    setBookedAppointment: (state, action: PayloadAction<any>) => ({
      ...state,
      appointment: action.payload,
    }),
    setCancelSuccess: (state, action: PayloadAction<boolean>) => ({
      ...state,
      cancelSuccess: action.payload,
    }),
    setAlreadyCancelled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      alreadyCancelled: action.payload,
    }),
    setAppointmentOccuredPastFlag: (state, action: PayloadAction<boolean>) => ({
      ...state,
      appointmentOccuredPastFlag: action.payload,
    }),
    setCancellationReasons: (state, action: PayloadAction<Array<any>>) => ({
      ...state,
      cancellationReasons: action.payload,
    }),
    setCancellationReason: (state, action: PayloadAction<string>) => ({
      ...state,
      cancellationReason: action.payload,
    }),
    setBookingError: (state, action: PayloadAction<boolean>) => ({
      ...state,
      bookingError: action.payload,
    }),
    setBookingErrorModalShow: (state, action: PayloadAction<boolean>) => ({
      ...state,
      bookingErrorModalShow: action.payload,
    }),
    resetReservation: () => initialState,
  },
});

export const {
  updateReservation,
  clearReservation,
  setLoading,
  setAvailability,
  setBookedAppointment,
  setCancelSuccess,
  setAlreadyCancelled,
  setAppointmentOccuredPastFlag,
  setCancellationReasons,
  setCancellationReason,
  resetReservation,
  setBookingError,
  setBookingErrorModalShow,
} = reservationSlice.actions;

export default reservationSlice.reducer;
