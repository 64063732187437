import { ADMIN_ACCOUNT_HOLDER_INFO_ROUTES } from 'intakeOptimization/routes/Admin.routes';
import { adminRoutes as absoluteAdminRoutes } from 'intakeOptimization/routes/absolutes';
import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { BackButton, Container } from 'lifestance-ui';

import { usePreviousRoute } from 'intakeOptimization/hooks/usePreviousRoute';

import { useDevice } from 'intakeOptimization/utils';

import styles from './AccountHolderInfo.module.scss';

const AccountHolderInfo = () => {
  const previousRoute = usePreviousRoute();
  const location = useLocation();
  const { isMobile } = useDevice();
  const [state, setState] = useState({
    isContainerFullWidth: false,
    hasProgressBar: true,
    hasBackButton: true,
  });

  useEffect(() => {
    if (
      [
        absoluteAdminRoutes.emailVerification,
        absoluteAdminRoutes.insuranceCard,
        absoluteAdminRoutes.consents,
        absoluteAdminRoutes.review,
        absoluteAdminRoutes.confirmation,
      ].includes(location.pathname)
    ) {
      setState((prevState) => ({
        ...prevState,
        hasBackButton: false,
      }));
    } else {
      setState({
        isContainerFullWidth: false,
        hasProgressBar: true,
        hasBackButton: true,
      });
    }
  }, [location, location.pathname]);

  return (
    <div>
      <Container fullWidth={state.isContainerFullWidth}>
        <Container sm>
          {!isMobile && state.hasBackButton && (
            <div className={styles.backContainer}>
              <BackButton withText className={styles.back} previousRoute={previousRoute} />
            </div>
          )}
        </Container>
        <div className={styles.childrenContainer}>
          <Routes>
            {ADMIN_ACCOUNT_HOLDER_INFO_ROUTES.map(({ path, Component }) => (
              <Route
                exact
                key={`p-${path}`}
                path={path}
                element={(
                  <Suspense>
                    <Component />
                  </Suspense>
                )}
              />
            ))}
          </Routes>
        </div>
      </Container>
    </div>
  );
};

export default AccountHolderInfo;
