import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { RSupportInfo } from 'intakeOptimization/@types';
import { bookingRoutes } from 'intakeOptimization/routes/constants';

import t from './types';

export type Patient = {
  id?: number;
  first_name?: string;
  last_name?: string;
  preferred_name?: string;
  date_of_birth?: string;
  phone_number?: string;
  referral_source?: string;
  account_holder_relationship?: string;
  pronouns?: string;
  about?: string;
  special_case_id?: number;
  created_at?: string;
  updated_at?: string;
  providerId?: number | string;
  addressId?: number | string;
  typeOfCare?: string;
  paymentMethod?: string;
  search_filter_values?: {
    zip_codes?: string;
    clinician_address_id?: number;
    type_of_care?: string;
    payment_type?: string;
    insurance_name?: string;
  };
  credit_card_on_file_collected?: boolean;
  intake_status?: string;
  amd_patient_id?: number;
  account_holder_id?: number;
  gender?: string;
  provider_id?: number;
  office_code?: number;
  marketing_referral_id?: number;
  profile_id?: number;
  referring_provider_name?: number;
  referring_provider_phone_number?: number;
  email?: string;
  amd_updated_at?: string;
  patientConcerns?: string[];
  patientPopulations?: string[];
  patientInterventions?: string[];
  specialCaseId?: number;
  genderIdentity?: string;
};

export type EmergencyContactInfo = {
  phone?: string;
  lastName?: string;
  firstName?: string;
  relationship?: string;
};

export type UserInsuranceDetails = {
  data?: {
    policyholder?: string;
    isDifferentPolicyHolder?: boolean;
    insuranceCarrier?: string;
    city?: string;
    state?: string;
    insuranceMemberId?: string;
    facility_id?: number;
    provider_id?: number;
    license_key?: number;
    isPolicyHolderFormEnabled?: boolean;
    isAddressFormEnabled?: boolean;
    zipCode?: string;
  };
  policyHolder?: {
    first_name?: string;
    last_name?: string;
    date_of_birth?: string;
    gender?: string;
    email?: string;
  };
  address?: {
    address_line1?: string;
    city?: string;
    state?: string;
    postal_code?: string;
  };
  patientId?: number;
  system?: string;
};

export type AddressPayloadType = {
  address_line1?: string;
  address_line2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  id?: number | string;
};

export type UserEmailStatus = {
  id?: number;
  email?: string;
  isVerified?: boolean;
  message?: string;
};

export type AccountHolderBasicData = {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  phone_number: string;
  gender: string;
  receive_email_updates: boolean;
  source: string;
  email: string;
  pronouns: string;
  about: string;
};

export type AccountHolderInfo = {
  email?: string;
  phone?: string;
  intake?: {
    origin?: string;
  };
  patient?: {
    id?: string;
  };
  zipCode?: string | number;
  lastName?: string;
  referral?: string;
  addressId?: number;
  firstName?: string;
  isLoading?: boolean;
  conditions?: string[];
  providerId?: number;
  typeOfCare?: string;
  contactInfo?: {
    id?: number;
    cbo?: number;
    location?: string;
    created_at?: string;
    updated_at?: string;
    license_key?: number;
    follow_up_url?: string;
    support_hours?: string;
    intake_call_in_number?: string;
    established_patients_call_in_number?: string;
  };
  dateOfBirth?: string;
  legalGender?: string;
  intakeStatus?: string;
  specialCases?: string;
  addressStreet?: string;
  exists_in_amd?: boolean;
  paymentMethod?: string;
  accountHolderId?: number;
  childDateOfBirth?: string;
  insuranceCompany?: string;
  insuranceDetails?: any;
  selectedSlotInfo?: any;
  hasSuicidalIdeation?: boolean;
  patient_portal_link?: string;
  emergencyContactInfo?: {
    phone?: string;
    lastName?: string;
    firstName?: string;
    relationship?: string;
  };
  accountHolderPatientId?: number;
  patient_is_policyholder?: boolean;
  accountHolderRelationship?: string;
  insurancePremliminaryData?: {
    policyholder?: string;
    isDifferentPolicyHolder?: boolean;
  };
};

type AccountHolderRequiredPayload = {
  addressId: number;
  dateOfBirth: string; // MM/DD/YYYY
  email: string;
  firstName: string;
  genderIdentity: string;
  insuranceCompany: string;
  lastName: string;
  legalGender: string;
  paymentMethod: string;
  phone: string; // NNN-NNN-NNNN
  providerId: number;
  receiveEmailUpdates: string | boolean;
  referral: string;
  typeOfCare: string;
  zipCode: string;
};

export type InitialState = {
  intake: {
    origin: string;
  };
  addressStreet: string;
  firstName: string;
  lastName: string;
  email: string;
  emailMirror: string;
  phone: string;
  dateOfBirth: string;
  legalGender: string;
  referral_source: string;
  insuranceDetails: UserInsuranceDetails;
  insurancePremliminaryData: {
    policyholder: string;
    isDifferentPolicyHolder: boolean;
    insuranceCarrier?: string;
    city?: string;
    state?: string;
    insuranceMemberId?: string;
    facility_id?: number;
    provider_id?: number;
    license_key?: number;
    isPolicyHolderFormEnabled?: boolean;
    isAddressFormEnabled?: boolean;
    zipCode?: string;
  };
  childDateOfBirth: string;
  isLoading: boolean;
  exists_in_amd: boolean;
  patient_is_policyholder: boolean;
  patient: Patient;
  selectedSlotInfo: {
    id: string;
  };
  conditions: string[];
  specialCases: string | number;
  hasSuicidalIdeation: boolean;
  contactInfo: RSupportInfo[];
  emergencyContactInfo: {
    phone: string;
    lastName: string;
    firstName: string;
    relationship: string;
  };
  patient_portal_link: string;
  accountHolderInfo: AccountHolderInfo;
  responsiblePartyPatients: any;
  emailStatus: UserEmailStatus;
  error: {
    status?: number;
    message?: string;
  };
  receiveEmailUpdates: string | boolean;
  providerId: number | string;
  childFirstName: string;
  preferredName: string;
  childLastName: string;
  childGender: string;
  childGenderIdentity: string;
  childNickname: string;
  personalInfo: string;
  pronouns: string;
  accountHolderRelationship: string;
  zipCode: string;
  addressId: number;
  typeOfCare: string;
  intakeStatus: string;
  paymentMethod: string;
  insuranceCompany: string;
  addressExtraLine: string;
  preliminaryAccountHolder: AccountHolderRequiredPayload;
  isLoadingResend: boolean;
  emailResent: boolean;
  genderIdentity: string;
};

const initialState: InitialState = {
  intake: {
    origin: bookingRoutes.providerMatches,
  },
  addressStreet: '',
  firstName: '',
  lastName: '',
  email: '',
  emailMirror: '',
  phone: '',
  dateOfBirth: '',
  legalGender: '',
  referral_source: '',
  insuranceDetails: {},
  insurancePremliminaryData: {
    isDifferentPolicyHolder: false,
    policyholder: 'self',
  },
  childDateOfBirth: '',
  isLoading: false,
  exists_in_amd: false,
  patient_is_policyholder: false,
  patient: { id: 0 },
  selectedSlotInfo: { id: '' },
  conditions: [],
  specialCases: '',
  hasSuicidalIdeation: false,
  contactInfo: [],
  emergencyContactInfo: {
    firstName: '',
    lastName: '',
    phone: '',
    relationship: '',
  },
  patient_portal_link: '',
  accountHolderInfo: {},
  responsiblePartyPatients: '',
  emailStatus: {},
  error: {},
  receiveEmailUpdates: true,
  providerId: '',
  childFirstName: '',
  preferredName: '',
  childLastName: '',
  childGender: '',
  childGenderIdentity: '',
  childNickname: '',
  personalInfo: '',
  pronouns: '',
  accountHolderRelationship: '',
  zipCode: '',
  addressId: 0,
  typeOfCare: '',
  intakeStatus: '',
  paymentMethod: '',
  insuranceCompany: '',
  addressExtraLine: '',
  preliminaryAccountHolder: {} as AccountHolderRequiredPayload,
  isLoadingResend: false,
  emailResent: false,
  genderIdentity: '',
};

export const userIntakeSlice = createSlice({
  name: 'IO_userGlobal',
  initialState,
  reducers: {
    setIntakeOrigin: (state, action: PayloadAction<string>) => {
      state.intake.origin = action.payload ? action.payload : bookingRoutes.providerMatches;
    },
    setContactInfo: (state, action: PayloadAction<RSupportInfo[]>) => {
      state.contactInfo = action.payload;
    },
    setEmergencyContactInfo: (state, action: PayloadAction<EmergencyContactInfo>) => {
      state.emergencyContactInfo = {
        ...state.emergencyContactInfo,
        ...action.payload,
      };
    },
    updateUser: (state, action: PayloadAction<any>) => ({ ...state, ...action.payload }),
    setPatient: (state, action: PayloadAction<Patient>) => {
      state.patient = { ...state.patient, ...action.payload };
    },
    setAccountHolder: (state, action: PayloadAction<AccountHolderInfo>) => {
      state.accountHolderInfo = action.payload;
    },
    setExistsInAmd: (state, action: PayloadAction<boolean>) => {
      state.exists_in_amd = action.payload;
    },
    setPatientIsPolicyholder: (state, action: PayloadAction<boolean>) => {
      state.patient_is_policyholder = action.payload;
    },
    setPatientPortalLink: (state, action: PayloadAction<string>) => {
      state.patient_portal_link = action.payload;
    },
    setResponsibleParty: (state, action: PayloadAction<any>) => {
      state.responsiblePartyPatients = action.payload;
    },
    setUserInsuranceDetails: (state, action: PayloadAction<UserInsuranceDetails>) => {
      state.insuranceDetails = action.payload;
    },
    setEmailStatus: (state, action: PayloadAction<UserEmailStatus>) => {
      state.emailStatus = { ...action.payload };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    handleError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    setSuicidalIdeation: (state, action: PayloadAction<boolean>) => {
      state.hasSuicidalIdeation = action.payload;
    },
    updateInsurancePreliminaryDetails: (state, action: PayloadAction<any>) => {
      state.insurancePremliminaryData = { ...state.insurancePremliminaryData, ...action.payload };
    },
    setPreliminaryAccountHolderData: (
      state,
      action: PayloadAction<AccountHolderRequiredPayload>,
    ) => {
      state.preliminaryAccountHolder = action.payload;
    },
    updatePreliminaryEmail: (state, action: PayloadAction<string>) => {
      state.preliminaryAccountHolder.email = action.payload;
    },
    setIsLoadingResend: (state, action: PayloadAction<boolean>) => {
      state.isLoadingResend = action.payload;
    },
    setEmailResent: (state, action: PayloadAction<boolean>) => {
      state.emailResent = action.payload;
    },
    setAccountHolderResentEmail: (state, action: PayloadAction<string>) => {
      state.accountHolderInfo.email = action.payload;
    },
    resetUser: () => initialState,
  },
});

export const getContactInfo = createAction<string | number>(t.GET_CONTACT_INFO);
export const getEmergencyContactInfo = createAction<number>(t.GET_EMERGENCY_CONTACT_INFO);
export const saveEmergencyContactInfo = createAction(t.SAVE_EMERGENCY_CONTACT_INFO);
export const saveAddressData = createAction<AddressPayloadType>(t.SAVE_ADDRESS);
export const updatePatient = createAction<{ patientId?: number; data: Patient }>(t.UPDATE_PATIENT);
export const createAccountHolder = createAction<AccountHolderInfo>(t.CREATE_ACCOUNT_HOLDER);
export const updateAccountHolder = createAction<{
  data: Partial<AccountHolderBasicData>;
  id: number;
}>(t.UPDATE_ACCOUNT_HOLDER);
export const createPatient = createAction<Patient | AccountHolderInfo>(t.CREATE_PATIENT);
export const updateUserInsuranceDetails = createAction<any>(t.UPDATE_INSURANCE_DETAILS);
export const getEmailStatus = createAction<{ token: string }>(t.GET_EMAIL_STATUS);
export const resendEmail = createAction<{ email_address: string; id: number }>(t.RESEND_EMAIL);
export const getSelectedSlotInfo = createAction<{ token: string }>(t.GET_SELECTED_SLOT_INFO);
export const saveAccountHolderSlot = createAction<any>(t.SAVE_ACCOUNT_HOLDER_SLOT);

export const {
  setIntakeOrigin,
  setContactInfo,
  setEmergencyContactInfo,
  updateUser,
  setPatient,
  setAccountHolder,
  setExistsInAmd,
  setPatientIsPolicyholder,
  setPatientPortalLink,
  setResponsibleParty,
  setUserInsuranceDetails,
  setEmailStatus,
  setLoading,
  handleError,
  setSuicidalIdeation,
  updateInsurancePreliminaryDetails,
  setPreliminaryAccountHolderData,
  updatePreliminaryEmail,
  setIsLoadingResend,
  setEmailResent,
  setAccountHolderResentEmail,
  resetUser,
} = userIntakeSlice.actions;

export default userIntakeSlice.reducer;
