import { NestedOption, Option } from 'intakeOptimization/@types';

export const updateAvailabilityFilterOptions = (
  options: NestedOption[],
  isAdmin: boolean,
): Option[] | NestedOption[] => {
  if (!isAdmin) return options as Option[];

  const after5pmObj = {
    label: 'After 5pm',
    value: 'after_5_PM',
  };
  if (options[0].options && options[0].options.length < 5) {
    return [...options[0].options, after5pmObj];
  }
  return options;
};
