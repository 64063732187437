import clsx from 'clsx';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowLeft } from 'lifestance-ui/assets/svg/arrow-left-line.svg';

import styles from './BackButton.module.scss';

interface IBackButtonProps {
  className?: string;
  withText?: boolean;
  testId?: string;
  previousRoute: string;
  onClick?: () => void;
}

export const BackButton: FC<React.PropsWithChildren<IBackButtonProps>> = ({
  className = '',
  withText = false,
  testId = 'backButton',
  previousRoute = '',
  onClick,
}) => (
  <Link
    data-cy={testId}
    data-testId={testId}
    className={clsx(className, styles.link)}
    to={previousRoute}
    onClick={onClick}
  >
    <ArrowLeft data-testid="LeftArrow" />
    {withText && <span className={styles.link}>Back</span>}
  </Link>
);
