import { ReactComponent as CalendarIcon } from 'patientOnboarding/assets/svg/calendar-line.svg';
import { ReactComponent as ErrorIcon } from 'patientOnboarding/assets/svg/error.svg';
import { ReactComponent as LoadingIcon } from 'patientOnboarding/assets/svg/loading.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Appointment } from 'patientOnboarding/components';

import { updatePreferences } from 'patientOnboarding/store/preferences/actions';

import {
  abieReservationError,
  availabilityStatus,
  reservationData,
  showAppt,
} from 'patientOnboarding/selectors/selectors';

import { onClickOut, useDevice } from 'patientOnboarding/utils';

import styles from './TopAppointment.module.scss';

export const TopAppointment = () => {
  const [openModal, setOpenModal] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const showAppointment = useSelector(showAppt);
  const reservation = useSelector(reservationData);
  const reservationError = useSelector(abieReservationError);
  const isReservationAvailable = useSelector(availabilityStatus);

  const { isMobile, isSmallTablet, isTablet } = useDevice();
  const dispatch = useDispatch();
  const location = useLocation();
  const fromRoute = location?.state?.from;
  const isFromRematch = !!fromRoute && !!fromRoute?.includes('rematch');
  const appointmentRef = useRef(null);
  const hasBeenOpened = localStorage.getItem('appointment');

  useEffect(() => {
    if (showAppointment && !hasBeenOpened) {
      setTimeout(() => {
        setOpenModal(true);
      }, 1000);
    }
  }, [hasBeenOpened]);

  useEffect(() => {
    if (isFromRematch) {
      setTimeout(() => {
        setShowLoading(false);
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (
      (reservation && !isMobile && !isSmallTablet && !isTablet)
      || (reservationError && reservationError !== '')
    ) {
      setOpenModal(showAppointment);
    } else {
      setOpenModal(false);
    }
  }, [reservation, reservationError, showAppointment, isMobile, isTablet]);

  const handleModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    if (showAppointment) {
      localStorage.setItem('appointment', 'true');
      dispatch(updatePreferences({ showAppointment: false }));
    }
  };

  useEffect(() => {
    if (openModal) {
      return onClickOut({
        triggerRef: appointmentRef,
        action: handleCloseModal,
      });
    }
  }, [openModal, handleCloseModal]);

  return (
    <div id="topAppointmentContainer" ref={appointmentRef}>
      {openModal && (
        <Appointment
          testId="AppointmentDetails"
          onClose={handleCloseModal}
          reservation={reservation}
          reservationError={reservationError}
        />
      )}
      <button
        data-testId="topAppointmentToggleDetails"
        type="button"
        className={styles.trigger}
        onClick={handleModal}
      >
        {isFromRematch && showLoading && (
          <LoadingIcon data-testId="topAppointmentLoadingIcon" className={styles.loadingIcon} />
        )}
        {!isReservationAvailable || (reservationError && reservationError !== '') ? (
          <ErrorIcon data-testId="topAppointmentErrorIcon" />
        ) : null}
        {(isReservationAvailable
          && (!reservationError || reservationError === '')
          && !isFromRematch)
        || !showLoading ? (
          <CalendarIcon data-testId="topAppointmentCalendarIcon" className={styles.calendarIcon} />
          ) : null}
        <span>Your appointment</span>
      </button>
    </div>
  );
};
