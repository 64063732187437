export const bookingRoutes = {
  findCare: '/find-care/booking',
  matchCriteria: '/find-care/booking/match-criteria',
  clinicianProfile: '/find-care/booking/provider/:id',
  providerMatches: '/find-care/booking/provider-matches',
  search: '/find-care/booking/search',
  followUp: '/find-care/booking/followup',
  scheduling: '/find-care/booking/scheduling',
};

export const intakeRoutes = {
  intake: '/find-care/intake',
  register: '/find-care/intake/register',
  userExists: '/find-care/intake/existing',
  account: '/find-care/intake/account',
  demographics: '/find-care/intake/demographics',
  reason: '/find-care/intake/reason',
  review: '/find-care/intake/review',
  email: '/find-care/intake/email',
  emailVerified: '/find-care/intake/email-verified',
  emailVerificationError: '/find-care/intake/email-error',
  emailTimedOut: '/find-care/intake/email-timed-out',
  insuranceDetails: '/find-care/intake/insurance-details',
  insuranceCard: '/find-care/intake/insurance-card',
  address: '/find-care/intake/address',
  emergencyContact: '/find-care/intake/emergency-contact',
  rematch: '/find-care/intake/reason-rematch',
  rematchResults: '/find-care/intake/reason/rematch/results',
  rematchClinician: '/find-care/intake/reason/rematch/:id',
  confirmation: '/find-care/intake/confirmation',
  cancel: '/find-care/intake/cancellation/confirm',
  cancelSuccess: '/find-care/intake/cancel-success',
  cancelKeep: '/find-care/intake/cancel-keep',
  cancelError: '/find-care/intake/cancel-error',
  cancelExclusion: '/find-care/intake/cancel-excluded',
  alreadyCancelled: '/find-care/intake/already-cancelled',
  appointmentOccuredPastFlag: '/find-care/intake/appointment-occured-past',
  appointmentError: '/find-care/intake/appointment-error',
  bookAppointment: '/find-care/intake/book-appointment',
  emailLanding: '/find-care/intake/landing-email',
  suicidalPrevention: '/find-care/intake/prevention',
  cancellationReasons: '/find-care/intake/cancellation',
  paymentMethod: '/find-care/intake/payment-method',
  verifyEmail: '/find-care/intake/verify-email',
};

export const adminRoutes = {
  patientType: '/admin/patient',
  filters: '/admin/filters',
  search: '/admin/booking-search',
  searchResults: '/admin/results',
  consents: '/admin/intake/consents',
  review: '/admin/intake/review',
  clinicianProfile: '/admin/provider/:id',
  confirmation: '/admin/intake/confirmation',
  appointmentError: '/admin/appointment-error',
  bookAppointment: '/admin/intake/book-appointment',
  accountHolder: '/admin/intake/account/*',
  bookingForSelf: '/admin/intake/account-bookingForSelf',
  accountHolderParent: '/admin/intake/account-parent',
  accountHolderChild: '/admin/intake/account-child',
  physicalAddress: '/admin/intake/account-physicalAddress',
  accountInsurance: '/admin/intake/account-accountInsurance',
  emailVerification: '/admin/intake/account-emailVerification',
  insuranceCard: '/admin/intake/insurance-card',
  appointmentCancellation: '/admin/intake/cancellation',
  accountHolderExists: '/admin/intake/existingPatient',
};

export const establishedPatientsRoutes = {
  followUp: '/find-care/booking/followup',
  existing: '/find-care/existing',
  provider: '/find-care/existing/provider-selection',
  modality: '/find-care/existing/modality',
  location: '/find-care/existing/location',
  availableAppointments: '/find-care/existing/available-appointments',
  confirmPaymentInfo: '/find-care/existing/confirm-payment-info',
  confirmation: '/find-care/existing/confirmed',
};
