import { FC } from 'react';
import clsx from 'clsx';
import styles from "./Label.module.scss";

interface ILabelProps {
  htmlFor?: string;
  className?: string;
}

export const Label: FC<React.PropsWithChildren<ILabelProps>> = ({ htmlFor, children, className = '' }) => (
  <label htmlFor={htmlFor} className={clsx(styles.label, className)}>{children}</label>
);
