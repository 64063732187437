import clsx from 'clsx';
import { FilterOption } from 'patientOnboarding/@types';
import { ReactComponent as CloseIcon } from 'patientOnboarding/assets/svg/close.svg';
import { ReactComponent as FilterLines } from 'patientOnboarding/assets/svg/filter-3-line.svg';
import { bookingRoutes } from 'patientOnboarding/routes/constants';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from 'lifestance-ui';

import { MobileFilter } from 'patientOnboarding/components';

import { loadingCliniciansSelector } from 'patientOnboarding/store/cliniciansGlobal/selectors';
import { updateFilters } from 'patientOnboarding/store/filters/actions';

import { loadingFiltersSelector, obiePreferencesData } from 'patientOnboarding/selectors/selectors';

import { useResizingMediaQuery } from 'patientOnboarding/hooks';

import {
  cleanAvailability,
  cleanNullProperties,
  getFormatForMatches,
  useDevice,
} from 'patientOnboarding/utils';

import styles from './MobileFilters.module.scss';

interface DefaultValues {
  concerns: string[];
  availability_filter: string[];
  gender: string[];
  modality: string[];
  language: string[];
  location_names: string[];
  [key: string]: string[];
}

interface MobileFiltersProps {
  onChange: (name: string, values: string[]) => void;
  defaultValues: DefaultValues;
  filters: FilterOption[];
  onClose?: () => void;
  onOpen?: () => void;
  buttonText: string;
}

export type MobileFiltersHandle = {
  clearAll: () => void;
};

export const MobileFilters: React.FC<React.PropsWithChildren<MobileFiltersProps>> = ({
  onChange,
  defaultValues,
  filters,
  onClose,
  onOpen,
  buttonText,
}) => {
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const preferences = useSelector(obiePreferencesData);
  const navigate = useNavigate();
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const isSmallTablet = useResizingMediaQuery('(max-width: 480px)');
  const isFiltersLoading = useSelector(loadingFiltersSelector);
  const loadingClinicians = useSelector(loadingCliniciansSelector);

  const activeFiltersKeys = [
    'concerns',
    'populations',
    'interventions',
    'availability_filter',
    'gender',
    'language',
  ];

  const filterRefs = useRef([
    createRef<MobileFiltersHandle>(),
    createRef<MobileFiltersHandle>(),
    createRef<MobileFiltersHandle>(),
    createRef<MobileFiltersHandle>(),
  ]);

  const handleOpen = () => {
    setOpen(true);

    setTimeout(() => {
      setVisible(true);
      onOpen?.();
    }, 100);
  };

  const handleClose = () => {
    setVisible(false);

    setTimeout(() => {
      setOpen(false);
      onClose?.();
    }, 500);
  };

  const handleSave = () => {
    handleClose();
  };

  const handleChange = (name: string, values: string[]) => {
    const newValue = name === 'availability_filter' ? cleanAvailability(values) : values;
    onChange(name, newValue);
  };

  const handleClear = () => {
    const {
      zipCode,
      paymentMethod,
      insuranceCompany,
      typeOfCare,
      utc_offset,
      modality,
      distance,
      entireState,
    } = preferences;
    const selectedFilters = {
      zipCode,
      paymentMethod,
      insuranceCompany,
      typeOfCare,
      utc_offset,
      modality,
      distance,
      entireState,
    };
    const formattedFilters = getFormatForMatches(selectedFilters);
    const cleanedFilters = cleanNullProperties(formattedFilters);
    const queryString = Object.keys(cleanedFilters)
      .map((key) => `${key}=${cleanedFilters[key]}`)
      .join('&');
    navigate(`${bookingRoutes.providerMatches}?${queryString}`);
    filterRefs.current.forEach((ref) => ref.current?.clearAll());
    dispatch(
      updateFilters({
        modality: [],
        location_names: [],
        availability_filter: [],
        gender: [],
        language: [],
        credentials: [],
      }),
    );
    filterRefs.current.forEach((ref) => ref.current?.clearAll());
  };

  const getMobileActiveFiltersLength = () => {
    let activeFilters = 0;
    activeFiltersKeys.forEach((key) => {
      /**
       * As in OBIE right now gender goes for both abbreviation and full name of gender "Male, M" we are loking for
       * elements with more than one character in the gender array.
       * */
      if (key === 'gender') {
        const genderDistinctValues = defaultValues[key]?.reduce(
          (prev, curr) => (curr.length > 1 ? prev + 1 : prev + 0),
          0,
        );
        activeFilters += genderDistinctValues;
      } else {
        activeFilters += defaultValues[key]?.length || 0;
      }
    });
    return activeFilters;
  };

  useEffect(() => {
    // Avoid scroll when MobileDropdown opened
    const appContainer = document.getElementById('appContainer') as HTMLElement;

    if (open && isMobile) {
      appContainer.style.overflow = 'hidden';
      appContainer.style.height = '100vh';
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    } else {
      appContainer.style.overflow = 'initial';
      appContainer.style.height = 'initial';
      document.body.style.overflow = 'initial';
      document.body.style.height = 'initial';
    }
  }, [open, isMobile]);

  const filtersAreActive = () => defaultValues.concerns[0] !== undefined
    || defaultValues.interventions[0] !== undefined
    || defaultValues.populations[0] !== undefined
    || defaultValues.availability_filter?.length > 0
    || defaultValues.gender?.length > 0
    || defaultValues.language?.length > 0;

  return (
    <>
      <div
        className={clsx(styles.select, {
          [styles.selectedOptions]: filtersAreActive(),
        })}
        onClick={handleOpen}
      >
        <span className={styles.labelTabletFilters}>Filters</span>
        {!isSmallTablet && !filtersAreActive() && (
          <div className={styles.filtersIcon}>
            <FilterLines />
          </div>
        )}
        {!isSmallTablet && filtersAreActive() && (
          <div className={styles.circle}>{getMobileActiveFiltersLength()}</div>
        )}
        {isSmallTablet && (
          <div className={styles.filtersIcon}>
            <FilterLines />
          </div>
        )}
        {isSmallTablet && filtersAreActive() && (
          <div
            className={clsx(styles.mobileCircle, {
              [styles.mobileCirclePadding]: getMobileActiveFiltersLength() > 9,
            })}
          >
            {getMobileActiveFiltersLength()}
          </div>
        )}
      </div>
      {open && (
        <>
          <div
            className={clsx(styles.underLayer, { [styles.open]: visible })}
            onClick={handleClose}
          />
          <div className={clsx(styles.mobileFilters, { [styles.open]: visible })}>
            <div className={clsx(styles.topFilters, { [styles.open]: visible })}>
              <span className={styles.handleClose} onClick={handleClose}>
                <CloseIcon />
              </span>
              <span className={styles.filtersTitle}>Filters</span>
              <span className={styles.clearAll} onClick={handleClear}>
                Clear all
              </span>
            </div>
            <div className={styles.filters}>
              {filters.map((filter: any, i: number) => {
                const values = filter.name !== 'concerns'
                  ? defaultValues[filter?.name]
                  : defaultValues.concerns.concat(
                    defaultValues.interventions,
                    defaultValues.populations,
                  );
                return (
                  <MobileFilter
                    className={styles.filter}
                    name={filter.name as string}
                    defaultValues={values}
                    label={filter.label as string}
                    options={filter.options}
                    onChange={handleChange}
                    ready={visible}
                    ref={filterRefs.current[i]}
                  />
                );
              })}
            </div>
            <div className={styles.footer}>
              <Button
                fullWidth
                onClick={handleSave}
                isLoading={isFiltersLoading || loadingClinicians}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
