import states from '__mocks__/states';
import { FC, FocusEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown, Input } from 'lifestance-ui';

import { getAddressData } from 'patientOnboarding/store/insurance/actions';

import { obieInsuranceData } from 'patientOnboarding/selectors/selectors';

import styles from './AddressForm.module.scss';

interface AddressFormProps {
  updateInsuranceData: (key: string, value: string) => void;
  validateIsInputFilled: (event: FocusEvent<HTMLInputElement>) => void;
  insuranceDetailsData: Record<string, string | number | boolean>;
}

export const AddressForm: FC<AddressFormProps> = ({
  updateInsuranceData,
  validateIsInputFilled,
  insuranceDetailsData,
}) => {
  const dispatch = useDispatch();
  const { city, state, error } = useSelector(obieInsuranceData);
  const handleRequest = (event: FocusEvent<HTMLInputElement>) => {
    validateIsInputFilled(event);
    dispatch(getAddressData(event.target?.value));
  };

  useEffect(() => {
    updateInsuranceData('state', state);
  }, [state]);

  useEffect(() => {
    updateInsuranceData('city', city);
  }, [city]);

  return (
    <>
      <div className={styles.divider} />
      <p className={styles.header}>Policyholder’s address for billing</p>
      <label className={styles.formLabel} htmlFor="streetAddress">
        Street address
      </label>
      <Input
        type="text"
        id="streetAddress"
        placeholder="Start typing"
        onBlur={validateIsInputFilled}
        onChange={(value) => updateInsuranceData('streetAddress', value)}
        value={insuranceDetailsData.streetAddress as string}
        data-dd-privacy="mask-user-input"
      />
      <div className={styles.labelContainer}>
        <label className={styles.formLabel} htmlFor="AddressLine2">
          Address line 2
        </label>
        <p className={styles.optional}>Optional</p>
      </div>
      <Input
        type="text"
        id="AddressLine2"
        placeholder="Start typing"
        onChange={(value) => updateInsuranceData('AddressLine2', value)}
        value={insuranceDetailsData.AddressLine2 as string}
        data-dd-privacy="mask-user-input"
      />
      <label className={styles.formLabel} htmlFor="zipCode">
        Zip Code
      </label>

      <Input
        id="zipCode"
        placeholder="eg. 12345"
        type="zipCode"
        onBlur={handleRequest}
        onChange={(value) => updateInsuranceData('zipCode', value)}
        value={insuranceDetailsData.zipCode as string}
        error={error as string}
      />

      <label className={styles.formLabel} htmlFor="city">
        City
      </label>

      <Input
        type="text"
        id="city"
        placeholder="City"
        onBlur={validateIsInputFilled}
        onChange={(value) => updateInsuranceData('city', value)}
        value={insuranceDetailsData.city as string}
      />

      <label className={styles.formLabel} htmlFor="state">
        State
      </label>

      <Dropdown
        options={states}
        onChange={(value: string) => updateInsuranceData('state', value)}
        sideWays
        icon="CloseIcon"
        value={insuranceDetailsData.state as string}
      />
    </>
  );
};
