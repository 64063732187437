import { adminRoutes } from 'intakeOptimization/routes/constants';
import EmailVerification from 'intakeOptimization/views/EmailVerification/EmailVerification';
import React from 'react';

const Search = React.lazy(() => import('intakeOptimization/views/Search/Search'));
const PatientForm = React.lazy(() => import('intakeOptimization/views/PatientForm/PatientForm'));

const ClinicianSearchResults = React.lazy(
  () => import('intakeOptimization/views/ClinicianSearchResults/ClinicianSearchResults'),
);
const Clinician = React.lazy(() => import('intakeOptimization/views/Clinician/Clinician'));
const BookingForSelf = React.lazy(
  () => import('intakeOptimization/views/BookingForSelf/BookingForSelf'),
);
const AccountHolderInfoParent = React.lazy(
  () => import('intakeOptimization/views/AccountHolderInfo/AccountHolderInfoParent'),
);
const AccountHolderInfoChild = React.lazy(
  () => import('intakeOptimization/views/AccountHolderInfo/AccountHolderInfoChild'),
);
const AccountHolderInfo = React.lazy(
  () => import('intakeOptimization/views/AccountHolderInfo/AccountHolderInfo'),
);
const AccountInsurance = React.lazy(
  () => import('intakeOptimization/views/AccountInsurance/AccountInsurance'),
);
const InsuranceCard = React.lazy(
  () => import('intakeOptimization/views/InsuranceCard/InsuranceCard'),
);
const Confirmation = React.lazy(() => import('intakeOptimization/views/Confirmation/Confirmation'));
const BookAppointment = React.lazy(
  () => import('intakeOptimization/views/BookAppointment/BookAppointment'),
);
const AppointmentError = React.lazy(
  () => import('intakeOptimization/views/AppointmentError/AppointmentError'),
);
const AccountExists = React.lazy(
  () => import('intakeOptimization/views/AccountHolderExists/AccountExists'),
);

const ADMIN_ROUTES = [
  {
    path: adminRoutes.patientType,
    Component: PatientForm,
  },
  {
    path: adminRoutes.searchResults,
    Component: ClinicianSearchResults,
  },
  {
    path: adminRoutes.clinicianProfile,
    Component: Clinician,
  },
  {
    path: adminRoutes.confirmation,
    Component: Confirmation,
  },
  {
    path: adminRoutes.bookAppointment,
    Component: BookAppointment,
  },
  {
    path: adminRoutes.accountHolder,
    Component: AccountHolderInfo,
  },
  {
    path: adminRoutes.insuranceCard,
    Component: InsuranceCard,
    featureFlag: 'insuranceCard',
  },
  {
    path: adminRoutes.search,
    Component: Search,
  },
  {
    path: adminRoutes.appointmentError,
    Component: AppointmentError,
  },
];

export const ADMIN_ACCOUNT_HOLDER_INFO_ROUTES = [
  {
    path: adminRoutes.bookingForSelf,
    Component: BookingForSelf,
  },
  {
    path: adminRoutes.accountHolderParent,
    Component: AccountHolderInfoParent,
  },
  {
    path: adminRoutes.accountHolderChild,
    Component: AccountHolderInfoChild,
  },
  {
    path: adminRoutes.accountInsurance,
    Component: AccountInsurance,
  },
  {
    path: adminRoutes.accountHolderExists,
    Component: AccountExists,
  },
  {
    path: adminRoutes.insuranceCard,
    Component: InsuranceCard,
    featureFlag: 'insuranceCard',
  },
  {
    path: adminRoutes.confirmation,
    Component: Confirmation,
  },
];

export default ADMIN_ROUTES;
