import * as API from 'intakeOptimization/api/legacy';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { loadMatches, setLoading } from './actions';
import t from './types';

function* handleSearchByName(
  action: PayloadAction<{
    providerName: string;
    zipCode: number;
    age: number;
    typeOfCare: string;
    paymentType: string;
    insurance: string;
  }>,
): any {
  yield put(setLoading(true));
  const { providerName, zipCode, age, typeOfCare, paymentType, insurance } = action.payload;
  const selfPay = 'self_pay';
  const insuranceToSearch = paymentType === selfPay ? '' : insurance;
  const queryString = `search[search_term]=${providerName}${
    zipCode ? `&search[zip_codes]=${zipCode}` : ''
  }${age ? `&search[age]=${age}` : ''}${typeOfCare ? `&search[type_of_cares]=${typeOfCare}` : ''}${
    paymentType ? `&search[payment_type]=${paymentType}` : ''
  }${insuranceToSearch ? `&search[insurances][]=${insuranceToSearch}` : ''}`;
  try {
    const response = yield call(API.getProviderByName, queryString);
    yield put(loadMatches(response.data));
    yield put(setLoading(false));
  } catch (err) {
    yield put(setLoading(false));
  }
}

function* searchesSaga(): any {
  yield all([yield takeLatest(t.GET_PROVIDER_BY_NAME, handleSearchByName)]);
}

export default searchesSaga;
