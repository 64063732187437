import { Clinician } from 'patientOnboarding/@types';

import { getValueByKey } from '../../miscellaneous';

export const getMirroredClinicians = (clinicians: Clinician[]) => clinicians.map((clinician) => ({
  ...clinician,
  licenseKeys: clinician.addresses.map((address) => getValueByKey(address, 'license_key').toString()),
  concerns: clinician.concerns.map((concern) => getValueByKey(concern, 'name')),
  expertises: clinician.expertises.map((expertise) => getValueByKey(expertise, 'name')),
  interventions: clinician.interventions.map((intervention) => getValueByKey(intervention, 'name')),
  populations: clinician.populations.map((population) => getValueByKey(population, 'name')),
  modality: [
    clinician.video_visit ? 'video_visit' : null,
    clinician.in_office ? 'in_office' : null,
  ],
  location_names: clinician.addresses.map((address) => getValueByKey(address, 'facility_name')),
  gender: [clinician.gender],
  language: clinician.languages.map((language) => getValueByKey(language, 'name')),
  credentials: clinician.license_types.map((licenseType) => getValueByKey(licenseType, 'name')),
  distance_in_miles: clinician.distance_in_miles,
})) as Array<Record<string, string | unknown>>;
