import { RefObject, useEffect, useRef } from 'react';

export function useScrollToMiddleOnFocus<T extends HTMLElement>(): RefObject<T> {
  const ref = useRef<T>(null);
  const lastInteractionRef = useRef<'mouse' | 'keyboard' | null>(null);

  useEffect(() => {
    const handleFocus = (event: FocusEvent) => {
      if (
        ref.current
        && ref.current.contains(event.target as Node)
        && lastInteractionRef.current === 'keyboard'
      ) {
        const element = ref.current;
        const rect = element.getBoundingClientRect();
        const elementMiddle = rect.top + rect.height / 2;
        const viewportHeight = window.innerHeight;
        const viewportMiddle = viewportHeight / 2;

        window.scrollTo({
          top: window.scrollY + elementMiddle - viewportMiddle,
          behavior: 'smooth',
        });
      }
    };

    const handleMouseDown = () => {
      lastInteractionRef.current = 'mouse';
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        lastInteractionRef.current = 'keyboard';
      }
    };

    const element = ref.current;
    if (element) {
      document.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('keydown', handleKeyDown);
      element.addEventListener('focusin', handleFocus);

      return () => {
        document.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('keydown', handleKeyDown);
        element.removeEventListener('focusin', handleFocus);
      };
    }
  }, []);

  return ref;
}
