import React, { FC, useState } from 'react';

import { ReactComponent as CaretDown } from 'lifestance-ui/assets/svg/arrow-down-s-line.svg';
import { ReactComponent as CaretUp } from 'lifestance-ui/assets/svg/arrow-up-s-line.svg';

import styles from './InformationDropdown.module.scss';

interface IInformationProps {
  title: string;
  isOpen?: boolean;
  onOpen?: () => void;
}

export const InformationDropdown: FC<React.PropsWithChildren<IInformationProps>> = ({
  children,
  title,
  isOpen = false,
  onOpen,
}) => {
  const [open, setOpen] = useState(isOpen);

  const handleOpen = () => {
    if (onOpen) {
      onOpen();
    }
    setOpen(!open);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title} onClick={handleOpen}>
        <span style={{ display: 'inline-block' }}>{title}</span>
        {open && <CaretUp />}
        {!open && <CaretDown />}
      </div>
      {open && <div className={styles.content}>{children}</div>}
    </div>
  );
};
