import clsx from 'clsx';
import parse from 'html-react-parser';
import React, {
  FocusEventHandler,
  MutableRefObject,
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as CaretDownIcon } from 'lifestance-ui/assets/svg/caret-down.svg';
import { ReactComponent as CaretUpIcon } from 'lifestance-ui/assets/svg/caret-up.svg';
import { onClickOut } from 'lifestance-ui/utils';

import { MobileDropdown } from 'lifestance-ui';

import { isAbie } from 'intakeOptimization/selectors/selectors';

import styles from './Dropdown.module.scss';

type Option = {
  label: string;
  value: any;
  description?: string;
  separator?: boolean;
  isDisabled?: boolean;
};

interface DropDownProps {
  className?: string;
  disabled?: boolean;
  icon?: string;
  onChange: any;
  placeholder?: string;
  options: Option[];
  sideWays?: boolean;
  title?: string;
  value: string;
  testId?: string;
  dataCy?: string;
  sm?: boolean;
  onMobileClose?: () => void;
  error?: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onOpen?: () => void;
  fullHeight?: boolean;
  lastEventType?: string;
  disableScrollOnSelect?: boolean;
}

export const Dropdown: React.FC<React.PropsWithChildren<DropDownProps>> = ({
  className = '',
  disabled = false,
  icon = '',
  onChange,
  options,
  dataCy = '',
  placeholder = '',
  sideWays = false,
  testId = '',
  title = '',
  value,
  sm = false,
  onMobileClose,
  error = '',
  onBlur,
  onOpen,
  fullHeight = false,
  lastEventType,
  disableScrollOnSelect = false,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedLabel, setSelectedLabel] = useState<Option | undefined>();
  const [selectedRef, setSelectedRef] = useState<HTMLDivElement>(null as unknown as HTMLDivElement);
  const [bodyScroll, setBodyScroll] = useState<boolean>(true);
  const optionsContainerRef = useRef<any>();
  const optionRefs = useRef<MutableRefObject<HTMLLIElement>[]>([]);
  const [tabbed, setTabbed] = useState<boolean>(false);
  const isAdmin = useSelector(isAbie);

  if (optionRefs?.current !== null && optionRefs?.current?.length !== options?.length) {
    optionRefs.current = Array(options?.length)
      .fill(options)
      .map((_, i) => optionRefs?.current[i] || createRef());
  }

  useEffect(() => {
    if (value !== '') {
      setSelectedValue(value);
    } else {
      setSelectedValue(placeholder);
    }
  }, [value]);

  useEffect(() => {
    const label = options?.find((option) => selectedValue === option.value);
    setSelectedLabel(label);
  }, [selectedValue, options]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    setTabbed(false);
    if (!disabled) setOpen(!open);
  }, [disabled, setOpen, open]);

  const handleSelect = (value: any, optionRefs: any) => {
    setSelectedValue(value);
    onChange(value);
    setOpen(!open);
    setSelectedRef(optionRefs?.current);
  };

  const mobileClose = () => {
    setOpen(!open);
    typeof onMobileClose === 'function' ? onMobileClose() : null;
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        const optionBounds = optionsContainerRef.current?.getBoundingClientRect();
        if (
          !disableScrollOnSelect
          && optionBounds
          && optionBounds.y + optionBounds.height > document.documentElement.clientHeight
        ) {
          document.documentElement.scrollTo({
            top: optionBounds.y - optionBounds.height + document.documentElement.scrollTop,
            behavior: 'smooth',
          });
        }
      }, 200);
      return onClickOut({
        triggerRef: dropdownRef,
        action: handleClick,
        ignoreParent: true,
      });
    }
  }, [open, handleClick]);

  useEffect(() => {
    !bodyScroll
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'initial');
  }, [bodyScroll]);

  const focusPreviouslySelectedOption = () => {
    setTimeout(() => {
      if (selectedRef !== null) {
        selectedRef?.focus();
      }
    }, 100);
  };

  const selectOption = (element: any) => {
    if (element) {
      const value = element?.getAttribute('data-value');
      element.focus();
      setSelectedValue(value);
      onChange(value);
      setSelectedRef(element);
    }
  };

  const selectFirstOption = () => {
    setTimeout(() => {
      const element = optionRefs.current[0].current;
      selectOption(element);
    }, 100);
  };

  const openAndFocusOption = () => {
    setOpen(true);
    if (selectedValue === placeholder) {
      selectFirstOption();
    } else {
      focusPreviouslySelectedOption();
    }
  };

  const handleNavigate = (event: React.KeyboardEvent<HTMLDivElement | HTMLLIElement>) => {
    event.stopPropagation();
    if (isAdmin) {
      if (event.key === 'Tab' && event.shiftKey && open) {
        event.preventDefault();
        if (selectedRef) {
          const previousOption = selectedRef?.previousSibling as HTMLLIElement;
          selectOption(previousOption);
        }
        return;
      }

      switch (event.key) {
        case ' ': // Space
        case 'Enter': // Enter
          event.preventDefault();
          if (!open) {
            openAndFocusOption();
          } else {
            setOpen(!open);
            if (dropdownRef.current !== null) dropdownRef.current.focus();
          }
          break;
        case 'Escape': // Escape
        case 'Tab': // Tab
          if (open) {
            event.preventDefault();
            if (!selectedRef) {
              selectFirstOption();
            } else {
              const nextOption = selectedRef.nextSibling as HTMLLIElement;
              selectOption(nextOption);
            }
          }
          break;
        default:
      }
    } else {
      switch (event.key) {
        case 'ArrowUp': // Arrow Up
          event.preventDefault();
          if (!open) {
            openAndFocusOption();
          }
          if (!selectedRef) {
            return;
          }
          if (selectedRef) {
            const previousOption = selectedRef?.previousSibling as HTMLLIElement;
            selectOption(previousOption);
          }
          break;
        case 'ArrowDown': // Arrow Down
        case ' ': // Space
          event.preventDefault();
          if (!open) {
            openAndFocusOption();
          } else if (!selectedRef) {
            selectFirstOption();
          } else {
            const nextOption = selectedRef.nextSibling as HTMLLIElement;
            selectOption(nextOption);
          }
          break;
        case 'Enter': // Enter
          if (!open) {
            openAndFocusOption();
          } else {
            setOpen(!open);
            if (dropdownRef.current !== null) dropdownRef.current.focus();
          }
          break;
        case 'Escape': // Escape
        case 'Tab': // Tab
          setOpen(false);
          break;
        default:
      }
    }
  };

  useEffect(() => {
    if (onOpen && open) {
      onOpen();
    }
  }, [open]);

  const onInputBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    setTabbed(false);
    if (onBlur) onBlur(event);
  };

  const onInputFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    setTabbed(lastEventType === 'tab');
  };

  return (
    <div
      tabIndex={disabled ? -1 : 0}
      role="listbox"
      className={clsx(className, styles.container, {
        [styles.disabled]: disabled,
      })}
      ref={dropdownRef}
      onKeyDown={handleNavigate}
      onBlur={onInputBlur}
      onFocus={onInputFocus}
    >
      <div
        className={clsx(styles.dropdown, {
          [styles.selectOpen]: open,
          [styles.selected]: selectedValue !== placeholder,
          [styles.disabled]: disabled,
          [styles.error]: error && error.length > 0,
          [styles.tabbed]: tabbed && isAdmin,
        })}
        data-cy={dataCy || testId}
        data-testId={`dropdown${testId}`}
        onKeyDown={handleNavigate}
        onClick={handleClick}
      >
        <span
          className={clsx(styles.value, {
            [styles.placeholder]: !selectedValue,
          })}
        >
          {selectedLabel?.label || placeholder}
        </span>
        {open ? <CaretUpIcon /> : <CaretDownIcon />}
      </div>
      {error && error.length ? (
        <span
          data-testId={`dropdownError${testId}`}
          className={clsx({ [styles.errorMessage]: error })}
        >
          {error}
        </span>
      ) : null}
      {open && (
        <div
          data-testId={`dropdownOptionsContainer${testId}`}
          className={clsx(className, styles.optionsContainer, {
            [styles.small]: sm,
            [styles.errorContainer]: error && error.length > 0,
          })}
          onKeyDown={handleNavigate}
          onMouseEnter={() => setBodyScroll(false)}
          onMouseLeave={() => setBodyScroll(true)}
          ref={optionsContainerRef}
        >
          {options
            && options.length > 0
            && options.map(({ label, value, description, separator, isDisabled }, i) => (
              <>
                <li
                  ref={optionRefs.current[i]}
                  key={`${value}-option${i + 1}`}
                  onClick={() => !isDisabled && handleSelect(value, optionRefs.current[i])}
                  tabIndex={-1}
                  role="option"
                  aria-selected={selectedValue === value}
                  data-value={value}
                  className={clsx(styles.option, {
                    [styles.selfPay]: value === 'self_pay',
                    [styles.disabled]: isDisabled,
                    [styles.focused]: selectedValue === value,
                    [styles.withDescription]: description,
                  })}
                  onKeyDown={handleNavigate}
                  data-cy={`${testId}-${i}`}
                  data-testId={`dropdownOption${testId}SetOption${i}`}
                >
                  <span className={clsx({ [styles.title]: description })}>{label}</span>
                  {description ? (
                    <span className={styles.description}>{parse(description)}</span>
                  ) : null}
                </li>
                {separator ? <div className={styles.separator} /> : null}
              </>
            ))}
        </div>
      )}
      <MobileDropdown
        testId={testId}
        options={options}
        title={title}
        handleSelect={handleSelect}
        open={open}
        mobileClose={mobileClose}
        value={selectedValue}
        icon={icon}
        sideWays={sideWays}
        fullHeight={fullHeight}
      />
    </div>
  );
};
