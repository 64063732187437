import clsx from 'clsx';
import { RClinicianProfile } from 'patientOnboarding/@types';
import { ReactComponent as Vector } from 'patientOnboarding/assets/svg/Vector.svg';
import { ReactComponent as ArrowIcon } from 'patientOnboarding/assets/svg/arrow-left-full.svg';
import React, { useState } from 'react';

import { SlideCard } from 'lifestance-ui';

import { useInvoca } from 'patientOnboarding/hooks';

import { defaultPhoneNumber, formatContactNumber, removeDuplicates } from 'patientOnboarding/utils';

import styles from './FrequentlyAskedQuestions.module.scss';

type ProfileProps = {
  profile: RClinicianProfile;
  phoneNumber?: string;
  isAdmin?: boolean;
};

export const FAQ: React.FC<ProfileProps> = ({ profile, phoneNumber, isAdmin = false }) => {
  const facility = profile?.facility_location[0];
  const clinic = `${facility?.facility_name} Clinic`;
  const address = `${facility?.address_line1}${
    facility?.address_line2 ? ` ${facility?.address_line2}` : ''
  }`;
  const geoAddress = `${facility?.city}, ${facility?.state} ${facility?.postal_code}`;
  const fullAddress = `${facility?.facility_name} ${facility?.address_line1} ${facility?.city} ${facility?.state}`;
  const directions = `https://www.google.com/maps/search/?api=1&query=${encodeURI(fullAddress)}`;
  const languages = profile.languages_spoken.filter((el) => el.name.toLowerCase() !== 'english');
  const [faqContent] = useState({
    firstName: profile.first_name,
    lastName: profile.last_name,
    fullName: `${profile.first_name} ${profile.last_name}`,
    license: profile.credentials,
    acceptingPatients: profile.accepting_new_patients,
    insuranceList: removeDuplicates(profile.insurances),
    manageMedication: profile.manages_medication,
    type: profile.type,
    languages,
  });
  const [activeId, setActiveId] = useState('');
  const content = [
    {
      id: 'questionOne',
      question: `How do I schedule an appointment with ${faqContent.fullName}?`,
    },
    {
      id: 'questionTwo',
      question: `Is ${faqContent.fullName} Accepting new patients?`,
    },
    {
      id: 'questionThree',
      question: `Which insurance does ${faqContent.fullName} work with?`,
    },
    {
      id: 'questionFour',
      question: `Can ${faqContent.fullName} prescribe medications?`,
    },
    {
      id: 'questionFive',
      question: `Where is the office of ${faqContent.fullName} located?`,
    },
    {
      id: 'questionSeven',
      question: `What language does ${faqContent.fullName} speak?`,
    },
  ];

  useInvoca();

  const handleSelect = (id: string) => {
    if (id === activeId) {
      setActiveId('');
    } else {
      setActiveId(id);
    }
  };

  return (
    <div className={styles.faqContainer}>
      <SlideCard
        key={`sliderCard${content[0].id}`}
        id={content[0].id}
        title={content[0].question}
        selected={content[0].id === activeId}
        onClick={handleSelect}
        disableTabNav={isAdmin}
      >
        <div>
          Choose an available time slot to book directly with this provider. You can also call
          {' '}
          <a
            tabIndex={isAdmin ? -1 : 0}
            href={`tel:${formatContactNumber(phoneNumber) || ''}`}
            className={clsx(styles.phone, 'invocaPhoneNumber')}
            data-testid="faqPhoneNumber1"
          >
            {phoneNumber || defaultPhoneNumber}
          </a>
          {' '}
          to have someone assist you.
        </div>
      </SlideCard>
      <SlideCard
        key={`sliderCard${content[2].id}`}
        id={content[2].id}
        title={content[2].question}
        selected={content[2].id === activeId}
        onClick={handleSelect}
        disableTabNav={isAdmin}
      >
        <div>
          LifeStance Health providers accept most commercial insurance plans that are offered in the
          states where we offer services, including telehealth. Please see the following list of
          insurances accepted by
          {' '}
          {faqContent.fullName}
          :
          <div className={styles.insuranceList}>
            {faqContent.insuranceList.map((insurance) => (
              <div className={styles.area} key={insurance.id}>
                <Vector className={styles.vector} />
                <div>{insurance.name}</div>
              </div>
            ))}
          </div>
          If you don&apos;t see your insurance listed, please contact us at
          {' '}
          <a
            tabIndex={isAdmin ? -1 : 0}
            href={`tel:${formatContactNumber(phoneNumber) || ''}`}
            className={clsx(styles.phone, 'invocaPhoneNumber')}
            data-testid="faqPhoneNumber2"
          >
            {phoneNumber || defaultPhoneNumber}
          </a>
          {' '}
          to see how we can help.
        </div>
      </SlideCard>
      <SlideCard
        key={`sliderCard${content[3].id}`}
        id={content[3].id}
        title={content[3].question}
        selected={content[3].id === activeId}
        onClick={handleSelect}
        disableTabNav={isAdmin}
      >
        <div>
          {faqContent.manageMedication
            ? `Yes, ${faqContent.fullName} is licensed to prescribe medication in appropriate situations.`
            : `${faqContent.fullName} is a ${faqContent.type} and cannot prescribe medication.
          However, most LifeStance offices have a Psychiatrist or APN on staff
          who can prescribe medication if needed.`}
        </div>
      </SlideCard>
      <SlideCard
        key={`sliderCard${content[4].id}`}
        id={content[4].id}
        title={content[4].question}
        selected={content[4].id === activeId}
        onClick={handleSelect}
        disableTabNav={isAdmin}
      >
        <div>
          <div>{clinic}</div>
          <div>{address}</div>
          <div>{geoAddress}</div>
          <div className={styles.directions}>
            <a
              tabIndex={isAdmin ? -1 : 0}
              className={styles.link}
              href={directions}
              target="_blank"
              rel="noreferrer"
              data-testId="faqGetDirections"
            >
              <span>Get directions</span>
              {' '}
              <ArrowIcon />
            </a>
          </div>
        </div>
      </SlideCard>
      {faqContent.languages.length > 0 && (
        <SlideCard
          key={`sliderCard${content[5].id}`}
          id={content[5].id}
          title={content[5].question}
          selected={content[5].id === activeId}
          onClick={handleSelect}
          disableTabNav={isAdmin}
        >
          <div className={styles.languageList}>
            {faqContent.languages.map((language) => (
              <div className={styles.area} key={language.id}>
                <Vector className={styles.vector} />
                <div>{language.name}</div>
              </div>
            ))}
          </div>
        </SlideCard>
      )}
    </div>
  );
};
