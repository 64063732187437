import { FacilityLocation } from 'intakeOptimization/@types';

export interface Filter {
  label: string;
  name: string;
  maxHeight?: boolean;
  options: NestedOption[] | Option[] | FacilityLocation[];
  testId?: string;
}

export interface Option {
  label: string;
  value: string;
  testId?: string;
}

export interface NestedOption {
  label?: string;
  options?: Option[];
}

export const filterNames = {
  licenseKeys: 'licenseKeys',
  focus: 'focus',
  modality: 'modality',
  location_names: 'location_names',
  availability_filter: 'availability_filter',
  gender: 'gender',
  language: 'language',
  credentials: 'credentials',
};

const filters: Filter[] = [
  {
    label: 'Key',
    name: filterNames.licenseKeys,
    maxHeight: true,
    options: [],
  },
  {
    label: 'Issues',
    name: filterNames.focus,
    maxHeight: true,
    options: [],
  },
  {
    label: 'Visit Type',
    name: filterNames.modality,
    testId: 'modalityEntity',
    options: [
      {
        label: 'Video visit',
        value: 'video_visit',
        testId: 'videoVisit',
      },
      {
        label: 'In-office',
        value: 'in_office',
        testId: 'in-OfficeVisit',
      },
    ],
  },
  {
    label: 'Location',
    name: filterNames.location_names,
    testId: 'locationEntity',
    maxHeight: true,
    options: [],
  },
  {
    label: 'Availability',
    name: filterNames.availability_filter,
    testId: 'availabilityFilterEntity',
    maxHeight: true,
    options: [
      {
        label: 'Time of day',
        options: [
          {
            label: 'Before 10am',
            value: 'before_10_AM',
            testId: 'before10AMEntity',
          },
          {
            label: 'Before 12pm',
            value: 'before_12_PM',
            testId: 'before12PMEntity',
          },
          {
            label: 'After 12pm',
            value: 'after_12_PM',
            testId: 'after12PMEntity',
          },
          {
            label: 'After 3pm',
            value: 'after_3_PM',
            testId: 'after3PMEntity',
          },
        ],
      },
      {
        label: 'Availability',
        options: [
          {
            label: 'Next Three Days',
            value: 'next_three_Days',
            testId: 'nextThreeDaysEntity',
          },
          {
            label: 'Next Week',
            value: 'next_week',
            testId: 'nextWeekEntity',
          },
          {
            label: 'Next Two Weeks',
            value: 'next_two_Weeks',
            testId: 'nextTwoWeeksEntity',
          },
          {
            label: 'Next Month',
            value: 'next_month',
            testId: 'nextMonthEntity',
          },
        ],
      },
    ],
  },
  {
    label: 'Gender',
    name: filterNames.gender,
    testId: 'genderEntity',
    options: [
      {
        label: 'Female',
        value: 'Female',
        testId: 'Female',
      },
      {
        label: 'Male',
        value: 'Male',
        testId: 'Male',
      },
      {
        label: 'Non-binary',
        value: 'Non-binary',
        testId: 'Non-binary',
      },
    ],
  },
  {
    label: 'Language',
    name: filterNames.language,
    testId: 'languageEntity',
    maxHeight: true,
    options: [
      {
        label: 'English',
        value: 'English',
        testId: 'English',
      },
      {
        label: 'Spanish',
        value: 'Spanish',
        testId: 'Spanish',
      },
      {
        label: 'Mandarin',
        value: 'Mandarin',
        testId: 'Mandarin',
      },
      {
        label: 'Hindi',
        value: 'Hindi',
        testId: 'Hindi',
      },
      {
        label: 'German',
        value: 'German',
        testId: 'German',
      },
      {
        label: 'French',
        value: 'French',
        testId: 'French',
      },
      {
        label: 'Russian',
        value: 'Russian',
        testId: 'Russian',
      },
    ],
  },
  {
    label: 'Credentials',
    name: filterNames.credentials,
    testId: 'credential',
    maxHeight: true,
    options: [],
  },
];

export default filters;
