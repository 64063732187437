import React, { FC } from 'react';

import styles from './LoadMoreButton.module.scss';

interface LoadMoreButtonProps {
  loadMore: () => void;
  testId?: string;
}

export const LoadMoreButton: FC<React.PropsWithChildren<LoadMoreButtonProps>> = ({
  testId,
  loadMore,
}) => (
  <div className={styles.container}>
    <button data-testId={testId} onClick={loadMore}>
      <span>Load more Providers</span>
    </button>
  </div>
);
