import App from 'modules/application/App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from 'modules/application/store';

import hotjarInitialize from 'patientOnboarding/utils/hotjar';

import './fonts/Damion/Damion-Regular.ttf';
import reportWebVitals from './reportWebVitals';

declare global {
  interface Window {
    Cypress: any;
    store: any;
    contactBubble: any;
    Invoca: any;
    HPS: any;
    hpsInstance: any;
    dataLayer: any;
  }
}

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container! as HTMLElement);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
);

if (window.Cypress) {
  window.store = store;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
hotjarInitialize();
