import { legalGender } from 'patientOnboarding/views/InsuranceDetails/data';
import React, { FC, useEffect, useState } from 'react';

import { Checkbox, Dropdown, Input, InputMask } from 'lifestance-ui';

import { validateBirthDay, validateEmail } from 'patientOnboarding/utils';

import styles from './PolicyholderForm.module.scss';

interface PolicyholderFormProps {
  updateInsuranceData: (name: string, value: boolean | string) => void;
  validateIsInputFilled: (event: any) => void;
  insuranceDetailsData: {
    isPolicyholderEmailValid: boolean;
    policyFirstName: string;
    policyLastName: string;
    policyBirthday: string;
    policyholderGender: string;
    policyholderEmail: string;
    hasDifferentAddress: boolean;
    [key: string]: string | boolean;
  };
}
export const PolicyholderForm: FC<PolicyholderFormProps> = ({
  updateInsuranceData,
  validateIsInputFilled,
  insuranceDetailsData,
}) => {
  const [showErrorBirth, setShowErrorBirth] = useState<boolean>();
  const [showErrorEmail, setShowErrorEmail] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(
    insuranceDetailsData.isPolicyholderEmailValid || false,
  );

  useEffect(() => {
    updateInsuranceData('isPolicyholderEmailValid', isEmailValid);
  }, [isEmailValid]);

  const handleBirthDateBlur = (dateOfBirth: string) => {
    const isValidBirth = !validateBirthDay(dateOfBirth);
    setShowErrorBirth(isValidBirth);
    updateInsuranceData('errorDOB', !isValidBirth);
  };

  const handleEmailBlur = (email: string) => {
    const isValidEmail = validateEmail(email);
    if (isValidEmail) {
      setShowErrorEmail(false);
      setIsEmailValid(true);
    } else {
      setShowErrorEmail(true);
      setIsEmailValid(false);
    }
  };

  return (
    <>
      <div className={styles.divider} />
      <p className={styles.header}>About the policyholder</p>
      <label className={styles.formLabel} htmlFor="policyFirstName">
        First name
      </label>
      <Input
        type="text"
        id="policyFirstName"
        testId="PolicyFirstName"
        placeholder="Enter as on insurance card"
        onBlur={(event) => {
          validateIsInputFilled(event);
        }}
        onChange={(value) => updateInsuranceData('policyFirstName', value)}
        value={insuranceDetailsData.policyFirstName}
        data-dd-privacy="mask-user-input"
      />
      <label className={styles.formLabel} htmlFor="policyLastName">
        Last name
      </label>
      <Input
        type="text"
        id="policyLastName"
        testId="PolicyLastName"
        placeholder="Enter as on insurance card"
        onBlur={(event) => {
          validateIsInputFilled(event);
        }}
        onChange={(value) => updateInsuranceData('policyLastName', value)}
        value={insuranceDetailsData.policyLastName}
        data-dd-privacy="mask-user-input"
      />
      <label className={styles.formLabel} htmlFor="policyBirthday">
        Date of birth
      </label>
      <InputMask
        id="policyBirthday"
        mask="99/99/9999"
        testId="PolicyDOB"
        placeholder="MM/DD/YYYY"
        onChange={(event) => updateInsuranceData('policyBirthday', event.target.value)}
        error={showErrorBirth ? 'Please enter a valid date of birth' : ''}
        onBlur={(e) => handleBirthDateBlur(e.target.value)}
        value={insuranceDetailsData.policyBirthday}
        inputMode="numeric"
      />
      <label className={styles.formLabel} htmlFor="policyholderGender">
        Gender registered with insurance
      </label>
      <Dropdown
        placeholder="Select an option"
        testId="PolicyholderGender"
        options={legalGender}
        onChange={(value: string) => updateInsuranceData('policyholderGender', value)}
        sideWays
        icon="CloseIcon"
        value={insuranceDetailsData.policyholderGender}
      />
      <div className={styles.fieldset}>
        <label className={styles.formLabel} htmlFor="policyholderEmail">
          Email address
        </label>
        <Input
          type="email"
          tabIndex={0}
          error={showErrorEmail ? 'Please enter a valid email address' : ''}
          id="policyholderEmail"
          testId="PolicyholderEmail"
          onChange={(value) => updateInsuranceData('policyholderEmail', value)}
          placeholder="julia@email.com"
          onBlur={(e) => handleEmailBlur(e.target.value)}
          value={insuranceDetailsData.policyholderEmail}
          data-dd-privacy="mask-user-input"
        />
      </div>
      <Checkbox
        testId="PolicyholderAddress"
        caption="This primary policyholder has a different mailing address than the patient."
        onChange={(value) => updateInsuranceData('hasDifferentAddress', value)}
        className={styles.checkbox}
        value={insuranceDetailsData.hasDifferentAddress}
      />
    </>
  );
};
