import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { AddressData, InsuranceDetails, RConsent } from 'intakeOptimization/@types';

import t from './types';

export type InitialState = {
  isLoading: boolean;
  error: string;
  consents: RConsent[];
  reviewedConsents: number[];
  signedConsents: boolean;
  hasAddress: boolean;
  insuranceDetails: InsuranceDetails;
  insuranceCards: {
    frontCard: File;
    backCard: File;
  };
  patientPortal: string;
  hasInsuranceInfo: boolean;
};

const initialState: InitialState = {
  isLoading: false,
  error: '',
  consents: [],
  reviewedConsents: [],
  signedConsents: false,
  insuranceDetails: {
    zipCode: '',
    city: '',
    state: '',
    streetAddress2: '',
    streetAddress1: '',
    hasDifferentAddress: false,
    email: '',
    gender: '',
    dateOfBirth: '',
    lastName: '',
    firstName: '',
    policyHolder: '',
    mentalHealthphoneNumber: '',
    insuranceMemberId: '',
    insuranceSkipFlag: false,
  },
  insuranceCards: {
    frontCard: {} as File,
    backCard: {} as File,
  },
  hasAddress: false,
  patientPortal: '',
  hasInsuranceInfo: false,
};

export const userSlice = createSlice({
  name: 'IO_user',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setConsents: (state, action: PayloadAction<RConsent[]>) => {
      state.consents = action.payload;
    },
    setSignedConsents: (state, action: PayloadAction<boolean>) => {
      state.signedConsents = action.payload;
    },
    reviewConsent: (state, action: PayloadAction<number>) => {
      state.reviewedConsents = [...state.reviewedConsents, action.payload];
    },
    setInsuranceDetails: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.insuranceDetails = {
        ...state.insuranceDetails,
        ...action.payload,
      };
    },
    setHasAddress: (state, action: PayloadAction<boolean>) => {
      state.hasAddress = action.payload;
    },
    setFrontCard: (state, action: PayloadAction<File>) => {
      state.insuranceCards.frontCard = action.payload;
    },
    setBackCard: (state, action: PayloadAction<File>) => {
      state.insuranceCards.backCard = action.payload;
    },
    setInsuranceSkipFlag: (state, action: PayloadAction<boolean>) => {
      state.insuranceDetails.insuranceSkipFlag = action.payload;
    },
    setPatientPortal: (state, action: PayloadAction<string>) => {
      state.patientPortal = action.payload;
    },
    setHasInsurance: (state, action: PayloadAction<boolean>) => {
      state.hasInsuranceInfo = action.payload;
    },
  },
});

export const getConsents = createAction<{ patientId: number }>(t.GET_CONSENTS);
export const saveAddressData = createAction<AddressData>(t.SAVE_ADDRESS);
export const signConsents = createAction<number>(t.SIGN_CONSENTS);
export const saveInsuranceDetails = createAction<number>(t.SAVE_INSURANCE_DETAILS);
export const validateZipCodeInsurance = createAction<number>(t.VALIDATE_ZIP_CODE);
export const getInsuranceSkipFlag = createAction<number>(t.GET_INSURANCE_SKIP_FLAG);

export const {
  setConsents,
  setError,
  setLoading,
  setSignedConsents,
  setInsuranceDetails,
  reviewConsent,
  setHasAddress,
  setBackCard,
  setFrontCard,
  setInsuranceSkipFlag,
  setPatientPortal,
  setHasInsurance,
} = userSlice.actions;

export default userSlice.reducer;
