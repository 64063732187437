import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import {
  AccountHolder,
  ChildPatient,
  RAccountHolder,
  RPatient,
  TrackPatientStatus,
  UpdateAccountHolder,
  User,
} from 'patientOnboarding/@types';

import t from './types';

type ErrorStatus = {
  status: number;
  message?: string;
};

export type ResParty = {
  chart: string;
  first_name: string;
  id: string;
  last_name: string;
  lfs_account_holder_id: number;
  responsible_party: boolean;
};

export type InitialState = {
  isAdmin: boolean;
  bookingForSelf: RAccountHolder;
  isBookingModalOpen: boolean;
  step: string;
  error: ErrorStatus;
  intake: boolean;
  paymentMethod: string;
  appointmentId: number;
  responsibleParty: ResParty[];
  isTalkingPoint: boolean;
  existsInAMD: boolean;
  isEnabled: boolean;
  isStateEnabled: boolean;
};

const initialState: InitialState = {
  isAdmin: false,
  bookingForSelf: {} as RAccountHolder,
  isBookingModalOpen: false,
  step: '',
  error: {} as ErrorStatus,
  intake: false,
  paymentMethod: 'self_pay',
  appointmentId: -1,
  responsibleParty: [],
  isTalkingPoint: true,
  existsInAMD: false,
  isEnabled: false,
  isStateEnabled: false,
};

type PatientDetails = {
  patientId: number;
  appointmentId: number;
};

type ResendEmail = {
  email: string;
  id: number;
};

const adminConfigSlice = createSlice({
  name: 'PO_admin',
  initialState,
  reducers: {
    setIsAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
    setIsBookingModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isBookingModalOpen = action.payload;
    },
    setStep: (state, action: PayloadAction<string>) => {
      state.step = action.payload;
    },
    setPaymentMethod: (state, action: PayloadAction<string>) => {
      state.paymentMethod = action.payload;
    },
    setPatientId: (state, action: PayloadAction<number>) => {
      state.bookingForSelf.account_holder_patient_id = action.payload;
    },
    setAppointmentId: (state, action: PayloadAction<number>) => {
      state.appointmentId = action.payload;
    },
    pushBookingForSelfInfo: (state, action: PayloadAction<Partial<RAccountHolder>>) => {
      state.bookingForSelf = { ...state.bookingForSelf, ...action.payload };
    },
    pushBookingForChildInfo: (state, action: PayloadAction<Partial<RPatient>>) => {
      state.bookingForSelf = {
        ...state.bookingForSelf,
        child: {
          ...state.bookingForSelf.child,
          ...action.payload,
        },
      };
    },
    handleExceptions: (state, action: PayloadAction<ErrorStatus>) => {
      state.error = action.payload;
    },
    setExistsInAMD: (state, action: PayloadAction<boolean>) => {
      state.existsInAMD = action.payload;
    },
    continueToIntake: (state, action: PayloadAction<boolean>) => {
      state.intake = action.payload;
    },
    cleanChildUser: (state) => {
      state.bookingForSelf.child = {} as User;
    },
    setResponsibleParty: (state, action: PayloadAction<ResParty[]>) => {
      state.responsibleParty = action.payload;
    },
    setIsTalkingPoint: (state, action: PayloadAction<boolean>) => {
      state.isTalkingPoint = action.payload;
    },
    resetAge: (state) => {
      state.bookingForSelf = {} as RAccountHolder;
    },
    setIsEnabled: (state, action: PayloadAction<boolean>) => {
      state.isEnabled = action.payload;
    },
    setIsStateEnabled: (state, action: PayloadAction<boolean>) => {
      state.isStateEnabled = action.payload;
    },
  },
});

export const getPatientDetails = createAction<PatientDetails>(t.GET_PATIENT_DETAILS);
export const putBookingForSelfInfo = createAction<UpdateAccountHolder>(t.PUT_BOOKING_FOR_Self_INFO);
export const postBookingForChildInfo = createAction<ChildPatient>(t.POST_BOOKING_FOR_Child_INFO);
export const putBookingForChildInfo = createAction<UpdateAccountHolder>(
  t.PUT_BOOKING_FOR_Child_INFO,
);
export const postBookingForSelfInfo = createAction<AccountHolder>(t.POST_BOOKING_FOR_Self_INFO);
export const resendBookingEmail = createAction<ResendEmail>(t.RESEND_BOOKING_EMAIL);
export const trackPatientStatus = createAction<TrackPatientStatus>(t.TRACK_PATIENT_STATUS);
export const startIntake = createAction(t.START_INTAKE);
export const saveNextSteps = createAction<number>(t.SET_ACCOUNT_EXISTS_NEXT_STEPS);

export const {
  setIsAdmin,
  setIsBookingModalOpen,
  setStep,
  setPatientId,
  setPaymentMethod,
  pushBookingForChildInfo,
  pushBookingForSelfInfo,
  handleExceptions,
  continueToIntake,
  setAppointmentId,
  cleanChildUser,
  setResponsibleParty,
  setIsTalkingPoint,
  setExistsInAMD,
  resetAge,
  setIsEnabled,
  setIsStateEnabled,
} = adminConfigSlice.actions;

export default adminConfigSlice.reducer;
