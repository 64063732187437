import clsx from 'clsx';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import Select, { StylesConfig } from 'react-select';

import { useDevice } from 'lifestance-ui/utils';

import styles from './DropdownSingleSelect.module.scss';

export interface DropdownSelectOption {
  value: string;
  label: string;
  isDisabled?: boolean;
}

interface DropdownSelectProps {
  placeholder: string;
  disabled?: boolean;
  className?: string;
  options: DropdownSelectOption[];
  onChange: any;
  value: string;
  testId?: string;
  dataCy?: string;
}

export const DropdownSingleSelect: React.FC<React.PropsWithChildren<DropdownSelectProps>> = ({
  placeholder,
  disabled = false,
  className = '',
  options,
  onChange,
  value,
  dataCy,
  testId,
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(value);
  const { isMobile } = useDevice();
  const [selectedRef, setSelectedRef] = useState<HTMLDivElement>(null as unknown as HTMLDivElement);

  const dropdownRef = useRef<HTMLDivElement>(null as unknown as HTMLDivElement);

  const focusOption = (element: HTMLDivElement): void => {
    if (element) {
      element.focus();
      setSelectedRef(element);
    }
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const focusPreviouslySelectedOption = () => {
    setTimeout(() => {
      selectedRef?.focus();
    }, 100);
  };

  const openAndFocusOption = () => {
    setOpen(true);
    if (selected?.length === 0) {
      // focusFirstOption();
    } else {
      focusPreviouslySelectedOption();
    }
  };

  const handleNavigate = (event: KeyboardEvent) => {
    event.stopPropagation();
    // FIXME: keyCode is deprecated
    // https://developer.mozilla.org/en-US/docs/web/api/keyboardevent/keycode#browser_compatibility
    switch (event.keyCode) {
      case 38: // Arrow Up
        if (!selectedRef) {
          return;
        }
        if (selectedRef) {
          const previousOption = selectedRef?.previousSibling as HTMLDivElement;
          focusOption(previousOption);
        }
        break;
      case 40: // Arrow Down
        if (!open) {
          openAndFocusOption();
        } else if (!selectedRef) {
          // focusFirstOption();
        } else {
          const nextOption = selectedRef.nextSibling as HTMLDivElement;
          focusOption(nextOption);
        }
        break;
      case 13: // Enter
        if (!open) {
          openAndFocusOption();
        } else {
          setOpen(!open);
          dropdownRef.current?.focus();
        }
        break;
      case 27: // Escape
      case 9: // Tab
        setOpen(false);
        break;
      // case 32: // Space
      // event.preventDefault();
      // selectOption(selectedRef);
      // break;
      default:
    }
  };

  const handleChange = (selectValue: any) => {
    setSelected(selectValue.value);
    onChange(selectValue.value);
  };
  const customStyles: StylesConfig<DropdownSelectOption> = {
    control: (custStyles, state) => ({
      ...custStyles,
      padding: '0px 0.875rem 0px 1.25rem',
      fontFamily: 'STYRENE B WEB',
      fontSize: '16px',
      fontWeight: 'normal',
      cursor: 'pointer',
      borderRadius: '8px',
      borderColor: state.isFocused ? '#8b8b8b' : '#d1d1d1',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#acacac',
      },
    }),
    valueContainer: (custStyles) => ({
      ...custStyles,
      border: '1px solid $gray-300',
      borderRadius: '8px',
      alignItems: 'center',
      cursor: 'pointer',
      color: '$gray-400',
      justifyContent: 'space-between',
      padding: '($spacing-04 + $spacing-01) $spacing-06',
      height: '52px',
    }),
    placeholder: (custStyles) => ({
      ...custStyles,
      color: '#acacac',
      fontWeight: 300,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (custStyles, { isDisabled, isFocused, isSelected, options: locOptios, label }) => isDisabled
      ? {
        ...custStyles,
        backgroundColor: '#fff',
        color: '#ACACAC',
        padding: '0.5rem 1.25rem',
        cursor: 'not-allowed',
        marginTop:
              locOptios.findIndex((option) => label === option.label) === 1 ? '1rem' : '0.5rem',
        marginLeft:
              locOptios.findIndex((option) => label === option.label) === 1 ? '1.25rem' : '1.25rem',
        paddingTop: locOptios.findIndex((option) => label === option.label) === 1 ? '1rem' : 0,
        paddingBottom:
              locOptios.findIndex((option) => label === option.label) === 1 ? '0.75rem' : '1rem',
        paddingLeft: locOptios.findIndex((option) => label === option.label) === 1 ? 0 : 0,
        borderBottom:
              locOptios.findIndex((option) => label === option.label) === 1
                ? 0
                : '1px solid #E3E3E3',
        borderTop:
              locOptios.findIndex((option) => label === option.label) === 1
                ? '1px solid #E3E3E3'
                : 'none',
        width:
              locOptios.findIndex((option) => label === option.label) === 1
                ? 'calc(100% - 2.5rem)'
                : 'calc(100% - 2.5rem)',
      }
      : {
        ...custStyles,
        backgroundColor: isFocused ? '#f6f9ff' : '#fff',
        color: isSelected ? '#333333' : '#333333',
        padding: '0.5rem 1.25rem',
      },
    menuList: (custStyles) => ({
      ...custStyles,
      paddingTop: '1.25rem',
      fontFamily: 'STYRENE B WEB',
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '24px',
    }),
    indicatorsContainer: (custStyles: any, state) => ({
      ...custStyles,
      marginRight: -5,
      svg: {
        color: '#8b8b8b',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
      },
    }),
  };

  return (
    <div
      tabIndex={0}
      role="listbox"
      data-cy={dataCy || testId}
      className={clsx(className, styles.container)}
      ref={dropdownRef}
      onKeyDown={handleNavigate}
    >
      <Select
        value={options.filter((option) => option.value === selected)}
        placeholder={placeholder}
        className="basic-single"
        classNamePrefix="select"
        styles={customStyles}
        isDisabled={disabled}
        name="color"
        options={options}
        onChange={handleChange}
        isSearchable={!isMobile}
      />
    </div>
  );
};

export default DropdownSingleSelect;
