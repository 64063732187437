import { adminRoutes } from 'intakeOptimization/routes/constants';

export const ADMIN_STEPS = [
  {
    number: 1,
    title: 'Insurance',
    step: 'pending',
    link: adminRoutes.insuranceCard,
  },
  {
    number: 2,
    title: 'Billing',
    step: 'pending',
    link: '/',
    // TODO: Add a route for billing.
  },
  {
    number: 3,
    title: 'Consents',
    step: 'pending',
    link: adminRoutes.consents,
  },
];
