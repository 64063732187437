import clsx from 'clsx';
import { Clinician } from 'intakeOptimization/@types';
import { ReactComponent as CloseCircleIcon } from 'intakeOptimization/assets/svg/close-circle.svg';
import { ReactComponent as CloseIcon } from 'intakeOptimization/assets/svg/close.svg';
import {
  adminRoutes as absoluteAdminRoutes,
  bookingRoutes as absoluteBookingRoutes,
} from 'intakeOptimization/routes/absolutes';
import { adminRoutes, bookingRoutes } from 'intakeOptimization/routes/constants';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CircularLoading, Container, Input } from 'lifestance-ui';

import { SearchResults } from 'intakeOptimization/components';

import { updatePreferences } from 'intakeOptimization/store/preferences/actions';
import { updateReservation } from 'intakeOptimization/store/reservationGlobal/actions';
import {
  clearSearchTerm,
  getClinicianByName,
  setPreviousPath,
  setSearchTerm,
} from 'intakeOptimization/store/searches/actions';

import {
  isAbie,
  obieOrAbiePreferencesSelector,
  obieSearchData,
  selectedClinician,
} from 'intakeOptimization/selectors/selectors';

import { useDebounce } from 'intakeOptimization/hooks';

import { simpleKeyboardControl, useLastEventClickOrTab } from 'intakeOptimization/utils';

import styles from './TopSearch.module.scss';

export const TopSearch: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useSelector(obieSearchData);
  const isAdmin = useSelector(isAbie);
  const clinician = useSelector(selectedClinician);
  const preferences = useSelector(obieOrAbiePreferencesSelector);
  const { searchTerm, clinicians, previousPath, loading } = matches;
  const debouncedSearchValue = useDebounce(searchTerm);
  const baseRoute = isAdmin ? absoluteAdminRoutes.clinicianProfile : bookingRoutes.clinicianProfile;
  const { lastEventType } = useLastEventClickOrTab();

  const searchProviderByName = (value: any) => {
    dispatch(setSearchTerm(value));
  };

  const handleOnNavigate = (route: string, provider: Clinician) => {
    dispatch(
      updateReservation({
        clinician: provider,
        selectedFacility: provider.addresses[0],
      }),
    );
    const id = `${provider.first_name.toLowerCase()}-${provider.last_name.toLowerCase()}-${
      provider.id
    }`;
    return navigate(route.replace(':id', id), { state: { ...provider, isAdmin: true } });
  };

  useEffect(() => {
    if (debouncedSearchValue) {
      dispatch(
        getClinicianByName(
          debouncedSearchValue,
          preferences.zipCode,
          preferences.age,
          preferences.typeOfCare,
          preferences.paymentMethod,
          preferences.insuranceCompany,
        ),
      );
    }
  }, [debouncedSearchValue]);

  const onConfirmRefresh = (event: any) => {
    event.preventDefault();
    event.returnValue = 'Are you sure you want to leave the page?. This would clear your filters.';
    return event;
  };

  useEffect(() => {
    if (preferences.zipCode.length === 0) {
      // We redirect to PatientForm if we don't have a zip code to send to BE
      navigate(absoluteAdminRoutes.patientType);
    }
    window.addEventListener('beforeunload', onConfirmRefresh, { capture: true });
    return () => {
      window.removeEventListener('beforeunload', onConfirmRefresh);
    };
  }, []);

  const handleCloseSearch = () => {
    if (previousPath) {
      navigate(previousPath);
    }
  };

  const handleClearSearch = () => {
    dispatch(clearSearchTerm());
  };

  useEffect(() => {
    dispatch(setPreviousPath(absoluteAdminRoutes.searchResults));
  }, []);

  return (
    <div className={clsx(styles.search, styles.active)}>
      <div className={styles.mobileHeader}>
        <CloseIcon onClick={handleCloseSearch} />
        <span>Search provider name</span>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={clsx(styles.close, {
          [styles.adminClose]: isAdmin,
        })}
        onClick={handleCloseSearch}
        onKeyDown={(e) => simpleKeyboardControl(e, handleCloseSearch, true)}
      >
        <CloseIcon />
      </div>
      <div className={styles.searchBar}>
        <span className={styles.label}>Search Provider Name</span>
        <Input
          value={searchTerm}
          onChange={searchProviderByName}
          type="search"
          placeholder="e.g. Wendy Buffett"
          defaultValue={searchTerm}
          lastEventType={lastEventType}
          enableTabNavStyling
        />
        <CloseCircleIcon className={styles.clearSearch} onClick={handleClearSearch} />
        <CloseCircleIcon className={styles.mobileClose} onClick={handleCloseSearch} />
      </div>
      <div className={styles.searchResults}>
        <Container className={styles.briefSearch}>
          <>
            {searchTerm && loading ? <CircularLoading size="xlarge" /> : null}
            {searchTerm !== '' && !loading ? (
              <SearchResults
                matches={clinicians}
                onNavigate={handleOnNavigate}
                baseRoute={baseRoute}
                brief
              />
            ) : null}
          </>
        </Container>
      </div>
    </div>
  );
};
