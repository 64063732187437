import clsx from 'clsx';
import { FacilityLocation, GlobalIdName, GlobalOption } from 'patientOnboarding/@types';
import { ReactComponent as CheckmarkIcon } from 'patientOnboarding/assets/svg/checkmark.svg';
import { bookingRoutes as absoluteBookingRoutes } from 'patientOnboarding/routes/absolutes';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, ContextModal, Toast } from 'lifestance-ui';

import { updatePreferences } from 'patientOnboarding/store/preferences/actions';

import {
  contactInfo as contactInfoSelector,
  obiePreferencesData,
  selectedClinicianSelector,
  selectedFacilityData,
} from 'patientOnboarding/selectors/selectors';

import { useInsuranceValidation, useInvoca } from 'patientOnboarding/hooks';

import {
  defaultPhoneNumber,
  formatContactNumber,
  getContactInfoByState,
  removeDuplicates,
  stateAbbrToName,
} from 'patientOnboarding/utils';

import styles from './Payment.module.scss';

interface IPayment {
  setDisabled: (value: boolean) => void;
}

interface IRenderPrompt {
  license_key: number;
  firstName: string;
  phoneNumber?: string;
}

const RenderInsurance: FC<IRenderPrompt> = ({ license_key, firstName, phoneNumber }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleStartRequiredFilters = () => {
    const licenseKey = 'License_Key';
    const queryString = license_key;
    dispatch(updatePreferences({ licenseKey: queryString }));
    navigate(`${absoluteBookingRoutes.matchCriteria}?${licenseKey}=${queryString}`);
  };

  useInvoca();

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalTitle}>Don&apos;t see your insurance?</div>
      <div className={styles.modalContent}>
        Find a provider who accepts your insurance and meets your needs.
      </div>
      <div className={styles.button}>
        <Button fullWidth onClick={handleStartRequiredFilters}>
          Match with a provider
        </Button>
        <div className={styles.information}>
          If you have your heart set on
          {' '}
          {firstName}
          {' '}
          continue with self-pay or call us at
          {' '}
          <a
            className={clsx(styles.phone, 'invocaPhoneNumber')}
            href={`tel:${formatContactNumber(phoneNumber) || ''}`}
          >
            {phoneNumber || defaultPhoneNumber}
          </a>
        </div>
      </div>
    </div>
  );
};

export const Payment: FC<IPayment> = ({ setDisabled }) => {
  const dispatch = useDispatch();

  const profile = useSelector(selectedClinicianSelector);
  const preferences = useSelector(obiePreferencesData);
  const insuranceOptions = profile.insurances;
  const [noInsurance, setNoInsurance] = useState(false);
  const facility = useSelector(selectedFacilityData);
  const state = facility?.state ? facility.state : preferences.state;
  const licenseKey = facility?.license_key ? facility.license_key : preferences.licenseKey;
  const contactInfo = useSelector(contactInfoSelector);
  const contactInfoByState = getContactInfoByState(contactInfo, state, licenseKey);
  const contactNumber = contactInfoByState?.intake_call_in_number;

  const facilities = profile?.facility_location;
  const getPrimaryLocation: FacilityLocation = facilities?.find(
    (el: FacilityLocation) => el.primary_location,
  );

  const transformedInsuranceOptions = useMemo(
    () => removeDuplicates(insuranceOptions)
      .filter(
        (value, index, a) => a.findIndex((innerValue) => innerValue.name === value.name) === index,
      )
      .map((insurance: GlobalIdName) => ({
        label: insurance.name,
        value: insurance.name,
        isSelected: false,
      })),
    [insuranceOptions],
  );

  const { insuranceOpts, isInsuranceValidOnState } = useInsuranceValidation({
    updatedPreferences: preferences,
    insuranceCompanies: transformedInsuranceOptions,
    clinicianState: getPrimaryLocation?.state,
  });

  const [paymentMethodOptions, setPaymentMethodOptions] = useState<GlobalOption[]>(insuranceOpts);

  useEffect(() => {
    if (preferences.paymentMethod === 'insurance') {
      setPaymentMethodOptions(
        insuranceOpts.map((paymentMethod) => ({
          ...paymentMethod,
          isSelected: paymentMethod.value === preferences.insuranceCompany,
        })),
      );
    } else {
      setPaymentMethodOptions(
        insuranceOpts.map((paymentMethod) => ({
          ...paymentMethod,
          isSelected: paymentMethod.value === 'self_pay',
        })),
      );
    }
  }, [insuranceOpts]);

  const handlePayment = (value: string, isDisabled: boolean) => {
    if (isDisabled) return;

    let insuranceCompany = '';
    if (value !== 'self_pay') {
      insuranceCompany = value;
    }
    dispatch(
      updatePreferences({
        insuranceCompany,
        paymentMethod: insuranceCompany.length === 0 ? 'self_pay' : 'insurance',
      }),
    );

    setPaymentMethodOptions((options) => options.map((paymentMethod) => ({
      ...paymentMethod,
      isSelected: paymentMethod.value === value,
    })));
    setDisabled(false);
  };

  const handleNoInsurance = () => {
    setNoInsurance(true);
  };

  const handleColdLanding = () => {
    setNoInsurance(false);
  };

  return (
    <>
      <div className={styles.header}>
        <h1 className={styles.head}>How would you like to pay?</h1>
        <span className={styles.subhead}>This provider accepts the following:</span>
      </div>
      {paymentMethodOptions.map((insurance: any) => (
        <li
          className={clsx(styles.options, {
            [styles.disabledItem]: insurance.isDisabled,
          })}
          key={insurance.value}
          onClick={() => handlePayment(insurance.value, insurance.isDisabled)}
        >
          <div
            className={clsx({
              [styles.selection]: insurance.isSelected,
            })}
          >
            <div>{insurance.label}</div>
            {insurance.isSelected && <CheckmarkIcon />}
          </div>
          {!isInsuranceValidOnState && insurance.isSelected && (
            <Toast
              variant="information"
              label={`Do you have ${preferences.insuranceCompany} Medicaid?`}
              description={`We are not currently accepting Medicaid benefits in ${stateAbbrToName(
                getPrimaryLocation?.state,
              )}.`}
              containerClassName={clsx(styles.toast, styles.showToast)}
            />
          )}
        </li>
      ))}
      <div className={styles.noInsurance} onClick={handleNoInsurance}>
        {' '}
        I don't see my insurance
      </div>
      {noInsurance ? (
        <ContextModal
          isOpen={noInsurance}
          onClose={handleColdLanding}
          bottomScreen
          mainClassName={styles.mainContainer}
        >
          <RenderInsurance
            license_key={profile.licenseKey}
            firstName={profile.first_name}
            phoneNumber={contactNumber}
          />
        </ContextModal>
      ) : null}
    </>
  );
};
