import clsx from 'clsx';
import React, { FC } from 'react';

import styles from '../ProgressBar.module.scss';
import { Step } from '../types';

interface PendingStepProps {
  step: Step;
  testId: string;
  numberOfSteps: number;
}
export const PendingStep: FC<PendingStepProps> = ({
  step,
  testId = 'progressBarPending',
  numberOfSteps,
}) => {
  // eslint-disable-next-line no-nested-ternary
  const stepNumber = numberOfSteps === 3 ? step.number : step.number === 3 ? 2 : step.number;
  return (
    <div
      data-testid={testId}
      className={clsx(styles.step, styles.step_pending, {
        [styles.oneThird]: numberOfSteps === 3,
        [styles.oneHalf]: numberOfSteps === 2,
      })}
    >
      <div className={styles.step_indicator} />
      <div data-testid={`${testId}Number`} className={styles.step_number}>
        <div className={styles.empty_circle}>{stepNumber}</div>
      </div>
      <div className={styles.step_title}>{step.title}</div>
    </div>
  );
};
