/* eslint-disable react/no-danger */
import { Education as IEducation } from 'intakeOptimization/@types';
import { ReactComponent as FileListLine } from 'intakeOptimization/assets/svg/file-list-line.svg';
import React from 'react';

import styles from './Education.module.scss';

interface IEducationListProps {
  data?: IEducation[] | string | undefined;
}

const Education: React.FC<React.PropsWithChildren<IEducationListProps>> = ({ data }) => (
  <div className={styles.educationContainer}>
    <FileListLine />
    <div className={styles.heading} data-testId="education">
      Education
    </div>
    {data && typeof data === 'string' ? (
      <div className={styles.education} dangerouslySetInnerHTML={{ __html: data }} />
    ) : (
      <div className={styles.educationContainer}>
        {data
          && (data as IEducation[])?.map((item: IEducation) => (
            <div className={styles.line}>
              <div>
                {item.university}
                {` | ${item.reference_type}`}
              </div>
            </div>
          ))}
      </div>
    )}
  </div>
);

export default Education;
