import clsx from 'clsx';
import { ReactComponent as CaretDownIcon } from 'patientOnboarding/assets/svg/caret-down.svg';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { genderFilters, useDevice } from 'patientOnboarding/utils';

import styles from './MobileFilter.module.scss';

export type FilterOption = {
  label?: string | React.ReactNode;
  value?: string;
  testId?: string;
  options: FilterOption[];
};

interface MobileFilterProps {
  label: string;
  className: string;
  onChange: (name: string, values: string[]) => void;
  options: FilterOption[];
  name: string;
  defaultValues: string[] | string;
  ready: boolean;
}

export type MobileFilterHandle = {
  clearAll: () => void;
};

export const MobileFilter = forwardRef<MobileFilterHandle, MobileFilterProps>(
  ({ label, className, onChange, options, name, defaultValues = [], ready }, ref) => {
    const { isSmallTablet, isTablet } = useDevice();
    const [open, setOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<string[]>(
      typeof defaultValues === 'string' && defaultValues?.includes(',')
        ? defaultValues?.split(',')
        : (defaultValues as string[]),
    );
    const [showAllOptions, setShowAllOptions] = useState<boolean>(false);
    const [showAllSuboptions, setShowAllSuboptions] = useState([false, false, false]);

    const clearAll = (): void => {
      setSelected([]);
    };

    useImperativeHandle(
      ref,
      () => ({
        clearAll,
      }),
      [setSelected],
    );

    useEffect(() => {
      if (ready) onChange(name, selected);
    }, [selected]);

    const handleClick = () => {
      const formattedValues = typeof defaultValues === 'string' && defaultValues !== '' ? [defaultValues] : defaultValues;
      if (formattedValues) setSelected(formattedValues);
      setOpen(!open);
    };

    const handleSelect = (value: string) => {
      if (selected?.includes(value)) {
        let updatedValues = [];
        if (name === 'gender') {
          updatedValues = selected.filter(
            (option) => option !== value && genderFilters[option] && !genderFilters[option].includes(value),
          );
        } else {
          updatedValues = Array.isArray(selected)
            ? selected?.filter((option) => option !== value)
            : [];
        }
        setSelected(updatedValues);
      } else {
        setSelected((previousSelected) => [...previousSelected, value]);
      }
    };

    const handleExpand = () => {
      setShowAllOptions(true);
    };

    const handleExpandSubOptions = (index: number) => {
      const newValues = showAllSuboptions.map((elem, ind) => (ind === index ? !elem : elem));
      setShowAllSuboptions(newValues);
    };

    return (
      <div className={clsx(className, styles.container)}>
        <div
          className={clsx(styles.select, {
            [styles.selectOpen]: open,
            [styles.selectedOptions]: selected?.length > 0,
          })}
          onClick={handleClick}
        >
          {label}
          <CaretDownIcon className={styles.dropDownIcon} />
        </div>
        {(open || isSmallTablet || isTablet) && (
          <div
            className={clsx(styles.optionsContainer, {
              [styles.expanded]: showAllOptions,
            })}
          >
            {options
              && options.map((option, ind) => {
                if (option.options) {
                  return (
                    <div
                      key={`divMobFilter${option.label}`}
                      className={clsx(styles.options, {
                        [styles.expandedSub]: showAllSuboptions[ind],
                      })}
                    >
                      <h3>{option.label}</h3>
                      {option.options.map((nestedOption, index, { length }) => (
                        <>
                          <div className={styles.option}>
                            <input
                              type="checkbox"
                              id={nestedOption.value}
                              value={nestedOption.value}
                              onChange={() => handleSelect(nestedOption.value as string)}
                              checked={selected?.includes(nestedOption.value as string)}
                            />
                            <label htmlFor={nestedOption.value}>
                              <div className={styles.checkbox} />
                              <span>{nestedOption.label}</span>
                            </label>
                          </div>
                          {!showAllSuboptions[ind] && length > 6 && index + 1 === length && (
                            <span
                              onClick={() => handleExpandSubOptions(ind)}
                              className={styles.seeMore}
                            >
                              View more
                            </span>
                          )}
                        </>
                      ))}
                    </div>
                  );
                }
                return (
                  <div key={`divMobFilter${option.label}`} className={styles.option}>
                    <input
                      type="checkbox"
                      id={option.value}
                      value={option.value}
                      onChange={() => handleSelect(option.value as string)}
                      checked={selected?.includes(option.value as string)}
                    />
                    <label htmlFor={option.value}>
                      <div className={styles.checkbox} />
                      <span>{option.label}</span>
                    </label>
                  </div>
                );
              })}
            {!showAllOptions && options?.length > 6 && (
              <span onClick={handleExpand} className={styles.seeMore}>
                View more
              </span>
            )}
          </div>
        )}
      </div>
    );
  },
);
