/*
 * For computed selectors or calculations inside a selector avoid declaring selectors as the
 * following and use instead createSelector from redux utilities.
 * https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization
 */
import { useLocation } from 'react-router-dom';

// TODO: redefine this once apps split
import { RootState } from 'modules/application/store';

import { getContactInfoByState } from 'patientOnboarding/utils';

export type { InitialState as ABIEState } from 'patientOnboarding/store/filters/reducers';
export type { InitialState as OBIEState } from 'patientOnboarding/store/preferences/reducers';

// Global
export const reservationData = (state: RootState) => state.obie.reservationGlobal;
export const reservationLicenseKey = (state: RootState) => state.obie.reservationGlobal.license_key;
export const selectedFacilityData = (state: RootState) => state.obie.reservationGlobal.selectedFacility;
export const isLoadingReservationSelector = (state: RootState) => state.obie.reservationGlobal.loading;
export const isAbie = (state: RootState) => state.obie.config.isAdmin;
export const availableDates = (state: RootState) => state.obie.matches?.clinicianAvailableDates;
export const availabilities = (state: RootState) => state.obie.matches?.clinicianAvailabilities;
export const showAppt = (state: RootState) => state.obie.preferences.showAppointment;
export const showApptLoading = (state: RootState) => state.obie.preferences.showAppointmentLoading;
export const availabilityStatus = (state: RootState) => state.obie.reservationGlobal.availability;
export const contactInfo = (state: RootState) => state.obie.userGlobal.contactInfo;
export const contactNumber = (state: RootState) => {
  if (state.obie.preferences?.marketingReferralPhone.length > 0) {
    return state.obie.preferences?.marketingReferralPhone;
  }
  const location = useLocation();
  const location_back = state.obie.config.isAdmin
    ? state.obie.filters.location_back
    : state.obie.preferences.location_back;
  const stateIn = state.obie.preferences.state;
  const licenseKey = state.obie.reservationGlobal.selectedFacility?.license_key
    ? state.obie.reservationGlobal.selectedFacility?.license_key
    : state.obie.preferences.licenseKey;
  const clinician = state.obie.matches.selectedClinician;
  const isColdLanding = (location_back?.length === 0 && location.pathname.includes('provider'))
    || location_back?.includes('/find-care/booking/search');
  if (isColdLanding) {
    const clinicianFacility = clinician?.facility_location[0];
    const contactInfoByState = getContactInfoByState(
      state.obie.userGlobal.contactInfo,
      clinicianFacility?.state,
      clinician?.license_key,
    );
    return contactInfoByState?.intake_call_in_number;
  }
  const contactInfoByState = getContactInfoByState(
    state.obie.userGlobal.contactInfo,
    stateIn,
    licenseKey,
  );
  return contactInfoByState?.intake_call_in_number;
};
export const selectedClinician = (state: RootState) => state.obie.matches.selectedClinician;
export const stickyMenuSelector = (state: RootState) => state.obie.matches.stickyMenu;
export const preliminaryInsuranceSelector = (state: RootState) => state.obie.userGlobal.insurancePremliminaryData;
export const selectedClinicianSelector = (state: RootState) => state.obie.matches.selectedClinician;
export const hasClinicianSelector = (state: RootState) => state.obie.matches.hasClinician;
export const otherProviders = (state: RootState) => state.obie.matches?.otherProviders;
export const isClinicianProfileLoading = (state: RootState) => state.obie.matches.loadingProfile;
export const loadingAvailabilities = (state: RootState) => state.obie.matches.loadingAvailabilities;
export const preliminaryAccountHolderSelector = (state: RootState) => state.obie.userGlobal.preliminaryAccountHolder;
export const loadingFiltersSelector = (state: RootState) => state.obie.matches.loadingFiltering;
export const obieAccountHolderExists = (state: RootState) => state.obie.userGlobal.exists_in_amd;
export const obiePortalLink = (state: RootState) => state.obie.userGlobal.patient_portal_link;
export const obieAccountHolderInfo = (state: RootState) => state.obie.userGlobal.accountHolderInfo;
export const obieAppointmentData = (state: RootState) => state.obie.reservationGlobal.appointment;
export const obiePatientAppointmentData = (state: RootState) => state.obie.reservationGlobal?.appointment?.patient_appointment;

// ABIE
export const abiePreferencesData = (state: RootState) => state.obie.filters;
export const abiePatientId = (state: RootState) => state.obie.config?.bookingForSelf?.account_holder_patient_id;
export const abieChildPatientId = (state: RootState) => state.obie.config.bookingForSelf.child?.id
  ? state.obie.config.bookingForSelf.child.id
  : state.obie.config.bookingForSelf.account_holder_patient_id;
export const abiePatientAppointmentId = (state: RootState) => state.obie.config.appointmentId;
export const abiePatientData = (state: RootState) => state.obie.config.bookingForSelf;
export const abieAdminConfig = (state: RootState) => state.obie.config;
export const abieInsuranceDetails = (state: RootState) => state.obie.user.insuranceDetails;
export const abieUserData = (state: RootState) => state.obie.user;
export const abieIsLoading = (state: RootState) => state.obie.user.isLoading;
export const hasAddress = (state: RootState) => state.obie.user.hasAddress;
export const abieReservation = (state: RootState) => state.obie.reservation.patientAppointment;
export const abieReservationLicenseKey = (state: RootState) => state.obie.reservation.patientAppointment.clinician_address.license_key;
export const abieReservationError = (state: RootState) => state.obie.reservation.error;
export const abieFilters = (state: RootState) => state.obie.filters;
export const abieInsuranceCards = (state: RootState) => state.obie.user.insuranceCards;
export const abiePatientType = (state: RootState) => state.obie.filters.patientType;
export const abieInsuranceSkipFlag = (state: RootState) => state.obie.user.insuranceDetails.insuranceSkipFlag;
export const abieCreateAccountError = (state: RootState) => state.obie.config.error;
export const abieAccountHolderExists = (state: RootState) => state.obie.config.existsInAMD;
export const abiePatientPortal = (state: RootState) => state.obie.user.patientPortal;
export const abiePatientHasInsuranceInfo = (state: RootState) => state.obie.user.hasInsuranceInfo;
export const abieResponsibleParties = (state: RootState) => state.obie.config.responsibleParty;
export const abieTalkingPoints = (state: RootState) => state.obie.config.isTalkingPoint;
export const abieFocusOptions = (state: RootState) => state.obie.filters.focusOptions;
export const abieSpecialCases = (state: RootState) => state.obie.filters.allSpecialCases;
export const abieBookingModalOpen = (state: RootState) => state.obie.config.isBookingModalOpen;
export const abieClinicians = (state: RootState) => state.obie.clinicians;
export const isLoadingAllClinicians = (state: RootState) => state.obie.clinicians.loadingClinicians;
export const isLoadingCount = (state: RootState) => state.obie.clinicians.loadingCount;
export const isLoadingCliniciansSelector = (state: RootState) => state.obie.clinicians.loadingClinicians;

// OBIE
export const obiePreferencesData = (state: RootState) => state.obie.preferences;
export const obiePreferencesIsLoading = (state: RootState) => state.obie.preferences.loading;
export const obieUserData = (state: RootState) => state.obie.userGlobal;
export const obieInsuranceDeatilsData = (state: RootState) => state.obie.userGlobal.insuranceDetails.data;
export const obieCreateAccountError = (state: RootState) => state.obie.userGlobal.error;
export const obieSearchData = (state: RootState) => state.obie.searches;
export const obieClinicianAvailableDates = (state: RootState) => state.obie.matches?.clinicianAvailableDates;
export const isExistingAccountHolder = (state: RootState) => state.obie.preferences?.isExistingUser;
export const obieZipCode = (state: RootState) => state.obie.preferences?.zipCode;
export const obieZipCodes = (state: RootState) => state.obie.preferences?.zip_codes;
export const obieLicenseKey = (state: RootState) => state.obie.preferences?.licenseKey;
export const SSOSessionData = (state: RootState) => state.obie.session.data;
export const sessionDataPatientInfoSelector = (state: RootState) => state.obie.session.data.patient_info;
export const obieMatches = (state: RootState) => state.obie.matches;
export const existingPatient = (state: RootState) => state.obie.patient;
export const obieInsuranceData = (state: RootState) => state.obie.insurance;
export const obieInsuranceFilters = (state: RootState) => state.obie.insurance.filters;
export const obieInsuranceCards = (state: RootState) => state.obie.insurance.cards;
export const obieGenderIdentities = (state: RootState) => state.obie.insurance.filters?.gender_identity;
export const obieInsuranceSkipFlag = (state: RootState) => state.obie.insurance.insuranceSkipFlag;
export const obieShowCCOF = (state: RootState) => state.obie.insurance.showCCOF;
export const obieHideSkipButtonCCOF = (state: RootState) => state.obie.insurance.hideSkipCCOF;
export const obieHeartlandApiKey = (state: RootState) => state.obie.insurance.heartlandApiKey;
export const obiePatient = (state: RootState) => state.obie.userGlobal.patient;
export const obieLocations = (state: RootState) => state.obie.insurance?.filters?.locations;
export const obieFocusOptions = (state: RootState) => state.obie.insurance?.filters?.focusOptions;
export const obieBookingModal = (state: RootState) => state.obie.preferences.isBookingModalObie;
export const obieTypesOfCareselector = (state: RootState) => state.obie.preferences.typeOfCare;
export const obiePreferencesInsuranceCompany = (state: RootState) => state?.obie?.preferences?.insuranceCompany;
export const obiePreviousPreferences = (state: RootState) => state.obie.preferences.previousPreferences;
export const obieAccountHolder = (state: RootState) => state.obie.preferences?.accountHolder;
export const obieUserIsLoading = (state: RootState) => state.obie.userGlobal?.isLoading;
export const obieSkippedCreditCardInfo = (state: RootState) => state.obie.preferences.skippedCreditCardInfo;
export const obieClinicians = (state: RootState) => state.obie.clinicians;
export const obieEnabled = (state: RootState) => state.obie.config.isEnabled;
export const lifestanceState = (state: RootState) => state.obie.config.isStateEnabled;

export const obieOrAbiePreferencesSelector = (state: RootState) => {
  if (state.obie.config.isAdmin) {
    return state.obie.filters;
  }
  return state.obie.preferences;
};
