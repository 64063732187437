const types = {
  GET_ALL_CLINICIANS: '@@clinicians/GET_ALL_CLINICIANS',
  GET_CLINICIANS_COUNT: '@@clinicians/GET_CLINICIANS_COUNT',
  LOAD_CLINICIANS: '@@clinicians/LOAD_CLINICIANS',
  SET_LOADING_CLINICIANS: '@@matches/SET_LOADING_CLINICIANS',
  SET_ERROR: '@@matches/SET_ERROR',
  SET_SEARCH_TERM: '@@matches/SET_SEARCH_TERM',
  RESET_CLINICIANS: '@@matches/RESET_CLINICIANS',
  GET_OTHER_CLINICIANS: '@@matches/GET_OTHER_CLINICIANS',
  GET_FILTERED_CLINICIANS_OBIE: '@@matches/GET_FILTERED_CLINICIANS_OBIE',
  GET_MORE_FILTERED_CLINICIANS_OBIE: '@@matches/GET_MORE_FILTERED_CLINICIANS_OBIE',
  GET_DISPLAYED_CLINICIANS_OBIE: '@@matches/GET_DISPLAYED_CLINICIANS_OBIE',
};

export default types;
