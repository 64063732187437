const types = {
  SET_INTAKE_ORIGIN: '@@IO_USER_GLOBAL/SET_INTAKE_ORIGIN',
  UPDATE_USER: '@@IO_USER_GLOBAL/UPDATE_USER',
  SAVE_ADDRESS: '@@IO_USER_GLOBAL/SAVE_ADDRESS',
  UPDATE_PATIENT: '@@IO_USER_GLOBAL/UPDATE_PATIENT',
  CREATE_ACCOUNT_HOLDER: '@@IO_USER_GLOBAL/CREATE_ACCOUNT_HOLDER',
  CREATE_PATIENT: '@@IO_USER_GLOBAL/CREATE_PATIENT',
  SET_PATIENT: '@@IO_USER_GLOBAL/SET_PATIENT',
  SET_INSURANCE_DETAILS: '@@IO_USER_GLOBAL/SET_INSURANCE_DETAILS',
  SET_EMAIL_STATUS: '@@IO_USER_GLOBAL/SET_EMAIL_STATUS',
  GET_EMAIL_STATUS: '@@IO_USER_GLOBAL/GET_EMAIL_STATUS',
  RESEND_EMAIL: '@@IO_USER_GLOBAL/RESEND_EMAIL',
  SET_ACCOUNT_HOLDER: '@@IO_USER_GLOBAL/SET_ACCOUNT_HOLDER',
  GET_FOCUS: '@@IO_USER_GLOBAL/GET_FOCUS',
  UPDATE_INSURANCE_DETAILS: '@@IO_USER_GLOBAL/UPDATE_INSURANCE_DETAILS',
  IS_LOADING: '@@IO_USER_GLOBAL/IS_LOADING',
  EXISTS_IN_AMD: '@@IO_USER_GLOBAL/EXISTS_IN_AMD',
  PATIENT_IS_POLICYHOLDER: '@@IO_USER_GLOBAL/PATIENT_IS_POLICYHOLDER',
  PATIENT_PORTAL_LINK: '@@IO_USER_GLOBAL/PATIENT_PORTAL_LINK',
  RESPONSIBLE_PARTY: '@@IO_USER_GLOBAL/RESPONSIBLE_PARTY',
  GET_SELECTED_SLOT_INFO: '@@IO_USER_GLOBAL/GET_SELECTED_SLOT_INFO',
  SAVE_ACCOUNT_HOLDER_SLOT: '@@IO_USER_GLOBAL/SAVE_ACCOUNT_HOLDER_SLOT',
  SET_ACCOUNT_HOLDER_SLOT: '@@IO_USER_GLOBAL/SET_ACCOUNT_HOLDER_SLOT',
  HANDLE_ERROR: '@@IO_USER_GLOBAL/HANDLE_ERROR',
  SET_CONTACT_INFO: '@@IO_USER_GLOBAL/SET_CONTACT_INFO',
  GET_CONTACT_INFO: '@@IO_USER_GLOBAL/GET_CONTACT_INFO',
  SET_EMERGENCY_CONTACT_INFO: '@@IO_USER_GLOBAL/SET_EMEREGENCY_CONTACT_INFO',
  GET_EMERGENCY_CONTACT_INFO: '@@IO_USER_GLOBAL/GET_EMERGENCY_CONTACT_INFO',
  SAVE_EMERGENCY_CONTACT_INFO: '@@IO_USER_GLOBAL/SAVE_EMERGENCY_CONTACT_INFO',
  SET_SUICIDAL_IDEATION: '@@IO_USER_GLOBAL/SET_SUICIDAL_IDEATION',
  UPDATE_INSURANCE_PRELIMINARY_DATA: '@@IO_USER_GLOBAL/UPDATE_INSURANCE_PRELIMINARY_DATA',
  UPDATE_ACCOUNT_HOLDER: '@@IO_USER_GLOBAL/UPDATE_ACCOUNT_HOLDER',
};

export default types;
