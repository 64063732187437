import { RootState } from 'modules/application/store';

export const activeFiltersSelectorObie = (state: RootState) => state.obie.cliniciansGlobal.activeFilters.reduce(
  (acc: Record<string, string[] | number[] | string | number>, filter) => {
    acc[filter.name] = filter.value;
    return acc;
  },
  {},
);

export const activeFiltersArraySelector = (state: RootState) => state.obie.cliniciansGlobal.activeFilters;

export const cliniciansSelector = (state: RootState) => state.obie.cliniciansGlobal.clinicians;
export const filteredCliniciansSelector = (state: RootState) => state.obie.cliniciansGlobal.filteredClinicians;

export const displayedCliniciansSelector = (state: RootState) => state.obie.cliniciansGlobal.displayedClinicians;

export const loadingDisplayedCliniciansSelector = (state: RootState) => state.obie.cliniciansGlobal.loadingDisplayedClinicians;
export const loadingCliniciansSelector = (state: RootState) => state.obie.cliniciansGlobal.loadingClinicians;

export const pageSelector = (state: RootState) => state.obie.cliniciansGlobal.page;
export const totalPagesSelector = (state: RootState) => state.obie.cliniciansGlobal.totalPages;
export const countSelector = (state: RootState) => state.obie.cliniciansGlobal.clinicianCount;
