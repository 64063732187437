import { GlobalLabelValue } from 'intakeOptimization/@types';

import { SELF_PAY_LABEL, SELF_PAY_VALUE } from 'intakeOptimization/utils';

import { stateAbbrToName, states } from './stateConversion';

type NotValid = {
  states: string[];
  insurances: string[];
};

export const notValidInsurancesGlobal: NotValid = {
  states: [states.TX, states.WA, states.MD, states.VA],
  insurances: ['Medicaid'],
};

// Reference to WEB-2870 for values stated in the table
const notValidInsurancesByState = [
  {
    state: states.TX,
    insurances: ['Magellan', 'Molina Healthcare', 'Optum (UnitedHealthcare)'],
  },
  {
    state: states.WA,
    insurances: ['Molina Healthcare'],
  },
  {
    state: states.MD,
    insurances: ['Optum (UnitedHealthcare)'],
  },
  {
    state: states.VA,
    insurances: ['Elevance Health (Anthem BCBS)'],
  },
];

const validateNotValidGlobalByLicenseKeyOrState = (insurance: string, state: string) => Boolean(notValidInsurancesGlobal.states.includes(state))
  && Boolean(notValidInsurancesGlobal.insurances.includes(insurance));

export const validateInsurance = (insurance: string, stateAbbreviation: string) => {
  const state = stateAbbrToName(stateAbbreviation);
  const isNotValid = validateNotValidGlobalByLicenseKeyOrState(insurance, state);
  const notValidInsurancesOnState = notValidInsurancesByState.find(
    (option) => option.state === state,
  );
  const isNotValidOnState = notValidInsurancesOnState?.insurances.includes(insurance);

  return { isNotValid, isNotValidOnState };
};

const validateAndAdd = (notValid: string[], insurances: GlobalLabelValue[], state: string) => {
  const map: Record<string, boolean> = {};
  const allInsurances = insurances.map((option) => option);
  for (let i = 0; i < allInsurances.length; i += 1) {
    map[allInsurances[i].value] = true;
  }

  for (let i = 0; i < notValid.length; i += 1) {
    const notValidInsurance = validateNotValidGlobalByLicenseKeyOrState(notValid[i], state);
    if (!map[notValid[i]] && notValidInsurance) {
      allInsurances.push({ label: notValid[i], value: notValid[i] });
    }
  }

  return allInsurances;
};

export const getSortedNotValidInsuranceOptions = (
  insuranceCompanies: GlobalLabelValue[],
  stateAbbr: string,
) => {
  const state = stateAbbrToName(stateAbbr);
  const addedInvalidOptions = validateAndAdd(
    notValidInsurancesGlobal.insurances,
    insuranceCompanies,
    state,
  );
  const firstOptions = addedInvalidOptions.filter((option) => notValidInsurancesGlobal.insurances.includes(option.value));
  const remainingOptions = addedInvalidOptions.filter(
    (option) => !notValidInsurancesGlobal.insurances.includes(option.value),
  );
  const insurances = firstOptions.length > 0 ? [...firstOptions, ...remainingOptions] : remainingOptions;
  return insurances;
};

export const formatNotValidInsuranceOptions = (
  insuranceCompanies: GlobalLabelValue[],
  state: string,
) => {
  const insurances = insuranceCompanies.map((option) => {
    const { isNotValid } = validateInsurance(option.value, state);
    return {
      ...option,
      isDisabled: isNotValid,
      label: isNotValid ? `${option.label} (not currently accepting)` : option.label,
    };
  });
  return [{ label: SELF_PAY_LABEL, value: SELF_PAY_VALUE, isDisabled: false }, ...insurances];
};
