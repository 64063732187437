import clsx from 'clsx';
import React, {
  ChangeEvent,
  FC,
  FocusEventHandler,
  InputHTMLAttributes,
  MouseEventHandler,
  TouchEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useScrollToMiddleOnFocus } from 'intakeOptimization/hooks';

import styles from './DateInput.module.scss';
import { DELETE_CONTENT_BACKWARD } from './constants';
import { mobile } from './utils';

type InputMaskProps = {
  error?: string;
  testId?: string;
  caution?: string;
  adminInputMask?: boolean;
  mask?: string;
  showMaskOnFocus?: boolean;
  className?: string;
  lastEventType?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const DateInput: FC<InputMaskProps> = ({
  mask = 'mm/dd/yyyy',
  tabIndex,
  id,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  error = '',
  caution = '',
  testId = '',
  adminInputMask,
  inputMode,
  showMaskOnFocus = true,
  value: inputValue = '',
  className = '',
  disabled = false,
  readOnly = false,
  onFocus,
  lastEventType,
}) => {
  const [value, setValue] = useState<Record<number, unknown>>('');
  const [tabbed, setTabbed] = useState<boolean>(false);
  const [toggleCursor, setCursor] = useState(false);
  const [positionCursor, setPosCursor] = useState({
    start: 0,
    end: 1,
  });
  const [letterObject, setLetterObject] = useState<Record<number, unknown>>({});
  const [moveCursor, setMoveCursor] = useState<Record<string, number | string>>({
    start: '',
    end: '',
  });
  const [maskOnFocus, setMaskOnFocus] = useState(false);
  const myRef = useScrollToMiddleOnFocus<HTMLInputElement>();

  useEffect(() => {
    myRef.current?.setSelectionRange(positionCursor.start, positionCursor.end);
  }, [positionCursor.start, positionCursor.end, toggleCursor]);

  useEffect(() => {
    myRef.current?.setSelectionRange(moveCursor.start as number, moveCursor.end as number);
  }, [moveCursor.start, moveCursor.end]);

  const createObject = (string: string) => {
    const newObject: Record<number, unknown> = {};
    [...string].forEach((el, index) => {
      newObject[index + 1] = el;
    });
    return newObject;
  };

  useEffect(() => {
    const newValue = inputValue || mask;
    const valueObject = createObject(newValue as string);
    setValue(valueObject);
    if (!showMaskOnFocus || inputValue) setMaskOnFocus(true);
  }, [inputValue, showMaskOnFocus]);

  useEffect(() => {
    const newLetterObject = createObject(mask);
    setLetterObject(newLetterObject);
    myRef.current?.setSelectionRange(0, 1);
  }, [mask]);

  const onInputFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    const { allDigits } = isCurrValueHaveDigits(value);
    if (!allDigits) {
      onPageLoad(event);
    }
    setTabbed(lastEventType === 'tab');
    if (onFocus) onFocus(event);
    if (showMaskOnFocus && !maskOnFocus) {
      setMaskOnFocus(true);
    }
  };

  const findDigitsOrLettersInValue = ({
    value: lettersInValue,
    looking,
  }: {
    value: Record<number, unknown>;
    looking: string;
  }) => {
    const separator = lettersInValue['3'];
    const regex: Record<string, RegExp> = {
      digits: /[0-9]/g,
      letters: /[mMyYdD]/,
    };
    const resultArray = Object.values(lettersInValue)
      .filter((el) => el !== separator)
      .map((el) => (el as string).search(regex[looking]))
      .filter((el) => el === 0);
    return resultArray.length;
  };

  const isCurrValueHaveDigits = (currValue: Record<string, unknown>) => {
    const quantityDigits = findDigitsOrLettersInValue({ value: currValue, looking: 'digits' });
    const resultIndexLetters = Object.values(currValue).findIndex((el) => {
      const regex = /[mMyYdD]/;
      const result = (el as string).search(regex);
      return result === 0;
    });
    const quantityLetters = findDigitsOrLettersInValue({ value: currValue, looking: 'letters' });
    return {
      allDigits: Boolean(quantityDigits === 8),
      indexLetter: resultIndexLetters,
      allLetters: Boolean(quantityLetters === 8),
    };
  };

  const trackingCursorPos = (e: MouseEvent) => {
    const { allDigits, indexLetter } = isCurrValueHaveDigits(value);
    const { selectionStart } = e.target as unknown as { selectionStart: number };
    if (allDigits) {
      if (positionCursor.start !== selectionStart) {
        setPosCursor({
          ...positionCursor,
          start: selectionStart,
          end: selectionStart + 1,
        });
      }
    } else if (indexLetter || indexLetter === 0) {
      setPosCursor({
        ...positionCursor,
        start: indexLetter,
        end: indexLetter + 1,
      });
      setCursor(!toggleCursor);
    } else {
      setCursor(!toggleCursor);
    }
  };

  const onClick = (e: MouseEvent) => {
    setTabbed(false);
    trackingCursorPos(e);
  };

  const onTouchStart = (e: MouseEvent) => {
    trackingCursorPos(e);
  };

  const onPageLoad = (e: any) => {
    trackingCursorPos(e);
  };

  const checkOneValue = (val: string, valueString: string, position: number) => {
    const regex: Record<string, RegExp> = {
      D: /([0-3]D)|(0[1-9]|[12][0-9]|3[01])|(D[0-9])/,
      M: /([0-1]M)|(0[1-9]|1[012])|(M[0-2])/,
      Y: /([1-2]YYY)|((19|20)YY)|((19|20)\dY)|((19|20)\d\d)|(Y{2,3}\d{1,2})|(YY\dY)|([1-2]Y\d{1,2})/,
      '/': /\//,
      '.': /\./,
    };
    const letter: string = letterObject[position] as string;
    let newVal;
    if (letter === 'D') {
      newVal = mask === 'DD.MM.YYYY' || mask === 'DD/MM/YYYY'
        ? valueString.slice(0, 2)
        : valueString.slice(3, 5);
    } else if (letter === 'M') {
      newVal = mask === 'DD.MM.YYYY' || mask === 'DD/MM/YYYY'
        ? valueString.slice(3, 5)
        : valueString.slice(0, 2);
    } else if (letter === 'Y') {
      newVal = valueString.slice(6, 10);
    } else if (position === 3) {
      newVal = valueString.slice(2, 3);
    } else {
      newVal = valueString.slice(5, 6);
    }
    return regex[letter].test(newVal);
  };

  const deletingElement = ({
    e,
    pos,
    currentValue,
  }: {
    e: React.KeyboardEvent<HTMLInputElement>;
    pos: number;
    currentValue: Record<number, unknown>;
  }) => {
    const newValue = letterObject[pos];
    const newState = {
      ...currentValue,
      [pos]: newValue,
    };
    setValue(newState);
    const newStart = pos - 1;
    const newEnd = newStart + 1;
    setPosCursor((prevState) => ({
      ...prevState,
      start: newStart,
      end: newEnd,
    }));
    const newDate = Object.values(newState).join('');

    const event = { ...e };
    // @ts-expect-error forcing target value
    event.target.value = newDate;
    event.currentTarget.value = newDate;
    // @ts-expect-error forcing event
    onChange?.(event);
  };

  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { selectionStart: start, selectionEnd: end, value: curValue },
    } = e;
    const selectionStart = start as number;
    const selectionEnd = end as number;

    if (
      mobile
      && (e.nativeEvent as unknown as { inputType: string }).inputType === DELETE_CONTENT_BACKWARD
    ) {
      (selectionStart as number) += 1;
      // @ts-expect-error forcing event type
      deletingElement({ e, pos: selectionStart as number, currentValue: value });
    } else {
      const valueArray = [...curValue];
      const newPositionStart = selectionStart - 1;
      const newValue = valueArray[newPositionStart];
      const reg = /[\d]/g;
      const isValidValue = reg.test(newValue);
      let newState: Record<string, unknown>;

      if (isValidValue && selectionStart < 11) {
        const valueString = Object.values({ ...value, [selectionStart]: newValue }).join('');
        const isMatch = checkOneValue(newValue, valueString, selectionStart);
        if (isMatch) {
          newState = { ...value, [selectionStart]: newValue };
          setValue(newState);
          const newSelectionStart = selectionStart === 2 || selectionStart === 5 ? selectionStart + 1 : selectionStart;
          const newSelectionEnd = selectionStart === 2 || selectionStart === 5 ? selectionEnd + 2 : selectionEnd + 1;
          setPosCursor({
            ...positionCursor,
            start: newSelectionStart,
            end: newSelectionEnd,
          });
        } else if (selectionStart === 1 || selectionStart === 4) {
          const nextValue = selectionStart + 1;
          const newPosStart = selectionStart + 2;
          newState = {
            ...value,
            [selectionStart]: '0',
            [nextValue]: newValue,
          };
          setValue(newState);
          setPosCursor({
            ...positionCursor,
            start: newPosStart,
            end: newPosStart + 1,
          });
        } else if (selectionStart === 7) {
          const nextValue = selectionStart + 2;
          const nextSelStart = selectionStart + 1;
          newState = {
            ...value,
            [selectionStart]: '2',
            [nextSelStart]: '0',
            [nextValue]: newValue,
          };
          setValue(newState);
          setPosCursor({
            ...positionCursor,
            start: nextValue,
            end: nextValue + 1,
          });
        } else if (selectionStart === 3 || selectionStart === 6) {
          const nextSelStart = selectionStart;
          setPosCursor({
            ...positionCursor,
            start: nextSelStart,
            end: nextSelStart + 1,
          });
        } else {
          newState = { ...value };
          setCursor(!toggleCursor);
        }
      } else {
        newState = { ...value };
        setCursor(!toggleCursor);
      }

      // @ts-expect-error newState is assigned
      const newDate = Object.values(newState).join('');

      const event = { ...e };
      event.target.value = newDate;
      event.currentTarget.value = newDate;

      onChange?.(event);
    }
  };

  const moveCursorTo = (start: number, end: number) => {
    setMoveCursor({
      ...moveCursor,
      start,
      end,
    });
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { allDigits } = isCurrValueHaveDigits(value);
    const { key, target } = e;
    const { selectionStart } = target as unknown as { selectionStart: number };
    if (key === 'Backspace' && allDigits && selectionStart === 0) {
      moveCursorTo(10, 11);
    }
    switch (key) {
      case 'Backspace':
      case 'Delete':
        if (selectionStart !== 0) {
          e.preventDefault();
          deletingElement({ e, pos: selectionStart, currentValue: value });
        } else {
          e.preventDefault();
        }
        break;
      case 'ArrowRight':
        moveCursorTo(selectionStart + 1, selectionStart + 2);
        break;
      case 'ArrowLeft':
        moveCursorTo(selectionStart - 1, selectionStart);
        break;
      default:
    }
  };

  const onHandleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setTabbed(false);
    const { allLetters } = isCurrValueHaveDigits(value);
    if (allLetters && showMaskOnFocus && maskOnFocus) {
      setMaskOnFocus(false);
    }
    onBlur?.(e);
  };

  const newState = Object.keys(value)?.length > 0 ? Object.values(value).join('') : value;

  const calculateAge = (dobObject: Record<number, unknown>) => {
    const dob = Object.values(dobObject).join('');

    const [month, day, year] = dob.split('/').map(Number);
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      // eslint-disable-next-line no-plusplus
      age--;
    }

    return age;
  };

  const age = calculateAge(value);
  const ageLabel = age === 1 ? 'yr' : 'yrs';

  return (
    <>
      <div
        className={clsx(
          styles.inputWrapper,
          {
            [styles.error]: error,
            [styles.caution]: caution,
            [styles.adminInputMask]: adminInputMask,
            [styles.tabbed]: tabbed,
          },
          className,
        )}
      >
        <input
          ref={myRef}
          type="tel"
          onClick={onClick as unknown as MouseEventHandler<HTMLInputElement>}
          data-testId={`inputMask${testId}`}
          tabIndex={tabIndex}
          id={id}
          className={styles.input}
          placeholder={placeholder}
          defaultValue={defaultValue}
          inputMode={inputMode || 'text'}
          spellCheck="false"
          onInput={onInput}
          onTouchStart={onTouchStart as unknown as TouchEventHandler<HTMLInputElement>}
          onFocus={onInputFocus}
          value={maskOnFocus ? (newState as string) : ''}
          onKeyDown={onKeyDown}
          autoComplete="off"
          onBlur={onHandleBlur}
          disabled={disabled}
          readOnly={readOnly}
        />
        <span
          className={clsx(styles.age, {
            [styles.display]: Number(age),
          })}
        >
          {Number(age) > 0 && age}
          {' '}
          {ageLabel}
        </span>
      </div>
      {error && error.length && (
        <span
          data-testId={`inputMask${testId}Error`}
          className={clsx({ [styles.errorMessage]: error })}
        >
          {error}
        </span>
      )}
      {caution && caution.length > 0 && (
        <span
          data-testId={`inputMask${testId}Caution`}
          className={clsx({ [styles.cautionMessage]: caution })}
        >
          {caution}
        </span>
      )}
    </>
  );
};
