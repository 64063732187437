export const insuranceProviders = [
  { label: 'Aetna', value: 'Aetna' },
  { label: 'Anthem Blue Cross', value: 'Anthem Blue Cross' },
  { label: 'Caresource', value: 'Caresource' },
  { label: 'Centene', value: 'Centene' },
  { label: 'Cigna Health', value: 'Cigna Health' },
  { label: 'CVS', value: 'CVS' },
];

export const policyholders = [
  { label: 'Me', value: 'self' },
  { label: 'My spouse', value: 'spouse' },
  { label: 'My child', value: 'child' },
  { label: 'Other', value: 'other' },
];

export const emergencyRelationship = [
  { label: 'Spouse', value: 'spouse' },
  { label: 'Adult Child', value: 'child' },
  { label: 'Parent', value: 'parents' },
  { label: 'Friend', value: 'friend' },
  { label: 'Other', value: 'other' },
];

export const legalGender = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Unknown' },
];

export const inputType = {
  NUMBER: 'number',
  PHONE: 'phone',
  EMAIL: 'email',
  TEXT: 'text',
  ZIPCODE: 'zipCode',
};
