import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { ProgressBar } from 'lifestance-ui';

import { obiePreferencesData } from 'intakeOptimization/selectors/selectors';

import { ADMIN_STEPS } from './steps';

interface Props {
  stepNumber: number;
}

export function AdminProgressBar(): ReactElement {
  const { paymentMethod } = useSelector(obiePreferencesData);
  return <ProgressBar steps={ADMIN_STEPS} step={ADMIN_STEPS[0]} paymentMethod={paymentMethod} />;
}
