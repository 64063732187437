import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';

import { PuzzleLoading } from 'lifestance-ui';

import styles from './VerifyingModal.module.scss';

interface IVeryfingModalProps {
  handleOpen: () => void;
}

export const VerifyingModal: FC<React.PropsWithChildren<IVeryfingModalProps>> = ({
  handleOpen,
}) => {
  const [step, setStep] = useState(1);
  const [stepHeight, setStepHeight] = useState(200);
  const stepsRef = useRef<HTMLDivElement>(null);
  const animationFlag = useRef(false);

  useEffect(() => {
    if (!animationFlag.current) {
      animationFlag.current = true;
      const miliseconds = 1500;
      const maxSteps = 2;
      const counter = setInterval(() => {
        setStep((prevStep) => {
          let newStep = 0;
          if (prevStep < maxSteps) {
            newStep = prevStep + 1;
          } else {
            newStep = maxSteps;
            handleOpen();
            clearInterval(counter);
          }
          return newStep;
        });
      }, miliseconds);
    }
  }, []);

  const getStepsSize = () => {
    const newHeight = stepsRef?.current?.clientHeight as number;
    setStepHeight(newHeight);
  };

  useEffect(() => {
    getStepsSize();
  }, [step]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>Verifying compatibility</div>
      <span className={styles.subHeader}>
        Cross-referencing your needs with this provider’s background and credentials...
      </span>
      <div className={styles.steps} ref={stepsRef}>
        <div
          className={clsx(styles.step1, {
            [styles.show]: step === 1 || step === 2,
            [styles.hide]: step > 2,
          })}
        >
          <PuzzleLoading loading={step === 1} />
        </div>
      </div>
    </div>
  );
};
