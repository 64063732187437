import React, { ReactChild } from "react";
import clsx from "clsx";
import styles from "./Tooltip.module.scss";

interface TooltipProps {
  children?: ReactChild;
  className?: string;
  content?: any;
  withTriangle?: boolean;
  legendOrientation?: 'up' | 'down';
}

export const Tooltip = ({ children, content, className = '', withTriangle = false, legendOrientation = 'down' }: TooltipProps) => (
  <div className={styles.tooltipWrapper}>
    {children}
    <div className={clsx(styles.tooltip, className)}>
      <div className={clsx({
          [styles.withTriangle]: withTriangle,
          [styles.legend]: !withTriangle,
          [styles.legendUp]: legendOrientation === 'up',
      })}>
        {content}
      </div>
    </div>
  </div>
);

Tooltip.defaultProps = {
  className: '',
  withTriangle: false
}
