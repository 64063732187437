import { createContext, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const PreviousRouteContext = createContext<string | null>(null);

export default function PreviousRouteProvider({ children }: any) {
  const location = useLocation();

  const ref = useRef<string | null>(null);
  useEffect(() => {
    ref.current = location.pathname;
  }, [location]);

  return (
    <PreviousRouteContext.Provider value={ref.current}>{children}</PreviousRouteContext.Provider>
  );
}

export const usePreviousPath = () => useContext(PreviousRouteContext);
