import { ReactComponent as HourGlass } from 'patientOnboarding/assets/svg/hourglass.svg';
import { useEffect, useState } from 'react';

import { Button } from 'lifestance-ui';

import styles from './TimeOut.module.scss';

function TimeOut() {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (clicked) {
      // do something meaningful, Promises, if/else, whatever, and then
      window.location.assign('https://patientportal.advancedmd.com/140098/account/logon');
    }
  });

  return (
    <div className={styles.container}>
      <HourGlass />
      <div className={styles.content}>
        <div className={styles.title}>You are not signed in</div>
        <div className={styles.heading}>
          <span>
            Your session ended because there was no activity. Please log in to your patient portal
            again.
          </span>
        </div>
        <Button fullWidth onClick={() => setClicked(true)} className={styles.button}>
          Go to patient portal
        </Button>
      </div>
    </div>
  );
}

export default TimeOut;
