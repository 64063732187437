import clsx from 'clsx';
import { Clinician, ClinicianAvailability } from 'patientOnboarding/@types';
import { ReactComponent as BuildingIcon } from 'patientOnboarding/assets/svg/building.svg';
import { ReactComponent as VideoChatIcon } from 'patientOnboarding/assets/svg/video-chat.svg';
import React, { MouseEvent } from 'react';

import { Tooltip } from 'lifestance-ui';

import { getLocalTime, getWeekDay } from 'patientOnboarding/utils';

import formatAndLocalDate from 'lifestance-ui/utils/formatAndLocalDate';
import styles from './AvailabilityButtons.module.scss';

interface AvailabilityButtonsProps {
  availabilities: ClinicianAvailability[];
  clinician: Clinician;
  onSelect: (
    availability: ClinicianAvailability,
    clinician: Clinician,
    event: MouseEvent<HTMLButtonElement>,
    facility?: any,
  ) => void;
  isAdmin?: boolean;
  bothVisits: boolean;
  video: boolean;
  office: boolean;
}
type AvailabilityToDisplay = {
  display: 'Video' | 'Office';
} & ClinicianAvailability;

export const AvailabilityButtons: React.FC<React.PropsWithChildren<AvailabilityButtonsProps>> = ({
  availabilities,
  clinician,
  onSelect,
  isAdmin,
  bothVisits,
  video,
  office,
}) => {
  const getKey = (key: string, index: number) => `${key}${index}`;
  const allAvailabilities: AvailabilityToDisplay[] = [];
  availabilities.forEach((availability) => {
    if (bothVisits) {
      if (availability.reason.includes('TELE')) {
        // Could be non-TORO and no filters
        allAvailabilities.push({ ...availability, display: 'Video' });
      }
      if (availability.reason.includes('OFFC')) {
        // Could be non-TORO and no filters
        allAvailabilities.push({
          ...{ ...availability, display: 'Office' },
        });
      }
      if (availability.reason.includes('TORO')) {
        // Could be TORO and both filters
        allAvailabilities.push({ ...availability, display: 'Video' });
        allAvailabilities.push({
          ...{ ...availability, display: 'Office' },
        });
      }
    } else if (video) {
      if (availability.reason.includes('TELE')) {
        // Could be TELE with virtual filter
        allAvailabilities.push({ ...availability, display: 'Video' });
      }
      if (availability.reason.includes('TORO')) {
        // Could be TORO but have only one filter active virtual or office
        allAvailabilities.push({ ...availability, display: 'Video' });
      }
    } else if (office) {
      if (availability.reason.includes('OFFC')) {
        // Could be OFFC with in-office filter
        allAvailabilities.push({ ...availability, display: 'Office' });
      }
      if (availability.reason.includes('TORO')) {
        // Could be TORO but have only one filter active virtual or office
        allAvailabilities.push({ ...availability, display: 'Office' });
      }
    }
  });

  // slicing to always have 3 records max
  const availabilitiesToDisplay = allAvailabilities.slice(0, 3);

  return (
    <>
      {availabilitiesToDisplay
        .sort(
          (a: any, b: any) => (new Date(a.appointment_utc_start_datetime) as any)
            - (new Date(b.appointment_utc_start_datetime) as any),
        )
        .map((availability, index) => {
          const key = getKey(availability.clinician_availability_key, index);
          const hoverText = availability.display === 'Video'
            ? 'This is a video appointment'
            : 'This is an in-office appointment';
          return (
            <Tooltip className={styles.tooltip} content={hoverText} withTriangle>
              <button
                key={key}
                className={clsx({
                  [styles.buttonDate]: !isAdmin,
                  [styles.AdminButtonAvailability]: isAdmin,
                })}
                data-cy="availabilityMatchBtn"
                data-testId={`availabilityMatchBtn_${index}`}
                type="button"
                onClick={(event) => onSelect(availability, clinician, event, clinician.addresses[0])}
              >
                {availability.display === 'Video' ? (
                  <VideoChatIcon className={styles.modalityIcon} />
                ) : (
                  <BuildingIcon className={styles.modalityIcon} />
                )}
                <span>
                  {formatAndLocalDate(availability.appointment_utc_start_datetime).dateSlotFormat}
                </span>
              </button>
            </Tooltip>
          );
        })}
    </>
  );
};

export default AvailabilityButtons;