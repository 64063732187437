import axios from 'axios';
import { getToken } from 'intakeOptimization/api/legacy/config';

import { store } from 'modules/application/store';
import t from 'modules/application/store/errorHandlers/types';

export const JWT_TOKEN = localStorage.getItem('token');

const BASE_URL = '/obie/api/v2';

const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${JWT_TOKEN}`,
  },
});

API.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    return config;
  },
  async (error) => Promise.reject(error),
);

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { status } = error.response;
    if (status === 401) {
      const token = await getToken();
      localStorage.setItem('token', token?.data.jwt_token);
      error.config.headers.Authorization = `Bearer ${token?.data.jwt_token}`;
      return API.request(error.config);
    }
    // List of API statuses to trigger an error page
    if ([500].includes(status)) {
      const payload = { status, error, redirectionUrl: '/error' };
      store.dispatch({ type: t.THROW_API_ERROR, payload });
    }

    return Promise.reject(error);
  },
);

export default API;
